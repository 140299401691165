import {
  getUserFullName,
  USER_EVENT_COMPONENTS,
} from "../../lib/notifications";
import OtherUserEventNotification from "./OtherUserEventNotification";
import classNames from "classnames";
import { User, UserEventPayload } from "../../types";
import { UserEventType } from "../../enums";
import useMarkUserEventsListAsRead from "../../hooks/notifications/useMarkUserEventsListAsRead";
import ProfilePicture from "../common/ProfilePicture";

import "../../styles/components/common/Notifications.scss";

export default function NotificationItem({
  payload,
  eventType,
  userBriefs,
  onDialogClose,
}: Props): JSX.Element {
  const Component =
    USER_EVENT_COMPONENTS[eventType] || OtherUserEventNotification;
  const user = userBriefs[payload.createdByUserSid];
  const createdByUserName = getUserFullName(user);
  const { eventId, createdByUserSid } = payload;
  const { markUserEventsAsRead } = useMarkUserEventsListAsRead(
    [eventId],
    onDialogClose,
  );
  const className = classNames({
    "notification-item": true,
    "notification-item-unread": !payload.isRead,
  });
  const profilePicture = (
    <ProfilePicture
      alt={createdByUserName}
      userSid={createdByUserSid}
      width={32}
      height={32}
      rounded
    />
  );

  return (
    <Component
      userEventData={payload}
      profilePicture={profilePicture}
      markUserEventsAsRead={markUserEventsAsRead}
      createdByUserName={createdByUserName}
      className={className}
    />
  );
}

type Props = {
  payload: UserEventPayload;
  eventType: UserEventType;
  userBriefs: Record<string, User>;
  onDialogClose: () => void;
};
