import React from "react";
import { IconProps } from "../../types";

export default function DelegationIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M27 27H3V3h24z" />
        <path
          className={className ? `${className}-path` : undefined}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m23.6 19-3.5 4.5-2.1-2.7M14 19h-4M17 15h-7M17 11h-7M21 14V8a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"
        />
      </g>
    </svg>
  );
}
