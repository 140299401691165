import { useParams } from "react-router-dom";
import {
  useGetContactsQuery,
  useGetNeighborsQuery,
  useGetServiceProvidersQuery,
} from "../api/facilityManagementApi";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { addItem } from "../slices/navigationItemsSlice";
import useCustomerRoles from "./useCustomerRoles";
import { UserRoles } from "../enums";

export default function useFetchContactCardInfo() {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const dispatch = useDispatch();
  const roles = useCustomerRoles();
  const titleNavigation =
    roles.includes(UserRoles.Owner) || roles.includes(UserRoles.Employee)
      ? "Eigentümergemeinschaft"
      : "Nachbarn";

  const {
    data: serviceProvidersList = [],
    isFetching: serviceProvidersListFetching,
    isError: serviceProvidersListIsError,
    error: serviceProvidersListError,
  } = useGetServiceProvidersQuery(
    {
      customerToken,
      facilityObjectId,
    },
    { skip: !facilityObjectId },
  );
  const {
    data: facilityObjectContactsList = [],
    isFetching: facilityObjectContactsListIsFetching,
  } = useGetContactsQuery(
    { customerToken, facilityObjectId },
    { skip: !facilityObjectId },
  );

  const {
    data: neighborsContactsList = [],
    isFetching: neighborsContactsListIsFetching,
    isError: neighborsContactsListIsError,
    error: neighborsContactsListError,
  } = useGetNeighborsQuery(
    { customerToken, facilityObjectId },
    { skip: !facilityObjectId },
  );

  const isFetching =
    neighborsContactsListIsFetching ||
    facilityObjectContactsListIsFetching ||
    serviceProvidersListFetching;

  useEffect(() => {
    if (!isFetching) {
      facilityObjectContactsList.length > 0 && dispatch(addItem("Verwaltung"));
      neighborsContactsList.length > 0 && dispatch(addItem(titleNavigation));
      serviceProvidersList.length > 0 && dispatch(addItem("Dienstleister"));
    }
  }, [
    neighborsContactsList,
    facilityObjectContactsList,
    serviceProvidersList,
    isFetching,
  ]);

  return {
    serviceProvidersList,
    facilityObjectContactsList,
    neighborsContactsList,
    serviceProvidersListError,
    serviceProvidersListIsError,
    serviceProvidersListFetching,
    neighborsContactsListIsFetching,
    neighborsContactsListError,
    neighborsContactsListIsError,
    facilityObjectContactsListIsFetching,
  };
}
