import { Activity } from "../../types";
import { useState, Dispatch, SetStateAction, useMemo } from "react";
import moment from "moment";

export default function useFilterMessages(
  myAddressMessages: Activity[],
  objectMessages: Activity[],
): UseFilterMessage {
  const [filter, setFilter] = useState<(number | string)[]>([]);

  const userFilters = filter.filter((v) => typeof v === "string");
  const additionalFilter = filter.filter(
    (v) => v !== -1 && typeof v !== "string",
  );

  const filteredMessages = useMemo(() => {
    const messages = Array.from(
      new Set(
        filter.includes(-1) && filter.length - userFilters.length !== 5
          ? [...myAddressMessages]
          : [...myAddressMessages, ...objectMessages],
      ).values(),
    );

    let filteredMessages =
      additionalFilter.length > 0
        ? messages.filter((m) => additionalFilter.includes(m.stateType))
        : messages;

    if (userFilters.length > 0) {
      const regex = new RegExp(userFilters.join("|"), "i");
      filteredMessages = filteredMessages.filter(
        ({ description, number, changedDateUTC }) =>
          regex.test(
            `${description} ${number} ${moment
              .utc(changedDateUTC)
              .local()
              .format("L HH:mm")}`,
          ),
      );
    }
    return filteredMessages.sort((a, b) => {
      return moment(b.changedDateUTC).diff(a.changedDateUTC);
    });
  }, [filter, myAddressMessages, objectMessages]);

  return {
    messages: filteredMessages,
    setFilter,
  };
}

type UseFilterMessage = {
  messages: Activity[];
  setFilter: Dispatch<SetStateAction<(number | string)[]>>;
};
