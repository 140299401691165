import { useParams } from "react-router-dom";
import { useGetBulletinBoardPostsQuery } from "../../api/bulletinBoardApi";
import { BulletinBoardPost } from "../../types";

export function sortPosts(a: BulletinBoardPost, b: BulletinBoardPost) {
  return (
    Number(a.pinned) + Number(b.pinned) ||
    new Date(b.modifyDateUTC).getTime() - new Date(a.modifyDateUTC).getTime()
  );
}
export default function useBulletinBoardPosts(page = 1, size = 10) {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data, isFetching, isError, error, isSuccess } =
    useGetBulletinBoardPostsQuery(
      {
        customerToken,
        facilityObjectId,
        page,
        size,
      },
      { skip: !facilityObjectId },
    );

  return { data, isFetching, isError, error, isSuccess };
}
