import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function DeactivatedComments({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M16.978 5.6c1.227 0 2.222.995 2.222 2.222v11.026a.8.8 0 0 1-1.366.566l-2.61-2.61H7.022A2.222 2.222 0 0 1 4.8 14.58V7.822c0-1.227.995-2.222 2.222-2.222zm0 1.6H7.022a.622.622 0 0 0-.622.622v6.76c0 .343.279.622.622.622h8.534a.8.8 0 0 1 .565.234l1.479 1.479V7.822a.622.622 0 0 0-.622-.622zm-2.15 1.172a.8.8 0 0 1 0 1.13l-4.525 4.526a.8.8 0 1 1-1.131-1.13l4.525-4.526a.8.8 0 0 1 1.131 0z"
      />
    </svg>
  );
}
