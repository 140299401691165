import React from "react";
import Button from "../common/Button";
import moment from "moment/moment";
import { Link, useParams } from "react-router-dom";
import "../../styles/components/common/TermsOfUseVersionItem.scss";
import useGetPermissions from "../../hooks/useGetPermissions";

export default function TermsOfUseVersionItem({
  version,
  state,
  changedAtUtc,
  createdAtUtc,
  id,
}: Props): JSX.Element {
  const changedDateTime =
    changedAtUtc !== null ? moment(changedAtUtc).format("LLL") : "";
  const createDateTime = moment(createdAtUtc).format("LLL");
  const permission = useGetPermissions();
  const versionState =
    state === 1 ? "Entwurf" : state === 2 ? "Online" : "Archiviert";
  const { customerToken } = useParams();
  return (
    <Link
      to={String(id)}
      data-testid="terms-of-services-item"
      className="terms-of-services-item-wrapper"
    >
      <div className="terms-of-services-item-wrapper-content">
        <h4 className="terms-of-services-item-wrapper-content-headline">
          Version {version}
        </h4>
        <small className="terms-of-services-item-wrapper-content-info">
          {changedDateTime !== ""
            ? `Geändert am ${changedDateTime}`
            : `Erstellt am ${createDateTime}`}
        </small>
        <span>{versionState}</span>
      </div>
      <div className="terms-of-services-item-buttons">
        {state === 1 && permission && <Button green label="Bearbeiten" />}
        <Link
          to={`/management/${customerToken}/nutzungsbedingungen/users/1`}
          className="terms-of-services-item-buttons-users-list"
        >
          {state === 2 && <Button green label="Zustimmung nach Benutzern" />}
        </Link>
      </div>
    </Link>
  );
}

type Props = {
  version: number;
  state: number;
  changedAtUtc: string;
  createdAtUtc: string;
  id: number;
};
