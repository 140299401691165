import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";
import { getCustomerLogoSources } from "../../lib";
import HomeCaseLogo from "../icons/HomeCaseLogo";
import "../../styles/components/common/CustomerLogo.scss";
export default function CustomerLogo(): JSX.Element {
  const { selectedAssignedObject, assignedObjects } =
    useFetchPostLoginBaseData();
  const { customerToken = "" } = useParams();

  const facilityObject = assignedObjects?.find(
    (item) => item.token === customerToken,
  );

  const logoSource =
    selectedAssignedObject || facilityObject || assignedObjects[0];

  const logo = getCustomerLogoSources(logoSource);

  return (
    <Fragment>
      {logo ? (
        <img className="drawer-header-image" {...logo} alt="Logo" />
      ) : (
        <HomeCaseLogo />
      )}
    </Fragment>
  );
}
