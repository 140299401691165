import { IconProps } from "../../types";

export default function SelectDocuments({
  height = 24,
  width = 24,
  className,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 348"
      version="1.1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M282.000000,349.000000
	C188.000015,349.000000 94.500031,349.000000 1.000038,349.000000
	C1.000025,233.000031 1.000025,117.000076 1.000013,1.000084
	C134.333267,1.000056 267.666534,1.000056 400.999878,1.000028
	C400.999908,116.999916 400.999908,232.999832 400.999939,348.999878
	C361.500000,349.000000 322.000000,349.000000 282.000000,349.000000
M254.445190,332.886688
	C252.836121,333.656067 251.254791,334.491547 249.612228,335.181091
	C246.183792,336.620361 243.751495,338.691864 244.112961,342.880493
	C250.906891,342.880493 257.479309,342.880493 264.921539,342.880493
	C264.212738,339.874695 263.610748,337.321747 262.996918,333.943665
	C262.999115,332.014526 263.001282,330.085358 263.816193,328.048035
	C264.479065,326.937958 265.641998,325.866089 265.730194,324.712097
	C267.187897,305.639709 268.466858,286.553711 269.885895,267.478210
	C270.415100,260.364319 271.328461,253.278366 271.823669,246.162766
	C272.142609,241.580307 272.019257,236.967056 272.942017,231.947617
	C272.801575,227.639359 272.719208,223.328049 272.492157,219.024353
	C272.400726,217.291092 271.469208,215.457458 271.824188,213.871902
	C272.985962,208.682327 274.825928,203.640793 275.915009,198.439682
	C277.299469,191.828094 277.891785,185.044388 279.400269,178.466675
	C281.086426,171.114395 277.983093,165.545975 273.684174,160.286804
	C270.887726,156.865753 267.621155,153.817993 264.941650,150.315048
	C261.457458,145.760147 254.614548,145.614609 250.858658,149.940948
	C249.985443,150.946793 247.468597,151.266342 245.985870,150.867844
	C240.919205,149.506042 235.976334,147.683640 230.365875,145.742401
	C223.929367,141.631638 217.236908,137.859863 211.160782,133.271820
	C207.963486,130.857574 205.405029,130.464813 201.390503,132.109314
	C195.315582,130.749985 189.240646,129.390640 183.024445,127.096321
	C182.971008,87.413223 182.917572,47.730129 182.850479,7.470252
	C182.365738,7.685325 181.880997,7.900397 181.227249,9.022534
	C181.213898,34.085388 181.223633,59.148285 181.178360,84.211082
	C181.151993,98.811188 181.037506,113.411148 180.085587,128.004807
	C177.302170,128.331406 174.477524,128.456573 171.741852,129.016479
	C149.764252,133.514496 134.963272,146.365570 129.632080,168.189941
	C124.708435,188.345825 129.481018,206.279785 146.166580,220.678329
	C155.821686,230.265778 167.793320,233.784866 181.289749,234.443588
	C181.366226,234.600784 181.442719,234.757980 181.252029,235.830597
	C181.230927,264.214783 181.205322,292.598999 181.207916,320.983185
	C181.208008,321.950287 181.491119,322.917389 181.437119,324.709808
	C181.456299,331.062866 181.475494,337.415924 181.374481,344.355957
	C181.845062,344.194824 182.315643,344.033661 182.862473,342.955475
	C182.862473,337.682312 182.862473,332.409119 182.862473,326.433594
	C189.365707,328.821838 195.323776,331.009888 202.309540,333.575317
	C202.601273,330.208405 202.835724,327.502625 203.350449,324.158600
	C204.629105,320.101807 205.907776,316.045013 207.927338,312.056122
	C208.802994,310.837067 210.022751,309.738373 210.498154,308.379303
	C213.456787,299.921356 215.841141,291.238220 219.228500,282.962402
	C221.801971,276.674988 224.597534,269.981110 229.070282,265.093262
	C234.772263,258.862152 242.263779,254.268555 249.651703,248.442474
	C249.780640,250.049759 249.848679,250.651169 249.874435,251.254364
	C250.889877,275.033722 251.865829,298.814972 252.991882,322.589081
	C253.068802,324.213043 254.266968,325.783936 254.955078,328.096954
	C254.963303,329.510040 254.971512,330.923157 254.445190,332.886688
M256.370239,144.491150
	C266.501343,145.260849 275.147095,132.658554 269.913635,123.670410
	C265.078125,115.365738 259.626770,110.337280 249.852676,117.504974
	C249.091751,118.062981 247.770264,117.820518 246.941391,118.328651
	C244.341949,119.922157 241.853546,121.696777 239.322128,123.401245
	C240.609116,124.895576 241.896103,126.389908 243.153366,128.728485
	C243.387527,138.164993 246.695892,142.186401 256.370239,144.491150
M335.625610,343.768768
	C352.353119,343.768768 369.080627,343.768768 385.808136,343.768768
	C385.806580,343.482147 385.804993,343.195496 385.803406,342.908875
	C375.879669,342.908875 365.955933,342.908875 356.361725,342.304443
	C358.392242,341.224365 360.311188,339.595551 362.471985,339.156311
	C373.634796,336.887054 379.959686,329.805115 383.377869,319.375214
	C386.168915,310.859009 389.256989,302.440186 392.464111,293.260468
	C387.941132,294.403809 383.029480,295.179962 378.480286,296.909515
	C372.355347,299.238129 374.954163,305.514496 373.433807,310.935516
	C371.942261,307.619873 370.815155,305.114380 369.440033,302.057526
	C367.656616,303.471313 366.071838,304.588348 364.651886,305.885834
	C362.501892,307.850372 360.466583,309.940491 358.231079,311.099609
	C357.424835,294.766602 347.295776,282.217621 332.457153,279.168030
	C317.718475,276.138977 302.700439,283.432709 296.170166,296.791321
	C289.463043,310.511658 292.952271,325.995239 305.488098,336.849854
	C308.943146,338.655029 312.398193,340.460205 315.207275,342.742340
	C299.170990,342.742340 283.134674,342.742340 267.098389,342.742340
	C267.101288,343.169037 267.104156,343.595764 267.107056,344.022461
	C283.181305,344.022461 299.255554,344.031799 315.329620,343.982727
	C315.881378,343.981049 316.431366,343.393921 317.920258,343.106232
	C323.625763,343.134064 329.331268,343.161865 335.625610,343.768768
M64.687347,100.931740
	C65.251564,100.606201 65.974800,100.409050 66.356575,99.936203
	C74.394768,89.980751 73.873016,71.321175 65.794716,61.234783
	C50.890732,42.625996 21.740082,45.767254 11.254704,64.919418
	C4.009968,78.152351 6.422523,96.019402 18.328039,105.621025
	C30.361166,117.126106 51.029510,116.559372 62.161938,104.359093
	C62.995007,103.446106 63.385242,102.129051 64.687347,100.931740
M20.298864,256.604614
	C34.221569,266.580963 48.855946,266.390656 62.153233,255.748459
	C63.668388,254.535843 64.084099,251.949463 65.716644,249.926743
	C66.605545,249.021011 67.759491,248.258774 68.340248,247.186203
	C72.057419,240.321045 73.498039,233.177567 72.094009,225.259598
	C69.002914,207.827484 53.872105,197.071808 37.989098,198.252716
	C16.687365,199.836533 3.708422,218.730682 8.107265,238.002350
	C9.773280,245.301270 12.834919,252.085052 20.298864,256.604614
M73.374496,174.540222
	C80.857796,180.369019 89.550537,182.320023 98.613068,180.817535
	C106.389496,179.528290 114.044411,177.027298 118.617905,168.700150
	C134.054703,152.320099 124.738342,122.888802 102.259399,117.022034
	C86.750740,112.974434 71.002304,120.090454 63.835548,134.384064
	C57.410992,147.197418 61.318203,164.253281 73.374496,174.540222
M233.433853,344.070526
	C236.318314,344.070526 239.202789,344.070526 242.087250,344.070526
	C242.079926,343.834900 242.072601,343.599274 242.065277,343.363647
	C234.428223,343.363647 226.791183,343.363647 219.154144,343.363647
	C219.153931,343.599335 219.153732,343.835022 219.153534,344.070709
	C223.588547,344.070709 228.023575,344.070709 233.433853,344.070526
z"
      />
      <path
        fill="#323144"
        opacity="1.000000"
        stroke="none"
        d="
M254.943665,327.379486
	C254.266968,325.783936 253.068802,324.213043 252.991882,322.589081
	C251.865829,298.814972 250.889877,275.033722 249.874435,251.254364
	C249.848679,250.651169 249.780640,250.049759 249.651703,248.442474
	C242.263779,254.268555 234.772263,258.862152 229.070282,265.093262
	C224.597534,269.981110 221.801971,276.674988 219.228500,282.962402
	C215.841141,291.238220 213.456787,299.921356 210.498154,308.379303
	C210.022751,309.738373 208.802994,310.837067 207.392090,311.711853
	C204.677185,310.122253 202.497513,308.876984 200.244293,307.227539
	C201.038055,297.910370 201.767075,288.981201 202.809692,280.088745
	C203.961029,270.268921 206.496521,261.031403 213.792450,253.620697
	C219.969131,247.346802 224.900131,240.157867 227.578735,231.270996
	C232.512268,231.403885 237.161301,231.983826 241.820877,232.086853
	C251.909088,232.309921 262.002716,232.288834 272.094116,232.367615
	C272.019257,236.967056 272.142609,241.580307 271.823669,246.162766
	C271.328461,253.278366 270.415100,260.364319 269.885895,267.478210
	C268.466858,286.553711 267.187897,305.639709 265.730194,324.712097
	C265.641998,325.866089 264.479065,326.937958 263.013336,328.011597
	C259.788208,327.776611 257.365936,327.578033 254.943665,327.379486
z"
      />
      <path
        fill="#E6E6E6"
        opacity="1.000000"
        stroke="none"
        d="
M272.518066,232.157608
	C262.002716,232.288834 251.909088,232.309921 241.820877,232.086853
	C237.161301,231.983826 232.512268,231.403885 227.496674,230.837463
	C227.620285,228.652252 227.982224,226.629272 228.616302,224.695526
	C230.011200,220.441483 231.543594,216.232529 233.303711,211.829407
	C236.200272,209.706985 238.813004,207.760361 242.388321,205.096558
	C237.841995,203.207520 234.004898,201.613174 230.151154,199.694183
	C230.690231,198.235855 231.245941,197.102158 232.105804,195.813049
	C234.479294,192.202850 237.875397,188.916199 238.338959,185.257919
	C239.275024,177.871170 238.617950,170.282547 238.617950,162.537888
	C235.653976,161.720261 232.673538,160.898102 229.427460,159.900848
	C227.389267,157.905014 225.616730,156.084274 224.183075,154.161285
	C226.676712,151.385010 228.831467,148.710983 230.986237,146.036957
	C235.976334,147.683640 240.919205,149.506042 245.985870,150.867844
	C247.468597,151.266342 249.985443,150.946793 250.858658,149.940948
	C254.614548,145.614609 261.457458,145.760147 264.941650,150.315048
	C267.621155,153.817993 270.887726,156.865753 273.684174,160.286804
	C277.983093,165.545975 281.086426,171.114395 279.400269,178.466675
	C277.891785,185.044388 277.299469,191.828094 275.915009,198.439682
	C274.825928,203.640793 272.985962,208.682327 271.824188,213.871902
	C271.469208,215.457458 272.400726,217.291092 272.492157,219.024353
	C272.719208,223.328049 272.801575,227.639359 272.518066,232.157608
z"
      />
      <path
        fill="#F2F2F2"
        opacity="1.000000"
        stroke="none"
        d="
M146.120392,220.002853
	C129.481018,206.279785 124.708435,188.345825 129.632080,168.189941
	C134.963272,146.365570 149.764252,133.514496 171.741852,129.016479
	C174.477524,128.456573 177.302170,128.331406 180.675507,128.013885
	C181.265442,128.022964 181.557373,127.941734 181.451797,128.402603
	C181.229645,134.241882 181.113068,139.620270 180.614502,145.001007
	C178.369156,145.661041 176.492828,146.284363 174.644608,146.982162
	C164.685074,150.742447 162.261246,154.054733 163.072937,164.616608
	C163.740540,173.303391 170.114624,185.652344 181.132751,186.428162
	C181.185257,189.251602 181.130264,191.639374 180.654999,194.035156
	C175.537582,194.691833 170.751282,194.790771 167.124359,198.800964
	C165.962173,200.085999 163.663712,200.611801 161.790222,200.935043
	C156.044830,201.926407 152.122620,204.964294 150.015656,210.408798
	C148.770157,213.627304 147.422256,216.806168 146.120392,220.002853
z"
      />
      <path
        fill="#02BEF1"
        opacity="1.000000"
        stroke="none"
        d="
M223.844208,154.263519
	C225.616730,156.084274 227.389267,157.905014 229.525528,160.279770
	C234.441727,172.332260 234.613785,184.069153 231.801636,195.968475
	C231.245941,197.102158 230.690231,198.235855 229.949341,199.967194
	C226.097794,203.894272 222.431458,207.223724 218.546204,210.271255
	C215.808380,204.091660 211.457550,201.102386 204.941467,200.716919
	C202.468979,200.570679 199.899796,199.303650 197.692734,198.006363
	C193.266663,195.404800 189.131561,192.124405 183.006271,195.751236
	C183.006271,192.165268 183.006271,189.567856 183.351791,186.894440
	C190.132370,184.741119 194.214142,180.440659 196.193542,173.635590
	C200.191208,160.561142 194.781906,149.228394 183.210571,145.376251
	C183.006165,139.375931 182.910446,133.820816 182.916077,128.227478
	C183.017441,128.189255 183.165726,128.031311 183.165726,128.031311
	C189.240646,129.390640 195.315582,130.749985 201.754822,132.569153
	C202.657013,134.825546 202.628052,137.290573 203.827148,138.307388
	C210.332077,143.823532 217.140396,148.981918 223.844208,154.263519
z"
      />
      <path
        fill="#F2F2F2"
        opacity="1.000000"
        stroke="none"
        d="
M72.917740,174.029892
	C61.318203,164.253281 57.410992,147.197418 63.835548,134.384064
	C71.002304,120.090454 86.750740,112.974434 102.259399,117.022034
	C124.738342,122.888802 134.054703,152.320099 118.041527,168.630569
	C116.057045,163.795700 113.370689,160.745026 107.912155,160.862183
	C106.750984,160.887115 105.614769,159.653107 104.382439,159.274673
	C101.593193,158.418121 98.784966,157.551788 95.923073,157.019394
	C91.055687,156.113907 87.269188,159.252319 82.991325,160.508301
	C75.548866,162.693420 73.645920,167.580246 72.917740,174.029892
M101.503410,131.190582
	C98.286819,127.981079 95.061440,124.463669 89.913857,127.075317
	C84.462975,129.840851 80.853119,137.301468 82.117477,143.347961
	C82.961304,147.383362 84.870491,151.076569 89.441399,151.857651
	C94.018951,152.639832 98.726593,152.396103 101.363441,147.802994
	C104.284309,142.715118 105.502914,137.283875 101.503410,131.190582
z"
      />
      <path
        fill="#02BDF1"
        opacity="1.000000"
        stroke="none"
        d="
M305.092834,336.386292
	C292.952271,325.995239 289.463043,310.511658 296.170166,296.791321
	C302.700439,283.432709 317.718475,276.138977 332.457153,279.168030
	C347.295776,282.217621 357.424835,294.766602 358.197021,311.958496
	C357.841797,319.841431 355.621613,326.121155 350.633911,331.133820
	C349.065918,326.196655 346.007446,323.641205 341.172882,323.119629
	C340.057648,322.999329 338.730652,322.822662 337.928497,322.156921
	C333.673676,318.625519 328.615204,318.295349 323.836243,319.608917
	C319.048248,320.924988 314.538300,323.417969 310.112732,325.781281
	C308.868134,326.445862 308.198669,328.402374 307.534058,329.886597
	C306.590027,331.994843 305.894531,334.214325 305.092834,336.386292
M324.985260,313.665100
	C325.687592,313.644073 326.389923,313.623077 327.944458,313.689178
	C332.110443,313.968292 336.801361,314.907806 337.829987,309.062866
	C338.367523,306.008728 338.284760,302.711609 337.782990,299.638397
	C336.942108,294.487885 332.114807,290.625702 326.859009,290.269409
	C320.592621,289.844635 314.049255,293.256927 312.340820,297.945251
	C311.262543,300.904388 310.281158,303.902740 309.370605,306.918060
	C308.107239,311.101868 309.486603,313.489899 313.838348,313.862030
	C317.291046,314.157318 320.803864,313.749634 324.985260,313.665100
z"
      />
      <path
        fill="#F2F2F2"
        opacity="1.000000"
        stroke="none"
        d="
M19.915174,256.030151
	C12.834919,252.085052 9.773280,245.301270 8.107265,238.002350
	C3.708422,218.730682 16.687365,199.836533 37.989098,198.252716
	C53.872105,197.071808 69.002914,207.827484 72.094009,225.259598
	C73.498039,233.177567 72.057419,240.321045 68.340248,247.186203
	C67.759491,248.258774 66.605545,249.021011 65.085480,249.768158
	C62.615543,245.579987 60.009529,242.772537 55.076122,242.890976
	C53.726482,242.923386 52.379402,241.806686 50.982132,241.376526
	C48.229427,240.529099 45.477211,239.621765 42.661686,239.049973
	C38.090153,238.121597 34.699879,241.626587 30.835457,242.545914
	C23.062328,244.395126 21.363531,249.791336 19.915174,256.030151
M44.379707,210.731308
	C34.845032,208.475220 28.353958,212.182022 25.758726,221.330353
	C25.397228,222.604660 24.913609,223.848145 24.427563,225.082687
	C22.117323,230.950592 23.954704,233.810837 30.357624,233.968094
	C35.343739,234.090546 40.337547,234.040619 45.324821,233.928513
	C49.067688,233.844406 51.418186,232.031250 52.440247,228.229324
	C54.286781,221.360443 51.526924,214.608353 44.379707,210.731308
z"
      />
      <path
        fill="#F2F2F2"
        opacity="1.000000"
        stroke="none"
        d="
M18.068386,104.999710
	C6.422523,96.019402 4.009968,78.152351 11.254704,64.919418
	C21.740082,45.767254 50.890732,42.625996 65.794716,61.234783
	C73.873016,71.321175 74.394768,89.980751 66.356575,99.936203
	C65.974800,100.409050 65.251564,100.606201 64.066765,100.758545
	C61.718285,95.175758 57.415501,93.123016 52.039730,92.394783
	C52.039730,89.106636 52.373241,86.088036 51.965553,83.173103
	C51.264584,78.161240 50.498642,73.093361 49.033390,68.269714
	C47.135822,62.022873 41.365311,60.823795 35.905552,60.207596
	C31.758598,59.739559 26.418545,64.570656 24.985655,69.783310
	C22.730272,77.988106 23.190491,85.852448 29.908895,92.687050
	C24.546961,93.585457 21.149326,96.022255 19.943121,101.026268
	C19.607073,102.420380 18.708757,103.678955 18.068386,104.999710
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M18.198212,105.310364
	C18.708757,103.678955 19.607073,102.420380 19.943121,101.026268
	C21.149326,96.022255 24.546961,93.585457 29.908895,92.687050
	C23.190491,85.852448 22.730272,77.988106 24.985655,69.783310
	C26.418545,64.570656 31.758598,59.739559 35.905552,60.207596
	C41.365311,60.823795 47.135822,62.022873 49.033390,68.269714
	C50.498642,73.093361 51.264584,78.161240 51.965553,83.173103
	C52.373241,86.088036 52.039730,89.106636 52.039730,92.394783
	C57.415501,93.123016 61.718285,95.175758 63.713760,100.792595
	C63.385242,102.129051 62.995007,103.446106 62.161938,104.359093
	C51.029510,116.559372 30.361166,117.126106 18.198212,105.310364
z"
      />
      <path
        fill="#403F58"
        opacity="1.000000"
        stroke="none"
        d="
M183.006271,186.970444
	C183.006271,189.567856 183.006271,192.165268 183.006271,195.751236
	C189.131561,192.124405 193.266663,195.404800 197.692734,198.006363
	C199.899796,199.303650 202.468979,200.570679 204.941467,200.716919
	C211.457550,201.102386 215.808380,204.091660 218.366470,210.487427
	C216.494919,212.776871 214.858643,215.110641 212.615738,216.245560
	C208.090363,218.535400 208.063446,221.669174 209.490479,225.736542
	C203.190186,228.262222 197.260681,230.754608 191.222336,232.947250
	C188.519073,233.928864 185.597565,234.309479 182.566193,234.935883
	C182.077499,234.852127 181.798141,234.854202 181.519226,234.915192
	C181.442719,234.757980 181.366226,234.600784 181.243317,233.753601
	C181.156357,220.051453 181.115799,207.039307 181.075256,194.027145
	C181.130264,191.639374 181.185257,189.251602 181.216003,185.966278
	C181.383698,178.394913 181.575653,171.721100 182.097382,165.030701
	C182.646255,165.023453 182.865387,165.032761 183.048248,165.505600
	C183.010056,172.969559 183.008163,179.970001 183.006271,186.970444
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M180.654999,194.035156
	C181.115799,207.039307 181.156357,220.051453 181.113953,233.525513
	C167.793320,233.784866 155.821686,230.265778 146.143494,220.340591
	C147.422256,216.806168 148.770157,213.627304 150.015656,210.408798
	C152.122620,204.964294 156.044830,201.926407 161.790222,200.935043
	C163.663712,200.611801 165.962173,200.085999 167.124359,198.800964
	C170.751282,194.790771 175.537582,194.691833 180.654999,194.035156
z"
      />
      <path
        fill="#F2F2F2"
        opacity="1.000000"
        stroke="none"
        d="
M350.875092,331.410675
	C355.621613,326.121155 357.841797,319.841431 358.272766,312.397278
	C360.466583,309.940491 362.501892,307.850372 364.651886,305.885834
	C366.071838,304.588348 367.656616,303.471313 369.440033,302.057526
	C370.815155,305.114380 371.942261,307.619873 373.433807,310.935516
	C374.954163,305.514496 372.355347,299.238129 378.480286,296.909515
	C383.029480,295.179962 387.941132,294.403809 392.464111,293.260468
	C389.256989,302.440186 386.168915,310.859009 383.377869,319.375214
	C379.959686,329.805115 373.634796,336.887054 362.471985,339.156311
	C360.311188,339.595551 358.392242,341.224365 355.791107,342.639282
	C354.137970,342.956390 353.055450,342.938690 351.983032,342.477234
	C351.704010,338.702667 351.414856,335.371857 351.035553,331.894409
	C350.945374,331.747742 350.875092,331.410675 350.875092,331.410675
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M20.107019,256.317383
	C21.363531,249.791336 23.062328,244.395126 30.835457,242.545914
	C34.699879,241.626587 38.090153,238.121597 42.661686,239.049973
	C45.477211,239.621765 48.229427,240.529099 50.982132,241.376526
	C52.379402,241.806686 53.726482,242.923386 55.076122,242.890976
	C60.009529,242.772537 62.615543,245.579987 64.731964,249.804840
	C64.084099,251.949463 63.668388,254.535843 62.153233,255.748459
	C48.855946,266.390656 34.221569,266.580963 20.107019,256.317383
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M73.146118,174.285065
	C73.645920,167.580246 75.548866,162.693420 82.991325,160.508301
	C87.269188,159.252319 91.055687,156.113907 95.923073,157.019394
	C98.784966,157.551788 101.593193,158.418121 104.382439,159.274673
	C105.614769,159.653107 106.750984,160.887115 107.912155,160.862183
	C113.370689,160.745026 116.057045,163.795700 117.734131,168.780472
	C114.044411,177.027298 106.389496,179.528290 98.613068,180.817535
	C89.550537,182.320023 80.857796,180.369019 73.146118,174.285065
z"
      />
      <path
        fill="#403F58"
        opacity="1.000000"
        stroke="none"
        d="
M350.633911,331.133820
	C350.875092,331.410675 350.945374,331.747742 350.906738,332.210419
	C346.273529,335.830109 341.678894,338.987091 336.772339,342.116150
	C335.985840,342.455353 335.511292,342.822510 335.036774,343.189667
	C329.331268,343.161865 323.625763,343.134064 317.374420,342.838623
	C316.503479,342.469147 316.178345,342.367279 315.853241,342.265381
	C312.398193,340.460205 308.943146,338.655029 305.290466,336.618073
	C305.894531,334.214325 306.590027,331.994843 307.534058,329.886597
	C308.198669,328.402374 308.868134,326.445862 310.112732,325.781281
	C314.538300,323.417969 319.048248,320.924988 323.836243,319.608917
	C328.615204,318.295349 333.673676,318.625519 337.928497,322.156921
	C338.730652,322.822662 340.057648,322.999329 341.172882,323.119629
	C346.007446,323.641205 349.065918,326.196655 350.633911,331.133820
z"
      />
      <path
        fill="#353346"
        opacity="1.000000"
        stroke="none"
        d="
M243.183090,127.884239
	C241.896103,126.389908 240.609116,124.895576 239.322144,123.401245
	C241.853546,121.696777 244.341949,119.922157 246.941391,118.328651
	C247.770264,117.820518 249.091751,118.062981 249.852676,117.504974
	C259.626770,110.337280 265.078125,115.365738 269.913635,123.670410
	C275.147095,132.658554 266.501343,145.260849 256.150452,143.897736
	C257.056854,140.333923 258.183014,137.363541 259.070770,135.022018
	C256.494324,133.769714 254.888138,132.927078 253.232254,132.197220
	C249.896851,130.727066 246.534393,129.318314 243.183090,127.884239
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M224.183075,154.161285
	C217.140396,148.981918 210.332077,143.823532 203.827148,138.307388
	C202.628052,137.290573 202.657013,134.825546 202.140259,132.653992
	C205.405029,130.464813 207.963486,130.857574 211.160782,133.271820
	C217.236908,137.859863 223.929367,141.631638 230.676056,145.889679
	C228.831467,148.710983 226.676712,151.385010 224.183075,154.161285
z"
      />
      <path
        fill="#5D5D6B"
        opacity="1.000000"
        stroke="none"
        d="
M182.864136,8.047034
	C182.917572,47.730129 182.971008,87.413223 183.095093,127.563812
	C183.165726,128.031311 183.017441,128.189255 182.709137,128.158875
	C182.106125,128.127502 181.824966,128.065231 181.557373,127.941734
	C181.557373,127.941734 181.265442,128.022964 181.114044,128.017059
	C181.037506,113.411148 181.151993,98.811188 181.178360,84.211082
	C181.223633,59.148285 181.213898,34.085388 181.557037,8.566003
	C182.210037,8.048567 182.535812,8.027754 182.864136,8.047034
z"
      />
      <path
        fill="#353346"
        opacity="1.000000"
        stroke="none"
        d="
M181.494690,343.768982
	C181.475494,337.415924 181.456299,331.062866 181.946838,324.325928
	C182.723969,323.894928 182.991394,323.847809 183.528061,323.590759
	C188.491425,322.242584 193.185547,321.104340 198.002350,320.275604
	C199.773407,321.989044 201.421783,323.392944 203.070160,324.796844
	C202.835724,327.502625 202.601273,330.208405 202.309540,333.575317
	C195.323776,331.009888 189.365707,328.821838 182.862473,326.433594
	C182.862473,332.409119 182.862473,337.682312 182.610275,343.355804
	C182.068130,343.614105 181.780334,343.618408 181.494690,343.768982
z"
      />
      <path
        fill="#5D5D6B"
        opacity="1.000000"
        stroke="none"
        d="
M183.258820,323.800720
	C182.991394,323.847809 182.723969,323.894928 182.049438,323.913269
	C181.491119,322.917389 181.208008,321.950287 181.207916,320.983185
	C181.205322,292.598999 181.230927,264.214783 181.385620,235.372894
	C181.798141,234.854202 182.077499,234.852127 182.659698,235.398895
	C182.973328,247.546036 182.958954,259.203308 183.001755,270.860382
	C183.066544,288.507233 183.171143,306.153961 183.258820,323.800720
z"
      />
      <path
        fill="#323144"
        opacity="1.000000"
        stroke="none"
        d="
M263.008728,334.768768
	C263.610748,337.321747 264.212738,339.874695 264.921539,342.880493
	C257.479309,342.880493 250.906891,342.880493 244.112961,342.880493
	C243.751495,338.691864 246.183792,336.620361 249.612228,335.181091
	C251.254791,334.491547 252.836121,333.656067 255.108398,332.613495
	C258.183990,333.149780 260.596344,333.959259 263.008728,334.768768
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M243.168228,128.306366
	C246.534393,129.318314 249.896851,130.727066 253.232254,132.197220
	C254.888138,132.927078 256.494324,133.769714 259.070770,135.022018
	C258.183014,137.363541 257.056854,140.333923 255.839355,143.670990
	C246.695892,142.186401 243.387527,138.164993 243.168228,128.306366
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M203.210297,324.477722
	C201.421783,323.392944 199.773407,321.989044 198.012177,319.859497
	C198.705490,315.299774 199.511658,311.465759 200.317810,307.631714
	C202.497513,308.876984 204.677185,310.122253 207.021637,311.677887
	C205.907776,316.045013 204.629105,320.101807 203.210297,324.477722
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M335.331177,343.479218
	C335.511292,342.822510 335.985840,342.455353 337.064453,342.401123
	C342.436707,342.783051 347.204803,342.851990 351.972931,342.920959
	C353.055450,342.938690 354.137970,342.956390 355.626343,342.941467
	C365.955933,342.908875 375.879669,342.908875 385.803406,342.908875
	C385.804993,343.195496 385.806580,343.482147 385.808136,343.768768
	C369.080627,343.768768 352.353119,343.768768 335.331177,343.479218
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M315.530273,342.503845
	C316.178345,342.367279 316.503479,342.469147 316.905396,342.825348
	C316.431366,343.393921 315.881378,343.981049 315.329620,343.982727
	C299.255554,344.031799 283.181305,344.022461 267.107056,344.022461
	C267.104156,343.595764 267.101288,343.169037 267.098389,342.742340
	C283.134674,342.742340 299.170990,342.742340 315.530273,342.503845
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M263.002808,334.356201
	C260.596344,333.959259 258.183990,333.149780 255.375671,332.338257
	C254.971512,330.923157 254.963303,329.510040 254.949371,327.738220
	C257.365936,327.578033 259.788208,327.776611 262.606995,328.065704
	C263.001282,330.085358 262.999115,332.014526 263.002808,334.356201
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M232.946228,344.070618
	C228.023575,344.070709 223.588547,344.070709 219.153534,344.070709
	C219.153732,343.835022 219.153931,343.599335 219.154144,343.363647
	C226.791183,343.363647 234.428223,343.363647 242.065277,343.363647
	C242.072601,343.599274 242.079926,343.834900 242.087250,344.070526
	C239.202789,344.070526 236.318314,344.070526 232.946228,344.070618
z"
      />
      <path
        fill="#83838D"
        opacity="1.000000"
        stroke="none"
        d="
M181.434586,344.062469
	C181.780334,343.618408 182.068130,343.614105 182.572144,343.814331
	C182.315643,344.033661 181.845062,344.194824 181.434586,344.062469
z"
      />
      <path
        fill="#83838D"
        opacity="1.000000"
        stroke="none"
        d="
M182.857300,7.758643
	C182.535812,8.027754 182.210037,8.048567 181.641541,8.112471
	C181.880997,7.900397 182.365738,7.685325 182.857300,7.758643
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M200.244293,307.227539
	C199.511658,311.465759 198.705490,315.299774 197.889496,319.549927
	C193.185547,321.104340 188.491425,322.242584 183.528076,323.590759
	C183.171143,306.153961 183.066544,288.507233 183.001755,270.860382
	C182.958954,259.203308 182.973328,247.546036 182.868622,235.425842
	C185.597565,234.309479 188.519073,233.928864 191.222336,232.947250
	C197.260681,230.754608 203.190186,228.262222 209.878540,225.807785
	C212.443771,225.223328 214.588943,225.159698 216.092911,224.145325
	C221.847031,220.264374 227.394958,216.077698 233.019867,212.005173
	C231.543594,216.232529 230.011200,220.441483 228.616302,224.695526
	C227.982224,226.629272 227.620285,228.652252 227.216949,231.068298
	C224.900131,240.157867 219.969131,247.346802 213.792450,253.620697
	C206.496521,261.031403 203.961029,270.268921 202.809692,280.088745
	C201.767075,288.981201 201.038055,297.910370 200.244293,307.227539
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M233.303711,211.829407
	C227.394958,216.077698 221.847031,220.264374 216.092911,224.145325
	C214.588943,225.159698 212.443771,225.223328 210.205017,225.652374
	C208.063446,221.669174 208.090363,218.535400 212.615738,216.245560
	C214.858643,215.110641 216.494919,212.776871 218.585373,210.769348
	C222.431458,207.223724 226.097794,203.894272 229.965973,200.291824
	C234.004898,201.613174 237.841995,203.207520 242.388321,205.096558
	C238.813004,207.760361 236.200272,209.706985 233.303711,211.829407
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M232.105804,195.813049
	C234.613785,184.069153 234.441727,172.332260 229.791183,160.454865
	C232.673538,160.898102 235.653976,161.720261 238.617950,162.537888
	C238.617950,170.282547 239.275024,177.871170 238.338959,185.257919
	C237.875397,188.916199 234.479294,192.202850 232.105804,195.813049
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M181.767609,165.047272
	C181.575653,171.721100 181.383698,178.394913 181.108490,185.530624
	C170.114624,185.652344 163.740540,173.303391 163.072937,164.616608
	C162.261246,154.054733 164.685074,150.742447 174.644608,146.982162
	C176.492828,146.284363 178.369156,145.661041 180.771027,145.141632
	C181.363846,145.577133 181.520386,145.803848 181.681030,146.423645
	C181.644501,152.940582 181.706055,158.993927 181.767609,165.047272
z"
      />
      <path
        fill="#403F58"
        opacity="1.000000"
        stroke="none"
        d="
M181.779129,145.960068
	C181.520386,145.803848 181.363846,145.577133 181.153015,145.139297
	C181.113068,139.620270 181.229645,134.241882 181.451797,128.402603
	C181.824966,128.065231 182.106125,128.127502 182.607788,128.197113
	C182.910446,133.820816 183.006165,139.375931 182.827682,145.437592
	C182.295364,145.949448 182.037247,145.954758 181.779129,145.960068
z"
      />
      <path
        fill="#313347"
        opacity="1.000000"
        stroke="none"
        d="
M181.681030,146.423645
	C182.037247,145.954758 182.295364,145.949448 182.936371,145.882812
	C194.781906,149.228394 200.191208,160.561142 196.148773,173.268860
	C193.058838,162.971909 190.507004,161.346664 183.084534,165.042084
	C182.865387,165.032761 182.646255,165.023453 182.097382,165.030701
	C181.706055,158.993927 181.644501,152.940582 181.681030,146.423645
z"
      />
      <path
        fill="#9C616B"
        opacity="1.000000"
        stroke="none"
        d="
M183.048248,165.505600
	C190.507004,161.346664 193.058838,162.971909 195.867584,173.521713
	C194.214142,180.440659 190.132370,184.741119 183.351791,186.894440
	C183.008163,179.970001 183.010056,172.969559 183.048248,165.505600
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M101.703072,131.514771
	C105.502914,137.283875 104.284309,142.715118 101.363441,147.802994
	C98.726593,152.396103 94.018951,152.639832 89.441399,151.857651
	C84.870491,151.076569 82.961304,147.383362 82.117477,143.347961
	C80.853119,137.301468 84.462975,129.840851 89.913857,127.075317
	C95.061440,124.463669 98.286819,127.981079 101.703072,131.514771
z"
      />
      <path
        fill="#313347"
        opacity="1.000000"
        stroke="none"
        d="
M324.290009,313.654053
	C320.803864,313.749634 317.291046,314.157318 313.838348,313.862030
	C309.486603,313.489899 308.107239,311.101868 309.370605,306.918060
	C310.281158,303.902740 311.262543,300.904388 312.340820,297.945251
	C314.049255,293.256927 320.592621,289.844635 326.859009,290.269409
	C332.114807,290.625702 336.942108,294.487885 337.782990,299.638397
	C338.284760,302.711609 338.367523,306.008728 337.829987,309.062866
	C336.801361,314.907806 332.110443,313.968292 327.809387,313.506195
	C331.956146,311.949127 334.581665,309.300568 334.786285,304.512634
	C329.407898,304.511536 324.047363,306.989471 319.987457,303.182312
	C318.777496,304.007996 317.465027,304.903687 314.852600,306.686462
	C318.510925,309.387390 321.400452,311.520721 324.290009,313.654053
z"
      />
      <path
        fill="#FBB7B8"
        opacity="1.000000"
        stroke="none"
        d="
M324.637634,313.659576
	C321.400452,311.520721 318.510925,309.387390 314.852600,306.686462
	C317.465027,304.903687 318.777496,304.007996 319.987457,303.182312
	C324.047363,306.989471 329.407898,304.511536 334.786285,304.512634
	C334.581665,309.300568 331.956146,311.949127 327.383301,313.462646
	C326.389923,313.623077 325.687592,313.644073 324.637634,313.659576
z"
      />
      <path
        fill="#CDCDCD"
        opacity="1.000000"
        stroke="none"
        d="
M44.744167,210.880707
	C51.526924,214.608353 54.286781,221.360443 52.440247,228.229324
	C51.418186,232.031250 49.067688,233.844406 45.324821,233.928513
	C40.337547,234.040619 35.343739,234.090546 30.357624,233.968094
	C23.954704,233.810837 22.117323,230.950592 24.427563,225.082687
	C24.913609,223.848145 25.397228,222.604660 25.758726,221.330353
	C28.353958,212.182022 34.845032,208.475220 44.744167,210.880707
z"
      />
      <path
        fill="#FEFEFE"
        opacity="1.000000"
        stroke="none"
        d="
M351.983032,342.477234
	C347.204803,342.851990 342.436707,342.783051 337.376434,342.429077
	C341.678894,338.987091 346.273529,335.830109 350.996948,332.357056
	C351.414856,335.371857 351.704010,338.702667 351.983032,342.477234
z"
      />
    </svg>
  );
}
