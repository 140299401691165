import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React, { Fragment } from "react";
import Phone from "../icons/Phone";
import Mail from "../icons/Mail";
import CaretDown from "../icons/CaretDown";
import ContactPersonCardIcon from "../common/contactPerson/ContactPersonCardIcon";
import ProfilePictureManagement from "../common/ProfilePictureManagement";

export default function ItemContactBoard({
  roleName,
  firma,
  street,
  city,
  email,
  lastName,
  firstName,
  tel,
  onClick,
  modelContent,
  onClose,
  employeeId,
  visitingCardId,
}: Props): JSX.Element {
  return (
    <Fragment>
      {!modelContent ? (
        <div
          className="dashboard-right-container-item-contact"
          onClick={onClick}
          data-testid="item-contact"
        >
          {roleName}
          <CaretDown className="dashboard-right-container-item-contact-headline-icon-right" />
        </div>
      ) : (
        <div
          className="dashboard-right-container-item-contact open"
          data-testid="item-contact"
          onClick={onClose}
        >
          <div className="title-container">
            {roleName}
            <CaretDown className="dashboard-right-container-item-contact-headline-icon-down" />
          </div>
          <div className="contact-info-card-wrapper">
            <div className="contact-info-card">
              <div>{firma}</div>
              <div>{`${firstName} ${lastName}`}</div>
              {street && (
                <div>
                  {street}, {city}
                </div>
              )}
              <div className="contact-info-card-communication-data">
                {tel && tel?.length > 0 && (
                  <div className="contact-info-card-communication-data-info">
                    <a href={`tel:${tel[0]}`}>
                      <Phone className="contact-info-card-communication-data-info-icon" />
                      <span>{tel[0]}</span>
                    </a>
                  </div>
                )}

                {email && email?.length > 0 && (
                  <div className="contact-info-card-communication-data-info">
                    <a href={`mailto:${email[0]}`}>
                      <Mail className="contact-info-card-communication-data-info-icon" />
                      <span>{email[0]}</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <ContactPersonCardIcon
              profilePicture={
                <ProfilePictureManagement
                  alt={lastName}
                  rounded
                  width={120}
                  height={120}
                  employeeId={employeeId}
                  visitingCardId={visitingCardId}
                />
              }
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  roleName?: string;
  firma?: string;
  street?: string;
  city?: string;
  email?: string[];
  mobil?: string[];
  tel?: string[];
  lastName: string;
  firstName?: string;
  modelContent?: boolean;
  onClick: () => void;
  onClose: () => void;
  id?: string | number;
  employeeId?: string;
  visitingCardId?: string;
};
