import React from "react";
import { IconProps } from "../../../types";

export default function ServicesAir({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
        d="M95 24H25a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h5c.92 0 1.667-.746 1.667-1.667V59A3.333 3.333 0 0 1 35 55.667h50A3.333 3.333 0 0 1 88.333 59v3.333c0 .92.747 1.667 1.667 1.667h5a5 5 0 0 0 5-5V29a5 5 0 0 0-5-5zm-6.667 25H31.667a2.5 2.5 0 1 1 0-5h56.666a2.5 2.5 0 1 1 0 5zm0-11.667H31.667a2.5 2.5 0 1 1 0-5h56.666a2.5 2.5 0 1 1 0 5zM70.333 80.22A3.333 3.333 0 1 0 67 74.447l-2.41 1.39a.833.833 0 0 1-1.257-.72v-2.784a3.333 3.333 0 1 0-6.666 0v2.784a.833.833 0 0 1-1.25.72L53 74.447a3.333 3.333 0 0 0-3.333 5.773l2.41 1.393a.83.83 0 0 1 0 1.44l-2.41 1.394A3.333 3.333 0 1 0 53 90.22l2.41-1.39a.833.833 0 0 1 1.25.72v2.783a3.333 3.333 0 1 0 6.667 0V89.55a.833.833 0 0 1 1.25-.72l2.41 1.39a3.333 3.333 0 1 0 3.333-5.773l-2.41-1.394a.83.83 0 0 1 0-1.44l2.423-1.393zM35 67.333a3.333 3.333 0 0 0-3.333 3.334 8.557 8.557 0 0 1-3.8 8.273 14.883 14.883 0 0 0-6.2 13.393 3.333 3.333 0 0 0 6.666 0 8.557 8.557 0 0 1 3.8-8.273 14.883 14.883 0 0 0 6.2-13.393A3.333 3.333 0 0 0 35 67.333zM92.133 78.94a8.557 8.557 0 0 1-3.8-8.273 3.333 3.333 0 1 0-6.666 0 14.883 14.883 0 0 0 6.2 13.393 8.557 8.557 0 0 1 3.8 8.273 3.333 3.333 0 1 0 6.666 0 14.883 14.883 0 0 0-6.2-13.393z"
      />
    </svg>
  );
}
