import React from "react";

import "../../../styles/components/common/contact/ContactPersonCard.scss";
import classNames from "classnames";

export default function ContactPersonCardResponsibility(
  props: PropsProvider,
): JSX.Element {
  const { responsibilities, dialog, contractedResources } = props;
  const classForResponsibility = classNames(
    "contact-person-card-responsibility",
    {
      "contact-person-card-responsibility-dialog": dialog,
    },
  );

  return (
    <div className={classForResponsibility} role="responsibility">
      {responsibilities?.map((item: string | undefined) => (
        <div
          className="contact-person-card-responsibility-name"
          data-testid="contact-person-card-responsibility-name"
          key={item}
        >
          {item}
        </div>
      ))}
      {!responsibilities?.length &&
        contractedResources?.map((item: string) => (
          <div
            className="contact-person-card-responsibility-name"
            data-testid="contact-person-card-responsibility-name"
            key={item}
          >
            {item}
          </div>
        ))}
    </div>
  );
}

type PropsProvider = {
  responsibilities?: (string | undefined)[];
  dialog?: boolean;
  contractedResources?: string[];
};
