import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Field, useFormState } from "react-final-form";
import { Employee } from "../../types";
import Select from "../common/form/Select";
import TextInput from "../common/form/TextInput";

import "../../styles/components/employees/EmployeesAddDialogDescription.scss";
import validate, { validateRequired } from "../../lib/formValidate";
import InfoText from "../userSettings/InfoText";
export default function EmployeesAddDialogDescription({
  employeesList,
  setSelectedEmployee,
  isErrorPostEmployeeOnlineIdentity,
  isErrorPostEnrollInternetUserAccount,
}: Props): JSX.Element {
  const { values, dirtyFields } = useFormState();

  const [isSelectedItem, setSelectedName] = useState<Employee | undefined>(
    undefined,
  );
  const fullName = isSelectedItem?.name || "";
  const nameParts = fullName.split(" ");

  const firstName =
    nameParts.length > 1 ? fullName.split(" ").slice(0, -1) : "";
  const lastName = nameParts[nameParts.length - 1];

  useEffect(() => {
    const employee = employeesList?.filter(
      (item) => item.name === values.selectName,
    );
    employee && setSelectedName(employee[0]);
    employee && setSelectedEmployee(employee[0]);
  }, [values, isSelectedItem]);

  useEffect(() => {
    const fullName = values.selectName || "";
    const nameParts = fullName.split(" ");

    const firstName =
      nameParts.length > 1 ? nameParts.slice(0, -1).join(" ") : "";
    const lastName = nameParts[nameParts.length - 1];

    if (dirtyFields) {
      values.firstName = firstName;
      values.lastName = lastName;
    }
  }, [values.selectName]);

  const options = useMemo(
    () =>
      employeesList
        ? [
            ...employeesList.map((item) => ({
              label: item.name,
              value: item.name,
            })),
          ]
        : [],
    [employeesList],
  );

  return (
    <Fragment>
      <div className="employees-add-dialog-description-field-wrapper">
        <Field
          component={Select}
          name="selectName"
          options={options}
          label="Für welchen CRM-Benutzer möchten Sie ein neues Mitarbeiterkonto
          anlegen?"
        />
        <div className="name-info-container">
          <Field
            name="firstName"
            type="text"
            component={TextInput}
            label="Vorname"
            initialValue={firstName[0]}
            value={firstName[0]}
            required
            validate={validate(
              validateRequired("Bitte geben Sie einen Vornamen an!"),
            )}
          />

          <Field
            name="lastName"
            type="text"
            component={TextInput}
            label="Nachname"
            initialValue={lastName}
            value={lastName}
            required
            validate={validate(
              validateRequired("Bitte geben Sie einen Nachnamen an!"),
            )}
          />
        </div>
        <Field
          name="email"
          type="text"
          component={TextInput}
          label="E-Mail-Adresse"
          infoText="Mit welcher E-Mail-Adresse soll sich der neue Mitarbeiter anmelden?"
          required
          validate={validate(
            validateRequired("Bitte geben Sie eine E-Mail-Adresse an!"),
          )}
          data-testid="email-field"
        />
      </div>
      {isErrorPostEmployeeOnlineIdentity ||
        (isErrorPostEnrollInternetUserAccount && (
          <InfoText
            isError={true}
            textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
          />
        ))}
    </Fragment>
  );
}

type Props = {
  employeesList?: Employee[];
  setSelectedEmployee: (item: Employee) => void;
  isErrorPostEmployeeOnlineIdentity: boolean;
  isErrorPostEnrollInternetUserAccount: boolean;
};
