import { SyntheticEvent, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

import {
  PropositionVoteType,
  PropositionVotingState,
  SynchronisationMethod,
} from "../../enums";
import { useGetVoteQuery, useSetVoteMutation } from "../../api/propositionApi";
import { PropositionVote } from "../../types";
import { useSynchronisation } from "../useSynchronisation";

export default function usePropositionVote(
  propositionId: string,
  votingState: PropositionVotingState,
): UsePropositionVote {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();
  const { connection } = useSynchronisation();
  const {
    data,
    isLoading: getVoteIsLoading,
    refetch,
  } = useGetVoteQuery(
    {
      customerToken,
      facilityObjectId,
      meetingId,
      agendaItemId,
      propositionId,
    },
    {
      skip: PropositionVotingState.Running !== votingState,
    },
  );

  const [
    vote,
    {
      isLoading: setVoteIsLoading,
      isError,
      error,
      originalArgs,
      isSuccess: setVoteSuccess,
    },
  ] = useSetVoteMutation();

  useEffect(() => {
    if (connection) {
      connection.on(SynchronisationMethod.PropositionVotingChanged, refetch);
    }

    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.PropositionVotingChanged, refetch);
      }
    };
  }, [connection]);

  const handleVote = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const voteType: PropositionVoteType = parseInt(e.currentTarget.value, 10);
      if (votingState === PropositionVotingState.Running) {
        vote({
          customerToken,
          facilityObjectId,
          meetingId,
          agendaItemId,
          propositionId,
          voteType,
        });
      }
    },
    [propositionId, votingState],
  );

  const retryVote = useCallback(() => {
    if (originalArgs) {
      vote(originalArgs);
    }
  }, [originalArgs]);

  return {
    getVoteIsLoading,
    handleVote,
    retryVote,
    setVoteIsLoading,
    setVoteSuccess,
    isError,
    error,
    data,
    refetch,
  };
}

type UsePropositionVote = {
  handleVote: (e: SyntheticEvent<HTMLButtonElement>) => void;
  retryVote: () => void;
  refetch: () => void;
  setVoteIsLoading: boolean;
  getVoteIsLoading: boolean;
  setVoteSuccess: boolean;
  isError: boolean;
  data: PropositionVote | undefined;
  error: FetchBaseQueryError | SerializedError | undefined;
};
