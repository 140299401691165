import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { useGetAgendaItemsQuery } from "../../../api/agendaItemApi";
import AgendaNavigation from "../../meetings/AgendaNavigation";
import TabBar from "../../common/TabBar";
import Tab from "../../common/Tab";
import { MeetingState, SynchronisationMethod, UserRoles } from "../../../enums";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import useNavigateTabByInvitation from "../../../hooks/meetings/useNavigateTabByInvitation";

import "../../../styles/components/pages/meetings/AgendaPage.scss";
import useMeetingState from "../../../hooks/meetings/useMeetingState";
import AlertDialog from "../../common/dialog/AlertDialog";
import MeetingExitIcon from "../../icons/MeetingExitIcon";
import useStateChanged from "../../../hooks/useStateChanged";
import { useGetMeetingQuery } from "../../../api/meetingApi";

const initialState = {
  meetingId: "",
  time: new Date().getTime(),
};

export default function AgendaPage(): JSX.Element {
  const {
    customerToken = "",
    meetingId = "",
    agendaItemId = "",
    facilityObjectId = "",
  } = useParams();
  const location = useLocation();
  const context = useOutletContext();
  const [firstTabTo, setFirstTabTo] = useState(agendaItemId);
  const customerRoles = useCustomerRoles();
  const participantsActive = location.pathname.includes("participants");
  const invitationActive = location.pathname.includes("invitation");
  const eventLogActive = location.pathname.includes("eventlog");
  const { checked } = useNavigateTabByInvitation();
  const { data = [] } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });
  const meetingState = useMeetingState(meetingId);
  const [isDialogIfMeetingClosed, setDialogIfMeetingClosed] = useState(false);
  useEffect(() => {
    if (!participantsActive && agendaItemId !== "") {
      setFirstTabTo(`./agenda/${agendaItemId}`);
    }
    if (!participantsActive && agendaItemId === "") {
      setFirstTabTo(`./`);
    }
  }, [agendaItemId, participantsActive]);

  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const isMeetingNotRunningOrClosed =
    meetingState !== MeetingState.Running &&
    meetingState !== MeetingState.Closed;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMeetingNotRunningOrClosed) {
      navigate(firstTabTo);
    }
  }, [isMeetingNotRunningOrClosed]);

  const isAgendaNavigationVisible =
    (isEmployee ||
      meetingState === MeetingState.Running ||
      meetingState === MeetingState.Closed) &&
    !participantsActive &&
    !invitationActive &&
    !eventLogActive;

  const dialogTitle = (
    <div className="dialog-description-wrapper">
      <span>Die Versammlung wurde durch Ihre Hausverwaltung beendet.</span>
      <span>Sie werden auf die Übersichtsseite weitergeleitet.</span>
    </div>
  );

  const handleDialogClose = useCallback(() => {
    setDialogIfMeetingClosed(false);
    setMeetingStateChanged(initialState);
    navigate("../");
  }, [meetingState]);

  const [meetingStateChanged, setMeetingStateChanged] = useStateChanged({
    initialState,
    synchronisationMethod: SynchronisationMethod.MeetingStateChanged,
  });

  const skip = !meetingStateChanged.meetingId;
  const { data: meeting, refetch } = useGetMeetingQuery(
    {
      ...initialState,
      ...meetingStateChanged,
      customerToken,
      facilityObjectId,
    },
    {
      skip,
    },
  );

  useEffect(() => {
    if (!skip) {
      refetch();
    }
  }, [meetingStateChanged, skip]);

  useEffect(() => {
    if (
      meeting?.state === MeetingState.Closed &&
      meetingStateChanged.meetingId === meetingId
    ) {
      setDialogIfMeetingClosed(true);
    }
  }, [meeting?.state, meetingStateChanged]);

  return (
    <Fragment>
      {checked && (
        <Fragment>
          <TabBar>
            {isMeetingNotRunningOrClosed && (
              <Tab
                to="invitation"
                active={invitationActive}
                label={
                  customerRoles.includes(UserRoles.Employee)
                    ? "Einladung"
                    : "Einladung & Vollmacht"
                }
              />
            )}
            {(isEmployee || isMeetingNotRunningOrClosed) && (
              <Tab
                to={firstTabTo}
                active={
                  !participantsActive && !invitationActive && !eventLogActive
                }
                label={"Tagesordnung"}
              />
            )}
            {isEmployee && (
              <Tab
                to="participants"
                active={participantsActive}
                label="Teilnehmer"
              />
            )}
            {isEmployee && meetingState === MeetingState.Closed && (
              <Tab
                to="eventLog"
                active={eventLogActive}
                label="ETV Ereignis Protokoll"
              />
            )}
          </TabBar>
          <div className="etv-agenda">
            {!participantsActive && !invitationActive && !eventLogActive && (
              <AgendaNavigation
                items={data}
                selectedItemId={agendaItemId}
                meetingId={meetingId}
                navigationIsVisible={isAgendaNavigationVisible}
              />
            )}
            <Outlet context={context} />
          </div>
        </Fragment>
      )}
      {isDialogIfMeetingClosed &&
        !isEmployee &&
        meeting?.state === MeetingState.Closed &&
        meeting?.id === meetingId && (
          <AlertDialog
            description={
              <MeetingExitIcon className={"exit-from-meeting-icon"} />
            }
            title={dialogTitle}
            onConfirm={handleDialogClose}
            confirmLabel="OK"
          />
        )}
    </Fragment>
  );
}
