import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { USER_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import {
  MarkUserEventsAsReadRequest,
  PicturesForFirstLoginRequest,
  ProfileSettingsRequest,
  UnreadStoredUserEventsCountResponse,
  UserEvent,
  UserEventsListRequest,
  UserEventsMappedResponse,
  UserEventsResponse,
  UserPicture,
  UserPictureRequest,
  UserProfile,
  UserRequest,
  UserSettings,
  UserSettingsChangePasswordRequest,
  UserSettingsDeleteAccountRequest,
  UserSettingsEmailChangeRequest,
  UserSettingsRequest,
  UsersMyProfile,
} from "../types";
import moment from "moment";

export const userApi = createApi({
  reducerPath: USER_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: [
    "UserSettings",
    "ManagementWithObjects",
    "AssignedObjects",
    "UserProfile",
    "UserEvents",
    "UserEventsUnreadCount",
  ],
  endpoints: (build) => ({
    getUsersMyProfile: build.query<UsersMyProfile, void>({
      query: () => `/users/myProfile`,
      providesTags: [{ type: "UserProfile", id: "LIST" }],
    }),
    getUserEventsUnreadCount: build.query<number, UserRequest>({
      query: ({ userSid }) => `/users/${userSid}/userEvents/unread/count`,
      transformResponse: (response: UnreadStoredUserEventsCountResponse) => {
        return response.unreadStoredUserEventsCount;
      },
      providesTags: ["UserEventsUnreadCount"],
    }),
    getUserEvents: build.query<UserEventsMappedResponse, UserEventsListRequest>(
      {
        query: ({ userSid }) => `/users/${userSid}/userEvents`,
        transformResponse: ({
          storedUserEvents,
          affectedUserBriefs,
          timeStampUtc,
        }: UserEventsResponse): UserEventsMappedResponse => {
          return {
            affectedUserBriefs,
            timeStampUtc,
            storedUserEvents: storedUserEvents
              .map(({ eventType, isRead, payload }) => {
                try {
                  const data = JSON.parse(payload);
                  const entries = Object.entries(data).map(([key, value]) => {
                    return [
                      `${key.charAt(0).toLowerCase()}${key.slice(1)}`,
                      value,
                    ];
                  });

                  return {
                    eventType,
                    isRead,
                    payload: { ...Object.fromEntries(entries), isRead },
                  };
                } catch (e) {
                  console.log(e);
                  return;
                }
              })
              .filter((event) => Boolean(event))
              .sort((a, b) => {
                return a && b
                  ? moment(b.payload.raisedAtUtc).diff(a.payload.raisedAtUtc)
                  : 0;
              }) as UserEvent[],
          };
        },
        providesTags: [{ type: "UserEvents", id: "LIST" }],
      },
    ),
    markUserEventsListAsRead: build.mutation<void, MarkUserEventsAsReadRequest>(
      {
        query: ({ userSid, eventIds, readAtUtc }) => ({
          url: `/users/${userSid}/userEvents/markListAsRead`,
          method: "POST",
          body: { eventIds, readAtUtc },
        }),
        invalidatesTags: [
          { type: "UserEvents", id: "LIST" },
          "UserEventsUnreadCount",
        ],
      },
    ),
    getUserProfile: build.query<UserProfile, UserRequest>({
      query: ({ userSid }) => `/users/${userSid}/profile`,
    }),

    postUserProfile: build.mutation<void, ProfileSettingsRequest>({
      query: ({ userSid, userSettingsInfo }) => ({
        url: `/users/${userSid}/profile`,
        method: "POST",
        body: userSettingsInfo,
      }),
      invalidatesTags: [{ type: "UserProfile", id: "LIST" }],
    }),

    getUserSettings: build.query<UserSettings, string>({
      query: (userSid) => `/users/${userSid}/settings`,
      providesTags: [{ type: "UserSettings", id: "LIST" }],
    }),

    postUserSettings: build.mutation<UserSettings, UserSettingsRequest>({
      query: ({ userSid, userSettingsInfo }) => ({
        url: `/users/${userSid}/settings`,
        method: "POST",
        body: userSettingsInfo,
      }),
      invalidatesTags: [{ type: "UserSettings", id: "LIST" }],
    }),

    postEmailChange: build.mutation<
      UserSettings,
      UserSettingsEmailChangeRequest
    >({
      query: ({ userSid, email }) => ({
        url: `/users/${userSid}/requestEmailChange`,
        method: "POST",
        body: email,
      }),
    }),

    postChangePassword: build.mutation<
      { succeeded: boolean; errorDescription: string },
      UserSettingsChangePasswordRequest
    >({
      query: ({ userSid, changePassword }) => ({
        url: `/users/${userSid}/changepassword`,
        method: "POST",
        body: changePassword,
      }),
    }),

    postUserAccountDelete: build.mutation<
      { succeeded: boolean; errorDescription: string },
      UserSettingsDeleteAccountRequest
    >({
      query: ({ userSid, password }) => ({
        url: `/users/${userSid}/requestUserAccountDeleteEmail`,
        method: "POST",
        body: { password },
      }),
    }),

    postUserPicture: build.mutation<void, UserPicture>({
      query: ({ userSid, file }) => {
        const formData = new FormData();
        formData.append("content", file);
        return {
          url: `/users/${userSid}/profile/picture`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "UserProfile", id: "LIST" }],
    }),

    getProfilePicture: build.query<string, UserPictureRequest>({
      query: ({ userSid, ...params }) => ({
        url: `/users/${userSid}/profile/picture`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(URL.createObjectURL(blob));
          }
          return Promise.resolve(null);
        },
      }),
    }),
    getUserPicturesForFirstLogin: build.query<
      PicturesForFirstLoginRequest,
      void
    >({
      query: () => {
        return {
          url: `/users/defaultpictures`,
          method: "GET",
        };
      },
    }),

    getDefaultProfilPicture: build.query<File, { pictureName: string }>({
      query: ({ pictureName }) => ({
        url: `/users/defaultpictures/${pictureName}`,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            const file = new File([blob], pictureName, { type: blob.type });
            return Promise.resolve(file);
          }
          return Promise.resolve(null);
        },
      }),
    }),

    getProfilPictures: build.query<
      { pictureName: string; pictureSrc: string }[],
      PicturesForFirstLoginRequest
    >({
      queryFn: async ({ fileNames }, api, extraOptions, baseQuery) => {
        const pictures: { pictureName: string; pictureSrc: string }[] = [];
        fileNames &&
          (await Promise.all(
            fileNames.map((pictureName) => {
              return baseQuery({
                url: `/users/defaultpictures/${pictureName}`,
                responseHandler: async (response) => {
                  if (response.status === 200) {
                    const url = await response.url;
                    Promise.resolve(url).then((value) =>
                      pictures.push({
                        pictureName: pictureName,
                        pictureSrc: value,
                      }),
                    );
                    return Promise.resolve(url);
                  }
                  return Promise.resolve(null);
                },
              });
            }),
          ));

        return {
          data: pictures,
        };
      },
    }),
  }),
});

export const {
  useGetUserEventsUnreadCountQuery,
  useGetUserEventsQuery,
  useMarkUserEventsListAsReadMutation,
  useGetUserProfileQuery,
  useGetUserSettingsQuery,
  usePostUserSettingsMutation,
  usePostEmailChangeMutation,
  usePostChangePasswordMutation,
  usePostUserAccountDeleteMutation,
  useGetUsersMyProfileQuery,
  usePostUserProfileMutation,
  usePostUserPictureMutation,
  useGetUserPicturesForFirstLoginQuery,
  useGetProfilPicturesQuery,
  useGetDefaultProfilPictureQuery,
  useGetProfilePictureQuery,
} = userApi;
