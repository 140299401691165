import React from "react";
import { IconProps } from "../../../types";

export default function ServicesHeating({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M99.076 59.313 62.413 20.98a3.453 3.453 0 0 0-4.82 0L20.927 59.313a3.333 3.333 0 0 0 2.41 5.64h5c.92 0 1.667.746 1.667 1.666v28.333a5 5 0 0 0 5 5h49.999a5 5 0 0 0 5-5V66.62c0-.92.746-1.666 1.667-1.666h5a3.333 3.333 0 0 0 2.406-5.637v-.003zm-46.02 29.386a2.5 2.5 0 0 1-2.78-4.156 9.033 9.033 0 0 0 3.894-6.257 9.057 9.057 0 0 0-3.897-6.26 13.956 13.956 0 0 1-6.103-10.407 13.966 13.966 0 0 1 6.113-10.413 2.5 2.5 0 0 1 2.774 4.16 9.05 9.05 0 0 0-3.887 6.253 9.047 9.047 0 0 0 3.897 6.26 13.963 13.963 0 0 1 6.103 10.407 13.96 13.96 0 0 1-6.113 10.413zm20 0a2.5 2.5 0 0 1-2.78-4.156 9.033 9.033 0 0 0 3.894-6.257 9.057 9.057 0 0 0-3.897-6.26 13.956 13.956 0 0 1-6.103-10.407 13.966 13.966 0 0 1 6.113-10.413 2.5 2.5 0 1 1 2.773 4.16 9.05 9.05 0 0 0-3.886 6.253 9.047 9.047 0 0 0 3.896 6.26 13.963 13.963 0 0 1 6.104 10.407 13.96 13.96 0 0 1-6.114 10.413z"
        fillRule="nonzero"
      />
    </svg>
  );
}
