import React from "react";
import { IconProps } from "../../types";

export default function MenuDotsVertical({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m14.865 7.743.127-.007.155.008c.255.027.498.126.7.285l.115.103a1.36 1.36 0 1 1-1.097-.39zm.127 5.755.155.008c.255.027.498.126.7.285l.115.103a1.36 1.36 0 1 1-1.097-.39l.127-.006zm-.127 5.769.127-.007.155.007c.255.028.498.127.7.286l.115.103a1.36 1.36 0 1 1-1.097-.39z"
      />
    </svg>
  );
}
