import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";

import { useGetVotingResultQuery } from "../../../api/propositionApi";
import AgendaItemPropositionVotingResultSkeleton from "../../skeleton/AgendaItemPropositionVotingResultSkeleton";
import FetchApiError from "../../common/FetchApiError";

import PropositionVotingResultBar from "./PropositionVotingResultBar";
import PropositionVotingResultDetails from "./PropositionVotingResultDetails";

import PropositionNoVotingResult from "./PropositionNoVotingResult";
import { PropositionVotingType } from "../../../enums";

import "../../../styles/components/meetings/proposition/PropositionVotingResult.scss";

export default function PropositionVotingResult({
  propositionId,
  votingType,
}: Props): ReactElement {
  const {
    meetingId = "",
    customerToken = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const { data, isFetching, isSuccess, isError, error } =
    useGetVotingResultQuery({
      meetingId,
      agendaItemId,
      facilityObjectId,
      propositionId,
      customerToken,
    });

  return (
    <div className="agenda-item-proposition-voting-result">
      <h3 className="agenda-item-proposition-voting-result-headline">
        Ergebnisse
      </h3>
      {isFetching && <AgendaItemPropositionVotingResultSkeleton />}
      {isSuccess && data && (
        <div className="agenda-item-proposition-voting-result-items">
          <div
            className="agenda-item-proposition-voting-result-item"
            data-testid="voting-result-item"
          >
            <h4>
              {votingType === PropositionVotingType.Simple
                ? "Kopfprinzip"
                : data.qualificationVotingRule.name.split(":")[0]}
            </h4>
            <PropositionVotingResultBar
              qualificationValues={data.qualificationValues}
            />
            <PropositionVotingResultDetails
              qualificationVotingRule={data.qualificationVotingRule}
              qualificationValues={data.qualificationValues}
              totalAttendedVotes={data.totalAttendedVotes}
              totalEligibleVotes={data.totalEligibleVotes}
            />
          </div>
          {data.doubleQualificationValues &&
            data.doubleQualificationVotingRule && (
              <div
                className="agenda-item-proposition-voting-result-item"
                data-testid="voting-result-item"
              >
                <h4>{data.doubleQualificationVotingRule.name.split(":")[0]}</h4>
                <PropositionVotingResultBar
                  qualificationValues={data.doubleQualificationValues}
                />
                <PropositionVotingResultDetails
                  qualificationVotingRule={data.doubleQualificationVotingRule}
                  qualificationValues={data.doubleQualificationValues}
                  totalAttendedVotes={data.totalAttendedVotes}
                  totalEligibleVotes={data.totalEligibleVotes}
                />
              </div>
            )}
        </div>
      )}
      {isError && (
        <FetchApiError
          error={error}
          notFoundElement={<PropositionNoVotingResult />}
        />
      )}
    </div>
  );
}

type Props = {
  propositionId: string;
  preliminaryRemark?: string;
  votingType?: PropositionVotingType;
};
