import useFetchNotifications from "../notifications/useFetchNotifications";
import { useMemo } from "react";
import { UserEventType } from "../../enums";
import { FacilityDocumentPermissionsGrantedUserEventModel } from "../../types";

export default function useDocumentsNotificationDocumentIds(read = false) {
  const { userEvents } = useFetchNotifications();

  return useMemo(() => {
    const documentsEvents = userEvents
      .filter(
        ({ eventType, isRead }) =>
          [
            UserEventType.FacilityDocumentContractualRelationPermissionsGrantedUserEvent,
            UserEventType.FacilityDocumentPersonPermissionsGrantedUserEvent,
          ].includes(eventType) && isRead === read,
      )
      .map(
        ({ payload }) => payload,
      ) as unknown as FacilityDocumentPermissionsGrantedUserEventModel[];
    return documentsEvents.map(
      ({ facilityObjectOnlineDocumentId }) => facilityObjectOnlineDocumentId,
    );
  }, [userEvents]);
}
