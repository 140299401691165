import React, { ReactElement } from "react";
import "../../../styles/components/meetings/participants/ParticipantsPageAlertDialogDescriptionInstructions.scss";
import { ParticipantsWithVoteInstructions } from "../../../types";
import ParticipantItemForInstructions from "./ParticipantItemForInstructions";
import { useGetAgendaItemsQuery } from "../../../api/agendaItemApi";
import { useParams } from "react-router-dom";

export default function ParticipantsPageAlertDialogDescriptionInstructions({
  instructions,
}: Props): ReactElement {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const { data: agendaItems } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    includePropositions: true,
  });

  return (
    <section className="participants-instructions-wrapper">
      {instructions?.map((instruction) => (
        <ParticipantItemForInstructions
          key={instruction.voteDirective?.createdByUserSid}
          instruction={instruction}
          agendaItems={agendaItems}
        />
      ))}
    </section>
  );
}

type Props = {
  instructions?: ParticipantsWithVoteInstructions[];
};
