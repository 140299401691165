import {
  BulletinBoardPostCommentCreatedUserEventModel,
  NotificationProps,
} from "../../types";
import { getShortenText } from "../../lib/notifications";
import NotificationItemDate from "./NotificationItemDate";
import { Link } from "react-router-dom";
import { ReactElement } from "react";

export default function BulletinBoardPostCommentCreatedUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): ReactElement {
  const { facilityObjectId, customerToken, raisedAtUtc, postId, text } =
    userEventData as BulletinBoardPostCommentCreatedUserEventModel;
  return (
    <Link
      to={`/${customerToken}/objekte/${facilityObjectId}/schwarzesbrett/${postId}`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong>{" "}
        <span>hat einen Beitrag kommentiert.</span>
        <div className="notification-item-content-text">
          {getShortenText(text)}
        </div>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </Link>
  );
}
