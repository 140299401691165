import { useCallback, useState, useMemo } from "react";
import { getDocument } from "pdfjs-dist";

const pdfCache = new Map<string, string>();

export const usePdfToImage = ({ file }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>("");

  const cacheKey = useMemo(() => file.name, [file.name]);

  const convertPdfToImage = useCallback(async () => {
    if (!file) return;

    if (pdfCache.has(cacheKey)) {
      setImageSrc(pdfCache.get(cacheKey) || "");
      return;
    }

    try {
      const buffer = await file.arrayBuffer();
      const pdf = await getDocument(buffer).promise;
      const canvas = document.createElement("canvas");
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
        viewport,
      };

      await page.render(renderContext).promise;

      const imageUrl = canvas.toDataURL("image/png");

      pdfCache.set(cacheKey, imageUrl);

      setImageSrc(imageUrl);
    } catch (e) {
      console.error(e);
    }
  }, [file, cacheKey]);

  return { imageSrc, convertPdfToImage };
};

type Props = {
  file: File;
};
