import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { ParticipantAttendanceType, PictureFillStrategy } from "../../enums";

import "../../styles/components/common/ProfilePicture.scss";
import UserOnlineStateBadge from "./UserOnlineStateBadge";
import { useGetProfilePictureQuery } from "../../api/userApi";
import UserIcon from "../icons/UserIcon";
import { Participant } from "../../types";

export default function ProfilePicture(props: Props): JSX.Element {
  const [skip, setSkip] = useState(true);
  const {
    width = 64,
    height = 64,
    alt = "profile picture",
    userSid,
    rounded,
    onlineState,
    fillStrategy,
    delegatedPerson,
    isDelegate,
  } = props;

  const { data, isFetching } = useGetProfilePictureQuery(
    {
      userSid: userSid
        ? String(userSid)
        : "00000000-0000-0000-0000-000000000000",
      width,
      height,
      fillStrategy,
    },
    { skip },
  );

  const [ref, { entry }] = useIntersectionObserver();

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      setSkip(false);
    }
  }, [entry]);

  const className = classNames({
    "profile-picture": true,
    "profile-picture-rounded": rounded,
  });

  return (
    <div className={className} data-testid="profile-picture" ref={ref}>
      {(isFetching || !data) && <UserIcon width={width} height={height} />}
      {data && (
        <img
          src={data}
          alt={alt}
          title={alt}
          style={{ width, height }}
          className="profile-picture-image"
          data-testid="profile-picture-image"
        />
      )}
      {onlineState !== undefined && (
        <UserOnlineStateBadge
          onlineState={onlineState}
          showHint
          delegatedPerson={delegatedPerson}
          isDelegate={isDelegate}
        />
      )}
    </div>
  );
}

type Props = {
  width?: number;
  height?: number;
  rounded?: boolean;
  userSid?: string | number;
  onlineState?: ParticipantAttendanceType;
  fillStrategy?: PictureFillStrategy;
  alt?: string;
  delegatedPerson?: Participant;
  isDelegate?: boolean;
};
