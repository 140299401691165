import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import {
  FacilityObjectType,
  PictureFillStrategy,
  UserRoles,
} from "../../enums";
import FacilityObjectPicture from "../common/FacilityObjectPicture";
import useCustomerRoles from "../../hooks/useCustomerRoles";

import "../../styles/components/home/ObjectsListCard.scss";
import { FacilityObject } from "../../types";

export default function ObjectsListCard(props: Props): ReactElement {
  const { street, city, zipCode, id, facilityObjectType, token } = props;

  const customerRoles = useCustomerRoles({
    customerToken: token,
    facilityObjectId: id.toString(),
  });

  const linkTo =
    customerRoles.length === 1 && customerRoles[0] === UserRoles.ServiceProvider
      ? `/${token}/objekte/${id}/nachrichten`
      : `/${token}/objekte/${id}/schwarzesbrett`;

  const devicePixelRatio = window.devicePixelRatio || 1;
  const width = Math.round(470 * devicePixelRatio);
  const height = Math.round(192 * devicePixelRatio);

  return (
    <Link
      role="link"
      className="object-list-card"
      data-testid="object-card"
      to={linkTo}
    >
      <FacilityObjectPicture
        facilityObjectId={String(id)}
        width={width}
        height={height}
        fillStrategy={PictureFillStrategy.FitAndConvert}
        customerToken={token}
      />

      <div className="object-list-card-info-wrapper">
        <div className="object-list-card-info">
          <span>{street}</span>
          <span className="object-list-card-city">
            {zipCode} {city}
          </span>
        </div>
        {facilityObjectType ===
          FacilityObjectType.ResidentialCommunityOfOwners && (
          <span className="object-type">WEG</span>
        )}
        {facilityObjectType === FacilityObjectType.PartOwnership && (
          <span className="object-type">SEV</span>
        )}
      </div>
    </Link>
  );
}

type Props = {
  onClick?: () => void;
} & FacilityObject;
