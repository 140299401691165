import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FacilityObject } from "../../types";
import FacilityObjectsTableItem from "./FacilityObjectsTableItem";

import usePagination from "../../hooks/usePagination";
import IconButton from "../common/IconButton";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";

import "../../styles/components/management/FacilityObjectsTable.scss";
import NoMeetings from "../icons/NoMeeting";
export default function FacilityObjectsTable({
  facilityObjects,
  isFetching,
}: Props): ReactElement {
  const [sort, setSort] = useState<{
    sortBy: "number" | "isOnline" | "description";
    direction: "descending" | "ascending";
  }>({
    sortBy: "number",
    direction: "descending",
  });

  const handleSort = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      const target = e.currentTarget;
      const sortBy = target.getAttribute("aria-label") || "number";
      const direction = target.getAttribute("aria-sort") || "descending";

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSort({ direction, sortBy });
    },
    [setSort],
  );

  const sortedObjects = useMemo(
    () =>
      facilityObjects.slice().sort((a, b) => {
        const { sortBy, direction } = sort;
        const compareA = a[sortBy];
        const compareB = b[sortBy];
        if (compareA !== null && compareB !== null && compareA > compareB) {
          return direction === "ascending" ? -1 : 1;
        }
        if (compareA !== null && compareB !== null && compareA < compareB) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }),
    [sort, facilityObjects],
  );

  const { prev, next, hasNextPage, hasPrevPage, pageItems, reset } =
    usePagination(sortedObjects, { pageSize: 50 });

  useEffect(() => {
    reset();
  }, [sortedObjects]);

  return (
    <div role="table" className="management-facility-objects-table">
      <div className="management-facility-objects-table-header" role="row">
        <div
          className="management-facility-objects-table-header-id"
          role="columnheader"
          aria-label="number"
          aria-sort={
            sort.sortBy === "number"
              ? sort.direction === "descending"
                ? "ascending"
                : "descending"
              : undefined
          }
          onClick={handleSort}
        >
          #
        </div>
        <div
          className="management-facility-objects-table-header-description"
          role="columnheader"
          data-testid="sort-description"
          aria-label="description"
          aria-sort={
            sort.sortBy === "description"
              ? sort.direction === "descending"
                ? "ascending"
                : "descending"
              : undefined
          }
          onClick={handleSort}
        >
          Beschreibung
        </div>
        <div
          className="management-facility-objects-table-header-state"
          role="columnheader"
          aria-label="isOnline"
          aria-sort={
            sort.sortBy === "isOnline"
              ? sort.direction === "descending"
                ? "ascending"
                : "descending"
              : undefined
          }
          onClick={handleSort}
        >
          Status
        </div>
        <div
          className="management-facility-objects-table-header-user-entries"
          role="columnheader"
        >
          Nutzerbeiträge
        </div>
        <div
          className="management-facility-objects-table-header-user-entries"
          role="columnheader"
        >
          Nutzerkommentare
        </div>
      </div>
      <div className="management-facility-objects-table-body">
        {!isFetching && facilityObjects.length === 0 && (
          <div className="management-facility-objects-table-body-no-result">
            <NoMeetings height="50vh" />
            <h2 className="management-facility-objects-table-body-no-result-headline">
              Keine Objekte gefunden
            </h2>
          </div>
        )}
        {pageItems.map((facilityObject) => (
          <FacilityObjectsTableItem
            facilityObject={facilityObject}
            key={facilityObject.id}
          />
        ))}
      </div>
      <div className="management-facility-objects-table-footer" role="row">
        <IconButton
          icon={ArrowLeft}
          onClick={prev}
          disabled={!hasPrevPage}
          grey
        />
        <IconButton
          icon={ArrowRight}
          onClick={next}
          disabled={!hasNextPage}
          grey
        />
      </div>
    </div>
  );
}

type Props = {
  facilityObjects: FacilityObject[];
  isFetching: boolean;
};
