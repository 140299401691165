import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

import Protocol from "../icons/Protocol";
import ActionView from "../icons/ActionView";

import "../../styles/components/skeleton/MeetingListSkeleton.scss";

export default function MeetingListSkeleton({ count }: Props): JSX.Element {
  const items = new Array(count).fill(null);

  return (
    <Fragment>
      <h3 className="meeting-list-skeleton-headline" data-testid="headline">
        <Skeleton />
      </h3>
      <section className="meeting-list-skeleton" role="list-skeleton">
        {items.map((v, key) => (
          <div
            className="meeting-list-item-skeleton"
            role="listitem-skeleton"
            key={key}
          >
            <Protocol />
            <div className="meeting-list-item-skeleton-content">
              <h4 className="meeting-list-item-skeleton-headline">
                <Skeleton />
              </h4>
              <small className="meeting-list-item-skeleton-info">
                <Skeleton />
              </small>
            </div>
            <div className="meeting-list-item-skeleton-actions">
              <ActionView className="meeting-list-item-skeleton-actions-view" />
            </div>
          </div>
        ))}
      </section>
    </Fragment>
  );
}

type Props = {
  count: number;
};
