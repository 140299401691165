import { MeetingState } from "../../enums";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { meetingApi } from "../../api/meetingApi";
import { useSelector } from "react-redux";

export default function useMeetingState(id?: string): MeetingState {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const meetingsStateSelector = useMemo(
    () =>
      meetingApi.endpoints.getMeetings.select({
        customerToken,
        facilityObjectId,
      }),
    [meetingId, customerToken, facilityObjectId],
  );
  const selectedMeetingId = id ? id : meetingId;
  const { data = [] } = useSelector(meetingsStateSelector);
  const meeting = data.find(({ id }) => id === selectedMeetingId);

  return meeting ? meeting.state : MeetingState.Unknown;
}
