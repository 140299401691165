import React, { ReactElement, useCallback, useState } from "react";
import "../../../styles/components/management/objectSettings/OnlineIdentityItem.scss";
import { OnlineIdentity, StakeholdersInfo } from "../../../types";
import { useGetUserProfileQuery } from "../../../api/userApi";
import Menu from "../../common/Menu";
import MenuListItem from "../../common/menu/MenuListItem";
import MenuListDiver from "../../common/menu/MenuListDiver";
import IconButton from "../../common/IconButton";
import ThreeDots from "../../icons/ThreeDots";
import Send from "../../icons/Send";
import { Form } from "react-final-form";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import SendInvitationDialogDescription from "./SendInvitationDialogDescription";
import { useSendRegistrationCodeByEmailMutation } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import AlertDialog from "../../common/dialog/AlertDialog";
import moment from "moment/moment";
import classNames from "classnames";

export default function OnlineIdentityItem({
  onlineIdentityInfo,
  isActivePropertyStakeholder,
}: Props): ReactElement {
  const { data: userProfile } = useGetUserProfileQuery({
    userSid: onlineIdentityInfo.userSid,
  });
  const [sendRegistrationCodeByEmail] =
    useSendRegistrationCodeByEmailMutation();
  const { customerToken = "" } = useParams();
  const [isSendInvitationDialogOpen, setSendInvitationDialog] = useState(false);

  const handleSetInvitationDialog = () => {
    setSendInvitationDialog(true);
  };
  const formattedDate = moment(onlineIdentityInfo.createDateUTC).format(
    "DD.MM.YYYY",
  );
  const button = <IconButton icon={ThreeDots} />;
  const [isSubmitErrorsText, setSubmitErrorsText] = useState(false);
  const [isSubmitDialogOpen, setSubmitDialog] = useState(false);
  const handleFormSubmit = useCallback(async ({ eMail }: { eMail: string }) => {
    const response = await sendRegistrationCodeByEmail({
      customerToken,
      personId: isActivePropertyStakeholder.addressId,
      email: eMail,
      issuedFor: userProfile?.firstName + " " + userProfile?.lastName,
    });

    setSendInvitationDialog(false);
    if (response && "data" in response) {
      const isSuccess = response.data;
      if (isSuccess) {
        setSubmitErrorsText(false);
        setSubmitDialog(true);
      }
    } else if (response && "error" in response) {
      setSubmitErrorsText(true);
      setSubmitDialog(false);
    }
  }, []);

  const invited =
    !onlineIdentityInfo.active && onlineIdentityInfo.email !== null
      ? "Eingeladen"
      : "Registriert";

  const className = classNames({
    active: onlineIdentityInfo.active,
    inactive: !onlineIdentityInfo.active,
  });
  return (
    <section className="online-identity-list-item">
      <div className="name-wrapper">
        <div className="provider-name" data-testid="provider-name" role="cell">
          <div>
            {userProfile?.firstName} {userProfile?.lastName}
          </div>
        </div>
      </div>
      <div className="email" role="cell">
        <div>{onlineIdentityInfo.email}</div>
      </div>
      <div className="account" role="cell">
        <div className={className}>
          {onlineIdentityInfo.active ? "Aktiv" : "Inaktiv"}
        </div>
      </div>
      <div className="status" role="cell">
        <div className={className}>{invited}</div>
      </div>
      <div className="reg-code" role="cell">
        <div>{onlineIdentityInfo.registrationCodeUsed}</div>
      </div>
      <div className="code-created" role="cell">
        <div>{formattedDate}</div>
      </div>
      <Menu button={button} className="provider-item-menu">
        <MenuListItem
          leadingIcon={<Send className="menu-icon" />}
          onClick={handleSetInvitationDialog}
        >
          Einladung senden
        </MenuListItem>
        <MenuListDiver />
      </Menu>

      {isSendInvitationDialogOpen && (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={{
            eMail: "",
          }}
          render={({ handleSubmit }) => {
            return (
              <ConfirmDialog
                description={
                  <SendInvitationDialogDescription
                    personId={isActivePropertyStakeholder.addressId}
                    isSubmitErrorsText={isSubmitErrorsText}
                  />
                }
                confirmLabel="Senden"
                title="Einladung versenden"
                onConfirm={handleSubmit}
                cancelLabel="Abbrechen"
                onCancel={() => setSendInvitationDialog(false)}
              />
            );
          }}
        />
      )}

      {!isSubmitErrorsText &&
        !isSendInvitationDialogOpen &&
        isSubmitDialogOpen && (
          <AlertDialog
            title="Einladung versenden"
            onConfirm={() => setSubmitDialog(false)}
            description="Die Einladung wurde erfolgreich versendet."
          />
        )}
    </section>
  );
}
type Props = {
  onlineIdentityInfo: OnlineIdentity;
  isActivePropertyStakeholder: StakeholdersInfo;
};
