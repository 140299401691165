import React from "react";
import { IconProps } from "../../types";

export default function Bell({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M19.1 16.6v-4a4.6 4.6 0 0 0-9.2 0v4c0 .5-.108.973-.3 1.4h9.8c-.192-.427-.3-.9-.3-1.4zm3.4 3.4h-16c-1.333 0-1.333-2 0-2a1.4 1.4 0 0 0 1.4-1.4v-4a6.6 6.6 0 0 1 13.2 0v4a1.4 1.4 0 0 0 1.4 1.4c1.333 0 1.333 2 0 2zm-5.751 2.702a2.6 2.6 0 0 1-4.498 0 1 1 0 0 1 .865-1.502h2.768a1 1 0 0 1 .865 1.502z"
      />
    </svg>
  );
}
