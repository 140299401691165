import React from "react";
import { IconProps } from "../../types";

export default function Pencil({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M18.111 9.414 9 18.525V21h2.475l9.11-9.111-2.474-2.475zm.707-2.121 3.89 3.889a1 1 0 0 1 0 1.414L12.595 22.707a1 1 0 0 1-.707.293H8a1 1 0 0 1-1-1v-3.889a1 1 0 0 1 .293-.707L17.404 7.293a1 1 0 0 1 1.414 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}
