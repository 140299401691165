import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React, { Fragment } from "react";
import useFetchDocuments from "../../hooks/useFetchDocuments";
import Dot from "../icons/Dot";
import IconDocument from "./IconDocument";
import { Link } from "react-router-dom";
import DashboardDocumentItemSkeleton from "../skeleton/DashboardDocumentItemSkeleton";
import moment from "moment";
import { getContentSize } from "../../lib";
import useOpenDocument from "../../hooks/documents/useOpenDocument";
import DocumentOpenDialog from "../documents/DocumentOpenDialog";

export default function Documents(): JSX.Element {
  const { data, isFetching } = useFetchDocuments();
  const { isDocumentFetching, selectedDocument, handleSetDocumentId } =
    useOpenDocument(data);

  return data.length > 0 ? (
    <div className="dashboard-right-container-item">
      <Link
        className="dashboard-right-container-item-title-container"
        to="../dokumente"
      >
        <h3 className="dashboard-right-container-item-title">NEUE DOKUMENTE</h3>
        <span>Alle...</span>
      </Link>
      <div>
        {data.map((item) => (
          <div
            className="dashboard-right-container-item-documents"
            key={item.documentId}
            data-testid="document-item"
            id={item.documentId}
            onClick={handleSetDocumentId}
          >
            <div className="icon">
              <IconDocument type={item.contentType} />
            </div>
            <div className="dashboard-right-container-item-documents-info">
              <span>{item.originalFileName}</span>
              <div className="size">
                <span>
                  {moment.utc(item.createDateUTC).local().format("L")}
                </span>
                <Dot className="size-dot" />
                <span>
                  {getContentSize(item.contentSize).count.toFixed(1)}{" "}
                  {getContentSize(item.contentSize).size}{" "}
                </span>
              </div>
            </div>
          </div>
        ))}
        {isFetching && (
          <Fragment>
            <DashboardDocumentItemSkeleton />
            <DashboardDocumentItemSkeleton />
            <DashboardDocumentItemSkeleton />
          </Fragment>
        )}
        {isDocumentFetching && selectedDocument && (
          <DocumentOpenDialog document={selectedDocument} />
        )}
      </div>
    </div>
  ) : (
    <Fragment />
  );
}
