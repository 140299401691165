import { SerializedError } from "@reduxjs/toolkit";
import moment from "moment";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useGetUserEventsQuery } from "../../api/userApi";
import { UserEvent, User } from "../../types";
import { mapUsersToSids } from "../../lib/notifications";
import useGetUsersMyProfile from "../useGetUsersMyProfile";

export default function useFetchNotifications(): useFetchNotifications {
  const { user } = useGetUsersMyProfile();
  const userSid = user.sid ?? "";

  const {
    data: eventsData = {
      timeStampUtc: moment.utc().toDate(),
      storedUserEvents: [],
      affectedUserBriefs: [],
    },
    isLoading: isLoading,
    isFetching,
    refetch,
    isError: isError,
    error: error,
  } = useGetUserEventsQuery(
    { userSid, maxCount: null },
    { skip: !userSid || userSid === "00000000-0000-0000-0000-000000000000" },
  );

  return {
    userEvents: eventsData.storedUserEvents,
    userBriefs: mapUsersToSids(eventsData.affectedUserBriefs),
    isFetching,
    isLoading: isLoading,
    isError,
    error,
    refetch,
  };
}

type useFetchNotifications = {
  userEvents: UserEvent[];
  userBriefs: Record<string, User>;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  error: SerializedError | FetchBaseQueryError | undefined;
};
