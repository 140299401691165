import { Field, Form } from "react-final-form";
import React, {
  Fragment,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { FORM_ERROR } from "final-form";

import { useSynchronisation } from "../../../hooks/useSynchronisation";
import Button from "../../common/Button";
import IconButton from "../../common/IconButton";
import { TextAreaForwardRef } from "../../common/form/TextArea";
import Pencil from "../../icons/Pencil";

import {
  MeetingState,
  PropositionVotingState,
  SynchronisationMethod,
  UserRoles,
} from "../../../enums";
import useUpdateProposition from "../../../hooks/meetings/useUpdateProposition";
import LoadingSpinner from "../../icons/LoadingSpinner";

import "../../../styles/components/meetings/proposition/PropositionUpdateForm.scss";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import { useInvalidateVoteInstructionsMutation } from "../../../api/propositionApi";
import { useParams } from "react-router-dom";
import InfoText from "../../userSettings/InfoText";
import { useDispatch } from "react-redux";
import { isPropositionEditing } from "../../../slices/agendaItemEditingProcessSlice";
import useInitialMeetingItemAttachments from "../../../hooks/meetings/useInitialMeetingItemAttachments";
import MeetingAttachmentList from "../attachments/MeetingAttachmentList";
import FilePreview from "../../common/form/FilePreview";
import FileInput from "../../common/form/FileInput";
import {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
} from "../../../lib/formValidate";

export default function PropositionUpdateForm({
  initialValues,
  meetingState,
  roles,
  votingState,
  id,
  showAttachments = false,
}: Props): ReactNode {
  const [readOnly, setReadOnly] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const updateElement: RefObject<HTMLTextAreaElement> = useRef(null);
  const [currentElementHeight, setCurrentElementHeight] = useState(0);
  const componentRef = useRef<HTMLDivElement>(null);
  const { update, isLoading } = useUpdateProposition(id);
  const { sync } = useSynchronisation();
  const [invalidateVoteInstructions] = useInvalidateVoteInstructionsMutation();
  const { initialAttachmentValues, initialItems } =
    useInitialMeetingItemAttachments(!showAttachments, id);
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: { [key: string]: string }) => {
      const result = await update(
        {
          updateValues: values,
          initialValues,
        },
        initialItems,
      );
      if (result?.error) {
        return { [FORM_ERROR]: "Beschluss konnte nicht gespeichert werden!" };
      } else {
        if (!readOnly) {
          setDialogOpen(true);
        }
        setReadOnly(true);
        sync(SynchronisationMethod.PropositionChanged);
      }
    },
    [readOnly, initialAttachmentValues, initialItems],
  );

  const handleEditButton = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setReadOnly(false);
      if (updateElement.current) {
        updateElement.current.focus();
      }
    },
    [readOnly, updateElement],
  );

  const handleInvalidateVoteInstructions = useCallback(async () => {
    await invalidateVoteInstructions({
      customerToken,
      meetingId,
      facilityObjectId,
      agendaItemId,
      propositionId: id,
    });
    setDialogOpen(false);
  }, [isDialogOpen]);

  const formClassName = classNames({
    "proposition-update-form": true,
    "proposition-update-form-readonly": readOnly,
  });

  const handleCancelButton = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setReadOnly(true);
    },
    [readOnly],
  );

  const isVotingStateNotRunning =
    votingState === PropositionVotingState.None ||
    votingState === PropositionVotingState.Closed;

  const shouldRenderEditButton =
    meetingState !== MeetingState.Closed &&
    roles.includes(UserRoles.Employee) &&
    isVotingStateNotRunning &&
    readOnly;

  const [fieldName] = Object.keys(initialValues);

  useEffect(() => {
    if (componentRef.current) {
      setCurrentElementHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef, readOnly]);

  useEffect(() => {
    if (!readOnly) {
      setReadOnly(true);
    }
  }, [id]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, attachments: initialAttachmentValues }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        error,
        values,
        submitSucceeded,
        dirty,
        form,
      }) => {
        useEffect(() => {
          if (dirty) {
            dispatch(isPropositionEditing(true));
          } else {
            dispatch(isPropositionEditing(false));
          }
          if (dirty && readOnly) {
            form.restart();
          }
        }, [dirty, readOnly]);

        return (
          <Fragment>
            <form
              onSubmit={handleSubmit}
              className={formClassName}
              data-testid="proposition-update-form"
            >
              {readOnly && (
                <div className={"proposition-update-form-read-only-wrapper"}>
                  <div
                    className="proposition-update-form-field"
                    ref={componentRef}
                  >
                    {values[fieldName]}
                  </div>
                  {submitSucceeded && !error && (
                    <InfoText
                      isSuccess={true}
                      textSuccess={"Erfolgreich gespeichert"}
                    />
                  )}
                </div>
              )}
              {!readOnly && (
                <Fragment>
                  <Field
                    component={TextAreaForwardRef}
                    name={fieldName}
                    className="proposition-update-form-field"
                    maxLength={fieldName === "title" ? 100 : undefined}
                    style={{ height: currentElementHeight }}
                    ref={updateElement}
                  />
                  {showAttachments && (
                    <div className={"proposition-update-form-edit-attachments"}>
                      <Field
                        component={FilePreview}
                        name="attachments"
                        subscription={{
                          error: true,
                          value: true,
                          touched: true,
                        }}
                      />
                      <Field
                        component={FileInput}
                        name="attachments"
                        width={32}
                        height={32}
                        validate={validateFiles(
                          validateFileType(),
                          validateFileFirstBytes(),
                        )}
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {shouldRenderEditButton && (
                <IconButton onClick={handleEditButton} icon={Pencil} />
              )}
              {error && (
                <div className="proposition-update-form-error">error</div>
              )}
              {!readOnly && (
                <div
                  className="proposition-update-form-actions"
                  data-testid="proposition-update-form-actions"
                >
                  <Button
                    leadingIcon={isLoading ? LoadingSpinner : Pencil}
                    label="Fertig"
                    lightblue
                    type="submit"
                    disabled={pristine || submitting}
                  />
                  <Button label="Abbrechen" onClick={handleCancelButton} />
                </div>
              )}
            </form>
            {isDialogOpen && (
              <ConfirmDialog
                description={""}
                title="Die erteilten Weisungen für diese Abstimmung bleiben trotz der Änderungen am Antrag weiterhin gültig.
                     Wenn Sie auf 'Weisungen ungültig', werden alle bisher erteilten Weisungen für diese Abstimmung deaktiviert."
                onConfirm={handleInvalidateVoteInstructions}
                confirmLabel="Weisungen ungültig"
                onCancel={() => setDialogOpen(false)}
                cancelLabel="Weisung weiterhin gültig"
              />
            )}
            {showAttachments && readOnly && (
              <MeetingAttachmentList
                agendaItemId={agendaItemId}
                propositionId={id}
                attachments={initialAttachmentValues}
              />
            )}
          </Fragment>
        );
      }}
    />
  );
}

type Props = {
  initialValues:
    | {
        decision: string;
      }
    | {
        title: string;
      };
  roles: UserRoles[];
  meetingState: MeetingState;
  votingState: PropositionVotingState;
  id: string;
  showAttachments?: boolean;
};
