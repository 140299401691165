import { ReactElement, useEffect } from "react";
import useFetchMessageDetails from "../../hooks/messages/useFetchMessageDetails";
import { Activity, Identity } from "../../types";
import FetchApiError from "../common/FetchApiError";
import FileUploadPreview from "../common/FileUploadPreview";

import MessageAnswerCreateForm from "./MessageAnswerCreateForm";
import MessageDetailsCustomFields from "./MessageDetailsCustomFields";
import MessageDetailsNotSelected from "./MessageDetailsNotSelected";
import MessageDetailsHeader from "./MessageDetailsHeader";

import MessageDetailsLoading from "./MessageDetailsLoading";
import MessageDetailsDocuments from "./MessageDetailsDocuments";
import MessageDetailsContacts from "./MessageDetailsContacts";

import useLightbox from "../../hooks/useLightbox";

import "../../styles/components/messages/MessageDetails.scss";

export default function MessageDetails({
  messageId,
  selectedMessage,
}: Props): ReactElement {
  const {
    activity,
    participants,
    contacts,
    documents,
    isFetching,
    activityError,
    participantsError,
    contactsError,
    isActivityError,
    isParticipantsError,
    isContactsError,
  } = useFetchMessageDetails();
  const { initialize } = useLightbox();

  const isError = isActivityError || isParticipantsError || isContactsError;
  const error = activityError || participantsError || contactsError;
  const hasMessages =
    (!!messageId &&
      Boolean(contacts.find((contact) => contact.activityId === messageId))) ||
    !isFetching;
  const hasDocuments =
    !!messageId &&
    Boolean(documents.find((document) => document.activityId === messageId));

  useEffect(() => {
    initialize();
  }, [messageId]);

  return (
    <div className="message-details">
      {selectedMessage && (
        <MessageDetailsHeader
          selectedMessage={selectedMessage}
          participants={participants}
        />
      )}
      <div className="message-details-content">
        {!!messageId &&
          activity &&
          activity.customFields &&
          activity.customFields.length > 0 && (
            <MessageDetailsCustomFields customFields={activity.customFields} />
          )}
        {hasDocuments && (
          <MessageDetailsDocuments
            documents={documents}
            headline="Dokumente"
            showUploadDate
            collapsable
            isMessageDetailsDocuments
          />
        )}
        {isError && <FetchApiError error={error} />}
        {!messageId && <MessageDetailsNotSelected />}
        {!!messageId && !hasMessages && <MessageDetailsLoading />}
        {!!messageId && hasMessages && (
          <MessageDetailsContacts contacts={contacts} />
        )}
      </div>
      <FileUploadPreview referenceId={activity?.id || ""} />
      {!!messageId && <MessageAnswerCreateForm />}
    </div>
  );
}

type Props = {
  identity?: Identity;
  selectedMessage?: Activity;
  messageId: string;
};
