import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLastLocation } from "react-router-dom-last-location";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/WelcomePage.scss";
import {
  useGetDefaultProfilPictureQuery,
  useGetProfilPicturesQuery,
  usePostUserPictureMutation,
} from "../../api/userApi";
import useGetPicturesFirstLogin from "../../hooks/useGetPicturesFirstLogin";
import ProfilePictureForUser from "../common/ProfilePictureForUser";
import Button from "../common/Button";
import Uploader from "../common/files/Uploader";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import { useNavigate } from "react-router-dom";
import ProfilePictureSettings from "../common/ProfilePictureSettings";
import PictureProfilePreview from "../common/PictureProfilePreview";
import ProfilePictureSkeleton from "../skeleton/ProfilePictureSkeleton";
import { useGetLoginInfoQuery } from "../../api/homecaseMvcApi";

export default function WelcomePage(): JSX.Element {
  const [isSubmitErrorsText, setSubmitErrorsText] = useState(false);
  const [pathnameLast, setPathnameLast] = useState("/");
  const [isProfilePicture, setProfilePicture] = useState<File | undefined>(
    undefined,
  );
  const [isAvatar, setAvatar] = useState<
    { pictureName: string; pictureSrc: string } | undefined
  >(undefined);
  const { lastLocation } = useLastLocation();
  const { user } = useGetUsersMyProfile();
  const navigate = useNavigate();
  const defaultPicturesList = useGetPicturesFirstLogin();
  const { data: isLoggedIn } = useGetLoginInfoQuery();

  const { data: pictures, isFetching } = useGetProfilPicturesQuery(
    defaultPicturesList,
    { skip: !isLoggedIn },
  );
  const [postUserPicture, { isSuccess, isError }] =
    usePostUserPictureMutation();

  const { data: pictureFile } = useGetDefaultProfilPictureQuery({
    pictureName: isAvatar ? isAvatar.pictureName : "",
  });

  const lastLocationName =
    pathnameLast === undefined || pathnameLast.endsWith("/");
  const to = lastLocationName ? "../" : pathnameLast;
  const backTo = {
    to: to ? to : "/",
    label: lastLocationName ? "Zurück zu HOMECASE" : "Zurück",
  };

  useEffect(() => {
    if (lastLocation) {
      setPathnameLast(lastLocation?.pathname);
    }
  }, [lastLocation?.pathname]);

  const handleAvatarUpload = useCallback((avatar: File) => {
    setProfilePicture(avatar);
    setAvatar(undefined);
  }, []);

  const handlePostPictureProfile = useCallback(async () => {
    if (isProfilePicture && !isAvatar) {
      await postUserPicture({
        userSid: user.sid,
        file: isProfilePicture,
      });
    }

    if (isAvatar && pictureFile) {
      await postUserPicture({
        userSid: user.sid,
        file: pictureFile,
      });
    }

    if (!isAvatar && !isProfilePicture) {
      navigate("/profil/bearbeiten");
    }
  }, [isProfilePicture, isAvatar, pictureFile]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/profil/bearbeiten");
    }
  }, [isProfilePicture, isAvatar, isSuccess]);

  useEffect(() => {
    if (isError) {
      setSubmitErrorsText(true);
    }
  }, [isError]);

  return (
    <div className="avatar-settings-container">
      <Helmet title=" Willkommen - Homecase" />
      <PageHeader
        title={`Hallo ${user.firstName} ${user.lastName}!`}
        backTo={backTo}
      />
      <div className="avatar-settings-container-profile-info-wrapper">
        <div className="avatar-settings-container-profile-info">
          <div className="avatar-settings-container-profile-info-text">
            <span>Willkommen bei HOMECASE.</span>
            <span>
              Bitte laden Sie ein Profilbild hoch oder wählen Sie eines der
              vorhandenen Bilder.
            </span>
          </div>
          <div className="profile">
            {!isAvatar && !isProfilePicture && (
              <div className="picture-for-user">
                <ProfilePictureSettings
                  handleAvatarUpload={handleAvatarUpload}
                />
              </div>
            )}
            {isProfilePicture && (
              <div className="picture-for-user">
                <PictureProfilePreview
                  isAvatar={isProfilePicture}
                  handleAvatarUpload={handleAvatarUpload}
                />
              </div>
            )}
            {isAvatar && !isProfilePicture && (
              <div className="picture-for-user">
                <div className="picture-with-pencil">
                  <ProfilePictureForUser
                    url={isAvatar.pictureSrc}
                    name={isAvatar.pictureName}
                    rounded={true}
                    alt={"foto"}
                    key={isAvatar.pictureSrc}
                    width={120}
                    height={120}
                    setAvatar={setAvatar}
                    setProfilePicture={setProfilePicture}
                  />
                  <Uploader onUpload={handleAvatarUpload} />
                </div>
              </div>
            )}
          </div>

          {pictures && !isFetching && (
            <section className="pictures-wrapper">
              <div className="pictures">
                {pictures.slice(0, 5).map(({ pictureSrc, pictureName }) => (
                  <ProfilePictureForUser
                    url={pictureSrc}
                    rounded={true}
                    alt={pictureName}
                    key={pictureSrc}
                    setAvatar={setAvatar}
                    setProfilePicture={setProfilePicture}
                    picturesList={true}
                  />
                ))}
              </div>
              <div className="pictures">
                {pictures.slice(5, 9).map(({ pictureSrc, pictureName }) => (
                  <ProfilePictureForUser
                    url={pictureSrc}
                    rounded={true}
                    alt={pictureName}
                    key={pictureSrc}
                    setAvatar={setAvatar}
                    setProfilePicture={setProfilePicture}
                    picturesList={true}
                  />
                ))}
              </div>
              <div className="pictures">
                {pictures.slice(9, 13).map(({ pictureSrc, pictureName }) => (
                  <ProfilePictureForUser
                    url={pictureSrc}
                    rounded={true}
                    alt={pictureName}
                    key={pictureSrc}
                    setAvatar={setAvatar}
                    setProfilePicture={setProfilePicture}
                    picturesList={true}
                  />
                ))}
              </div>
            </section>
          )}
          {isFetching && (
            <div className="pictures-wrapper" data-testid="pictures-skeleton">
              <div className="pictures">
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
              </div>
              <div className="pictures">
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
                <ProfilePictureSkeleton />
              </div>
            </div>
          )}
          {isSubmitErrorsText && (
            <div className="error-text">
              <span>
                Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es
                erneut.
              </span>
            </div>
          )}
          <div className="avatar-settings-container-button">
            <Button
              lightblue
              label="Weiter"
              onClick={handlePostPictureProfile}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
