import React from "react";
import { IconProps } from "../../types";

export default function Phone({
  width = 30,
  height = 30,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M21.888 18.92v-.025a.596.596 0 0 0-.505-.606 11.263 11.263 0 0 1-2.466-.615.596.596 0 0 0-.626.131l-1.015 1.015a1 1 0 0 1-1.201.162 13.782 13.782 0 0 1-5.168-5.168 1 1 0 0 1 .162-1.202l1.01-1.01a.599.599 0 0 0 .134-.633 11.235 11.235 0 0 1-.612-2.455.6.6 0 0 0-.608-.514H8.597a.598.598 0 0 0-.596.636 14.82 14.82 0 0 0 2.302 6.496 14.59 14.59 0 0 0 4.493 4.49 14.794 14.794 0 0 0 6.44 2.296.598.598 0 0 0 .652-.602v-2.397zm2 2.392a2.598 2.598 0 0 1-2.85 2.596 16.8 16.8 0 0 1-7.322-2.603 16.568 16.568 0 0 1-5.096-5.093 16.824 16.824 0 0 1-2.61-7.38A2.598 2.598 0 0 1 8.597 6h2.387a2.6 2.6 0 0 1 2.6 2.243c.09.693.26 1.372.503 2.024a2.6 2.6 0 0 1-.589 2.745l-.457.458a11.782 11.782 0 0 0 3.379 3.379l.462-.462a2.597 2.597 0 0 1 2.738-.585 9.28 9.28 0 0 0 2.035.505 2.598 2.598 0 0 1 2.234 2.623v2.382z"
      />
    </svg>
  );
}
