import React, { useCallback, useEffect, useState, Fragment } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

import Button from "../Button";

import "../../../styles/components/common/Dialog.scss";

export default function ErrorDialog({
  title,
  description,
  onRetry,
  error,
  serverErrorDescription,
}: Props): JSX.Element {
  const [show, setShowError] = useState({
    showError: false,
    isServerError: false,
    isCanceled: false,
  });

  useEffect(() => {
    const isServerError =
      // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error?.originalStatus >= 500;
    if (!show.showError && !show.isCanceled && error) {
      setShowError({ showError: true, isCanceled: false, isServerError });
    }
    if (!error && show.showError) {
      setShowError({
        showError: false,
        isCanceled: false,
        isServerError: false,
      });
    }
  }, [show, setShowError, error]);

  const onCancel = useCallback(() => {
    setShowError({ showError: false, isCanceled: true, isServerError: false });
  }, [setShowError]);

  return (
    <Fragment>
      {show.showError && (
        <div className="dialog-background-layer" data-testid="error-dialog">
          <div
            className="dialog"
            role="alertdialog"
            aria-modal
            aria-labelledby="dialog1Title"
            aria-describedby="dialog1Desc"
          >
            <h2 className="dialog-title" id="dialog1Title">
              {title}
            </h2>
            <p
              className="dialog-description"
              id="dialog1Desc"
              data-testid="description"
            >
              {show.isServerError && serverErrorDescription
                ? serverErrorDescription
                : description}
            </p>
            <div className="dialog-actions">
              {!show.isServerError && <div />}
              <Button
                label={show.isServerError ? "Abbrechen" : "OK"}
                onClick={onCancel}
              />
              {show.isServerError && onRetry != undefined && (
                <Button label="Erneut versuchen" onClick={onRetry} lightblue />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  description: string;
  serverErrorDescription?: string;
  title: string;
  onRetry?: () => void;
  error: SerializedError | FetchBaseQueryError | undefined;
};
