import { IconProps } from "../../types";

export default function XlsFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="xls-file-icon"
    >
      <defs>
        <rect id="b" width="32" height="26" x="2" y="5" rx="1" />
        <filter
          id="a"
          width="109.4%"
          height="115.4%"
          x="-4.7%"
          y="-5.8%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="33"
          height="27"
          x="1.5"
          y="4.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <path
          fill="#219D07"
          fillRule="nonzero"
          d="M9.503 8.086c.477-.033.957-.063 1.436-.086a440.46 440.46 0 0 1-1.704 3.46c.58 1.183 1.174 2.357 1.757 3.54-.509-.03-1.017-.062-1.525-.097-.359-.88-.796-1.732-1.053-2.652-.287.857-.697 1.666-1.027 2.506A50.702 50.702 0 0 1 6 14.713c.543-1.062 1.066-2.133 1.626-3.189-.475-1.087-.997-2.154-1.487-3.235.465-.027.93-.055 1.394-.08.314.826.659 1.64.919 2.487.278-.897.694-1.742 1.051-2.61z"
        />
        <rect width="14" height="1" x="15" y="10" fill="#27A00D" rx=".5" />
        <path stroke="#CACCD7" d="M6.5 18.5h23v9h-23z" opacity=".8" />
        <path
          fill="#CACCD7"
          fillOpacity=".8"
          d="M14 22h7v-3h1v3h7v1h-7v4h-1v-4h-7v4h-1v-4H7v-1h6v-3h1v3z"
        />
      </g>
    </svg>
  );
}
