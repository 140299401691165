import {
  ActivityCustomFieldAllowedListItem,
  ActivityCustomFieldDefinition,
  ActivityFormError,
  CreateActivityRequest,
  FormFieldConfiguration,
} from "../types";
import { ActivityCustomFieldType } from "../enums";
import TextArea from "../components/common/form/TextArea";
import CurrencyInput from "../components/common/form/CurrencyInput";
import NumberInput from "../components/common/form/NumberInput";
import Select from "../components/common/form/Select";
import DateYearInput from "../components/common/form/DateYearInput";
import DateInput from "../components/common/form/DateInput";
import validate, {
  validateCurrency,
  validateDate,
  validateIban,
  validateNumber,
  validateRequired,
} from "./formValidate";
import { FormApi } from "final-form";
import moment from "moment";
import PdfFile from "../components/common/files/PdfFile";
import ImageFile from "../components/common/files/ImageFile";
import VideoFile from "../components/common/files/VideoFile";
import TextInput from "../components/common/form/TextInput";

const CUSTOM_FIELD_MAP = {
  [ActivityCustomFieldType.Text]: TextArea,
  [ActivityCustomFieldType.Currency]: CurrencyInput,
  [ActivityCustomFieldType.Number]: NumberInput,
  [ActivityCustomFieldType.ItemList]: Select,
  [ActivityCustomFieldType.DateYear]: DateYearInput,
  [ActivityCustomFieldType.ShortDate]: DateInput,
  [ActivityCustomFieldType.Iban]: TextInput,
};

const CUSTOM_FIELD_VALIDATOR_MAP = {
  [ActivityCustomFieldType.Text]: [validateRequired()],
  [ActivityCustomFieldType.Currency]: [validateCurrency()],
  [ActivityCustomFieldType.Number]: [validateNumber()],
  [ActivityCustomFieldType.ItemList]: [validateRequired()],
  [ActivityCustomFieldType.DateYear]: [
    validateRequired("Bitte geben Sie ein Jahr an!"),
    validateNumber("Bitte geben Sie ein Jahr an!"),
  ],
  [ActivityCustomFieldType.ShortDate]: [
    validateRequired("Bitte geben Sie ein Datum an!"),
    validateDate(),
  ],
  [ActivityCustomFieldType.Iban]: [validateIban()],
};

const SUBMISSION_FIELD_ERRORS = {
  RequirementsError: "Dieses Feld darf nicht leer sein.",
  InvalidFormatError: "Ungültiges Format.",
};
export const PREVIEW_COMPONENTS = {
  "application/pdf": PdfFile,
  "image/jpg": ImageFile,
  "image/jpeg": ImageFile,
  "image/png": ImageFile,
  "image/svg+xml": ImageFile,
  "video/mp4": VideoFile,
};

export function mapAllowedListItemsToOptions(
  allowedListItems: ActivityCustomFieldAllowedListItem[],
) {
  return [
    { label: "----", value: "" },
    ...allowedListItems.map(({ id, value }) => ({
      label: value,
      value: id,
    })),
  ];
}
export function mapCustomFieldsToForm(
  customFields: ActivityCustomFieldDefinition[],
): FormFieldConfiguration[] {
  return customFields.map((customField) => ({
    component: CUSTOM_FIELD_MAP[customField.type],
    label: customField.description || customField.name,
    name: String(customField.id) + "-field",
    required: customField.isRequired,
    validate: customField.isRequired
      ? validate(...CUSTOM_FIELD_VALIDATOR_MAP[customField.type])
      : undefined,
    symbol:
      customField.type === ActivityCustomFieldType.Currency
        ? "right"
        : undefined,
    options:
      customField.type === ActivityCustomFieldType.ItemList
        ? mapAllowedListItemsToOptions(customField.allowedListItems)
        : undefined,
  }));
}

export function mapErrorsToFields(errors: ActivityFormError[]) {
  return Object.fromEntries(
    errors.map(({ fieldId, fieldName, errorType }) =>
      fieldId
        ? [`${fieldId}-field`, SUBMISSION_FIELD_ERRORS[errorType]]
        : [fieldName.toLowerCase(), SUBMISSION_FIELD_ERRORS[errorType]],
    ),
  );
}
export function mapFormValuesToActivityAndDocuments(
  values: Record<string, string | undefined> | Record<string, File[]>,
  form: FormApi,
  facilityObjectId: string,
  customerToken: string,
): { activity: CreateActivityRequest; documents?: File[] } {
  const fields = form.getRegisteredFields();
  const valueEntries = Object.entries(values).filter(([name]) =>
    fields.includes(name),
  );
  const customFields = fields.filter((name) => name.endsWith("-field"));

  const customFieldsValues = valueEntries.filter(([name]) =>
    name.endsWith("-field"),
  );

  const customFieldsWithValues = customFields.map((customField) => {
    const customFieldDefinitionId = parseInt(
      customField.replace("-field", ""),
      10,
    );
    const [, value = null] =
      customFieldsValues.find(([name]) => name === customField) || [];
    return {
      customFieldDefinitionId,
      value,
    };
  });

  return {
    activity: {
      customFieldValues: customFieldsWithValues,
      facilityObjectId,
      tenancyOrOwnershipId: values.tenancyOrOwnershipId as string,
      customerToken,
      activityTemplateId: values.activityTemplateId as string,
      text: values.text as string,
      textMediaType: "text/plain",
      onlineDateUtc: moment.utc().format(),
    },
    documents: values.documents ? (values.documents as File[]) : undefined,
  };
}
