import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import classNames from "classnames";
import { IconProps } from "../../types";

import useTracking from "../../hooks/googleAnalytics/useTracking";

import "../../styles/components/common/IconButton.scss";

export default function IconButton({
  green,
  red,
  grey,
  lightblue,
  className,
  width = 24,
  height = 24,
  icon,
  onClick,
  ...rest
}: Props): JSX.Element {
  const buttonClassName = classNames(
    {
      "icon-button": true,
      "icon-button-red": red,
      "icon-button-green": green,
      "icon-button-grey": grey,
      "icon-button-lightblue": lightblue,
    },
    className,
  );
  const Icon = icon;
  const { trackEvent } = useTracking();
  return (
    <button
      {...rest}
      className={buttonClassName}
      data-testid="icon-button-icon"
      onClick={trackEvent(
        { action: "click", category: "button", label: "IconButton" },
        onClick,
      )}
    >
      <Icon width={width} height={height} className="icon-button-icon" />
    </button>
  );
}

type Props = {
  green?: boolean;
  red?: boolean;
  grey?: boolean;
  lightblue?: boolean;
  className?: string;
  value?: string | number | boolean;
  name?: string;
  type?: "button" | "submit" | "reset";
  icon: FunctionComponent<IconProps>;
  width?: number;
  height?: number;
} & ButtonHTMLAttributes<HTMLButtonElement>;
