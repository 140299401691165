import { useParams } from "react-router-dom";
import { useGetTermsOfUseActualQuery } from "../../api/termsOfUseApi";

export default function useTermsOfUseActual(skip = false) {
  const { customerToken = "" } = useParams();
  const {
    data: termsOfUseActual,
    isError,
    isLoading,
  } = useGetTermsOfUseActualQuery(
    {
      customerToken,
    },
    { skip },
  );

  return { termsOfUseActual, isError, isLoading };
}
