import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AgendaItem, AgendaItemRequest, AgendaItemsRequest } from "../types";
import { AGENDA_ITEMS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";

export const agendaItemApi = createApi({
  reducerPath: AGENDA_ITEMS_REDUCER_PATH,
  tagTypes: ["AgendaItems"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),

  endpoints: (build) => ({
    getAgendaItems: build.query<AgendaItem[], AgendaItemsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, ...params }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems`,
        params,
      }),
      transformResponse: (response: AgendaItem[]) =>
        response.sort(({ number: a }, { number: b }) => a - b),
      providesTags: ["AgendaItems"],
    }),
    getAgendaItem: build.query<AgendaItem, AgendaItemRequest>({
      query: ({ customerToken, meetingId, agendaItemId, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}`,
    }),
    patchAgendaItem: build.mutation<void, AgendaItemRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        description,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}`,
        method: "PATCH",
        body: { description },
      }),
      invalidatesTags: ["AgendaItems"],
    }),
  }),
});

export const {
  useGetAgendaItemsQuery,
  useGetAgendaItemQuery,
  usePatchAgendaItemMutation,
} = agendaItemApi;
