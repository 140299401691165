import React, { ReactElement } from "react";
import { Customer, FacilityObject, NavigationItem } from "../../types";
import { getCustomerLogo } from "../../lib";

import "../../styles/components/userSettings/ManagementItem.scss";

export default function ManagementItem({ customer }: Props): ReactElement {
  const iconCustomerLogo = getCustomerLogo(customer);

  return (
    <div
      className="management-item-container"
      data-testid="management-item"
      onClick={() => window.location.assign(`/${customer?.token}`)}
    >
      {iconCustomerLogo && (
        <div className="management-item-container-logo">
          <img width={85} height={40} {...iconCustomerLogo} alt="Logo" />
        </div>
      )}
      {!iconCustomerLogo && <span className="navigation-list-item-circle" />}
      <span>{customer?.name}</span>
    </div>
  );
}

type Props = {
  item?: NavigationItem;
  customer?: Customer;
  facilityObjectId?: boolean;
  assignedObjects?: FacilityObject[];
};
