import React from "react";
import { Field } from "react-final-form";
import CheckboxField from "../../common/form/CheckboxField";
import "../../../styles/components/meetings/proposition/PropositionVotingResolutionDialogInfo.scss";
import Select from "../../common/form/Select";
import {
  useGetPropositionCategoriesQuery,
  useGetPropositionStatusesQuery,
} from "../../../api/propositionApi";
import { useParams } from "react-router-dom";

export default function PropositionVotingResolutionDialogInfo(): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { data: propositionCategories } = useGetPropositionCategoriesQuery({
    customerToken,
    facilityObjectId,
  });

  const { data: propositionStatuses } = useGetPropositionStatusesQuery({
    customerToken,
    facilityObjectId,
  });

  return (
    <section className="proposition-voting-resolution-form-fields">
      <Field
        name="finalDecision"
        component={CheckboxField}
        type="checkbox"
        label="Beschluss gefasst"
        id="finalDecision"
      />
      <Field
        name="decisionCalm"
        component={CheckboxField}
        type="checkbox"
        label="Beschluss rechtskräftig"
        id="decisionCalm"
      />
      <Field
        name="propositionStatusId"
        component={Select}
        type="select"
        label="Status"
        id="propositionStatusId"
        options={(propositionStatuses ?? []).map(({ id, title }) => ({
          label: title,
          value: id,
        }))}
      />
      <Field
        name="propositionCategoryId"
        component={Select}
        type="select"
        label="Kategorie"
        id="propositionCategoryId"
        options={(propositionCategories ?? []).map(({ id, title }) => ({
          label: title,
          value: id,
        }))}
      />
    </section>
  );
}
