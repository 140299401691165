import React from "react";
import { useLocation } from "react-router-dom";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import ProfilePicture from "../common/ProfilePicture";
import Uploader from "../common/files/Uploader";
import "../../styles/components/common/ProfilePictureSettings.scss";

export default function ProfilePictureSettings({
  handleAvatarUpload,
}: Props): JSX.Element {
  const { pathname } = useLocation();
  const { user } = useGetUsersMyProfile();
  const { firstName, sid } = user;

  const isPathname =
    pathname.includes("/profil/bearbeiten") || pathname.includes("/willkommen");
  return (
    <div className="picture-settings-wrapper">
      <ProfilePicture
        alt={user ? firstName : "Profilbild"}
        userSid={sid}
        width={120}
        height={120}
        rounded
      />
      {isPathname && <Uploader onUpload={handleAvatarUpload} />}
    </div>
  );
}
type Props = {
  handleAvatarUpload: (item: File) => void;
};
