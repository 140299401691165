import React from "react";
import { IconProps } from "../../../types";

export default function ServicesWater({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 22)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <rect y="53.333" width="13.333" height="23.333" rx="1" />
        <rect x="66.667" width="13.333" height="23.333" rx="1" />
        <path d="M45 50H31.667c-.92 0-1.667.746-1.667 1.667a5 5 0 0 1-5 5h-5c-.92 0-1.667.746-1.667 1.666v13.334c0 .92.747 1.666 1.667 1.666h5c11.96-.014 21.652-9.706 21.667-21.666 0-.92-.747-1.667-1.667-1.667zM60 3.333h-8.333C39.707 3.348 30.015 13.04 30 25c0 .92.746 1.667 1.667 1.667H45c.92 0 1.667-.747 1.667-1.667a5 5 0 0 1 5-5H60c.92 0 1.667-.746 1.667-1.667V5c0-.92-.747-1.667-1.667-1.667z" />
        <rect x="26.667" y="31.667" width="23.333" height="13.333" rx="1" />
      </g>
    </svg>
  );
}
