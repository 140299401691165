import { MyInvitation, Participant } from "../../../types";
import { Fragment } from "react";
import moment from "moment";
import { ParticipantDelegationType } from "../../../enums";

import "../../../styles/components/meetings/inivitation/InvitationState.scss";
import Checkmark from "../../icons/Checkmark";
import CloseIcon from "../../icons/CloseIcon";
import DelegationIcon from "../../icons/DelegationIcon";

export default function InvitationState({
  myInvitation,
  myRelatedParticipant,
}: Props) {
  const invitationRespondedAt = moment
    .utc(myInvitation?.invitationRespondedAtUtc)
    .local()
    .format("L");

  const isDelegated =
    myRelatedParticipant?.delegationType ===
      ParticipantDelegationType.VotingEligibilityDelegatedToParticipant ||
    myRelatedParticipant?.delegationType ===
      ParticipantDelegationType.VotingEligibilityDelegatedToEmployee;

  const delegation = myRelatedParticipant && isDelegated;

  return (
    <Fragment>
      {myInvitation && (
        <div className="meeting-invitation-state">
          {myInvitation.invitationAccepted && (
            <div className="accepted">
              <Checkmark width={32} height={32} className="icon-accepted" />
              {`Sie haben die Einladung am ${invitationRespondedAt} zugesagt.`}
            </div>
          )}
          {!myInvitation.invitationAccepted && (
            <div className="declined">
              <CloseIcon height={32} width={32} className="icon-declined" />
              {`Sie haben die Einladung am ${invitationRespondedAt} abgesagt${
                !delegation ? " und Vollmacht an keine Person erteilt" : ""
              }.`}
            </div>
          )}
          {myRelatedParticipant &&
            myRelatedParticipant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToEmployee && (
              <div className="accepted">
                <DelegationIcon
                  width={32}
                  height={32}
                  className="icon-delegation"
                />
                Sie haben Vollmacht an Ihre Hausverwaltung erteilt.
              </div>
            )}
          {myRelatedParticipant &&
            myRelatedParticipant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToParticipant && (
              <div className="accepted">
                <DelegationIcon
                  width={32}
                  height={32}
                  className="icon-delegation"
                />
                {`Sie haben ihre Vollmacht an ${myRelatedParticipant.delegate?.firstName} ${myRelatedParticipant.delegate?.lastName} erteilt.`}
              </div>
            )}
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  myRelatedParticipant?: Participant;
  myInvitation?: MyInvitation;
};
