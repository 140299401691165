import "../../styles/components/employees/ItemEmployees.scss";
import React, { Fragment } from "react";
import CaretDown from "../icons/CaretDown";
import { useGetPermissionsQuery } from "../../api/customerApi";
import { useParams } from "react-router-dom";
import useGetSortPermissionsList from "../../hooks/employee/useGetSortPermissionsList";
export default function ItemEmployees({
  email,
  name,
  onClick,
  id,
  modelContent,
  onClose,
}: Props): JSX.Element {
  const { customerToken = "" } = useParams();

  const { data: permissionsList } = useGetPermissionsQuery({
    customerToken,
    employeeId: id,
  });

  const sortedPermissionsList = useGetSortPermissionsList(permissionsList);
  return (
    <Fragment>
      {!modelContent ? (
        <div
          className="employee-container-item-info"
          onClick={onClick}
          data-testid="employee-item"
        >
          <span className="employee-container-item-info-title">
            <CaretDown className="employee-container-item-info-headline-icon-right" />{" "}
            <p>{name}</p>
          </span>
          <span className="employee-container-item-info-email">{email}</span>
        </div>
      ) : (
        <section className="employee-container-item-info open">
          <div
            className="employee-container-item-open"
            onClick={onClose}
            data-testid="employee-item-open"
          >
            <div className="employee-container-item-info-title">
              <CaretDown className="employee-container-item-info-headline-icon-down" />
              <p>{name}</p>
            </div>
            <span className="employee-container-item-info-email">{email}</span>
          </div>
          <div className="permissions-container">
            <p className="permissions-container-title">Berechtigungen</p>
            {!sortedPermissionsList?.length && (
              <span className="permissions-info-text">
                Es wurden noch keine Berechtigungen festgelegt.
              </span>
            )}
            <ul className="permissions-list">
              {sortedPermissionsList?.map((item, index) => (
                <li key={index}>{item.permissionName}</li>
              ))}
            </ul>
          </div>
        </section>
      )}
    </Fragment>
  );
}

type Props = {
  roleName?: string;
  email: string | null;
  mobil?: string[];
  tel?: string[];
  name: string;
  modelContent?: boolean;
  onClick: () => void;
  onClose: () => void;
  id?: string;
  employeeId?: string;
  visitingCardId?: string;
};
