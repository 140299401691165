import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import React, { Fragment, useCallback, useEffect } from "react";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import FileInput from "../../common/form/FileInput";

import { OnlineDocumentPermissionType } from "../../../enums";
import ObjectDocumentUploadDetailsFields from "./ObjectDocumentUploadDetailsFields";

import Button from "../../common/Button";
import Checkmark from "../../icons/Checkmark";
import { MutableState, Tools } from "final-form";
import {
  useCreateOnlineDocumentMutation,
  useGetOnlineDocumentCategoriesQuery,
} from "../../../api/documentsApi";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../icons/LoadingSpinner";

import "../../../styles/components/management/objectSettings/ObjectDocumentUploadFormDialog.scss";

const fileRemove = (
  index: number,
  state: MutableState<Values>,
  { changeValue }: Tools<Values>,
) => {
  changeValue(state, "file", (file: File[]) => {
    const newFile = [...file];
    newFile.splice(index, 1);
    return newFile;
  });
};

export default function ObjectDocumentUploadFormDialog({
  handleToggleUploadDialog,
}: Props) {
  const [createDocument] = useCreateOnlineDocumentMutation();
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data: categories = [] } = useGetOnlineDocumentCategoriesQuery({
    customerToken,
  });
  const handleOnSubmit = useCallback(
    async (values: Values) => {
      const { documents } = values;
      const results = await Promise.all(
        documents.map(
          async (document) =>
            await createDocument({
              file: document.file,
              category:
                categories.find(({ id }) => id === Number(document.category))
                  ?.name || "",
              description: document.description,
              tenantCanSee: document[OnlineDocumentPermissionType.TenantCanSee],
              objectOwnerCanSee:
                document[OnlineDocumentPermissionType.ObjectOwnerCanSee],
              serviceProviderCanSee:
                document[OnlineDocumentPermissionType.ServiceProviderCanSee],
              customerToken,
              facilityObjectId,
            }),
        ),
      );
      const errors = results.map((result) =>
        "error" in result
          ? "data" in result.error
            ? result.error.data
            : "unknown"
          : null,
      );
      if (errors.filter((error) => error !== null).length > 0) {
        return { documents: errors };
      }
    },
    [categories],
  );

  return (
    <Form
      onSubmit={handleOnSubmit}
      mutators={{
        ...arrayMutators,
        fileRemove,
      }}
      validateOnBlur={false}
      render={({ handleSubmit, values, form, submitting, submitSucceeded }) => {
        useEffect(() => {
          if (values.file?.length > 0 && !values.documents) {
            values.file.forEach((file) => {
              form.mutators.push("documents", {
                file,
                [OnlineDocumentPermissionType.TenantCanSee]: false,
                [OnlineDocumentPermissionType.ObjectOwnerCanSee]: false,
                [OnlineDocumentPermissionType.ServiceProviderCanSee]: false,
              });
            });
          }
        }, [values.file, values.documents]);

        return (
          <Fragment>
            {!submitSucceeded && (
              <form
                onSubmit={handleSubmit}
                className="object-document-upload-form-dialog"
                noValidate
              >
                <ConfirmDialog
                  description={
                    <Fragment>
                      <FieldArray
                        name="documents"
                        component={ObjectDocumentUploadDetailsFields}
                      />
                      {!values.documents && (
                        <Field
                          name="file"
                          component={FileInput}
                          description="Dokumente zum hochladen hier ablegen, oder auswählen."
                        />
                      )}
                    </Fragment>
                  }
                  title="Objektdokumente hochladen"
                  showCloseButton
                  showCancelButton={false}
                  confirmComponent={
                    <Button
                      label={`${values.documents?.length ?? 0} Dokumente hinzufügen`}
                      leadingIcon={submitting ? LoadingSpinner : Checkmark}
                      lightblue
                      disabled={!values.documents || submitting}
                      data-testid="submit-button"
                    />
                  }
                  onCancel={handleToggleUploadDialog}
                />
              </form>
            )}
            {submitSucceeded && (
              <ConfirmDialog
                title="Objektdokumente hochladen"
                description={
                  <div className="object-document-upload-form-dialog-success">
                    <Checkmark
                      className="object-document-upload-form-dialog-success-icon"
                      height={64}
                      width={64}
                    />
                    <span>
                      Es wurden {values.documents.length} Dokumente erfolgreich
                      hochgeladen.
                    </span>
                  </div>
                }
                showCancelButton={false}
                confirmComponent={
                  <Button
                    label="Schließen"
                    leadingIcon={Checkmark}
                    lightblue
                    onClick={handleToggleUploadDialog}
                  />
                }
                onCancel={handleToggleUploadDialog}
              />
            )}
          </Fragment>
        );
      }}
    />
  );
}

type Props = {
  handleToggleUploadDialog: () => void;
};

type Values = {
  file: File[];
  documents: Array<{
    file: File;
    category: string;
    description: string;
    [OnlineDocumentPermissionType.TenantCanSee]: boolean;
    [OnlineDocumentPermissionType.ObjectOwnerCanSee]: boolean;
    [OnlineDocumentPermissionType.ServiceProviderCanSee]: boolean;
  }>;
};
