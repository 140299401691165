import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";
import { ReactElement } from "react";

import "../../../styles/components/common/form/NumberInput.scss";

export default function NumberInput({
  input,
  meta,
  className,
  label,
  required,
  ...rest
}: FieldRenderProps<string | undefined, HTMLInputElement>): ReactElement {
  const numberInputClassName = classNames(className, {
    "number-input-field": true,
    "number-input-error": (meta.error || meta.submitError) && meta.touched,
  });
  return (
    <section className={numberInputClassName}>
      <label>
        {label && (
          <span className="number-input-field-label" role="label">
            {label} {required && "*"}
          </span>
        )}
        <input {...input} {...rest} type="number" required={required} />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="number-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
