import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { NavigationItem } from "../../types";
import NavigationListItem from "./NavigationListItem";

import "../../styles/components/navigation/NavigationList.scss";
import { useGetCustomersQuery } from "../../api/customerApi";
import { useGetLoginInfoQuery } from "../../api/homecaseMvcApi";

export default function NavigationList({
  items,
  management,
  showNavigationList,
}: Props): JSX.Element {
  const { facilityObjectId = "" } = useParams();
  const { data: isLoggedIn } = useGetLoginInfoQuery();
  const { data: managementList } = useGetCustomersQuery(undefined, {
    skip: !isLoggedIn,
  });

  return (
    <Fragment>
      {!facilityObjectId && !management && showNavigationList ? (
        <Fragment>
          <h3 className="navigation-list-title">Meine Verwaltungen</h3>
          <ul className="navigation-list managementsList">
            {managementList?.map((item, key) => (
              <NavigationListItem
                managementItem={item}
                key={key}
                facilityObjectId={facilityObjectId === ""}
              />
            ))}
          </ul>
        </Fragment>
      ) : (
        <ul className="navigation-list">
          {items.map((item, key) => (
            <NavigationListItem item={item} key={key} />
          ))}
        </ul>
      )}
    </Fragment>
  );
}

type Props = {
  items: NavigationItem[];
  management?: boolean;
  showNavigationList?: boolean;
};
