import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ACTIVITIES_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import {
  Activity,
  ActivityRequest,
  ActivityContact,
  ActivityContactRequest,
  ActivityCustomFieldDefinition,
  ActivityDocument,
  ActivityDocumentsRequest,
  ActivityTemplate,
  ActivityTemplateCustomFieldsRequest,
  CreateActivityDocumentRequest,
  CreateActivityRequest,
  CreateActivityContactRequest,
  CustomerRequest,
  CustomerRolesRequest,
  ActivityDocumentRequest,
  User,
  ActivityParticipantRequest,
  ActivityTemplatesByFacilityObjectRequest,
} from "../types";
import moment from "moment";
import { ActivityType } from "../enums";

export const activitiesApi = createApi({
  reducerPath: ACTIVITIES_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["Activities", "Contacts"],
  endpoints: (build) => ({
    getFacilityObjectsActivities: build.query<Activity[], CustomerRolesRequest>(
      {
        query: ({ customerToken, facilityObjectId, ...params }) => ({
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/activities`,
          params,
        }),

        providesTags: [{ type: "Activities", id: "LIST" }],
        transformResponse: (activities: Activity[]) =>
          activities.map((activity) => ({
            ...activity,
            type: ActivityType.Object,
          })),
      },
    ),
    getMyAddressActivities: build.query<Activity[], CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/activities/myAddress`,
      providesTags: [{ type: "Activities", id: "LIST" }],
      transformResponse: (activities: Activity[]) =>
        activities.map((activity) => ({
          ...activity,
          type: ActivityType.General,
        })),
    }),
    getActivityTemplates: build.query<ActivityTemplate[], CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/activityTemplates`,
    }),
    getActivityTemplatesByFacilityObject: build.query<
      ActivityTemplate[],
      ActivityTemplatesByFacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/activityTemplates`,
    }),
    getActivityTemplateCustomFields: build.query<
      ActivityCustomFieldDefinition[],
      ActivityTemplateCustomFieldsRequest
    >({
      query: ({ customerToken, activityTemplateId }) =>
        `/customers/${customerToken}/activityTemplates/${activityTemplateId}/customFields`,
    }),
    getActivity: build.query<Activity, ActivityRequest>({
      query: ({ customerToken, activityId }) =>
        `/customers/${customerToken}/activities/${activityId}`,
    }),
    getActivityParticipants: build.query<User[], ActivityParticipantRequest>({
      query: ({ customerToken, activityId }) =>
        `/customers/${customerToken}/activities/${activityId}/participants`,
    }),
    getActivityContacts: build.query<ActivityContact[], ActivityContactRequest>(
      {
        query: ({ customerToken, activityId }) =>
          `/customers/${customerToken}/activities/${activityId}/contacts`,
        transformResponse: (contacts: ActivityContact[]) => {
          contacts.sort((a, b) =>
            moment(a.createdDateUTC).diff(b.createdDateUTC),
          );
          return contacts;
        },
        providesTags: [{ type: "Contacts", id: "LIST" }],
      },
    ),
    getActivityDocuments: build.query<
      ActivityDocument[],
      ActivityDocumentsRequest
    >({
      query: ({ customerToken, activityId }) =>
        `/customers/${customerToken}/activities/${activityId}/onlinedocuments`,
    }),
    getActivityDocument: build.query<Blob, ActivityDocumentRequest>({
      query: ({ customerToken, activityId, documentId }) => ({
        url: `/customers/${customerToken}/activities/${activityId}/onlinedocuments/${documentId}`,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),
    postActivityDocument: build.mutation<
      ActivityDocument,
      CreateActivityDocumentRequest
    >({
      query: ({ customerToken, referenceId, file, onlineDateUTC }) => {
        const formData = new FormData();
        formData.append("content", file);
        return {
          url: `/customers/${customerToken}/activities/${referenceId}/onlinedocuments`,
          method: "POST",
          params: { onlineDateUTC },
          body: formData,
        };
      },
    }),
    postActivity: build.mutation<Activity, CreateActivityRequest>({
      query: ({ customerToken, ...body }) => ({
        url: `/v2/customers/${customerToken}/activities`,
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Activities", id: "LIST" }],
    }),
    postActivityContact: build.mutation<
      ActivityContact,
      CreateActivityContactRequest
    >({
      query: ({ customerToken, activityId, ...body }) => ({
        url: `/customers/${customerToken}/activities/${activityId}/contacts`,
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Contacts", id: "LIST" }],
    }),
  }),
});

export const {
  useGetFacilityObjectsActivitiesQuery,
  useGetMyAddressActivitiesQuery,
  useGetActivityQuery,
  useGetActivityParticipantsQuery,
  useGetActivityContactsQuery,
  useGetActivityDocumentsQuery,
  useGetActivityDocumentQuery,
  useGetActivityTemplatesQuery,
  useGetActivityTemplatesByFacilityObjectQuery,
  useGetActivityTemplateCustomFieldsQuery,
  usePostActivityMutation,
  usePostActivityContactMutation,
} = activitiesApi;
