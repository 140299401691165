import { ParticipantAttendanceType } from "../../enums";
import classNames from "classnames";
import { ReactNode } from "react";

import "../../styles/components/common/UserOnlineStateBadge.scss";
import { Participant } from "../../types";

const HINT_TEXT: {
  [key: number]: string;
} = {
  [ParticipantAttendanceType.Abscent]: "Abwesend",
  [ParticipantAttendanceType.Online]: "Anwesend in Homecase",
  [ParticipantAttendanceType.Locally]: "Anwesend",
};

export default function UserOnlineStateBadge({
  onlineState,
  showHint,
  children,
  delegatedPerson,
  isDelegate,
}: Props): JSX.Element {
  const userOnlineStateClassName = classNames({
    "user-online-state-badge": true,
    "user-online-state-badge-absent":
      onlineState === ParticipantAttendanceType.Abscent && !isDelegate,
    "user-online-state-badge-online":
      onlineState === ParticipantAttendanceType.Online,
    "user-online-state-badge-locally":
      onlineState === ParticipantAttendanceType.Locally,
    "user-online-state-badge-delegated-locally":
      onlineState === ParticipantAttendanceType.Abscent &&
      isDelegate &&
      delegatedPerson?.attendance === ParticipantAttendanceType.Locally,
    "user-online-state-badge-delegated-online":
      onlineState === ParticipantAttendanceType.Abscent &&
      isDelegate &&
      delegatedPerson?.attendance !== ParticipantAttendanceType.Locally,
  });

  return (
    <span className={userOnlineStateClassName}>
      {!isDelegate && children}
      {showHint && (
        <span className="user-online-state-badge-hint">
          {`${HINT_TEXT[onlineState]} ${
            isDelegate ? "(vertreten durch Vollmacht)" : ""
          }`}
        </span>
      )}
    </span>
  );
}

type Props = {
  onlineState: ParticipantAttendanceType;
  showHint?: boolean;
  children?: ReactNode[] | ReactNode | JSX.Element[] | JSX.Element;
  delegatedPerson?: Participant;
  isDelegate?: boolean;
};
