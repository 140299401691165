import React, { Fragment, useCallback, useEffect, useState } from "react";
import useDownload from "../../hooks/useDownload";
import DownloadIcon from "../icons/DownloadIcon";
import PdfFileIcon from "../icons/PdfFileIcon";
import { usePdfToImage } from "../../hooks/meetings/usePdfToImage";

export default function PdfPreviewComponent({
  file,
  downloadable,
  onClick,
}: FilePreviewProps) {
  const [objectUrl, setObjectUrl] = useState(URL.createObjectURL(file));
  const handleDownload = useDownload(objectUrl, file.name, downloadable);
  const { imageSrc, convertPdfToImage } = usePdfToImage({ file });

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setObjectUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  useEffect(() => {
    convertPdfToImage();
  }, [file, imageSrc]);

  const onClickHandler = useCallback(() => {
    onClick && onClick(file);
  }, [onClick, file]);

  return (
    <Fragment>
      <div className="pdf-preview-large-container">
        <div onClick={onClickHandler} style={{ cursor: "pointer" }}>
          {imageSrc && (
            <img src={imageSrc} alt={file.name} className="pdf-file" />
          )}
          {!imageSrc && <PdfFileIcon width={150} height={150} />}
          <div
            className={"image-file-downloadable"}
            onClick={onClickHandler}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {downloadable && (
              <span className={"download-icon"} onClick={handleDownload}>
                <DownloadIcon />
              </span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

type FilePreviewProps = {
  file: File;
  downloadable?: boolean;
  onClick: ((file: File) => void) | undefined;
};
