import React from "react";
import NoMeeting from "../icons/NoMeeting";

import "../../styles/components/meetings/NoMeetingAvailable.scss";

export default function NoMeetingAvailable(): JSX.Element {
  return (
    <section className="no-meeting-available" role="status" aria-atomic>
      <NoMeeting className="no-meeting-available-image" />
      <h2 className="no-meeting-available-title">
        Aktuell sind keine Eigentümerversammlungen vorhanden.
      </h2>
    </section>
  );
}
