import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";

import "../../styles/components/management/PermissionsGroupItem.scss";
import CaretDown from "../icons/CaretDown";
import { Permission } from "../../types";
import Checkbox from "../common/form/Checkbox";
import {
  useGetGroupPermissionsListQuery,
  usePostPermissionMutation,
  useRevokePermissionMutation,
} from "../../api/customerApi";
import { useParams } from "react-router-dom";
import AlertDialog from "../common/dialog/AlertDialog";
export default function PermissionsGroupItem({
  name,
  permissionsList,
  groupId,
}: Props): JSX.Element {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isErrorDialog, setErrorDialog] = useState(false);
  const { customerToken = "" } = useParams();
  const { data: permissionsListChecked } = useGetGroupPermissionsListQuery({
    customerToken,
    groupId,
  });
  const [revokePermission, { isError: revokePermissionError }] =
    useRevokePermissionMutation();
  const [postPermission, { isError: postPermissionError }] =
    usePostPermissionMutation();
  const handlePostPermission = async (e: SyntheticEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    const action = checked ? postPermission : revokePermission;

    await action({ customerToken, pid: parseInt(value, 10), groupId });
  };

  const permissionsIds = permissionsListChecked?.map((item) => item.pid);
  const isError = revokePermissionError || postPermissionError;

  useEffect(() => {
    if (revokePermissionError || postPermissionError) {
      setErrorDialog(true);
    }
  }, [revokePermissionError, postPermissionError]);

  return (
    <Fragment>
      {!isInfoOpen && (
        <section
          className="permissions-group-item-wrapper"
          data-testid="permissions-item"
          onClick={() => setInfoOpen(!isInfoOpen)}
        >
          <div className="permissions-group-item-title-wrapper">
            <h3 className="details-title">{name}</h3>
            <div className="details-action">
              <CaretDown className="caret-icon-right" width={16} height={16} />
            </div>
          </div>
        </section>
      )}
      {isInfoOpen && (
        <section className="permissions-group-item-wrapper open">
          <div
            className="permissions-group-item-title-wrapper open"
            data-testid="permissions-item-open"
            onClick={() => setInfoOpen(!isInfoOpen)}
          >
            <h3 className="details-title">{name}</h3>
            <div className="details-action">
              <CaretDown className="caret-icon-down" width={16} height={16} />
            </div>
          </div>
          <span className="permission">Berechtigung</span>
          <ul className="permissions-list">
            {permissionsList?.map((item) => (
              <li key={item.pid}>
                <Checkbox
                  label={item.permissionName}
                  defaultChecked={Boolean(permissionsIds?.includes(item.pid))}
                  onChange={handlePostPermission}
                  value={String(item.pid)}
                  disabled={name === "Administratoren"}
                />
              </li>
            ))}
          </ul>
        </section>
      )}
      {isError && isErrorDialog && (
        <AlertDialog
          description=""
          title="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
          onConfirm={() => setErrorDialog(false)}
        />
      )}
    </Fragment>
  );
}

type Props = {
  name: string;
  permissionsList?: Permission[];
  groupId: string;
};
