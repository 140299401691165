import { GroupingType } from "../../enums";
import { useMemo } from "react";
import { Document } from "../../types";
import moment from "moment";

export default function useGroupDocuments(
  documents: Document[],
  groupedBy: GroupingType,
) {
  const groupedDocuments = useMemo(() => {
    let groupedDocuments = new Map<string, Document[]>();
    if (groupedBy === GroupingType.None) {
      groupedDocuments.set(GroupingType.None, documents);
    }
    if (groupedBy === GroupingType.DocumentCategory) {
      const categories = Array.from(
        new Set(documents.map((document) => document.category)),
      );

      groupedDocuments = new Map(
        categories
          .filter((category) => category !== null)
          .sort()
          .map((category) => [
            category,
            documents.filter((d) => d.category === category),
          ]),
      );

      const noCategoryDocuments = documents.filter((d) => !d.category);
      if (noCategoryDocuments.length > 0) {
        groupedDocuments.set("Keine Kategorie", noCategoryDocuments);
      }
    }
    if (groupedBy === GroupingType.Date) {
      const currenDate = moment();
      const lastMonth = moment().subtract(1, "month");
      const lastMonthYear = moment().subtract(1, "year");

      groupedDocuments.set(
        "Heute",
        documents.filter(({ createDateUTC }) => {
          return moment.utc(createDateUTC).diff(currenDate, "days") === 0;
        }),
      );
      groupedDocuments.set(
        "Gestern",
        documents.filter(
          ({ createDateUTC }) =>
            moment.utc(createDateUTC).diff(currenDate, "days") < 0 &&
            moment.utc(createDateUTC).diff(currenDate, "days") >= -1,
        ),
      );
      groupedDocuments.set(
        "Letzte 30 Tage",
        documents.filter(({ createDateUTC }) => {
          return (
            moment.utc(createDateUTC).diff(currenDate, "days") < -1 &&
            moment.utc(createDateUTC).diff(currenDate, "days") > -30
          );
        }),
      );

      groupedDocuments.set(
        lastMonth.format("MMMM"),
        documents.filter(({ createDateUTC }) => {
          const createDate = moment.utc(createDateUTC);
          const diffMonths = moment
            .duration(currenDate.diff(createDate))
            .asMonths();

          return (
            diffMonths >= 1 &&
            createDate.month() === lastMonth.month() &&
            createDate.year() === lastMonthYear.year()
          );
        }),
      );

      while (lastMonth.month() > 0) {
        lastMonth.subtract(1, "month");
        groupedDocuments.set(
          lastMonth.format("MMMM"),
          documents.filter(({ createDateUTC }) => {
            const d = moment.utc(createDateUTC);
            return (
              d.month() === lastMonth.month() && d.year() === lastMonth.year()
            );
          }),
        );
      }
      while (lastMonth.year() > currenDate.year() - 3) {
        lastMonth.subtract(1, "year");
        groupedDocuments.set(
          lastMonth.year().toString(),
          documents.filter(({ createDateUTC }) => {
            const d = moment.utc(createDateUTC);
            return d.year() === lastMonth.year();
          }),
        );
      }

      groupedDocuments.set(
        "Älter als 3 Jahre",
        documents.filter(({ createDateUTC }) => {
          return moment.utc(createDateUTC).year() < currenDate.year() - 3;
        }),
      );

      groupedDocuments.forEach((value, key, map) => {
        if (!value || value.length === 0) {
          map.delete(key);
        }
      });
    }

    return groupedDocuments;
  }, [documents, groupedBy]);

  return {
    groupedDocuments,
    categories: Array.isArray(groupedDocuments)
      ? []
      : Array.from(groupedDocuments.keys()),
  };
}
