import React from "react";
import { IconProps } from "../../../types";

export default function ServicesFirePrevention({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M100 31.49A6.527 6.527 0 0 0 93.48 25H26.53a6.527 6.527 0 0 0-6.49 6.53L20 55.997c.182 23.518 16.578 43.797 39.537 48.9.44.09.893.09 1.333 0 22.911-5.308 39.122-25.733 39.09-49.25L100 31.49zM59.697 83.333c-9.373 0-16.97-7.597-16.97-16.97a15.333 15.333 0 0 1 9.56-14.403.893.893 0 0 1 1.243.583 9.64 9.64 0 0 0 3.273 4.667.837.837 0 0 0 1.294-.25 17.557 17.557 0 0 0-2.1-17.78.9.9 0 0 1 1-1.41 31.157 31.157 0 0 1 19.67 28.593c0 9.373-7.598 16.97-16.97 16.97z"
        fillRule="nonzero"
      />
    </svg>
  );
}
