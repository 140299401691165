import { useParams } from "react-router-dom";
import { useGetCustomersQuery } from "../../api/customerApi";
import { LOGO_SRC_SET } from "../../enums";

export default function useGetLogoForPdfDocument(): Props {
  const { customerToken = "" } = useParams();
  const { data: customers = [] } = useGetCustomersQuery();

  const [firstSize] = Object.entries(LOGO_SRC_SET)[0];
  const src = `/${customerToken}/logo_${firstSize}_${customers[0]?.logoVersionStamp}.png`;
  return { src };
}

type Props = {
  src: string;
};
