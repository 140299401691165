import "../../styles/components/userSettings/ManagementDialogDescription.scss";
import React from "react";
import InfoText from "./InfoText";

export default function ManagementDialogDescription({
  setInputText,
  data,
  isOpenError,
  setOpenError,
}: Props): JSX.Element {
  return (
    <section className="management-dialog-description-container">
      <span>
        Geben Sie hier Ihren Registrierungscode ein, um eine weitere
        Hausverwaltung anzubinden.
      </span>
      <label>
        <input
          data-testid="registrierungscode"
          type="text"
          placeholder={"Registrierungscode"}
          onChange={(e) => {
            setInputText(e.target.value);
            setOpenError(false);
          }}
        />
      </label>
      {!data?.succeeded && isOpenError && (
        <InfoText
          isError={true}
          textError="Es ist ein interner Fehler aufgetreten. Bitte versuchen Sie es erneut."
        />
      )}
    </section>
  );
}
type Props = {
  setInputText: (item: string) => void;
  data?: { succeeded: boolean; assignedCustomer: string };
  isOpenError?: boolean;
  setOpenError: (item: boolean) => void;
};
