import useStateChanged from "../../hooks/useStateChanged";
import {
  MeetingAttendConfirmed,
  MeetingState,
  SynchronisationMethod,
  UserRoles,
} from "../../enums";
import { useGetMeetingQuery } from "../../api/meetingApi";
import { useParams } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import MeetingAttendInformation from "./MeetingAttendInformation";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import useAttendMeeting from "../../hooks/meetings/useAttendMeeting";
import useMeetingState from "../../hooks/meetings/useMeetingState";

const initialState = {
  meetingId: "",
  time: new Date().getTime(),
};

export default function MeetingStateChangedDialog(): JSX.Element {
  const [isDialogVersammlungStartOpen, setIsDialogVersammlungStartOpen] =
    useState(false);
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [meetingStateChanged, setMeetingStateChanged] = useStateChanged({
    initialState,
    synchronisationMethod: SynchronisationMethod.MeetingStateChanged,
  });

  const skip = !meetingStateChanged.meetingId;
  const { data, refetch, isFetching } = useGetMeetingQuery(
    {
      ...initialState,
      ...meetingStateChanged,
      customerToken,
      facilityObjectId,
    },
    {
      skip,
    },
  );
  const [attendConfirmed, setAttendConfirmed] = useState(
    MeetingAttendConfirmed.IsToConfirm,
  );
  const customerRoles = useCustomerRoles();
  const isNotEmployee = !customerRoles.includes(UserRoles.Employee);
  const meetingState = useMeetingState(meetingStateChanged.meetingId);
  const { handleAttend } = useAttendMeeting(
    meetingState,
    customerRoles,
    meetingStateChanged.meetingId,
  );

  useEffect(() => {
    if (!skip) {
      refetch();
    }
  }, [meetingStateChanged, skip]);

  const handleConfirm = useCallback(() => {
    setIsDialogVersammlungStartOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setMeetingStateChanged(initialState);
  }, [setMeetingStateChanged]);

  const handleCancelConfirmation = useCallback(() => {
    setMeetingStateChanged(initialState);
    setIsDialogVersammlungStartOpen(false);
  }, [setMeetingStateChanged]);

  const handleJoinMeeting = useCallback(() => {
    setMeetingStateChanged(initialState);
    if (!isNotEmployee) {
      handleAttend();
      return;
    }
    setAttendConfirmed(MeetingAttendConfirmed.InConfirmation);
    handleAttend();
    setIsDialogVersammlungStartOpen(false);
  }, [customerRoles, attendConfirmed, setAttendConfirmed, handleAttend]);

  return (
    <Fragment>
      {isNotEmployee &&
        !skip &&
        !isFetching &&
        data &&
        data.state === MeetingState.Running && (
          <ConfirmDialog
            title="Versammlung wurde gestartet"
            description={`Die Versammlung ${data.description} wurde gestartet, Sie können jetzt teilnehmen.`}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            confirmLabel="Teilnehmen"
          />
        )}
      {isNotEmployee && isDialogVersammlungStartOpen && (
        <ConfirmDialog
          description={
            <MeetingAttendInformation title="An der Versammlung teilnehmen" />
          }
          title="An der Versammlung teilnehmen"
          onConfirm={handleJoinMeeting}
          onCancel={handleCancelConfirmation}
        />
      )}
    </Fragment>
  );
}
