import React, { useCallback, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Participant } from "../../../types";

import "../../../styles/components/meetings/InvitatioDelegationDialogDescription.scss";
import useSetVotingEligibility from "../../../hooks/meetings/useSetVotingEligibility";
import { ParticipantDelegationType } from "../../../enums";
import useFetchParticipants from "../../../hooks/meetings/useFetchParticipants";
import {
  EMPLOYEE_PARTICIPANT,
  getDelegationParticipant,
} from "../../../lib/meeting";
import Button from "../../common/Button";
import VotingInstructions from "./VotingInstructions";
import InfoText from "../../userSettings/InfoText";
import LoadingSpinner from "../../icons/LoadingSpinner";
import VotingEligibilityDropdown from "../participants/VotingEligibilityDropdown";
import CheckboxField from "../../common/form/CheckboxField";
import classNames from "classnames";

export default function InvitationDelegationDialogDescription({
  myRelatedParticipant,
  onCancel,
  actionButtonText,
}: Props): JSX.Element {
  const GRANT_POWER_OF_ATTORNEY = "Vollmacht erteilen";
  const EDIT_POWER_OF_ATTORNEY = "Vollmacht bearbeiten";
  const { participants, isFetching } = useFetchParticipants();
  const selectableVotingEligibilityParticipants = useMemo(
    () => [EMPLOYEE_PARTICIPANT, ...participants],
    [participants],
  );
  const delegateParticipantInitialState = getDelegationParticipant(
    selectableVotingEligibilityParticipants,
    myRelatedParticipant.delegationType,
    myRelatedParticipant.delegate,
  );
  const isDelegated =
    myRelatedParticipant.delegationType !== ParticipantDelegationType.None;
  const title = isDelegated ? "Vollmacht ändern" : "Vollmacht geben";
  const { handleParticipantDelegationSelect } =
    useSetVotingEligibility(myRelatedParticipant);
  const [isDialogVoteInstructionsOpen, setDialogVoteInstructionsOpen] =
    useState(false);

  const onSubmit = useCallback(
    async ({
      rightToVote,
      delegateParticipant,
    }: {
      rightToVote: string;
      delegateParticipant: Participant | undefined;
    }) => {
      if (!rightToVote && delegateParticipant) {
        return { rightToVote: "false" };
      }
      const result =
        await handleParticipantDelegationSelect(delegateParticipant);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return result.error;
      }
      onCancel();
    },
    [],
  );

  const [delegateParticipant, setDelegateParticipant] = useState<
    Participant | undefined
  >(delegateParticipantInitialState);

  const classNameInfo = classNames({
    "invitation-dialog-delegation-wrapper": true,
    instructions:
      actionButtonText !== GRANT_POWER_OF_ATTORNEY &&
      actionButtonText !== EDIT_POWER_OF_ATTORNEY,
  });

  const classNameForm = classNames({
    "invitation-dialog-delegation-form": true,
    instructions:
      actionButtonText === GRANT_POWER_OF_ATTORNEY ||
      actionButtonText === EDIT_POWER_OF_ATTORNEY,
  });
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ delegateParticipant }}
      render={({ handleSubmit, submitErrors, values, hasSubmitErrors }) => {
        const onSelect = useCallback((p: Participant | undefined) => {
          setDelegateParticipant(p);
        }, []);
        return (
          <section className={classNameInfo}>
            {actionButtonText !== GRANT_POWER_OF_ATTORNEY &&
            actionButtonText !== EDIT_POWER_OF_ATTORNEY ? (
              <span className="invitation-dialog-delegation-description">
                Schade, dass Sie nicht persönlich teilnehmen können. Um Ihre
                Stimmrechte wahrzunehmen, wählen Sie einen Bevollmächtigten aus,
                erteilen Sie Weisungen an die Hausverwaltung oder tun Sie
                beides. Wenn Sie für eine Abstimmung keine Weisung erteilen,
                kann der Bevollmächtigte in Ihrem Namen abstimmen. Der
                Bevollmächtigte darf jedoch keine Weisungen ändern, die Sie
                bereits erteilt haben.
              </span>
            ) : (
              <span className="invitation-dialog-delegation-description">
                Schade, dass Sie nicht persönlich teilnehmen können. Um Ihre
                Stimmrechte wahrzunehmen, wählen Sie alternativ einen
                Bevollmächtigten aus:
              </span>
            )}

            <section className="invitation-dialog-delegation-info-wrapper">
              {actionButtonText !== GRANT_POWER_OF_ATTORNEY &&
                actionButtonText !== EDIT_POWER_OF_ATTORNEY && (
                  <div className="instructions-wrapper">
                    <span className="invitation-dialog-delegation-instructions-title">
                      Weisungen:
                    </span>
                    <span className="invitation-dialog-delegation-description">
                      Weisungen sind rechtlich bindende Anweisungen an die
                      Hausverwaltung, wie in Ihrem Namen abgestimmt werden soll.
                      Im Folgenden werden alle Beschlussentwürfe durchgegangen,
                      und Sie können Ihre Stimme dazu abgeben. Ihre Weisungen
                      bleiben unverändert gültig. Für Abstimmungen, in denen
                      keine Weisung erteilt wurde, kann der Bevollmächtigte eine
                      Stimme abgeben.
                    </span>
                    <Button
                      lightblue
                      label="Weisung erteilen"
                      onClick={() => {
                        setDialogVoteInstructionsOpen(true);
                      }}
                      className="invitation-dialog-delegation-instructions-button"
                    />

                    {isDialogVoteInstructionsOpen && (
                      <VotingInstructions
                        isDialogOpen={isDialogVoteInstructionsOpen}
                        setDialogOpen={setDialogVoteInstructionsOpen}
                      />
                    )}
                  </div>
                )}

              <form onSubmit={handleSubmit} className={classNameForm}>
                <div className="invitation-dialog-delegation-select-field-wrapper">
                  <span className="invitation-dialog-delegation-select-field-title">
                    Vollmacht erteilen für:
                  </span>
                  {isFetching && (
                    <div className="voting-eligibility-dropdown">
                      <LoadingSpinner width={32} height={32} />
                      <span> Vollmachten werden geladen</span>
                    </div>
                  )}
                  {!isFetching && (
                    <VotingEligibilityDropdown
                      selectableVotingEligibilityParticipants={
                        selectableVotingEligibilityParticipants
                      }
                      delegateParticipant={values.delegateParticipant}
                      participant={myRelatedParticipant}
                      closestSelector=".dialog"
                      onSelect={onSelect}
                      readOnly={false}
                    />
                  )}
                  <Field
                    name="rightToVote"
                    component={CheckboxField}
                    type="checkbox"
                    label="Hiermit bestätige ich, dass die oben stehende Person mein Stimmrecht ausüben darf."
                    id="rightToVote"
                    required
                    disabled={!values.delegateParticipant}
                  />
                </div>
                <Button
                  label={title}
                  type="submit"
                  lightblue
                  className="invitation-dialog-delegation-instructions-button"
                />
                {submitErrors?.rightToVote && !values.rightToVote && (
                  <div className="invitation-dialog-delegation-info-text">
                    <InfoText
                      isError={true}
                      textError="Bestätigen Sie bitte, dass die oben stehende Person Ihr Stimmrecht ausüben darf."
                    />
                  </div>
                )}
                {hasSubmitErrors && !submitErrors?.rightToVote && (
                  <InfoText
                    isError={true}
                    textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
                  />
                )}
              </form>
            </section>
          </section>
        );
      }}
    />
  );
}

type Props = {
  myRelatedParticipant: Participant;
  onCancel: () => void;
  actionButtonText: string | null;
};
