import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../common/Button";
import {
  useGetUserSettingsQuery,
  usePostUserSettingsMutation,
} from "../../api/userApi";
import SwitchInput from "../common/SwitchInput";

import "../../styles/components/userSettings/Notifications.scss";
import InfoText from "./InfoText";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import SelectField from "../common/SelectField";
import { useGetProhibitDeactivationUserSettingReceiveEmailNotificationQuery } from "../../api/customerApi";

export default function Notifications(): JSX.Element {
  const [isDialogSuccessOpen, setDialogSuccessOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const { user } = useGetUsersMyProfile();
  const { data, isLoading } = useGetUserSettingsQuery(user.sid);
  const [postUserSettings, { isSuccess }] = usePostUserSettingsMutation();
  const { data: isReceiveEmailNotificationDisabled } =
    useGetProhibitDeactivationUserSettingReceiveEmailNotificationQuery();

  const [checkedEmail, setCheckedEmail] = useState(
    data?.receiveEmailNotification,
  );
  const [checkedPush, setCheckedPush] = useState(data?.receivePushNotification);

  const [
    deferEmailNotificationsForPeriodInDays,
    setDeferEmailNotificationsForPeriodInDays,
  ] = useState(data?.deferEmailNotificationsForPeriodInDays);

  const postSettings = useCallback(async () => {
    setDialogSuccessOpen(true);
    if (
      !isLoading &&
      checkedEmail !== undefined &&
      checkedPush !== undefined &&
      deferEmailNotificationsForPeriodInDays !== undefined
    ) {
      await postUserSettings({
        userSid: user.sid,
        userSettingsInfo: {
          sid: user.sid,
          receiveEmailNotification: checkedEmail,
          receivePushNotification: checkedPush,
          deferEmailNotificationsForPeriodInDays:
            deferEmailNotificationsForPeriodInDays,
        },
      });
    }
  }, [
    checkedEmail,
    checkedPush,
    isLoading,
    data,
    deferEmailNotificationsForPeriodInDays,
  ]);

  useEffect(() => {
    if (!isLoading && data) {
      setCheckedEmail(data.receiveEmailNotification);
      setCheckedPush(data.receivePushNotification);
      setDeferEmailNotificationsForPeriodInDays(
        data.deferEmailNotificationsForPeriodInDays,
      );
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!isLoading && data) {
      if (
        data.receiveEmailNotification === checkedEmail &&
        data.receivePushNotification === checkedPush &&
        data.deferEmailNotificationsForPeriodInDays ===
          deferEmailNotificationsForPeriodInDays
      ) {
        setDisabled(true);
      }
    }
  }, [data, checkedEmail, checkedPush]);

  const howOftenOptionsDisabled = useMemo(() => [{ label: "", value: 0 }], []);
  const howOftenOptions = useMemo(
    () => [
      { label: "Sofort", value: 0 },
      {
        label: "Täglich",
        value: 1,
      },
      {
        label: "Alle 2 Tage",
        value: 2,
      },
      {
        label: "Alle 4 Tage",
        value: 4,
      },
      {
        label: "Alle 7 Tage",
        value: 7,
      },
    ],
    [],
  );

  return (
    <section className="user-settings-notifications-container-sections">
      <section className="user-settings-notifications-container">
        <div className="notifications-info-text">
          <h3>Benachrichtigungen einstellen</h3>
          <span className="disable">
            Sie können sich benachrichtigen lassen, z.B. wenn Sie eine Nachricht
            erhalten haben oder einer Ihrer Beiträge kommentiert wurde.
          </span>
          {isReceiveEmailNotificationDisabled?.prohibitDeactivationUserSettingReceiveEmailNotification && (
            <span className="disable">
              Hinweis: Die E-Mail-Benachrichtigungen bleiben immer
              eingeschaltet, damit Ihre Hausverwaltung Sie erreichen kann.
            </span>
          )}
        </div>
        <div className="notifications-info-text">
          <SwitchInput
            id="push"
            label="Ich möchte per Push-Mitteilung benachrichtigt werden."
            defaultChecked={checkedPush}
            onClick={() => {
              setCheckedPush(!checkedPush);
              setDialogSuccessOpen(false);
              setDisabled(false);
            }}
          />
          <SwitchInput
            id="email"
            label="Ich möchte per E-Mail benachrichtigt werden."
            defaultChecked={checkedEmail}
            onClick={() => {
              setCheckedEmail(!checkedEmail);
              setDialogSuccessOpen(false);
              setDisabled(false);
            }}
            disabled={
              isReceiveEmailNotificationDisabled?.prohibitDeactivationUserSettingReceiveEmailNotification
            }
          />
        </div>
      </section>
      <section>
        <section className="user-settings-notifications-container">
          <div className="notifications-info-text">
            <h3>Häufigkeit der E-Mail-Benachrichtigungen</h3>
            <div className="notifications-info-text-email-settings-info">
              <span className="disable">
                über Dokumente und Schwarzes Brett
              </span>
              <span className="notifications-info-text-info">
                i
                <span className="notifications-info-text-hint">
                  <ul className="notifications-info-text-hint-list">
                    <li>
                      Über Nachrichten und bestimmte Ereignisse werden Sie immer
                      sofort benachrichtigt. Dafür kann keine Häufigkeit gewählt
                      werden.
                    </li>
                    <li>
                      Push-Mitteilungen auf Mobilgeräten zusammengefasst
                      bekommen: diese kann nur direkt in Ihrer
                      Handy/Tablet-Einstellungen (Notification Grouping)
                      eingesetzt werden.
                    </li>
                    <li>
                      Die Benachrichtigungen sollen nie komplett ausgeschaltet
                      werden, damit Ihre Hausverwaltung Sie erreichen kann.
                    </li>
                  </ul>
                </span>
              </span>
            </div>
          </div>
          <div className="notifications-info-text">
            <div className="notifications-info-text-select">
              <SelectField
                role={"select"}
                disabled={!checkedEmail}
                value={deferEmailNotificationsForPeriodInDays}
                name="email-settings"
                options={
                  checkedEmail ? howOftenOptions : howOftenOptionsDisabled
                }
                onChange={(e) => {
                  setDeferEmailNotificationsForPeriodInDays(
                    Number(e.target.value),
                  );
                  setDialogSuccessOpen(false);
                  setDisabled(false);
                }}
              />
            </div>

            <Button
              lightblue
              label="Speichern"
              role={"button"}
              className="notifications-info-save"
              onClick={postSettings}
              disabled={isDisabled}
            />
            {!checkedEmail && !checkedPush && !isLoading && (
              <InfoText
                isInfo={true}
                textInfo="Sie haben leider alle Mitteilungen ausgeschaltet. Sie werden also
            über die wichtigen Informationen von Ihrer Hausverwaltung nur dann
            erfahren, wenn Sie sich regelmäßig, aktiv in HOMECASE anmelden und
            Ihre Nachrichten und Dokumente anschauen."
              />
            )}
            {isSuccess && isDialogSuccessOpen && (
              <InfoText
                isSuccess={true}
                textSuccess="Ihre Einstellungen wurden gespeichert."
              />
            )}
          </div>
        </section>
      </section>
    </section>
  );
}
