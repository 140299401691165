import React, { ReactElement } from "react";
import useNavigationItems from "../../hooks/useNavigationItems";
import NavigationList from "./NavigationList";
import { Link } from "react-router-dom";

export default function Navigation({
  management,
  showNavigationList = true,
}: Props): ReactElement {
  const { items } = useNavigationItems(management);
  const year = new Date().getFullYear();

  return (
    <nav className="navigation-list-container" role="navigation">
      <NavigationList
        items={items}
        management={management}
        showNavigationList={showNavigationList}
      />
      <div className="navigation-list-footer">
        <div className="navigation-list-footer-links">
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
        </div>
        <div className="navigation-list-footer-copyright">
          <a href="https://www.domus-software.de">
            &copy; {year} DOMUS Software AG
          </a>
        </div>
      </div>
    </nav>
  );
}

type Props = {
  management?: boolean;
  showNavigationList?: boolean;
};
