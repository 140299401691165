import useFetchPropositionParticipants from "./useFetchPropositionParticipants";
import {
  ParticipantAttendanceType,
  ParticipantDelegationType,
  PropositionVoteType,
} from "../../enums";
import { useMemo } from "react";
import useSearchByQueryParticipants from "./useSearchByQueryParticipants";

export default function useParticipantsForVoteControlCenter(
  propositionId: string,
) {
  const { participants, isError, error, isFetching } =
    useFetchPropositionParticipants(propositionId);
  const { queryParticipants, handleFilterByQuery } =
    useSearchByQueryParticipants(participants);
  const participantsForVote = useMemo(
    () =>
      queryParticipants.filter(
        ({ attendance, delegationType, delegate }) =>
          delegationType ===
            ParticipantDelegationType.VotingEligibilityDelegatedToEmployee ||
          attendance === ParticipantAttendanceType.Locally ||
          (delegationType ===
            ParticipantDelegationType.VotingEligibilityDelegatedToParticipant &&
            queryParticipants.find(
              ({ attendance, id }) =>
                attendance === ParticipantAttendanceType.Locally &&
                id === delegate?.id,
            )),
      ),
    [queryParticipants],
  );

  const participantsAbsent = useMemo(
    () =>
      queryParticipants.filter(({ attendance, delegationType, delegate }) => {
        return (
          attendance === ParticipantAttendanceType.Abscent &&
          (delegationType === ParticipantDelegationType.None ||
            (delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToParticipant &&
              queryParticipants.find(
                ({ attendance, id }) =>
                  attendance === ParticipantAttendanceType.Abscent &&
                  id === delegate?.id,
              )))
        );
      }),
    [queryParticipants],
  );

  const participantsVoted = useMemo(
    () =>
      queryParticipants.filter(
        ({ participantVoting }) =>
          participantVoting.vote !== PropositionVoteType.None,
      ),
    [queryParticipants],
  );

  const participantsNotVoted = useMemo(
    () =>
      queryParticipants.filter(
        ({ participantVoting, attendance, delegationType, delegate }) =>
          participantVoting.vote === PropositionVoteType.None &&
          (attendance !== ParticipantAttendanceType.Abscent ||
            (attendance === ParticipantAttendanceType.Abscent &&
              delegationType ===
                ParticipantDelegationType.VotingEligibilityDelegatedToParticipant &&
              queryParticipants.find(
                ({ attendance, id }) =>
                  attendance !== ParticipantAttendanceType.Abscent &&
                  id === delegate?.id,
              ))),
      ),
    [queryParticipants],
  );

  return {
    handleFilterByQuery,
    participantsForVote,
    participantsAbsent,
    participantsNotVoted,
    participantsVoted,
    participants,
    isError,
    isFetching,
    error,
  };
}
