import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React from "react";
import Skeleton from "react-loading-skeleton";

export default function DashboardContactItemSkeleton(): JSX.Element {
  return (
    <div
      className="dashboard-right-container-item-contact"
      data-testid="item-contact"
    >
      <Skeleton width={250} />
    </div>
  );
}
