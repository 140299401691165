import Skeleton from "react-loading-skeleton";

export default function MessageListSkeleton({
  isMessageSelected,
}: Props): JSX.Element {
  const items = new Array(isMessageSelected ? 0 : 5).fill(null);
  return (
    <ul className="message-list" data-testid="message-list-skeleton">
      {items.map((v, key) => (
        <li className="message-list-item" key={key}>
          <div className="message-list-item-content">
            <span className="message-list-item-header">
              <strong className="message-list-item-header-title">
                <Skeleton />
              </strong>
              <span className="message-list-item-state">
                <Skeleton />
              </span>
            </span>
            <span className="message-list-item-date">
              <Skeleton height={12} />
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
}

type Props = {
  isMessageSelected: boolean;
};
