import PageHeader from "../../common/PageHeader";
import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { TermsOfServices } from "../../../types";
import TermsOfUseVersionItem from "../../termsOfUse/TermsOfUseVersionItem";
import Button from "../../common/Button";
import { Helmet } from "react-helmet";
import useFetchTermsOfUse from "../../../hooks/termsOfUse/useFetchTermsOfUse";
import "../../../styles/components/pages/management/TermsOfUsePage.scss";
import useGetPermissions from "../../../hooks/useGetPermissions";

export default function TermsOfUsePage(): JSX.Element {
  const navigate = useNavigate();
  const { sortedTermsOfUse, isFetching, latestId } = useFetchTermsOfUse();
  const permission = useGetPermissions();

  const pathnameEndsWith =
    window.location.pathname.endsWith("nutzungsbedingungen") ||
    window.location.pathname.endsWith("nutzungsbedingungen/");

  const backTo = !pathnameEndsWith
    ? { to: "./", label: "Nutzungsbedingungen" }
    : undefined;

  return (
    <div className="terms-of-use">
      <Helmet>
        <title>Management - Nutzungsbedingungen - Homecase</title>
      </Helmet>
      <PageHeader title="Nutzungsbedingungen der Verwaltung" backTo={backTo} />
      <div className="terms-of-use-container">
        <Outlet />
        {permission && (
          <Button
            className="version-erstellen-button"
            lightblue
            label="Neue Version erstellen"
            onClick={() => {
              navigate(`${latestId + 1}`);
            }}
            disabled={!permission}
            role={"button"}
          />
        )}

        {[1, 2, 3].map((itemTitle) => (
          <div key={itemTitle}>
            <h2 className="versions-list-title">
              {itemTitle === 1
                ? "Entwurf"
                : itemTitle === 2
                  ? "Online"
                  : "Archiv"}
            </h2>
            <div className="versions-verlauf-liste">
              {!isFetching &&
                sortedTermsOfUse &&
                sortedTermsOfUse.map(
                  (item: TermsOfServices) =>
                    itemTitle === item.state && (
                      <TermsOfUseVersionItem
                        key={item.version}
                        id={item.id}
                        createdAtUtc={item.createdAtUtc}
                        changedAtUtc={item.changedAtUtc}
                        version={item.version}
                        state={item.state}
                      />
                    ),
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
