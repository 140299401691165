import { useParams } from "react-router-dom";
import { useGetRolesQuery } from "../api/customerApi";
import { UserRoles } from "../enums";

export default function useCustomerRoles(
  props: Props = { customerToken: "", facilityObjectId: "" },
): UserRoles[] {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const customerTokenForGetRolesQuery = props.customerToken
    ? props.customerToken
    : customerToken;

  const facilityObjectIdForGetRolesQuery = props.facilityObjectId
    ? props.facilityObjectId
    : facilityObjectId;

  const { data = [] } = useGetRolesQuery(
    {
      customerToken: customerTokenForGetRolesQuery,
      facilityObjectId: facilityObjectIdForGetRolesQuery,
    },
    { skip: !customerTokenForGetRolesQuery },
  );
  return data;
}

type Props = {
  customerToken: string;
  facilityObjectId: string;
};
