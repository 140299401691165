import "../../styles/components/pages/meetings/EventLogPage.scss";
import React, { useCallback } from "react";
import moment from "moment";
import { MeetingEventLog } from "../../types";
import { LogEventType } from "../../enums";
import classNames from "classnames";
import useGetEventLogTitle from "../../hooks/meetings/useGetEventLogTitle";

export default function EventLogItem({ item }: Props) {
  const votingRepeated = item.eventDetails && item.eventDetails.votingRepeated;
  const getEventColorClass = useCallback((eventType: string) => {
    switch (eventType) {
      case "PropositionVotingStarted":
        if (votingRepeated) {
          return "color-proposition-voting-repeated";
        } else {
          return "color-proposition-voting-started";
        }
      case "PropositionVotingEnded":
        return "color-proposition-voting-ended";
      case "ParticipantLeftMeetingInHomecase":
        return "color-participant-left-meeting";
      case "ParticipantDelegatedVotingEligibility":
        return "color-participant-delegated-voting-eligibility";
      case "VotingEligibilityChangedByEmployee":
        return "color-voting-eligibility-changed-by-employee";
      default:
        return "";
    }
  }, []);

  const eventColorClass = getEventColorClass(item.eventType);
  const divClass = classNames("event-log-item", eventColorClass);
  const istType = Object.keys(LogEventType).includes(item.eventType);
  const title = useGetEventLogTitle({ item });

  return (
    istType && (
      <div className={divClass} data-testid="event-log-item-div">
        <span className="event-log-item-time">
          {moment.utc(item.createdAtUtc).local().format("L - HH:mm:ss")}
        </span>
        <span>{title}</span>
      </div>
    )
  );
}

type Props = {
  item: MeetingEventLog;
};
