import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import usePropositionVotingStateChanged from "../../../hooks/meetings/usePropositionVotingStateChanged";
import { UserRoles } from "../../../enums";
import useCustomerRoles from "../../../hooks/useCustomerRoles";

export default function PropositionVotingStateChangedDialog(): JSX.Element {
  const navigate = useNavigate();
  const { showDialog, reset, queryProps, data } =
    usePropositionVotingStateChanged();

  const handleConfirm = useCallback(() => {
    reset();
    navigate(
      `${queryProps.meetingId}/agenda/${queryProps.agendaItemId}#${queryProps.propositionId}`,
    );
  }, [navigate, reset]);

  const customerRoles = useCustomerRoles();
  const isNotEmployee = !customerRoles.includes(UserRoles.Employee);

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Fragment>
      {isNotEmployee && showDialog && data && (
        <ConfirmDialog
          title="Eine Abstimmung wurde gestartet"
          description={data.decision || data.propositionContent}
          onConfirm={handleConfirm}
          confirmLabel="Zur Abstimmung"
          onCancel={handleCancel}
          showCancelButton={false}
          showCloseButton
        />
      )}
    </Fragment>
  );
}
