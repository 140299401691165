import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function LocationIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="3 3 24 24"
      className={className}
    >
      <path
        d="M15 5.75c3.584 0 6.5 2.835 6.5 6.347 0 .382-.04.771-.12 1.165-.23 1.149-.774 2.316-1.563 3.489l.278-.001c1.22 0 2.302.78 2.687 1.938l.612 1.833a2.833 2.833 0 0 1-2.688 3.729H9.294a2.833 2.833 0 0 1-2.688-3.729l.611-1.832a2.832 2.832 0 0 1 2.688-1.939h.278c-.79-1.172-1.333-2.34-1.563-3.488a5.92 5.92 0 0 1-.12-1.165c0-3.512 2.916-6.347 6.5-6.347zm-3.248 13H9.905a.832.832 0 0 0-.79.57l-.612 1.834a.833.833 0 0 0 .79 1.096h11.413c.57 0 .97-.557.79-1.096l-.611-1.834a.833.833 0 0 0-.79-.57l-1.847.002a24.698 24.698 0 0 1-2.052 2.042l-.312.271-.26.216a1 1 0 0 1-1.248 0l-.26-.216a20.803 20.803 0 0 1-.312-.27l-.34-.308a24.837 24.837 0 0 1-1.59-1.6l-.122-.137zM15 7.75c-2.491 0-4.5 1.953-4.5 4.347 0 .247.027.504.08.771.262 1.302 1.133 2.775 2.428 4.282a22.403 22.403 0 0 0 1.81 1.865l.182.165.182-.165a22.403 22.403 0 0 0 1.81-1.865c1.295-1.507 2.167-2.98 2.427-4.282.054-.267.081-.524.081-.771 0-2.394-2.008-4.347-4.5-4.347zm2.004 2.497a2.833 2.833 0 1 1-4.007 4.006 2.833 2.833 0 0 1 4.007-4.006zM14.41 11.66a.833.833 0 1 0 1.178 0l-.085-.075a.834.834 0 0 0-1.093.075z"
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
      />
    </svg>
  );
}
