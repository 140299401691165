import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  bulletinBoardApi,
  useDeleteBulletinBoardAttachmentMutation,
  usePutBulletinBoardPostMutation,
} from "../../api/bulletinBoardApi";
import { append, remove } from "../../slices/fileUploadSlice";
import { isSameFile } from "../../lib";

export default function useUpdatePost(
  bulletinBoardPostId: number,
  page: number,
) {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const dispatch = useDispatch();
  const [updatePost, { isLoading }] = usePutBulletinBoardPostMutation();
  const [deleteBulletinBoardAttachment] =
    useDeleteBulletinBoardAttachmentMutation();

  const deleteAttachment = useCallback(
    async (deleteFiles: { id: number; name: string }[]) => {
      await deleteBulletinBoardAttachment({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        deleteFiles,
      });
      deleteFiles.map((item) => {
        dispatch(remove(item));
      });
    },
    [],
  );

  const save = useCallback(
    async (
      values: { post: string; attachments?: File[] },
      initialAttachments: File[] = [],
      filesInitialValues: {
        id: number;
        name: string;
        contentSize: number;
      }[] = [],
    ) => {
      const { attachments = [] } = values;
      const addedFiles = attachments.filter(
        (a) => !initialAttachments.find((b) => isSameFile(a, b)),
      );
      const removedFiles = initialAttachments.filter(
        (a) => !attachments.find((b) => isSameFile(a, b)),
      );

      const filesToDelete = filesInitialValues.filter((a) =>
        removedFiles.find((b) => a.name === b.name && a.contentSize === b.size),
      );

      await deleteAttachment(filesToDelete);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data, error } = await updatePost({
        facilityObjectId,
        customerToken,
        bulletinBoardPostId,
        text: values.post,
        page,
      });

      if (error) {
        return error;
      }

      if (data && addedFiles && addedFiles.length > 0) {
        dispatch(
          append({
            referenceId: bulletinBoardPostId,
            files: addedFiles,
            customerToken,
            facilityObjectId,
            bulletinBoardPostId,
            endpoint:
              bulletinBoardApi.endpoints.postBulletinBoardPostAttachment,
          }),
        );
      }
    },
    [],
  );

  return { save, isLoading };
}
