import { useParams } from "react-router-dom";
import { useGetAgendaItemsQuery } from "../../api/agendaItemApi";
import { useMemo } from "react";
import { AgendaItemType, PropositionLegalValidityType } from "../../enums";
import {
  PropositionsResultForPDF,
  PropositionsResultForPDFRequest,
} from "../../types";

export default function useGetAgendaItemWithDetailsForPdfDocument(): Props {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const { data: agendaItems = [] } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    includePropositions: true,
  });

  const agendaItemsWithPropositions = useMemo(() => {
    return agendaItems.filter(
      (item) =>
        item.propositions &&
        item.propositions.length > 0 &&
        item.type !== AgendaItemType.ParticipantsRegistration,
    );
  }, [agendaItems]);

  const agendaItemsWithoutPropositions = useMemo(() => {
    return agendaItems.filter(
      (item) =>
        item.type === AgendaItemType.ParticipantsRegistration ||
        (item.propositions && item.propositions.length === 0),
    );
  }, [agendaItems]);

  const allPropositionsWithDetails: PropositionsResultForPDFRequest[] =
    useMemo(() => {
      return agendaItemsWithPropositions.flatMap((item) =>
        (item.propositions || []).map((proposition) => ({
          proposition: proposition,
          propositionId: proposition.id,
          agendaItemId: item.id,
          agendaItemNumber: item.number,
          agendaItemTitle: item.title || "",
          propositionTitle: proposition.title || "",
          decisionLegalValidity: proposition.decisionLegalValidity,
          statusTitle: proposition.statusTitle,
          categoryTitle: proposition.categoryTitle,
          description: item.description ? item.description : "",
          votingType: proposition.votingType,
        })),
      );
    }, [agendaItemsWithPropositions]);

  const allItemsPropositionsWithoutDetails: PropositionsResultForPDF[] =
    useMemo(() => {
      return agendaItemsWithoutPropositions.map((item) => {
        return {
          votingResult: null,
          propositionId: "",
          agendaItemId: item.id,
          agendaItemNumber: item.number,
          agendaItemTitle: item.title || "",
          description: item.description || "",
          propositionTitle: "",
          decisionLegalValidity: PropositionLegalValidityType.None,
          statusTitle: "",
          categoryTitle: "",
          votingType: null,
        };
      });
    }, [agendaItemsWithoutPropositions]);

  return { allPropositionsWithDetails, allItemsPropositionsWithoutDetails };
}

type Props = {
  allPropositionsWithDetails: PropositionsResultForPDFRequest[];
  allItemsPropositionsWithoutDetails: PropositionsResultForPDF[];
};
