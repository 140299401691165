import React from "react";
import { Text, View } from "@react-pdf/renderer";
import PdfStyles from "./PdfStyles";

export default function PdfDocumentMeetingResultsListFooter() {
  return (
    <View style={PdfStyles.footer}>
      <Text>Zusammenfassung der Eigentümerversammlung</Text>
      <Text
        render={({ pageNumber, totalPages }) =>
          `Seite ${pageNumber} von ${totalPages}`
        }
      />
    </View>
  );
}
