import Home from "../components/icons/Home";
import Neighbors from "../components/icons/Neighbors";
import Messages from "../components/icons/Messages";
import Documents from "../components/icons/Documents";
import Report from "../components/icons/Report";
import Invoices from "../components/icons/Invoices";
import Meetings from "../components/icons/Meetings";
import { NavigationItem, NavigationRawItem } from "../types";
import People from "../components/icons/management/People";
import SettingsIcon from "../components/icons/SettingsIcon";
import House from "../components/icons/management/House";
import Buildings from "../components/icons/management/Buildings";
import Process from "../components/icons/management/Process";
import { useParams } from "react-router-dom";
import KeyIcon from "../components/icons/KeyIcon";
import Fluks from "../components/icons/Fluks";

const NAVIGATION_CONFIG_MAP = {
  BulletinBoard: {
    icon: Home,
    title: "Schwarzes Brett",
    navigate: true,
    to: "schwarzesbrett",
  },
  Contacts: {
    icon: Neighbors,
    title: "Kontakte",
    navigate: true,
    to: "kontakte",
  },
  Activities: {
    icon: Messages,
    title: "Nachrichten",
    navigate: true,
    to: "nachrichten",
  },
  Documents: {
    icon: Documents,
    title: "Dokumente",
    navigate: true,
    to: "dokumente",
  },
  Reports: {
    icon: Report,
    title: "Berichte",
    navigate: false,
    to: "berichte",
  },
  VoucherAudits: {
    icon: Invoices,
    title: "Belegprüfungen",
    navigate: false,
    to: "belegpruefungen",
  },
  Meetings: {
    icon: Meetings,
    title: "Versammlungen",
    navigate: true,
    to: "versammlungen",
  },
  Owners: {
    title: "Eigentümergemeinschaft",
    navigate: true,
    to: "nachbarn",
  },
  Neighbors: {
    title: "Nachbarn",
    navigate: true,
    to: "nachbarn",
  },
  ServiceProviders: {
    title: "Dienstleister",
    navigate: true,
    to: "dienstleister",
  },
  FacilityObjectContacts: {
    title: "Verwaltung",
    navigate: true,
    to: "verwalter",
  },
};

export const NAVIGATION_MANAGEMENT_CONFIG = [
  {
    icon: Buildings,
    title: "Objekte",
    to: "objekte",
    navigate: false,
  },
  {
    icon: People,
    title: "Mitarbeiter",
    to: "mitarbeiter",
    navigate: true,
  },
  {
    icon: KeyIcon,
    title: "Berechtigungen",
    to: "berechtigungen",
    navigate: true,
  },
  {
    icon: SettingsIcon,
    title: "Zentrale Einstellungen",
    to: "einstellungen",
    navigate: true,
  },
  {
    icon: Process,
    title: "Nutzungsbedingungen",
    to: "nutzungsbedingungen",
    navigate: true,
  },
  {
    icon: Fluks,
    title: "fluks Anbindung",
    to: "fluks",
    navigate: true,
  },
  {
    icon: House,
    title: "Zurück zu HOMECASE",
    to: `/`,
    navigate: true,
  },
];

export function mapRawNavigationManagementItems() {
  const { customerToken = "" } = useParams();
  return NAVIGATION_MANAGEMENT_CONFIG.map((item) =>
    item.title === "Zurück zu HOMECASE"
      ? {
          icon: House,
          title: "Zurück zu HOMECASE",
          to: `/${customerToken}`,
          navigate: false,
        }
      : item,
  );
}

export function mapRawNavigationItems(
  items: NavigationRawItem[],
): NavigationItem[] {
  return items
    .map(({ type, extendedProps, subItems }) => {
      const item = NAVIGATION_CONFIG_MAP[type]
        ? ({ ...NAVIGATION_CONFIG_MAP[type] } as unknown as NavigationItem)
        : undefined;
      if (item && extendedProps) {
        const props = Object.entries(extendedProps);
        props.forEach(
          ([name, value]) => (item.to = item.to.replace(name, value)),
        );
      }
      if (item && subItems) {
        item.subItems = mapRawNavigationItems(subItems);
      }
      return item;
    })
    .filter((item): item is NavigationItem => item !== undefined);
}
