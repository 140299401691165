import React from "react";
import { IconProps } from "../../types";

export default function Neighbors({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M15 9.667c.845 0 1.333.666 1.333.666 1.15 0 1.834 1.049 1.834 2.412 0 .68-.376 1.393-.376 1.393s.209.07.209.539c0 .675-.545 1.052-.545 1.052-.076.672-1.122 1.657-1.122 1.657v1.123c.562 1.684 4 .561 4 4.491H9.667c0-3.917 3.438-2.807 4-4.491v-1.123s-1.046-.985-1.122-1.657c0 0-.545-.204-.545-1.052 0-.437.209-.539.209-.539l-.023-.033c-.091-.14-.436-.713-.436-1.422 0-1.935 1.455-3.016 3.25-3.016zM20.333 7c1.045 0 1.334.667 1.334.667C22.672 7.667 23 8.786 23 10c0 .606-.225 1.107-.225 1.107.105.062.225.22.225.6 0 .379-.345.804-.519.817-.066.599-.593 1.365-.814 1.476v1c.491 1.5 3.333.5 3.333 4h-5.34a26.39 26.39 0 0 0-1.993-1.034s1.666-1.643 1.666-3.29c0-.223-.026-.424-.072-.605.124-.365.239-.836.239-1.326 0-1.567-.701-2.812-1.779-3.39C17.941 8.02 18.851 7 20.333 7zM9.401 7c1.687 0 2.399.975 2.72 2.202-1.064.767-1.704 1.992-1.704 3.48 0 .586.251 1.63.548 2.088-.131.03-.505.059-.632.059v.672c.158.412.51.658.926.866.19.506.712 1.174 1.074 1.561 0 0-1.515.788-1.984 1.072H5c0-2.609 2.791-2.087 3.333-3.499v-.665c-1.961 0-2.589-.778-2.589-.778.293-.235.345-.857.351-1.626v-.338l-.001-.352v-.361c.005-1.763.174-3.714 2.24-3.714 0 0 .457-.667 1.067-.667z"
        fillRule="evenodd"
      />
    </svg>
  );
}
