import { SyntheticEvent, useCallback } from "react";

export default function useDownload(
  url: string,
  name: string,
  downloadable: boolean | undefined,
) {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.target = "_blank";

  return useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (downloadable) {
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
      }
    },
    [url, downloadable],
  );
}
