import React, { ReactElement, SyntheticEvent } from "react";
import Button from "../Button";

import "../../../styles/components/common/Dialog.scss";
import IconButton from "../IconButton";
import CloseIcon from "../../icons/CloseIcon";
import classNames from "classnames";

export default function AlertDialog({
  title,
  description,
  onConfirm,
  confirmLabel = "OK",
  showCloseButton = false,
  className,
}: Props): JSX.Element {
  const classNameInfo = classNames({
    "dialog dialog-alert": true,
    "dialog-delegate": className,
  });
  return (
    <div className="dialog-background-layer">
      <div
        className={classNameInfo}
        role="alertdialog"
        aria-modal
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
        data-testid="alert-dialog"
      >
        {showCloseButton && (
          <div className="close-button-wrapper">
            <IconButton
              onClick={onConfirm}
              className="dialog-button-close"
              icon={CloseIcon}
            />
          </div>
        )}
        <h2 className="dialog-title" id="dialog1Title">
          {title}
        </h2>
        <div
          className="dialog-description"
          id="dialog1Desc"
          data-testid="description"
        >
          {description}
        </div>
        <div className="dialog-actions">
          <Button
            lightblue
            label={confirmLabel}
            onClick={onConfirm}
            className="dialog-actions-confirm"
            aria-controls="form"
          />
        </div>
      </div>
    </div>
  );
}

type Props = {
  description: string | ReactElement;
  title: string | ReactElement;
  onConfirm: (e: SyntheticEvent) => void | Promise<void>;
  confirmLabel?: string;
  showCloseButton?: boolean;
  className?: string;
};
