import { CombineFormValidatorOption, FormValidator, Validator } from "../types";
import validator from "validator";
import { readFileBinary } from "./index";

const INVALID_FILE_BYTES = [
  [0x4d, 0x5a],
  [0x11, 0xe0],
  [0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb11, 0xae1],
  [0x40, 0x72],
];

export const ACCEPT_FILES = {
  "image/*": [".jpg", ".png", ".jpeg", ".bmp", ".gif", ".tiff", ".tif"],
  "application/pdf": [".pdf"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "text/*": [".log", ".txt", ".rtx", ".csv", ".rtf"],
  "application/msword": [".doc", ".dot"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.oasis.opendocument.formula": [".odf"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.chart": [".odc"],
  "application/msexcel": [".xls", ".xla"],
  "application/vnd.ms-excel": [".xls", ".xla"],
  "application/mspowerpoint": [".ppt", ".ppz", ".pps", ".pot"],
  "application/vnd.ms-powerpoint": [".ppt", ".ppz", ".pps", ".pot"],
  "video/*": [],
};

const REGEXP_EXT = new RegExp(
  Object.values(ACCEPT_FILES).flat().join("$|") + "+",
  "i",
);
const REGEXP_MIME_TYPE = new RegExp(
  "^" + Object.keys(ACCEPT_FILES).join("|^"),
  "i",
);

export function formValidator(
  validateWith: Validator,
  errorMessage: string,
): FormValidator {
  return function (value?: string): undefined | string {
    return value !== undefined && validateWith(value)
      ? undefined
      : errorMessage;
  };
}

export function combineValidators(
  validatorOptions: CombineFormValidatorOption[],
  ...validators: FormValidator[]
): FormValidator[] {
  return [
    validators,
    validatorOptions.map(({ validator, errorMessage }) =>
      formValidator(validator, errorMessage),
    ),
  ].flat();
}

export default function validate(...validators: FormValidator[]) {
  return function (value?: string) {
    return validators.reduce<undefined | string>(
      (error, validator) => error || validator(value),
      undefined,
    );
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function validateFiles(...validators: Function[]) {
  return function (value?: File[]) {
    return validators.reduce<
      undefined | { errorMessage: string; name: string }[]
    >((error, validator) => error || validator(value), undefined);
  };
}

export function validateCurrency(
  errorMessage = "Bitte geben Sie eine Währung an!",
) {
  return formValidator(validator.isCurrency, errorMessage);
}

export function validateDate(
  errorMessage = "Bitte geben Sie ein gültiges Datum an!",
) {
  return formValidator(validator.isDate, errorMessage);
}

export function validateNumber(
  errorMessage = "Bitte geben Sie einen gültigen Zahlenwert ein!",
) {
  return formValidator(validator.isNumeric, errorMessage);
}

export function validateIban(
  errorMessage = "Bitte geben Sie einen gültigen IBAN ein!",
) {
  return formValidator(validator.isIBAN, errorMessage);
}

export function validateRequired(
  errorMessage = "Dieses Feld darf nicht leer sein.",
) {
  return formValidator((value) => {
    return !!value;
  }, errorMessage);
}

export function validateGreaterThen(
  number: number,
  errorMessage = "Anzahl der Anwesenden soll nicht 0 und  kleiner als die Summe der eingegebenen Stimmen sein.",
) {
  return formValidator((value) => {
    return Number(value) >= number && Number(value) !== 0;
  }, errorMessage);
}

export function validateFileType(errorMessage = "Ungültiger Dateityp") {
  return (value: File[]) => {
    if (!value) {
      return;
    }
    const files = [...value];
    const error = files
      .map((file) => {
        const { type, name } = file;
        if (REGEXP_EXT.test(name) && REGEXP_MIME_TYPE.test(type)) {
          return undefined;
        }
        return { errorMessage, name: file.name };
      })
      .filter((v) => v !== undefined);

    return error.length ? error : undefined;
  };
}

export function validateFileFirstBytes(
  errorMessage = "Ungültiges Dateiformat",
) {
  return async (value: File[]) => {
    if (!value) {
      return;
    }
    const files = [...value];

    const validation = await Promise.all(
      files.map(async (file) => {
        const buffer = await readFileBinary(file);
        const array = new Uint8Array(buffer);
        const isInvalidFile = INVALID_FILE_BYTES.find((bytes) => {
          const firstBytes = array.slice(0, bytes.length);
          return firstBytes.every((a, b) => a === bytes[b]);
        });
        return isInvalidFile ? { errorMessage, name: file.name } : undefined;
      }),
    );
    const error = validation.filter((v) => v !== undefined);

    return error.length ? error : undefined;
  };
}
