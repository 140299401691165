import "../../../styles/components/meetings/proposition/PropositionSimpleVotingInformation.scss";
import React from "react";
import { PropositionVotingState } from "../../../enums";
import InformationBox from "../../common/InformationBox";

export default function PropositionSimpleVotingInformation({
  votingState,
}: Props): JSX.Element {
  return (
    <div
      className="agenda-item-proposition-simple-voting-information"
      data-testid="simple-voting-information"
    >
      {votingState === PropositionVotingState.None && (
        <div className="simple-voting-container-info-text">
          <InformationBox text="Dies ist eine einfache Versammlung, die Hausverwaltung wird die Stimmen zusammengezählt eingeben." />
        </div>
      )}
    </div>
  );
}

type Props = {
  votingState: PropositionVotingState;
};
