import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";
import { Fragment, ReactElement } from "react";

import "../../../styles/components/common/form/Select.scss";
import SelectField from "../SelectField";

export default function Select({
  input,
  meta,
  className,
  required,
  options = [],
  defaultOption,
  ...rest
}: FieldRenderProps<string | undefined, HTMLSelectElement>): ReactElement {
  const selectClassName = classNames(className, {
    "select-error": (meta.error || meta.submitError) && meta.touched,
  });

  return (
    <SelectField
      options={options}
      defaultOption={defaultOption}
      className={selectClassName}
      required={required}
      {...input}
      {...rest}
    >
      <Fragment>
        {(meta.error || meta.submitError) && meta.touched && (
          <div className="select-field-error" role="alert">
            {meta.error || meta.submitError}
          </div>
        )}
      </Fragment>
    </SelectField>
  );
}
