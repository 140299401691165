import { PictureFillStrategy } from "../../enums";
import { useGetFacilityObjectPictureQuery } from "../../api/facilityManagementApi";
import { useMemo } from "react";
import House from "../icons/management/House";

export default function FacilityObjectPicture(props: Props): JSX.Element {
  const { data, isFetching } = useGetFacilityObjectPictureQuery(props);
  const url = useMemo(
    () => (data ? URL.createObjectURL(data) : undefined),
    [data],
  );

  return (
    <div className="facility-object-picture">
      {isFetching && !url && (
        <House
          width={128}
          height={128}
          className="facility-object-picture-icon"
        />
      )}
      {url && (
        <img
          src={url}
          className="facility-object-picture-image"
          data-testid="facility-object-picture-image"
        />
      )}
    </div>
  );
}

type Props = {
  facilityObjectId: string;
  customerToken: string;
  width: number;
  height: number;
  fillStrategy: PictureFillStrategy;
};
