import { useParams } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  activitiesApi,
  usePostActivityContactMutation,
} from "../../api/activitiesApi";
import { SubmissionErrors, FORM_ERROR, FormApi } from "final-form";
import { append } from "../../slices/fileUploadSlice";
import moment from "moment";

export default function useCreateMessageAnswer(): UseCreateMessageAnswer {
  const { customerToken = "", messageId = "" } = useParams();
  const dispatch = useDispatch();

  const [postActivityContact, { isLoading, isError, error, isSuccess }] =
    usePostActivityContactMutation();

  const save = useCallback(
    async (values: Record<string, string | File[]>) => {
      const { documents } = values;
      const onlineDateUTC = moment.utc().format();
      if (values.text) {
        const result = await postActivityContact({
          customerToken,
          activityId: messageId,
          text: values.text as string,
          textMediaType: "text/plain",
          onlineDateUTC,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { [FORM_ERROR]: result.error };
        }
      }
      if (documents) {
        dispatch(
          append({
            referenceId: messageId,
            files: documents as File[],
            customerToken,
            onlineDateUTC,
            endpoint: activitiesApi.endpoints.postActivityDocument,
          }),
        );
      }
    },
    [messageId, customerToken],
  );

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    save,
    messageId,
  };
}

type UseCreateMessageAnswer = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error?: FetchBaseQueryError | SerializedError;
  messageId: string;
  save: (
    values: Record<string, string | File[]>,
    form: FormApi,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
};
