import React from "react";
import { useFormState } from "react-final-form";
import InfoText from "../../userSettings/InfoText";

import "../../../styles/components/meetings/InvitationDialogDescription.scss";

export default function InvitationDeclineDialogDescription(): JSX.Element {
  const { hasSubmitErrors } = useFormState();

  return (
    <section className="invitation-dialog-wrapper">
      <span className="invitation-dialog-question">
        Wollen Sie wirklich Einladung absagen? (Sie können Ihre Antworten später
        bei Bedarf ändern)
      </span>

      {hasSubmitErrors && (
        <InfoText
          isError={true}
          textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
        />
      )}
    </section>
  );
}
