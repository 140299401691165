import { FieldRenderProps } from "react-final-form";
import React, { Fragment, ReactElement } from "react";

import "../../../styles/components/common/SwitchInput.scss";
import SwitchInput from "../SwitchInput";

export default function SwitchInputField({
  input,
  label,
  infoText,
  required,
  infoNotification,
}: FieldRenderProps<string | undefined, HTMLInputElement>): ReactElement {
  return (
    <Fragment>
      <SwitchInput
        {...input}
        label={label}
        infoText={infoText}
        required={required}
        infoNotification={infoNotification}
      />
    </Fragment>
  );
}
