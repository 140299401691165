import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React, { Fragment, ReactElement } from "react";
import { useGetMeetingsQuery } from "../../api/meetingApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import DashboardUpcomingDatesSkeleton from "../skeleton/DashboardUpcomingDatesSkeleton";
import { MeetingState } from "../../enums";

export default function UpcomingDates(): ReactElement {
  const navigate = useNavigate();
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data = [], isFetching } = useGetMeetingsQuery({
    customerToken,
    facilityObjectId,
  });

  const upcomingDates = data.filter(
    (item) =>
      item.state === MeetingState.Running ||
      item.state === MeetingState.OwnerAndEmployeePreview,
  );

  return upcomingDates.length > 0 ? (
    <div className="dashboard-right-container-item">
      <div
        className="dashboard-right-container-item-title-container"
        onClick={() => {
          navigate("../versammlungen");
        }}
      >
        <h3 className="dashboard-right-container-item-title">
          AKTUELLE TERMINE
        </h3>
        <span>Alle...</span>
      </div>
      <div>
        {upcomingDates.slice(0, 3).map((item) => (
          <Link
            className="dashboard-right-container-item-dates"
            to={`/${customerToken}/objekte/${facilityObjectId}/versammlungen/${item.id}`}
            key={item.id}
            data-testid="link"
          >
            <div className="date-container">
              <span>{moment(item.startDate).format("MMM")}</span>
              <span className="date-container-date">
                {moment(item.startDate).format("D")}
              </span>
            </div>
            <div className="date-container">
              <p className="date-container-title">{item.description}</p>
              <p>{moment(item.startDate).format("LLL")}</p>
            </div>
          </Link>
        ))}
        {isFetching && (
          <Fragment>
            <DashboardUpcomingDatesSkeleton />
          </Fragment>
        )}
      </div>
    </div>
  ) : (
    <Fragment />
  );
}
