import useFetchNotifications from "../notifications/useFetchNotifications";
import { useMemo } from "react";
import { UserEventType } from "../../enums";
import { BulletinBoardPostCommentCreatedUserEventModel } from "../../types";

export default function useBulletinBoardNotificationPostIds(read = false) {
  const { userEvents } = useFetchNotifications();

  return useMemo(() => {
    const bulletinBoardEvents = userEvents
      .filter(
        ({ eventType, isRead }) =>
          [
            UserEventType.BulletinBoardPostCommentCreatedUserEvent,
            UserEventType.BulletinBoardPostCreatedUserEvent,
          ].includes(eventType) && isRead === read,
      )
      .map(
        ({ payload }) => payload,
      ) as unknown as BulletinBoardPostCommentCreatedUserEventModel[];
    return bulletinBoardEvents.map(({ postId }) => postId);
  }, [userEvents]);
}
