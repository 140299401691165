import React, { Fragment, useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useProvidersFiltered from "../../hooks/servicesProviders/useProvidersFiltered";
import ContactPersonCard from "../common/contactPerson/ContactPersonCard";
import ContactPersonCardSkeleton from "../skeleton/ContactPersonCardSkeleton";
import ContactPersonCardDialog from "../common/contactPerson/ContactPersonCardDialog";
import { Contact } from "../../types";
import FetchApiError from "../common/FetchApiError";
import useFetchContactCardInfo from "../../hooks/useFetchContactCardInfo";

import "../../styles/components/pages/ServiceProvidersPage.scss";
import useScrollToSection from "../../hooks/common/useScrollToSection";

const skeletonArray = Array(3).fill(undefined);

type Props = {
  contactsListServiceProviders: Contact[];
};

export default function ServiceProvidersPage({
  contactsListServiceProviders,
}: Props): JSX.Element {
  const [modelContent, setModelContent] = useState<null | {
    provider: Contact;
    resource: string | undefined;
  }>(null);

  const {
    serviceProvidersListFetching,
    serviceProvidersListIsError,
    serviceProvidersListError,
  } = useFetchContactCardInfo();

  const { filteredServicesProviders, getFindIcon, resourceGroups } =
    useProvidersFiltered(contactsListServiceProviders);

  const handleCloseContactPersonCardDialog = useCallback(() => {
    setModelContent(null);
  }, []);

  const { ref, rootRef } = useScrollToSection("dienstleister", [0]);

  return (
    <div
      className="service-providers"
      data-testid="service-providers__content"
      ref={rootRef}
    >
      <h2>Dienstleister</h2>
      {!serviceProvidersListFetching &&
        resourceGroups?.map((resource, index) => (
          <Fragment key={index}>
            <p className="service-providers-container-headline">{resource}</p>
            <div
              className="service-providers-container"
              key={index}
              ref={index === 1 ? ref : undefined}
            >
              {filteredServicesProviders(resource).map((provider) => (
                <ContactPersonCard
                  key={provider.id}
                  firstName={provider.firstName}
                  lastName={provider.lastName}
                  responsibilities={provider.responsibilities}
                  icon={getFindIcon(
                    provider.contractedResources as string[],
                    resource as string,
                  )}
                  contractedResources={provider.contractedResources}
                  phone={provider.communication?.phones}
                  email={provider.communication?.emails}
                  onClick={() => {
                    setModelContent({ provider, resource });
                  }}
                />
              ))}
            </div>
          </Fragment>
        ))}
      {modelContent && (
        <ContactPersonCardDialog
          firstName={modelContent.provider.firstName}
          lastName={modelContent.provider.lastName}
          responsibilities={modelContent.provider.responsibilities}
          icon={getFindIcon(
            modelContent.provider.contractedResources,
            modelContent.resource,
          )}
          phone={modelContent.provider.communication?.phones}
          email={modelContent.provider.communication?.emails}
          street={modelContent.provider.street}
          zip={modelContent.provider.zip}
          city={modelContent.provider.city}
          webpage={modelContent.provider.communication?.website}
          fax={modelContent.provider.communication?.fax}
          contractedResources={modelContent.provider.contractedResources}
          onClose={handleCloseContactPersonCardDialog}
        />
      )}
      {serviceProvidersListFetching &&
        !serviceProvidersListIsError &&
        skeletonArray.map((v, key) => (
          <div className="service-providers-container" key={key}>
            <h2 className="service-providers-container-headline">
              <Skeleton height={33} width={300} />
            </h2>
            <div className="service-providers-contact-card-container">
              {skeletonArray.map((v, key) => (
                <ContactPersonCardSkeleton key={key} />
              ))}
            </div>
          </div>
        ))}
      {serviceProvidersListIsError && (
        <FetchApiError error={serviceProvidersListError} />
      )}
    </div>
  );
}
