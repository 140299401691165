import { Participant, SortState } from "../../types";
import { useMemo } from "react";
export function sortParticipants(
  participants: Participant[],
  key: SortKey,
  sortDirection: "descending" | "ascending" | "none",
): Participant[] {
  return sortDirection === "none"
    ? participants
    : participants.slice().sort((a, b) => {
        let x;
        let y;
        switch (key) {
          case "name":
            x = `${a.lastName}${a.firstName}`;
            y = `${b.lastName}${b.firstName}`;
            break;
          case "objectNumber":
            x = a.facilityAssignments
              ? a.facilityAssignments[0].facilityObjectNumber
              : 0;
            y = b.facilityAssignments
              ? b.facilityAssignments[0].facilityObjectNumber
              : 0;
            break;
          case "facilityAssignments":
            x = a.facilityAssignments
              ? a.facilityAssignments
                  .map(({ facilityUnitDescription }) => facilityUnitDescription)
                  .join(" ")
              : "";
            y = b.facilityAssignments
              ? b.facilityAssignments
                  .map(({ facilityUnitDescription }) => facilityUnitDescription)
                  .join(" ")
              : "";
            break;
          default:
            x = a[key];
            y = b[key];
        }

        if (x < y) {
          return sortDirection === "ascending" ? -1 : 1;
        }

        if (x > y) {
          return sortDirection === "ascending" ? 1 : -1;
        }
        return 0;
      });
}

export default function useSortParticipants(
  participants: Participant[],
  sort: SortState,
): Participant[] {
  return useMemo(
    () =>
      sortParticipants(
        participants,
        sort.sortBy as SortKey,
        sort.sortDirection,
      ),
    [participants, sort],
  );
}

type SortKey =
  | "name"
  | "attendance"
  | "delegationType"
  | "facilityAssignments"
  | "objectNumber";
