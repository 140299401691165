import { useCallback } from "react";
import moment from "moment";
import useIdentity from "../useIdentity";
import { useMarkUserEventsListAsReadMutation } from "../../api/userApi";

export default function useMarkUserEventsListAsRead(
  eventIds: string[],
  cb?: () => void,
): UseMarkUserEventsListAsRead {
  const identity = useIdentity();
  const userSid = identity?.userSid ?? "";

  const [markUserEventsListAsRead] = useMarkUserEventsListAsReadMutation();

  const markUserEventsAsRead = useCallback(async () => {
    if (eventIds.length > 0) {
      const readAtUtc = moment.utc().toDate();
      await markUserEventsListAsRead({
        userSid,
        eventIds,
        readAtUtc,
      });
      if (cb) {
        cb();
      }
    }
  }, [userSid, eventIds, cb]);

  return {
    markUserEventsAsRead,
  };
}

type UseMarkUserEventsListAsRead = {
  markUserEventsAsRead: () => void;
};
