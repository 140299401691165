import React from "react";
import { IconProps } from "../../../types";

export default function ServicesCleaning({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(32 20)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M28.211 60.047a.827.827 0 0 0-.55-1.157 7.333 7.333 0 0 1-3.9-2.423 7.25 7.25 0 0 1-1.723-4.794.84.84 0 0 0-.853-.843h-5.39a.831.831 0 0 0-.834.833v25.004A3.333 3.333 0 0 0 18.295 80h10a3.27 3.27 0 0 0 1.596-.413.84.84 0 0 0 .407-.527.85.85 0 0 0-.107-.667 18.287 18.287 0 0 1-1.98-18.346z" />
        <circle cx="23.288" cy="8.333" r="8.333" />
        <path d="M9.861 24.13a.83.83 0 0 1-.143 1.093 17.37 17.37 0 0 0-6.037 13.11 9.173 9.173 0 0 0 9.167 9.167h8.333a4.44 4.44 0 0 0 3.88-2.743c.179-.454.276-.936.287-1.424a4.087 4.087 0 0 0-.307-1.513 4.403 4.403 0 0 0-3.86-2.667h-8.333a.833.833 0 0 1-.833-.833 9.807 9.807 0 0 1 1.373-4.133.833.833 0 0 1 1.567.396V35c0 .46.373.833.833.833h5.393a7.473 7.473 0 0 1 7.19 5.5.837.837 0 0 0 1.334.417l1.41-1.173a1.09 1.09 0 0 1 .123-.094.833.833 0 0 0 .38-.7v-4.496a.257.257 0 0 1 .5-.087l1.877 5.157c.355.99.057 2.096-.747 2.773l-1.63 1.36-4.773 3.973a4.18 4.18 0 0 0-.534 5.87h.017a4.15 4.15 0 0 0 3.187 1.487c.72-.02 1.423-.23 2.036-.61a59.803 59.803 0 0 0 7.034-5.693 10.86 10.86 0 0 0 3.24-12.02L39.95 32.34a17.417 17.417 0 0 0-7.546-9.007 17.397 17.397 0 0 0-8.897-2.5h-2.333a16.613 16.613 0 0 0-4.39.64.83.83 0 0 1-.91-.333L5.988 6.473a3.333 3.333 0 0 0-5.523 3.714L9.86 24.13zM36.395 57.523c-5.3 4.299-6.364 11.967-2.437 17.547l1.467 2.18a3.333 3.333 0 0 0 4.626.903l1.334-.893a.83.83 0 0 0 .26-1.103l-4.187-7.334a1.671 1.671 0 0 1 2.897-1.666l4 7a.847.847 0 0 0 1.2.276l3.92-2.64a.837.837 0 0 0 .256-1.106l-3.933-6.884a1.671 1.671 0 0 1 2.897-1.666l3.75 6.563a.847.847 0 0 0 1.2.277l1.64-1.104a3.333 3.333 0 0 0 .903-4.63l-1.863-2.763a12.503 12.503 0 0 0-17.93-2.957z" />
      </g>
    </svg>
  );
}
