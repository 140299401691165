import { useParams } from "react-router-dom";
import { usePatchAgendaItemMutation } from "../../api/agendaItemApi";
import { useCallback } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";
import { append, remove } from "../../slices/fileUploadSlice";
import { MeetingAttachment } from "../../types";
import {
  propositionApi,
  useDeleteMeetingItemAttachmentMutation,
} from "../../api/propositionApi";

export default function useUpdateAgendaItem(): UseUpdateAgendaItem {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
    agendaItemId = "",
  } = useParams();

  const dispatch = useDispatch();

  const [patchAgendaItem, { isError, isSuccess, isLoading }] =
    usePatchAgendaItemMutation();

  const [deleteMeetingItemAttachment] =
    useDeleteMeetingItemAttachmentMutation();

  const deleteAttachments = useCallback(
    async (deleteFiles: { id: string; name: string }[]) => {
      await deleteMeetingItemAttachment({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        deleteFiles,
      });
      deleteFiles.map((item) => {
        dispatch(remove(item));
      });
    },
    [],
  );

  const update = useCallback(
    async (
      values: {
        updateValues: AgendaItemUpdateValues;
        initialValues: AgendaItemUpdateValues;
      },
      initialAttachments: MeetingAttachment[] = [],
    ) => {
      const {
        updateValues: { description, attachments = [] },
        initialValues: { description: initDescription },
      } = values;
      const shouldPatch = description !== initDescription;

      const addedFiles = attachments.filter(
        (a) =>
          !initialAttachments.find(
            (b) => a.name === b.originalFileName && a.size === b.contentSize,
          ),
      );

      const removedFiles = initialAttachments
        .filter(
          (b) =>
            !attachments.find(
              (a) => a.name === b.originalFileName && a.size === b.contentSize,
            ),
        )
        .map(({ attachmentId, originalFileName }) => ({
          id: attachmentId,
          name: originalFileName,
        }));

      if (removedFiles.length > 0) {
        await deleteAttachments(removedFiles);
      }

      if (addedFiles.length > 0) {
        dispatch(
          append({
            referenceId: agendaItemId,
            files: addedFiles,
            customerToken,
            meetingId,
            facilityObjectId,
            agendaItemId,
            endpoint: propositionApi.endpoints.postMeetingItemAttachment,
          }),
        );
      }

      if (shouldPatch) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data, error } = await patchAgendaItem({
          customerToken,
          facilityObjectId,
          meetingId,
          agendaItemId,
          ...values.updateValues,
        });

        if (error) {
          return { error };
        }
        return { data };
      }

      return {};
    },
    [customerToken, facilityObjectId, agendaItemId, meetingId],
  );

  return {
    update,
    isError,
    isSuccess,
    isLoading,
  };
}

type UseUpdateAgendaItem = {
  update: (
    values: {
      updateValues: AgendaItemUpdateValues;
      initialValues: AgendaItemUpdateValues;
    },
    initialAttachments: MeetingAttachment[],
  ) => Promise<{
    data?: void;
    error?: FetchBaseQueryError | SerializedError;
  }>;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
};

type AgendaItemUpdateValues = {
  description?: string;
  attachments?: File[];
};
