import React, {
  useCallback,
  useState,
  ReactElement,
  Fragment,
  useEffect,
} from "react";
import { Form, FormProps } from "react-final-form";

import Button from "../../common/Button";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import Play from "../../icons/Play";
import { PropositionVotingState, PropositionVotingType } from "../../../enums";
import Stop from "../../icons/Stop";

import "../../../styles/components/meetings/proposition/PropositionVotingControlForm.scss";
import { validateGreaterThen } from "../../../lib/formValidate";
import PropositionVotingSimple from "./PropositionVotingSimple";
import PropositionVotingResolution from "./PropositionVotingResolution";

const BUTTON_LABELS = {
  [PropositionVotingState.None]: "Abstimmung starten",
  [PropositionVotingState.Running]: "Abstimmung beenden",
  [PropositionVotingState.Closed]: "Abstimmung wiederholen",
};

export default function PropositionVotingControlForm({
  onSubmit,
  initialValues,
  disabled,
  votingState,
  votingType,
  propositionId,
}: Props): ReactElement {
  const [votingResult, setVotingSimpleResult] = useState({
    totalVotes: 0,
  });
  const buttonLabel = BUTTON_LABELS[votingState];
  const leadingIcon =
    votingState === PropositionVotingState.Running ? Stop : Play;

  const [isRepeatVotingToConfirm, setRepeatVotingToConfirm] = useState(false);
  const handleConfirmRepeatVoting = useCallback(() => {
    setRepeatVotingToConfirm(!isRepeatVotingToConfirm);
  }, [setRepeatVotingToConfirm, isRepeatVotingToConfirm]);

  return (
    <Form
      onSubmit={
        votingState === PropositionVotingState.Closed
          ? handleConfirmRepeatVoting
          : onSubmit
      }
      validate={
        votingState === PropositionVotingState.Running &&
        votingType === PropositionVotingType.Simple
          ? (values) => {
              return {
                totalAttendedParticipants:
                  values.totalAttendedParticipants &&
                  validateGreaterThen(votingResult.totalVotes)(
                    values.totalAttendedParticipants,
                  ),
              };
            }
          : () => ({})
      }
      initialValues={initialValues}
      render={({ handleSubmit, form, values, submitSucceeded }) => {
        const {
          totalYesVotes = 0,
          totalNoVotes = 0,
          totalAbstentionVotes = 0,
        } = values;

        const totalVotes: number = [
          totalYesVotes,
          totalNoVotes,
          totalAbstentionVotes,
        ].reduce((a, b) => Number(a) + Number(b), 0);

        const confirmDialogDescription =
          votingType === PropositionVotingType.Simple
            ? "Nur beim Beenden der wiederholten Abstimmung werden die Stimmen in CONVENTUS erneut gespeichert. Möchten Sie nun die vorherige Stimmen zurücksetzen?"
            : "Nur beim Beenden der wiederholten Abstimmung werden die Stimmen in CONVENTUS erneut gespeichert. Möchten Sie nun die vorherige Stimmen beibehalten oder zurücksetzen?";

        const confirmDialogButtons = (
          <Fragment>
            {votingType === PropositionVotingType.Detailed && (
              <Button
                lightblue
                label="Beibehalten"
                onClick={() => {
                  onSubmit({ ...values, keepStoredVotes: true }, form);
                }}
                className="dialog-actions-confirm"
                aria-controls="form"
                data-testid="proposition-voting-confirm-dialog-button-keep-voting"
              />
            )}
            <Button
              lightblue
              label="Zurücksetzen"
              onClick={() => {
                onSubmit({ ...values, keepStoredVotes: false }, form);
              }}
              className="dialog-actions-confirm"
              aria-controls="form"
              data-testid="proposition-voting-confirm-dialog-button-reset-voting"
            />
          </Fragment>
        );

        useEffect(() => {
          if (
            submitSucceeded &&
            Number(values?.totalAttendedParticipants) >= totalVotes
          ) {
            form.reset();
          }
        }, [submitSucceeded]);

        useEffect(() => {
          setVotingSimpleResult({
            totalVotes: totalVotes,
          });
        }, [values]);
        return (
          <div>
            {votingState === PropositionVotingState.Running && (
              <div className="agenda-item-proposition-voting-control-form-text">
                Abstimmung läuft...
              </div>
            )}
            <form
              className="agenda-item-proposition-voting-control-form"
              onSubmit={handleSubmit}
            >
              <div className="agenda-item-proposition-voting-control-form-buttons-wrapper">
                <Button
                  label={buttonLabel}
                  className="agenda-item-proposition-voting-control-form-button"
                  green={votingState === PropositionVotingState.None}
                  lightblue={
                    votingState === PropositionVotingState.Running ||
                    votingState === PropositionVotingState.Closed
                  }
                  leadingIcon={leadingIcon}
                  disabled={disabled}
                />
                <PropositionVotingResolution
                  votingState={votingState}
                  propositionId={propositionId}
                />
              </div>
              <div className="simple-voting-wrapper">
                {votingState === PropositionVotingState.Running &&
                  votingType === PropositionVotingType.Simple && (
                    <PropositionVotingSimple values={votingResult.totalVotes} />
                  )}
              </div>
              {isRepeatVotingToConfirm && (
                <ConfirmDialog
                  title="Abstimmung wiederholen"
                  description={confirmDialogDescription}
                  onCancel={handleConfirmRepeatVoting}
                  confirmComponent={confirmDialogButtons}
                />
              )}
            </form>
          </div>
        );
      }}
    />
  );
}

type Props = {
  disabled: boolean;
  votingState: PropositionVotingState;
  votingType?: PropositionVotingType;
  propositionId?: string;
} & FormProps;
