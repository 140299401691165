import React, { Fragment, ReactElement, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Drawer from "./components/common/Drawer";
import Navigation from "./components/navigation/Navigation";
import { SynchronisationProvider } from "./hooks/useSynchronisation";
import DrawerHeaderElementForPubliclyOutlet from "./components/DrawerHeaderElementForPubliclyOutlet";

export default function PublicOutlet({
  showNavigationList,
}: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const [mobile] = useState(searchParams.get("mobile"));

  return (
    <SynchronisationProvider endpoint="/hub/meeting">
      <Drawer
        collapsable={false}
        drawerHeaderElement={<DrawerHeaderElementForPubliclyOutlet />}
      >
        <Navigation showNavigationList={showNavigationList} />
      </Drawer>
      <main className={classNames("homecase-content", { fullscreen: mobile })}>
        <Fragment>
          <Outlet />
        </Fragment>
      </main>
    </SynchronisationProvider>
  );
}

type Props = {
  home?: boolean;
  showNavigationList?: boolean;
};
