import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Lightbox from "../../common/lightbox/Lightbox";
import React, { Fragment, useCallback } from "react";
import useOpenDocument from "../../../hooks/documents/useOpenDocument";
import { Document } from "../../../types";
import DocumentOpenDialog from "../../documents/DocumentOpenDialog";

export default function DocumentDetailsPage() {
  const navigate = useNavigate();
  const documents = useOutletContext<Document[]>();

  const { documentId = "" } = useParams();
  const { isDocumentFetching, selectedDocument } = useOpenDocument(
    documents,
    documentId,
  );

  const handleLightboxClose = useCallback(() => {
    navigate("../");
  }, [navigate]);

  return (
    <Fragment>
      {isDocumentFetching && selectedDocument && (
        <DocumentOpenDialog document={selectedDocument} />
      )}
      <Lightbox onClose={handleLightboxClose} />
    </Fragment>
  );
}
