import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import ManagementDialogDescription from "./ManagementDialogDescription";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import ManagementItem from "./ManagementItem";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";
import { usePostAssignUserToCustomerMutation } from "../../api/customerApi";

import "../../styles/components/userSettings/Management.scss";
import InfoText from "./InfoText";
import { useGetCustomersQuery } from "../../api/customerApi";
import AlertDialog from "../common/dialog/AlertDialog";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";

export default function Management(): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const [isOpenError, setOpenError] = useState(false);
  const [isOpenSucceeded, setOpenSucceeded] = useState(false);
  const [inputText, setInputText] = useState("");
  const { user } = useGetUsersMyProfile();
  const { assignedObjects } = useFetchPostLoginBaseData();
  const [postValidateCode, { data }] = usePostAssignUserToCustomerMutation();
  const { data: customers = [] } = useGetCustomersQuery();

  const handlePostValidateCode = async () => {
    await postValidateCode({
      registrationCode: inputText,
      userSid: user.sid,
    });

    if (!data?.succeeded) {
      setOpenError(true);
    } else {
      setOpen(false);
    }
  };

  const handleRegisterNew = () => {
    window.location.assign("/anmelden");
  };

  useEffect(() => {
    if (data?.succeeded) {
      setOpen(false);
      setOpenSucceeded(true);
    }
  }, [data]);

  useEffect(() => {
    if (data?.succeeded) {
      setOpenSucceeded(true);
    }
    if (!data?.succeeded) {
      setOpenSucceeded(false);
    }
  }, [inputText]);

  return (
    <section className="user-settings-management-container">
      <div className="user-settings-management-container-list">
        <div className="management-list-info">
          <h3>Angebundene Hausverwaltungen</h3>
          <span className="disable">
            Die nebenstehenden Hausverwaltungen sind derzeit an Ihren
            HOMECASE-Account angebunden.
          </span>
        </div>
        <div className="management-list">
          {customers.map((item, key) => (
            <ManagementItem
              customer={item}
              key={key}
              assignedObjects={assignedObjects}
            />
          ))}
          <Button
            lightblue
            label="Hausverwaltung anbinden"
            role={"button"}
            onClick={() => {
              setOpen(true);
            }}
          />
          {isOpenSucceeded && (
            <InfoText
              isSuccess={true}
              textSuccess="Die Hausverwaltung wurde erfolgreich angebunden."
            />
          )}
        </div>
      </div>
      {isOpen && (
        <ConfirmDialog
          title={"Hausverwaltung anbinden"}
          description={
            <ManagementDialogDescription
              setInputText={setInputText}
              data={data}
              isOpenError={isOpenError}
              setOpenError={setOpenError}
            />
          }
          confirmLabel={"+ Hausverwaltung anbinden"}
          onConfirm={handlePostValidateCode}
          cancelLabel="Abbrechen"
          onCancel={() => {
            setOpen(false);
            setOpenError(false);
          }}
        />
      )}

      {isOpenSucceeded && (
        <AlertDialog
          title={"Die Hausverwaltung wurde erfolgreich angebunden"}
          description="Bitte melden Sie sich neu an."
          confirmLabel={"Neu anmelden"}
          onConfirm={handleRegisterNew}
        />
      )}
    </section>
  );
}
