import { SyntheticEvent, useCallback, useState } from "react";
import { TermsOfServicesUsersSortBy } from "../../enums";

export default function useSortedUsersTermsOfUse(): SortUsers {
  const [sort, setSort] = useState<SortState>({
    sortBy: TermsOfServicesUsersSortBy.UserName,
    sortDirection: "ascending",
  });

  const handleSort = useCallback(
    (e: SyntheticEvent) => {
      const value =
        e.currentTarget.getAttribute("aria-level") ||
        TermsOfServicesUsersSortBy.None;
      if (value && sort.sortBy === parseInt(value, 10)) {
        setSort({
          sortBy: parseInt(value, 10),
          sortDirection:
            sort.sortDirection === "ascending" ? "descending" : "ascending",
        });
      } else if (value) {
        setSort({
          sortBy: parseInt(value, 10),
          sortDirection: "ascending",
        });
      }
    },
    [sort, setSort],
  );

  return {
    handleSort,
    sort,
  };
}

type SortState = {
  sortDirection: "descending" | "ascending" | "none";
  sortBy: TermsOfServicesUsersSortBy;
};

type SortUsers = {
  handleSort: (e: SyntheticEvent) => void;
  sort: SortState;
};
