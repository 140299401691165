import React, { Fragment, ReactElement, useState } from "react";

import "../../../styles/components/management/objectSettings/ObjectOwner.scss";
import TableSortHeader from "../../common/TableSortHeader";
import StakeholdersInfoListItem from "./StakeholdersInfoListItem";
import { useParams } from "react-router-dom";
import useTableSort from "../../../hooks/common/useTableSort";
import { useGetFacilityObjectOwnersQuery } from "../../../api/facilityManagementApi";
import InfoText from "../../userSettings/InfoText";
import { StakeholdersInfo } from "../../../types";
import StakeholdersInfoListItemUsersAndCode from "./StakeholdersInfoListItemUsersAndCode";
import CaretDown from "../../icons/CaretDown";
import StakeholdersInfoListItemSkeleton from "../../skeleton/StakeholdersInfoListItem";
import useSortStakeholders from "../../../hooks/useSortStakeholders";

export default function ObjectOwner(): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "firstName",
      sortDirection: "ascending",
    },
    "service-provider-list",
  );

  const sortOptions = [{ sortBy: "firstName", label: "Name" }];

  const {
    data: facilityObjectOwners,
    isFetching,
    isSuccess,
  } = useGetFacilityObjectOwnersQuery({
    customerToken,
    facilityObjectId,
  });

  const isActiveStakeholderInitialState: StakeholdersInfo = {
    addressId: 0,
    firstName: "",
    lastName: "",
    activeAccountsCount: 0,
  };

  const sortedFacilityObjectOwners = useSortStakeholders(
    facilityObjectOwners ? facilityObjectOwners : [],
    sort,
  );
  const [isActiveStakeholder, setActiveStakeholder] =
    useState<StakeholdersInfo>(isActiveStakeholderInitialState);

  return (
    <section className="object-owner-wrapper">
      {isActiveStakeholder.addressId !== 0 && (
        <span
          onClick={() => setActiveStakeholder(isActiveStakeholderInitialState)}
          role="button"
          className="object-owner-back"
        >
          <CaretDown width={16} height={16} /> Alle Hauseigentümer
        </span>
      )}
      {isActiveStakeholder.addressId === 0 && (
        <div className="object-owner-container">
          <div className="object-owner-info">
            <h3>Hauseigentümer</h3>
            {facilityObjectOwners && facilityObjectOwners.length === 0 && (
              <InfoText
                isInfo={true}
                textInfo="Diesem Objekt sind zurzeit keine Hauseigentümer zugeordnet."
              />
            )}
            {facilityObjectOwners && facilityObjectOwners.length > 0 && (
              <div className="sort-header">
                <TableSortHeader
                  handleSort={handleSort}
                  sortOptions={sortOptions}
                  sort={sort}
                />
              </div>
            )}

            <div className="object-owner-info-list-body" role="row">
              {sortedFacilityObjectOwners?.map((facilityObjectOwner, index) => (
                <StakeholdersInfoListItem
                  key={index}
                  stakeholdersInfo={facilityObjectOwner}
                  isActive={
                    facilityObjectOwner.addressId ===
                    isActiveStakeholder.addressId
                  }
                  setActiveStakeholder={setActiveStakeholder}
                />
              ))}

              {!isSuccess && isFetching && (
                <Fragment>
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}

      {isActiveStakeholder.addressId !== 0 && (
        <StakeholdersInfoListItemUsersAndCode
          isActiveStakeholder={isActiveStakeholder}
        />
      )}
    </section>
  );
}
