import React, { ReactElement, useMemo } from "react";
import "../../../styles/components/meetings/participants/ParticipantItemForInstructions.scss";
import { AgendaItem, ParticipantsWithVoteInstructions } from "../../../types";
import InstructionItemForParticipant from "./InstructionItemForParticipant";
import moment from "moment";
import { AgendaItemType } from "../../../enums";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { isEmployeeByRoles } from "../../../lib";

export default function ParticipantItemForInstructions({
  instruction,
  agendaItems,
}: Props): ReactElement {
  const userRoles = useCustomerRoles();
  const isEmployee = isEmployeeByRoles(userRoles);
  const itemsWithPropositions = useMemo(() => {
    return agendaItems?.filter((item) => {
      const itemType =
        item.type === AgendaItemType.WithoutSpecificPurpose ||
        item.type === AgendaItemType.OrganizationalResolution;
      return (
        itemType &&
        item.propositions?.length &&
        item.propositions[0] &&
        item.propositions.some((prop) => prop.votingType === 2)
      );
    });
  }, [agendaItems]);

  return (
    <div
      key={instruction.voteDirective?.createdByUserSid}
      className="participants-instructions-item-wrapper"
    >
      {isEmployee && (
        <div className="participants-instructions-item-name-wrapper">
          <span className="participants-instructions-item-name">
            {instruction.firstName}
          </span>
          <span className="participants-instructions-item-name">
            {instruction.lastName}
          </span>
        </div>
      )}
      <span className="created-date">
        {isEmployee ? "erstellt am " : "Ihre erteilten Weisungen vom "}
        {moment
          .utc(instruction.voteDirective?.createdAtUtc)
          .local()
          .format("LLL")}
      </span>

      <div className="instructions-wrapper">
        {itemsWithPropositions?.map((item) => (
          <InstructionItemForParticipant
            key={item.id}
            agendaItem={item}
            instructions={instruction.voteDirective?.instructions}
          />
        ))}
      </div>
    </div>
  );
}

type Props = {
  instruction: ParticipantsWithVoteInstructions;
  agendaItems?: AgendaItem[];
};
