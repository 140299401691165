import React from "react";
import { useLocation } from "react-router-dom";
import { PREVIEW_COMPONENTS } from "../../lib/messages";
import Uploader from "../common/files/Uploader";
import OtherFile from "../common/files/OtherFile";

import "../../styles/components/common/PictureProfilePreview.scss";

export default function PictureProfilePreview({
  handleAvatarUpload,
  isAvatar,
}: Props): JSX.Element {
  const { pathname } = useLocation();

  const Component = isAvatar ? PREVIEW_COMPONENTS["image/jpg"] : OtherFile;
  const isPathname =
    pathname.includes("/profil/bearbeiten") || pathname.includes("/willkommen");

  return (
    <div className="picture-preview-wrapper">
      <div className="picture-preview" data-testid="profile-picture-preview">
        <Component file={isAvatar} width={120} height={120} />
      </div>
      {isPathname && <Uploader onUpload={handleAvatarUpload} />}
    </div>
  );
}

type Props = {
  isAvatar: File;
  handleAvatarUpload: (item: File) => void;
};
