import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import useFetchEmployeesList from "../../hooks/employee/useFetchEmployeesList";

import "../../styles/components/pages/EmployeesPage.scss";
import ItemEmployees from "../employees/ItemEmployees";
import { Employee } from "../../types";
import AddEmployeeButton from "../employees/AddEmployeeButton";
import useGetSortEmployees from "../../hooks/employee/useGetSortEmployees";

export default function EmployeesPage(): JSX.Element {
  const { employeesListWithOid } = useFetchEmployeesList();
  const sortedParticipants = useGetSortEmployees(employeesListWithOid);

  const [modelContent, setModelContent] = useState<null | Employee>(null);
  return (
    <div className="employees-page-container">
      <Helmet>
        <title> Mitarbeiter - Homecase</title>
      </Helmet>
      <PageHeader title="Mitarbeiter" actionComponent={AddEmployeeButton} />
      <section className="employees-page-container-info-wrapper">
        <section className="employees-page-container-header">
          <div className="employees-page-container-header-employees">
            Mitarbeiter
          </div>
          <div className="employees-page-container-header-email">
            E-Mail-Adresse
          </div>
        </section>
        <section className="employees-page-container-info">
          {sortedParticipants?.map((item, index) => (
            <ItemEmployees
              key={index}
              id={item.id}
              email={item.email}
              name={item.name}
              onClick={() => setModelContent(item)}
              onClose={() => setModelContent(null)}
              modelContent={modelContent?.name === item.name}
            />
          ))}
        </section>
      </section>
    </div>
  );
}
