import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function ObjectNumberIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="3 3 24 24"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path
            className={className ? `${className}-path` : undefined}
            d="m12.7 18.45 1.15-6.9M16.15 18.45l1.15-6.9M18.8 13.3h-7M18.2 16.7h-7M19 24h-8a5 5 0 0 1-5-5v-8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5z"
          />
        </g>
      </g>
    </svg>
  );
}
