import React from "react";
import { IconProps } from "../../types";

export default function Fluks({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="2 0 30 30"
      fill="none"
    >
      <g
        className={className ? `${className}-path` : undefined}
        name="fluks"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.046 25c0-3.816 3.112-6.91 6.95-6.91l1.668-2.21-1.668-2.21h-.278v.002C12.548 13.82 7.46 18.834 7.46 25h4.587z" />
        <path d="M7.46 21.932c.767-2.762 2.54-5.118 4.893-6.676a6.74 6.74 0 0 1 6.435-4.696h3.544L24 8.28 22.332 6h-3.544C12.53 6 7.459 11.042 7.459 17.262v4.67zM7.459 19.777V25" />
      </g>
    </svg>
  );
}
