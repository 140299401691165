import React from "react";
import { View, Text } from "@react-pdf/renderer";
import PdfStyles from "./PdfStyles";
export default function PdfDocumentVoteBar({
  label,
  count,
  total,
  color,
}: BarProps) {
  const percentage = count > 0 ? (count / total) * 100 : 0;
  return (
    <View style={PdfStyles.voteBarContainer}>
      <Text style={PdfStyles.voteBarContainerLabel}>{`${label}`}</Text>
      <Text style={PdfStyles.voteBarContainerCount}>{`${count}/${total}`}</Text>
      {count > 0 && (
        <View style={PdfStyles.voteBarContainerBar}>
          <Text
            style={[
              PdfStyles.voteBar,
              {
                width: `${percentage}%`,
                backgroundColor: color,
              },
            ]}
          ></Text>
        </View>
      )}
    </View>
  );
}

type BarProps = {
  label: string;
  count: number;
  total: number;
  color: string;
};
