import React, { Fragment } from "react";
import { QualificationVotingRule } from "../../../types";

export default function PropositionInformation({
  qualificationVotingRule,
  preliminaryRemark,
  forOwner,
}: Props): JSX.Element {
  return (
    <Fragment>
      {preliminaryRemark && !forOwner && (
        <p className="agenda-item-proposition-content-remark">
          Vorbemerkung: {preliminaryRemark}
        </p>
      )}
      {qualificationVotingRule?.name && (
        <dl className="agenda-item-proposition-information">
          <dt className="agenda-item-proposition-information-title">
            Stimmrecht:
          </dt>
          <dd className="agenda-item-proposition-information-value">
            {qualificationVotingRule?.name}
          </dd>
        </dl>
      )}
    </Fragment>
  );
}

type Props = {
  qualificationVotingRule?: QualificationVotingRule;
  preliminaryRemark?: string;
  forOwner: boolean;
};
