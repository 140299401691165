import { useMemo } from "react";
import { StakeholdersInfo, SortState } from "../types";
export function sortParticipants(
  facilityObjectsTenants: StakeholdersInfo[],
  key: SortKey,
  sortDirection: "descending" | "ascending" | "none",
): StakeholdersInfo[] {
  return sortDirection === "none"
    ? facilityObjectsTenants
    : facilityObjectsTenants.slice().sort((a, b) => {
        let x;
        let y;
        switch (key) {
          case "name":
            x = `${a.lastName}${a.firstName}`;
            y = `${b.lastName}${b.firstName}`;
            break;

          default:
            x = a[key];
            y = b[key];
        }

        if (x < y) {
          return sortDirection === "ascending" ? -1 : 1;
        }

        if (x > y) {
          return sortDirection === "ascending" ? 1 : -1;
        }
        return 0;
      });
}

export default function useSortStakeholders(
  facilityObjectsTenants: StakeholdersInfo[],
  sort: SortState,
): StakeholdersInfo[] {
  return useMemo(
    () =>
      sortParticipants(
        facilityObjectsTenants,
        sort.sortBy as SortKey,
        sort.sortDirection,
      ),
    [facilityObjectsTenants, sort],
  );
}

type SortKey = "name";
