export const ACTIVITIES_REDUCER_PATH = "activities";
export const MEETING_REDUCER_PATH = "meeting";
export const PARTICIPANTS_REDUCER_PATH = "participants";
export const PROPOSITION_REDUCER_PATH = "proposition";
export const AGENDA_ITEMS_REDUCER_PATH = "agendaItems";
export const CUSTOMER_REDUCER_PATH = "customer";
export const BULLETIN_BOARD_REDUCER_PATH = "bulletinBoard";
export const DOCUMENTS_REDUCER_PATH = "documents";
export const USER_REDUCER_PATH = "user";
export const FACILITY_OBJECTS_REDUCER_PATH = "facilityObjects";
export const FILE_UPLOAD_REDUCER_PATH = "fileUpload";
export const HOMECASE_MVC_REDUCER_PATH = "homecaseMvc";
export const TERMS_OF_SERVICES_PATH = "termsOfServices";
export const SUB_ITEMS_REDUCER_PATH = "subItems";
export const PROPOSITION_INSTRUCTIONS_VOTING_PATH =
  "propositionInstructionsVoting";
export const AGENDA_ITEM_EDITING_REDUCER_PATH = "agendaItemEditing";
