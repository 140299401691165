import React, { ReactElement, useCallback } from "react";
import "../../../styles/components/management/objectSettings/RegCodeItem.scss";
import Menu from "../../common/Menu";
import MenuListItem from "../../common/menu/MenuListItem";
import MenuListDiver from "../../common/menu/MenuListDiver";
import IconButton from "../../common/IconButton";
import ThreeDots from "../../icons/ThreeDots";
import DeleteIcon from "../../icons/DeleteIcon";
import { useRevokeRegistrationCodeForOnlineIdentityMutation } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function RegCodeItem({
  regCode,
  createDate,
}: Props): ReactElement {
  const formattedDate = moment(createDate).format("DD.MM.YYYY");
  const { customerToken = "" } = useParams();
  const button = <IconButton icon={ThreeDots} />;
  const [revokeRegistrationCodeForOnlineIdentity] =
    useRevokeRegistrationCodeForOnlineIdentityMutation();

  const handleDeleteCode = useCallback(async () => {
    await revokeRegistrationCodeForOnlineIdentity({
      customerToken,
      regCode,
    });
  }, [regCode]);

  return (
    <div role="row" className="reg-code-list-item">
      <div className="reg-code-wrapper">
        <div className="reg-code" role="cell">
          <div>{regCode}</div>
        </div>
        <div className="reg-code-create-date" role="cell">
          <div>{formattedDate}</div>
        </div>
      </div>

      <Menu button={button} className="provider-item-menu">
        <MenuListItem
          leadingIcon={<DeleteIcon className="menu-icon" />}
          onClick={handleDeleteCode}
        >
          Code löschen
        </MenuListItem>
        <MenuListDiver />
      </Menu>
    </div>
  );
}

type Props = {
  regCode: string;
  createDate: string;
};
