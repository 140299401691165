import React, { Fragment, useState } from "react";
import "../../../styles/components/management/objectSettings/ObjectServiceProvider.scss";
import TableSortHeader from "../../common/TableSortHeader";
import useTableSort from "../../../hooks/common/useTableSort";
import { useGetFacilityObjectServiceProvidersQuery } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import StakeholdersInfoListItem from "./StakeholdersInfoListItem";
import { StakeholdersInfo } from "../../../types";
import StakeholdersInfoListItemUsersAndCode from "./StakeholdersInfoListItemUsersAndCode";
import CaretDown from "../../icons/CaretDown";
import StakeholdersInfoListItemSkeleton from "../../skeleton/StakeholdersInfoListItem";
import useSortStakeholders from "../../../hooks/useSortStakeholders";

export default function ObjectServiceProvider(): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "name",
      sortDirection: "ascending",
    },
    "service-provider-list",
  );

  const sortOptions = [{ sortBy: "name", label: "Name" }];

  const {
    data: serviceProviders,
    isSuccess,
    isFetching,
  } = useGetFacilityObjectServiceProvidersQuery({
    customerToken,
    facilityObjectId,
  });

  const isActiveStakeholderInitialState: StakeholdersInfo = {
    addressId: 0,
    firstName: "",
    lastName: "",
    activeAccountsCount: 0,
  };

  const sortedServiceProviders = useSortStakeholders(
    serviceProviders ? serviceProviders : [],
    sort,
  );

  const [isActiveStakeholder, setActiveStakeholder] =
    useState<StakeholdersInfo>(isActiveStakeholderInitialState);
  return (
    <section className="object-service-provider-wrapper">
      {isActiveStakeholder.addressId !== 0 && (
        <span
          onClick={() => setActiveStakeholder(isActiveStakeholderInitialState)}
          role="button"
          className="object-service-provider-back"
        >
          <CaretDown width={16} height={16} /> Alle Dienstleister
        </span>
      )}
      {isActiveStakeholder.addressId === 0 && (
        <div className="object-service-provider-container">
          <div className="object-service-provider-info">
            <h3>Dienstleister</h3>

            <div className="sort-header">
              <TableSortHeader
                handleSort={handleSort}
                sortOptions={sortOptions}
                sort={sort}
              />
            </div>

            <div className="object-service-provider-info-list-body" role="row">
              {sortedServiceProviders?.map((serviceProvider, index) => (
                <StakeholdersInfoListItem
                  key={index}
                  stakeholdersInfo={serviceProvider}
                  isActive={
                    serviceProvider.addressId === isActiveStakeholder.addressId
                  }
                  setActiveStakeholder={setActiveStakeholder}
                />
              ))}

              {!isSuccess && isFetching && (
                <Fragment>
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
      {isActiveStakeholder.addressId !== 0 && (
        <StakeholdersInfoListItemUsersAndCode
          isActiveStakeholder={isActiveStakeholder}
        />
      )}
    </section>
  );
}
