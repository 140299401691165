import { FieldRenderProps } from "react-final-form";
import {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import AlertDialog from "../dialog/AlertDialog";
import "cropperjs/dist/cropper.css";
import "../../../styles/components/common/form/CropImage.scss";

export default function CropImage({
  input,
  onCrop = () => {},
}: FieldRenderProps<File[], HTMLElement>): ReactElement {
  const ref = useRef<ReactCropperElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [showCrop, setShowCrop] = useState(false);
  const handleConfirm = useCallback((e: SyntheticEvent) => {
    setShowCrop(false);
    if (e.currentTarget.className.includes("dialog-button-close")) {
      return;
    }
    const cropper = ref.current?.cropper;
    if (cropper) {
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          const file = new File([blob as Blob], "image.jpg", {
            type: "image/jpeg",
          });
          setFile(file);
          input.onChange([file]);
          onCrop();
        },
        "image/jpeg",
        1,
      );
    }
  }, []);

  useEffect(() => {
    if (input.value) {
      const [newFile] = input.value;
      if (file !== newFile) {
        setFile(newFile);
        setShowCrop(true);
      }
    }
  }, [input, file]);

  return (
    <Fragment>
      {showCrop && file && (
        <AlertDialog
          className={"dialog-crop"}
          showCloseButton
          description={
            <Cropper
              className="dialog-crop-image"
              data-testid="crop-image"
              initialAspectRatio={16 / 9}
              aspectRatio={16 / 9}
              src={URL.createObjectURL(file)}
              ref={ref}
              zoomable={false}
            />
          }
          title={"test"}
          onConfirm={handleConfirm}
          confirmLabel="Übernehmen"
        />
      )}
    </Fragment>
  );
}
