import React from "react";
import { IconProps } from "../../types";

export default function ActionsWarningIcon({
  width = 30,
  height = 30,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="nonzero"
        d="m17.694 7.065 6.893 12.063c1.182 2.069-.312 4.643-2.694 4.643H8.107c-2.383 0-3.877-2.575-2.694-4.643l6.892-12.063c1.192-2.087 4.198-2.087 5.39 0zm-3.652.992L7.15 20.12c-.42.736.11 1.651.958 1.651h13.786c.847 0 1.378-.915.958-1.65L15.958 8.055a1.103 1.103 0 0 0-1.916 0zm.957 9.29.125.007a1.225 1.225 0 1 1-.125-.006zm.001-6.07a1 1 0 0 1 1 1v3.366a1 1 0 0 1-2 0v-3.366a1 1 0 0 1 1-1z"
        fill="#C09A3E"
      />
    </svg>
  );
}
