import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import InvitationAcceptDialogDescription from "./InvitationAcceptDialogDescription";
import { Form } from "react-final-form";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { usePostAcceptInvitationMutation } from "../../../api/meetingApi";
import useSetVotingEligibility from "../../../hooks/meetings/useSetVotingEligibility";
import { MyInvitation, Participant } from "../../../types";

export default function InvitationAccept({
  onCancel,
  hasExistsDelegation,
  myRelatedParticipant,
  isEmployee,
  myInvitation,
}: Props) {
  const {
    meetingId = "",
    customerToken = "",
    facilityObjectId = "",
  } = useParams();
  const { handleParticipantDelegationSelect } =
    useSetVotingEligibility(myRelatedParticipant);
  const [postAcceptInvitation] = usePostAcceptInvitationMutation();
  const onSubmit = useCallback(
    async ({
      voteCastingType,
      attendanceType,
    }: {
      attendanceType: string;
      voteCastingType: string;
    }) => {
      if (Number(attendanceType) === 0 || Number(voteCastingType) === 0) {
        return { notZero: true };
      }
      const resultAccept = await postAcceptInvitation({
        customerToken,
        meetingId,
        facilityObjectId,
        attendanceType: parseInt(attendanceType, 10),
        voteCastingType: parseInt(voteCastingType, 10),
      });
      if (hasExistsDelegation) {
        await handleParticipantDelegationSelect(undefined);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!resultAccept.error) {
        onCancel();
        return undefined;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return resultAccept.error;
    },
    [onCancel, hasExistsDelegation],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        attendanceType: myInvitation?.attendanceType,
        voteCastingType: myInvitation?.voteCastingType,
      }}
      render={({ handleSubmit, dirty }) => {
        return (
          <ConfirmDialog
            description={<InvitationAcceptDialogDescription />}
            cancelLabel="Abbrechen"
            confirmLabel="Zusagen"
            title="Einladung zusagen"
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmDisabled={isEmployee || !dirty}
          />
        );
      }}
    />
  );
}

type Props = {
  onCancel: () => void;
  hasExistsDelegation: boolean;
  myRelatedParticipant: Participant;
  isEmployee: boolean;
  myInvitation?: MyInvitation;
};
