import { Document, FileWithMeta } from "../../types";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useLazyGetOnlineDocumentActivityNumberQuery,
  useLazyGetOnlineDocumentQuery,
} from "../../api/documentsApi";
import useLightbox from "../useLightbox";

export default function useOpenDocument(
  documents: Document[],
  documentId?: string,
) {
  const { toggleOpen, addFile, files } = useLightbox();
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [selectedDocumentId, setSelectedDocumentId] = useState(documentId);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  );
  const [getOnlineDocument, { isFetching: isDocumentFetching }] =
    useLazyGetOnlineDocumentQuery();
  const [getOnlineDocumentNumber] =
    useLazyGetOnlineDocumentActivityNumberQuery();

  useEffect(() => {
    const fn = async () => {
      if (selectedDocumentId) {
        const document = documents.find(
          ({ documentId: id }) => id === selectedDocumentId,
        );
        if (document) {
          setSelectedDocument(document);
          const lightboxFile = files.find(
            ({ name, type, lastModified }) =>
              name === document.originalFileName &&
              type === document.contentType &&
              lastModified === Date.parse(document.createDateUTC),
          );
          if (lightboxFile) {
            return toggleOpen(lightboxFile);
          }
        }

        const [numberResult, result] = await Promise.all([
          getOnlineDocumentNumber({
            documentId: selectedDocumentId,
            facilityObjectId,
            customerToken,
          }),
          getOnlineDocument({
            documentId: selectedDocumentId,
            facilityObjectId,
            customerToken,
          }),
        ]);

        if (result.data && document) {
          const file: FileWithMeta = new File(
            [result.data],
            document.originalFileName,
            {
              type: document.contentType,
              lastModified: Date.parse(document.createDateUTC),
            },
          );
          file.meta = numberResult.data
            ? [{ title: "Aktivitätennummer", value: `#${numberResult.data}` }]
            : undefined;
          addFile(file);
          toggleOpen(file);
        }
      }
    };
    fn();
  }, [documents, selectedDocumentId]);

  const handleSetDocumentId = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      setSelectedDocumentId(e.currentTarget.id);
    },
    [],
  );

  return {
    selectedDocument,
    isDocumentFetching,
    handleSetDocumentId,
  };
}
