import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React, { Fragment, useState } from "react";
import useFetchContactCardInfo from "../../hooks/useFetchContactCardInfo";
import ItemContactBoard from "./ItemContactBoard";
import DashboardContactItemSkeleton from "../skeleton/DashboardContactItemSkeleton";
import { useNavigate } from "react-router-dom";

export default function Contacts(): JSX.Element {
  const navigate = useNavigate();
  const { facilityObjectContactsList, facilityObjectContactsListIsFetching } =
    useFetchContactCardInfo();
  const [modelContent, setModelContent] = useState<null | ItemContactBoard>(
    null,
  );

  return facilityObjectContactsList.length > 0 ? (
    <div className="dashboard-right-container-item">
      <div
        className="dashboard-right-container-item-title-container"
        onClick={() => navigate("../verwalter")}
      >
        <h3 className="dashboard-right-container-item-title">
          IHRE VERWALTUNG
        </h3>
        <span>Alle...</span>
      </div>
      <div>
        {facilityObjectContactsList.slice(0, 3).map((item, index) => (
          <ItemContactBoard
            key={index}
            id={item.id}
            roleName={item.roleName}
            firma={item.firma}
            street={item.street}
            city={item.city}
            email={item.communication?.emails}
            mobil={item.communication?.mobiles}
            tel={item.communication?.phones}
            firstName={item.firstName}
            lastName={item.lastName}
            onClick={() => setModelContent(item)}
            onClose={() => setModelContent(null)}
            modelContent={modelContent?.lastName === item.lastName}
            employeeId={item.employeeId}
            visitingCardId={item.visitingCardId}
          />
        ))}
        {facilityObjectContactsListIsFetching && (
          <Fragment>
            <DashboardContactItemSkeleton />
            <DashboardContactItemSkeleton />
            <DashboardContactItemSkeleton />
          </Fragment>
        )}
      </div>
    </div>
  ) : (
    <Fragment />
  );
}

type ItemContactBoard = {
  roleName?: string;
  firma?: string;
  street?: string;
  city?: string;
  email?: string[];
  mobil?: string[];
  tel?: string[];
  lastName?: string;
  firstName?: string;
  onClose?: () => void;
  id?: string | number;
};
