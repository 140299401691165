import { Fragment, ReactElement } from "react";
import { ParticipantWithAttendanceState } from "../../../types";
import ParticipantAttendanceStateBadge from "./ParticipantAttendanceStateBadge";

export default function ParticipantAttendance({
  participant,
}: Props): ReactElement {
  const { attendanceState } = participant;
  return (
    <Fragment>
      {attendanceState && (
        <Fragment>
          <ParticipantAttendanceStateBadge attendanceState={attendanceState} />
          <span>{attendanceState.text}</span>
        </Fragment>
      )}
    </Fragment>
  );
}

type Props = {
  participant: ParticipantWithAttendanceState;
};
