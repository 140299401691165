import React from "react";
import Skeleton from "react-loading-skeleton";

import "../../styles/components/skeleton/AgandaListSkeleton.scss";

export default function AgendaListSkeleton(): JSX.Element {
  return (
    <section role="list" className="agenda-list-skeleton">
      <div className="agenda-list-item-skeleton" role="listitem">
        <div className="agenda-list-item-skeleton-content">
          <h4 className="agenda-list-item-skeleton-headline">
            <Skeleton />
          </h4>
          <p className="agenda-list-item-skeleton-info" role="addition">
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="agenda-list-item-skeleton" role="listitem">
        <div className="agenda-list-item-skeleton-content">
          <h4 className="agenda-list-item-skeleton-headline">
            <Skeleton />
          </h4>
          <p className="agenda-list-item-skeleton-info" role="addition">
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="agenda-list-item-skeleton" role="listitem">
        <div className="agenda-list-item-skeleton-content">
          <h4 className="agenda-list-item-skeleton-headline">
            <Skeleton />
          </h4>
          <p className="agenda-list-item-skeleton-info" role="addition">
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="agenda-list-item-skeleton" role="listitem">
        <div className="agenda-list-item-skeleton-content">
          <h4 className="agenda-list-item-skeleton-headline">
            <Skeleton />
          </h4>
          <p className="agenda-list-item-skeleton-info" role="addition">
            <Skeleton />
          </p>
        </div>
      </div>
    </section>
  );
}
