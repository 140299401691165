import React, { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import classNames from "classnames";
import Drawer from "./components/common/Drawer";
import LoadingSpinner from "./components/icons/LoadingSpinner";
import Navigation from "./components/navigation/Navigation";
import NoFacilityObjectPage from "./components/pages/NoFacilityObjectPage";
import useFetchPostLoginBaseData from "./hooks/useFetchPostLoginBaseData";
import FetchApiError from "./components/common/FetchApiError";
import { SynchronisationProvider } from "./hooks/useSynchronisation";
import useLoadMissingProfilePicturesForRecentLogins from "./hooks/useLoadMissingProfilePicturesForRecentLogins";
import Branding from "./components/common/Branding";
import TermsOfUseDialogContent from "./components/termsOfUse/TermsOfUseDialogContent";
import useLocalStorage from "./hooks/useLocalStorage";
import useGetUsersMyProfile from "./hooks/useGetUsersMyProfile";
import { useGetEmployeesListQuery } from "./api/customerApi";
import NotFoundPage from "./components/pages/NotFoundPage";
import DrawerHeaderElementForPrivateOutlet from "./components/DrawerHeaderElementForPrivateOutlet";
import useScrollToTop from "./hooks/useScrollToTop";
import { RecentLogin } from "./types";
import ScrollToTopButton from "./components/common/ScrollToTopButton";

export default function PrivateOutlet({
  management,
  isPageNotDependOnObject,
}: Props): ReactElement {
  const {
    isSuccess,
    isError,
    error,
    selectedAssignedObject,
    assignedObjects,
    termsOfUseAccepted,
  } = useFetchPostLoginBaseData();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { customerToken = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [mobile] = useState(searchParams.get("mobile"));
  const { isError: isEmployeeError } = useGetEmployeesListQuery(
    { customerToken },
    { skip: !management || !customerToken },
  );

  useLoadMissingProfilePicturesForRecentLogins(
    Boolean(isSuccess && selectedAssignedObject),
  );
  const { facilityObjectId } = useParams();
  const { rootRef, scrollToTop, showScrollToTop } = useScrollToTop();
  const isFacilityObjectNotSelected = !facilityObjectId
    ? assignedObjects.length === 0
    : !selectedAssignedObject;

  const renderNoFacilityObject =
    isFacilityObjectNotSelected && !isPageNotDependOnObject && !management;

  const LOCAL_STORAGE_KEY = "RecentLogins.v3";
  const [login] = useLocalStorage<RecentLogin[]>(LOCAL_STORAGE_KEY, []);
  const { user } = useGetUsersMyProfile();

  useEffect(() => {
    if (pathname !== pathname.toLowerCase()) {
      navigate(pathname.toLowerCase(), { replace: true });
    }
  }, [pathname]);

  useEffect(() => {
    const getReloading = () => {
      if (user.sid !== login[0].sid) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", getReloading);

    return () => {
      window.removeEventListener("storage", getReloading);
    };
  }, []);

  return (
    <SynchronisationProvider endpoint="/hub/meeting">
      {!isError && !isSuccess && (
        <div className="page-loading">
          <LoadingSpinner width={100} height={100} />
        </div>
      )}
      {isSuccess && !renderNoFacilityObject && <Branding />}
      {!mobile && isSuccess && !isEmployeeError && (
        <Drawer
          assignedObjects={assignedObjects}
          collapsable={!isPageNotDependOnObject}
          drawerHeaderElement={
            <DrawerHeaderElementForPrivateOutlet
              selectedAssignedObject={selectedAssignedObject}
            />
          }
        >
          <Navigation management={management} />
        </Drawer>
      )}
      {isSuccess && (
        <main
          className={classNames("homecase-content", { fullscreen: mobile })}
          ref={rootRef}
        >
          {!renderNoFacilityObject && !isEmployeeError && (
            <Fragment>
              <Outlet context={[selectedAssignedObject]} />
              <TermsOfUseDialogContent
                termsOfUseAccepted={termsOfUseAccepted}
                management={management}
              />
            </Fragment>
          )}
          {isSuccess && renderNoFacilityObject && <NoFacilityObjectPage />}
        </main>
      )}
      {showScrollToTop && <ScrollToTopButton scrollToTop={scrollToTop} />}
      {isEmployeeError && <NotFoundPage />}
      {isError && <FetchApiError error={error} />}
    </SynchronisationProvider>
  );
}

type Props = {
  management?: boolean;
  termsOfUseAccepted?: boolean;
  isPageNotDependOnObject?: boolean;
};
