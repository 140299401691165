import React from "react";
import { IconProps } from "../../types";

export default function Send({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M22.649 6.065a1 1 0 0 1 1.288 1.288l-5.943 16.98c-.3.854-1.49.903-1.858.075l-3.244-7.298-7.298-3.244c-.828-.368-.78-1.559.076-1.858l16.979-5.943zm-2.04 4.743l-5.766 5.765 2.096 4.718 3.67-10.483zm-7.18 4.351l5.765-5.766-10.483 3.67 4.718 2.096z"
        fillRule="evenodd"
      />
    </svg>
  );
}
