import { ActivityDocument } from "../../types";
import classNames from "classnames";
import MessageDetailsDocumentItem from "./MessageDetailsDocumentItem";

import { useCallback, useState } from "react";
import CaretDown from "../icons/CaretDown";

import "../../styles/components/messages/MessageDetailsDocuments.scss";

export default function MessageDetailsDocuments({
  documents,
  headline,
  showUploadDate,
  collapsable = false,
  isMessageDetailsDocuments,
}: Props): JSX.Element {
  const [collapsed, setCollapsed] = useState(collapsable);
  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <div
      className="message-details-documents"
      data-testid="message-details-documents"
    >
      {headline && (
        <h3
          className="message-details-documents-headline"
          onClick={collapsable ? toggleCollapsed : undefined}
        >
          <span>
            {headline} ({documents.length})
          </span>
          {collapsable && (
            <CaretDown
              className={classNames("message-details-documents-icon", {
                "message-details-documents-icon-collapsed": collapsed,
              })}
            />
          )}
        </h3>
      )}
      <div className="message-details-documents-items">
        {!collapsed &&
          documents.map((document) => (
            <MessageDetailsDocumentItem
              document={document}
              key={document.documentId}
              showUploadDate={showUploadDate}
              size={100}
              isMessageDetailsDocuments={isMessageDetailsDocuments}
            />
          ))}
      </div>
    </div>
  );
}

type Props = {
  documents: ActivityDocument[];
  headline?: string;
  showUploadDate?: boolean;
  collapsable?: boolean;
  isMessageDetailsDocuments?: boolean;
};
