import React, { Fragment } from "react";
import { Provider } from "react-redux";
import moment from "moment";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Worker } from "@react-pdf-viewer/core";

import "moment/locale/de";
import "moment/locale/fr";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

import "./styles/index.scss";

const userLang = navigator.language || "de";
const [lang] = userLang.split("-");

moment.locale(lang);
import { createRoot } from "react-dom/client";
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Fragment>
      <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.mjs`} />
      <Provider store={store}>
        <App />
      </Provider>
    </Fragment>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
