import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/NoFacilityObjectPage.scss";
import NoMeetings from "../icons/NoMeeting";

export default function NoFacilityObjectPage(): JSX.Element {
  return (
    <div className="not-facility-object" data-testid="not-found">
      <Helmet>
        <title>Keine Adressen gefunden - Homecase</title>
      </Helmet>
      <PageHeader title="Keine Adressen gefunden" />
      <div className="not-facility-object-container">
        <NoMeetings />
        <div className="not-facility-object-content">
          <h2>Aktuell ist noch keine Adresse hinterlegt.</h2>
          <p className="not-facility-object-content-block">
            Ihr Account wurde von Ihrer Hausverwaltung freigeschaltet, jedoch
            wurde noch keine Adresse hinterlegt.
          </p>
          <p className="not-facility-object-content-block">
            Sollten Sei bereits einen Registrierungscode erhalten haben, klicken
            Sie in den <a href="/einstellungen">Einstellungen</a> auf das Tab
            Hausverwaltung um Ihren Registrierungscode einzugeben.
          </p>
          <p className="not-facility-object-content-block">
            Sie haben keinen Registrierungscode erhalten? Bitte wenden Sie sich
            an ihre Hausverwaltung.
          </p>
        </div>
      </div>
    </div>
  );
}
