import { useParams } from "react-router-dom";
import {
  bulletinBoardApi,
  useLazyGetBulletinBoardPostAttachmentQuery,
} from "../../api/bulletinBoardApi";
import { useMemo, useState, useEffect } from "react";

export default function useInitialAttachments(postId: number) {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [fetchAttachmentData] = useLazyGetBulletinBoardPostAttachmentQuery();
  const { data } =
    bulletinBoardApi.endpoints.getBulletinBoardPostAttachments.useQueryState({
      customerToken,
      facilityObjectId,
      bulletinBoardPostId: postId,
    });

  const initialItems = useMemo(() => {
    if (data) {
      return data.map(({ id, originalFileName, contentSize }) => ({
        id,
        name: originalFileName,
        contentSize,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    const getAttachmentData = async () => {
      const fileData = data
        ? await Promise.all(
            data.map(async (item) => {
              const response = await fetchAttachmentData({
                customerToken,
                facilityObjectId,
                bulletinBoardPostId: postId,
                bulletinBoardPostAttachmentId: String(item.id),
              });
              if (response.data) {
                return new File([response.data], item?.originalFileName || "", {
                  type: item.contentType,
                  lastModified: new Date(item.createDateUTC).getTime(),
                });
              }
              return null;
            }),
          )
        : [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAttachments(fileData.filter((f) => f !== null));
    };

    getAttachmentData();
  }, []);

  return {
    initialItems,
    initialAttachmentValues: attachments,
  };
}
