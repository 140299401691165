import React, { useMemo } from "react";
import { Participant } from "../../../types";
import ParticipantsVotingEligibilityListItem from "./ParticipantsVotingEligibilityListItem";
import { getDelegationParticipant } from "../../../lib/meeting";
import { sortParticipants } from "../../../hooks/meetings/useSortParticipants";

import "../../../styles/components/meetings/participants/ParticipantsVotingEligibilityList.scss";

export default function ParticipantsVotingEligibilityList({
  participants,
  propositionId,
  votingDisabled,
}: Props): JSX.Element {
  const sortedParticipants = sortParticipants(
    participants,
    "name",
    "ascending",
  );

  const filteredParticipants = useMemo(
    () =>
      sortedParticipants.filter(
        ({ participantVotingWeight }) =>
          participantVotingWeight?.qualificationValue.value !== 0,
      ),
    [sortedParticipants],
  );

  return (
    <div
      role="table"
      className="etv-participants-voting-eligibility-list"
      data-testid="voting-eligibility-list"
    >
      {filteredParticipants &&
        filteredParticipants.map((p, key) => (
          <ParticipantsVotingEligibilityListItem
            key={key}
            propositionId={propositionId}
            participant={p}
            delegatedParticipant={getDelegationParticipant(
              sortedParticipants,
              p.delegationType,
              p.delegate,
            )}
            votingDisabled={votingDisabled}
          />
        ))}
    </div>
  );
}

type Props = {
  participants: Participant[];
  propositionId: string;
  forOwner?: boolean;
  votingDisabled?: boolean;
};
