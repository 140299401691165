import React from "react";
import { IconProps } from "../../types";

export default function HomeCaseLogo({
  width = 170,
  height = 62,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      version="1.1"
      id="Ebene_1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 170 62"
      enableBackground="new 0 0 170 62"
      xmlSpace="preserve"
    >
      <g id="Ebene_1_1_">
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_1_"
                x="-236.406"
                y="-210.109"
                width="640"
                height="480"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <path
              display="none"
              clipPath="url(#SVGID_2_)"
              fill="#29A9E1"
              d="M-4.023,78.645h-5.853V68.094h-10.732v10.551h-5.835V52.738
				h5.835v10.334h10.732V52.738h5.853V78.645z M16.551,79.096c-3.709,0-6.732-1.207-9.068-3.622
				c-2.338-2.414-3.506-5.562-3.506-9.438c0-4.096,1.187-7.408,3.56-9.938c2.371-2.529,5.516-3.794,9.43-3.794
				c3.697,0,6.688,1.21,8.971,3.632c2.281,2.42,3.424,5.611,3.424,9.574c0,4.072-1.184,7.354-3.551,9.847
				C23.444,77.85,20.358,79.096,16.551,79.096 M16.805,57.326c-2.049,0-3.674,0.769-4.879,2.305
				c-1.203,1.535-1.807,3.566-1.807,6.097c0,2.565,0.604,4.597,1.807,6.089c1.205,1.493,2.783,2.24,4.734,2.24
				c2.012,0,3.608-0.727,4.788-2.178c1.179-1.45,1.771-3.465,1.771-6.043c0-2.687-0.572-4.775-1.717-6.27
				C20.358,58.074,18.791,57.326,16.805,57.326 M66.881,78.645h-5.763V63.144c0-1.674,0.073-3.522,0.217-5.546h-0.144
				c-0.302,1.59-0.573,2.734-0.814,3.432l-6.07,17.615h-4.77l-6.178-17.434c-0.169-0.471-0.44-1.676-0.813-3.613h-0.163
				c0.156,2.553,0.235,4.793,0.235,6.721v14.326h-5.257V52.738h8.545l5.293,15.355c0.422,1.229,0.729,2.463,0.921,3.703h0.11
				c0.324-1.434,0.668-2.68,1.029-3.739l5.293-15.319h8.329V78.645z M92.007,78.645H76.47V52.738h14.94v4.75h-9.104v5.764h8.474
				v4.734h-8.474v5.926h9.701V78.645z M118.435,77.723c-1.891,0.916-4.36,1.373-7.407,1.373c-3.975,0-7.101-1.168-9.375-3.504
				c-2.277-2.338-3.416-5.45-3.416-9.342c0-4.144,1.279-7.504,3.84-10.08c2.56-2.577,5.881-3.866,9.963-3.866
				c2.529,0,4.662,0.319,6.396,0.958v5.618c-1.734-1.036-3.71-1.554-5.926-1.554c-2.434,0-4.396,0.765-5.891,2.295
				c-1.492,1.528-2.239,3.601-2.239,6.215c0,2.505,0.704,4.501,2.114,5.988c1.408,1.487,3.306,2.23,5.689,2.23
				c2.276,0,4.36-0.555,6.251-1.662V77.723L118.435,77.723z M149.433,78.645h-6.358l-1.842-5.764h-9.216l-1.823,5.764h-6.324
				l9.432-25.906h6.92L149.433,78.645z M139.894,68.4l-2.78-8.707c-0.207-0.65-0.351-1.428-0.435-2.33h-0.146
				c-0.061,0.758-0.209,1.51-0.451,2.258l-2.818,8.779H139.894z M155.175,77.65v-5.781c1.048,0.879,2.187,1.539,3.415,1.979
				s2.469,0.66,3.721,0.66c0.735,0,1.376-0.066,1.926-0.199c0.547-0.133,1.006-0.315,1.373-0.551
				c0.366-0.235,0.642-0.513,0.82-0.831c0.182-0.319,0.271-0.666,0.271-1.039c0-0.506-0.146-0.958-0.435-1.354
				c-0.289-0.398-0.684-0.767-1.184-1.104s-1.093-0.662-1.779-0.977c-0.687-0.313-1.428-0.633-2.224-0.957
				c-2.022-0.844-3.53-1.873-4.524-3.09c-0.993-1.217-1.489-2.686-1.489-4.408c0-1.348,0.271-2.508,0.813-3.478
				c0.543-0.97,1.279-1.768,2.214-2.394c0.933-0.627,2.015-1.088,3.243-1.383s2.529-0.442,3.902-0.442
				c1.349,0,2.545,0.081,3.586,0.244c1.041,0.161,2.002,0.411,2.881,0.749v5.402c-0.434-0.302-0.906-0.566-1.418-0.795
				c-0.512-0.229-1.039-0.418-1.58-0.57c-0.543-0.149-1.082-0.262-1.617-0.334c-0.536-0.071-1.045-0.107-1.527-0.107
				c-0.662,0-1.264,0.064-1.807,0.189c-0.541,0.127-0.999,0.305-1.373,0.533c-0.373,0.229-0.662,0.502-0.867,0.821
				c-0.203,0.319-0.307,0.679-0.307,1.074c0,0.435,0.115,0.821,0.344,1.166c0.229,0.343,0.555,0.668,0.975,0.976
				c0.422,0.307,0.935,0.608,1.537,0.903c0.602,0.295,1.281,0.599,2.041,0.912c1.035,0.434,1.967,0.896,2.791,1.382
				c0.824,0.488,1.532,1.039,2.123,1.652c0.59,0.615,1.041,1.316,1.354,2.104c0.313,0.789,0.47,1.708,0.47,2.755
				c0,1.445-0.273,2.659-0.822,3.641c-0.548,0.982-1.291,1.777-2.229,2.386c-0.939,0.608-2.033,1.045-3.279,1.312
				c-1.247,0.265-2.563,0.396-3.946,0.396c-1.423,0-2.773-0.12-4.058-0.361C157.226,78.494,156.115,78.133,155.175,77.65
				 M196.345,78.645h-15.537V52.738h14.941v4.75h-9.105v5.764h8.474v4.734h-8.474v5.926h9.701V78.645z"
            />
          </g>
          <g>
            <defs>
              <rect
                id="SVGID_3_"
                x="-236.406"
                y="-210.109"
                width="640"
                height="480"
              />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlinkHref="#SVGID_3_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_4_)"
              fill="#29A9E1"
              d="M122.417,15.08L105.798,1.253c-0.583-0.485-1.428-0.489-2.016-0.011
				l-5.709,4.645V5.202c0-0.583-0.473-1.056-1.056-1.056h-2.286c-0.583,0-1.054,0.473-1.054,1.056v4.263l-6.894,5.61
				c-0.519,0.422-0.221,1.262,0.448,1.262h1.692v16.416c0,1.474,1.194,2.67,2.67,2.67h2.35v5.082c0,0.372,0.433,0.573,0.718,0.336
				l6.531-5.418h16.375c1.475,0,2.669-1.196,2.669-2.67V16.337h1.727C122.628,16.337,122.929,15.505,122.417,15.08 M103.836,29.06
				h-3.944v-3.944h3.944V29.06z M103.836,23.626h-3.944v-3.944h3.944V23.626z M109.269,29.06h-3.943v-3.944h3.943V29.06z
				 M109.269,23.626h-3.943v-3.944h3.943V23.626z"
            />
          </g>
        </g>
      </g>
      <g id="Ebene_2">
        <g>
          <path
            fill="#2DA9E1"
            d="M19.756,61.606h-4.929v-9.158H5.792v9.158H0.878V39.118h4.914v8.97h9.035v-8.97h4.929V61.606z"
          />
          <path
            fill="#2DA9E1"
            d="M34.226,61.999c-3.123,0-5.669-1.049-7.637-3.145c-1.967-2.096-2.95-4.828-2.95-8.193
			c0-3.556,0.999-6.431,2.996-8.626s4.645-3.293,7.94-3.293c3.112,0,5.63,1.051,7.553,3.151c1.922,2.102,2.882,4.872,2.882,8.313
			c0,3.534-0.996,6.383-2.989,8.548C40.029,60.918,37.429,61.999,34.226,61.999z M34.438,43.102c-1.724,0-3.094,0.667-4.107,2
			c-1.015,1.333-1.521,3.097-1.521,5.293c0,2.226,0.506,3.989,1.521,5.284c1.014,1.296,2.342,1.944,3.985,1.944
			c1.694,0,3.037-0.628,4.032-1.89c0.994-1.26,1.49-3.008,1.49-5.246c0-2.33-0.482-4.145-1.445-5.441
			C37.429,43.749,36.111,43.102,34.438,43.102z"
          />
          <path
            fill="#2DA9E1"
            d="M73.748,61.606h-4.852V48.151c0-1.454,0.061-3.059,0.183-4.814h-0.122
			c-0.253,1.38-0.482,2.372-0.685,2.979l-5.11,15.29h-4.016l-5.202-15.134c-0.143-0.408-0.371-1.453-0.686-3.136h-0.136
			c0.131,2.217,0.198,4.161,0.198,5.833v12.437h-4.427V39.118h7.195l4.457,13.33c0.354,1.066,0.613,2.138,0.775,3.215h0.091
			c0.274-1.245,0.563-2.326,0.868-3.246l4.457-13.299h7.012V61.606L73.748,61.606z"
          />
          <path
            fill="#2DA9E1"
            d="M91.927,61.606H78.845V39.118h12.58v4.124h-7.667v5.002h7.135v4.109h-7.135v5.143h8.168V61.606z"
          />
          <path
            fill="#70C3EA"
            d="M111.399,60.807c-1.592,0.794-3.67,1.192-6.237,1.192c-3.347,0-5.979-1.015-7.895-3.043
			s-2.874-4.731-2.874-8.108c0-3.597,1.077-6.514,3.231-8.75c2.154-2.238,4.951-3.356,8.389-3.356c2.129,0,3.925,0.276,5.385,0.831
			v4.877c-1.46-0.899-3.123-1.35-4.989-1.35c-2.049,0-3.701,0.665-4.959,1.992c-1.259,1.326-1.886,3.127-1.886,5.395
			c0,2.175,0.592,3.906,1.779,5.198c1.187,1.292,2.784,1.937,4.791,1.937c1.918,0,3.671-0.48,5.264-1.442L111.399,60.807
			L111.399,60.807z"
          />
          <path
            fill="#70C3EA"
            d="M135.123,61.606h-5.355l-1.551-5.002h-7.759l-1.536,5.002h-5.323l7.94-22.488h5.825L135.123,61.606z
			 M127.092,52.715l-2.344-7.559c-0.172-0.565-0.294-1.239-0.364-2.023h-0.123c-0.049,0.657-0.176,1.312-0.38,1.96l-2.372,7.621
			L127.092,52.715L127.092,52.715z"
          />
          <path
            fill="#70C3EA"
            d="M137.449,60.744v-5.019c0.882,0.762,1.842,1.335,2.875,1.717c1.034,0.383,2.079,0.572,3.134,0.572
			c0.618,0,1.158-0.056,1.62-0.172c0.46-0.116,0.847-0.275,1.155-0.479c0.31-0.204,0.539-0.445,0.691-0.721
			c0.152-0.276,0.229-0.579,0.229-0.902c0-0.439-0.121-0.831-0.365-1.176c-0.244-0.346-0.575-0.664-0.995-0.957
			c-0.421-0.291-0.921-0.573-1.499-0.847c-0.578-0.271-1.201-0.549-1.87-0.831c-1.704-0.73-2.974-1.625-3.812-2.682
			c-0.836-1.057-1.255-2.331-1.255-3.827c0-1.17,0.229-2.178,0.686-3.018c0.456-0.843,1.077-1.536,1.862-2.078
			c0.784-0.545,1.696-0.944,2.731-1.2c1.032-0.256,2.129-0.385,3.285-0.385c1.136,0,2.142,0.071,3.021,0.213
			c0.876,0.141,1.686,0.356,2.426,0.65v4.688c-0.365-0.26-0.764-0.49-1.195-0.689c-0.431-0.197-0.874-0.362-1.329-0.493
			c-0.457-0.132-0.911-0.228-1.361-0.291c-0.452-0.062-0.882-0.092-1.287-0.092c-0.557,0-1.064,0.053-1.521,0.163
			s-0.841,0.264-1.155,0.462s-0.558,0.438-0.729,0.715c-0.174,0.276-0.26,0.588-0.26,0.932c0,0.378,0.098,0.715,0.289,1.013
			c0.192,0.299,0.467,0.581,0.821,0.848s0.786,0.526,1.292,0.784c0.507,0.257,1.081,0.52,1.721,0.791
			c0.871,0.377,1.655,0.776,2.35,1.199c0.695,0.425,1.29,0.903,1.787,1.437c0.499,0.533,0.879,1.142,1.143,1.826
			c0.263,0.684,0.395,1.481,0.395,2.392c0,1.254-0.23,2.307-0.691,3.16c-0.462,0.854-1.087,1.543-1.879,2.069
			c-0.792,0.528-1.712,0.907-2.761,1.137c-1.051,0.23-2.159,0.346-3.324,0.346c-1.196,0-2.335-0.104-3.415-0.313
			C139.177,61.475,138.241,61.162,137.449,60.744z"
          />
          <path
            fill="#70C3EA"
            d="M169.332,61.606H156.25V39.118h12.579v4.124h-7.666v5.002h7.134v4.109h-7.134v5.143h8.169V61.606z"
          />
        </g>
      </g>
    </svg>
  );
}
