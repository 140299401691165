import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOMECASE_MVC_REDUCER_PATH } from "../reducerPaths";
import { LEGACY_BASE_URL, prepareHeaders } from "./utils";

export const homecaseMvcApi = createApi({
  reducerPath: HOMECASE_MVC_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: LEGACY_BASE_URL,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getLoginInfo: build.query<boolean, void>({
      query: () => ({
        url: `/login/isloggedin`,
        redirect: "manual",
        responseHandler: (response) => {
          if (response.status === 200) {
            return Promise.resolve(true);
          }
          return Promise.resolve(false);
        },
      }),
    }),
    getRequestVerificationToken: build.query<string, void>({
      query: () => ({
        url: "/abmelden",
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return "";
          }
          const content = await response.text();
          const match = content.match(
            /<input name="__RequestVerificationToken" type="hidden" value="([^"]+)" \/>/,
          );
          if (match) {
            return match[1];
          }
          return "";
        },
      }),
    }),
    postLogOut: build.mutation<boolean, string>({
      query: (token) => ({
        url: `/abmelden`,
        method: "POST",
        body: `__RequestVerificationToken=${token}`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        responseHandler: (response) => {
          if (response.status === 200) {
            return Promise.resolve(true);
          }
          return Promise.resolve(false);
        },
      }),
    }),
  }),
});

export const {
  useGetLoginInfoQuery,
  usePostLogOutMutation,
  useLazyGetRequestVerificationTokenQuery,
} = homecaseMvcApi;

export default homecaseMvcApi;
