import React, { Fragment, ReactNode } from "react";
import ObjectsListCard from "./ObjectsListCard";
import { FacilityObject } from "../../types";
import ObjectsListCardSkeleton from "../skeleton/ObjectsListCardSkeleton";
import useScrollToSection from "../../hooks/common/useScrollToSection";

import "../../styles/components/home/ObjectsList.scss";
import useSortedObjects from "../../hooks/facilityObject/useSortedObjects";

export default function ObjectsList({
  isLoading,
  facilityObjects,
  token,
  isSortedBy,
}: Props): ReactNode {
  const { ref, rootRef } = useScrollToSection(`${token}`, [1]);

  const { sortedFacilityObjects } = useSortedObjects(
    isSortedBy,
    facilityObjects,
  );

  return (
    <Fragment>
      {facilityObjects && (
        <div className="objects-list-container" ref={rootRef}>
          {sortedFacilityObjects?.map((facilityObject, index) => (
            <Fragment key={facilityObject.id}>
              {Math.ceil(facilityObjects.length / 2) === index && (
                <div ref={ref} />
              )}
              <ObjectsListCard {...facilityObject} />
            </Fragment>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="objects-list-container">
          <ObjectsListCardSkeleton />
          <ObjectsListCardSkeleton />
          <ObjectsListCardSkeleton />
          <ObjectsListCardSkeleton />
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  isLoading: boolean;
  facilityObjects?: FacilityObject[];
  token: string;
  isSortedBy: string;
};
