import { ReactElement, SyntheticEvent, useCallback, useRef } from "react";
import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";
import Button from "../Button";
import UploadIcon from "../../icons/UploadIcon";

export default function FileButton({
  input,
  label,
  disabled,
  meta,
  ...rest
}: FieldRenderProps<File[] | undefined, HTMLInputElement>): ReactElement {
  const ref = useRef<HTMLInputElement>(null);
  const fileInputClassName = classNames({
    "file-button-field": true,
    "file-button-error": meta.touched && meta.error,
  });
  const focusFileInput = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      ref.current?.click();
    },
    [ref],
  );

  const onFileChange = useCallback(() => {
    const files = ref.current?.files;
    if (files) {
      input.onChange(files);
    }
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, ...inputRest } = input;
  return (
    <section className={fileInputClassName}>
      <Button
        label={label}
        leadingIcon={UploadIcon}
        disabled={disabled}
        onClick={focusFileInput}
        data-testid="file-input-button"
      />
      <input
        {...inputRest}
        {...rest}
        hidden
        type="file"
        ref={ref}
        data-testid="file-input"
        onChange={onFileChange}
      />
      {meta.touched && meta.error && (
        <div className="file-button-error-message">
          {meta.error
            .map(({ errorMessage }: { errorMessage: string }) => errorMessage)
            .join("\n")}
        </div>
      )}
    </section>
  );
}
