import React, { ReactElement } from "react";

import "../../styles/components/meetings/AgendaList.scss";
import { AgendaItem } from "../../types";
import AgendaListItemForInstructionsDialog from "./AgendaListItemForInstructionsDialog";
import useGetSortedAgendaItems from "../../hooks/meetings/useGetSortedAgendaItems";

export default function AgendaListForInstructionsDialog({
  agendaItems,
  instructions = false,
  setPage,
  itemWithPropositions,
}: Props): ReactElement {
  const { sortedAgendaItems } = useGetSortedAgendaItems(agendaItems);

  return (
    <section role="list" className="agenda-list">
      {sortedAgendaItems.map((item, index) => (
        <AgendaListItemForInstructionsDialog
          {...item}
          key={item.id}
          index={index}
          instructions={instructions}
          itemWithPropositions={itemWithPropositions}
          setPage={setPage}
        />
      ))}
    </section>
  );
}

type Props = {
  agendaItems: AgendaItem[];
  instructions?: boolean;
  setPage: (page: number) => void;
  itemWithPropositions?: AgendaItem[];
};
