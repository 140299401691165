import Button from "../common/Button";
import React, { Fragment, useCallback, useState } from "react";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import ObjectsPageActionsButtonDialogDescription from "./ObjectsPageActionsButtonDialogDescription";
import AlertDialog from "../common/dialog/AlertDialog";
import { useSendRegistrationCodesMutation } from "../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import ErrorDialog from "../common/dialog/ErrorDialog";

export default function ObjectsPageActionsButton(): JSX.Element {
  const { customerToken = "" } = useParams();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [sendRegistrationCodes, { isLoading, isError, error }] =
    useSendRegistrationCodesMutation();
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleSendRegistrationCodes = useCallback(async () => {
    await sendRegistrationCodes({
      customerToken,
    });

    setAlertDialogOpen(true);
    setDialogOpen(false);
  }, []);

  return (
    <Fragment>
      <Button
        lightblue
        className="documents-page-header-actions-group-button"
        role="button"
        label="Alle einladen"
        onClick={handleDialogOpen}
      />
      {isDialogOpen && (
        <ConfirmDialog
          description={<ObjectsPageActionsButtonDialogDescription />}
          title="Alle Mieter/Eigentümer einladen"
          onConfirm={handleSendRegistrationCodes}
          confirmLabel="Alle einladen"
          onCancel={handleDialogClose}
        />
      )}
      {isAlertDialogOpen && !isError && !isLoading && (
        <AlertDialog
          title="Einladungen werden versendet..."
          onConfirm={() => {
            setAlertDialogOpen(false);
          }}
          description="Dies kann einige Zeit in Anspruch nehmen. Sie können dieses Fenster schließen."
        />
      )}
      {isError && (
        <ErrorDialog
          description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
          title="Es ist ein Fehler aufgetreten!"
          onRetry={handleSendRegistrationCodes}
          error={error}
        />
      )}
    </Fragment>
  );
}
