import InvitationDelegationDialogDescription from "./InvitationDelegationDialogDescription";
import React from "react";
import { Participant } from "../../../types";
import AlertDialog from "../../common/dialog/AlertDialog";

export default function InvitationDelegate({
  onCancel,
  myRelatedParticipant,
  actionButtonText,
}: Props) {
  return (
    <AlertDialog
      description={
        <InvitationDelegationDialogDescription
          myRelatedParticipant={myRelatedParticipant}
          onCancel={onCancel}
          actionButtonText={actionButtonText}
        />
      }
      confirmLabel="Schließen"
      title={
        actionButtonText !== "Vollmacht erteilen"
          ? "Vollmacht & Weisungen"
          : "Vollmacht"
      }
      className="dialog-delegate"
      onConfirm={onCancel}
    />
  );
}

type Props = {
  onCancel: () => void;
  myRelatedParticipant: Participant;
  actionButtonText: string | null;
};
