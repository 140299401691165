import React from "react";
import { Route, Routes } from "react-router-dom";

import PrivateOutlet from "./PrivateOutlet";
import LoginPage from "./components/pages/LoginPage";
import EtvIndexPage from "./components/pages/meetings/IndexPage";
import { AUTHENTICATION_PATH } from "./lib";
import AgendaPage from "./components/pages/meetings/AgendaPage";
import MeetingsPage from "./components/pages/meetings/MeetingsPage";
import AgendaItemPage from "./components/pages/meetings/AgendaItemPage";
import AgendaListPage from "./components/pages/meetings/AgendaListPage";
import ParticipantsPage from "./components/pages/meetings/ParticipantsPage";
import ListMessagePage from "./components/pages/messages/ListMessagePage";
import IndexMessagePage from "./components/pages/messages/IndexMessagePage";
import NotFoundPage from "./components/pages/NotFoundPage";
import TermsOfUseContent from "./components/pages/TermsOfUseContent";
import ContactsPage from "./components/pages/ContactsPage";
import TermsOfUseUsersListPage from "./components/pages/management/TermsOfUseUsersListPage";
import DashboardPage from "./components/pages/DashboardPage";
import UserSettingsPage from "./components/pages/UserSettingsPage";
import GoodbyePage from "./components/pages/GoodbyePage";
import Management from "./components/userSettings/Management";
import Notifications from "./components/userSettings/Notifications";
import Account from "./components/userSettings/Account";
import { LastLocationProvider } from "react-router-dom-last-location";
import InvitationPage from "./components/pages/meetings/InvitationPage";
import ProfileSettingsPage from "./components/pages/ProfileSettingsPage";
import MyProfilePage from "./components/pages/MyProfilePage";
import { Helmet } from "react-helmet";
import useTracking from "./hooks/googleAnalytics/useTracking";
import WelcomePage from "./components/pages/WelcomePage";
import HomePage from "./components/pages/HomePage";
import TermsOfUseEditPage from "./components/pages/management/TermsOfUseEditPage";
import TermsOfUsePage from "./components/pages/management/TermsOfUsePage";
import FluksPage from "./components/pages/management/FluksPage";
import CentralSettingsPage from "./components/pages/management/CentralSettingsPage";
import ObjectsPage from "./components/pages/management/ObjectsPage";
import EmployeesPage from "./components/pages/EmployeesPage";
import PermissionsPage from "./components/pages/management/PermissionsPage";
import DocumentsPage from "./components/pages/documents/DocumentsPage";
import DocumentDetailsPage from "./components/pages/documents/DocumentDetailsPage";
import DataSecurityPage from "./components/pages/DataSecurityPage";
import Imprint from "./components/pages/Imprint";
import PublicOutlet from "./PublicOutlet";
import EventLogPage from "./components/pages/meetings/EventLogPage";
import FacilityObjectSettings from "./components/pages/management/FacilityObjectSettings";
import ObjectComponent from "./components/management/objectSettings/ObjectComponent";
import ObjectReports from "./components/management/objectSettings/ObjectReports";
import ObjectDocuments from "./components/management/objectSettings/ObjectDocuments";
import ObjectTenantOrFlatOwner from "./components/management/objectSettings/ObjectTenantOrFlatOwner";
import ObjectServiceProvider from "./components/management/objectSettings/ObjectServiceProvider";
import ObjectOwner from "./components/management/objectSettings/ObjectOwner";

export default function AppRouter(): JSX.Element {
  const { trackPageViewByHelmet } = useTracking();
  return (
    <LastLocationProvider>
      <Helmet onChangeClientState={trackPageViewByHelmet} />
      <Routes>
        <Route path={AUTHENTICATION_PATH} element={<LoginPage />} />
        <Route
          path="/:customerToken"
          element={<PrivateOutlet isPageNotDependOnObject />}
        >
          <Route index element={<HomePage />} />
          <Route path="nutzungsbedingungen" element={<TermsOfUseContent />} />
        </Route>
        <Route path="" element={<PrivateOutlet isPageNotDependOnObject />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route
          path="/:customerToken/objekte/:facilityObjectId"
          element={<PrivateOutlet />}
        >
          <Route path="kontakte" element={<ContactsPage />} />
          <Route path="verwalter" element={<ContactsPage />} />
          <Route path="nachbarn" element={<ContactsPage />} />
          <Route path="dienstleister" element={<ContactsPage />} />
          <Route path="dokumente" element={<DocumentsPage />}>
            <Route
              path=":documentId/:documentName"
              element={<DocumentDetailsPage />}
            />
          </Route>

          <Route
            path="schwarzesbrett/:bulletinBoardPostId?"
            element={<DashboardPage />}
            action={() => "Dashboard"}
          />
          <Route path="nachrichten" element={<IndexMessagePage />}>
            <Route path="" element={<ListMessagePage />} />
            <Route path=":messageId" element={<ListMessagePage />} />
          </Route>
          <Route path="versammlungen" element={<EtvIndexPage />}>
            <Route index element={<MeetingsPage />} />
            <Route path=":meetingId" element={<AgendaPage />}>
              <Route path="" element={<AgendaListPage />} />
              <Route path="agenda/:agendaItemId" element={<AgendaItemPage />} />
              <Route path="participants" element={<ParticipantsPage />} />
              <Route path="invitation" element={<InvitationPage />} />
              <Route path="eventLog" element={<EventLogPage />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="/management/:customerToken"
          element={<PrivateOutlet management />}
        >
          <Route path="objekte" element={<ObjectsPage />} />
          <Route
            path="objekte/:facilityObjectId"
            element={<FacilityObjectSettings />}
          >
            <Route path="objekt" element={<ObjectComponent />} />
            <Route path="berichte" element={<ObjectReports />} />
            <Route path="dokumente" element={<ObjectDocuments />}>
              <Route
                path=":documentId/:documentName"
                element={<DocumentDetailsPage />}
              />
            </Route>
            <Route path="mieter" element={<ObjectTenantOrFlatOwner />} />
            <Route path="diensleister" element={<ObjectServiceProvider />} />
            <Route path="hauseigentuemer" element={<ObjectOwner />} />
          </Route>

          <Route path="fluks" element={<FluksPage />} />
          <Route path="mitarbeiter" element={<EmployeesPage />} />
          <Route path="berechtigungen" element={<PermissionsPage />} />
        </Route>

        <Route
          path="/management/:customerToken"
          element={<PrivateOutlet management isPageNotDependOnObject />}
        >
          <Route path="einstellungen" element={<CentralSettingsPage />} />
          <Route path="nutzungsbedingungen" element={<TermsOfUsePage />}>
            <Route path=":termsOfUseId" element={<TermsOfUseEditPage />} />
          </Route>
          <Route
            path="nutzungsbedingungen/users/:pageNumber"
            element={<TermsOfUseUsersListPage />}
          />
        </Route>

        <Route
          path="/einstellungen"
          element={<PrivateOutlet isPageNotDependOnObject />}
        >
          <Route path="" element={<UserSettingsPage />}>
            <Route path="" element={<Notifications />} />
            <Route path="hausverwaltungen" element={<Management />} />
            <Route path="benachrichtigungen" element={<Notifications />} />
            <Route path="account" element={<Account />} />
          </Route>
        </Route>

        <Route
          path="/profil"
          element={<PrivateOutlet isPageNotDependOnObject />}
        >
          <Route path="" element={<MyProfilePage />} />
          <Route path="bearbeiten" element={<ProfileSettingsPage />} />
        </Route>

        <Route
          path="/willkommen"
          element={<PrivateOutlet isPageNotDependOnObject />}
        >
          <Route path="" element={<WelcomePage />} />
        </Route>

        <Route
          path="/goodbye"
          element={<GoodbyePage showPageHeaderActions={false} />}
        />
        <Route path="*" element={<NotFoundPage />} />

        <Route
          path="/impressum"
          element={<PublicOutlet showNavigationList={false} />}
        >
          <Route path="" element={<Imprint />} />
        </Route>
        <Route
          path="/datenschutz"
          element={<PublicOutlet showNavigationList={false} />}
        >
          <Route path="" element={<DataSecurityPage />} />
        </Route>
      </Routes>
    </LastLocationProvider>
  );
}
