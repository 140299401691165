import React, { Fragment } from "react";
import { Meeting } from "../../types";
import MeetingListItem from "./MeetingListItem";

import "../../styles/components/meetings/MeetingList.scss";

export default function MeetingList({
  meetings,
  headline,
}: Props): JSX.Element {
  return (
    <Fragment>
      {headline && (
        <h3 className="meeting-list-headline" data-testid="headline">
          {headline}
        </h3>
      )}
      <section className="meeting-list" role="list">
        {meetings.map((item: Meeting) => (
          <MeetingListItem key={item.id} {...item} />
        ))}
      </section>
    </Fragment>
  );
}

type Props = {
  meetings: Meeting[];
  headline?: string;
};
