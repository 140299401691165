import ProfilePicture from "../common/ProfilePicture";
import { useCallback, useState } from "react";
import { UserProfile } from "../../types";
import moment from "moment/moment";
import ContactPersonCardDialog from "../common/contactPerson/ContactPersonCardDialog";

import "../../styles/components/bulletinBoard/PostByUser.scss";
export default function PostByUser({
  createdByUser,
  createDateUTC,
}: Props): JSX.Element {
  const [showProfile, setShowProfile] = useState(false);
  const handleShowProfile = useCallback(() => setShowProfile(true), []);
  const handleHideProfile = useCallback(() => setShowProfile(false), []);
  const employeeTag = createdByUser.isEmployee ? " (Verwaltung)" : "";
  const profilePicture = (
    <ProfilePicture
      alt={createdByUser.firstName}
      height={180}
      width={180}
      userSid={createdByUser.sid}
      rounded
    />
  );
  return (
    <div className="bulletin-board-post-by-user">
      <div
        className="bulletin-board-post-by-user-picture"
        role="button"
        onClick={handleShowProfile}
      >
        <ProfilePicture
          alt={createdByUser.firstName}
          height={32}
          width={32}
          userSid={createdByUser.sid}
          rounded
        />
      </div>
      <div className="bulletin-board-post-by-user-content">
        <div className="bulletin-board-post-by-user-name">
          {`${createdByUser.firstName} ${createdByUser.lastName}`}
          <span className="bulletin-board-post-by-user-name-is-employee">
            {employeeTag}
          </span>
        </div>
        <div className="bulletin-board-post-by-user-date">
          {moment.utc(createDateUTC).local().format("LLL")}
        </div>
      </div>
      {showProfile && (
        <ContactPersonCardDialog
          aboutMe={createdByUser.aboutMe}
          lastName={createdByUser.lastName}
          firstName={createdByUser.firstName}
          gender={createdByUser.gender}
          profilePicture={profilePicture}
          onClose={handleHideProfile}
          birthDay={
            createdByUser.dayOfBirth
              ? moment({
                  day: createdByUser.dayOfBirth,
                  month: createdByUser.monthOfBirth - 1,
                  year: createdByUser.yearOfBirth,
                }).format("L")
              : undefined
          }
        />
      )}
    </div>
  );
}

type Props = {
  createdByUser: UserProfile;
  createDateUTC: string | Date;
};
