import { ReactNode, useCallback } from "react";
import classNames from "classnames";

import "../../../styles/components/common/menu/MenuListItem.scss";
import { useNavigate } from "react-router-dom";

export default function MenuListItem({
  children,
  className,
  leadingIcon,
  onClick,
  to,
  shouldNavigate,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const isClickable = typeof onClick === "function" || to !== undefined;
  const menuListItemClass = classNames(
    { "menu-list-item": true, "menu-list-item-clickable": isClickable },
    className,
  );

  const onClickHandler = useCallback(() => {
    if (to) {
      if (shouldNavigate) {
        return navigate(to);
      }
      window.location.assign(to);
      return;
    }
    if (onClick) {
      onClick();
    }
  }, [to, onClick]);
  return (
    <li className={menuListItemClass} role="menuitem" onClick={onClickHandler}>
      {leadingIcon && (
        <span className="menu-list-item-leading-icon">{leadingIcon}</span>
      )}
      {children}
    </li>
  );
}

type Props = {
  children: ReactNode;
  leadingIcon?: ReactNode;
  onClick?: () => void;
  className?: string;
  to?: string;
  shouldNavigate?: boolean;
};

export type MenuItem = typeof MenuListItem;
