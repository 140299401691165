import React from "react";
import { IconProps } from "../../types";

export default function CaretDown({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M3.719 7.781L2.28 9.22l9 9 .719.687.719-.687 9-9L20.28 7.78 12 16.063 3.719 7.78z"
        strokeWidth={1}
      />
    </svg>
  );
}
