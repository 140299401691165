import React, { ReactElement, useEffect } from "react";
import "../../styles/components/pages/ContactsPage.scss";
import ServiceProvidersPage from "./ServiceProvidersPage";
import FacilityObjectContactsPage from "./FacilityObjectContactsPage";
import NeighborsPage from "./NeighborsPage";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import SearchField from "../common/SearchField";
import useSearchByContactCardInfo from "../../hooks/useSearchByContactCardInfo";
import useFetchContactCardInfo from "../../hooks/useFetchContactCardInfo";
import FetchApiError from "../common/FetchApiError";
import { useOutletContext } from "react-router-dom";
import { FacilityObject } from "../../types";

export default function ContactsPage(): ReactElement {
  const {
    serviceProvidersList,
    facilityObjectContactsList,
    neighborsContactsList,
    neighborsContactsListError,
  } = useFetchContactCardInfo();

  const {
    handleChangeSearchString: handleChangeSearchStringServiceProviders,
    contactsList: contactsListServiceProviders,
  } = useSearchByContactCardInfo(serviceProvidersList);

  const {
    handleChangeSearchString: handleChangeSearchStringFacilityObjectContacts,
    contactsList: contactsListFacilityObjectContacts,
  } = useSearchByContactCardInfo(facilityObjectContactsList);

  const {
    handleChangeSearchString: handleChangeSearchStringNeighborsContactsLis,
    contactsList: contactsListNeighborsContactsList,
  } = useSearchByContactCardInfo(neighborsContactsList);

  useEffect(() => {
    if (window.location.pathname.endsWith("kontakte")) {
      document.body.getElementsByClassName("homecase-content")[0].scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [window.location.pathname]);
  const [selectedAssignedObject] = useOutletContext<[FacilityObject]>();

  return (
    <div className="container-contacts">
      <Helmet>
        <title>Kontakte - Homecase</title>
      </Helmet>
      <PageHeader
        title="Kontakte"
        placeholder="Suchen..."
        searchComponent={SearchField}
        selectedAssignedObject={selectedAssignedObject}
        onSearch={(evt) => {
          handleChangeSearchStringServiceProviders(evt);
          handleChangeSearchStringFacilityObjectContacts(evt);
          handleChangeSearchStringNeighborsContactsLis(evt);
        }}
      />
      {contactsListNeighborsContactsList.length > 0 && (
        <NeighborsPage
          contactsListNeighborsContactsList={contactsListNeighborsContactsList}
        />
      )}
      {neighborsContactsListError && (
        <FetchApiError error={neighborsContactsListError} />
      )}
      {contactsListFacilityObjectContacts.length > 0 && (
        <FacilityObjectContactsPage
          contactsListFacilityObjectContacts={
            contactsListFacilityObjectContacts
          }
        />
      )}
      {contactsListServiceProviders.length > 0 && (
        <ServiceProvidersPage
          contactsListServiceProviders={contactsListServiceProviders}
        />
      )}
    </div>
  );
}
