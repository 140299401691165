import React from "react";
import ProfilePicture from "../common/ProfilePicture";
import Skeleton from "react-loading-skeleton";

export default function PropositionVoteControlCenterTableSkeleton(): JSX.Element {
  const skeletonItems = Array(10).fill(null);

  return (
    <div
      role="table"
      className="agenda-item-proposition-vote-control-center-table"
      data-testid="agenda-item-proposition-vote-control-center-table-skeleton"
    >
      <div
        className="agenda-item-proposition-vote-control-center-table-header"
        role="row"
      >
        <div
          className="agenda-item-proposition-vote-control-center-table-header-name"
          role="columnheader"
          aria-details="name"
        >
          Eigentümer*in
        </div>
        <div
          className="agenda-item-proposition-vote-control-center-table-header-delegate"
          role="columnheader"
          aria-details="delegate"
        >
          Hat Vollmacht gegeben an
        </div>
        <div className="agenda-item-proposition-vote-control-center-table-header-actions-wrapper">
          <div className="agenda-item-proposition-vote-control-center-table-header-actions">
            Abstimmen
          </div>
        </div>
      </div>
      <div className="agenda-item-proposition-vote-control-center-table-body">
        {skeletonItems.map((v, key) => (
          <div
            role="row"
            key={key}
            className="agenda-item-proposition-vote-control-center-table-row"
          >
            <div
              className="agenda-item-proposition-vote-control-center-table-row-name"
              data-testid="participant-name"
              role="cell"
            >
              <ProfilePicture
                alt={"profile picture"}
                width={32}
                height={32}
                rounded
              />
              <Skeleton
                width={100}
                containerClassName="agenda-item-proposition-vote-control-center-table-row-name-skeleton"
              />
            </div>
            <div
              role="cell"
              className="agenda-item-proposition-vote-control-center-table-row-delegate"
            >
              <div
                className="agenda-item-proposition-vote-control-center-table-row-delegate-participant"
                data-testid="delegate-participant"
              >
                <ProfilePicture
                  alt={"profile picture"}
                  width={32}
                  height={32}
                  rounded
                />
                <Skeleton
                  width={100}
                  containerClassName="agenda-item-proposition-vote-control-center-table-row-name-skeleton"
                />
              </div>
            </div>
            <div
              className="agenda-item-proposition-vote-control-center-table-row-actions"
              data-testid="participant-actions"
              role="cell"
            >
              <Skeleton height={48} width={300} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
