import { SyntheticEvent, useCallback, useState } from "react";
import { MeetingAttendConfirmed, UserRoles } from "../../enums";
import useCustomerRoles from "../useCustomerRoles";
import { useNavigate } from "react-router-dom";
import useAttendMeeting from "./useAttendMeeting";
import useMeetingState from "./useMeetingState";

export default function useHandleMeeting(
  id?: string,
  attendConfirmed?: MeetingAttendConfirmed,
) {
  const meetingIdToAttend = id ? id : "";
  const customerRoles = useCustomerRoles();
  const navigate = useNavigate();
  const meetingState = useMeetingState(id);
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const { handleAttend } = useAttendMeeting(meetingState, customerRoles, id);
  const [isParticipationConfirmed, setParticipationConfirmed] =
    useState(attendConfirmed);

  const handleJoinMeeting = useCallback(
    (e: SyntheticEvent<HTMLButtonElement> | SyntheticEvent<HTMLDivElement>) => {
      if (isEmployee) {
        handleAttend();
        navigate(meetingIdToAttend);
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      switch (isParticipationConfirmed) {
        case MeetingAttendConfirmed.IsToConfirm:
          setParticipationConfirmed(MeetingAttendConfirmed.InConfirmation);
          break;
        case MeetingAttendConfirmed.InConfirmation:
          setParticipationConfirmed(MeetingAttendConfirmed.IsToConfirm);
          handleAttend();
          break;
        default:
          handleAttend();
      }
    },
    [
      customerRoles,
      attendConfirmed,
      setParticipationConfirmed,
      handleAttend,
      id,
      isParticipationConfirmed,
    ],
  );

  return {
    handleJoinMeeting,
    isParticipationConfirmed,
    setParticipationConfirmed,
  };
}
