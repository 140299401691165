import { useParams } from "react-router-dom";
import {
  useCreateOnlineIdentityForTenantMutation,
  useIssueNewRegistrationCodeForOnlineIdentityMutation,
} from "../api/facilityManagementApi";
import { useCallback, useState } from "react";
import { StakeholdersInfo } from "../types";

export default function useCreateNewCode(
  isActivePropertyStakeholder: StakeholdersInfo,
): Props {
  const { customerToken = "" } = useParams();
  const [createOnlineIdentityForTenant] =
    useCreateOnlineIdentityForTenantMutation();

  const [issueNewRegistrationCodeForOnlineIdentity] =
    useIssueNewRegistrationCodeForOnlineIdentityMutation();

  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const handleCreateNewCode = useCallback(async () => {
    setError(null);
    setIsSuccess(false);
    const response = await createOnlineIdentityForTenant({
      customerToken,
      tenantId: isActivePropertyStakeholder.addressId,
      issuedFor:
        isActivePropertyStakeholder.firstName +
        " " +
        isActivePropertyStakeholder.lastName,
    });

    if (response && "data" in response) {
      await issueNewRegistrationCodeForOnlineIdentity({
        customerToken,
        oid: response.data.oid,
      });
      setIsSuccess(true);
    } else if (response && "error" in response) {
      setError(response.error as string);
    }
  }, [
    isActivePropertyStakeholder,
    createOnlineIdentityForTenant,
    issueNewRegistrationCodeForOnlineIdentity,
  ]);

  return { handleCreateNewCode, error, isSuccess };
}

type Props = {
  handleCreateNewCode: () => void;
  error: string | null;
  isSuccess: boolean;
};
