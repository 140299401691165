import React, { ReactNode } from "react";

import "../../styles/components/common/TabBar.scss";

export default function TabBar({ children }: Props): JSX.Element {
  return (
    <div className="tab-bar" role="tablist">
      <div className="tab-bar-scroller">
        <div className="tab-bar-scroller-area">
          <div className="tab-bar-scroller-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

type Props = {
  children: ReactNode | ReactNode[];
};
