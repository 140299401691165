import { useCallback, useMemo } from "react";
import PdfFileIcon from "../../icons/PdfFileIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import classNames from "classnames";
import useDownload from "../../../hooks/useDownload";

import "../../../styles/components/common/files/PdfFile.scss";

export default function PdfFile({
  file,
  width = 100,
  height = 100,
  downloadable,
  onClick,
}: Props): JSX.Element {
  const url = useMemo(() => URL.createObjectURL(file), [file]);
  const handleDownload = useDownload(url, file.name, downloadable);
  const className = classNames({
    "pdf-file": true,
    "pdf-file-downloadable": downloadable,
  });

  const onClickHandler = useCallback(() => {
    onClick && onClick(file);
  }, [onClick, file]);

  return (
    <div className={className} data-testid="pdf-file" onClick={onClickHandler}>
      <PdfFileIcon width={width} height={height} />
      <span className="pdf-file-download-icon" onClick={handleDownload}>
        <DownloadIcon />
      </span>
    </div>
  );
}

type Props = {
  file: File;
  width?: number;
  height?: number;
  downloadable?: boolean;
  onClick?: (file: File) => void;
};
