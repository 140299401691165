import React from "react";
import { IconProps } from "../../../types";

export default function ServicesGlass({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        d="M60.333 20C41.933 20.02 27.02 34.932 27 53.333v40A6.667 6.667 0 0 0 33.667 100H87a6.667 6.667 0 0 0 6.667-6.667v-40C93.647 34.933 78.734 20.02 60.333 20zm-2.5 26.46a.833.833 0 0 1-1.446.563l-12.48-13.55a.82.82 0 0 1 .116-1.223 26.53 26.53 0 0 1 12.884-5.353.833.833 0 0 1 .94.826l-.014 18.737zm5-18.737a.834.834 0 0 1 .94-.826 26.53 26.53 0 0 1 12.894 5.353.84.84 0 0 1 .333.59.83.83 0 0 1-.217.633L64.28 47.023a.833.833 0 0 1-1.447-.563V27.723zm-23.683 9.41a.837.837 0 0 1 1.273-.056l13.687 14.86a.833.833 0 0 1-.613 1.396H34.513a.83.83 0 0 1-.846-.86 26.553 26.553 0 0 1 5.483-15.34zM47 91.667c0 .92-.746 1.666-1.667 1.666h-10c-.92 0-1.666-.746-1.666-1.666V80c0-.92.746-1.667 1.666-1.667h10c.92 0 1.667.747 1.667 1.667v11.667zm0-20c0 .92-.746 1.666-1.667 1.666h-10c-.92 0-1.666-.746-1.666-1.666V60c0-.92.746-1.667 1.666-1.667h10c.92 0 1.667.747 1.667 1.667v11.667zm21.667 20c0 .92-.747 1.666-1.667 1.666H53.667c-.92 0-1.667-.746-1.667-1.666V80c0-.92.746-1.667 1.667-1.667H67c.92 0 1.667.747 1.667 1.667v11.667zm0-20c0 .92-.747 1.666-1.667 1.666H53.667c-.92 0-1.667-.746-1.667-1.666V60c0-.92.746-1.667 1.667-1.667H67c.92 0 1.667.747 1.667 1.667v11.667zm18.333 20c0 .92-.746 1.666-1.667 1.666h-10c-.92 0-1.666-.746-1.666-1.666V80c0-.92.746-1.667 1.666-1.667h10c.92 0 1.667.747 1.667 1.667v11.667zm0-20c0 .92-.746 1.666-1.667 1.666h-10c-.92 0-1.666-.746-1.666-1.666V60c0-.92.746-1.667 1.666-1.667h10c.92 0 1.667.747 1.667 1.667v11.667zM67.17 53.333a.833.833 0 0 1-.613-1.396l13.686-14.86a.83.83 0 0 1 .667-.27.847.847 0 0 1 .623.333A26.553 26.553 0 0 1 87 52.473a.83.83 0 0 1-.833.86H67.17z"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      />
    </svg>
  );
}
