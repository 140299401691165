import React, { useState } from "react";
import AccountEmailForm from "./AccountEmailForm";
import AccountPasswordForm from "./AccountPasswordForm";
import AccountDeleteForm from "./AccountDeleteForm";
import ActionView from "../icons/ActionView";
import classNames from "classnames";

import "../../styles/components/userSettings/Account.scss";

export default function Account(): JSX.Element {
  const [isCheck, setCheck] = useState(false);

  const handlerPasswordView = () => {
    setCheck(!isCheck);
  };

  const className = classNames({
    checkbox: !isCheck,
  });

  return (
    <section className="user-settings-account-container">
      <div className="account-info-container">
        <div className="email">
          <h3>E-Mail-Adresse ändern</h3>
          <span className="disable">
            Hier können Sie Ihre E-Mail-Adresse ändern, mit der Sie sich bei
            HOMECASE anmelden.
          </span>
        </div>
        <AccountEmailForm />
      </div>
      <div className="account-info-container">
        <div className="password">
          <h3>Passwort ändern</h3>
          <span className="disable">
            Hier können Sie Ihr Passwort ändern, mit dem Sie sich bei HOMECASE
            anmelden.
          </span>
          <div
            className="password-checkbox"
            onClick={handlerPasswordView}
            data-testid="password-checkbox"
          >
            <div className={className}>
              <ActionView className="password-checkbox-view" />
            </div>
            <span> Passwörter im Klartext anzeigen</span>
          </div>
        </div>
        <AccountPasswordForm isCheck={isCheck} />
      </div>
      <div className="account-info-container">
        <div className="delete">
          <h3>Account löschen</h3>
          <span className="disable">
            Sollten Sie HOMECASE nicht mehr nutzen wollen, können Sie hier die
            Löschung Ihres Accounts anfordern.
          </span>
        </div>
        <AccountDeleteForm />
      </div>
    </section>
  );
}
