import React from "react";
import { IconProps } from "../../../types";

export default function ServicesUser({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M59.834 24c8.6 0 17.267 5.691 17.267 18.413 0 9.622-2.25 17.074-6.833 22.049a5.75 5.75 0 0 0-.613 5.068l.096.252c.62 1.797 2.007 2.132 4.207 2.939l3.79 1.406c9.585 3.585 16.406 6.505 18.576 10.87a4.466 4.466 0 0 1-1.112 5.328C81.497 102.108 69.704 108 59.834 108c-9.87 0-21.662-5.891-35.378-17.674a4.466 4.466 0 0 1-1.112-5.329c2.447-4.91 10.804-8.004 22.367-12.276l1.225-.443c1.428-.529 2.386-1.008 2.904-2.288l.078-.208a5.75 5.75 0 0 0-.517-5.32c-4.583-4.975-6.833-12.427-6.833-22.049C42.568 29.691 51.234 24 59.834 24z"
        fill="#8E9A9E"
        fillRule="nonzero"
      />
    </svg>
  );
}
