import { useParams } from "react-router-dom";
import { SyntheticEvent, useCallback } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

import { useUpdateMeetingsMutation } from "../../api/meetingApi";
import { MeetingState } from "../../enums";

export default function useUpdateMeetingState(
  id?: string,
): UseUpdateMeetingState {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const [
    updateMeetings,
    { isLoading, error, isSuccess, isError, originalArgs },
  ] = useUpdateMeetingsMutation();
  const meetingIdToUpdate = id ? id : meetingId;
  const startMeeting = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await updateMeetings({
        customerToken,
        meetingId: meetingIdToUpdate,
        facilityObjectId,
        state: MeetingState.Running,
      });
    },
    [meetingIdToUpdate, customerToken, updateMeetings, facilityObjectId],
  );

  const closeMeeting = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await updateMeetings({
        customerToken,
        meetingId: meetingIdToUpdate,
        facilityObjectId,
        state: MeetingState.Closed,
      });
    },
    [meetingIdToUpdate, customerToken, updateMeetings, facilityObjectId],
  );

  const shareMeeting = useCallback(async () => {
    await updateMeetings({
      customerToken,
      meetingId: meetingIdToUpdate,
      facilityObjectId,
      state: MeetingState.OwnerAndEmployeePreview,
    });
  }, [meetingIdToUpdate, customerToken, updateMeetings, facilityObjectId]);

  const retry = useCallback(() => {
    if (originalArgs) {
      updateMeetings(originalArgs);
    }
  }, [originalArgs, updateMeetings]);

  return {
    closeMeeting,
    startMeeting,
    shareMeeting,
    isLoading,
    isSuccess,
    isError,
    error,
    retry,
  };
}

type UseUpdateMeetingState = {
  closeMeeting: (e: SyntheticEvent) => void;
  startMeeting: (e: SyntheticEvent) => void;
  shareMeeting: () => void;
  retry: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
};
