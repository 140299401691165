import React from "react";
import { IconProps } from "../../../types";

export default function ServicesServiceProviderDefault({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M26.155 23 20 29.155l6.155 12.31 9.232 3.077 10.771 10.771 6.155-6.155-10.77-10.77-3.078-9.233L26.155 23zm55.599.03a18.914 18.914 0 0 0-14.167 5.536c-5.035 5.035-5.486 11.09-2.982 16.788L28.86 81.099a9.228 9.228 0 0 0 0 13.055 9.228 9.228 0 0 0 13.055 0L77.66 58.41c5.697 2.503 11.754 2.05 16.788-2.987 5.198-5.198 6.74-12.65 4.653-19.199L87.698 47.632l-9.851-2.465-2.465-9.851 11.409-11.402a19.13 19.13 0 0 0-5.037-.884zm-4.755 45.014L65.417 79.627 80.245 94.46c3.2 3.2 8.391 3.2 11.589 0 3.2-3.2 3.2-8.388 0-11.589L76.999 68.044zM35.387 84.549a3.079 3.079 0 0 1 0 6.155 3.079 3.079 0 0 1 0-6.155z"
        fillRule="nonzero"
      />
    </svg>
  );
}
