import React from "react";
import { Participant } from "../../../types";
import ProfilePicture from "../../common/ProfilePicture";
import { PropositionVoteType } from "../../../enums";
import { getReverseParticipantDisplayName } from "../../../lib/meeting";

export default function ParticipantsVotingResultListItem({
  participant,
}: Props): JSX.Element {
  return (
    <div
      role="row"
      key={participant.id}
      className="etv-participants-voting-result-list-item"
      data-testid="etv-participants-voting-result-list-item"
    >
      <div
        className="etv-participants-voting-result-list-item-name"
        data-testid="participant-name"
        role="cell"
      >
        <ProfilePicture
          alt={participant.lastName || "profile picture"}
          userSid={participant.userSid}
          width={32}
          height={32}
          rounded
        />
        <span>{getReverseParticipantDisplayName(participant, ",")}</span>
      </div>
      <div
        className="etv-participants-voting-result-list-item-actions"
        data-testid="participant-actions"
        role="cell"
      >
        {participant.participantVoting?.vote === PropositionVoteType.Yes && (
          <span className="green">&#10003;</span>
        )}
      </div>
      <div
        className="etv-participants-voting-result-list-item-actions"
        data-testid="participant-actions"
        role="cell"
      >
        {participant.participantVoting?.vote === PropositionVoteType.No && (
          <span className="red">X</span>
        )}
      </div>
      <div
        className="etv-participants-voting-result-list-item-actions"
        data-testid="participant-actions"
        role="cell"
      >
        {participant.participantVoting?.vote ===
          PropositionVoteType.Abstention && <span className="grey">—</span>}
      </div>
    </div>
  );
}

type Props = {
  participant: Participant;
  delegatedParticipant?: Participant;
};
