import React, { useEffect } from "react";
import { Participant } from "../../../types";
import ProfilePicture from "../../common/ProfilePicture";
import Button from "../../common/Button";
import {
  ParticipantAttendanceType,
  PropositionVoteType,
  SynchronisationMethod,
} from "../../../enums";
import useVoteForParticipant from "../../../hooks/meetings/useVoteForParticipant";
import UpdateDialog from "../../common/dialog/UpdateDialog";
import ErrorDialog from "../../common/dialog/ErrorDialog";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import InformationBox from "../../common/InformationBox";
import ParticipantsVotingEligibilityName from "./ParticipantsVotingEligibilityName";

import "../../../styles/components/meetings/participants/ParticipantsVotingEligibilityList.scss";
import classNames from "classnames";

export default function ParticipantsVotingEligibilityListItem({
  participant,
  propositionId,
  delegatedParticipant,
  votingDisabled,
}: Props): JSX.Element {
  const { voteForParticipant, error, isLoading, retry, isSuccess, isError } =
    useVoteForParticipant(participant.id, propositionId);
  const { sync } = useSynchronisation();

  const participantAttend =
    participant.attendance !== ParticipantAttendanceType.Abscent;

  const disabled = votingDisabled || participantAttend;

  const buttonClassName = classNames({
    deactivated: participant.participantVoting?.basedOnInstruction,
  });

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.PropositionVotingChanged, propositionId);
    }
  }, [isSuccess]);

  return (
    <div
      role="row"
      key={participant.id}
      className="etv-participants-voting-eligibility-list-item"
    >
      <div
        className="etv-participants-voting-eligibility-list-item-name"
        data-testid="participant-name"
        role="cell"
      >
        <ProfilePicture
          alt={participant.lastName || "profile picture"}
          userSid={participant.userSid}
          onlineState={participant.attendance}
          width={32}
          height={32}
          rounded
        />
        <ParticipantsVotingEligibilityName participant={participant} forOwner />
      </div>
      <div
        role="cell"
        className="etv-participants-voting-eligibility-list-item-delegate"
      >
        {delegatedParticipant && !participantAttend && (
          <div
            className="etv-participants-voting-eligibility-list-item-delegate-participant"
            data-testid="delegate-participant"
          >
            <ProfilePicture
              alt={delegatedParticipant.lastName || "profile picture"}
              userSid={delegatedParticipant.userSid}
              onlineState={delegatedParticipant.attendance}
              width={32}
              height={32}
              rounded
            />
            <span>{`${delegatedParticipant.firstName} ${delegatedParticipant.lastName}`}</span>
          </div>
        )}
      </div>
      {participantAttend && (
        <InformationBox
          text={`${participant.firstName} ${participant.lastName} ist anwesend. Es kann keine Stimme abgegeben werden.`}
        />
      )}
      {!participantAttend && (
        <div
          className="etv-participants-voting-eligibility-list-item-actions"
          data-testid="participant-actions"
          role="cell"
        >
          <Button
            label="Ja"
            green={[PropositionVoteType.Yes, PropositionVoteType.None].includes(
              participant.participantVoting?.vote,
            )}
            value={PropositionVoteType.Yes}
            disabled={disabled}
            onClick={voteForParticipant}
            className={buttonClassName}
          />
          <Button
            label="Nein"
            red={[PropositionVoteType.No, PropositionVoteType.None].includes(
              participant.participantVoting?.vote,
            )}
            disabled={disabled}
            value={PropositionVoteType.No}
            onClick={voteForParticipant}
            className={buttonClassName}
          />
          <Button
            label="Enthaltung"
            grey={[
              PropositionVoteType.Abstention,
              PropositionVoteType.None,
            ].includes(participant.participantVoting?.vote)}
            disabled={disabled}
            value={PropositionVoteType.Abstention}
            onClick={voteForParticipant}
            className={buttonClassName}
          />
        </div>
      )}
      {isLoading && (
        <UpdateDialog
          description={`Stimme für ${participant.firstName} ${participant.lastName} wird gespeichert.`}
          title="Stimme wird gespeichert"
        />
      )}
      {!isLoading && isError && (
        <ErrorDialog
          description={`Stimme für ${participant.firstName} ${participant.lastName} konnte nicht gespeichert werden.`}
          title="Stimme konnte nicht gespeichert werden."
          onRetry={retry}
          error={error}
        />
      )}
    </div>
  );
}

type Props = {
  participant: Participant;
  propositionId: string;
  delegatedParticipant?: Participant;
  votingDisabled?: boolean;
};
