import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useSynchronisation } from "../useSynchronisation";
import { useGetPropositionQuery } from "../../api/propositionApi";
import { PropositionVotingState, SynchronisationMethod } from "../../enums";
import { Proposition } from "../../types";

const initialState: PropositionVotingStateChangedState = {
  propositionId: "",
  meetingId: "",
  agendaItemId: "",
};

export default function usePropositionVotingStateChanged(): PropositionVotingStateChanged {
  const {
    customerToken = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const [skip, setSkip] = useState(true);
  const [queryProps, setQueryProps] = useState(initialState);
  const { connection } = useSynchronisation();
  const { data, refetch, isFetching, isError } = useGetPropositionQuery(
    {
      ...queryProps,
      customerToken,
      facilityObjectId,
    },
    {
      skip,
    },
  );

  const showDialog = Boolean(
    !skip &&
      !isFetching &&
      !isError &&
      data &&
      agendaItemId !== queryProps.agendaItemId &&
      data.votingState === PropositionVotingState.Running,
  );

  const reset = useCallback(() => {
    setSkip(true);
  }, [skip]);

  const listener = useCallback((args: string[]) => {
    const [meetingId, agendaItemId, propositionId] = args;
    setQueryProps({
      meetingId,
      agendaItemId,
      propositionId,
    });
    setSkip(false);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.on(
        SynchronisationMethod.PropositionVotingStateChanged,
        listener,
      );
    }
    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.PropositionVotingStateChanged);
      }
    };
  }, [connection, listener]);

  useEffect(() => {
    if (!skip && queryProps.propositionId) {
      refetch();
    }
  }, [skip, queryProps, refetch]);

  return {
    showDialog,
    queryProps,
    reset,
    data,
  };
}

type PropositionVotingStateChanged = {
  showDialog: boolean;
  queryProps: PropositionVotingStateChangedState;
  reset: () => void;
  data?: Proposition;
};

type PropositionVotingStateChangedState = {
  meetingId: string;
  agendaItemId: string;
  propositionId: string;
};
