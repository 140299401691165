import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import classNames from "classnames";

import "../../styles/components/common/Button.scss";
import { IconProps } from "../../types";
import useTracking from "../../hooks/googleAnalytics/useTracking";

export default function Button({
  green,
  red,
  grey,
  lightblue,
  label,
  className,
  leadingIcon: LeadingIcon,
  trailingIcon: TrailingIcon,
  onClick,
  trackingEventLabel,
  ...rest
}: Props): JSX.Element {
  const buttonClassName = classNames(
    {
      button: true,
      "button-red": red,
      "button-green": green,
      "button-grey": grey,
      "button-lightblue": lightblue,
    },
    className,
  );

  const { trackEvent } = useTracking();

  return (
    <button
      {...rest}
      className={buttonClassName}
      aria-label={label}
      onClick={trackEvent(
        {
          category: "button",
          action: "click",
          label: trackingEventLabel || label,
        },
        onClick,
      )}
    >
      {LeadingIcon && (
        <LeadingIcon width={24} height={24} className="button-icon" />
      )}
      <span className="button-label">{label}</span>
      {TrailingIcon && (
        <TrailingIcon width={24} height={24} className="button-icon-trailing" />
      )}
    </button>
  );
}

type Props = {
  green?: boolean;
  red?: boolean;
  grey?: boolean;
  lightblue?: boolean;
  label: string;
  trackingEventLabel?: string;
  className?: string;
  value?: string | number | boolean;
  name?: string;
  type?: "button" | "submit" | "reset";
  leadingIcon?: FunctionComponent<IconProps>;
  trailingIcon?: FunctionComponent<IconProps>;
} & ButtonHTMLAttributes<HTMLButtonElement>;
