import { useParams } from "react-router-dom";
import { useGetOnlineTermsOfUseMutation } from "../../api/termsOfUseApi";
import { useCallback } from "react";

export default function useTermsOfUsePatch(latestId: number) {
  const { customerToken = "", termsOfUseId } = useParams();
  const [patchTerms, { isError, isSuccess }] = useGetOnlineTermsOfUseMutation();

  const termsOfUsePatch = useCallback(
    async (content: string) => {
      if (!!termsOfUseId && Number(termsOfUseId) <= latestId) {
        return await patchTerms({
          customerToken,
          content,
          id: Number(termsOfUseId),
        });
      }
    },
    [termsOfUseId, latestId],
  );
  return { termsOfUsePatch, isError, isSuccess };
}
