import React from "react";
import { IconProps } from "../../types";

export default function List({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m7.992 19.26.155.007c.255.028.498.127.7.286l.115.103a1.36 1.36 0 1 1-1.097-.39l.127-.006zm5.772.353 8.643.007a1 1 0 0 1 .115 1.993l-.117.007-8.642-.007a1 1 0 0 1-.115-1.994l.116-.006zm-5.772-6.115.155.008c.255.027.498.126.7.285l.115.103a1.36 1.36 0 1 1-1.097-.39l.127-.006zm11.787.367a1 1 0 0 1 .116 1.994l-.116.006h-6.016a1 1 0 0 1-.116-1.993l.116-.007h6.016zM7.992 7.736l.155.008c.255.027.498.126.7.285l.115.103a1.36 1.36 0 1 1-1.097-.39l.127-.006zm14.414.353a1 1 0 0 1 .117 1.993l-.117.007h-8.643a1 1 0 0 1-.116-1.993l.116-.007h8.643z"
        fillRule="evenodd"
      />
    </svg>
  );
}
