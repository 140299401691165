import React from "react";

export default function LoginPage(): JSX.Element {
  return (
    <div className="login-wrapper" data-testid="login-wrapper">
      <div
        className="login-wrapper__content"
        data-testid="login-wrapper__content"
      ></div>
    </div>
  );
}
