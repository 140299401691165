import { useCallback, useMemo } from "react";
import { TenantsRegistrationsData } from "../types";

export default function useExportToCSV(
  registrationData?: TenantsRegistrationsData[],
): Props {
  const newRegistrationData = useMemo(() => {
    return registrationData?.map((item) => ({
      ID: item.addressId,
      Vorname1: item.firstName1,
      Nachname1: item.lastName1,
      Vorname2: item.firstName2,
      Nachname2: item.lastName2,
      Vorname3: item.firstName3,
      Nachname3: item.lastName3,
      Vorname4: item.firstName4,
      Nachname4: item.lastName4,
      Straße: item.street,
      Stadt: item.city,
      PLZ: item.zipCode,
      BriefAnschrift: item.postalAddress,
      Registrierungscodes: item.onlineIdentities
        .map((identity) => identity.regCode)
        .join("\r"),
    }));
  }, [registrationData]);

  const rows = useMemo(() => {
    return newRegistrationData
      ? newRegistrationData
          .map((item) =>
            Object.values(item)
              .map((value) => {
                // Überprüfen, ob der Wert ein String ist
                if (typeof value === "string") {
                  // Zeilenumbrüche durch \r\n ersetzen
                  return `"${value.replace(/\n/g, "\r\n")}"`;
                }
                return value;
              })
              .join(","),
          )
          .join("\n")
      : [];
  }, [newRegistrationData, registrationData]);

  const headers = useMemo(() => {
    return newRegistrationData && newRegistrationData.length > 0
      ? Object.keys(newRegistrationData[0]).join(",") + "\n"
      : ", ";
  }, [newRegistrationData, registrationData]);

  const exportToCSV = useCallback(async () => {
    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [newRegistrationData, registrationData, headers, rows]);

  return { exportToCSV };
}

type Props = {
  exportToCSV: () => void;
};
