import React from "react";
import { IconProps } from "../../types";

export default function Protocol({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <filter
          x="-5.8%"
          y="-4.7%"
          width="111.5%"
          height="112.5%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
            in="shadowOffsetOuter1"
          />
        </filter>
        <path
          d="M6 2h24a1 1 0 0 1 1 1v23.113L24.953 34H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z"
          id="b"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <path
          strokeOpacity=".25"
          stroke="#9599AF"
          d="M30 1.5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06v23.283L25.2 34.5H6c-.414 0-.79-.168-1.06-.44A1.495 1.495 0 0 1 4.5 33V3c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44z"
          fill="#FFF"
        />
        <rect fill="#7A7F9A" x="8" y="6" width="8" height="1" rx=".5" />
        <rect
          fillOpacity=".6"
          fill="#9599AF"
          x="8"
          y="9"
          width="16"
          height="1"
          rx=".5"
        />
        <path
          d="M33 28v4.17c0 1.497-1.41 2.83-2.947 2.83H19.947C18.411 35 17 33.667 17 32.17V28h16zm-2.947-9C31.589 19 33 20.333 33 21.83v4.15H17v-4.15c0-1.497 1.41-2.83 2.947-2.83z"
          fill="#8E9A9E"
          fillRule="nonzero"
        />
        <path
          d="M27.779 23.062c0 .697-.547 1.23-1.263 1.23h-3.032c-.716 0-1.263-.533-1.263-1.23v-.739c0-.41.337-.738.758-.738s.758.328.758.738v.492h2.526v-.492c0-.41.337-.738.758-.738s.758.328.758.738v.739zM22.221 30.2c0-.41.337-.738.758-.738s.758.328.758.738v.492h2.526V30.2c0-.41.337-.738.758-.738s.758.328.758.738v.738c0 .698-.547 1.231-1.263 1.231h-3.032c-.716 0-1.263-.533-1.263-1.23V30.2z"
          fill="#FFF"
        />
        <g
          opacity=".6"
          stroke="#9599AF"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M13 15.5 10.501 18 9 16.5M12.26 25.74l-3.02 3.02M12.26 28.76l-3.02-3.02M13 20.5 10.501 23 9 21.5" />
        </g>
      </g>
    </svg>
  );
}
