import React from "react";
import ProfilePicture from "../common/ProfilePicture";
import Skeleton from "react-loading-skeleton";

export default function ParticipantsVotingEligibilityListSkeleton({
  forOwner,
}: Props): JSX.Element {
  const skeletonItems = Array(forOwner ? 1 : 10).fill(null);

  return (
    <div
      role="table"
      className="etv-participants-voting-eligibility-list"
      data-testid="voting-eligibility-list-skeleton"
    >
      {skeletonItems.map((v, key) => (
        <div
          role="row"
          key={key}
          className="etv-participants-voting-eligibility-list-item"
        >
          <div
            className="etv-participants-voting-eligibility-list-item-name"
            data-testid="participant-name"
            role="cell"
          >
            <ProfilePicture
              alt={"profile picture"}
              width={32}
              height={32}
              rounded
            />
            <Skeleton width={100} />
          </div>
          <div
            role="cell"
            className="etv-participants-voting-eligibility-list-item-delegate"
          >
            <Skeleton />
          </div>
          <div
            className="etv-participants-voting-eligibility-list-item-actions"
            data-testid="participant-actions"
            role="cell"
          >
            <Skeleton height={48} width={300} />
          </div>
        </div>
      ))}
    </div>
  );
}

type Props = {
  forOwner?: boolean;
};
