import { FunctionComponent, ReactNode, useCallback, useRef } from "react";
import { useEffect } from "react";
import classNames from "classnames";
import { IconProps } from "../../../types";
import CloseIcon from "../../icons/CloseIcon";
import Phone from "../../icons/Phone";
import Mail from "../../icons/Mail";
import ContactPersonCardResponsibility from "./ContactPersonCardResponsibility";
import ContactPersonCardIcon from "./ContactPersonCardIcon";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

import "../../../styles/components/common/contact/ContactPersonCardDialog.scss";

export default function ContactPersonCardDialog(
  props: PropsProvider,
): JSX.Element {
  const {
    firstName,
    lastName,
    responsibilities = [],
    icon: ItemIcon,
    phone,
    email,
    webpage,
    street,
    zip,
    city,
    fax,
    gender,
    birthDay,
    profilePicture,
    aboutMe,
    firma,
    contractedResources,
    onClose,
  } = props;

  const classNameLink = classNames({ center: !phone || !email });
  const classNameDiv = classNames({ center: !birthDay || !gender });

  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  const handleOnKeyDown = useCallback((evt: KeyboardEvent) => {
    if (["ArrowRight", "ArrowLeft", "Escape"].includes(evt.key)) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);
    return () => document.removeEventListener("keydown", handleOnKeyDown);
  }, []);

  return (
    <div className="contact-person-card-dialog-background-layer">
      <div
        className="contact-person-card-dialog-container"
        role="alertdialog"
        aria-modal
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
        data-testid="contact-person-card-dialog"
        ref={ref}
      >
        <span
          className="dialog-container-close"
          onClick={onClose}
          role="button"
          data-testid="close-button"
        >
          <CloseIcon className="dialog-container-close-icon" />
        </span>
        <ContactPersonCardIcon
          firstName={firstName}
          lastName={lastName}
          icon={ItemIcon}
          profilePicture={profilePicture}
        />

        <ContactPersonCardResponsibility
          responsibilities={responsibilities}
          contractedResources={contractedResources}
          dialog
        />

        <div className="contact-person-card-dialog-container-contacts">
          {phone && phone?.length > 0 && (
            <div className="contact-person-card-dialog-container-contacts-info">
              <a href={`tel:${phone[0]}`} className={classNameLink}>
                <Phone className="contact-person-card-icon" />
                <span>{phone[0]}</span>
              </a>
            </div>
          )}

          {email && email?.length > 0 && (
            <div className="contact-person-card-dialog-container-contacts-info">
              <a href={`mailto:${email[0]}`} className={classNameLink}>
                <Mail className="contact-person-card-icon" />
                <span>{email[0]}</span>
              </a>
            </div>
          )}
          {gender && (
            <div className="contact-person-card-dialog-container-contacts-info">
              <div className={classNameDiv}>
                <span>{gender}</span>
              </div>
            </div>
          )}
          {birthDay && (
            <div className="contact-person-card-dialog-container-contacts-info">
              <div className={classNameDiv}>
                <span>{birthDay}</span>
              </div>
            </div>
          )}
        </div>
        <div>{firma}</div>
        <div className="contact-person-card-dialog-container-data">
          <div className="contact-person-card-dialog-container-address">
            {webpage && webpage?.length > 0 && (
              <div className="contact-person-cards-contacts-info">
                <span>Web: </span>{" "}
                <a
                  className="contact-person-cards-contacts-info-webpage"
                  href={`http://${webpage[0]}`}
                  target="_blank"
                >
                  {webpage[0]}
                </a>
              </div>
            )}
            {fax && fax?.length > 0 && (
              <div className="contact-person-cards-contacts-info">
                Fax: {fax[0]}
              </div>
            )}
            {street && (
              <div className="contact-person-cards-contacts-info">
                {street}, {zip} {city}
              </div>
            )}
            {aboutMe && (
              <div className="contact-person-cards-contacts-info">
                {aboutMe}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

type PropsProvider = {
  firstName?: string;
  lastName: string;
  onClick?: () => void;
  responsibilities?: (string | undefined)[];
  icon?: FunctionComponent<IconProps>;
  profilePicture?: ReactNode;
  phone?: string[];
  email?: string[];
  webpage?: string[];
  street?: string;
  zip?: string;
  city?: string;
  aboutMe?: string;
  gender?: string;
  birthDay?: string;
  fax?: string[];
  onClose: () => void;
  firma?: string;
  contractedResources?: string[];
};
