import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/DataSecurityPage.scss";

export default function DataSecurityPage(): ReactElement {
  const backTo = {
    to: "/",
    label: "Zurück zu HOMECASE",
  };

  return (
    <div className="data-security-page-container">
      <Helmet>
        <title> Datenschutz - Homecase</title>
      </Helmet>
      <PageHeader title="Datenschutz" backTo={backTo} />
      <div className="data-security-page-container-info">
        <h2>Informationspflichten nach Art. 12 ff. EU-DSGVO</h2>

        <h3>
          A Informationspflichten nach Art. 12 ff. EU-DSGVO für unsere Webseite
        </h3>
        <h3>I Name und Kontaktdaten des Verantwortlichen</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Ihr Ansprechpartner als Verantwortlicher im Sinne der Europäischen
            Datenschutz-Grundverordnung („EU-DSGVO“) und anderer nationaler
            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
            datenschutzrechtlicher Bestimmungen ist:
          </p>

          <p>
            DOMUS Software AG
            <br />
            Otto-Hahn-Straße 4<br />
            85521 Riemerling (Hohenbrunn)
            <br />
            Tel.: +49 (0)89 66086-0
            <br />
            E-Mail: homecase@domus-software.de
          </p>

          <p>(im Folgenden als „wir“, „uns“ oder „unser“ bezeichnet).</p>
        </div>
        <h3>II Kontaktdaten des Datenschutzbeauftragten</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Unser Datenschutzbeauftragter kann über folgende Kontaktdaten
            erreicht werden:
          </p>

          <p>
            MAGELLAN Säugling Rechtsanwaltsgesellschaft mbH, Raiffeisenallee 9,
            82041 Oberhaching /
            <a href="https://www.magellan-legal.de" target="_blank">
              www.magellan-legal.de
            </a>
          </p>

          <p>
            Bitte wenden Sie sich in allen Fragen rund um das Thema Datenschutz
            und Datensicherheit bei uns direkt an unseren
            Datenschutzbeauftragten. E-Mail: datenschutz_domus@magellan-legal.de
            / Tel.: +49 (0)89 66086-0
          </p>
        </div>
        <h3>III Allgemeines zu Datenverarbeitung</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Umfang</h4>

          <p>
            Wir verarbeiten Ihre personenbezogenen Daten grundsätzlich nur,
            soweit dies zur funktionsfähigen Bereitstellung unserer Webseite
            sowie unserer Inhalte und Leistungen erforderlich ist.
          </p>

          <h4>2 Rechtsgrundlage</h4>

          <p>
            Soweit wir für die Verarbeitung Ihrer personenbezogenen Daten eine
            Einwilligung von Ihnen einholen, ist die Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 S. 1 lit. a) EU-DSGVO.
          </p>
          <p>
            Erfolgt die Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung
            eines Vertrages mit Ihnen oder im Rahmen der Anbahnung einer
            Vertragsbeziehung, ist die Rechtsgrundlage für die Verarbeitung Art.
            6 Abs. 1 S. 1 lit. b) EU-DSGVO.
          </p>
          <p>
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
            uns obliegenden rechtlichen Verpflichtung erforderlich ist, ist die
            Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. c)
            EU-DSGVO.
          </p>
          <p>
            Erfolgt die Verarbeitung Ihrer personenbezogenen Daten zur Wahrung
            der berechtigten Interessen von uns oder eines Dritten, wobei Ihre
            Interessen, Grundrechte und Grundfreiheiten das erstgenannte
            Interesse nicht überwiegen, ist die Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.
          </p>

          <h4>3 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden gelöscht, sobald der Zweck für
            die Speicherung entfällt oder, sollte Ihnen ein Widerrufsrecht
            zustehen, Sie den Widerruf der Einwilligung erklären. Eine
            Speicherung kann darüber hinaus erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen wir
            unterliegen, festgelegt wurde. In diesem Fall werden Ihre
            personenbezogenen Daten jedoch gesperrt.
          </p>

          <h4>4 Externe Verlinkungen</h4>

          <p>
            Wenn wir Links zu externen Webseiten bereitstellen, gilt diese
            Datenschutzerklärung nicht für die Verarbeitung Ihrer
            personenbezogenen Daten durch den Verantwortlichen der verlinkten
            Webseite. Wir empfehlen Ihnen daher, die Datenschutzhinweise auf der
            extern von Ihnen besuchten Webseite zu lesen. Sofern diese
            Verlinkung eine Rechtsgrundlage für die hierdurch entstehende
            Verarbeitung Ihrer personenbezogenen Daten benötigt, ist dies Ihre
            Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a) EU-DSGVO, die Sie
            durch das Klicken der Verlinkung geben.
          </p>
          <p>
            Für gewöhnlich werden durch das Klicken der Verlinkung (Hyperlink)
            folgende Ihrer personenbezogenen Daten verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Bildschirmauflösung</li>
            <li>Verwendeter Browser</li>
            <li>Bandbreite</li>
            <li>Spracheinstellungen</li>
          </ul>
        </div>
        <h3>IV Datenverarbeitungen auf der Webseite</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Webseitenfunktionen</h4>
        </div>
        <h3>a Bereitstellung der Webseite und Erstellung von Logfiles</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen der Bereitstellung unserer Webseite verarbeiten wir Ihre
            personenbezogenen Daten, um eine fehlerfreie Auslieferung unserer
            Webseite an Ihren PC oder Ihr Mobile Device zu ermöglichen. Hierbei
            müssen teilweise Ihre personenbezogenen Daten für die Dauer einer
            Sitzung gespeichert werden.
          </p>
          <p>
            Wir speichern Ihre personenbezogenen Daten zudem vorübergehend in
            Logfiles, um die Funktionsfähigkeit unserer Webseite und die
            Sicherheit unserer IT-Systeme zu gewährleisten. Eine anderweitige
            Verarbeitung Ihrer personenbezogenen Daten in Logfiles findet nicht
            statt.
          </p>
          <p>
            Folgende Ihrer personenbezogenen Daten werden für die Bereitstellung
            der Webseite und zur Erstellung von Logfiles verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Zugriffsdatum</li>
            <li>Zugriffszeitpunkt</li>
            <li>Ggf. zuvor besuchte Webseite</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
          </ul>

          <h4>2 Rechtsgrundlage</h4>

          <p>Berechtigtes Interesse, Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.</p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bereitstellung der
            Webseite, der Gewährleistung der Funktionsfähigkeit der Webseite und
            der Sicherheit der hierfür genutzten IT-Systeme.
          </p>

          <p>Im Zweck liegt gleichzeitig auch unser berechtigtes Interesse.</p>

          <h4>4 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden in Logfiles für die Dauer von 30
            Tagen gespeichert. Darüber hinaus werden Ihre personenbezogenen
            Daten im Rahmen der Bereitstellung der Webseite nur für die Dauer
            der Sitzung gespeichert.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten und die Speicherung
            Ihrer personenbezogenen Daten in Logfiles ist für die Bereitstellung
            der Webseite, die Gewährleistung der Funktionsfähigkeit der Webseite
            und die Gewährleistung der genutzten IT-Systeme zwingend
            erforderlich. Es besteht für Sie folglich keine
            Widerspruchsmöglichkeit.
          </p>
        </div>
        <h3>b Technisch-notwendige Cookies</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen von technisch-notwendigen Cookies verarbeiten wir Ihre
            personenbezogenen Daten, weil viele Funktionen und Dienste unserer
            Webseite, die Ihnen die Nutzung unserer Webseite erleichtern oder
            überhaupt erst ermöglichen, ohne Cookies (sog. „technisch-notwendige
            Cookies“) nicht ordnungsgemäß funktionieren.
          </p>

          <p>
            Mittels dieser technisch-notwendigen Cookies speichern wir teilweise
            personenbezogene Daten von Ihnen, die allerdings nur zum Einsatz
            dieser Funktionen und Dienste verwendet werden. Eine anderweitige
            Verarbeitung Ihrer personenbezogenen Daten findet nicht statt.
          </p>

          <p>
            Eine Auflistung der von uns genutzten technisch-notwendigen Cookies,
            deren Zweck, Speicherdauer sowie weitere Informationen finden Sie in
            unserem Cookie-Banner.
          </p>

          <p>
            Folgende personenbezogene Daten werden im Rahmen des Einsatzes von
            technisch-notwendigen Cookies verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Spracheinstellungen Ihres Browsers</li>
            <li>Verwendeter Browser</li>
            <li>Warenkorb-Informationen</li>
          </ul>

          <h4>2 Rechtsgrundlage</h4>

          <p>
            Berechtigtes Interesse, § 25 Abs. 2 TDDDG i.V.m. Art. 6 Abs. 1 S. 1
            lit. f) EU-DSGVO.
          </p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bereitstellung der
            Funktionen und Dienste unserer Webseite.
          </p>

          <p>Im Zweck liegt gleichzeitig auch unser berechtigtes Interesse.</p>

          <h4>4 Speicherdauer</h4>

          <p>
            In der Regel für die Dauer der jeweiligen Sitzung, sofern sich aus
            den Detailinformationen der Auflistung der von uns genutzten
            technisch-notwendigen Cookies nichts anderes ergibt.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Technisch-notwendige Cookies werden auf Ihrem PC oder Mobile Device
            gespeichert und von diesem an unsere Webseite übermittelt. Daher
            haben Sie die volle Kontrolle über die Verwendung von
            technisch-notwendigen Cookies.
          </p>

          <p>
            Durch eine Änderung der Einstellungen in Ihrem Browser können Sie
            die Übertragung von Cookies deaktivieren oder einschränken. Bereits
            gespeicherte Cookies können Sie jederzeit löschen. Dies kann auch
            automatisiert erfolgen. Werden Cookies für unsere Webseite
            deaktiviert, können möglicherweise nicht mehr alle Funktionen der
            Webseite vollumfänglich genutzt werden.
          </p>
        </div>
        <h3>c Technisch nicht-notwendige Cookies</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Sofern es im Rahmen der Funktionen und Dienste unserer Webseite zum
            Einsatz von technisch nicht-notwendigen Cookies kommt, finden Sie
            eine Auflistung dieser Cookies, deren Zweck, Speicherdauer sowie
            weitere Informationen in unserem Cookie-Banner.
          </p>

          <h2>2 eCommerce</h2>
        </div>
        <h3>a Nutzerkonto-Registrierung</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Auf unserer Webseite haben Sie die Möglichkeit, sich für unsere
            Plattform zu registrieren.
          </p>

          <p>
            Im Rahmen der Registrierung und der Eröffnung Ihres Nutzerkontos
            kommt es zur Verarbeitung folgender personenbezogener Daten:
          </p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>E-Mail-Adresse</li>
            <li>Registrierungscode</li>
          </ul>

          <h4>2 Rechtsgrundlage</h4>

          <p>Vertragserfüllung, Art. 6 Abs. 1 S. 1 lit. b) EU-DSGVO.</p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bereitstellung Ihres
            Nutzerkontos und der ggf. ergänzenden Bereitstellung weiterer
            zusätzlicher Inhalte und Leistungen.
          </p>

          <h4>4 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden für die Dauer der Bereitstellung
            Ihres Nutzerkontos gespeichert.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Sie haben jederzeit die Möglichkeit Ihr Nutzerkonto aufzulösen. In
            diesem Fall werden Ihre personenbezogenen Daten gelöscht, soweit
            nicht gesetzliche Aufbewahrungsfristen einer Löschung
            entgegenstehen.
          </p>
        </div>
        <h3>b E-Mail-Kontakt</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen der E-Mail-Kontaktaufnahme werden folgende
            personenbezogene Daten verarbeitet:
          </p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>E-Mail-Adresse</li>
            <li>Inhalt der Nachricht</li>
          </ul>
          <h4>2 Rechtsgrundlage</h4>

          <p>Berechtigtes Interesse, Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.</p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bearbeitung Ihrer
            Anfrage.
          </p>

          <h4>4 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden bis zum Zweckwegfall
            gespeichert. Dieser tritt in der Regel mit der Bearbeitung Ihrer
            Anfrage ein, sofern keine längeren Aufbewahrungsfristen einschlägig
            sind.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Sie haben jederzeit die Möglichkeit, der Verarbeitung Ihrer
            personenbezogenen Daten im Rahmen der Kontaktaufnahme für die
            Zukunft zu widersprechen. In diesem Fall können wir Ihre Anfrage
            jedoch nicht weiterbearbeiten. Alle personenbezogenen Daten, die im
            Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall
            gelöscht, es sei denn, dass gesetzliche Aufbewahrungsfristen einer
            Löschung entgegenstehen. Dann erfolgt eine Sperrung Ihrer
            personenbezogenen Daten bis zum Ende der gesetzlichen
            Aufbewahrungsfristen.
          </p>

          <h4>3 Marketing</h4>
        </div>
        <h3>a Webanalyse durch Google Analytics</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>
          <p>
            Im Rahmen der Webanalyse nutzen wir die Plattform Google Analytics
            zum Sammeln von Kennzahlen unserer Webseite und -zur Analyse Ihres
            Surfverhaltens.
          </p>
          <p>
            Werden Einzelseiten unserer Webseite aufgerufen, so werden folgende
            Daten gespeichert:
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Bildschirmauflösung</li>
            <li>Maus- und Keyboardverhalten</li>
          </ul>
          <h4>2 Rechtsgrundlage</h4>
          Einwilligung, § 25 Abs. 1 TDDDG i.V.m. Art. 6 Abs. 1 S. 1 lit. a)
          EU-DSGVO.
          <h4>3 Zweck</h4>
          <p>
            Der Zweck der Datenverarbeitung liegt in der Analyse Ihres
            Surfverhaltens. Wir sind durch die Auswertung der gewonnen Daten in
            der Lage, Informationen über die Nutzung der einzelnen Komponenten
            unserer Webseite zusammenzustellen. Dies hilft uns dabei, unsere
            Webseite und deren Nutzerfreundlichkeit stetig zu verbessern.
          </p>
          <h4>4 Speicherdauer</h4>
          <p>
            Eine genaue Aufstellung der Speicherdauer eines jeden von uns
            eingesetzten „Tracking Cookies“ finden Sie in unserem Cookie Banner.
          </p>
          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>
          <p>
            Sie können Ihre Einwilligungserklärung in die Verarbeitung Ihrer
            personenbezogenen Daten im Rahmen des Einsatzes von Google Analytics
            jederzeit für die Zukunft widerrufen, indem Sie wie folgt vorgehen:
          </p>
        </div>
        <h3>i Änderung der Einwilligungseinstellungen auf unserer Webseite</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Wir bieten Ihnen auf unserer Webseite die Möglichkeit an, die
            Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Einsatzes
            von Google Analytics einfach zu widerrufen.
          </p>

          <p>Dies können Sie in unserem Cookie Banner tun.</p>
        </div>
        <h3>ii Änderung der Einstellungen Ihres Browsers</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Alternativ können Sie durch eine Änderung der Einstellungen in Ihrem
            Browser die Übertragung von Cookies im Allgemeinen deaktivieren oder
            einschränken. Bereits gespeicherte Cookies können Sie jederzeit
            löschen. Dies kann auch automatisiert erfolgen. Werden auch
            technisch-notwendige Cookies für unsere Webseite deaktiviert, können
            möglicherweise nicht mehr alle Funktionen der Webseite
            vollumfänglich genutzt werden.
          </p>
        </div>
        <h3>iii Browser-Add-on</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Wenn Sie nicht möchten, dass Ihre personenbezogenen Daten durch
            Google Analytics verarbeitet werden, können Sie zudem das
            Browser-Add-on zur Deaktivierung von Google Analytics installieren.
            Dieses Add-on weist das auf Webseiten ausgeführte JavaScript von
            Google Analytics (ga.js, analytics.js und dc.js) an, das Senden von
            Informationen an Google Analytics nicht zu gestatten.
          </p>

          <p>
            Falls Sie Google Analytics deaktivieren möchten, rufen Sie unten
            genannte Seite auf und installieren Sie das Add-on zur Deaktivierung
            von Google Analytics für Ihren Browser. Detaillierte Informationen
            zur Installation und Deinstallation des Add-ons erhalten Sie in den
            relevanten Hilferessourcen für Ihren Browser.
          </p>

          <p>
            Browser- und Betriebssystem-Updates können dazu führen, dass das
            Add-on zur Deaktivierung nicht mehr wie vorgesehen arbeitet. Weitere
            Informationen zum Verwalten von Add-ons für Chrome finden Sie in den
            unten genannten Seiten. Falls Sie nicht Chrome verwenden,
            informieren Sie sich direkt beim Hersteller Ihres Browsers, ob
            Add-ons in der von Ihnen verwendeten Browserversion ordnungsgemäß
            arbeiten.
          </p>

          <p>
            Die neuesten Versionen von Internet Explorer laden gelegentlich das
            Add-on zur Deaktivierung von Google Analytics, nachdem
            personenbezogene Daten bereits an Google Analytics gesendet wurden.
            Falls Sie Internet Explorer verwenden, werden daher durch das Add-on
            Cookies auf Ihrem Rechner installiert. Durch diese Cookies wird
            sichergestellt, dass jegliche erfassten Daten sofort vom Server, der
            die Daten erfasst hat, gelöscht werden. Stellen Sie sicher, dass
            Drittanbieter-Cookies nicht für den Internet Explorer deaktiviert
            sind. Wenn Sie Ihre Cookies löschen, werden diese Cookies innerhalb
            kurzer Zeit durch das Add-on zurückgesetzt, um sicherzustellen, dass
            Ihr Google Analytics-Browser-Add-on weiterhin uneingeschränkt
            arbeitet.
          </p>

          <p>
            Das Browser-Add-on zur Deaktivierung von Google Analytics verhindert
            nicht, dass personenbezogene Daten an die Webseite oder an andere
            Tracking-Dienste gesendet werden.
          </p>

          <p>
            Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden
            Sie unter:
          </p>

          <p>
            http://www.google.com/analytics/terms/de.html bzw. unter
            https://support.google.com/analytics/answer/6004245?hl=de.
          </p>

          <p>Auf unserer Webseite ist zudem die IP-Anonymisierung aktiviert.</p>

          <h4>4 Datenschutz und Recht</h4>
        </div>
        <h3>a Ausübung Ihrer Betroffenenrechte nach Art. 12 ff. EU-DSGVO</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen der Bearbeitung von Betroffenenrechten verarbeiten wir
            Ihre personenbezogenen Daten. Dabei verarbeiten wir Ihre in diesem
            Zusammenhang übermittelten Kontaktdaten ausschließlich zur
            Bearbeitung und Beantwortung Ihrer Nachricht und der anschließenden
            Dokumentation der gesetzeskonformen Bearbeitung im Rahmen unserer
            Rechenschaftspflicht.
          </p>

          <p>
            Im Rahmen der Bearbeitung von Betroffenenrechten werden folgende
            personenbezogene Daten verarbeitet:
          </p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Postanschrift</li>
            <li>E-Mail-Adresse</li>
            <li>Telefonnummer</li>
          </ul>

          <h4>2 Rechtsgrundlage</h4>

          <p>
            Rechtliche Pflicht, Art. 6 Abs. 1 S. 1 lit. c) i.V.m. Art. 12 ff.
            EU-DSGVO.
          </p>

          <p>
            Berechtigtes Interesse für die anschließende Dokumentation, Art. 6
            Abs. 1 S. 1 lit. f) EU-DSGVO.
          </p>

          <h4>3 Zweck</h4>

          <p>
            Gesetzeskonforme Bearbeitung Ihrer datenschutzrechtlichen
            Betroffenenrechte.
          </p>

          <h4>4 Speicherdauer</h4>

          <p>
            3 Jahre nach Abschluss der Bearbeitung des jeweiligen Vorgangs, § 41
            BDSG i.V.m. § 31 Abs. 2 Nr. 1 OWIG.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Sie haben jederzeit die Möglichkeit, der Verarbeitung Ihrer
            personenbezogenen Daten im Rahmen der Bearbeitung Ihrer
            Betroffenenrechte für die Zukunft zu widersprechen. In diesem Fall
            können wir Ihre datenschutzrechtlichen Betroffenenrechte jedoch
            nicht weiterbearbeiten.
          </p>

          <p>
            Die Dokumentation der gesetzeskonformen Bearbeitung der jeweiligen
            Bearbeitung Ihrer Betroffenenrechte ist zwingend erforderlich. Es
            besteht folglich für Sie keine Widerspruchsmöglichkeit.
          </p>
        </div>
        <h3>b Rechtsverteidigung und -durchsetzung</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Ihre personenbezogenen Daten werden von uns verarbeitet, wenn Sie
            rechtliche Ansprüche gegen uns geltend machen oder wir Ansprüche und
            Rechte gegen Sie geltend machen.
          </p>

          <h4>2 Rechtsgrundlage</h4>

          <p>Berechtigtes Interesse, Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.</p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Abwehr unberechtigter
            Inanspruchnahmen und der rechtlichen Durchsetzung und Geltendmachung
            von Ansprüchen und Rechten.
          </p>

          <p>Hierin liegt auch unser berechtigtes Interesse.</p>

          <h4>4 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden bis zum Zweckwegfall
            gespeichert. Dies ist in der Regel mit Rechtskraft der jeweiligen
            Entscheidung gegeben.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der
            Rechtsverteidigung und -durchsetzung ist für die Rechtsverteidigung
            und -durchsetzung zwingend erforderlich. Es besteht folglich für Sie
            keine Widerspruchsmöglichkeit.
          </p>
        </div>
        <h3>V Empfängerkategorien</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Innerhalb unseres Unternehmens erhalten diejenigen Stellen und
            Abteilungen personenbezogene Daten, die diese zur Erfüllung der
            zuvor genannten Zwecke brauchen. Daneben bedienen wir uns zum Teil
            unterschiedlicher Dienstleister und übermitteln Ihre
            personenbezogenen Daten an weitere vertrauenswürdige Empfänger.
            Diese können z.B. sein:
          </p>

          <ul>
            <li>Druckereien</li>
            <li>Lettershops</li>
            <li>Scan-Service</li>
            <li>Bankinstitute</li>
            <li>IT-Dienstleister</li>
            <li>Kooperationspartner</li>
            <li>Rechtsanwälte, Steuerberater und Gerichte</li>
          </ul>
        </div>
        <h3>VI Drittlandübermittlung</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Im Rahmen der Verarbeitung Ihrer personenbezogenen Daten kann es
            vorkommen, dass wir Ihre personenbezogenen Daten an
            vertrauenswürdige Dienstleister in Drittländern übermitteln.
            Drittländer sind Länder, die außerhalb der Europäischen Union (EU)
            oder des Europäischen Wirtschaftsraums (EWR) liegen.
          </p>
          <p>
            Dabei arbeiten wir nur mit solchen Dienstleistern zusammen, die uns
            geeignete Garantien für die Sicherheit Ihrer personenbezogenen Daten
            geben und garantieren können, dass Ihre personenbezogenen Daten nach
            den strengen europäischen Datenschutzstandards verarbeitet werden.
            Eine Kopie dieser geeigneten Garantien kann bei uns vor Ort
            eingesehen werden.
          </p>
          <p>
            Wenn wir personenbezogene Daten in Drittländer übermitteln, erfolgt
            dies auf der Grundlage eines sogenannten Angemessenheitsbeschlusses
            der Europäischen Kommission, oder, wenn ein solcher Beschluss nicht
            vorliegt, auf der Grundlage von sogenannten
            Standarddatenschutzklauseln, die ebenfalls von der Europäischen
            Kommission erlassen wurden.
          </p>
        </div>
        <h3>VII Ihre Rechte</h3>
        <div className="data-security-page-container-info-text">
          <p>Folgende Rechte stehen Ihnen uns gegenüber zu:</p>

          <h4>1 Recht auf Auskunft</h4>

          <p>
            Sie haben ein Recht auf Auskunft darüber, ob und welche
            personenbezogenen Daten von Ihnen durch uns verarbeitet werden. In
            diesem Fall informieren wir Sie zusätzlich über
          </p>

          <ol>
            <li>den Verarbeitungszweck</li>
            <li>die Datenkategorien</li>
            <li>die Empfänger Ihrer personenbezogenen Daten</li>
            <li>
              die geplante Speicherdauer bzw. die Kriterien für die geplante
              Speicherdauer
            </li>
            <li>Ihre weiteren Rechte</li>
            <li>
              sofern nicht Sie uns Ihre personenbezogenen Daten mitgeteilt
              haben: Alle verfügbaren Informationen über deren Herkunft
            </li>
            <li>
              sofern vorhanden: das Bestehen einer automatisierten
              Entscheidungsfindung sowie Informationen über die involvierte
              Logik, die Tragweite und die angestrebten Auswirkungen der
              Verarbeitung.
            </li>
          </ol>

          <h4>2 Recht auf Berichtigung</h4>

          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung,
            sofern Ihre von uns verarbeiteten personenbezogenen Daten unrichtig
            oder unvollständig sind.
          </p>

          <h4>3 Recht auf Einschränkung der Verarbeitung</h4>

          <p>Sie haben ein Recht auf Einschränkung der Verarbeitung, sofern</p>

          <ol>
            <li>
              wir die Richtigkeit Ihrer von uns verarbeiteten personenbezogenen
              Daten überprüfen
            </li>
            <li>
              die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist
            </li>
            <li>
              Sie Ihre von uns verarbeiteten personenbezogenen Daten nach
              Zweckwegfall zur Rechtsverfolgung benötigen
            </li>
            <li>
              Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
              Daten eingelegt haben und wir diesen Widerspruch prüfen.
            </li>
          </ol>

          <h4>4 Recht auf Löschung</h4>

          <p>Sie haben ein Recht auf Löschung, sofern</p>

          <ol>
            <li>
              wir Ihre personenbezogenen Daten für ihren ursprünglichen
              Verwendungszweck nicht mehr benötigen
            </li>
            <li>
              Sie Ihre Einwilligung widerrufen und es keine weitere
              Rechtsgrundlage für die Verarbeitung ihrer personenbezogenen Daten
              gibt
            </li>
            <li>
              Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
              Daten einlegen und - sofern es sich nicht um Direktmarketing
              handelt - keine vorrangigen Gründe für die Weiterverarbeitung
              vorliegen
            </li>
            <li>
              die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist
            </li>
            <li>
              die Löschung Ihrer personenbezogenen Daten gesetzlich gefordert
              ist
            </li>
            <li>
              Ihre personenbezogenen Daten als Minderjähriger für Dienste der
              Informationsgesellschaft erhoben wurden.
            </li>
          </ol>

          <h4>5 Recht auf Unterrichtung</h4>

          <p>
            Sofern Sie Ihr Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung geltend gemacht haben, werden wir allen Empfängern
            Ihrer personenbezogenen Daten, diese Berichtigung, Löschung der
            Daten oder Einschränkung der Verarbeitung mitteilen.
          </p>

          <h4>6 Recht auf Datenübertragbarkeit</h4>

          <p>
            Sie haben ein Recht Ihre von uns auf Grundlage einer Einwilligung
            oder zur Vertragsdurchführung verarbeiteten personenbezogenen Daten
            in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten und an einen anderen Verantwortlichen zu übermitteln.
            Sofern dies technisch machbar ist, haben Sie das Recht, dass wir
            diese Daten direkt an einen anderen Verantwortlichen übermitteln.
          </p>

          <h4>7 Widerspruchsrecht</h4>

          <p>
            Sie haben im Fall von besonderen Gründen ein Recht auf Widerspruch
            gegen die Verarbeitung Ihrer personenbezogenen Daten. In diesem Fall
            verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei
            denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen.
          </p>

          <p>
            Im Fall einer Verarbeitung Ihrer personenbezogenen Daten zum Zwecke
            des Direktmarketings haben Sie jederzeit ein Recht auf Widerspruch.
          </p>

          <h4>8 Recht auf Widerruf</h4>

          <p>
            Sie haben das Recht, eine uns gegenüber erklärte Einwilligung
            jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird
            die Rechtmäßigkeit von vorangegangenen Verarbeitungen auf Grundlage
            der Einwilligung nicht berührt.
          </p>

          <h4>9 Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>

          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            der zuständigen Aufsichtsbehörde zu, wenn Sie der Ansicht sind, dass
            die Verarbeitung Ihrer personenbezogenen Daten durch uns gegen die
            EU-DSGVO verstößt.
          </p>

          <p>Zuständige Aufsichtsbehörde für uns ist:</p>

          <p>
            Bayerische Landesamt für Datenschutzaufsicht
            <br />
            Promenade 18
            <br />
            91522 Ansbach
          </p>

          <p>
            Postanschrift: Postfach 1349, 91504 Ansbach
            <br />
            Tel.: +49.981.53-1300
            <br />
            E-Mail: poststelle@lda-bayern.de
          </p>
        </div>
        <h3>
          B Informationspflichten nach Art. 12 ff. EU-DSGVO für unsere Plattform
        </h3>
        <div className="data-security-page-container-info-text"></div>
        <h3>I Name und Kontaktdaten des Verantwortlichen</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Wir (die DOMUS Software AG) sind Betreiberin der Plattform. Auf
            dieser stellen wir Funktionalitäten (sogenannte „DOMUS-Dienste“) für
            Sie als Plattform-Nutzer bereit. Sie sind Plattform-Nutzer, wenn Sie
            nicht Immobilienverwalter sind.
          </p>

          <h4>
            1 Verantwortlicher für die Datenverarbeitungen auf der
            Plattform-Webseite
          </h4>

          <p>
            Ihr Ansprechpartner als Verantwortlicher im Sinne der Europäischen
            Datenschutz-Grundverordnung („EU-DSGVO“) und anderer nationaler
            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
            datenschutzrechtlicher Bestimmungen für die Datenverarbeitung durch
            Nutzung der Plattform-Webseite ist:
          </p>
          <p>
            Ihr Ansprechpartner als Verantwortlicher im Sinne der Europäischen
            Datenschutz-Grundverordnung (\„EU-DSGVO\“) und anderer nationaler
            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
            datenschutzrechtlicher Bestimmungen für die Datenverarbeitung durch
            Nutzung der Plattform-Webseite ist:
          </p>

          <p>
            DOMUS Software AG
            <br />
            Otto-Hahn-Straße 4<br />
            85521 Riemerling (Hohenbrunn)
            <br />
            <br />
            Tel.: +49 (0)89 66086-0
            <br />
            E-Mail: homecase@domus-software.de
            <br />
            <br />
            (im Folgenden als \„wir\“, \„uns\“ oder \„unser\“ bezeichnet).
          </p>

          <h4>
            2 Verantwortlicher für die Datenverarbeitung durch Nutzung der
            DOMUS-Dienste
          </h4>

          <p>
            Ihr Ansprechpartner als Verantwortlicher im Sinne der Europäischen
            Datenschutz-Grundverordnung (\„EU-DSGVO\“) und anderer nationaler
            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
            datenschutzrechtlicher Bestimmungen für die durch die Nutzung der
            DOMUS-Dienste verarbeiteten personenbezogenen Daten ist:
          </p>

          <p>Der jeweilige Immobilienverwalter.</p>

          <p>
            (im Folgenden als \„Kunde\“, oder \„Immobilienverwalter\“
            bezeichnet).
          </p>

          <p>
            Diesen finden Sie insbesondere unter \„Ihre Verwaltung\“ in der
            oberen rechten Ecke des Schwarzen Bretts des betroffenen
            Immobilienobjekts.
          </p>
        </div>
        <h3>II Kontaktdaten des Datenschutzbeauftragten</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Der Schutz Ihrer persönlichen Daten hat für uns einen hohen
            Stellenwert. Um dieser Bedeutung Ausdruck zu verleihen, haben wir
            ein auf Datenschutz und Datensicherheit spezialisiertes
            Beratungsunternehmen mit der Übernahme dieser zentralen Themen
            beauftragt. Auch unser Datenschutzbeauftragter kommt aus diesem sehr
            erfahrenen Expertenkreis.
          </p>

          <p>Wir werden beraten durch:</p>

          <p>
            MAGELLAN Compliance GmbH, Raiffeisenallee 9, 82041 Oberhaching /
            www.magellan-datenschutz.de
          </p>

          <p>
            Bitte wenden Sie sich bei Fragen bezüglich der Datenverarbeitung
            durch uns rund um das Thema Datenschutz und Datensicherheit direkt
            an unseren Datenschutzbeauftragten. E-Mail:
            datenschutz_domus@magellan-legal.de / Tel.: +49 (0)89 66086-0
          </p>

          <p>
            Wenden Sie sich bezüglich der Verarbeitung Ihrer Daten durch unsere
            Kunden im Rahmen der DOMUS-Dienste, bitte an Ihren
            Immobilienverwalter oder den Datenschutzbeauftragten Ihres
            Immobilienverwalters.
          </p>
        </div>
        <h3>III Allgemeines zur Datenverarbeitung</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Umfang</h4>

          <p>
            Wir verarbeiten Ihre personenbezogenen Daten grundsätzlich nur,
            soweit dies zur funktionsfähigen Bereitstellung unserer Webseite
            sowie unserer Inhalte und Leistungen erforderlich ist.
          </p>

          <h4>2 Rechtsgrundlage</h4>

          <p>
            Soweit wir für die Verarbeitung Ihrer personenbezogenen Daten eine
            Einwilligung von Ihnen einholen, ist die Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 S. 1 lit. a) EU-DSGVO.
          </p>

          <p>
            Erfolgt die Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung
            eines Vertrages mit Ihnen oder im Rahmen der Anbahnung einer
            Vertragsbeziehung, ist die Rechtsgrundlage für die Verarbeitung Art.
            6 Abs. 1 S. 1 lit. b) EU-DSGVO.
          </p>

          <p>
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
            uns obliegenden rechtlichen Verpflichtung erforderlich ist, ist die
            Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. c)
            EU-DSGVO.
          </p>

          <p>
            Erfolgt die Verarbeitung Ihrer personenbezogenen Daten zur Wahrung
            der berechtigten Interessen von uns oder eines Dritten, wobei Ihre
            Interessen, Grundrechte und Grundfreiheiten das erstgenannte
            Interesse nicht überwiegen, ist die Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.
          </p>
          <h4>3 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden gelöscht, sobald der Zweck für
            die Speicherung entfällt oder, sollte Ihnen ein Widerrufsrecht
            zustehen, Sie den Widerruf der Einwilligung erklären. Eine
            Speicherung kann darüber hinaus erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen wir
            unterliegen, festgelegt wurde. In diesem Fall werden Ihre
            personenbezogenen Daten jedoch gesperrt.
          </p>

          <h4>4 Externe Verlinkungen</h4>

          <p>
            Wenn wir Links zu externen Webseiten bereitstellen, gilt diese
            Datenschutzerklärung nicht für die Verarbeitung Ihrer
            personenbezogenen Daten durch den Verantwortlichen der verlinkten
            Webseite. Wir empfehlen Ihnen daher, die Datenschutzhinweise auf der
            extern von Ihnen besuchten Webseite zu lesen. Sofern diese
            Verlinkung eine Rechtsgrundlage für die hierdurch entstehende
            Verarbeitung Ihrer personenbezogenen Daten benötigt, ist dies Ihre
            Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a) EU-DSGVO, die Sie
            durch das Klicken der Verlinkung geben.
          </p>

          <p>
            Für gewöhnlich werden durch das Klicken der Verlinkung (Hyperlink)
            folgende Ihrer personenbezogenen Daten verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Bildschirmauflösung</li>
            <li>Verwendeter Browser</li>
            <li>Bandbreite</li>
            <li>Spracheinstellungen</li>
          </ul>
        </div>
        <h3>IV Datenverarbeitungen auf der Plattform-Webseite</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Webseitenfunktionen</h4>
        </div>
        <h3>a Bereitstellung der Webseite und Erstellung von Logfiles</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen der Bereitstellung unserer Webseite verarbeiten wir Ihre
            personenbezogenen Daten, um eine fehlerfreie Auslieferung unserer
            Webseite an Ihren PC oder Ihr Mobile Device zu ermöglichen. Hierbei
            müssen teilweise Ihre personenbezogenen Daten für die Dauer einer
            Sitzung gespeichert werden.
          </p>

          <p>
            Wir speichern Ihre personenbezogenen Daten zudem vorübergehend in
            Logfiles, um die Funktionsfähigkeit unserer Webseite und die
            Sicherheit unserer IT-Systeme zu gewährleisten. Eine anderweitige
            Verarbeitung Ihrer personenbezogenen Daten in Logfiles findet nicht
            statt.
          </p>

          <p>
            Folgende Ihrer personenbezogenen Daten werden für die Bereitstellung
            der Webseite und zur Erstellung von Logfiles verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Zugriffsdatum</li>
            <li>Zugriffszeitpunkt</li>
            <li>Ggf. zuvor besuchte Webseite</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
          </ul>

          <h4>2 Rechtsgrundlage</h4>

          <p>Berechtigtes Interesse, Art. 6 Abs. 1 S. 1 lit. f) EU-DSGVO.</p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bereitstellung der
            Webseite, der Gewährleistung der Funktionsfähigkeit der Webseite und
            der Sicherheit der hierfür genutzten IT-Systeme.
          </p>

          <p>Im Zweck liegt gleichzeitig auch unser berechtigtes Interesse.</p>

          <h4>4 Speicherdauer</h4>

          <p>
            Ihre personenbezogenen Daten werden in Logfiles für die Dauer von 30
            Tagen gespeichert. Darüber hinaus werden Ihre personenbezogenen
            Daten im Rahmen der Bereitstellung der Webseite nur für die Dauer
            der Sitzung gespeichert.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten und die Speicherung
            Ihrer personenbezogenen Daten in Logfiles ist für die Bereitstellung
            der Webseite, die Gewährleistung der Funktionsfähigkeit der Webseite
            und die Gewährleistung der genutzten IT-Systeme zwingend
            erforderlich. Es besteht für Sie folglich keine
            Widerspruchsmöglichkeit.
          </p>
        </div>
        <h3>b Technisch-notwendige Cookies</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Im Rahmen von technisch-notwendigen Cookies verarbeiten wir Ihre
            personenbezogenen Daten, weil viele Funktionen und Dienste unserer
            Webseite, die Ihnen die Nutzung unserer Webseite erleichtern oder
            überhaupt erst ermöglichen, ohne Cookies (sog. „technisch-notwendige
            Cookies“) nicht ordnungsgemäß funktionieren.
          </p>

          <p>
            Mittels dieser technisch-notwendigen Cookies speichern wir teilweise
            personenbezogene Daten von Ihnen, die allerdings nur zum Einsatz
            dieser Funktionen und Dienste verwendet werden. Eine anderweitige
            Verarbeitung Ihrer personenbezogenen Daten findet nicht statt.
          </p>

          <p>
            Eine Auflistung der von uns genutzten technisch-notwendigen Cookies,
            deren Zweck, Speicherdauer sowie weitere Informationen finden Sie in
            unserem Cookie-Banner.
          </p>

          <p>
            Folgende personenbezogene Daten werden im Rahmen des Einsatzes von
            technisch-notwendigen Cookies verarbeitet:
          </p>

          <ul>
            <li>IP-Adresse</li>
            <li>Spracheinstellungen Ihres Browsers</li>
            <li>Verwendeter Browser</li>
            <li>Warenkorb-Informationen</li>
          </ul>
          <h4>2 Rechtsgrundlage</h4>

          <p>
            Berechtigtes Interesse, § 25 Abs. 2 TDDDG i.V.m. Art. 6 Abs. 1 S. 1
            lit. f) EU-DSGVO.
          </p>

          <h4>3 Zweck</h4>

          <p>
            Der Zweck der Datenverarbeitung liegt in der Bereitstellung der
            Funktionen und Dienste unserer Webseite.
          </p>

          <p>Im Zweck liegt gleichzeitig auch unser berechtigtes Interesse.</p>

          <h4>4 Speicherdauer</h4>

          <p>
            In der Regel für die Dauer der jeweiligen Sitzung, sofern sich aus
            den Detailinformationen der Auflistung der von uns genutzten
            technisch-notwendigen Cookies nichts anderes ergibt.
          </p>

          <h4>5 Widerspruchs- und Beseitigungsmöglichkeit</h4>

          <p>
            Technisch-notwendige Cookies werden auf Ihrem PC oder Mobile Device
            gespeichert und von diesem an unsere Webseite übermittelt. Daher
            haben Sie die volle Kontrolle über die Verwendung von
            technisch-notwendigen Cookies.
          </p>

          <p>
            Durch eine Änderung der Einstellungen in Ihrem Browser können Sie
            die Übertragung von Cookies deaktivieren oder einschränken. Bereits
            gespeicherte Cookies können Sie jederzeit löschen. Dies kann auch
            automatisiert erfolgen. Werden Cookies für unsere Webseite
            deaktiviert, können möglicherweise nicht mehr alle Funktionen der
            Webseite vollumfänglich genutzt werden.
          </p>
        </div>
        <h3>c Technisch nicht-notwendige Cookies</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Sofern es im Rahmen der Funktionen und Dienste unserer Webseite zum
            Einsatz von technisch nicht-notwendigen Cookies kommt, finden Sie
            eine Auflistung dieser Cookies, deren Zweck, Speicherdauer sowie
            weitere Informationen in unserem Cookie-Banner.
          </p>

          <h4>2 DOMUS-Dienste</h4>

          <p>
            Wir speichern Ihre bei Nutzung der DOMUS-Dienste verarbeiteten
            personenbezogenen Daten lediglich für die Dauer der Bereitstellung
            Ihres Plattform-Nutzerkontos. Nach Auflösung ihres Kontos löschen
            wir sämtliche mit Ihrem Plattform-Nutzerprofil verbundenen
            personenbezogenen Daten und Unterlagen.
          </p>

          <p>
            Auf eine darüberhinausgehende Speicherung durch unsere Kunden haben
            wir keinen Einfluss.
          </p>

          <p>Folgende DOMUS-Dienste werden von uns bereitgestellt:</p>
        </div>
        <h3>a Plattform-Nutzerkonto</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Ihr Plattform-Nutzerkonto enthält Ihren Vornamen und Nachnamen und
            weitere von Ihnen freiwillig angegebene Daten (z.B.: Geschlecht,
            Geburtsdatum und Profilbild).
          </p>

          <p>Diese Angaben sind für alle anderen Plattform-Nutzer sichtbar.</p>

          <p>Folgende Einstellungen können freigeschalten sein:</p>

          <ul>
            <li>
              Erhalt von Benachrichtigungen (Push und E-Mail) und deren
              Häufigkeit
            </li>
            <li>Veränderung Ihrer E-Mail-Adresse</li>
            <li>Löschung Ihres Plattform-Nutzerkontos.</li>
          </ul>

          <p>
            Werden Ihnen diese Einstellungen nicht angezeigt, müssen Sie
            Änderungen mit Ihrem Immobilienverwalter besprechen.
          </p>
        </div>
        <h3>b Nachrichten</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Unter „Nachrichten“ haben Sie die Möglichkeit andere
            Plattform-Nutzer und Ihren Immobilienverwalter zu kontaktieren.
          </p>

          <p>Dabei werden folgende personenbezogenen Daten verarbeitet:</p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Inhalt der Nachricht</li>
            <li>Ggf. hochgeladene Dokumente oder Fotos.</li>
          </ul>
        </div>
        <h3>c Schwarzes Brett</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Wir bieten für jedes Immobilienobjekt ein „schwarzes Brett“ an.
            Inhalte, die Sie hier veröffentlichen, sind für alle
            Plattform-Nutzer dieses Objekts sichtbar.
          </p>

          <p>Dabei werden folgende personenbezogenen Daten verarbeitet:</p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Inhalt der Nachricht</li>
            <li>Ggf. hochgeladene Dokumente oder Fotos.</li>
          </ul>
        </div>
        <h3>d Kontakte</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Unter „Kontakte“ können Sie sich ein Verzeichnis der
            Plattform-Nutzer eines Immobilienobjekts anzeigen lassen.
          </p>

          <p>Dabei verarbeiten wir folgende personenbezogenen Daten:</p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Ggf. Foto</li>
            <li>Ggf. Geschlecht</li>
            <li>Ggf. Biographie</li>
            <li>
              Ggf. geschäftliche Telefonnummer (Hausverwalter, Dienstleister)
            </li>
            <li>
              Ggf. geschäftliche E-Mail-Adresse (Hausverwalter, Dienstleister)
            </li>
          </ul>
        </div>
        <h3>e Dokumente</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Durch den DOMUS-Dienst „Dokumente“ haben Sie und der jeweilige
            Immobilienverwalter die Möglichkeit miteinander Dokumente
            auszutauschen.
          </p>

          <p>
            Im Rahmen dieser Funktionalität kommt es zur Verarbeitung des
            Inhalts der hochgeladenen Dokumente.
          </p>
        </div>
        <h3>f Berichte</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Der DOMUS-Dienst „Berichte“ ermöglicht Plattform-Nutzern die (WEG-)
            Eigentümer sind, die Erstellung von Berichten und Auswertungen
            (Hausabrechnung, Einzelbuchungslisten und Beschlusssammlungen, etc.)
            des jeweiligen Immobilienobjekts.
          </p>

          <p>
            Im Rahmen dieser Funktionalität werden folgende personenbezogenen
            Daten verarbeitet:
          </p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Objekt</li>
            <li>Saldovortrag</li>
            <li>Zahlungen</li>
          </ul>
        </div>
        <h3>g Belegprüfung</h3>
        <div className="data-security-page-container-info-text">
          <h4>1 Beschreibung und Umfang</h4>

          <p>
            Die „Belegprüfung“ bietet Plattform-Nutzern, die WEG-Eigentümer
            sind, die Möglichkeit Belege zu überprüfen (z.B. Jahresabrechnung
            und Rechnungsbelege).
          </p>

          <p>Dabei werden folgende personenbezogenen Daten verarbeitet:</p>

          <ul>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Inhalt der Anmerkungen bzw. Fragen.</li>
          </ul>
        </div>
        <h3>V Empfängerkategorien</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Innerhalb unseres Unternehmens erhalten diejenigen Stellen und
            Abteilungen personenbezogene Daten, die diese zur Erfüllung der
            zuvor genannten Zwecke brauchen. Daneben bedienen wir uns zum Teil
            unterschiedlicher Dienstleister und übermitteln Ihre
            personenbezogenen Daten an weitere vertrauenswürdige Empfänger.
            Diese können z.B. sein:
          </p>

          <ul>
            <li>Druckereien</li>
            <li>Lettershops</li>
            <li>Scan-Service</li>
            <li>Bankinstitute</li>
            <li>IT-Dienstleister</li>
            <li>Kooperationspartner</li>
            <li>Rechtsanwälte, Steuerberater und Gerichte</li>
          </ul>
        </div>
        <h3>VI Drittlandübermittlung</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Im Rahmen der Verarbeitung Ihrer personenbezogenen Daten kann es
            vorkommen, dass wir Ihre personenbezogenen Daten an
            vertrauenswürdige Dienstleister in Drittländern übermitteln.
            Drittländer sind Länder, die außerhalb der Europäischen Union (EU)
            oder des Europäischen Wirtschaftsraums (EWR) liegen.
          </p>

          <p>
            Dabei arbeiten wir nur mit solchen Dienstleistern zusammen, die uns
            geeignete Garantien für die Sicherheit Ihrer personenbezogenen Daten
            geben und garantieren können, dass Ihre personenbezogenen Daten nach
            den strengen europäischen Datenschutzstandards verarbeitet werden.
            Eine Kopie dieser geeigneten Garantien kann bei uns vor Ort
            eingesehen werden.
          </p>

          <p>
            Wenn wir personenbezogene Daten in Drittländer übermitteln, erfolgt
            dies auf der Grundlage eines sogenannten Angemessenheitsbeschlusses
            der Europäischen Kommission, oder, wenn ein solcher Beschluss nicht
            vorliegt, auf der Grundlage von sogenannten
            Standarddatenschutzklauseln, die ebenfalls von der Europäischen
            Kommission erlassen wurden.
          </p>
        </div>
        <h3>VII Ihre Rechte</h3>
        <div className="data-security-page-container-info-text">
          <p>Folgende Rechte stehen Ihnen uns gegenüber zu:</p>

          <h4>1 Recht auf Auskunft</h4>

          <p>
            Sie haben ein Recht auf Auskunft darüber, ob und welche
            personenbezogenen Daten von Ihnen durch uns verarbeitet werden. In
            diesem Fall informieren wir Sie zusätzlich über
          </p>

          <ol>
            <li>den Verarbeitungszweck</li>
            <li>die Datenkategorien</li>
            <li>die Empfänger Ihrer personenbezogenen Daten</li>
            <li>
              die geplante Speicherdauer bzw. die Kriterien für die geplante
              Speicherdauer
            </li>
            <li>Ihre weiteren Rechte</li>
            <li>
              sofern nicht Sie uns Ihre personenbezogenen Daten mitgeteilt
              haben: Alle verfügbaren Informationen über deren Herkunft
            </li>
            <li>
              sofern vorhanden: das Bestehen einer automatisierten
              Entscheidungsfindung sowie Informationen über die involvierte
              Logik, die Tragweite und die angestrebten Auswirkungen der
              Verarbeitung.
            </li>
          </ol>

          <h4>2 Recht auf Berichtigung</h4>

          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung,
            sofern Ihre von uns verarbeiteten personenbezogenen Daten unrichtig
            oder unvollständig sind.
          </p>

          <h4>3 Recht auf Einschränkung der Verarbeitung</h4>

          <p>Sie haben ein Recht auf Einschränkung der Verarbeitung, sofern</p>

          <ol>
            <li>
              wir die Richtigkeit Ihrer von uns verarbeiteten personenbezogenen
              Daten überprüfen
            </li>
            <li>
              die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist
            </li>
            <li>
              Sie Ihre von uns verarbeiteten personenbezogenen Daten nach
              Zweckwegfall zur Rechtsverfolgung benötigen
            </li>
            <li>
              Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
              Daten eingelegt haben und wir diesen Widerspruch prüfen.
            </li>
          </ol>

          <h4>4 Recht auf Löschung</h4>

          <p>Sie haben ein Recht auf Löschung, sofern</p>

          <ol>
            <li>
              wir Ihre personenbezogenen Daten für ihren ursprünglichen
              Verwendungszweck nicht mehr benötigen
            </li>
            <li>
              Sie Ihre Einwilligung widerrufen und es keine weitere
              Rechtsgrundlage für die Verarbeitung ihrer personenbezogenen Daten
              gibt
            </li>
            <li>
              Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
              Daten einlegen und - sofern es sich nicht um Direktmarketing
              handelt - keine vorrangigen Gründe für die Weiterverarbeitung
              vorliegen
            </li>
            <li>
              die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist
            </li>
            <li>
              die Löschung Ihrer personenbezogenen Daten gesetzlich gefordert
              ist
            </li>
            <li>
              Ihre personenbezogenen Daten als Minderjähriger für Dienste der
              Informationsgesellschaft erhoben wurden.
            </li>
          </ol>

          <h4>5 Recht auf Unterrichtung</h4>

          <p>
            Sofern Sie Ihr Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung geltend gemacht haben, werden wir allen Empfängern
            Ihrer personenbezogenen Daten, diese Berichtigung, Löschung der
            Daten oder Einschränkung der Verarbeitung mitteilen.
          </p>

          <h4>6 Recht auf Datenübertragbarkeit</h4>

          <p>
            Sie haben ein Recht Ihre von uns auf Grundlage einer Einwilligung
            oder zur Vertragsdurchführung verarbeiteten personenbezogenen Daten
            in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten und an einen anderen Verantwortlichen zu übermitteln.
            Sofern dies technisch machbar ist, haben Sie das Recht, dass wir
            diese Daten direkt an einen anderen Verantwortlichen übermitteln.
          </p>

          <h4>7 Widerspruchsrecht</h4>

          <p>
            Sie haben im Fall von besonderen Gründen ein Recht auf Widerspruch
            gegen die Verarbeitung Ihrer personenbezogenen Daten. In diesem Fall
            verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei
            denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen.
          </p>

          <p>
            Im Fall einer Verarbeitung Ihrer personenbezogenen Daten zum Zwecke
            des Direktmarketings haben Sie jederzeit ein Recht auf Widerspruch.
          </p>

          <h4>8 Recht auf Widerruf</h4>

          <p>
            Sie haben das Recht, eine uns gegenüber erklärte Einwilligung
            jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird
            die Rechtmäßigkeit von vorangegangenen Verarbeitungen auf Grundlage
            der Einwilligung nicht berührt.
          </p>

          <h4>9 Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>

          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            der zuständigen Aufsichtsbehörde zu, wenn Sie der Ansicht sind, dass
            die Verarbeitung Ihrer personenbezogenen Daten durch uns gegen die
            EU-DSGVO verstößt.
          </p>
        </div>
      </div>
    </div>
  );
}
