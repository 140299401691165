import React from "react";

import { IconProps } from "../../types";

export default function ActionView({
  width = 30,
  height = 30,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M15 8c2.545 0 4.762 1.223 6.639 3.225.64.683 1.196 1.412 1.667 2.142.285.441.482.791.588 1.004a1 1 0 0 1 0 .894 11.3 11.3 0 0 1-.588 1.005 15.173 15.173 0 0 1-1.667 2.141c-1.877 2.002-4.094 3.225-6.639 3.225s-4.762-1.223-6.639-3.225a15.173 15.173 0 0 1-1.667-2.141 11.3 11.3 0 0 1-.588-1.005 1 1 0 0 1 0-.894 11.3 11.3 0 0 1 .588-1.004 15.173 15.173 0 0 1 1.667-2.142C10.238 9.223 12.455 8 15 8zm0 2c-1.91 0-3.647.958-5.18 2.593a13.194 13.194 0 0 0-1.675 2.225 13.415 13.415 0 0 0 1.675 2.225c1.533 1.635 3.27 2.593 5.18 2.593 1.91 0 3.647-.958 5.18-2.593a13.194 13.194 0 0 0 1.675-2.225 13.415 13.415 0 0 0-1.675-2.225C18.647 10.958 16.91 10 15 10zm0 1.636A3.182 3.182 0 1 1 15 18a3.182 3.182 0 0 1 0-6.364zm0 2A1.182 1.182 0 1 0 15 16a1.182 1.182 0 0 0 0-2.364z"
      />
    </svg>
  );
}
