import React from "react";
import { IconProps } from "../../../types";

export default function ServicesInsurance({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M92.69 39.023 74.643 20.977A3.333 3.333 0 0 0 72.287 20h-38.62A6.667 6.667 0 0 0 27 26.667v66.666A6.667 6.667 0 0 0 33.667 100H87a6.667 6.667 0 0 0 6.667-6.667V41.38c0-.884-.352-1.732-.977-2.357zM87 91.667c0 .92-.746 1.666-1.667 1.666h-50c-.92 0-1.666-.746-1.666-1.666V28.333c0-.92.746-1.666 1.666-1.666h34.884c.44 0 .864.175 1.176.486l15.12 15.12c.312.313.487.736.487 1.177v48.217z" />
        <path d="M71.964 46H48.113A2.125 2.125 0 0 0 46 48.125v8.873a25.863 25.863 0 0 0 13.707 22.918.7.7 0 0 0 .664.002 25.87 25.87 0 0 0 13.705-22.92v-8.873A2.125 2.125 0 0 0 71.964 46zm-3.568 11.255-10.058 9.492c-.56.53-1.442.516-1.983-.034l-3.362-3.394a1.417 1.417 0 1 1 2.013-1.994l2.383 2.409 9.06-8.538a1.418 1.418 0 0 1 1.947 2.06z" />
      </g>
    </svg>
  );
}
