import { useDropzone } from "react-dropzone";
import React from "react";
import PencilUploader from "../PencilUploader";
import "../../../styles/components/profileSettings/Profile.scss";

export default function Uploader({ onUpload }: Props): JSX.Element {
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      onUpload(file);
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: false,
    maxSize: 524288000,
    accept: {
      "image/*": [".jpg", ".png", ".jpeg", ".bmp", ".gif", ".tiff", ".tif"],
    },
  });

  return (
    <div {...getRootProps()} className="avatar-uploader">
      <input {...getInputProps()} data-testid="file" />
      <PencilUploader />
    </div>
  );
}

type Props = {
  onUpload: (item: File) => void;
};
