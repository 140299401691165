import { useNavigate, useParams } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import {
  activitiesApi,
  useGetActivityTemplatesByFacilityObjectQuery,
  usePostActivityMutation,
} from "../../api/activitiesApi";
import { ActivityTemplate } from "../../types";
import { FormApi, SubmissionErrors } from "final-form";
import {
  mapErrorsToFields,
  mapFormValuesToActivityAndDocuments,
} from "../../lib/messages";
import { append } from "../../slices/fileUploadSlice";

export default function useCreateMessage(): UseCreateMessage {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data = [],
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetActivityTemplatesByFacilityObjectQuery({
    customerToken,
    facilityObjectId,
  });
  const [postActivity] = usePostActivityMutation();

  const templates = useMemo(() => {
    return data.slice().sort(({ description: a }, { description: b }) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }, [data]);

  const save = useCallback(
    async (values: Record<string, string>, form: FormApi) => {
      const { tenancyOrOwnershipId, ...rest } = values;
      const { activity, documents } = mapFormValuesToActivityAndDocuments(
        {
          ...rest,
          tenancyOrOwnershipId:
            tenancyOrOwnershipId === "_"
              ? undefined
              : (tenancyOrOwnershipId as string),
        },
        form,
        facilityObjectId,
        customerToken,
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data, error } = await postActivity(activity);
      if (error && error.status === 400) {
        return mapErrorsToFields(error.data);
      }
      if (data && data.id) {
        if (documents) {
          dispatch(
            append({
              referenceId: data.id,
              files: documents,
              customerToken,
              onlineDateUTC: activity.onlineDateUtc,
              endpoint: activitiesApi.endpoints.postActivityDocument,
            }),
          );
        }
        navigate(`../${data.id}`);
      }
      return;
    },
    [],
  );

  return {
    isFetching,
    isLoading,
    isError,
    error,
    templates,
    save,
  };
}

type UseCreateMessage = {
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  templates: ActivityTemplate[];
  save: (
    values: Record<string, string>,
    form: FormApi,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
};
