import React from "react";
import { IconProps } from "../../types";

export default function ArrowRight({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M16.418 9.293a1 1 0 0 0 0 1.414l3.168 3.168H9a1 1 0 0 0 0 2h10.586l-3.168 3.168a1 1 0 0 0 1.414 1.414l4.875-4.875.073-.082.007-.008-.08.09a1.008 1.008 0 0 0 .293-.688v-.037c0-.024-.002-.048-.004-.071l.004.089a1.008 1.008 0 0 0-.213-.617 1.016 1.016 0 0 0-.08-.09l-4.875-4.875a1 1 0 0 0-1.414 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}
