import React, { Fragment } from "react";
import {
  MeetingState,
  PropositionVotingState,
  UserRoles,
} from "../../../enums";
import PropositionUpdateForm from "./PropositionUpdateForm";

export default function PropositionSummary({
  addition,
  additionNumber,
  title,
  roles,
  meetingState,
  votingState,
  id,
}: Props): JSX.Element {
  const valueAdditionNumber = additionNumber ? `(${additionNumber})` : "";

  const propositionSummaryPrefixTitle =
    votingState === PropositionVotingState.Running ||
    votingState === PropositionVotingState.Closed
      ? "Beschlussantrag"
      : "Beschlussentwurf";

  return (
    <Fragment>
      <span className="proposition-summary-prefix">{`${propositionSummaryPrefixTitle} ${
        addition ? `(${addition})` : valueAdditionNumber
      }`}</span>
      <PropositionUpdateForm
        initialValues={{ title }}
        roles={roles}
        meetingState={meetingState}
        votingState={votingState}
        id={id}
      />
    </Fragment>
  );
}

type Props = {
  addition?: string;
  additionNumber: number;
  title: string;
  roles: UserRoles[];
  meetingState: MeetingState;
  votingState: PropositionVotingState;
  id: string;
};
