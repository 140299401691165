import React, {
  useEffect,
  useMemo,
  ReactElement,
  useState,
  useCallback,
} from "react";
import FetchApiError from "../../common/FetchApiError";
import ParticipantsList from "../../meetings/ParticipantsList";
import ParticipantsListSkeleton from "../../skeleton/ParticipantsListSkeleton";

import "../../../styles/components/pages/meetings/ParticipantsPage.scss";
import useFetchParticipants from "../../../hooks/meetings/useFetchParticipants";
import useMeetingState from "../../../hooks/meetings/useMeetingState";
import { getParticipantAttendanceState } from "../../../lib/meeting";
import { ParticipantWithAttendanceState } from "../../../types";
import LoadingSpinner from "../../icons/LoadingSpinner";
import { MeetingState } from "../../../enums";
import Button from "../../common/Button";
import AlertDialog from "../../common/dialog/AlertDialog";
import { useGetParticipantsWithVoteInstructionsQuery } from "../../../api/meetingApi";
import { useParams } from "react-router-dom";
import ParticipantsPageAlertDialogDescriptionInstructions from "../../meetings/participants/ParticipantsPageAlertDialogDescriptionInstructions";

export default function ParticipantsPage(): ReactElement {
  const {
    participants,
    isError,
    error,
    isSuccess,
    isFetching,
    isLoading,
    refetch,
  } = useFetchParticipants();
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const meetingState = useMeetingState();
  const participantsWithAttendanceState = useMemo(
    () =>
      participants.map(
        (participant): ParticipantWithAttendanceState => ({
          ...participant,
          attendanceState: getParticipantAttendanceState(
            meetingState,
            participant,
          ),
        }),
      ),
    [participants, meetingState],
  );

  const { data: instructions } = useGetParticipantsWithVoteInstructionsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });

  const isReadOnly =
    meetingState === MeetingState.Unknown ||
    meetingState === MeetingState.EmployeePreview ||
    meetingState === MeetingState.Closed;

  const handleSetDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleSetDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    refetch();
  }, [meetingState]);

  return isLoading ? (
    <div className="etv-participants-loading">
      <p className="etv-participants-loading-text">
        Die Teilnahmeliste wird erstellt, bitte gedulden Sie sich einen Moment.
      </p>
      <LoadingSpinner
        width={16}
        height={16}
        className="etv-participants-loading-spinner"
      />
    </div>
  ) : (
    <section className="etv-participants">
      <div className="etv-participants-headline-wrapper">
        <h2 className="etv-participants-headline">Teilnehmer</h2>
        {instructions && instructions[0] && (
          <Button label="Weisungen" onClick={handleSetDialogOpen} />
        )}
      </div>
      {isError && <FetchApiError error={error} />}
      {participants.length > 0 && (
        <ParticipantsList
          participants={participantsWithAttendanceState}
          readonly={isReadOnly}
        />
      )}
      {!isSuccess && isFetching && <ParticipantsListSkeleton />}
      {isDialogOpen && (
        <AlertDialog
          description={
            <ParticipantsPageAlertDialogDescriptionInstructions
              instructions={instructions}
            />
          }
          confirmLabel={"Schließen"}
          title="Weisungen"
          onConfirm={handleSetDialogClose}
          showCloseButton={true}
        />
      )}
    </section>
  );
}
