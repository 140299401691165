import { CustomerSettings } from "../types";
import { useGetSettingsQuery } from "../api/customerApi";
import { useParams } from "react-router-dom";

export default function useBrandingSettings(): BrandingSettings {
  const { customerToken = "" } = useParams();
  const { data } = useGetSettingsQuery(
    { customerToken },
    { skip: !customerToken },
  );

  return data;
}

type BrandingSettings = CustomerSettings | undefined;
