import { ReactElement } from "react";
import { ParticipantAttendanceState } from "../../../types";
import classNames from "classnames";

import "../../../styles/components/meetings/participants/ParticipantAttendanceStateBadge.scss";

export default function ParticipantAttendanceStateBadge({
  attendanceState,
}: Props): ReactElement {
  const { symbol, className } = attendanceState;
  const classNameForBadge = classNames({
    "participant-attendance-state-badge": true,
    [`participant-attendance-state-badge-${className}`]: true,
  });

  return (
    <span className={classNameForBadge}>
      <span>{symbol}</span>
    </span>
  );
}

type Props = {
  attendanceState: ParticipantAttendanceState;
};
