import { SortState, Document } from "../../types";
import { useMemo } from "react";

export function sortDocuments(
  documents: Document[],
  key: SortKey,
  sortDirection: "descending" | "ascending" | "none",
): Document[] {
  return sortDirection === "none"
    ? documents
    : documents.slice().sort((a, b) => {
        const x = String(a[key]).toLowerCase();
        const y = String(b[key]).toLowerCase();

        if (key === "contentSize") {
          if (sortDirection === "ascending") {
            return a.contentSize - b.contentSize;
          }

          if (sortDirection === "descending") {
            return b.contentSize - a.contentSize;
          }
        }

        if (x < y) {
          return sortDirection === "ascending" ? 1 : -1;
        }

        if (x > y) {
          return sortDirection === "ascending" ? -1 : 1;
        }
        return 0;
      });
}

export default function useSortDocuments(
  documents: Document[],
  sort: SortState,
) {
  return useMemo(() => {
    return sortDocuments(documents, sort.sortBy as SortKey, sort.sortDirection);
  }, [documents, sort]);
}

type SortKey =
  | "originalFileName"
  | "category"
  | "contentSize"
  | "createDateUTC";
