import { AuditReviewRetryUserEventModel, NotificationProps } from "../../types";
import { getPrettifyGuid } from "../../lib/notifications";
import NotificationItemDate from "./NotificationItemDate";

export default function AuditReviewRetryUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): JSX.Element {
  const { facilityObjectId, customerToken, raisedAtUtc, auditId, text } =
    userEventData as AuditReviewRetryUserEventModel;
  const prettifiedAuditId = getPrettifyGuid(auditId);

  return (
    <a
      href={`/${customerToken}/objekte/${facilityObjectId}/belegpruefungen/${prettifiedAuditId}`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong>
        <span>{text}</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </a>
  );
}
