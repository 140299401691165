import { useParams } from "react-router-dom";
import { useSynchronisation } from "../useSynchronisation";
import { useEffect } from "react";
import { SynchronisationMethod } from "../../enums";
import { Participant } from "../../types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useGetPropositionParticipantsQuery } from "../../api/propositionApi";

export default function useFetchPropositionParticipants(
  propositionId: string,
): FetchParticipants {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const {
    data = [],
    isFetching,
    isSuccess,
    isError,
    error,
    isLoading,
    refetch,
  } = useGetPropositionParticipantsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    propositionId,
    agendaItemId,
  });

  const { connection } = useSynchronisation();

  useEffect(() => {
    if (connection) {
      connection.on(SynchronisationMethod.PropositionVotingChanged, refetch);
      connection.on(SynchronisationMethod.ParticipantsChanged, refetch);
    }
    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.PropositionVotingChanged);
        connection.off(SynchronisationMethod.ParticipantsChanged);
      }
    };
  }, [connection]);

  useEffect(() => {
    refetch();
  }, []);

  return {
    participants: data,
    isFetching,
    isSuccess,
    isError,
    isLoading,
    error,
  };
}

type FetchParticipants = {
  participants: Participant[];
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
};
