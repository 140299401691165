import React, { SyntheticEvent, useCallback, useMemo } from "react";
import { FormFieldOption } from "../../types";
import { FacilityObjectQueryFilterType, FacilityObjectType } from "../../enums";
import classNames from "classnames";
import { Field, useForm } from "react-final-form";

import LoadingSpinner from "../icons/LoadingSpinner";
import SearchField from "../common/SearchField";
import SelectField from "../common/SelectField";
import useFacilityObjectsWithFilter from "../../hooks/useFacilityObjectsWithFilter";

import "../../styles/components/bulletinBoard/CreatePostSelectObjects.scss";

const selectableOptions: FormFieldOption[] = [
  { label: "Mietobjekte", value: FacilityObjectType.Tenantment },
  { label: "WEG", value: FacilityObjectType.ResidentialCommunityOfOwners },
  { label: "SEV", value: FacilityObjectType.PartOwnership },
  { label: "Sozialer Wohnungsbau", value: FacilityObjectType.SocialHousing },
  { label: "Sonstige", value: FacilityObjectType.Other },
  { label: "Gewerbeobjekte", value: FacilityObjectType.CommercialProperty },
  {
    label: "Objektflächenmodell",
    value: FacilityObjectType.ObjectSurfaceModel,
  },
  { label: "Zentralmieter", value: FacilityObjectType.CentralTenant },
];

export default function CreatePostSelectObjects({
  selectedObjectCount = 0,
}: Props): JSX.Element {
  const {
    facilityObjects,
    setSearch,
    isFetching,
    setFilter,
    filter,
    offline,
    setOffline,
    facilityObjectId,
  } = useFacilityObjectsWithFilter(
    FacilityObjectQueryFilterType.CurrentlyManaged,
  );
  const handleSearch = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  }, []);

  const handleFilter = useCallback((e: SyntheticEvent<HTMLSelectElement>) => {
    const filter = parseInt(e.currentTarget.value, 10);
    setFilter(isNaN(filter) ? undefined : filter);
  }, []);

  const form = useForm();
  const facilityObjectIds = form.getFieldState("facilityObjectIds")?.value;

  const hasSelectedAll = useMemo(() => {
    if (Array.isArray(facilityObjectIds)) {
      for (const item of facilityObjects) {
        if (item.isOnline && !facilityObjectIds.includes(item.id)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }, [facilityObjects, facilityObjectIds]);

  const selectAllLabel = useMemo(() => {
    return ` ${filter ? "Aufgelistete" : "Alle"} Objekte ${
      hasSelectedAll ? "abwählen" : "auswählen"
    }`;
  }, [filter, hasSelectedAll]);

  const handleSelectAllOfType = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      if (checked) {
        const newValues = facilityObjects
          .filter((item) => item.isOnline)
          .map((item) => item.id);
        form.change("facilityObjectIds", newValues);
      } else {
        const newValues = facilityObjectIds.filter(
          (id: number) => !facilityObjects.find((item) => item.id === id),
        );
        form.change("facilityObjectIds", newValues);
      }
    },
    [form, facilityObjects, facilityObjectIds],
  );

  return (
    <div className="bulletin-board-post-create-post-select-objects">
      <div className="bulletin-board-post-create-post-select-objects-wrapper">
        <span>
          Wählen Sie bitte die Objekte aus, wo dieser Beitrag erscheinen soll:
          {selectedObjectCount > 0 && (
            <span className="selected-objects">
              {selectedObjectCount} Objekte ausgewählt
            </span>
          )}
        </span>
        <div className="multi-checkbox-field-list-filter">
          <SearchField onChange={handleSearch} />
          <SelectField
            options={selectableOptions}
            defaultOption="Alle"
            onChange={handleFilter}
          />
        </div>
        {isFetching && <LoadingSpinner />}
        {!isFetching && facilityObjects && (
          <div className="multi-checkbox-field-list" role="list">
            <div className="multi-checkbox-field-list-header">
              <label className="multi-checkbox-field-header">
                <input
                  type="checkbox"
                  onChange={handleSelectAllOfType}
                  checked={hasSelectedAll}
                />
                {selectAllLabel}
              </label>
              <label className="multi-checkbox-field-header">
                <input
                  type="checkbox"
                  onChange={() => setOffline(!offline)}
                  checked={offline}
                />
                Offline Objekte einblenden
              </label>
            </div>
            {facilityObjects.map((facilityObject) => {
              const isCurrentObject =
                facilityObject.id === Number(facilityObjectId);
              return (
                <label
                  className="multi-checkbox-field-item"
                  key={facilityObject.id}
                  role="listitem"
                >
                  {!isCurrentObject && (
                    <Field
                      name="facilityObjectIds"
                      component="input"
                      type="checkbox"
                      value={facilityObject.id}
                      disabled={!facilityObject.isOnline}
                    />
                  )}
                  {isCurrentObject && (
                    <Field
                      name="facilityObjectIds"
                      component="input"
                      type="checkbox"
                      value={facilityObject.id}
                      checked
                      disabled={true}
                    />
                  )}
                  <span className="object-name">
                    {facilityObject.number} - {facilityObject.description}
                  </span>
                  {facilityObject.facilityObjectType ===
                    FacilityObjectType.ResidentialCommunityOfOwners && (
                    <span className="object-type">WEG</span>
                  )}
                  {facilityObject.facilityObjectType ===
                    FacilityObjectType.PartOwnership && (
                    <span className="object-type">SEV</span>
                  )}

                  <span
                    className={classNames("object-state", {
                      online: facilityObject.isOnline,
                    })}
                  >
                    {facilityObject.isOnline ? "Online" : "Offline"}
                  </span>
                </label>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

type Props = {
  selectedObjectCount?: number;
};
