import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import moment from "moment";
import {
  usePostUserPictureMutation,
  usePostUserProfileMutation,
} from "../../api/userApi";
import Button from "../common/Button";
import TextInput from "../common/form/TextInput";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import TextArea from "../common/form/TextArea";
import Select from "../common/form/Select";
import Checkmark from "../icons/Checkmark";
import DatePickerComponent from "../common/form/DatePickerComponent";

import validate, { validateRequired } from "../../lib/formValidate";
import "../../styles/components/profileSettings/ProfileForm.scss";
import CloseIcon from "../icons/CloseIcon";

export default function ProfileForm({ isAvatar }: Props): JSX.Element {
  const [isSubmitErrorsText, setSubmitErrorsText] = useState(false);
  const { user, isSuccessUsersMyProfile } = useGetUsersMyProfile();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    aboutMe,
    dayOfBirth,
    monthOfBirth,
    yearOfBirth,
    gender,
    firstName,
    lastName,
    sid,
    email,
    profileVersionStamp,
  } = user;
  const isBirthDay = dayOfBirth && monthOfBirth && yearOfBirth;

  const birthDay = isBirthDay
    ? moment({
        day: dayOfBirth,
        month: monthOfBirth - 1,
        year: yearOfBirth,
      }).toDate()
    : undefined;

  const [
    postUserPicture,
    { isSuccess: isSuccessPostUserPicture, isError: isErrorPostUserPicture },
  ] = usePostUserPictureMutation();

  const [postUserProfile, { isError, isSuccess }] =
    usePostUserProfileMutation();

  const genderOptions = useMemo(
    () => [
      { label: "Nicht angegeben", value: "Nicht angegeben" },
      {
        label: "Männlich",
        value: "Männlich",
      },
      {
        label: "Weiblich",
        value: "Weiblich",
      },
    ],
    [],
  );

  const handleSubmitProfileChanges = useCallback(
    async (values: Record<string, string>) => {
      const gender = user.gender ? user.gender : "Nicht angegeben";
      await postUserProfile({
        userSid: sid,
        userSettingsInfo: {
          aboutMe: values.aboutMe,
          dayOfBirth: values.birthDay ? moment(values.birthDay).date() : null,
          monthOfBirth: values.birthDay
            ? moment(values.birthDay).month() + 1
            : null,
          yearOfBirth: values.birthDay ? moment(values.birthDay).year() : null,
          gender: values.gender ? values.gender : gender,
          firstName: values.firstName ? values.firstName : user.firstName,
          lastName: values.lastName ? values.lastName : user.lastName,
          sid,
          email,
          profileVersionStamp,
        },
      });

      if (isAvatar) {
        await postUserPicture({
          userSid: user.sid,
          file: isAvatar,
        });
      }
    },
    [user, isAvatar, isSubmitErrorsText, isSuccess],
  );

  useEffect(() => {
    if (isAvatar && isSuccessPostUserPicture) {
      navigate("/profil");
    }
    if (isSuccess && !isAvatar) {
      navigate("/profil");
    }
  }, [isSuccess, isSuccessPostUserPicture, isAvatar]);

  useEffect(() => {
    if (isError || isErrorPostUserPicture) {
      setSubmitErrorsText(true);
    }
  }, [isError, isErrorPostUserPicture, birthDay]);

  return pathname.endsWith("/profil/bearbeiten") && isSuccessUsersMyProfile ? (
    <Form
      onSubmit={handleSubmitProfileChanges}
      initialValues={{
        aboutMe,
        gender,
        birthDay,
        firstName,
        lastName,
        sid,
        email,
        profileVersionStamp,
      }}
      noValidate
      render={({ handleSubmit, dirty }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="profile-settings-container-form"
          >
            <div className="profile-settings-container-form-fields">
              <Field
                name="firstName"
                type="text"
                component={TextInput}
                label="Vorname"
                required
                validate={validate(
                  validateRequired("Bitte geben Sie einen Vornamen an!"),
                )}
              />

              <Field
                name="lastName"
                type="text"
                component={TextInput}
                label="Nachname"
                required
                validate={validate(
                  validateRequired("Bitte geben Sie einen Nachnamen an!"),
                )}
              />
              <Field
                name="gender"
                type="select"
                options={genderOptions}
                component={Select}
                label="Geschlecht"
                className="gender-field"
              />
              <Field
                name="birthDay"
                component={DatePickerComponent}
                label="Geburtsdatum"
              />
              <Field
                name="aboutMe"
                type="text"
                component={TextArea}
                label="Über mich"
                placeholder={"Erzählen Sie etwas über sich…"}
                maxLength={2000}
                rows="5"
              />
              <span className="info-text">
                Ihr Profil wird für andere HOMECASE-Benutzer (z.B. Nachbarn,
                Hausverwaltung, Dienstleister, Hauseigentümer) angezeigt
              </span>
              {isSubmitErrorsText && (
                <div className="error-text">
                  <span>
                    Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie
                    es erneut
                  </span>
                </div>
              )}
              <div className="actions-buttons-wrapper">
                <Button
                  leadingIcon={CloseIcon}
                  label="Abbrechen"
                  className="profile-settings-container-button"
                  onClick={() => navigate("/profil")}
                />
                <Button
                  leadingIcon={Checkmark}
                  lightblue
                  label="Profil aktualisieren"
                  className="profile-settings-container-button"
                  disabled={!dirty && !isAvatar}
                />
              </div>
            </div>
          </form>
        );
      }}
    />
  ) : (
    <div></div>
  );
}

type Props = {
  isAvatar?: File;
};
