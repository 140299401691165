import React from "react";
import { IconProps } from "../../types";

export default function SearchIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M19.096 20.51a8.111 8.111 0 1 1 1.414-1.414l3.197 3.197a1 1 0 0 1-1.414 1.414l-3.197-3.197zm-.578-2.165a6.111 6.111 0 1 0-.173.173 1.01 1.01 0 0 1 .173-.173z"
        fillRule="evenodd"
      />
    </svg>
  );
}
