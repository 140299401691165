import React from "react";
import { IconProps } from "../../../types";

export default function ServicesPainter({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
        d="M32.857 64.117a.833.833 0 0 1 .476.753v17.797a5 5 0 0 0 10 0V64.87c0-.322.186-.615.477-.753a16.28 16.28 0 0 0 9.5-13.907.833.833 0 0 0-.833-.877H24.19a.835.835 0 0 0-.833.877 16.28 16.28 0 0 0 9.5 13.907zM56.667 24.333A3.333 3.333 0 0 0 53.333 21h-30A3.333 3.333 0 0 0 20 24.333l3.223 20.96a.833.833 0 0 0 .824.707H52.62c.411 0 .76-.3.823-.707l3.224-20.96zM78.333 46c-10.766 0-21.666 4-21.666 11.667v30c0 7.666 10.9 11.666 21.666 11.666 10.767 0 21.667-4 21.667-11.666v-30C100 50.007 89.1 46 78.333 46zm15 28.333a6.667 6.667 0 1 1-13.333 0v-10.86a.837.837 0 0 0-.88-.83c-.253 0-.513.024-.787.024-9.743 0-15-3.547-15-5 0-1.454 5.257-5 15-5 9.744 0 15 3.546 15 5v16.666z"
      />
    </svg>
  );
}
