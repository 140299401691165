import React, {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import Linkify from "react-linkify";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import { Link } from "react-router-dom";

export default function LinkifyContent({
  children,
  linkify,
}: Props): ReactElement {
  const [openExternLink, setOpenExternLink] = useState(false);
  const [externLink, setExternLink] = useState("");

  const linkifyComponentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ): ReactElement => {
    const url = new URL(decoratedHref);
    return url.hostname !== window.location.hostname ? (
      <a onClick={handleOnLinkClick} href={decoratedHref} key={key}>
        {decoratedText}
      </a>
    ) : (
      <Link to={decoratedHref} key={key} data-testid="local-link">
        {decoratedText}
      </Link>
    );
  };

  const handleOnLinkClick = useCallback(
    (e: SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const url = new URL(e.currentTarget.getAttribute("href") || "");

      setExternLink(url.href);
      setOpenExternLink(true);
    },
    [],
  );

  const handleOpenExternLink = useCallback(() => {
    setOpenExternLink(false);
    window.open(externLink, "_blank");
  }, [externLink]);

  const handleCancelOpenExternLink = useCallback(() => {
    setOpenExternLink(false);
  }, []);

  return (
    <Fragment>
      {linkify ? (
        <Linkify componentDecorator={linkifyComponentDecorator}>
          {children}
        </Linkify>
      ) : (
        children
      )}
      {openExternLink && (
        <ConfirmDialog
          description="Sie werden auf eine externe Seite weitergeleitet. Möchten Sie fortfahren?"
          title="Weiterleitung auf eine externe Seite"
          onConfirm={handleOpenExternLink}
          onCancel={handleCancelOpenExternLink}
        />
      )}
    </Fragment>
  );
}

type Props = {
  children: React.ReactNode;
  linkify: boolean;
};
