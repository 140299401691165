import { FieldRenderProps } from "react-final-form";
import {
  forwardRef,
  ReactElement,
  Ref,
  SyntheticEvent,
  useCallback,
} from "react";
import classNames from "classnames";

import "../../../styles/components/common/form/Textarea.scss";

export default function TextArea(
  {
    input,
    meta,
    className,
    label,
    required,
    onChange,
    ...rest
  }: FieldRenderProps<string | undefined, HTMLTextAreaElement>,
  ref: Ref<HTMLTextAreaElement>,
): ReactElement {
  const textareaClassName = classNames(className, {
    "textarea-field": true,
    "textarea-error": (meta.error || meta.submitError) && meta.touched,
    "textarea-focus": meta.active,
  });
  const hasRef = ref && Object.keys(ref).length !== 0;

  const onChangeIntern = useCallback(
    (event: SyntheticEvent<HTMLTextAreaElement>) => {
      const value = event.currentTarget?.value;
      input.onChange(value.length === 1 ? value.trim() : value);

      if (onChange) {
        onChange(event);
      }
    },
    [],
  );

  return (
    <section className={textareaClassName}>
      <label>
        {label && (
          <span className="textarea-field-label">
            {label} {required && "*"}
          </span>
        )}
        <textarea
          {...input}
          {...rest}
          onChange={onChangeIntern}
          ref={hasRef ? ref : undefined}
          required={required}
          data-testid="textarea"
        />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="textarea-field-error">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}

export const TextAreaForwardRef = forwardRef<
  HTMLTextAreaElement,
  FieldRenderProps<string, HTMLTextAreaElement>
>(TextArea);
