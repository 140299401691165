import { IconProps } from "../../types";

export default function LogoutIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M19.986 16H12.8a1 1 0 0 1 0-2h7.186l-1.493-1.493a1 1 0 0 1 1.414-1.414l3.2 3.2a1 1 0 0 1 0 1.414l-3.2 3.2a1 1 0 0 1-1.414-1.414L19.986 16zM13.6 22a1 1 0 0 1 0 2h-4A2.6 2.6 0 0 1 7 21.4V8.6A2.6 2.6 0 0 1 9.6 6h4a1 1 0 0 1 0 2h-4a.6.6 0 0 0-.6.6v12.8a.6.6 0 0 0 .6.6h4z"
      />
    </svg>
  );
}
