import React from "react";
import { IconProps } from "../../../types";

export default function Buildings({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 60 60"
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M13 .594l-.719.687-12 12-.281.313V49h50V13.594l-.281-.313-12-12L37 .594l-.719.687L25 12.562 13.719 1.283 13 .593zm0 2.844l11 11V47H2V14.437l11-11zm24 0l11 11V47H26V14.437l11-11zM4 16v8h8v-8H4zm10 0v8h8v-8h-8zm14 0v8h8v-8h-8zm10 0v8h8v-8h-8zM6 18h4v4H6v-4zm10 0h4v4h-4v-4zm14 0h4v4h-4v-4zm10 0h4v4h-4v-4zM4 26v8h8v-8H4zm10 0v8h8v-8h-8zm14 0v8h8v-8h-8zm10 0v8h8v-8h-8zM6 28h4v4H6v-4zm10 0h4v4h-4v-4zm14 0h4v4h-4v-4zm10 0h4v4h-4v-4zM4 36v8h8v-8H4zm10 0v8h8v-8h-8zm14 0v8h8v-8h-8zm10 0v8h8v-8h-8zM6 38h4v4H6v-4zm10 0h4v4h-4v-4zm14 0h4v4h-4v-4zm10 0h4v4h-4v-4 Z"
      />
    </svg>
  );
}
