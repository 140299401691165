import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { Meeting } from "../../types";
import { useGetMeetingsQuery } from "../../api/meetingApi";
import { getMeetingTitle } from "../../lib/meeting";

export default function useGetMeetingInfoForPdfDocument(): Props {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const { data: meetingInfo = [] } = useGetMeetingsQuery({
    customerToken,
    facilityObjectId,
  });

  const meeting = useMemo(() => {
    return meetingInfo.find(({ id }) => id === meetingId);
  }, [meetingInfo, meetingId]);

  const meetingTitle = meeting
    ? getMeetingTitle(meeting)
    : "Eigentümerversammlungen";

  return { meetingTitle, meeting };
}

type Props = {
  meeting: Meeting | undefined;
  meetingTitle: string;
};
