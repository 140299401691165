import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMeetingState from "./useMeetingState";
import { useGetInvitationStateQuery } from "../../api/participantsApi";
import { MeetingState, UserRoles } from "../../enums";
import useCustomerRoles from "../useCustomerRoles";
import { useEffect, useState } from "react";

export default function useNavigateTabByInvitation() {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const { pathname } = useLocation();
  const meetingState = useMeetingState(meetingId);
  const [hasNavigate, setHasNavigate] = useState(false);
  const navigate = useNavigate();
  const roles = useCustomerRoles();
  const checkInvitation =
    meetingState !== MeetingState.Closed &&
    meetingState !== MeetingState.Running &&
    !roles.includes(UserRoles.Employee);
  const { data, isSuccess } = useGetInvitationStateQuery(
    {
      meetingId,
      facilityObjectId,
      customerToken,
    },
    { skip: !checkInvitation },
  );

  useEffect(() => {
    if (
      checkInvitation &&
      isSuccess &&
      !hasNavigate &&
      !data &&
      pathname.endsWith(meetingId)
    ) {
      setHasNavigate(true);
      navigate("invitation");
    }
  }, [checkInvitation, data, isSuccess, pathname, hasNavigate]);

  return { checked: !checkInvitation || isSuccess };
}
