import MenuDots from "../icons/MenuDots";

import "../../styles/components/bulletinBoard/PostMenu.scss";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { ReactElement, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import { UserRoles } from "../../enums";

export default function PostCommentMenu({
  canDelete,
  canEdit,
  handleEdit,
  handleDelete,
  pinned,
  handlePin,
}: Props): ReactElement {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  const handleClose = useCallback(() => setOpen(false), []);
  const userRole = useCustomerRoles();
  const handleEditClick = useCallback(() => {
    handleClose();
    handleEdit && handleEdit();
  }, [handleEdit]);

  const handleDeleteClick = useCallback(() => {
    handleClose();
    handleDelete && handleDelete();
  }, [handleEdit]);

  const handlePinClicked = useCallback(() => {
    handleClose();
    handlePin();
  }, [handlePin]);
  useOnClickOutside(ref, handleClose);

  return (
    <div className="bulletin-board-post-list-item-menu" ref={ref}>
      <div
        onClick={toggleOpen}
        data-testid="bulletin-board-post-list-item-menu-action"
        role="button"
        aria-label="Optionen"
      >
        <MenuDots className="bulletin-board-post-list-item-menu-action" />
      </div>
      <ul
        className={classNames("bulletin-board-post-list-item-menu-list", {
          open,
        })}
        role="menu"
      >
        {canEdit && (
          <li
            className="bulletin-board-post-list-item-menu-list-item"
            onClick={handleEditClick}
            role="menuitem"
          >
            Kommentar bearbeiten
          </li>
        )}
        {canDelete && (
          <li
            className="bulletin-board-post-list-item-menu-list-item"
            onClick={handleDeleteClick}
            role="menuitem"
          >
            Kommentar löschen
          </li>
        )}
        {userRole.includes(UserRoles.Employee) && (
          <li
            className="bulletin-board-post-list-item-menu-list-item"
            role="menuitem"
            onClick={handlePinClicked}
          >
            {pinned ? "Fixierung aufheben" : "Kommentar fixieren"}
          </li>
        )}
      </ul>
    </div>
  );
}

type Props = {
  canDelete: boolean;
  handleDelete?: () => void;
  handleEdit?: () => void;
  canEdit: boolean;
  pinned: boolean;
  handlePin: () => void;
};
