import { useParams } from "react-router-dom";
import { usePostTermsOfUseAcceptedMutation } from "../../api/termsOfUseApi";
import { useCallback } from "react";

export default function usePostAcceptTermsOfUse() {
  const { customerToken = "" } = useParams();
  const [postTermsOfUseAccepted] = usePostTermsOfUseAcceptedMutation();

  const postAcceptTermsOfUse = useCallback(async () => {
    return await postTermsOfUseAccepted({
      customerToken,
    });
  }, []);

  return { postAcceptTermsOfUse };
}
