import React, { ReactElement, useMemo } from "react";
import "../../../styles/components/meetings/participants/InstructionItemForParticipant.scss";
import {
  AgendaItem,
  MyVotingInstructionsPropositionsInfo,
  Proposition,
} from "../../../types";
import classNames from "classnames";

export default function PropositionItemForInstructions({
  instructions,
  proposition,
  isPropositionsList,
  propositionIndex,
}: Props): ReactElement {
  const getVoteMeaning = (vote: number): string => {
    switch (vote) {
      case 1:
        return "Ja";
      case 2:
        return "Nein";
      case 3:
        return "Enthaltung";
      default:
        return "keine Weisung erteilt";
    }
  };

  const propositionInstructions = useMemo(() => {
    return instructions?.find(
      (instruction) => instruction.propositionId === proposition.id,
    );
  }, [instructions, proposition.id]);

  const instructionVote = getVoteMeaning(
    propositionInstructions ? propositionInstructions.vote : 4,
  );

  const voteClass = classNames("instruction-vote", {
    green: instructionVote === "Ja",
    red: instructionVote === "Nein",
    blue: instructionVote === "Enthaltung",
  });

  return (
    <div className="proposition-info">
      <span>
        {isPropositionsList && `Beschlussantrag ${propositionIndex + 1}:`}
      </span>
      <span className={voteClass}>{instructionVote}</span>
    </div>
  );
}

type Props = {
  instructions?: MyVotingInstructionsPropositionsInfo[];
  agendaItems?: AgendaItem[];
  proposition: Proposition;
  isPropositionsList: boolean;
  propositionIndex: number;
};
