import { Link } from "react-router-dom";
import React from "react";
import { MeetingGenericEventModel, NotificationProps } from "../../types";
import NotificationItemDate from "./NotificationItemDate";

export default function MeetingGenericEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): JSX.Element {
  const {
    facilityObjectId,
    customerToken,
    raisedAtUtc,
    foreignMeetingId,
    text,
  } = userEventData as MeetingGenericEventModel;

  return (
    <Link
      to={`/${customerToken}/objekte/${facilityObjectId}/versammlungen/${foreignMeetingId}`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong>
        <span>{text}</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </Link>
  );
}
