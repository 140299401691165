import React, { Fragment, useEffect } from "react";
import { Participant } from "../../../types";
import { getDelegationParticipant } from "../../../lib/meeting";
import useSortParticipants from "../../../hooks/meetings/useSortParticipants";
import PropositionVoteControlCenterTableRow from "./PropositionVoteControlCenterTableRow";
import { ParticipantsPurposeType, SynchronisationMethod } from "../../../enums";
import ErrorDialog from "../../common/dialog/ErrorDialog";
import UpdateDialog from "../../common/dialog/UpdateDialog";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import TableSortHeader from "../../common/TableSortHeader";
import useTableSort from "../../../hooks/common/useTableSort";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export default function PropositionVoteControlCenterTable({
  participants,
  rawParticipants,
  propositionId,
  votingDisabled,
  participantsPurpose,
  error,
  isError,
  isLoading,
  isSuccess,
  retry,
}: Props): JSX.Element {
  const { sync } = useSynchronisation();

  const { sort, handleSort } = useTableSort(
    {
      sortBy: "name",
      sortDirection: "ascending",
    },
    "participants-voting-list",
  );
  const sortedParticipants = useSortParticipants(participants, sort);

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.PropositionVotingChanged, propositionId);
    }
  }, [isSuccess]);

  return (
    <div
      role="table"
      className="agenda-item-proposition-vote-control-center-table"
    >
      <div
        className="agenda-item-proposition-vote-control-center-table-header"
        role="row"
      >
        <Fragment>
          <TableSortHeader
            handleSort={handleSort}
            sortOptions={[
              {
                sortBy:
                  participantsPurpose === ParticipantsPurposeType.ForVote
                    ? "name"
                    : null,
                label: `Eigentümer*in (${participants.length})`,
              },
              {
                sortBy:
                  participantsPurpose === ParticipantsPurposeType.ForVote
                    ? "delegationType"
                    : null,
                label: "Hat Vollmacht gegeben an",
              },
              {
                sortBy: null,
                label: "Abstimmen",
              },
            ]}
            sort={sort}
            className="sort-title"
          />
        </Fragment>
      </div>

      <div className="agenda-item-proposition-vote-control-center-table-body">
        {sortedParticipants.map((p, key) => (
          <PropositionVoteControlCenterTableRow
            key={key}
            propositionId={propositionId}
            participant={p}
            delegatedParticipant={getDelegationParticipant(
              rawParticipants,
              p.delegationType,
              p.delegate,
            )}
            votingDisabled={votingDisabled}
          />
        ))}
      </div>
      {isLoading && (
        <UpdateDialog
          description={`Stimmen werden gespeichert.`}
          title="Stimmen werden gespeichert"
        />
      )}
      {!isLoading && isError && (
        <ErrorDialog
          description={`Stimmen für die Teilnehmer konnten nicht gespeichert werden.`}
          title="Stimmen konnten nicht gespeichert werden."
          onRetry={retry}
          error={error}
        />
      )}
    </div>
  );
}

type Props = {
  participants: Participant[];
  rawParticipants: Participant[];
  propositionId: string;
  votingDisabled?: boolean;
  showActions?: boolean;
  participantsPurpose: ParticipantsPurposeType;
  isError?: boolean;
  error?: FetchBaseQueryError | SerializedError;
  isLoading?: boolean;
  isSuccess?: boolean;
  retry?: () => void;
};
