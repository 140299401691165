import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "../../../styles/components/meetings/inivitation/InvitationInstructions.scss";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import VotingInstructionsDialogDescription from "./VotingInstructionsDialogDescription";
import { useParams } from "react-router-dom";
import { useGetAgendaItemsQuery } from "../../../api/agendaItemApi";
import { useAddMyVoteInstructionsMutation } from "../../../api/meetingApi";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../store";
import AlertDialog from "../../common/dialog/AlertDialog";
import { AgendaItemType } from "../../../enums";
import ConfirmInstructionsDialog from "../ConfirmInstructionsDialog";
import { setPropositionVoteInstructions } from "../../../slices/propositionInstructionsVotingSlice";

export default function VotingInstructions({
  isDialogOpen,
  setDialogOpen,
}: Props): ReactElement {
  const [addMyVoteInstructions, { isError, error, isSuccess }] =
    useAddMyVoteInstructionsMutation();
  const [isPage, setPage] = useState(0);
  const [isDialogCancelInstructionsOpen, setDialogCancelInstructionsOpen] =
    useState(false);
  const [errorAlertDialogHidden, setErrorAlertDialogHidden] = useState(true);
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const { data = [], isLoading } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    includePropositions: true,
  });

  const itemsWithPropositions = useMemo(() => {
    return data.filter((item) => {
      const itemType =
        item.type === AgendaItemType.WithoutSpecificPurpose ||
        item.type === AgendaItemType.OrganizationalResolution;
      return (
        itemType &&
        item.propositions?.length &&
        item.propositions[0] &&
        item.propositions.some((prop) => prop.votingType === 2)
      );
    });
  }, [data]);

  const dispatch = useDispatch();
  const pages = itemsWithPropositions.length + 2;
  const title =
    isPage !== 0 && isPage <= itemsWithPropositions.length
      ? `TOP ${
          itemsWithPropositions[isPage - 1].addition
            ? itemsWithPropositions[isPage - 1].number +
              "." +
              itemsWithPropositions[isPage - 1].addition
            : itemsWithPropositions[isPage - 1].number
        }: ${itemsWithPropositions[isPage - 1].title}`
      : "Tagesordungspunkte";

  const propositionsState = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.propositionInstructionsVoting.items,
  );

  const handleConfirmDialog = async () => {
    if (isPage === itemsWithPropositions.length) {
      setPage(itemsWithPropositions.length + 1);
    } else if (isPage > itemsWithPropositions.length) {
      await addMyVoteInstructions({
        customerToken,
        meetingId,
        facilityObjectId,
        voteInstructions: propositionsState,
      });
    } else {
      setPage(isPage + 1);
    }
  };

  const handleCancelDialog = useCallback(
    (e: SyntheticEvent) => {
      if (isPage === 0 || !e.currentTarget.textContent) {
        setDialogCancelInstructionsOpen(true);
      } else {
        setPage(isPage - 1);
      }
    },
    [isPage],
  );

  const handleOnClickErrorAlertDialogHidden = useCallback(() => {
    setErrorAlertDialogHidden(true);
  }, []);

  const handleOnConfirmDialogCancelInstructionsOpen = useCallback(() => {
    dispatch(setPropositionVoteInstructions([]));
    setDialogOpen(false);
    setDialogCancelInstructionsOpen(false);
  }, []);

  const handleOnCancelDialogCancelInstructionsOpen = useCallback(() => {
    setDialogCancelInstructionsOpen(false);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDialogOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error || isError) {
      setDialogOpen(true);
      setErrorAlertDialogHidden(false);
    }
  }, [isError]);

  return (
    <section className="invitation-instructions-wrapper">
      {isDialogOpen && (
        <ConfirmInstructionsDialog
          description={
            <VotingInstructionsDialogDescription
              agendaItems={data}
              isPage={isPage}
              isLoading={isLoading}
              setPage={setPage}
              itemWithPropositions={itemsWithPropositions}
            />
          }
          cancelLabel={isPage === 0 ? "Abbrechen" : "Zurück"}
          confirmLabel={
            isPage <= itemsWithPropositions.length
              ? "Fortfahren"
              : "Weisungen speichern"
          }
          title={title}
          className="dialog-delegate invitation"
          onConfirm={handleConfirmDialog}
          onCancel={handleCancelDialog}
          showCloseButton
          pagination
          pagesCount={pages}
          isPageNumber={isPage + 1}
        />
      )}

      {isError && !errorAlertDialogHidden && (
        <AlertDialog
          title={
            "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut."
          }
          description={""}
          confirmLabel={"OK"}
          onConfirm={handleOnClickErrorAlertDialogHidden}
        />
      )}
      {isDialogCancelInstructionsOpen && (
        <ConfirmDialog
          description=""
          cancelLabel="Abbrechen"
          confirmLabel="Ja"
          title="Sind Sie sich sicher? Alle eingetragenen Stimmen gehen verloren."
          className="dialog-close"
          onConfirm={handleOnConfirmDialogCancelInstructionsOpen}
          onCancel={handleOnCancelDialogCancelInstructionsOpen}
        />
      )}
    </section>
  );
}

type Props = {
  isDialogOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
};
