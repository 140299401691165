import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";

import { useLazyValidateMeetingQuery } from "../../api/meetingApi";
import { MeetingDataIntegrityFailureReason } from "../../types";

export default function useValidateMeeting(id?: string): UseValidateMeeting {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const meetingIdToUpdate = id ? id : meetingId;
  const [isValidating, setIsValidating] = useState(false);
  const [validateMeeting, { isError, isSuccess, currentData }] =
    useLazyValidateMeetingQuery();

  const validate = useCallback(async () => {
    setIsValidating(true);
    await validateMeeting({
      customerToken,
      facilityObjectId,
      meetingId: meetingIdToUpdate,
    });
    setIsValidating(false);
  }, [meetingIdToUpdate, customerToken, facilityObjectId]);

  return {
    validate,
    isValidating,
    isValid: isSuccess && Boolean(currentData?.succeeded),
    isError,
    failureReasons: currentData?.failureReasons,
  };
}

type UseValidateMeeting = {
  validate: () => void;
  isValidating: boolean;
  isValid: boolean;
  isError: boolean;
  failureReasons: MeetingDataIntegrityFailureReason[] | undefined;
};
