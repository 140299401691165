import React from "react";
import { IconProps } from "../../types";

export default function LoadingSpinner({
  width = 58,
  height = 58,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      role="loading-spinner-image"
      width={width}
      height={height}
      className={className}
      style={{
        margin: "auto",
        background: "rgba(0, 0, 0, 0.0)",
        display: "block",
        shapeRendering: "auto",
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect
        id="layer1"
        fill="#00bef2"
        x="15"
        y="55"
        width="30"
        height="30"
        rx="3"
        ry="3"
        style={{ animationPlayState: "running", animationDelay: "0s" }}
      >
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.8333333333333333s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.3333333333333333s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </rect>
      <rect
        id="layer2"
        fill="#bcc2c5"
        x="55"
        y="55"
        width="30"
        height="30"
        rx="3"
        ry="3"
        style={{ animationPlayState: "running", animationDelay: "0s" }}
      >
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.1666666666666667s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-0.6666666666666666s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </rect>
      <rect
        id="layer3"
        fill="#0096bf"
        x="55"
        y="15"
        width="30"
        height="30"
        rx="3"
        ry="3"
        style={{ animationPlayState: "running", animationDelay: "0s" }}
      >
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-0.5s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="0s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </rect>
    </svg>
  );
}
