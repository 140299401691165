import { Form } from "react-final-form";

import React, { useCallback, useEffect, useState } from "react";
import { Employee } from "../../types";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import EmployeesAddDialogDescription from "./EmployeesAddDialogDescription";
import {
  usePostEmployeeOnlineIdentityMutation,
  usePostEnrollInternetUserAccountMutation,
} from "../../api/customerApi";
import { useParams } from "react-router-dom";
import useGetSortEmployees from "../../hooks/employee/useGetSortEmployees";

export default function EmployeesAddDialog({ onCancel, employeesList }: Props) {
  const sortedEmployeesList = useGetSortEmployees(employeesList);
  const { customerToken = "" } = useParams();
  const [
    postEmployeeOnlineIdentity,
    {
      data,
      isSuccess: isSuccessPostEmployeeOnlineIdentity,
      isError: isErrorPostEmployeeOnlineIdentity,
    },
  ] = usePostEmployeeOnlineIdentityMutation();
  const [
    postEnrollInternetUserAccount,
    {
      isSuccess: isSuccessPostEnrollInternetUserAccount,
      isError: isErrorPostEnrollInternetUserAccount,
    },
  ] = usePostEnrollInternetUserAccountMutation();
  const [isSelectedEmployee, setSelectedEmployee] = useState<
    Employee | undefined
  >(undefined);

  const onSubmit = useCallback(
    async ({
      lastName,
      firstName,
    }: {
      lastName: string;
      firstName: string;
      email: string;
    }) => {
      const name = `${lastName} ${firstName}`;
      if (isSelectedEmployee) {
        await postEmployeeOnlineIdentity({
          customerToken,
          issuedFor: name,
          employeeId: isSelectedEmployee.id,
        });
      }
    },
    [data, isSelectedEmployee],
  );

  useEffect(() => {
    if (
      isSuccessPostEnrollInternetUserAccount &&
      isSuccessPostEmployeeOnlineIdentity
    ) {
      onCancel();
    }
  }, [
    isSuccessPostEmployeeOnlineIdentity,
    isSuccessPostEnrollInternetUserAccount,
  ]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        selectName: sortedEmployeesList ? sortedEmployeesList[0].name : "",
      }}
      render={({ handleSubmit, values }) => {
        useEffect(() => {
          if (isSuccessPostEmployeeOnlineIdentity && data) {
            postEnrollInternetUserAccount({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              oid: data.oid,
              customerToken,
            });
          }
        }, [
          isSuccessPostEmployeeOnlineIdentity,
          postEmployeeOnlineIdentity,
          postEnrollInternetUserAccount,
        ]);
        return (
          <ConfirmDialog
            description={
              <EmployeesAddDialogDescription
                employeesList={sortedEmployeesList}
                setSelectedEmployee={setSelectedEmployee}
                isErrorPostEnrollInternetUserAccount={
                  isErrorPostEnrollInternetUserAccount
                }
                isErrorPostEmployeeOnlineIdentity={
                  isErrorPostEmployeeOnlineIdentity
                }
              />
            }
            cancelLabel="Abbrechen"
            confirmLabel="Mitarbeiterkonto anlegen"
            title="Neues Mitarbeiterkonto anlegen"
            onConfirm={handleSubmit}
            onCancel={onCancel}
          />
        );
      }}
    />
  );
}

type Props = {
  onCancel: () => void;
  employeesList?: Employee[];
};
