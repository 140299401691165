import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React from "react";
import ZipFileIcon from "../icons/ZipFileIcon";
import DocFileIcon from "../icons/DocFileIcon";
import XlsFileIcon from "../icons/XlsFileIcon";
import PptFileIcon from "../icons/PptFileIcon";
import PdfFileIcon from "../icons/PdfFileIcon";
import DefaultFileIcon from "../icons/DefaultFileIcon";
import { IconProps } from "../../types";
import AudioFileIcon from "../icons/AudioFileIcon";

const fileTypeIcons = {
  "application/zip": ZipFileIcon,
  "application/msword": DocFileIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    DocFileIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    XlsFileIcon,
  "text/csv": XlsFileIcon,
  "application/msexcel": XlsFileIcon,
  "application/vnd.ms-excel": XlsFileIcon,
  "application/vnd.ms-powerpoint": PptFileIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    PptFileIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    PptFileIcon,
  "application/pdf": PdfFileIcon,
  "audio/mpeg": AudioFileIcon,
  "audio/wav": AudioFileIcon,
  "audio/ogg": AudioFileIcon,
};

export default function IconDocument({
  type,
  ...iconProps
}: Props & IconProps): JSX.Element {
  const typeA = type || "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = fileTypeIcons[typeA] || DefaultFileIcon;

  return <Icon {...iconProps} />;
}
type Props = {
  type: string;
};
