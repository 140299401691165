import "../../styles/components/meetings/MeetingWatchInformation.scss";
import useGetMyRelatedParticipants from "../../hooks/meetings/useGetMyRelatedParticipants";
import LoadingSpinner from "../icons/LoadingSpinner";
import React from "react";

export default function MeetingWatchInformation({
  meetingId,
}: Props): JSX.Element {
  const { myRelatedParticipants, isFetching } =
    useGetMyRelatedParticipants(meetingId);

  const participant = myRelatedParticipants[0];

  const participantName = participant
    ? `${participant.user.firstName} ${participant.user.lastName}`.trim()
    : "anderer Teilnehmer";

  return (
    <span className="etv-meeting-watch-information">
      Aus Ihrer Einheit nimmt&nbsp;
      {isFetching && (
        <LoadingSpinner
          width={16}
          height={16}
          className="loading-spinner-image"
        />
      )}
      {!isFetching && <strong>{participantName}</strong>}&nbsp;bereits an der
      Versammlung teil. Pro Einheit darf nur eine Person über HOMECASE an der
      Versammlung teilnehmen.
    </span>
  );
}

type Props = {
  meetingId: string;
};
