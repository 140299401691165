import { useNavigate, useParams } from "react-router-dom";
import {
  useAttendMutation,
  useGetAttendanceQuery,
  useLeaveMutation,
} from "../../api/participantsApi";
import { useCallback } from "react";
import { AttendMeeting } from "../../types";
import {
  MeetingState,
  ParticipantAttendanceType,
  ParticipantDelegationType,
  SynchronisationMethod,
  UserRoles,
} from "../../enums";
import { useSynchronisation } from "../useSynchronisation";

export default function useAttendMeeting(
  state: MeetingState,
  customerRoles: UserRoles[],
  id?: string,
): UseAttendMeeting {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const navigate = useNavigate();
  const { syncMeeting } = useSynchronisation();
  const meetingIdToAttend = id ? id : meetingId;
  const [attend, { isLoading: isAttending }] = useAttendMutation();
  const [leave, { isLoading: isLeaving }] = useLeaveMutation();
  const { data } = useGetAttendanceQuery(
    {
      meetingId: meetingIdToAttend,
      customerToken,
      facilityObjectId,
    },
    {
      skip:
        state !== MeetingState.Running ||
        customerRoles.includes(UserRoles.Employee) ||
        !meetingIdToAttend,
    },
  );

  const handleAttend = useCallback(async () => {
    const meetingAttend = data?.attend ?? false;
    const meetingWatch = data?.watch ?? false;

    if (!meetingAttend && !customerRoles.includes(UserRoles.Employee)) {
      if (meetingWatch) {
        navigate(meetingIdToAttend);
      } else {
        const result = await attend({
          customerToken,
          facilityObjectId,
          meetingId: meetingIdToAttend,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.data === null) {
          syncMeeting(
            SynchronisationMethod.ParticipantsChanged,
            meetingIdToAttend,
          );
          navigate(meetingIdToAttend);
        }
      }
    }
  }, [meetingIdToAttend, data, customerRoles]);

  const handleLeave = useCallback(
    async (
      attendance: ParticipantAttendanceType,
      delegationType: ParticipantDelegationType = ParticipantDelegationType.None,
      delegateId?: string,
    ) => {
      if (data?.attend && !customerRoles.includes(UserRoles.Employee)) {
        const result = await leave({
          customerToken,
          facilityObjectId,
          meetingId: meetingIdToAttend,
          attendance,
          delegationType,
          delegateId,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.data === null) {
          syncMeeting(
            SynchronisationMethod.ParticipantsChanged,
            meetingIdToAttend,
          );
        }
      }
    },
    [meetingIdToAttend, data, customerRoles],
  );

  return {
    handleAttend,
    handleLeave,
    isAttending,
    isLeaving,
    data,
  };
}

type UseAttendMeeting = {
  handleAttend: () => void;
  handleLeave: (
    attendance: ParticipantAttendanceType,
    delegationType?: ParticipantDelegationType,
    delegateId?: string,
  ) => void;
  isAttending: boolean;
  isLeaving: boolean;
  data: AttendMeeting | undefined;
};
