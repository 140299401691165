import { Form } from "react-final-form";
import useCreateMessage from "../../hooks/messages/useCreateMessage";
import FetchApiError from "../common/FetchApiError";

import Messages from "../icons/Messages";
import LoadingSpinner from "../icons/LoadingSpinner";

import MessageCreateForm from "./MessageCreateForm";
import useFetchFacilityObjectWithFacilityUnits from "../../hooks/messages/useFetchFacilityObjectWithFacilityUnits";

import "../../styles/components/messages/MessageCreate.scss";

export default function MessageCreate(): JSX.Element {
  const { templates, isFetching, error, isError, save } = useCreateMessage();
  const {
    facilityObject,
    facilityUnits,
    isFacilityManagementDataFetching,
    facilityManagementDataError,
    isFacilityManagementDataError,
  } = useFetchFacilityObjectWithFacilityUnits();

  const isInitialDataFetching = isFetching || isFacilityManagementDataFetching;
  const isInitialDataError = isError || isFacilityManagementDataError;
  const initialDataError = error || facilityManagementDataError;

  return (
    <div className="message-create" data-testid="message-create">
      <div className="message-create-header">
        <h1>Neue Nachricht</h1>
      </div>
      <div className="message-create-content">
        {isInitialDataError && <FetchApiError error={initialDataError} />}
        {isInitialDataFetching && (
          <div className="message-create-loading">
            <Messages
              height={200}
              width={200}
              className="message-create-loading-icon"
            />
            <LoadingSpinner />
          </div>
        )}
        {!isInitialDataFetching && !isInitialDataError && (
          <Form
            onSubmit={save}
            render={(props) => (
              <MessageCreateForm
                {...props}
                facilityObject={facilityObject}
                facilityUnits={facilityUnits}
                templates={templates}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
