import IconDocument from "../dashboard/IconDocument";
import React from "react";
import Skeleton from "react-loading-skeleton";

export default function DocumentListItemSkeleton(): JSX.Element {
  return (
    <div
      className="documents-facility-object-document"
      data-testid="document-list-item-skeleton"
    >
      <div className="documents-facility-object-document-icon">
        <IconDocument type="" width={24} height={24} />
      </div>
      <div className="documents-facility-object-document-name">
        <Skeleton height={16} />
      </div>
      <div className="documents-facility-object-document-info">
        <Skeleton height={12} width={135} />
      </div>
    </div>
  );
}
