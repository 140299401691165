import { useParams } from "react-router-dom";
import { useGetTermsOfUseQuery } from "../../api/termsOfUseApi";
import { TermsOfServices } from "../../types";

const sortItems = (termsOfServices: TermsOfServices[]) => {
  const sortedTermsOfServices = termsOfServices?.slice();

  sortedTermsOfServices?.sort((a, b) => {
    const x = a.version;
    const y = b.version;

    if (x > y) {
      return -1;
    }

    if (x < y) {
      return 1;
    }
    return 0;
  });
  return sortedTermsOfServices;
};

export default function useFetchTermsOfUse() {
  const { customerToken = "" } = useParams();
  const { data = [], isFetching } = useGetTermsOfUseQuery({
    customerToken,
  });

  const sortedTermsOfUse = sortItems(data);
  const latestId = sortedTermsOfUse[0]?.id || 0;

  return { sortedTermsOfUse, isFetching, latestId };
}
