import { useEffect, useRef, useState } from "react";

export default function useScrollToTop() {
  const rootRef = useRef<HTMLDivElement | HTMLHeadingElement | null>(null);

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (rootRef.current) {
        const scrollTop = rootRef.current.scrollTop;
        setShowScrollToTop(scrollTop > 100);
      }
    };

    const current = rootRef.current;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [rootRef.current]);

  const scrollToTop = () => {
    const top = rootRef.current?.offsetTop;

    rootRef.current?.scrollTo({
      top: top && top - 550,
    });
  };

  return { rootRef, scrollToTop, showScrollToTop };
}
