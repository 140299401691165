import React, { ReactElement, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import Button from "../common/Button";
import { usePostChangePasswordMutation } from "../../api/userApi";
import TextInput from "../common/form/TextInput";
import InfoText from "./InfoText";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";

export default function AccountPasswordForm({ isCheck }: Props): ReactElement {
  const { user } = useGetUsersMyProfile();
  const [isOnFocus, setOnFocus] = useState(false);

  const [postChangePassword, { data }] = usePostChangePasswordMutation();

  const handleSubmitChangePassword = async (values: Record<string, string>) => {
    setOnFocus(false);
    await postChangePassword({
      userSid: user.sid,
      changePassword: {
        currentPassword: values.password,
        newPassword: values.newPassword,
        confirmNewPassword: values.newPasswordConfirm,
      },
    });
  };

  return (
    <Form
      onSubmit={(values) => handleSubmitChangePassword(values)}
      render={({ handleSubmit, form, submitSucceeded }) => {
        useEffect(() => {
          form.reset();
        }, [submitSucceeded]);

        return (
          <form
            onSubmit={handleSubmit}
            className="user-settings-account-container-form"
          >
            <div className="user-settings-account-container-form-fields">
              <Field
                name="password"
                type={isCheck ? "text" : "password"}
                component={TextInput}
                rows={1}
                label="Derzeitiges Passwort:"
                onBlur={() => {
                  setOnFocus(true);
                }}
                placeholder="Aktuelles Passwort eingeben"
              />

              <Field
                name="newPassword"
                type={isCheck ? "text" : "password"}
                component={TextInput}
                rows={1}
                label="Neues Passwort:"
                onBlur={() => {
                  setOnFocus(true);
                }}
                placeholder="Neues Passwort eingeben"
              />

              <Field
                name="newPasswordConfirm"
                type={isCheck ? "text" : "password"}
                component={TextInput}
                rows={1}
                label="Neues Passwort bestätigen:"
                onBlur={() => {
                  setOnFocus(true);
                }}
                placeholder="Neues Passwort bestätigen"
              />

              {!isOnFocus && !data?.succeeded && data !== undefined && (
                <InfoText isError={true} textError={data?.errorDescription} />
              )}
              {data?.succeeded && (
                <InfoText
                  isSuccess={true}
                  textSuccess="Ihr Passwort wurde erfolgreich geändert."
                />
              )}
              <Button
                lightblue
                label="Passwort ändern"
                className="user-settings-account-container-button"
              />
            </div>
          </form>
        );
      }}
    />
  );
}

type Props = {
  isCheck?: boolean;
};
