import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ArrowLeft from "../icons/ArrowLeft";
import List from "../icons/List";
import ArrowRight from "../icons/ArrowRight";
import { AgendaItem } from "../../types";

import "../../styles/components/meetings/AgendaNavigation.scss";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../store";
import {
  isAgendaItemEditing,
  isPropositionEditing,
} from "../../slices/agendaItemEditingProcessSlice";
import { useGetPropositionsQuery } from "../../api/propositionApi";

export default function AgendaNavigation({
  items,
  selectedItemId,
  meetingId,
  navigationIsVisible,
}: Props): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const dispatch = useDispatch();
  const selectedIndex = items.findIndex(({ id }) => id === selectedItemId);
  const selectedItem: AgendaItem = items[selectedIndex];
  const { id: previousItemId } = (selectedIndex > 0 &&
    items[selectedIndex - 1]) || { id: undefined };

  const { id: nextItemId } = (selectedIndex < items.length &&
    items[selectedIndex + 1]) || { id: undefined };

  const headline = selectedItem
    ? `TOP ${
        selectedItem.addition
          ? selectedItem.number + "." + selectedItem.addition
          : selectedItem.number
      }: ${selectedItem.title}`
    : "Tagesordungspunkte";

  const navigate = useNavigate();
  const [isTopLeave, setTopLeave] = useState(false);
  const [isPropositionRunning, setPropositionRunning] = useState(false);
  const [isAriaLabel, setAriaLabel] = useState<string | null>("");
  const propositionEditing = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.agendaItemEditing.propositionEditing,
  );

  const agendaItemEditing = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.agendaItemEditing.agendaItemEditing,
  );

  const handleNavigate = useCallback(
    (e: SyntheticEvent) => {
      const { ariaLabel } = e.currentTarget;
      if (ariaLabel === "Ja") {
        dispatch(isPropositionEditing(false));
        dispatch(isAgendaItemEditing(false));
      }
      const labelText =
        ariaLabel === "Ja" || ariaLabel === "Fortfahren"
          ? isAriaLabel
          : ariaLabel;

      switch (labelText) {
        case "Vorheriger Tagesordungspunkt":
          navigate(`agenda/${previousItemId}`);
          break;
        case "Nächster Tagesordungspunkt":
          navigate(`agenda/${nextItemId}`);
          break;
        case "Übersicht Tagesordnung":
          navigate(`../${meetingId}`);
          break;
        default:
          break;
      }

      setTopLeave(false);
      setPropositionRunning(false);
    },
    [
      previousItemId,
      nextItemId,
      meetingId,
      propositionEditing,
      agendaItemEditing,
      isAriaLabel,
    ],
  );

  const { data: propositions = [] } = useGetPropositionsQuery({
    customerToken,
    facilityObjectId,
    meetingId,
    agendaItemId: selectedItemId || "",
  });

  const isPropositionsRunning = useMemo(
    () => propositions?.some((p) => p.votingState === 1) || false,
    [propositions],
  );

  const handleTopLeave = useCallback(
    (e: SyntheticEvent) => {
      setAriaLabel(e.currentTarget.ariaLabel);
      setPropositionRunning(true);
      setTopLeave(true);
    },
    [isPropositionsRunning, propositionEditing, agendaItemEditing],
  );

  return (
    <div className="etv-agenda-navigation">
      <h2 className="etv-agenda-navigation-headline">{headline}</h2>
      {navigationIsVisible && (
        <aside className="etv-agenda-navigation-actions" role="toolbar">
          {previousItemId && (
            <div
              className="etv-agenda-navigation-back"
              role="link"
              onClick={
                propositionEditing || agendaItemEditing || isPropositionsRunning
                  ? handleTopLeave
                  : handleNavigate
              }
              aria-label="Vorheriger Tagesordungspunkt"
            >
              <ArrowLeft className="etv-agenda-navigation-icon" />
            </div>
          )}
          {!previousItemId && (
            <span className="etv-agenda-navigation-back etv-agenda-navigation-disabled">
              <ArrowLeft className="etv-agenda-navigation-icon" />
            </span>
          )}
          <div
            className="etv-agenda-navigation-home"
            role="link"
            aria-label="Übersicht Tagesordnung"
            onClick={
              propositionEditing || agendaItemEditing || isPropositionsRunning
                ? handleTopLeave
                : handleNavigate
            }
          >
            <List className="etv-agenda-navigation-icon" />
          </div>
          {nextItemId && (
            <div
              className="etv-agenda-navigation-next"
              role="link"
              aria-label="Nächster Tagesordungspunkt"
              onClick={
                propositionEditing || agendaItemEditing || isPropositionsRunning
                  ? handleTopLeave
                  : handleNavigate
              }
            >
              <ArrowRight className="etv-agenda-navigation-icon" />
            </div>
          )}
          {!nextItemId && (
            <span className="etv-agenda-navigation-next etv-agenda-navigation-disabled">
              <ArrowRight className="etv-agenda-navigation-icon" />
            </span>
          )}
        </aside>
      )}
      {isTopLeave &&
        (propositionEditing || agendaItemEditing) &&
        !(isPropositionsRunning && isPropositionRunning) && (
          <ConfirmDialog
            description={""}
            title="Sie haben ungesicherte Änderungen, sind Sie sicher, dass Sie fortfahren möchten?"
            onConfirm={handleNavigate}
            confirmLabel="Ja"
            onCancel={() => {
              setTopLeave(false);
              setAriaLabel("");
            }}
            cancelLabel="Abbrechen"
          />
        )}
      {isPropositionsRunning && isPropositionRunning && (
        <ConfirmDialog
          description={""}
          title="Die Abstimmung läuft noch. Sind Sie sicher, dass Sie fortfahren möchten?"
          onConfirm={handleNavigate}
          confirmLabel="Fortfahren"
          onCancel={() => {
            setPropositionRunning(false);
            setTopLeave(false);
          }}
          cancelLabel="Abbrechen"
        />
      )}
    </div>
  );
}

type Props = {
  items: AgendaItem[];
  selectedItemId?: string;
  meetingId: string;
  navigationIsVisible: boolean;
};
