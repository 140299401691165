import React from "react";
import { IconProps } from "../../types";

export default function CrossIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
      style={{ transform: "rotate(45deg)" }}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M16.414 15l5.293 5.293a1 1 0 0 1-1.414 1.414L15 16.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L13.586 15 8.293 9.707a1 1 0 1 1 1.414-1.414L15 13.586l5.293-5.293a1 1 0 0 1 1.414 1.414L16.414 15z"
        fillRule="evenodd"
      />
    </svg>
  );
}
