import { useCallback, useState } from "react";
import { HelmetData, HelmetTags } from "react-helmet";
export default function useTracking() {
  const [lastTitle, setLastTitle] = useState<string>("");

  /*
  useEffect(() => {
    ReactGA.initialize("G-LVPV0CQQJP", {
      gaOptions: { anonymizeIp: true },
      gtagOptions: { send_page_view: false },
    });
  }, []);*/

  const trackPageViewByHelmet = useCallback<
    (
      newState: HelmetData,
      addedTags: HelmetTags,
      removedTags: HelmetTags,
    ) => void
  >(
    (newState) => {
      const title = String(newState.title);
      if (newState.title && lastTitle !== title) {
        /*
        ReactGA.send({
          hitType: "pageview",
          title,
        });
        */
        setLastTitle(String(title));
      }
    },
    [lastTitle],
  );

  const trackEvent = useCallback(
    (
      eventObject: {
        action: string;
        category: string;
        label?: string;
        value?: number;
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clickHandler?: (event?: any) => void,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (event: any) => {
        /*
        ReactGA.event({
          ...eventObject,
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
         */
        clickHandler && clickHandler(event);
      };
    },
    [],
  );

  const trackNonInteractionEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (eventObject: {
      action: string;
      category: string;
      label?: string;
      value?: number;
    }) => {
      /*
      ReactGA.event({
        ...eventObject,
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
       */
    },
    [],
  );

  return { trackPageViewByHelmet, trackEvent, trackNonInteractionEvent };
}
