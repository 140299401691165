import React from "react";
import { IconProps } from "../../../types";

export default function ServicesKey({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M64.962 49.999a19.916 19.916 0 0 0-5-13.213v-8.453a8.333 8.333 0 0 0-16.666 0v1.737a20 20 0 0 0-5 38.768v2.137l-2.846 2.843a1.667 1.667 0 0 0-.487 1.18c.001.442.176.866.487 1.18l2.846 2.846v1.954l-2.846 2.846c-.651.651-.651 1.706 0 2.357l2.846 2.843v4.307c0 .442.174.866.487 1.18l5 5a1.667 1.667 0 0 0 2.36 0l5-5c.312-.314.487-.738.486-1.18V68.838a20.063 20.063 0 0 0 13.333-18.84zm-20-10a3.333 3.333 0 1 1 0 6.667 3.333 3.333 0 0 1 0-6.667zm6.667-16.666a5 5 0 0 1 5 5v5.433a19.96 19.96 0 0 0-6.667-3.133v6.033H46.63v-8.333a5 5 0 0 1 5-5z" />
        <path d="M94.474 80.084 77.141 62.752c4.376-9.012 1.34-19.871-7.076-25.306a1.667 1.667 0 0 0-2.427 2.093c5.255 11.333 1.3 24.805-9.246 31.5a1.667 1.667 0 0 0 .897 3.069h.02a20.076 20.076 0 0 0 8.47-1.963l2.166 1.923v4.263c0 .92.746 1.667 1.667 1.667h4.286l.713.746v4.254c0 .92.747 1.666 1.667 1.666h3.73l3.056 2.88c.31.291.719.453 1.144.453h7.086c.92 0 1.667-.746 1.667-1.666v-7.07c0-.441-.175-.864-.487-1.177z" />
      </g>
    </svg>
  );
}
