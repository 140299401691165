import "../../styles/components/meetings/RecordingAttendanceInformation.scss";
import React from "react";
import InformationBox from "../common/InformationBox";

export default function RecordingAttendanceInformation(): JSX.Element {
  return (
    <div className="recording-attendance-information-wrapper">
      <div className="recording-attendance-information-info-text">
        <InformationBox text="Sobald Sie in HOMECASE auf ‘Teilnehmen’ klicken, wird Ihre Anwesenheit automatisch registriert. Weitere Anwesende trägt der Hausverwalter in die “Teilnehmerliste” ein." />
      </div>
    </div>
  );
}
