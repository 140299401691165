import React from "react";
import { Field, useFormState } from "react-final-form";

import CheckboxField from "../common/form/CheckboxField";
import "../../styles/components/fluks/FluksKeyInfoDialog.scss";
import InfoText from "../userSettings/InfoText";

export default function FluksKeyInfoDialog({
  isErrorPostApiKey,
}: Props): JSX.Element {
  const state = useFormState();

  return (
    <section className="fluks-key-dialog-wrapper">
      <span className="text-info">
        Wenn Sie einen neuen fluks API Schlüssel für Ihre Hausverwaltung
        erstellen, wird der aktuell eingesetzte Schlüssel ungültig und damit
        entfallen alle fluks HOMECASE Aktionen und Auslöser.
      </span>
      <p className="warning-info">
        Ein Schlüssel kann nur einmal, bei der Erstellung heruntergeladen
        werden. Bewahren Sie ihn gut auf.
      </p>
      <Field
        name="remark"
        component={CheckboxField}
        type="checkbox"
        label="Ich habe die Hinweise gelesen."
        id="remark"
        required
      />
      {state.submitFailed && !state.values.remark && (
        <InfoText
          isError={true}
          textError="Bestätigen Sie bitte, dass Sie die oben stehende Hinweise gelesen haben."
        />
      )}
      {isErrorPostApiKey && (
        <InfoText
          isError={true}
          textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
        />
      )}
    </section>
  );
}

type Props = {
  isErrorPostApiKey: boolean;
};
