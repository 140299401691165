import React from "react";
import { IconProps } from "../../types";

export default function Documents({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M18.682 6c.217 0 .424.086.578.24l4.5 4.5c.154.154.24.361.24.578v11.046c0 .904-.732 1.636-1.636 1.636H11.727a1.636 1.636 0 0 1-1.636-1.636V7.636c0-.904.732-1.636 1.636-1.636zM8.909 6a3.239 3.239 0 0 0-.454 1.636v14.728c0 .599.173 1.152.454 1.636H7.636A1.636 1.636 0 0 1 6 22.364V7.636C6 6.732 6.732 6 7.636 6zm9.364 12.273h-4.091a.818.818 0 0 0 0 1.636h4.09a.818.818 0 0 0 0-1.636zM19.909 15h-5.727a.818.818 0 0 0 0 1.636h5.727a.818.818 0 0 0 0-1.636z"
        fillRule="evenodd"
      />
    </svg>
  );
}
