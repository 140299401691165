import React, { useEffect } from "react";
import Button from "../../common/Button";

import {
  MeetingState,
  PropositionVoteType,
  PropositionVotingState,
  SynchronisationMethod,
  UserRoles,
} from "../../../enums";
import usePropositionVote from "../../../hooks/meetings/usePropositionVote";
import UpdateDialog from "../../common/dialog/UpdateDialog";
import ErrorDialog from "../../common/dialog/ErrorDialog";

import useAttendMeeting from "../../../hooks/meetings/useAttendMeeting";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import {
  getPropositionVoteText,
  getPropositionVotingInformationText,
} from "../../../lib/meeting";
import PropositionVotingWeightInfo from "./PropositionVotingWeightInfo";
import PropositionVoteForParticipants from "./PropositionVoteForParticipants";

import "../../../styles/components/meetings/proposition/PropositionVoting.scss";
import { useGetVotingWeightQuery } from "../../../api/propositionApi";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { useParams } from "react-router-dom";
import InformationBox from "../../common/InformationBox";

export default function PropositionVoting({
  id,
  votingState,
  meetingState,
  roles,
  meetingId,
}: Props): JSX.Element {
  const {
    setVoteIsLoading,
    getVoteIsLoading,
    data,
    setVoteSuccess,
    handleVote,
    retryVote,
    error,
  } = usePropositionVote(id, votingState);
  const { data: attendData } = useAttendMeeting(meetingState, roles, meetingId);
  const { sync } = useSynchronisation();
  const disabled =
    !attendData?.attend ||
    votingState !== PropositionVotingState.Running ||
    getVoteIsLoading;

  const customerRoles = useCustomerRoles();
  const {
    customerToken = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const {
    data: propositionVotingWight,
    isSuccess,
    isLoading,
  } = useGetVotingWeightQuery(
    {
      customerToken,
      propositionId: id,
      facilityObjectId,
      meetingId,
      agendaItemId,
    },
    {
      skip: customerRoles.includes(UserRoles.Employee),
    },
  );
  const isShareOfVotesNull =
    propositionVotingWight?.qualificationValue.value === 0;

  useEffect(() => {
    if (setVoteSuccess) {
      sync(SynchronisationMethod.PropositionVotingChanged, id);
    }
  }, [setVoteSuccess]);

  return (
    <div
      className="agenda-item-proposition-voting"
      data-testid="proposition-voting"
    >
      <PropositionVotingWeightInfo
        propositionId={id}
        isSuccess={isSuccess}
        data={propositionVotingWight}
        isLoading={isLoading}
      />
      {!isShareOfVotesNull && (
        <div className="agenda-item-proposition-voting-action">
          <Button
            label="Ja"
            green={
              !data ||
              [PropositionVoteType.None, PropositionVoteType.Yes].includes(
                data.voteType,
              )
            }
            value={PropositionVoteType.Yes}
            onClick={handleVote}
            disabled={disabled}
          />
          <Button
            label="Nein"
            red={
              !data ||
              [PropositionVoteType.None, PropositionVoteType.No].includes(
                data.voteType,
              )
            }
            value={PropositionVoteType.No}
            onClick={handleVote}
            disabled={disabled}
          />
          <Button
            label="Enthaltung"
            grey={
              !data ||
              [
                PropositionVoteType.None,
                PropositionVoteType.Abstention,
              ].includes(data.voteType)
            }
            value={PropositionVoteType.Abstention}
            onClick={handleVote}
            disabled={disabled}
          />
        </div>
      )}
      {isShareOfVotesNull && (
        <InformationBox text="Sie sind bei dieser Abstimmung nicht stimmberechtigt." />
      )}
      <PropositionVoteForParticipants
        propositionId={id}
        forOwner
        votingDisabled={disabled}
      />
      <div className="agenda-item-proposition-voting-information">
        {data?.voteType !== PropositionVoteType.None &&
          votingState === PropositionVotingState.Running &&
          !isShareOfVotesNull && (
            <span
              className={`agenda-item-proposition-voting-information-${data?.voteType}`}
            >
              {getPropositionVoteText(data)}
            </span>
          )}
        {!isShareOfVotesNull &&
          getPropositionVotingInformationText(attendData, data, votingState)}
      </div>
      {setVoteIsLoading && (
        <UpdateDialog
          description="Ihre abgegebene Stimme wird gespeichert"
          title="Stimme abgeben"
        />
      )}
      <ErrorDialog
        description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
        title="Es ist ein Fehler aufgetreten!"
        onRetry={retryVote}
        error={error}
      />
    </div>
  );
}

type Props = {
  votingState: PropositionVotingState;
  meetingState: MeetingState;
  id: string;
  meetingId: string;
  roles: UserRoles[];
};
