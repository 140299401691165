import React, { useCallback, useMemo } from "react";
import { AgendaItem } from "../../types";

import "../../styles/components/meetings/AgendaListItemInstructions.scss";
import { AgendaItemType, PropositionVotingType } from "../../enums";
import useGetInstructionsState from "../../hooks/meetings/useGetInstructionsState";
import classNames from "classnames";

export default function AgendaListItemForInstructionsDialog({
  title,
  description,
  id,
  number,
  addition,
  instructions,
  setPage,
  propositions,
  itemWithPropositions,
  type,
}: Props): JSX.Element {
  const propositionsDetailed = useMemo(() => {
    return propositions?.filter(
      (proposition) =>
        proposition.votingType === PropositionVotingType.Detailed,
    );
  }, [propositions]);

  const { propositionsInstructions } =
    useGetInstructionsState(propositionsDetailed);
  const agendaItemIndex = itemWithPropositions?.findIndex((a) => a.id === id);

  const itemType =
    type === AgendaItemType.WithoutSpecificPurpose ||
    type === AgendaItemType.OrganizationalResolution;
  const isProposition =
    propositions?.length && propositionsDetailed?.length && itemType;

  const handleOnClick = useCallback(() => {
    isProposition && agendaItemIndex !== undefined && agendaItemIndex >= 0
      ? setPage(agendaItemIndex + 1)
      : null;
  }, []);

  const instructionsTextInfoClassName = classNames({
    "agenda-list-item-content-instructions-not-granted":
      !propositionsInstructions[0],
    "agenda-list-item-content-instructions-granted":
      propositionsInstructions[0],
  });

  const agendaItemClassName = classNames("agenda-list-item", {
    "agenda-list-item-disable-link": !(isProposition && instructions),
  });

  return (
    <div
      className={agendaItemClassName}
      role="listitem"
      onClick={handleOnClick}
    >
      <h3 className="item-number">
        {addition ? number + "." + addition : number + "."}
      </h3>
      <div className="agenda-list-item-content-instructions" role="link">
        <h4 className="agenda-list-item-headline">{title}</h4>
        {description && (
          <p className="agenda-list-item-info" role="contentinfo">
            {description}
          </p>
        )}

        {isProposition ? (
          <p className={instructionsTextInfoClassName}>
            {!propositionsInstructions[0]
              ? "Keine Weisung erteilt."
              : "Weisung erteilt"}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

type Props = AgendaItem & {
  instructions: boolean;
  setPage: (page: number) => void;
  index: number;
  itemWithPropositions?: AgendaItem[];
};
