import React from "react";
import classNames from "classnames";
import { ParticipantAttendanceType } from "../../enums";

import "../../styles/components/common/ProfilePicture.scss";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";

export default function ProfilePictureManagement(props: Props): JSX.Element {
  const {
    width = 64,
    height = 64,
    alt,
    rounded,
    employeeId,
    visitingCardId,
  } = props;

  const { profilePictureManagement } = useGetProfilePicture(
    employeeId,
    visitingCardId,
  );

  const className = classNames({
    "profile-picture": true,
    "profile-picture-rounded": rounded,
  });

  return (
    <div className={className} data-testid="profile-picture">
      {profilePictureManagement && (
        <img
          src={profilePictureManagement}
          alt={alt}
          title={alt}
          style={{ width, height }}
          className="profile-picture-image"
        />
      )}
    </div>
  );
}

type Props = {
  width?: number;
  height?: number;
  rounded?: boolean;
  userSid?: string | number;
  onlineState?: ParticipantAttendanceType;
  alt: string;
  profileVersionStamp?: string;
  employeeId?: string;
  visitingCardId?: string;
};
