import React from "react";
import { IconProps } from "../../types";

export default function ApiError({
  width,
  height,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 184 181"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Tablet-›-Objekte"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Tablet/Objekte/Fehler"
          transform="translate(-591.000000, -246.000000)"
        >
          <g id="Content" transform="translate(340.000000, 0.000000)">
            <g id="Group" transform="translate(185.000000, 246.070059)">
              <g
                id="Illustration-Fehler"
                transform="translate(66.464221, 0.000000)"
              >
                <path
                  d="M9.53577921,40.365226 L9.53577921,17.039733 C9.53658187,13.5603392 12.3578383,10.7403839 15.8372321,10.7411863 C16.1345579,10.7412549 16.4315059,10.7623716 16.7258367,10.8044671 C26.980369,12.2689626 34.4897605,14.383958 39.2540113,17.1494533 C51.5404642,25.1228844 58.5851487,37.9952148 59.0661659,54.1494533 C58.693472,56.8155639 58.656863,58.653166 58.1656135,63.1738436 C58.5360197,62.9207654 58.5360197,62.9207654 58.1656135,63.1738436 C58.2194328,74.1367542 58.6946232,78.2304414 59.9667184,80.320185 C60.5920602,81.0410406 61.5676966,81.7816594 63.5689284,83.0275021 C67.6397982,84.0633677 73.5424941,84.9329527 81.5799781,84.8323802 L165.335779,84.8323802 C169.312229,84.8323802 172.535779,88.0559299 172.535779,92.03238 L172.535779,113.729941 C172.535779,117.706391 169.312229,120.929941 165.335779,120.929941 L81.5799781,120.929941 L81.5799781,120.929941 C56.8137278,120.929941 39.8217573,114.544883 30.2484864,100.173844 C26.1144951,94.2408524 23.9047425,87.3358321 23.0440665,79.417746 C22.4014239,74.3849875 22.369169,70.284141 23.0440665,62.2714046 C22.6057976,61.9009564 22.6057976,61.9009564 23.0440665,61.3689655 C22.7149823,58.0450822 22.7462643,56.4748744 23.0440665,55.0518924 C22.7116569,50.4621004 21.9815006,49.1279296 20.342409,47.8323802 C19.421314,47.3786112 17.6343608,46.9705432 14.9815493,46.6081762 C11.8610171,46.1809111 9.53532145,43.514873 9.53577921,40.365226 Z"
                  id="Path-4"
                  fill="#AEB2C5"
                  fillRule="nonzero"
                />
                <polygon
                  id="Path-2"
                  fill="#FFFFFF"
                  transform="translate(85.535779, 107.429941) scale(1, -1) translate(-85.535779, -107.429941) "
                  points="77.5357792 93.9299411 83.2500649 102.734289 82.6786364 109.777767 86.6786364 112.71255 84.9643506 120.929941 90.6786364 115.060376 88.3929221 109.777767 92.3929221 105.082115 93.5357792 93.9299411"
                />
                <rect
                  id="Rectangle-5"
                  fill="#7ED321"
                  style={{ mixBlendMode: "multiply" }}
                  transform="translate(171.002906, 101.810604) rotate(-7.000000) translate(-171.002906, -101.810604) "
                  x="162.002906"
                  y="74.8106036"
                  width="18"
                  height="54"
                  rx="6"
                />
                <rect
                  id="Rectangle-5"
                  fill="#4A90E2"
                  style={{ mixBlendMode: "multiply" }}
                  transform="translate(12.035779, 26.929941) rotate(8.000000) translate(-12.035779, -26.929941) "
                  x="3.53577921"
                  y="0.929941145"
                  width="17"
                  height="52"
                  rx="6"
                />
                <path
                  d="M79.5060573,135.309615 C79.2529412,135.056499 78.9576392,134.929941 78.5779652,134.929941 L78.5779652,134.929941 C78.2404772,134.929941 77.9029892,135.056499 77.6498732,135.309615 L70.7735549,142.607793 L70.7313689,142.649979 C66.4705827,147.248254 66.4705827,154.377688 70.7313689,158.975962 C72.7562969,161.169634 75.5827591,162.393028 78.5357792,162.393028 L78.5357792,162.393028 C81.4887993,162.393028 84.3152615,161.127448 86.3401896,158.975962 C90.6009758,154.377688 90.6009758,147.248254 86.3401896,142.649979 L79.5060573,135.309615 Z M74.781225,156.191686 C74.190621,156.57136 73.389087,156.36043 73.051599,155.769826 C73.051599,155.769826 73.051599,155.769826 73.051599,155.769826 C72.0391349,154.124572 71.4485309,152.268388 71.3641589,150.327832 C71.3219729,149.61067 71.8703909,149.020066 72.5875529,149.020066 C73.304715,148.97788 73.895319,149.526298 73.895319,150.24346 C73.937505,151.71997 74.401551,153.19648 75.1608991,154.46206 C75.5405731,155.052664 75.3718291,155.812012 74.781225,156.191686 Z"
                  id="Shape"
                  fill="#4A90E2"
                  fillRule="nonzero"
                  style={{ mixBlendMode: "multiply" }}
                />
                <path
                  d="M90.241436,133.206068 C90.0573516,133.021983 89.8425865,132.929941 89.5664599,132.929941 L89.5664599,132.929941 C89.3210141,132.929941 89.0755683,133.021983 88.8914839,133.206068 L83.8905251,138.513834 L83.8598444,138.544514 C80.7610908,141.888714 80.7610908,147.073757 83.8598444,150.417956 C85.3325194,152.013354 87.3881282,152.903096 89.5357792,152.903096 L89.5357792,152.903096 C91.6834302,152.903096 93.739039,151.982674 95.211714,150.417956 C98.3104676,147.073757 98.3104676,141.888714 95.211714,138.544514 L90.241436,133.206068 Z"
                  id="Shape"
                  fill="#4A90E2"
                  fillRule="nonzero"
                  style={{ mixBlendMode: "multiply" }}
                />
                <path
                  d="M90.3303261,162.082015 C90.2289436,161.980632 90.110664,161.929941 89.9585903,161.929941 L89.9585903,161.929941 C89.8234136,161.929941 89.688237,161.980632 89.5868545,162.082015 L86.8326302,165.00521 L86.8157331,165.022107 C85.1091279,166.863889 85.1091279,169.719496 86.8157331,171.561278 C87.626793,172.439926 88.7588975,172.929941 89.9416932,172.929941 L89.9416932,172.929941 C91.1244889,172.929941 92.2565933,172.423029 93.0676532,171.561278 C94.7742585,169.719496 94.7742585,166.863889 93.0676532,165.022107 L90.3303261,162.082015 Z"
                  id="Shape"
                  fill="#4A90E2"
                  fillRule="nonzero"
                  style={{ mixBlendMode: "multiply" }}
                />
                <path
                  d="M82.1536748,175.01289 C82.0983752,174.957591 82.0338591,174.929941 81.9509098,174.929941 L81.9509098,174.929941 C81.8771771,174.929941 81.8034443,174.957591 81.7481448,175.01289 L80.2458407,176.607361 L80.2366241,176.616577 C79.3057485,177.621185 79.3057485,179.178789 80.2366241,180.183397 C80.6790204,180.66266 81.2965319,180.929941 81.9416932,180.929941 L81.9416932,180.929941 C82.5868545,180.929941 83.204366,180.653443 83.6467623,180.183397 C84.5776379,179.178789 84.5776379,177.621185 83.6467623,176.616577 L82.1536748,175.01289 Z"
                  id="Shape"
                  fill="#4A90E2"
                  fillRule="nonzero"
                  style={{ mixBlendMode: "multiply" }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
