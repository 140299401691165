import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLastLocation } from "react-router-dom-last-location";
import PageHeader from "../common/PageHeader";
import Profile from "../profileSettings/Profile";

import "../../styles/components/pages/ProfileSettingsPage.scss";

export default function MyProfilePage(): JSX.Element {
  const [pathnameLast, setPathnameLast] = useState("/");
  const { lastLocation } = useLastLocation();

  const lastLocationName =
    pathnameLast === undefined || pathnameLast.endsWith("/");
  const to = lastLocationName ? "../" : pathnameLast;

  const backTo = {
    to: to ? to : "/",
    label: lastLocationName ? "Zurück zu HOMECASE" : "Zurück",
  };

  useEffect(() => {
    if (lastLocation) {
      setPathnameLast(lastLocation?.pathname);
    }
  }, [lastLocation?.pathname]);

  return (
    <div className="profile-settings-container">
      <Helmet>
        <title> Profil bearbeiten - Homecase</title>
      </Helmet>
      <PageHeader title="Mein Profil" backTo={backTo} />
      <div className="profile-settings-container-profile-info">
        <Profile />
      </div>
    </div>
  );
}
