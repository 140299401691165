import React, { useMemo } from "react";
import { AgendaItem } from "../../types";
import { Link, useParams } from "react-router-dom";

import "../../styles/components/meetings/AgendaListItem.scss";
import useMeetingState from "../../hooks/meetings/useMeetingState";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import {
  AgendaItemType,
  MeetingState,
  PropositionVotingState,
  UserRoles,
} from "../../enums";
import classNames from "classnames";

export default function AgendaListItem({
  title,
  description,
  id,
  number,
  propositions,
  addition,
  type,
}: AgendaItem): JSX.Element {
  const { meetingId = "" } = useParams();
  const meetingState = useMeetingState(meetingId);
  const customerRoles = useCustomerRoles();
  const isEmployee = customerRoles.includes(UserRoles.Employee);

  const agendaItemClassName =
    isEmployee ||
    meetingState === MeetingState.Running ||
    meetingState === MeetingState.Closed
      ? "agenda-list-item"
      : "agenda-list-item agenda-list-item-disable-link";

  const propositionVotingStateRunning = useMemo(() => {
    return propositions?.some(
      (proposition) =>
        proposition.votingState === PropositionVotingState.Running,
    );
  }, [propositions]);

  const propositionVotingStateNone = useMemo(() => {
    return propositions?.some(
      (proposition) => proposition.votingState === PropositionVotingState.None,
    );
  }, [propositions]);

  const propositionVotingStateClosed = useMemo(() => {
    return propositions?.every(
      (proposition) =>
        proposition.votingState === PropositionVotingState.Closed,
    );
  }, [propositions]);

  const propositionInfoText = propositionVotingStateRunning
    ? "Abstimmung läuft"
    : propositionVotingStateNone
      ? "Abstimmung ausstehend"
      : "";

  const classNameVotingInfo = classNames("voting-info", {
    pending: propositionVotingStateNone,
    progress: propositionVotingStateRunning && !propositionVotingStateNone,
    ended: propositionVotingStateClosed,
  });

  return (
    <Link className={agendaItemClassName} role="listitem" to={`agenda/${id}`}>
      <h3 className="item-number">
        {addition ? number + "." + addition : number + "."}
      </h3>
      <div className="agenda-list-item-content" role="link">
        <div className="agenda-list-item-info-wrapper">
          <h4 className="agenda-list-item-headline">{title}</h4>
          {description && (
            <p className="agenda-list-item-info" role="contentinfo">
              {description}
            </p>
          )}
        </div>
        <div className="voting-info-wrapper">
          {propositions?.length &&
          propositions.length > 0 &&
          type !== AgendaItemType.ParticipantsRegistration ? (
            <span className={classNameVotingInfo}>
              {propositionVotingStateClosed
                ? "Abstimmung beendet"
                : propositionInfoText}
            </span>
          ) : (
            <div />
          )}
        </div>
      </div>
    </Link>
  );
}
