import React, { useEffect } from "react";

import PropositionVotingControlForm from "./PropositionVotingControlForm";
import {
  MeetingState,
  PropositionVotingState,
  PropositionVotingType,
  SynchronisationMethod,
} from "../../../enums";
import usePropositionVotingControl from "../../../hooks/meetings/usePropositionVotingControl";
import UpdateDialog from "../../common/dialog/UpdateDialog";
import { useSynchronisation } from "../../../hooks/useSynchronisation";

import "../../../styles/components/meetings/proposition/PropositionVotingControl.scss";

export default function PropositionVotingControl({
  meetingState,
  votingState,
  id,
  agendaItemId,
  votingType,
}: Props): JSX.Element {
  const {
    handleVotingControl,
    isLoading,
    isVotingRunning,
    startVotingSuccess,
    stopVotingSuccess,
    stopSimpleVotingSuccess,
    isError,
  } = usePropositionVotingControl(id, votingState, votingType);
  const { sync } = useSynchronisation();
  const isDisabled =
    meetingState !== MeetingState.Running ||
    isLoading ||
    (isVotingRunning && votingState !== PropositionVotingState.Running);
  const updateDialogTitle =
    votingState === PropositionVotingState.Running
      ? "Abstimmung wird beendet"
      : "Abstimmung wird gestartet";
  const updateDialogDescription =
    votingState === PropositionVotingState.Running
      ? "Die aktuelle Abstimmung wird beendet"
      : "Die aktuelle Abstimmung wird gestartet";

  useEffect(() => {
    if (
      !isError &&
      !isLoading &&
      (startVotingSuccess || stopVotingSuccess || stopSimpleVotingSuccess)
    ) {
      sync(
        SynchronisationMethod.PropositionVotingStateChanged,
        agendaItemId,
        id,
      );
    }
  }, [
    startVotingSuccess,
    stopVotingSuccess,
    id,
    isLoading,
    isError,
    stopSimpleVotingSuccess,
  ]);
  return (
    <div
      className="agenda-item-proposition-voting-control"
      data-testid="proposition-voting-control"
    >
      <div className="agenda-item-proposition-voting-control-actions">
        <PropositionVotingControlForm
          onSubmit={handleVotingControl}
          initialValues={{
            time: 2,
            totalYesVotes: 0,
            totalNoVotes: 0,
            totalAbstentionVotes: 0,
            totalAttendedParticipants: 0,
          }}
          disabled={isDisabled}
          votingState={votingState}
          votingType={votingType}
          propositionId={id}
        />
      </div>
      {isLoading && (
        <UpdateDialog
          description={updateDialogDescription}
          title={updateDialogTitle}
        />
      )}
    </div>
  );
}

type Props = {
  meetingState: MeetingState;
  votingState: PropositionVotingState;
  id: string;
  agendaItemId: string;
  votingType?: PropositionVotingType;
};
