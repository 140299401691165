import React, { Fragment, ReactElement, useCallback, useState } from "react";
import ProfilePicture from "./ProfilePicture";
import Menu from "./Menu";
import MenuListItem from "./menu/MenuListItem";
import MenuListDiver from "./menu/MenuListDiver";

import UserIcon from "../icons/UserIcon";
import SettingsIcon from "../icons/SettingsIcon";
import LogoutIcon from "../icons/LogoutIcon";
import Process from "../icons/management/Process";

import "../../styles/components/common/UserSettingsMenu.scss";
import { useParams } from "react-router-dom";
import KeyIcon from "../icons/KeyIcon";
import useTermsOfUseAccepted from "../../hooks/termsOfUse/useTermsOfUseAccepted";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";
import ConfirmDialog from "./dialog/ConfirmDialog";
import {
  useLazyGetRequestVerificationTokenQuery,
  usePostLogOutMutation,
} from "../../api/homecaseMvcApi";
import LoadingSpinner from "../icons/LoadingSpinner";

export default function UserSettingsMenu(): ReactElement {
  const { customers } = useFetchPostLoginBaseData();
  const defaultCustomerForEmployeeUser = customers[0];
  const [isLogOut, setLogOut] = useState(false);
  const { customerToken = defaultCustomerForEmployeeUser?.token ?? "" } =
    useParams();

  const isEmployee = customers.some(
    ({ token, currentUserIsEmployee }) =>
      token === customerToken && currentUserIsEmployee,
  );

  const { accepted, isError } = useTermsOfUseAccepted();
  const { user } = useGetUsersMyProfile();
  const button = (
    <ProfilePicture
      alt={user ? user.firstName : "Profilbild"}
      userSid={user?.sid}
      width={32}
      height={32}
      rounded
    />
  );

  const [logOut, { data }] = usePostLogOutMutation();
  const [getRequestVerificationToken, { isLoading }] =
    useLazyGetRequestVerificationTokenQuery();

  const handleLogOut = useCallback(async () => {
    const token = await getRequestVerificationToken();
    const result = await logOut(token.data || "");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (result.data) {
      window.location.assign("/anmelden");
    }
    setLogOut(false);
  }, [data]);

  const header = (
    <div className="user-settings-menu-profile">
      <ProfilePicture
        alt={user ? user.firstName : "Profilbild"}
        userSid={user?.sid}
        width={88}
        height={88}
        rounded
      />
      <span>
        {user?.firstName} {user?.lastName}
      </span>
      {isLogOut && (
        <ConfirmDialog
          description={isLogOut && isLoading ? <LoadingSpinner /> : ""}
          cancelLabel="Abbrechen"
          confirmLabel="Ja"
          title="Möchten Sie sich wirklich abmelden?"
          onConfirm={handleLogOut}
          onCancel={() => setLogOut(false)}
        />
      )}
    </div>
  );

  return (
    <Menu button={button} className="user-settings-menu" header={header}>
      <MenuListItem
        leadingIcon={<UserIcon />}
        to="/profil/bearbeiten"
        shouldNavigate
      >
        Profil bearbeiten
      </MenuListItem>
      <MenuListItem
        leadingIcon={<SettingsIcon />}
        to="/einstellungen"
        shouldNavigate
      >
        Einstellungen
      </MenuListItem>
      {!isEmployee && !isError && accepted !== null && customerToken !== "" && (
        <MenuListItem
          leadingIcon={<Process />}
          to={`/${customerToken}/nutzungsbedingungen`}
          shouldNavigate
        >
          Nutzungsbedingungen
        </MenuListItem>
      )}
      {isEmployee && (
        <Fragment>
          <MenuListDiver />
          <MenuListItem
            leadingIcon={<KeyIcon />}
            to={`/management/${customerToken}/objekte`}
          >
            Verwaltungsbereich
          </MenuListItem>
        </Fragment>
      )}
      <MenuListDiver />
      <MenuListItem
        leadingIcon={<LogoutIcon />}
        to=""
        onClick={() => setLogOut(true)}
      >
        Abmelden
      </MenuListItem>
    </Menu>
  );
}
