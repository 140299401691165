import React, { Fragment } from "react";
import ProfilePicture from "../common/ProfilePicture";
import Skeleton from "react-loading-skeleton";

export default function PropositionVotingResultsForParticipantsSkeleton({
  forOwner,
}: Props): JSX.Element {
  const skeletonItems = Array(forOwner ? 1 : 10).fill(null);

  return (
    <Fragment>
      {skeletonItems.map((v, key) => (
        <div
          role="row"
          key={key}
          className="etv-participants-voting-result-list-item"
          data-testid="etv-participants-voting-result-list-item-skeleton"
        >
          <div
            className="etv-participants-voting-result-list-item-name"
            data-testid="participant-name"
            role="cell"
          >
            <ProfilePicture
              alt={"profile picture"}
              width={32}
              height={32}
              rounded
            />
            <Skeleton width={100} />
          </div>
          <div
            role="cell"
            className="etv-participants-voting-result-list-item-delegate"
          >
            <Skeleton />
          </div>
          <div
            className="etv-participants-voting-result-list-item-actions"
            data-testid="participant-actions"
            role="cell"
          >
            <Skeleton height={16} width={20} />
          </div>
          <div
            className="etv-participants-voting-result-list-item-actions"
            data-testid="participant-actions"
            role="cell"
          >
            <Skeleton height={16} width={20} />
          </div>
          <div
            className="etv-participants-voting-result-list-item-actions"
            data-testid="participant-actions"
            role="cell"
          >
            <Skeleton height={16} width={20} />
          </div>
        </div>
      ))}
    </Fragment>
  );
}

type Props = {
  forOwner?: boolean;
};
