import { IconProps } from "../../types";
import React from "react";

export default function WrongFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="wrong-file-icon"
    >
      <defs>
        <rect id="b" width="26" height="32" x="5" y="2" rx="1" />
        <filter
          id="a"
          width="111.5%"
          height="112.5%"
          x="-5.8%"
          y="-4.7%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="27"
          height="33"
          x="4.5"
          y="1.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <rect width="8" height="1" x="9" y="8" fill="#7A7F9A" rx=".5" />
        <rect
          width="6"
          height="1"
          x="9"
          y="11"
          fill="#9599AF"
          fillOpacity=".6"
          rx=".5"
        />
        <rect
          width="17"
          height="1"
          x="9"
          y="17"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="14"
          height="1"
          x="9"
          y="20"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="18"
          height="1"
          x="9"
          y="23"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="12"
          height="1"
          x="9"
          y="26"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="5"
          height="1"
          x="9"
          y="29"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
      </g>
      <g>
        <path
          className={className ? `${className}-path` : undefined}
          d="M16.414 15l5.293 5.293a1 1 0 0 1-1.414 1.414L15 16.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L13.586 15 8.293 9.707a1 1 0 1 1 1.414-1.414L15 13.586l5.293-5.293a1 1 0 0 1 1.414 1.414L16.414 15z"
          fillRule="evenodd"
          fill="#f03e3e"
          transform="translate(7,10)"
        />
      </g>
    </svg>
  );
}
