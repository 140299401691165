import React from "react";
import { IconProps } from "../../../types";

export default function ServicesAccountancy({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M97.023 99.023a3.333 3.333 0 0 0 0-4.713l-7.813-7.813a1.667 1.667 0 0 1-.257-2.03 18.267 18.267 0 0 0 2.574-9.37 18.47 18.47 0 1 0-9.06 15.856 1.667 1.667 0 0 1 2.03.257l7.813 7.813a3.333 3.333 0 0 0 4.713 0zm-23.926-12.16A11.767 11.767 0 1 1 84.86 75.097c-.007 6.494-5.27 11.757-11.763 11.766z" />
        <path d="M71.333 99a.833.833 0 0 0-.746-.827 23.333 23.333 0 1 1 16.09-42.08A.833.833 0 0 0 88 55.42V40c0-3.168-2.229-5.9-5.333-6.533a1.667 1.667 0 0 1-1.334-1.63v-5.17A6.667 6.667 0 0 0 74.667 20H33c-5.523 0-10 4.477-10 10v60c0 5.523 4.477 10 10 10h37.5c.46 0 .833-.373.833-.833V99zM29.667 30A3.333 3.333 0 0 1 33 26.667h40.833c.46 0 .834.373.834.833v5c0 .46-.373.833-.834.833H33A3.333 3.333 0 0 1 29.667 30z" />
      </g>
    </svg>
  );
}
