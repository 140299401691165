import { useCallback, useMemo } from "react";
import DownloadIcon from "../../icons/DownloadIcon";

import "../../../styles/components/common/files/ImageFile.scss";
import classNames from "classnames";
import useDownload from "../../../hooks/useDownload";

export default function ImageFile({
  file,
  width = 100,
  height = 100,
  downloadable,
  onClick,
}: Props): JSX.Element {
  const url = useMemo(() => URL.createObjectURL(file), [file]);
  const handleDownload = useDownload(url, file.name, downloadable);
  const className = classNames({
    "image-file": true,
    "image-file-downloadable": downloadable,
  });

  const onClickHandler = useCallback(() => {
    onClick && onClick(file);
  }, [onClick, file]);

  return (
    <div
      className={className}
      data-testid="image-file"
      onClick={onClickHandler}
    >
      <img
        src={url}
        alt={file.name}
        width={`${width}px`}
        height={`${height}px`}
      />
      {downloadable && (
        <span className="image-file-download-icon" onClick={handleDownload}>
          <DownloadIcon />
        </span>
      )}
    </div>
  );
}

type Props = {
  file: File;
  width?: number;
  height?: number;
  downloadable?: boolean;
  onClick?: (file: File) => void;
};
