import React from "react";
import { IconProps } from "../../types";

export default function Mail({
  width = 30,
  height = 30,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        d="M21.4 8c1.432 0 2.6 1.168 2.6 2.6v9.8c0 1.432-1.168 2.6-2.6 2.6H8.6A2.605 2.605 0 0 1 6 20.4v-9.8C6 9.168 7.168 8 8.6 8h12.8zm.6 4.52-6.427 4.5a1 1 0 0 1-1.146 0L8 12.52V20.4c0 .328.272.6.6.6h12.8c.328 0 .6-.272.6-.6v-7.88zM21.4 10H8.6a.6.6 0 0 0-.438.192L15 14.98l6.838-4.787A.6.6 0 0 0 21.4 10z"
        fillRule="evenodd"
        className={className ? `${className}-path` : undefined}
      />
    </svg>
  );
}
