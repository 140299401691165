import React from "react";
import { IconProps } from "../../types";

export default function Comment({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m8 21.146 1.848-1.849a1 1 0 0 1 .708-.293h10.666c.43 0 .778-.348.778-.777v-8.45A.778.778 0 0 0 21.222 9H8.778A.778.778 0 0 0 8 9.778v11.368zm2.97-.142-3.263 3.263c-.63.63-1.707.184-1.707-.707V9.778A2.778 2.778 0 0 1 8.778 7h12.444A2.778 2.778 0 0 1 24 9.778v8.449a2.778 2.778 0 0 1-2.778 2.777H10.97z"
        fillRule="evenodd"
      />
    </svg>
  );
}
