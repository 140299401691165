import React from "react";
import { IconProps } from "../../types";

export default function DownloadIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
      data-testid="download-icon"
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M14 20.42v-5.087a1 1 0 0 1 2 0v5.086l1.626-1.626a1 1 0 1 1 1.414 1.414l-3.333 3.333a1 1 0 0 1-1.414 0l-3.333-3.333a1 1 0 0 1 1.414-1.414L14 20.419zm5.684-8.587H20a5.167 5.167 0 0 1 2.976 9.393 1 1 0 0 1-1.15-1.636A3.167 3.167 0 0 0 20 13.833h-1.05a1 1 0 0 1-.969-.751 5.667 5.667 0 1 0-9.732 5.164A1 1 0 0 1 6.75 19.57a7.667 7.667 0 1 1 12.933-7.738z"
      />
    </svg>
  );
}
