import { BulletinBoard } from "../../types";
import {
  useDisableBulletinBoardTenantCommentingMutation,
  useEnableBulletinBoardTenantCommentingMutation,
} from "../../api/bulletinBoardApi";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export default function useToggleTenantCanComment(
  facilityObjectId: string,
  data?: BulletinBoard,
) {
  const { customerToken = "" } = useParams();
  const [disableTenantComment, { isLoading: disableIsLoading }] =
    useDisableBulletinBoardTenantCommentingMutation();
  const [enableTenantComment, { isLoading: enableIsLoading }] =
    useEnableBulletinBoardTenantCommentingMutation();
  const toggleTenantsCanComment = useCallback(() => {
    if (data) {
      if (data.tenantsCanComment) {
        return disableTenantComment({
          customerToken,
          facilityObjectId,
        });
      }
      return enableTenantComment({
        customerToken,
        facilityObjectId,
      });
    }
  }, [data, facilityObjectId]);

  return {
    toggleTenantsCanComment,
    isLoading: enableIsLoading || disableIsLoading,
  };
}
