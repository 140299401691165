import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";

import "../../../styles/components/common/form/DateInput.scss";

export default function DateInput({
  input,
  meta,
  className,
  label,
  required,
  ...rest
}: FieldRenderProps<string | undefined, HTMLInputElement>): JSX.Element {
  const dateInputClassName = classNames(className, {
    "date-input-field": true,
    "date-input-error": (meta.error || meta.submitError) && meta.touched,
  });
  return (
    <section className={dateInputClassName}>
      <label>
        {label && (
          <span className="date-input-field-label" role="label">
            {label} {required && "*"}
          </span>
        )}
        <input
          {...input}
          {...rest}
          type="date"
          required={required}
          role="date"
        />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="date-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
