import { ReactNode } from "react";
import { PREVIEW_COMPONENTS } from "../../../lib/messages";
import useLightbox from "../../../hooks/useLightbox";
import OtherFile from "../../common/files/OtherFile";

export default function MeetingAttachmentItem({
  attachment,
}: Props): ReactNode {
  const { toggleOpen } = useLightbox();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const PreviewComponent = PREVIEW_COMPONENTS[attachment.type] || OtherFile;

  return (
    <div
      className="meeting-attachment-item"
      data-testid="meeting-attachment-item"
    >
      {attachment && PreviewComponent && (
        <PreviewComponent
          file={attachment}
          width={56}
          height={56}
          onClick={toggleOpen}
        />
      )}
      {attachment && (
        <span
          className="meeting-attachment-item-name"
          data-testid="meeting-attachment-item-name"
          title={attachment.name}
        >
          {attachment.name}
        </span>
      )}
    </div>
  );
}

type Props = {
  attachment: File;
  propositionId?: string;
};
