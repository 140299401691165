import React from "react";
import { IconProps } from "../../types";

export default function Home({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m21.68 14.441-1.87 7.863c-.115.483-.612.874-1.11.874h-7.377c-.498 0-.994-.39-1.11-.874L8.32 14.442l-.844.845a.863.863 0 1 1-1.222-1.222l7.576-7.58a1.656 1.656 0 0 1 2.343 0l7.575 7.58a.864.864 0 0 1-1.222 1.222l-.845-.846zm-8.321 2.872v3.553c0 .373.303.675.677.675h1.928a.675.675 0 0 0 .677-.675v-3.553a.676.676 0 0 0-.677-.675h-1.928a.675.675 0 0 0-.677.675z"
        fillRule="evenodd"
      />
    </svg>
  );
}
