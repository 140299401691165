import React, { Fragment, useCallback, useState } from "react";
import Bell from "../icons/Bell";
import "../../styles/components/common/Notifications.scss";
import NotificationsDialog from "./NotificationsDialog";
import useFetchUnreadNotificationsCount from "../../hooks/notifications/useFetchUnreadNotificationsCount";
import { useGetLoginInfoQuery } from "../../api/homecaseMvcApi";
export default function Notifications(): JSX.Element {
  const [isNotificationsShowed, setNotificationsShowed] = useState(false);
  const unreadNotificationsCount = useFetchUnreadNotificationsCount();
  const handleShowNotifications = useCallback(() => {
    setNotificationsShowed(!isNotificationsShowed);
  }, [setNotificationsShowed, isNotificationsShowed]);
  const { data: isLoggedIn } = useGetLoginInfoQuery();

  return (
    <Fragment>
      {isLoggedIn && (
        <div
          aria-label="Benachrichtigungen"
          className="notification-button"
          onClick={handleShowNotifications}
          role="button"
        >
          <Bell className="notification-button-icon" height={30} width={30} />
          {unreadNotificationsCount > 0 && (
            <span
              className="notification-button-count"
              data-testid="notification-count"
            >
              {unreadNotificationsCount}
            </span>
          )}
        </div>
      )}
      {isNotificationsShowed && isLoggedIn && (
        <NotificationsDialog onDialogClose={handleShowNotifications} />
      )}
    </Fragment>
  );
}
