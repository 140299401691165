import React from "react";
import { IconProps } from "../../types";

export default function ArrowLeft({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M14.582 9.293a1 1 0 0 1 0 1.414l-3.168 3.168H22a1 1 0 0 1 0 2H11.414l3.168 3.168a1 1 0 0 1-1.414 1.414l-4.875-4.875-.073-.082a1.005 1.005 0 0 1-.007-.008l.08.09A1.008 1.008 0 0 1 8 14.894v-.037c0-.024.002-.048.004-.071L8 14.875a1.008 1.008 0 0 1 .213-.617c.025-.031.051-.062.08-.09l4.875-4.875a1 1 0 0 1 1.414 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}
