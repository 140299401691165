import { IconProps } from "../../types";

export default function DocFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="doc-file-icon"
    >
      <defs>
        <rect id="b" width="26" height="32" x="5" y="2" rx="1" />
        <filter
          id="a"
          width="111.5%"
          height="112.5%"
          x="-5.8%"
          y="-4.7%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="27"
          height="33"
          x="4.5"
          y="1.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <path
          fill="#2087FF"
          fillRule="nonzero"
          d="M11.526 7.231c.384-.021.769-.037 1.153-.057.269 1.334.544 2.667.835 3.995.229-1.372.482-2.74.726-4.11.405-.014.81-.035 1.212-.059-.457 1.915-.858 3.847-1.357 5.75-.338.172-.844-.008-1.244.02-.27-1.308-.583-2.61-.823-3.924-.237 1.277-.544 2.542-.815 3.811-.389-.02-.78-.043-1.17-.069-.335-1.74-.73-3.467-1.043-5.21.346-.016.694-.03 1.04-.042.208 1.26.444 2.512.627 3.774.285-1.293.576-2.586.86-3.879z"
        />
        <rect width="8" height="1" x="19" y="8" fill="#2087FF" rx=".5" />
        <rect
          width="6"
          height="1"
          x="19"
          y="11"
          fill="#9599AF"
          fillOpacity=".6"
          rx=".5"
        />
        <rect
          width="17"
          height="1"
          x="9"
          y="17"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="14"
          height="1"
          x="9"
          y="20"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="18"
          height="1"
          x="9"
          y="23"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="12"
          height="1"
          x="9"
          y="26"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
        <rect
          width="5"
          height="1"
          x="9"
          y="29"
          fill="#9599AF"
          fillOpacity=".5"
          rx=".5"
        />
      </g>
    </svg>
  );
}
