import React from "react";
import { IconProps } from "../../../types";

export default function ServicesExterminator({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M86.237 27.768a3.303 3.303 0 0 0-4.234 5.071 30.854 30.854 0 0 1 9.903 33.921 29.553 29.553 0 0 1-7.117 11.652 1.668 1.668 0 0 1-2.6-2.055c1.432-2.32 2.853-4.558 4.078-6.193 2.703-3.627 1.832-6.504.267-7.868a5.005 5.005 0 0 0-6.754.394 75.906 75.906 0 0 0-5.048 5.892 10.964 10.964 0 0 0-10.414-5.025 36.036 36.036 0 0 1-9.643 4.338 2.503 2.503 0 0 1-3.05-1.722 2.476 2.476 0 0 1 1.725-3.08 36.6 36.6 0 0 0 8.009-3.52 4.135 4.135 0 0 0-4.672-6.823 2.477 2.477 0 0 1-2.75-4.121 9.08 9.08 0 0 1 9.814-.167 1.668 1.668 0 0 0 2.312-.56 24.328 24.328 0 0 0 1.906-16.18 1.642 1.642 0 0 0-1.235-1.368c-10.948-2.723-22.022-1.382-28.115 3.29A13.68 13.68 0 0 0 29.483 33c-4.882 1.298-10.944 5.825-9.166 12.649a10.38 10.38 0 0 0 8.91 7.097c-.204-.027-.361 9.506-.271 10.59.77 9.22 5.719 30.145 34.558 30.145a37.334 37.334 0 0 0 34.828-25.122 38.372 38.372 0 0 0-12.105-40.591zM25.602 41.15c2.241-.509 4.57-.485 6.8.07a17.017 17.017 0 0 0-2.465 6.644 5.502 5.502 0 0 1-4.848-3.484 4.438 4.438 0 0 1 .513-3.23zm23.46.764a3.303 3.303 0 1 1 0-6.606 3.303 3.303 0 0 1 0 6.606z"
        fillRule="nonzero"
      />
    </svg>
  );
}
