import { Activity } from "../../types";
import MessageListItem from "./MessageListItem";

import "../../styles/components/messages/MessageList.scss";
import { Fragment } from "react";

export default function MessageList({ messages }: Props): JSX.Element {
  return (
    <Fragment>
      <ul className="message-list">
        {messages.map((message) => (
          <MessageListItem message={message} key={message.id} />
        ))}
      </ul>
      {messages.length < 1 && (
        <div className="message-list-no-news">Keine Nachrichten</div>
      )}
    </Fragment>
  );
}

type Props = {
  messages: Activity[];
};
