import React from "react";
import { IconProps } from "../../../types";

export default function ServicesTechnicalAdministration({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M100 56.668a3.333 3.333 0 0 0-3.333-3.334h-15c-.92 0-1.667.746-1.667 1.667v10a5 5 0 0 1-5 5H45a5 5 0 0 1-5-5V55c0-.92-.746-1.667-1.667-1.667h-15A3.333 3.333 0 0 0 20 56.668v6.666a3.333 3.333 0 0 0 3.333 3.334h6.56c.754 0 1.414.505 1.61 1.233.488 1.88 1.1 3.727 1.83 5.527.262.624.12 1.345-.36 1.823l-4.796 4.787a3.333 3.333 0 0 0 0 4.713l7.073 7.07a3.303 3.303 0 0 0 4.713 0l4.78-4.787a1.667 1.667 0 0 1 1.83-.366c1.8.729 3.646 1.337 5.527 1.82a1.667 1.667 0 0 1 1.233 1.61v6.57A3.333 3.333 0 0 0 56.667 100h6.666a3.333 3.333 0 0 0 3.334-3.333v-6.56c0-.754.505-1.414 1.233-1.61a43.943 43.943 0 0 0 5.523-1.83 1.667 1.667 0 0 1 1.827.356l4.787 4.784a3.333 3.333 0 0 0 4.713 0l7.07-7.07a3.333 3.333 0 0 0 0-4.714l-4.783-4.786a1.667 1.667 0 0 1-.37-1.81 44.177 44.177 0 0 0 1.816-5.527 1.667 1.667 0 0 1 1.61-1.233h6.574A3.333 3.333 0 0 0 100 63.334v-6.666z" />
        <path d="M33.893 42.088A1.667 1.667 0 0 0 35 45h6.667c.92 0 1.666.746 1.666 1.667v16.666a3.333 3.333 0 0 0 3.334 3.334h6.666c.92 0 1.667-.747 1.667-1.667v-8.333a5 5 0 1 1 10 0V65c0 .92.746 1.667 1.667 1.667h6.666a3.333 3.333 0 0 0 3.334-3.334V46.668c0-.92.746-1.667 1.666-1.667H85a1.667 1.667 0 0 0 1.107-2.913L62.213 20.84a3.333 3.333 0 0 0-4.426 0L33.893 42.088z" />
      </g>
    </svg>
  );
}
