import { Fragment } from "react";

import "../../styles/components/meetings/MeetingAttendInformation.scss";

export default function MeetingAttendInformation({
  title,
}: Props): JSX.Element {
  return (
    <Fragment>
      <span className="etv-meeting-attend-information">
        Herzlich Willkommen, die Versammlung <strong>{title}</strong> wurde
        gestartet und Sie können nun teilnehmen.
      </span>
      <span className="etv-meeting-attend-information">
        Bitte stellen Sie sicher, dass Sie eine stabile Internetverbindung
        haben.
      </span>
      <span className="etv-meeting-attend-information">
        Falls Sie nicht vor Ort teilnehmen, treten Sie bitte auch die Online-
        bzw. Telekonferenz bei (Link / Tel.Nr. von Ihrer Hausverwaltung).
      </span>
      <span className="etv-meeting-attend-information">
        Mit Klick auf den Button <strong>Jetzt teilnehmen</strong> bestätigen
        Sie, dass keine Fremden (die nicht Eigentümer im Haus sind) in Ihrer
        Nähe die Versammlung sehen / hören können.
      </span>
    </Fragment>
  );
}

type Props = {
  title: string;
};
