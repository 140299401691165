import React from "react";
import { IconProps } from "../../../types";

export default function ServicesBuild({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 22)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M40.478 29.765a1.666 1.666 0 0 0-1.553-2.27H25.828c-.92 0-1.666.747-1.666 1.667v8.332c0 .92.746 1.666 1.666 1.666h9.865c.687 0 1.304-.422 1.553-1.063l3.232-8.332z" />
        <rect y="6.667" width="39.992" height="15.83" rx=".5" />
        <path d="m30.35 55.823 3.666-9.394a1.666 1.666 0 0 0-1.553-2.27H1.666c-.92 0-1.666.746-1.666 1.666v9.998h30.35z" />
        <rect y="60.822" width="19.163" height="15.83" rx=".5" />
        <rect x="57.488" y="60.822" width="22.495" height="15.83" rx=".5" />
        <path d="M52.49 64.032a1.666 1.666 0 0 0-2.27-1.553l-10.785 4.195a7.475 7.475 0 0 1-8.048-1.526 7.125 7.125 0 0 1-1.82-3.11 1.666 1.666 0 0 0-1.603-1.216h-2.136c-.92 0-1.666.746-1.666 1.667v12.497c0 .92.746 1.666 1.666 1.666h24.995c.92 0 1.666-.746 1.666-1.666V64.032z" />
        <rect y="27.496" width="19.163" height="11.664" rx=".5" />
        <path d="M56.932 40.203a2.43 2.43 0 0 1-3.61-.077 2.503 2.503 0 0 1 .22-3.529l4.78-4.206a1.666 1.666 0 0 0 .076-2.426l-1.743-1.74a7.099 7.099 0 0 0-6.832-1.919 5.935 5.935 0 0 0-4.262 4.24L34.53 58.908a2.296 2.296 0 0 0 3.106 3.103L65.88 51.014a5.905 5.905 0 0 0 4.372-4.236 7.132 7.132 0 0 0-1.933-6.888l-1.623-1.623 4.806-4.24a4.182 4.182 0 0 0 .39-5.871l-7.232-8.332a4.546 4.546 0 0 0 1.94-1L78.437 8.07a4.61 4.61 0 1 0-6.095-6.919L60.5 11.902a4.61 4.61 0 0 0-1.133 5.38 5.839 5.839 0 0 0 1.186 5.448l5.932 6.839a1.666 1.666 0 0 1-.153 2.332l-9.401 8.302z" />
      </g>
    </svg>
  );
}
