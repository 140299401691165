import React from "react";
import { IconProps } from "../../../types";

export default function ServicesGarden({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="m102.35 72.485-7.303-10.434a1.172 1.172 0 0 1 .96-1.846 3.583 3.583 0 0 0 3.021-5.507l-8.571-13.452a.889.889 0 0 1 .297-1.25 3.583 3.583 0 0 0 1.243-4.927l-9.108-15.2a1.792 1.792 0 0 0-3.07 0l-9.124 15.2a3.583 3.583 0 0 0 1.244 4.927.896.896 0 0 1 .3 1.25l-7.37 11.581a1.792 1.792 0 0 0 .745 2.584 8.958 8.958 0 0 1 5.135 8.098v12.82c0 .99.802 1.793 1.792 1.793h2.547c.99 0 1.792.802 1.792 1.791v9.575a4.48 4.48 0 1 0 8.958 0v-9.575c0-.99.802-1.791 1.792-1.791h11.785a3.583 3.583 0 0 0 2.938-5.637h-.003z" />
        <path d="M61.79 58.134a5.375 5.375 0 0 0-5.374 5.375v2.687c0 .99-.802 1.792-1.792 1.792H49.25c-.99 0-1.791-.802-1.791-1.792V63.51a5.375 5.375 0 0 0-10.75 0v2.687c0 .99-.802 1.792-1.792 1.792h-5.375c-.99 0-1.791-.802-1.791-1.792V63.51a5.375 5.375 0 0 0-10.75 0v26.874a3.583 3.583 0 0 0 3.583 3.584h3.584a3.583 3.583 0 0 0 3.583-3.584v-.895c0-.99.802-1.792 1.791-1.792h5.375c.99 0 1.792.802 1.792 1.792v.895a3.583 3.583 0 0 0 3.583 3.584h3.583a3.583 3.583 0 0 0 3.584-3.584v-.895c0-.99.802-1.792 1.791-1.792h5.375c.99 0 1.792.802 1.792 1.792v.895a3.583 3.583 0 0 0 3.583 3.584h3.583a3.583 3.583 0 0 0 3.584-3.584V63.51a5.375 5.375 0 0 0-5.375-5.375zM36.709 78.738c0 .99-.802 1.791-1.792 1.791h-5.375c-.99 0-1.791-.802-1.791-1.791v-1.792c0-.99.802-1.791 1.791-1.791h5.375c.99 0 1.792.802 1.792 1.791v1.792zm19.708 0c0 .99-.802 1.791-1.792 1.791H49.25c-.99 0-1.791-.802-1.791-1.791v-1.792c0-.99.802-1.791 1.791-1.791h5.375c.99 0 1.792.802 1.792 1.791v1.792z" />
      </g>
    </svg>
  );
}
