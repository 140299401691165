import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { ParticipantAttendanceType } from "../../enums";
import { useSetLocallyAttendanceMutation } from "../../api/participantsApi";
import { useParams } from "react-router-dom";
import { Participant } from "../../types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export default function useLocallyAttendMeeting(
  participant: Participant,
): LocallyAttendMeeting {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const [setLocallyAttendance, { isLoading, isError, error, isSuccess }] =
    useSetLocallyAttendanceMutation();

  const [participantAttendance, setParticipantAttendance] =
    useState<ParticipantAttendanceType>(participant.attendance);

  useEffect(() => {
    if (participantAttendance !== participant.attendance) {
      setParticipantAttendance(participant.attendance);
    }
  }, [participant]);

  const handleAttendanceChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const { value: participantId, checked } = e.currentTarget;
      const attendance = checked
        ? ParticipantAttendanceType.Locally
        : ParticipantAttendanceType.Abscent;

      setParticipantAttendance(attendance);
      setLocallyAttendance({
        meetingId,
        attendance,
        facilityObjectId,
        participantId,
        customerToken,
      });
    },
    [setParticipantAttendance],
  );

  const retryAttendanceChange = useCallback(() => {
    setLocallyAttendance({
      meetingId,
      attendance: participantAttendance,
      facilityObjectId,
      participantId: participant.id,
      customerToken,
    });
  }, [participantAttendance, participant]);
  return {
    handleAttendanceChange,
    retryAttendanceChange,
    isError,
    isLoading,
    isSuccess,
    error,
    participantAttendance,
  };
}

type LocallyAttendMeeting = {
  handleAttendanceChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  retryAttendanceChange: () => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  participantAttendance: ParticipantAttendanceType;
  error: FetchBaseQueryError | SerializedError | undefined;
};
