import React from "react";
import Skeleton from "react-loading-skeleton";
import { PropositionVotingWeight } from "../../../types";

export default function PropositionVotingWeightInfo({
  isSuccess,
  data,
  isLoading,
}: Props): JSX.Element {
  return (
    <dl className="agenda-item-proposition-information">
      <dt className="agenda-item-proposition-information-title">
        Mein Stimmanteil:
      </dt>
      {isSuccess && data && (
        <dd className="agenda-item-proposition-information-value">
          {" "}
          ({data.qualificationValue.value} /
          {data.qualificationValue.totalValues})
        </dd>
      )}
      {isLoading && (
        <dd className="agenda-item-proposition-information-value">
          <Skeleton width={100} />
        </dd>
      )}
    </dl>
  );
}

type Props = {
  propositionId: string;
  isSuccess: boolean;
  data?: PropositionVotingWeight;
  isLoading: boolean;
};
