import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
} from "react";
import { Participant, ParticipantWithAttendanceState } from "../../types";
import ParticipantsListItem from "./ParticipantsListItem";
import useSortParticipants from "../../hooks/meetings/useSortParticipants";

import useSearchByQueryParticipants from "../../hooks/meetings/useSearchByQueryParticipants";

import { EMPLOYEE_PARTICIPANT } from "../../lib/meeting";
import SearchField from "../common/SearchField";
import usePagination from "../../hooks/usePagination";
import IconButton from "../common/IconButton";
import ArrowRight from "../icons/ArrowRight";
import ArrowLeft from "../icons/ArrowLeft";

import TableSortHeader from "../common/TableSortHeader";
import useTableSort from "../../hooks/common/useTableSort";

import "../../styles/components/meetings/ParticipantsList.scss";
import useMeetingState from "../../hooks/meetings/useMeetingState";
import { MeetingState } from "../../enums";

export default function ParticipantsList({
  participants,
  pageSize = 50,
  readonly,
}: Props): ReactElement {
  const { queryParticipants, handleFilterByQuery } =
    useSearchByQueryParticipants(participants);
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "facilityAssignments",
      sortDirection: "ascending",
    },
    "participants-list",
  );
  const sortedParticipants = useSortParticipants(queryParticipants, sort);
  const meetingState = useMeetingState();

  const { pageItems, hasPrevPage, hasNextPage, prev, next, reset } =
    usePagination(sortedParticipants, {
      pageSize,
    });

  const selectableVotingEligibilityParticipants: Participant[] = [
    EMPLOYEE_PARTICIPANT,
    ...participants,
  ];

  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleFilterByQuery(e);
      reset();
    },
    [handleFilterByQuery, reset],
  );

  const sortOptions = [
    { sortBy: "name", label: "Eigentümer*in" },
    { sortBy: "objectNumber", label: "Objekt" },
    { sortBy: "facilityAssignments", label: "Einheit" },
    { sortBy: "attendance", label: "Status" },
    { sortBy: null, label: "Anwesenheit" },
    { sortBy: "delegationType", label: "Hat Vollmacht gegeben an" },
  ];

  const facilityObjectIds = useMemo(() => {
    return participants
      .flatMap((obj) => obj.facilityAssignments)
      .map((assignment) => assignment?.facilityObjectId);
  }, [participants]);

  const allFacilityIdsAreEqual = useMemo(() => {
    return facilityObjectIds.every((id) => id === facilityObjectIds[0]);
  }, [facilityObjectIds]);

  const filteredSortOptions = useMemo(() => {
    return !allFacilityIdsAreEqual
      ? sortOptions
      : sortOptions.filter((option) => option.sortBy !== "objectNumber");
  }, [allFacilityIdsAreEqual, sortOptions]);

  return (
    <section role="table" className="etv-participants-list">
      {readonly &&
        ((meetingState === MeetingState.EmployeePreview && (
          <div className="etv-participants-list-warning-info">
            Änderungen an der Teilnehmerliste sind erst möglich, sobald die
            Eigentümerversammlung für alle Teilnehmer der Versammlung auf
            HomeCase einsehbar ist.
          </div>
        )) ||
          (meetingState === MeetingState.Closed && (
            <div className="etv-participants-list-warning-info">
              Die Eigentümerversammlung wurde beendet. Die Teilnehmerliste zeigt
              den letzten Stand vor beenden der Eigentümerversammlung an. Für
              genauere Details können Sie das “ETV Ereignis Protokoll” nutzen.
            </div>
          )))}
      <SearchField onChange={handleChange} placeholder={"Person suchen"} />
      <TableSortHeader
        handleSort={handleSort}
        sortOptions={filteredSortOptions}
        sort={sort}
        className={!allFacilityIdsAreEqual ? "facility-ids-not-equal" : ""}
      />
      <div className="etv-participants-list-body" role="row">
        {pageItems.map((participant, index) => (
          <ParticipantsListItem
            participant={participant as Participant}
            key={participant.id + index}
            selectableVotingEligibilityParticipants={
              selectableVotingEligibilityParticipants
            }
            participants={participants}
            readonly={readonly}
            allFacilityIdsAreEqual={allFacilityIdsAreEqual}
          />
        ))}
      </div>
      <div className="etv-participants-list-footer" role="row">
        <IconButton
          icon={ArrowLeft}
          onClick={prev}
          disabled={!hasPrevPage}
          grey
        />
        <IconButton
          icon={ArrowRight}
          onClick={next}
          disabled={!hasNextPage}
          grey
        />
      </div>
    </section>
  );
}

type Props = {
  participants: ParticipantWithAttendanceState[];
  pageSize?: number;
  readonly: boolean;
};
