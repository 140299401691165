import { Contact, IconProps } from "../../types";
import React, { useCallback, useMemo } from "react";
import { SERVICE_PROVIDER_ICONS } from "../../lib/serviceProviders";
import ServicesDefault from "../../components/icons/serviceProviders/ServicesDefault";

export default function useProvidersFiltered(
  contactsListServiceProviders: Contact[],
): ProvidersFiltered {
  const resourceGroups = useMemo(() => {
    const resourceGroups = contactsListServiceProviders.flatMap(
      ({ resourceGroups }) =>
        resourceGroups?.map((resourceGroupName) => resourceGroupName),
    );
    return Array.from(new Set(resourceGroups));
  }, [contactsListServiceProviders]);

  const filteredServicesProviders = useCallback(
    (element: string | undefined) =>
      contactsListServiceProviders.filter(({ resourceGroups }) =>
        Boolean(
          resourceGroups?.find(
            (resourceGroupName) => resourceGroupName === element,
          ),
        ),
      ),
    [contactsListServiceProviders],
  );

  const getFindIcon = useCallback(
    (
      contractedResources: string[] = [],
      element = "",
    ): React.FunctionComponent<IconProps> | undefined => {
      let IconComponent = ServicesDefault as React.FunctionComponent<IconProps>;
      element !== undefined &&
        SERVICE_PROVIDER_ICONS.find((item) => {
          item.title.test(
            contractedResources.length === 1 ? contractedResources[0] : element,
          ) && (IconComponent = item.icon);
        });

      return IconComponent;
    },
    [],
  );
  return {
    filteredServicesProviders,
    getFindIcon,
    resourceGroups,
  };
}

type ProvidersFiltered = {
  filteredServicesProviders: (element: string | undefined) => Contact[];
  getFindIcon: (
    contractedResources: string[] | undefined,
    element: string | undefined,
  ) => React.FunctionComponent<IconProps> | undefined;
  resourceGroups?: (string | undefined)[];
};
