import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  PropositionAcceptanceStatus,
  PropositionLegalValidityType,
  PropositionVotingState,
} from "../../../enums";
import Button from "../../common/Button";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import { Form } from "react-final-form";
import PropositionVotingResolutionDialogInfo from "./PropositionVotingResolutionDialogInfo";
import {
  useGetPropositionQuery,
  useGetVotingResultQuery,
  usePatchDecisionAcceptedMutation,
  usePatchPropositionMutation,
} from "../../../api/propositionApi";
import { useParams } from "react-router-dom";
import AlertDialog from "../../common/dialog/AlertDialog";
import classNames from "classnames";

export default function PropositionVotingResolution({
  votingState,
  propositionId,
}: Props): JSX.Element {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const { data: proposition, refetch } = useGetPropositionQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    agendaItemId,
    propositionId,
  });

  const { data: votingResult } = useGetVotingResultQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    agendaItemId,
    propositionId,
  });

  const [
    patchDecisionForce,
    {
      isError: isErrorPatchDecisionForce,
      isSuccess: isSuccessPatchDecisionForce,
    },
  ] = usePatchPropositionMutation();
  const [
    patchDecisionAccepted,
    {
      isError: isErrorPatchDecisionAccepted,
      isSuccess: isSuccessPatchDecisionAccepted,
    },
  ] = usePatchDecisionAcceptedMutation();

  const onSubmit = useCallback(
    async (values: Record<string, boolean | string>) => {
      await patchDecisionForce({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        decisionHasLegalForce: values.decisionCalm
          ? (values.decisionCalm as boolean)
          : false,
        categoryId: values.propositionCategoryId as string,
        statusId: values.propositionStatusId as string,
      });

      await patchDecisionAccepted({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        decision: {
          isDecisionAccepted: values.finalDecision as boolean,
          meetingHasQuorum: !!values.finalDecision as boolean,
        },
      });
      refetch();
      setOpenDialog(false);
    },
    [],
  );
  const isSuccessBeschluss =
    isSuccessPatchDecisionAccepted && isSuccessPatchDecisionForce;

  const errorBeschluss =
    isErrorPatchDecisionForce || isErrorPatchDecisionAccepted;
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isErrorDialog, setErrorDialog] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);

  useEffect(() => {
    if (errorBeschluss) {
      setErrorDialog(true);
    }
  }, [isErrorPatchDecisionForce, isErrorPatchDecisionAccepted, setOpenDialog]);

  useEffect(() => {
    if (isSuccessBeschluss) {
      setSuccessDialogOpen(true);
    }
  }, [
    isSuccessPatchDecisionForce,
    isSuccessPatchDecisionAccepted,
    setOpenDialog,
  ]);

  const votingResultAcceptanceStatus =
    votingResult?.acceptanceStatus === PropositionAcceptanceStatus.Accepted;
  const propositionDecisionLegalValidity = proposition?.decisionLegalValidity;
  const propositionStatusTitle = proposition?.statusTitle;
  const propositionCategoryTitle = proposition?.categoryTitle;

  const buttonClassName = classNames(
    "agenda-item-proposition-voting-control-resolution",
    {
      "resolution-green":
        votingResultAcceptanceStatus &&
        votingState === PropositionVotingState.Closed,
    },
  );
  return (
    <Fragment>
      <Button
        label={
          votingResultAcceptanceStatus
            ? "Beschluss gefasst."
            : "Beschluss gefasst?"
        }
        className={buttonClassName}
        onClick={() => setOpenDialog(true)}
        type="button"
        disabled={votingState !== PropositionVotingState.Closed}
      />
      {votingResultAcceptanceStatus && (
        <div className={"resolution-points"}>
          {propositionDecisionLegalValidity ? (
            <span>Beschluss Rechtsfähig</span>
          ) : (
            ""
          )}
          {propositionStatusTitle
            ? propositionStatusTitle !== "(kein Status)" && (
                <span
                  title={
                    propositionStatusTitle.length > 25
                      ? propositionStatusTitle
                      : ""
                  }
                >
                  Status: {propositionStatusTitle}
                </span>
              )
            : ""}
          {propositionCategoryTitle
            ? propositionCategoryTitle !== "(keine Kategorie)" && (
                <span
                  title={
                    propositionCategoryTitle.length > 25
                      ? propositionCategoryTitle
                      : ""
                  }
                >
                  Kategorie: {propositionCategoryTitle}
                </span>
              )
            : ""}
        </div>
      )}
      {isOpenDialog && (
        <Form
          onSubmit={onSubmit}
          initialValues={{
            finalDecision:
              votingResult?.acceptanceStatus !==
              PropositionAcceptanceStatus.NotAccepted,
            decisionCalm:
              propositionDecisionLegalValidity ===
              PropositionLegalValidityType.Valid,
            propositionStatusId: proposition?.statusId,
            propositionCategoryId: proposition?.categoryId,
          }}
          render={({ handleSubmit, dirty }) => {
            return (
              <ConfirmDialog
                description={<PropositionVotingResolutionDialogInfo />}
                title="Beschluss"
                confirmLabel="Speichern"
                cancelLabel="Abbrechen"
                onConfirm={handleSubmit}
                onCancel={() => setOpenDialog(false)}
                confirmDisabled={!dirty}
              />
            );
          }}
        />
      )}
      {errorBeschluss && isErrorDialog && (
        <AlertDialog
          description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
          title="Es ist ein Fehler aufgetreten!"
          onConfirm={() => setErrorDialog(false)}
        />
      )}
      {!errorBeschluss && isSuccessBeschluss && isSuccessDialogOpen && (
        <AlertDialog
          description=""
          title="Ihre Auswahl wurde Erfolgreich in Conventus gespeichert"
          onConfirm={() => setSuccessDialogOpen(false)}
        />
      )}
    </Fragment>
  );
}

type Props = {
  votingState: PropositionVotingState;
  propositionId?: string;
};
