import React from "react";
import "../../styles/components/common/ProfilePictureForUser.scss";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

export default function ProfilePictureSkeleton(): JSX.Element {
  const className = classNames({
    "profile-picture": true,
    "profile-picture-rounded": true,
    "pictures-list": true,
  });
  return (
    <div className={className} data-testid="profile-picture">
      <Skeleton width={70} height={70} className="profile-picture-image" />
    </div>
  );
}
