import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import "../../../styles/components/pages/messages/IndexMessagesPage.scss";
import SearchField from "../../common/SearchField";
import { FacilityObject } from "../../../types";

export default function IndexMessagePage(): ReactElement {
  const { messageId = "" } = useParams();

  const [selectedAssignedObject] = useOutletContext<[FacilityObject]>();
  const [query, setQuery] = useState<string>("");
  const title = "Nachrichten";
  const backTo = !messageId
    ? undefined
    : { to: "../nachrichten", label: "Alle Nachrichten" };

  const onSearch = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    setQuery(value);
  }, []);

  return (
    <div className="messages">
      <Helmet>
        <title>{title} - Homecase</title>
      </Helmet>
      <PageHeader
        title={title}
        backTo={backTo}
        selectedAssignedObject={selectedAssignedObject}
        searchComponent={SearchField}
        onSearch={onSearch}
      />
      <div className="messages-container">
        <Outlet context={[query]} />
      </div>
    </div>
  );
}
