import Messages from "../icons/Messages";

export default function MessageDetailsNotSelected(): JSX.Element {
  return (
    <div
      className="message-details-not-selected"
      data-testid="message-details-not-selected"
    >
      <Messages
        height={200}
        width={200}
        className="message-details-not-selected-icon"
      />
      <h1>Keine Nachricht ausgewählt.</h1>
      <p>Wählen Sie links eine Nachricht aus.</p>
    </div>
  );
}
