import useLocalStorage from "./useLocalStorage";
import { RecentLogin } from "../types";
import { useEffect } from "react";
import { blobToDataUrl } from "../lib";

const LOCAL_STORAGE_KEY = "RecentLogins.v3";
const PROFILE_PICTURE_SIZE = Math.round(160 * devicePixelRatio);

export async function loadProfilePicture(sid: string): Promise<string | null> {
  try {
    const response = await fetch(
      `/users/${sid}_${PROFILE_PICTURE_SIZE}x${PROFILE_PICTURE_SIZE}_fill_${Date.now()}.jpg`,
      {
        method: "GET",
      },
    );

    if (response.status === 200) {
      const blob = await response.blob();
      return (await blobToDataUrl(blob)) || null;
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export default function useLoadMissingProfilePicturesForRecentLogins(
  signedIn: boolean,
) {
  const [recentLogins, setRecentLogins] = useLocalStorage<RecentLogin[]>(
    LOCAL_STORAGE_KEY,
    [],
  );

  useEffect(() => {
    if (signedIn) {
      const loadPictures = async () => {
        const recentLoginsWithProfilePicture = await Promise.all(
          recentLogins.map(
            async ({
              sid,
              loginToken,
              name,
              profilePictureDataURL,
            }: RecentLogin): Promise<RecentLogin> => {
              if (profilePictureDataURL === null) {
                const dataUrl = await loadProfilePicture(sid);
                if (dataUrl !== null) {
                  return {
                    sid,
                    loginToken,
                    name,
                    profilePictureDataURL: dataUrl,
                  };
                }
              }

              return {
                sid,
                loginToken,
                name,
                profilePictureDataURL,
              };
            },
          ),
        );
        setRecentLogins(recentLoginsWithProfilePicture);
      };
      loadPictures();
    }
  }, [signedIn]);
}
