import { useGetMyRelatedParticipantsQuery } from "../../api/participantsApi";
import { useParams } from "react-router-dom";
import useCustomerRoles from "../useCustomerRoles";
import { isEmployeeByRoles } from "../../lib";
import { EMPLOYEE_PARTICIPANT } from "../../lib/meeting";

export default function useGetMyRelatedParticipants(relatedToMeetingId = "") {
  const {
    customerToken = "",
    meetingId = relatedToMeetingId,
    facilityObjectId = "",
  } = useParams();

  const userRoles = useCustomerRoles();
  const isEmployee = isEmployeeByRoles(userRoles);
  const {
    data: myRelatedParticipants = isEmployee ? [EMPLOYEE_PARTICIPANT] : [],
    isFetching,
  } = useGetMyRelatedParticipantsQuery(
    {
      customerToken,
      meetingId,
      facilityObjectId,
    },
    { skip: isEmployee },
  );
  return {
    myRelatedParticipants,
    isFetching,
  };
}
