import { useParams } from "react-router-dom";
import { useGetTermsOfUseWithContentQuery } from "../../api/termsOfUseApi";

export default function useFetchTermsOfUseContent(latestId: number) {
  const { customerToken = "", termsOfUseId = "" } = useParams();
  const { data: termsOfUseContent } = useGetTermsOfUseWithContentQuery(
    {
      customerToken,
      id: termsOfUseId,
    },
    { skip: !termsOfUseId || Number(termsOfUseId) > latestId },
  );

  return { termsOfUseContent };
}
