import { useLightboxContext } from "../../../hooks/useLightbox";
import { createContext, ReactNode, SyntheticEvent } from "react";
import { FileWithMeta } from "../../../types";

export const LightboxContext = createContext<LightboxProviderContext | null>(
  null,
);
export default function LightboxProvider({
  children,
  options,
  files = [],
}: Props): JSX.Element {
  return (
    <LightboxContext.Provider value={useLightboxContext(options, files)}>
      {children}
    </LightboxContext.Provider>
  );
}

type Props = {
  children: ReactNode | ReactNode[];
  options?: {
    showMeta?: boolean;
    closeButton?: boolean;
  };
  files?: File[];
};

export type LightboxProviderContext = {
  addFile: (file: FileWithMeta) => void;
  initialize: (initFiles?: FileWithMeta[]) => void;
  toggleOpen: (file?: FileWithMeta) => void;
  files: FileWithMeta[];
  selectedIndex: number;
  next: (e: SyntheticEvent | KeyboardEvent) => void;
  prev: (e: SyntheticEvent | KeyboardEvent) => void;
  isOpen: boolean;
  options: {
    showMeta: boolean;
    closeButton: boolean;
  };
};
