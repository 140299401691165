import React from "react";
import { IconProps } from "../../../types";

export default function ServicesChemistryMold({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 20)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <rect x="13.333" y="13.333" width="10" height="10" rx="1" />
        <path d="m51.053 26.837-10-3.334a3.337 3.337 0 0 0-2.106 6.334l10 3.333a3.337 3.337 0 1 0 2.106-6.333zM48.947 6.837l-10 3.333a3.337 3.337 0 0 0 2.106 6.333l10-3.333a3.337 3.337 0 0 0-2.106-6.333z" />
        <rect y="40" width="36.667" height="40" rx="1.5" />
        <path d="M6.667 36.667H30a1.667 1.667 0 0 0 1.49-2.414l-2.41-4.82a4.97 4.97 0 0 0-4.473-2.766H12.06a4.973 4.973 0 0 0-4.473 2.763l-2.41 4.823a1.667 1.667 0 0 0 1.49 2.414zM71.29 8.71A11.69 11.69 0 0 0 60 0a3.333 3.333 0 1 0 0 6.667 5 5 0 0 1 5 5A3.333 3.333 0 0 0 68.333 15a5 5 0 1 1 0 10A3.333 3.333 0 0 0 65 28.333a5 5 0 0 1-5 5A3.333 3.333 0 0 0 60 40a11.69 11.69 0 0 0 11.29-8.71 11.667 11.667 0 0 0 0-22.58z" />
      </g>
    </svg>
  );
}
