import {
  createListenerMiddleware,
  ListenerEffectAPI,
  UnknownAction,
} from "@reduxjs/toolkit";
import {
  append,
  FileUploadState,
  updateUploadState,
} from "../slices/fileUploadSlice";
import { RootState } from "../store";
import { UploadState } from "../enums";

const fileUploadListener = createListenerMiddleware();

export async function fileUploadListenerEffect(
  action: UnknownAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenerApi: ListenerEffectAPI<any, any>,
) {
  const state = listenerApi.getState() as RootState;
  const fileUpload = state.fileUpload as FileUploadState;

  await Promise.all(
    fileUpload.files.map(
      async ({ file, endpoint, referenceId, customerToken, ...rest }) => {
        listenerApi.dispatch(
          updateUploadState({
            referenceId,
            file,
            uploadState: UploadState.InProgress,
          }),
        );

        const result = await listenerApi.dispatch(
          endpoint.initiate({ file, referenceId, customerToken, ...rest }),
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.error) {
          listenerApi.dispatch(
            updateUploadState({
              referenceId,
              file,
              uploadState: UploadState.Failure,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error: result.error,
            }),
          );
          return result;
        }

        listenerApi.dispatch(
          updateUploadState({
            referenceId,
            file,
            uploadResult: result?.data,
            uploadState: UploadState.Completed,
          }),
        );
        return result;
      },
    ),
  );
}

fileUploadListener.startListening({
  actionCreator: append,
  effect: fileUploadListenerEffect,
});

export default fileUploadListener;
