import React from "react";
import { View } from "@react-pdf/renderer";
import { PropositionsResultForPDF } from "../../../types";
import PdfStyles from "./PdfStyles";
import PdfDocumentVoteBar from "./PdfDocumentVoteBar";
export default function PdfDocumentVotingResult({ item }: Props) {
  const total = item.votingResult
    ? item.votingResult.qualificationValues.totalYesValues +
      item.votingResult.qualificationValues.totalNoValues +
      item.votingResult.qualificationValues.totalAbstentionsValues
    : 0;
  return (
    <View style={PdfStyles.voteResults}>
      <PdfDocumentVoteBar
        label="Ja"
        count={
          item.votingResult
            ? item.votingResult.qualificationValues.totalYesValues
            : 0
        }
        total={total}
        color="green"
      />
      <PdfDocumentVoteBar
        label="Nein"
        count={
          item.votingResult
            ? item.votingResult.qualificationValues.totalNoValues
            : 0
        }
        total={total}
        color="red"
      />
      <PdfDocumentVoteBar
        label="Enthalten"
        count={
          item.votingResult
            ? item.votingResult.qualificationValues.totalAbstentionsValues
            : 0
        }
        total={total}
        color="grey"
      />
    </View>
  );
}

type Props = {
  item: PropositionsResultForPDF;
};
