import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";
import TabBar from "../../common/TabBar";
import Tab from "../../common/Tab";
import React, { ReactElement } from "react";
import { Outlet, useLocation } from "react-router-dom";

import useFetchFacilityObjectWithFacilityUnits from "../../../hooks/messages/useFetchFacilityObjectWithFacilityUnits";
import { FacilityObjectType } from "../../../enums";
import "../../../styles/components/pages/management/FacilityObjectSettings.scss";

export default function FacilityObjectSettings(): ReactElement {
  const { pathname } = useLocation();
  const { facilityObject } = useFetchFacilityObjectWithFacilityUnits();

  const backTo = {
    to: "../objekte",
    label: "Alle Objekte",
  };

  return (
    <div className="facility-object-settings-container">
      <Helmet
        title={`${facilityObject.number} -  ${facilityObject.description ? facilityObject.description : ""} - Homecase`}
      />
      <PageHeader
        title={`${facilityObject.number ? facilityObject.number : ""} -  ${facilityObject.description ? facilityObject.description : ""}`}
        backTo={backTo}
      />
      <TabBar>
        <Tab
          to={"objekt"}
          active={pathname.endsWith("objekt")}
          label="Objekt"
        />
        <Tab
          to={"berichte"}
          active={pathname.endsWith("berichte")}
          label="Berichte"
        />
        <Tab
          to={"dokumente"}
          active={pathname.includes("dokumente")}
          label="Dokumente"
        />
        <Tab
          to={"mieter"}
          active={pathname.endsWith("mieter")}
          label={
            facilityObject.facilityObjectType ===
            FacilityObjectType.ResidentialCommunityOfOwners
              ? "Eigentümer"
              : "Mieter"
          }
        />
        <Tab
          to={"diensleister"}
          active={pathname.endsWith("diensleister")}
          label="Dienstleister"
        />
        <Tab
          to={"hauseigentuemer"}
          active={pathname.endsWith("hauseigentuemer")}
          label="Hauseigentümer"
        />
      </TabBar>
      <div className="facility-object-settings-container-wrapper">
        <Outlet />
      </div>
    </div>
  );
}
