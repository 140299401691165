import React from "react";
import { IconProps } from "../../../types";

export default function ServicesBanking({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 20)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M40 21.667v-15A6.667 6.667 0 0 0 33.333 0H6.667A6.667 6.667 0 0 0 0 6.667v15a6.667 6.667 0 0 0 6.667 6.666h26.666A6.667 6.667 0 0 0 40 21.667zM6.667 7.5c0-.46.373-.833.833-.833h25c.46 0 .833.373.833.833v13.333c0 .46-.373.834-.833.834h-25a.833.833 0 0 1-.833-.834V7.5zM76.667 73.333h-2.5a.833.833 0 0 1-.834-.833V60.833c0-.46.373-.833.834-.833H75a3.333 3.333 0 1 0 0-6.667H58.333a3.333 3.333 0 0 0 0 6.667h.834c.46 0 .833.373.833.833V72.5c0 .46-.373.833-.833.833H47.5a.833.833 0 0 1-.833-.833V60.833c0-.46.373-.833.833-.833h.833a3.333 3.333 0 1 0 0-6.667H31.667a3.333 3.333 0 0 0 0 6.667h.833c.46 0 .833.373.833.833V72.5c0 .46-.373.833-.833.833H30A3.333 3.333 0 0 0 30 80h46.667a3.333 3.333 0 0 0 0-6.667zM28.333 45.893v2.44c0 .92.747 1.667 1.667 1.667h46.667c.92 0 1.666-.746 1.666-1.667v-2.44c0-.557-.279-1.078-.743-1.386L54.257 28.95a1.667 1.667 0 0 0-1.847 0L29.077 44.507c-.464.308-.744.829-.744 1.386z" />
        <circle cx="20" cy="14.167" r="4.167" />
      </g>
    </svg>
  );
}
