import React from "react";
import { IconProps } from "../../types";

export default function Messages({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M18.857 13.143c3.156 0 5.714 2.238 5.714 5 0 1.198-.48 2.297-1.283 3.158.065.524.315 1.197 1.019 1.883l.17.158a.298.298 0 0 1-.26.51c-1.254-.072-2.386-.551-3.253-1.06a6.393 6.393 0 0 1-2.107.35c-3.156 0-5.714-2.238-5.714-5 0-2.76 2.558-5 5.714-5zm-5-7.143c3.996 0 7.288 2.714 7.785 6.224a7.784 7.784 0 0 0-2.785-.51c-3.938 0-7.143 2.884-7.143 6.429 0 .678.12 1.331.337 1.946a8.37 8.37 0 0 1-1.4-.431c-.88.693-1.973 1.255-3.245 1.327l-.262.008a.359.359 0 0 1-.43-.35c0-.148.09-.275.218-.33l.012-.003c.817-.618 1.27-1.313 1.485-2.01C6.932 17.002 6 15.172 6 13.143 6 9.198 9.518 6 13.857 6z"
        fillRule="evenodd"
      />
    </svg>
  );
}
