import React, { ReactNode } from "react";
import LoadingSpinner from "../../icons/LoadingSpinner";

import "../../../styles/components/common/Dialog.scss";

export default function UpdateDialog({
  title,
  description,
}: Props): JSX.Element {
  return (
    <div className="dialog-background-layer" data-testid="update-dialog">
      <div
        className="dialog"
        role="alertdialog"
        aria-modal
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
      >
        <h2 className="dialog-title" id="dialog1Title">
          {title}
        </h2>
        <p
          className="dialog-description"
          id="dialog1Desc"
          data-testid="description"
        >
          {description}
        </p>
        <div className="dialog-spinner">
          <LoadingSpinner width={100} height={100} />
        </div>
      </div>
    </div>
  );
}

type Props = {
  description: string | ReactNode;
  title: string;
};
