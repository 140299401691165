import { useGetUserEventsUnreadCountQuery } from "../../api/userApi";
import useGetUsersMyProfile from "../useGetUsersMyProfile";
import { useGetLoginInfoQuery } from "../../api/homecaseMvcApi";

export default function useFetchUnreadNotificationsCount(): number {
  const { user } = useGetUsersMyProfile();
  const userSid = user.sid ?? "";
  const { data: isLoggedIn } = useGetLoginInfoQuery();

  const { data = 0 } = useGetUserEventsUnreadCountQuery(
    { userSid },
    {
      skip:
        !userSid ||
        userSid === "00000000-0000-0000-0000-000000000000" ||
        !isLoggedIn,
      pollingInterval: 30000,
    },
  );

  return data;
}
