import React from "react";
import Skeleton from "react-loading-skeleton";

import "../../styles/components/common/contact/ContactPersonCard.scss";

export default function ContactPersonCardSkeleton(): JSX.Element {
  return (
    <div
      className="contact-person-card"
      data-testid="contact-person-card-skeleton"
    >
      <div className="contact-person-card-responsibility" role="responsibility">
        <Skeleton />
      </div>
      <div className="contact-person-card-icon-container">
        <div className="contact-person-card-icon-container-oval">
          <Skeleton width={120} height={120} />
        </div>

        <span
          data-testid="contact-person-card-name"
          className="contact-person-card-name"
        >
          <Skeleton />
        </span>
      </div>
      <div className="contact-person-card-contacts">
        <Skeleton />
      </div>
    </div>
  );
}
