import { AgendaItem } from "../../types";
import { useMemo } from "react";

export default function useGetSortedAgendaItems(
  agendaItems: AgendaItem[],
): Props {
  const sortedAgendaItems = useMemo(() => {
    return agendaItems.slice().sort((a, b) => {
      if (a.number !== b.number) {
        return a.number - b.number;
      } else {
        if (a.addition && b.addition) {
          return a.addition < b.addition ? -1 : a.addition > b.addition ? 1 : 0;
        }
        return 0;
      }
    });
  }, [agendaItems]);

  return { sortedAgendaItems };
}

type Props = {
  sortedAgendaItems: AgendaItem[];
};
