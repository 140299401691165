import WrongFileIcon from "../../icons/WrongFileIcon";

import "../../../styles/components/common/files/UnsupportedFile.scss";

export default function UnsupportedFile({
  error,
  width = 100,
  height = 100,
}: Props): JSX.Element {
  return (
    <div className="unsupported-file" data-testid="unsupported-file">
      <WrongFileIcon height={height} width={width} />
      <span className="unsupported-file-message">{error.errorMessage}</span>
    </div>
  );
}

type Props = {
  file: File;
  width?: number;
  height?: number;
  error: {
    errorMessage: string;
    name: string;
  };
};
