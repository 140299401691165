import LoadingSpinner from "../icons/LoadingSpinner";
import { BulletinBoardPostAttachment } from "../../types";
import { useGetBulletinBoardPostAttachmentQuery } from "../../api/bulletinBoardApi";
import { PREVIEW_COMPONENTS } from "../../lib/messages";
import OtherFile from "../common/files/OtherFile";
import { useEffect, useMemo } from "react";
import useLightbox from "../../hooks/useLightbox";

export default function PostAttachmentItem({
  attachment,
  customerToken,
  postId,
  facilityObjectId,
}: Props) {
  const { addFile, toggleOpen } = useLightbox();
  const { data, isFetching } = useGetBulletinBoardPostAttachmentQuery(
    {
      customerToken,
      facilityObjectId,
      bulletinBoardPostId: postId,
      bulletinBoardPostAttachmentId: attachment ? String(attachment.id) : "",
      fillStrategy: 1,
    },
    { skip: !attachment },
  );

  const { file, PreviewComponent } = useMemo(() => {
    if (data && attachment) {
      const type = data?.type || "";
      return {
        file: new File([data], attachment?.originalFileName || "", {
          type: attachment.contentType,
          lastModified: new Date(attachment.createDateUTC).getTime(),
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        PreviewComponent: PREVIEW_COMPONENTS[type] || OtherFile,
      };
    }
    return {};
  }, [data, attachment]);

  useEffect(() => {
    if (data && file) {
      addFile(file);
    }
  }, [data]);

  return (
    <div
      className="bulletin-board-post-attachments-item"
      data-testid="post-attachment-item"
    >
      {isFetching && <LoadingSpinner />}
      {file && PreviewComponent && (
        <PreviewComponent
          file={file}
          width={81}
          height={81}
          onClick={toggleOpen}
        />
      )}
      {file && (
        <span
          className="bulletin-board-post-attachments-item-name"
          title={file.name}
        >
          {file.name}
        </span>
      )}
    </div>
  );
}

type Props = {
  attachment?: BulletinBoardPostAttachment;
  postId: number;
  customerToken: string;
  facilityObjectId: string;
};
