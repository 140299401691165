import {
  ActivityDocumentsSharingByMailEventModel,
  NotificationProps,
} from "../../types";
import { getPrettifyGuid } from "../../lib/notifications";
import { ActivityDocumentSharedByMailDispatchingSharingType } from "../../enums";
import NotificationItemDate from "./NotificationItemDate";

export default function ActivityDocumentsSharingByMailEventNotification({
  userEventData,
  profilePicture,
  className,
  markUserEventsAsRead,
  createdByUserName,
}: NotificationProps): JSX.Element {
  const {
    facilityObjectId,
    customerToken,
    raisedAtUtc,
    activityId,
    documentId,
    originalFileName,
    text,
    sharingType,
  } = userEventData as ActivityDocumentsSharingByMailEventModel;

  const prettifiedActivityId = getPrettifyGuid(activityId);
  const prettifiedDocumentId = getPrettifyGuid(documentId);

  const link =
    sharingType ==
    ActivityDocumentSharedByMailDispatchingSharingType.FacilityObjectOnlineDocumentContractualRelation
      ? `/${customerToken}/objekte/${facilityObjectId}/dokumente/${prettifiedDocumentId}/${originalFileName}`
      : `/${customerToken}/nachrichten/${prettifiedActivityId}/dokumente/${prettifiedDocumentId}/${originalFileName}`;

  return (
    <a
      href={link}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item-document-sharing"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong>
        <span>{text}</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </a>
  );
}
