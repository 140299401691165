import { useParams } from "react-router-dom";
import { useGetFacilityObjectNavigationItemsQuery } from "../api/facilityManagementApi";
import { NavigationItem } from "../types";
import { mapRawNavigationManagementItems } from "../lib/navigation";

export default function useNavigationItems(
  management = false,
): UseNavigationItems {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { isFetching, data = [] } = useGetFacilityObjectNavigationItemsQuery(
    {
      customerToken,
      facilityObjectId,
    },
    { skip: !facilityObjectId },
  );

  const navigationManagement = mapRawNavigationManagementItems();

  return {
    items: !management ? data : navigationManagement,
    isFetching,
  };
}

type UseNavigationItems = {
  items: NavigationItem[];
  isFetching: boolean;
};
