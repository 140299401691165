import moment from "moment";
import React, { Fragment, useCallback, useState } from "react";
import ContactPersonCard from "../common/contactPerson/ContactPersonCard";
import FetchApiError from "../common/FetchApiError";
import { Contact } from "../../types";
import ProfilePicture from "../common/ProfilePicture";
import ContactPersonCardSkeleton from "../skeleton/ContactPersonCardSkeleton";

import ContactPersonCardDialog from "../common/contactPerson/ContactPersonCardDialog";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import { UserRoles } from "../../enums";
import useFetchContactCardInfo from "../../hooks/useFetchContactCardInfo";
import useScrollToSection from "../../hooks/common/useScrollToSection";

import "../../styles/components/pages/NeighborsPage.scss";

const skeletonArray = Array(9).fill(undefined);

type Props = {
  contactsListNeighborsContactsList: Contact[];
};

export default function NeighborsPage({
  contactsListNeighborsContactsList,
}: Props): JSX.Element {
  const [modalContent, setModalContent] = useState<null | Contact>(null);

  const handleClose = useCallback(() => {
    setModalContent(null);
  }, []);

  const {
    neighborsContactsListIsFetching,
    neighborsContactsListError,
    neighborsContactsListIsError,
  } = useFetchContactCardInfo();

  const roles = useCustomerRoles();
  const titleNavigation =
    roles.includes(UserRoles.Owner) || roles.includes(UserRoles.Employee)
      ? "Eigentümergemeinschaft"
      : "Nachbarn";

  const { ref, rootRef } = useScrollToSection("nachbarn", [0.5]);

  return (
    <div className="neighbors" data-testid="neighbors" ref={rootRef}>
      <h2>{titleNavigation}</h2>
      <div className="neighbors-container">
        {!neighborsContactsListIsFetching &&
          !neighborsContactsListIsError &&
          contactsListNeighborsContactsList.map((neighbor, index) => (
            <Fragment key={neighbor.id}>
              {Math.ceil(contactsListNeighborsContactsList.length / 2) ===
                index && <div ref={ref} />}
              <ContactPersonCard
                key={neighbor.id}
                firstName={neighbor.firstName}
                lastName={neighbor.lastName}
                onClick={() => setModalContent(neighbor)}
                profilePicture={
                  <ProfilePicture
                    alt={neighbor.lastName}
                    userSid={neighbor.id}
                    rounded
                    width={120}
                    height={120}
                  />
                }
              />
            </Fragment>
          ))}
        {modalContent && (
          <ContactPersonCardDialog
            firstName={modalContent.firstName}
            lastName={modalContent.lastName}
            aboutMe={modalContent.aboutMe}
            gender={modalContent.gender}
            birthDay={
              modalContent.birthDate?.dayOfBirth
                ? moment({
                    day: modalContent.birthDate?.dayOfBirth,
                    month: modalContent.birthDate?.monthOfBirth - 1,
                    year: modalContent.birthDate?.yearOfBirth,
                  }).format("L")
                : undefined
            }
            profilePicture={
              <ProfilePicture
                alt={modalContent.lastName}
                userSid={modalContent.id}
                rounded
                width={120}
                height={120}
              />
            }
            onClose={handleClose}
          />
        )}
        {neighborsContactsListIsFetching &&
          !neighborsContactsListIsError &&
          skeletonArray.map((v, key) => (
            <ContactPersonCardSkeleton key={key} />
          ))}
        {neighborsContactsListIsError && (
          <FetchApiError error={neighborsContactsListError} />
        )}
      </div>
    </div>
  );
}
