import React, { Fragment } from "react";
import { QualificationVotingValues } from "../../../types";

export default function PropositionVotingResultBar({
  qualificationValues,
}: Props): JSX.Element {
  return (
    <Fragment>
      <div className="agenda-item-proposition-voting-result-bar">
        <span
          className="agenda-item-proposition-voting-result-bar-green"
          style={{
            minWidth: `${qualificationValues.totalYesValuesInPercent}%`,
          }}
        />
        <span
          className="agenda-item-proposition-voting-result-bar-red"
          style={{
            minWidth: `${qualificationValues.totalNoValuesInPercent}%`,
          }}
        />
        <span
          className="agenda-item-proposition-voting-result-bar-grey"
          style={{
            minWidth: `${qualificationValues.totalAbstentionsValuesInPercent}%`,
          }}
        />
        <span
          className="agenda-item-proposition-voting-result-bar-lightgrey"
          style={{
            minWidth: `${qualificationValues.totalUndecidedValuesInPercent}%`,
          }}
        />
      </div>
      <div className="agenda-item-proposition-voting-result-legend">
        <span className="agenda-item-proposition-voting-result-legend-green">
          Ja {qualificationValues.totalYesValuesInPercent.toFixed(2)}% (
          {qualificationValues.totalYesValues}/
          {qualificationValues.totalEligibleValues})
        </span>
        <span className="agenda-item-proposition-voting-result-legend-red">
          Nein {qualificationValues.totalNoValuesInPercent.toFixed(2)}% (
          {qualificationValues.totalNoValues}/
          {qualificationValues.totalEligibleValues})
        </span>
        <span className="agenda-item-proposition-voting-result-legend-grey">
          Enthaltung{" "}
          {qualificationValues.totalAbstentionsValuesInPercent.toFixed(2)}% (
          {qualificationValues.totalAbstentionsValues}/
          {qualificationValues.totalEligibleValues})
        </span>
        <span className="agenda-item-proposition-voting-result-legend-lightgrey">
          Nicht abgestimmt{" "}
          {qualificationValues.totalUndecidedValuesInPercent.toFixed(2)}% (
          {qualificationValues.totalUndecidedValues}/
          {qualificationValues.totalEligibleValues})
        </span>
      </div>
    </Fragment>
  );
}

type Props = {
  qualificationValues: QualificationVotingValues;
};
