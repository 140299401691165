import classNames from "classnames";
import { ActivityCustomField } from "../../types";
import { Fragment, useCallback, useState } from "react";
import CaretDown from "../icons/CaretDown";

import "../../styles/components/messages/MessageDetailsCustomFields.scss";

export default function MessageDetailsCustomFields({
  customFields,
  collapsed = false,
}: Props): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const className = classNames({
    "message-details-custom-fields": true,
    "message-details-custom-fields-collapsed": isCollapsed,
  });

  return (
    <div className={className} data-testid="message-details-custom-fields">
      <h3
        className="message-details-custom-fields-headline"
        data-testid="message-details-custom-fields-headline"
        onClick={toggleCollapsed}
      >
        <span>Formular</span>
        <CaretDown className="message-details-custom-fields-headline-icon" />
      </h3>
      <div className="message-details-custom-fields-content">
        {customFields.map(
          ({ definition: { description, name }, displayValue }, key) => (
            <Fragment key={key}>
              <span
                className="message-details-custom-fields-label"
                data-testid="message-details-custom-fields-label"
              >
                {description || name}
              </span>
              <span
                className="message-details-custom-fields-value"
                data-testid="message-details-custom-fields-value"
              >
                {displayValue}
              </span>
            </Fragment>
          ),
        )}
      </div>
    </div>
  );
}

type Props = {
  customFields: ActivityCustomField[];
  collapsed?: boolean;
};
