import { FieldArrayRenderProps } from "react-final-form-arrays";
import TextInput from "../../common/form/TextInput";
import { Field, useForm } from "react-final-form";
import {
  FacilityObjectType,
  OnlineDocumentPermissionType,
} from "../../../enums";
import { useGetOnlineDocumentCategoriesQuery } from "../../../api/documentsApi";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import Select from "../../common/form/Select";

import { getContentSize } from "../../../lib";
import IconButton from "../../common/IconButton";
import CloseIcon from "../../icons/CloseIcon";
import CheckboxField from "../../common/form/CheckboxField";
import validate, { validateRequired } from "../../../lib/formValidate";
import classNames from "classnames";

import "../../../styles/components/management/objectSettings/ObjectDocumentUploadDetailsFields.scss";
import useFetchFacilityObjectWithFacilityUnits from "../../../hooks/messages/useFetchFacilityObjectWithFacilityUnits";

export default function ObjectDocumentUploadDetailsFields({
  fields,
  meta,
}: FieldArrayRenderProps<Values, HTMLElement>) {
  const form = useForm();
  const { customerToken = "" } = useParams();
  const { facilityObject } = useFetchFacilityObjectWithFacilityUnits();
  const { data = [] } = useGetOnlineDocumentCategoriesQuery({
    customerToken,
  });
  const categories = useMemo(
    () =>
      data.map((category) => ({
        label: category.name,
        value: category.id,
      })),

    [data],
  );
  const values = fields.value as unknown as Values;
  const { submitError } = meta;

  return fields.map((name, index) => {
    const file = values.at(index)?.file;
    const hasError = submitError && submitError.at(index) !== null;
    return (
      <div
        key={name}
        className={classNames("object-document-upload-details-fields", {
          hasError,
        })}
        data-testid="object-document-upload-details-fields"
      >
        <div className="object-document-upload-details-fields-header">
          <span className="object-document-upload-details-fields-header-name">
            {file?.name}
          </span>
          <span className="object-document-upload-details-fields-header-size">
            {getContentSize(file?.size || 0).count.toFixed(1)}{" "}
            {getContentSize(file?.size || 0).size}
          </span>
          <IconButton
            icon={CloseIcon}
            onClick={() => {
              fields.remove(index);
              form.mutators.fileRemove(index);
            }}
          />
        </div>
        <Field
          name={`${name}.category`}
          component={Select}
          label="Kategorie"
          options={categories}
          defaultOption={"Kategorie auswählen"}
          required
          validate={validate(
            validateRequired("Bitte wählen Sie eine Kategorie aus!"),
          )}
        />
        <Field
          name={`${name}.description`}
          component={TextInput}
          label="Beschreibung"
          placeholder="Füge eine optionale Beschreibung hinzu"
        />
        <span className="object-document-upload-details-fields-permissions-label">
          Berechtigungen
        </span>
        <div className="object-document-upload-details-fields-permissions">
          <Field
            type="checkbox"
            name={`${name}.${OnlineDocumentPermissionType.TenantCanSee}`}
            component={CheckboxField}
            label={
              facilityObject.facilityObjectType ===
              FacilityObjectType.ResidentialCommunityOfOwners
                ? "Eigentümer"
                : "Mieter"
            }
          />
          <Field
            type="checkbox"
            name={`${name}.${OnlineDocumentPermissionType.ObjectOwnerCanSee}`}
            component={CheckboxField}
            label="Hauseigentümer"
          />
          <Field
            type="checkbox"
            name={`${name}.${OnlineDocumentPermissionType.ServiceProviderCanSee}`}
            component={CheckboxField}
            label="Dienstleister"
          />
        </div>
        {hasError && (
          <div
            className="object-document-upload-details-fields-error"
            role="alert"
          >
            {submitError.at(index)}
          </div>
        )}
      </div>
    );
  });
}

type Values = Array<{
  file: File;
  category: string;
  description: string;
  [OnlineDocumentPermissionType.TenantCanSee]: boolean;
  [OnlineDocumentPermissionType.ObjectOwnerCanSee]: boolean;
  [OnlineDocumentPermissionType.ServiceProviderCanSee]: boolean;
}>;
