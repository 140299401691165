import React from "react";
import { IconProps } from "../../types";

export default function UserIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
      data-testid="user-icon"
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M22.333 23a1 1 0 1 1-2 0v-1.667A2.333 2.333 0 0 0 18 19h-6.667A2.333 2.333 0 0 0 9 21.333V23a1 1 0 0 1-2 0v-1.667A4.333 4.333 0 0 1 11.333 17H18a4.333 4.333 0 0 1 4.333 4.333V23zm-7.666-7.333a4.333 4.333 0 1 1 0-8.667 4.333 4.333 0 0 1 0 8.667zm0-2a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667z"
        fillRule="evenodd"
      />
    </svg>
  );
}
