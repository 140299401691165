import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { Field, Form } from "react-final-form";
import { usePostUserAccountDeleteMutation } from "../../api/userApi";
import TextInput from "../common/form/TextInput";
import InfoText from "./InfoText";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import { useNavigate } from "react-router-dom";

export default function AccountDeleteForm(): JSX.Element {
  const { user } = useGetUsersMyProfile();
  const [isDialogSuccessOpen, setDialogSuccessOpen] = useState(false);
  const navigate = useNavigate();
  const [postUserAccountDelete, { data, isLoading }] =
    usePostUserAccountDeleteMutation();

  const handleSubmitDeleteAccount = async (values: Record<string, string>) => {
    setDialogSuccessOpen(true);
    await postUserAccountDelete({
      userSid: user.sid,
      password: values.password,
    });
  };

  useEffect(() => {
    if (data?.succeeded) {
      navigate("/goodbye", { replace: true });
    }
  }, [data?.succeeded]);

  return (
    <Form
      onSubmit={(values) => handleSubmitDeleteAccount(values)}
      render={({ handleSubmit, form, submitSucceeded, values }) => {
        useEffect(() => {
          form.reset();
        }, [submitSucceeded]);

        useEffect(() => {
          if (values.password !== undefined) {
            setDialogSuccessOpen(false);
          }
        }, [values]);

        return (
          <form
            onSubmit={handleSubmit}
            className="user-settings-account-container-form"
          >
            <div className="user-settings-account-container-form-fields">
              <Field
                name="password"
                type="password"
                component={TextInput}
                rows={1}
                label="Derzeitiges Passwort:"
                placeholder="Aktuelles Passwort eingeben"
              />

              {!data?.succeeded &&
                isDialogSuccessOpen &&
                !isLoading &&
                data?.errorDescription.length !== 0 && (
                  <InfoText isError={true} textError={data?.errorDescription} />
                )}
              <Button
                red
                label="Account löschen"
                className="user-settings-account-container-button"
              />
            </div>
          </form>
        );
      }}
    />
  );
}
