import "../../styles/components/bulletinBoard/PostMenu.scss";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Fragment, ReactElement, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import { UserRoles } from "../../enums";
import { BulletinBoardPost } from "../../types";
import MenuDotsVertical from "../icons/MenuDotsVertical";

export default function PostMenu({
  post,
  handleEdit,
  handleDelete,
  handleReport,
  handlePin,
  handleUpdateTenantCommenting,
}: Props): ReactElement {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  const handleClose = useCallback(() => setOpen(false), []);
  const userRole = useCustomerRoles();

  const { canDelete, canEdit, pinned, tenantsCanComment } = post;

  const handleEditClick = useCallback(() => {
    handleClose();
    handleEdit && handleEdit();
  }, [handleEdit]);

  const handleDeleteClick = useCallback(() => {
    handleClose();
    handleDelete && handleDelete();
  }, [handleEdit]);

  const handlePinClicked = useCallback(() => {
    handleClose();
    handlePin && handlePin();
  }, [handlePin]);

  const handleUpdateTenantCommentingClicked = useCallback(() => {
    handleClose();
    handleUpdateTenantCommenting && handleUpdateTenantCommenting();
  }, [handleUpdateTenantCommenting]);

  useOnClickOutside(ref, handleClose);

  return (
    <div className="bulletin-board-post-list-item-menu" ref={ref}>
      <div
        onClick={toggleOpen}
        data-testid="bulletin-board-post-list-item-menu-action"
        role="button"
        aria-label="Optionen"
      >
        <MenuDotsVertical className="bulletin-board-post-list-item-menu-action" />
      </div>
      <ul
        className={classNames("bulletin-board-post-list-item-menu-list", {
          open,
        })}
        role="menu"
      >
        {canEdit && (
          <li
            className="bulletin-board-post-list-item-menu-list-item"
            role="menuitem"
            onClick={handleEditClick}
          >
            Beitrag bearbeiten
          </li>
        )}
        {canDelete && (
          <li
            className="bulletin-board-post-list-item-menu-list-item"
            role="menuitem"
            onClick={handleDeleteClick}
          >
            Beitrag löschen
          </li>
        )}
        {userRole.includes(UserRoles.Employee) && (
          <Fragment>
            <li
              className="bulletin-board-post-list-item-menu-list-item"
              role="menuitem"
              onClick={handlePinClicked}
            >
              {pinned ? "Fixierung aufheben" : "Beitrag fixieren"}
            </li>
            <li
              className="bulletin-board-post-list-item-menu-list-item"
              role="menuitem"
              onClick={handleUpdateTenantCommentingClicked}
            >
              {tenantsCanComment
                ? "Kommentieren deaktivieren"
                : "Kommentare zulassen"}
            </li>
          </Fragment>
        )}
        <li
          className="bulletin-board-post-list-item-menu-list-item"
          role="menuitem"
          onClick={handleReport}
        >
          Beitrag melden
        </li>
      </ul>
    </div>
  );
}

type Props = {
  post: BulletinBoardPost;
  handleDelete?: () => void;
  handleEdit?: () => void;
  handleReport: () => void;
  handlePin: () => void;
  handleUpdateTenantCommenting: () => void;
};
