import React from "react";
import Button from "../../common/Button";

import {
  PropositionVoteType,
  PropositionVotingType,
  UserRoles,
} from "../../../enums";

import PropositionVotingWeightInfo from "./PropositionVotingWeightInfo";

import "../../../styles/components/meetings/proposition/PropositionVoting.scss";
import { useGetVotingWeightQuery } from "../../../api/propositionApi";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { useParams } from "react-router-dom";
import InformationBox from "../../common/InformationBox";
import { useDispatch } from "react-redux";
import { updatePropositionVoteInstructions } from "../../../slices/propositionInstructionsVotingSlice";
import useGetInstructionsState from "../../../hooks/meetings/useGetInstructionsState";

export default function PropositionInstructionsVoting({
  id,
  meetingId,
  agendaItemId,
  votingType,
  isAgendaItemPage,
}: Props): JSX.Element {
  const customerRoles = useCustomerRoles();
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const {
    data: propositionVotingWight,
    isSuccess,
    isLoading,
  } = useGetVotingWeightQuery(
    {
      customerToken,
      propositionId: id,
      facilityObjectId,
      meetingId,
      agendaItemId,
    },
    {
      skip: customerRoles.includes(UserRoles.Employee),
    },
  );
  const isShareOfVotesNull =
    propositionVotingWight?.qualificationValue.value === 0;

  const { proposition } = useGetInstructionsState(undefined, id);

  const dispatch = useDispatch();

  return (
    <div
      className="agenda-item-proposition-voting"
      data-testid="proposition-voting"
    >
      {isAgendaItemPage && (
        <PropositionVotingWeightInfo
          propositionId={id}
          isSuccess={isSuccess}
          data={propositionVotingWight}
          isLoading={isLoading}
        />
      )}

      {!isShareOfVotesNull && votingType === PropositionVotingType.Detailed && (
        <div className="agenda-item-proposition-voting-action">
          <Button
            label="Ja"
            green={
              proposition?.voteType === PropositionVoteType.Yes || !proposition
            }
            value={PropositionVoteType.Yes}
            onClick={() =>
              dispatch(
                updatePropositionVoteInstructions({
                  propositionId: id,
                  voteType: PropositionVoteType.Yes,
                }),
              )
            }
          />
          <Button
            label="Nein"
            red={
              proposition?.voteType === PropositionVoteType.No || !proposition
            }
            value={PropositionVoteType.No}
            onClick={() =>
              dispatch(
                updatePropositionVoteInstructions({
                  propositionId: id,
                  voteType: PropositionVoteType.No,
                }),
              )
            }
          />
          <Button
            label="Enthaltung"
            grey={
              proposition?.voteType === PropositionVoteType.Abstention ||
              !proposition
            }
            value={PropositionVoteType.Abstention}
            onClick={() =>
              dispatch(
                updatePropositionVoteInstructions({
                  propositionId: id,
                  voteType: PropositionVoteType.Abstention,
                }),
              )
            }
          />
        </div>
      )}
      {isShareOfVotesNull && (
        <InformationBox text="Sie sind bei dieser Abstimmung nicht stimmberechtigt." />
      )}
    </div>
  );
}

type Props = {
  id: string;
  meetingId: string;
  agendaItemId: string;
  votingType: PropositionVotingType;
  isAgendaItemPage?: boolean;
};
