import React, { ReactElement, useEffect } from "react";
import "../../../styles/components/meetings/inivitation/InvitationInstructions.scss";
import { AgendaItem } from "../../../types";
import AgendaItemDetails from "../agendaItem/AgendaItemDetails";
import { useDispatch } from "react-redux";
import { setPropositionVoteInstructions } from "../../../slices/propositionInstructionsVotingSlice";
import AgendaListForInstructionsDialog from "../AgendaListForInstructionsDialog";
import { useGetVoteInstructionsQuery } from "../../../api/meetingApi";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../icons/LoadingSpinner";

export default function VotingInstructionsDialogDescription({
  agendaItems,
  isPage,
  isLoading,
  setPage,
  itemWithPropositions,
}: Props): ReactElement {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const dispatch = useDispatch();
  const { data } = useGetVoteInstructionsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });

  useEffect(() => {
    if (data) {
      const result = data.instructions.map((item) => {
        return {
          propositionId: item.propositionId,
          voteType: item.vote,
        };
      });
      dispatch(setPropositionVoteInstructions(result));
    }
  }, [data]);

  return (
    <section className="invitation-instructions-wrapper">
      {isLoading && <LoadingSpinner />}
      {isPage === 0 && !isLoading && (
        <AgendaListForInstructionsDialog
          agendaItems={agendaItems}
          itemWithPropositions={itemWithPropositions}
          instructions={true}
          setPage={setPage}
        />
      )}
      {isPage > 0 && !isLoading && isPage <= itemWithPropositions.length && (
        <div className="etv-agenda-item">
          <AgendaItemDetails item={itemWithPropositions[isPage - 1]} />
        </div>
      )}
      {isPage > itemWithPropositions.length && (
        <AgendaListForInstructionsDialog
          agendaItems={agendaItems}
          itemWithPropositions={itemWithPropositions}
          instructions={true}
          setPage={setPage}
        />
      )}
    </section>
  );
}

type Props = {
  agendaItems: AgendaItem[];
  isPage: number;
  isLoading: boolean;
  setPage: (page: number) => void;
  itemWithPropositions: AgendaItem[];
};
