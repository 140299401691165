import { SyntheticEvent, useCallback } from "react";
import { SortState } from "../../types";
import useLocalStorage from "../useLocalStorage";

export default function useTableSort(initialSort: SortState, name: string) {
  const [sort, setSort] = useLocalStorage(name, initialSort);

  const handleSort = useCallback(
    (e: SyntheticEvent) => {
      const sortBy = e.currentTarget.getAttribute("aria-label") || "";
      const currentSortDirection =
        e.currentTarget.getAttribute("aria-sort") || "none";

      const sortState: SortState = {
        sortBy,
        sortDirection:
          currentSortDirection === "none"
            ? "descending"
            : currentSortDirection === "descending"
              ? "ascending"
              : "none",
      };

      setSort(sortState);
    },
    [sort, setSort],
  );

  return {
    handleSort,
    sort: sort as SortState,
  };
}
