import { useParams } from "react-router-dom";
import { useGetParticipantsQuery } from "../../api/participantsApi";
import { useSynchronisation } from "../useSynchronisation";
import { useEffect } from "react";
import { SynchronisationMethod } from "../../enums";
import { Participant } from "../../types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export default function useFetchParticipants(
  relatedToMeetingId = "",
): FetchParticipants {
  const {
    customerToken = "",
    meetingId = relatedToMeetingId,
    facilityObjectId = "",
  } = useParams();

  const {
    data = [],
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetParticipantsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });

  const { connection } = useSynchronisation();

  useEffect(() => {
    if (connection) {
      connection.on(SynchronisationMethod.ParticipantsChanged, refetch);
    }
    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.ParticipantsChanged);
      }
    };
  }, [connection]);

  return {
    participants: data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
}

type FetchParticipants = {
  participants: Participant[];
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  refetch: () => void;
};
