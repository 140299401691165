import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";

import "../../../styles/components/pages/CentralSettingsPage.scss";
import Button from "../../common/Button";
import {
  useGetSettingsQuery,
  usePostAutoSetCorrespondenceToHomecaseForNewRegisteredUserMutation,
  usePostProhibitDeactivationUserSettingReceiveEmailNotificationMutation,
} from "../../../api/customerApi";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import SwitchInputField from "../../common/form/SwitchInputField";
import InfoText from "../../userSettings/InfoText";

export default function CentralSettingsPage(): JSX.Element {
  const { customerToken = "" } = useParams();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const { data, isLoading } = useGetSettingsQuery({ customerToken });
  const [
    postAutoSetCorrespondenceToHomecaseForNewRegisteredUser,
    {
      isSuccess: PostCorrespondenceToHomecaseForNewRegisteredUseIsSuccess,
      isError: PostCorrespondenceToHomecaseForNewRegisteredUseIsError,
    },
  ] = usePostAutoSetCorrespondenceToHomecaseForNewRegisteredUserMutation();

  const [
    postPostProhibitDeactivationUserSettingReceiveEmailNotificationMutation,
    {
      isSuccess: PostEmailNotificationMutationIsSuccess,
      isError: PostEmailNotificationMutationIsError,
    },
  ] = usePostProhibitDeactivationUserSettingReceiveEmailNotificationMutation();

  const postSettings = useCallback(
    async (values: Record<string, boolean | undefined>) => {
      setShowSuccessDialog(true);
      setShowErrorDialog(true);
      if (
        !isLoading &&
        values.push !== undefined &&
        values.push !==
          data?.autoSetCorrespondenceDispatchingMethodToHomecaseForNewRegisteredUser
      ) {
        await postAutoSetCorrespondenceToHomecaseForNewRegisteredUser({
          customerToken,
          value: values.push,
        });
      }
      if (
        !isLoading &&
        values.emailNotification !== undefined &&
        values.emailNotification !==
          data?.prohibitDeactivationUserSettingReceiveEmailNotification
      ) {
        await postPostProhibitDeactivationUserSettingReceiveEmailNotificationMutation(
          {
            customerToken,
            value: values.emailNotification,
          },
        );
      }
    },
    [isLoading, data],
  );

  const isPostError =
    PostCorrespondenceToHomecaseForNewRegisteredUseIsError ||
    PostEmailNotificationMutationIsError;
  const isPostSuccess =
    PostEmailNotificationMutationIsSuccess ||
    PostCorrespondenceToHomecaseForNewRegisteredUseIsSuccess;

  const infoPushNotification = (
    <span className="email-notifications-info-text">
      i
      <span className="email-notifications-info-text-hint">
        <ul className="email-notifications-info-text-hint-list">
          <li>
            Dafür holen Sie die Zustimmung der Endbenutzer z.B. in den
            Nutzungsbedingungen Ihrer Hausverwaltung oder in anderem Vertrag.
          </li>
          <li>
            Die Versandart bei allen bestehenden Benutzern auf 'HOMECASE'
            (online) setzen: möglich mit einem on-premise Skript, verfügbar auf
            Anfrage bei der DOMUS.
          </li>
        </ul>
      </span>
    </span>
  );

  const infoEmailNotification = (
    <span className="email-notifications-info-text">
      i
      <span className="email-notifications-info-text-hint">
        <ul className="email-notifications-info-text-hint-list">
          <li>
            Dafür holen Sie die Zustimmung der Endbenutzer z.B. in den
            Nutzungsbedingungen Ihrer Hausverwaltung oder in anderem Vertrag.
          </li>
          <li>
            Beim Aktivieren dieser Einstellung, wird die E-Mail-Benachrichtigung
            für alle HOMECASE-Benutzer, die noch keine E-Mail-Benachrichtigung
            eingestellt haben, einmalig automatisch aktiviert (mit der
            Häufigkeit 'Täglich').
          </li>
        </ul>
      </span>
    </span>
  );
  return (
    <div className="central-settings-container">
      <Helmet>
        <title> Zentrale Einstellungen - Homecase</title>
      </Helmet>
      <PageHeader title="Zentrale Einstellungen" />
      <section className="central-settings-container-info-wrapper">
        <section className="central-settings-container-sections">
          <section className="electronic-communications-container">
            <div className="electronic-communications-info">
              <h3>Elektronische Kommunikation bevorzugen</h3>
            </div>
            <div className="electronic-communications-info">
              <Form
                onSubmit={postSettings}
                initialValues={{
                  push: data?.autoSetCorrespondenceDispatchingMethodToHomecaseForNewRegisteredUser,
                  emailNotification:
                    data?.prohibitDeactivationUserSettingReceiveEmailNotification,
                }}
                render={({ handleSubmit, dirty }) => {
                  useEffect(() => {
                    if (dirty) {
                      setShowSuccessDialog(false);
                      setShowErrorDialog(false);
                    }
                  }, [dirty]);

                  return (
                    <form onSubmit={handleSubmit} role="form">
                      <Field
                        name="push"
                        component={SwitchInputField}
                        type="checkbox"
                        id="push"
                        infoText="Beim Registrieren eines neuen HOMECASE Benutzers, wird seine Versandart in ERP/CRM automatisch auf 'HOMECASE' gesetzt."
                        label="Für neu registrierte Benutzer die Versandart auf HOMECASE setzen"
                        infoNotification={infoPushNotification}
                      />

                      <Field
                        name="emailNotification"
                        component={SwitchInputField}
                        type="checkbox"
                        id="email-notification"
                        infoText="Wenn diese Einstellung aktiv ist, dürfen die HOMECASE Benutzer ihre Einstellung für E-Mail-Benachrichtigungen nicht ausschalten."
                        label="E-Mail-Benachrichtigung nicht ausschalten"
                        infoNotification={infoEmailNotification}
                      />

                      <Button
                        lightblue
                        label="Speichern"
                        role="button"
                        className="electronic-communications-info-save"
                        disabled={!dirty}
                      />
                      {isPostSuccess && showSuccessDialog && !isPostError && (
                        <InfoText
                          isSuccess={true}
                          textSuccess="Ihre Einstellungen wurden gespeichert."
                        />
                      )}
                    </form>
                  );
                }}
              />
              {isPostError && showErrorDialog && (
                <InfoText
                  isError={true}
                  textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
                />
              )}
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
