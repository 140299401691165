import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { BulletinBoard, FacilityObject } from "../types";
import {
  useDisableBulletinBoardTenantCommentingMutation,
  useEnableBulletinBoardTenantCommentingMutation,
} from "../api/bulletinBoardApi";

export default function useToggleTenantCanComment(
  facilityObject: FacilityObject,
  data?: BulletinBoard,
) {
  const { customerToken = "" } = useParams();
  const facilityObjectId = facilityObject.id.toString();
  const [enablePostCommenting, { isLoading: enableIsLoading }] =
    useEnableBulletinBoardTenantCommentingMutation();
  const [disablePostCommenting, { isLoading: disableIsLoading }] =
    useDisableBulletinBoardTenantCommentingMutation();
  const toggleTenantsCanComment = useCallback(() => {
    if (data) {
      if (data.tenantsCanComment) {
        return disablePostCommenting({
          customerToken,
          facilityObjectId,
        });
      }
      return enablePostCommenting({
        customerToken,
        facilityObjectId,
      });
    }
  }, [data, facilityObjectId]);

  return {
    toggleTenantsCanComment,
    isLoading: enableIsLoading || disableIsLoading,
  };
}
