import { NavigationItem } from "../../types";
import "../../styles/components/navigation/NavigationList.scss";
import NavigationListSubItem from "./NavigationListSubItem";
import classNames from "classnames";

export default function NavigationSubItemsList({
  items,
  isCollapsed,
  setIsCollapsed,
}: Props): JSX.Element {
  return (
    <ul className={classNames("navigation-list", { isCollapsed })}>
      {items.map((item, key) => (
        <NavigationListSubItem
          item={item}
          key={key}
          setIsCollapsed={setIsCollapsed}
        />
      ))}
    </ul>
  );
}

type Props = {
  items: NavigationItem[];
  isCollapsed?: boolean;
  parent: NavigationItem;
  setIsCollapsed: (item: boolean) => void;
};
