import Skeleton from "react-loading-skeleton";
import React from "react";

export default function InvitationSkeleton() {
  return (
    <dl
      className="meeting-invitation-list"
      data-testid="meeting-invitation-skeleton"
    >
      <dt className="meeting-invitation-list-title">Zeitpunkt:</dt>
      <dd className="meeting-invitation-list-content">
        <Skeleton width={100} />
      </dd>

      <dt className="meeting-invitation-list-title">Online:</dt>
      <dd className="meeting-invitation-list-content">
        <Skeleton width={120} />
      </dd>
      <dt className="meeting-invitation-list-title">oder vor Ort:</dt>
      <dd className="meeting-invitation-list-content">
        <Skeleton width={100} />
      </dd>
      <dt className="meeting-invitation-list-title">Tagesordnung:</dt>
      <dd className="meeting-invitation-list-content">
        <Skeleton width={150} />
      </dd>
      <dt className="meeting-invitation-list-title">Abstimmen:</dt>
      <dd className="meeting-invitation-list-content">
        <Skeleton width={100} />
      </dd>
    </dl>
  );
}
