import { IconProps } from "../../types";

export default function PdfFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="pdf-file-icon"
    >
      <defs>
        <rect id="b" width="26" height="32" x="5" y="2" rx="1" />
        <filter
          id="a"
          width="111.5%"
          height="112.5%"
          x="-5.8%"
          y="-4.7%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="27"
          height="33"
          x="4.5"
          y="1.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <path
          fill="#DC1A44"
          fillRule="nonzero"
          d="M25.634 20.667c-.39.115-.963.128-1.578.04a7.53 7.53 0 0 1-1.993-.595c1.179-.172 2.094-.12 2.876.158.185.066.49.242.695.397zm-6.578-1.082l-.142.039c-.317.086-.626.17-.923.245l-.402.102c-.806.204-1.631.413-2.446.661.31-.747.597-1.501.879-2.24.209-.546.421-1.104.642-1.655.112.184.228.369.35.554.552.842 1.247 1.62 2.042 2.294zm-2.052-8.42c.052.921-.147 1.808-.438 2.658-.36-1.052-.527-2.213-.078-3.15.115-.24.21-.369.27-.436.095.146.22.473.246.928zm-4.212 11.672c-.202.36-.408.699-.62 1.018-.509.768-1.342 1.59-1.77 1.59-.043 0-.094-.007-.168-.085-.048-.05-.056-.087-.053-.136.014-.282.388-.785.93-1.251a9.154 9.154 0 0 1 1.68-1.136zm14.206-2.13c-.066-.94-1.649-1.544-1.664-1.55-.612-.217-1.277-.322-2.033-.322-.809 0-1.68.117-2.8.379A9.747 9.747 0 0 1 18 16.644c-.284-.433-.54-.865-.762-1.286.543-1.3 1.033-2.696.944-4.26C18.11 9.842 17.544 9 16.775 9c-.527 0-.982.39-1.351 1.163-.66 1.375-.486 3.135.514 5.235-.36.847-.695 1.724-1.019 2.574-.403 1.056-.819 2.145-1.287 3.182-1.313.52-2.391 1.15-3.29 1.923-.59.506-1.3 1.28-1.34 2.087-.02.38.11.728.375 1.008.282.296.635.453 1.024.453 1.284 0 2.52-1.765 2.754-2.118.472-.711.914-1.505 1.347-2.42 1.09-.393 2.251-.687 3.377-.972l.403-.102c.303-.077.618-.162.941-.25.342-.093.694-.19 1.051-.28 1.156.735 2.4 1.215 3.612 1.39 1.02.149 1.928.063 2.542-.257.552-.288.583-.732.57-.91z"
        />
      </g>
    </svg>
  );
}
