import React from "react";
import { IconProps } from "../../../types";

export default function ServicesPlayground({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 27)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M79.923 36.19a3.333 3.333 0 0 0-3.974-2.533l-9.893 2.187a.84.84 0 0 0-.647.697 17.72 17.72 0 0 1-.973 5.13.833.833 0 0 0 .94 1.153l12-2.666a3.333 3.333 0 0 0 2.547-3.967zM14.668 49.001a.83.83 0 0 0-.81-.667l-4.137-.026-7.107 1.573a3.334 3.334 0 0 0 1.44 6.51l11-2.43a.84.84 0 0 0 .574-1.173 14.467 14.467 0 0 1-.96-3.787zM51.746 39.918a.833.833 0 0 0-1-.694l-22.594 5a.833.833 0 0 0-.637.977c.438 1.556.71 3.154.813 4.767a.837.837 0 0 0 1 .82l6.334-1.397a.833.833 0 0 1 1.013.83v8.957h-3.34a3.333 3.333 0 1 0 0 6.667H46.67a3.333 3.333 0 0 0 0-6.667h-3.334v-10.8a.837.837 0 0 1 .667-.814l9.6-2.123a.83.83 0 0 0 .37-1.44 6.667 6.667 0 0 1-2.226-4.083z" />
        <circle cx="10.001" cy="20" r="6.667" />
        <path d="M22.335 53.268a3.333 3.333 0 0 0 2.613-3.92l-1.13-5.667a6.687 6.687 0 0 0-6.533-5.353h-3.95v-3.334a3.333 3.333 0 0 0-6.667 0v6.667A3.333 3.333 0 0 0 10 44.994h6.587c.396 0 .738.279.817.667l1 5a3.37 3.37 0 0 0 3.93 2.607z" />
        <circle cx="70.003" cy="6.667" r="6.667" />
        <path d="M57.669 42.25c.22.044.443.065.667.064a3.333 3.333 0 0 0 3.266-2.666l1.027-7.26a.833.833 0 0 1 .827-.717h6.547a3.333 3.333 0 0 0 3.333-3.334V21.67a3.333 3.333 0 1 0-6.667 0v3.334h-3.937a6.687 6.687 0 0 0-6.533 5.353l-1.13 7.98a3.333 3.333 0 0 0 2.6 3.914z" />
      </g>
    </svg>
  );
}
