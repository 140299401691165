import React from "react";
import { IconProps } from "../../../types";

export default function ServicesMove({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 21)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M27.5 11.667a.83.83 0 0 0 .833.833h17.844a.833.833 0 0 0 .59-1.423l-9.614-9.614A5.037 5.037 0 0 0 33.617 0h-5.284a.83.83 0 0 0-.833.833v10.834zM21.667 12.5c.46 0 .833-.373.833-.833V.833A.833.833 0 0 0 21.667 0H16.38a5.037 5.037 0 0 0-3.537 1.463L3.23 11.077a.833.833 0 0 0 .59 1.423h17.847zM21.667 46.667A8.333 8.333 0 0 1 30 38.333h19.167A.83.83 0 0 0 50 37.5V18.333a.833.833 0 0 0-.833-.833H.833a.83.83 0 0 0-.833.833v30a5 5 0 0 0 5 5h15.833a.83.83 0 0 0 .834-.833v-5.833z" />
        <path d="M31.667 43.333a5 5 0 0 0-5 5v23.334c0 .92.746 1.666 1.666 1.666h.874c.43 0 .79-.328.83-.756a8.333 8.333 0 0 1 16.596 0c.04.428.4.756.83.756h5.077c.43 0 .79-.328.83-.756a8.333 8.333 0 0 1 16.597 0c.04.428.4.756.83.756H75a5 5 0 0 0 5-5V60c-.007-6.9-5.6-12.493-12.5-12.5h-3.333a1.813 1.813 0 0 0-.19 0 .833.833 0 0 1-.87-.597 5 5 0 0 0-4.774-3.57H31.667zm43.096 14.79a.833.833 0 0 1-.806 1.044H67.5a.837.837 0 0 1-.833-.834v-5c0-.46.373-.833.833-.833a7.51 7.51 0 0 1 7.263 5.623z" />
        <circle cx="61.667" cy="73.333" r="5" />
        <circle cx="38.333" cy="73.333" r="5" />
      </g>
    </svg>
  );
}
