import { ActivityDocument } from "../../types";
import { useParams } from "react-router-dom";
import { useGetActivityDocumentQuery } from "../../api/activitiesApi";
import useLightbox from "../useLightbox";
import { useCallback, useEffect, useState } from "react";

export default function useOpenActivityDocument(document: ActivityDocument) {
  const { customerToken = "" } = useParams();
  const [file, setFile] = useState<File | null>(null);
  const { addFile, toggleOpen } = useLightbox();
  const { data, isFetching } = useGetActivityDocumentQuery({
    customerToken,
    activityId: document.activityId,
    documentId: document.documentId,
  });
  const type = data?.type || "";

  useEffect(() => {
    if (data && !file) {
      const documentFile = new File([data], document.originalFileName, {
        type: data.type,
        lastModified: new Date(document.modifyDateUTC).getTime(),
      });
      setFile(documentFile);
      addFile(documentFile);
    }
  }, [data, file]);

  const open = useCallback(() => {
    if (file) {
      toggleOpen(file);
    }
  }, [file, toggleOpen]);

  return {
    file,
    type,
    isFetching,
    open,
  };
}
