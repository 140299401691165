import React from "react";
import {
  QualificationVotingRule,
  QualificationVotingValues,
} from "../../../types";

export default function PropositionVotingResultDetails({
  qualificationVotingRule,
  qualificationValues,
  totalEligibleVotes,
  totalAttendedVotes,
}: Props): JSX.Element {
  return (
    <table className="agenda-item-proposition-voting-result-details">
      <tbody>
        <tr>
          <td className="agenda-item-proposition-voting-result-details-title">
            Stimmrecht:
          </td>
          <td>{qualificationVotingRule.name}</td>
        </tr>
        <tr>
          <td className="agenda-item-proposition-voting-result-details-title">
            Teilgenommen:
          </td>
          <td>
            {`${totalAttendedVotes} / ${totalEligibleVotes} `}
            Eigentümer
            {` (${qualificationValues.totalAttendeesValues} / ${qualificationValues.totalEligibleValues})`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

type Props = {
  qualificationVotingRule: QualificationVotingRule;
  qualificationValues: QualificationVotingValues;
  totalAttendedVotes: number;
  totalEligibleVotes: number;
};
