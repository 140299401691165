import React, { ReactElement } from "react";
import "../../styles/components/management/objectSettings/ServiceProviderListItem.scss";
import Skeleton from "react-loading-skeleton";

export default function StakeholdersInfoListItemSkeleton(): ReactElement {
  return (
    <div role="row" className="service-provider-list-item">
      <div className="name-wrapper" data-testid="stakeholder-item">
        <div role="cell">
          <div>
            <Skeleton style={{ width: "150px" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
