import { createSlice } from "@reduxjs/toolkit";
import { PROPOSITION_INSTRUCTIONS_VOTING_PATH } from "../reducerPaths";
import { MyVoteInstructionsInfo } from "../types";

type InitialStateType = {
  items: MyVoteInstructionsInfo[];
};
export const initialState: InitialStateType = {
  items: [],
};

const propositionInstructionsVotingSlice = createSlice({
  name: PROPOSITION_INSTRUCTIONS_VOTING_PATH,
  initialState,
  reducers: {
    setPropositionVoteInstructions: (state, action) => {
      state.items = action.payload;
    },
    updatePropositionVoteInstructions: (state, action) => {
      const isExisting = state.items.some(
        (propositionItem) =>
          propositionItem.propositionId === action.payload.propositionId,
      );

      if (!isExisting) {
        state.items.push(action.payload);
      }

      state.items = state.items.map((propositionItem) =>
        propositionItem.propositionId === action.payload.propositionId
          ? action.payload
          : propositionItem,
      );
    },
  },
});

export const {
  setPropositionVoteInstructions,
  updatePropositionVoteInstructions,
} = propositionInstructionsVotingSlice.actions;
export default propositionInstructionsVotingSlice.reducer;
