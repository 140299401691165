import { Document } from "../../types";
import IconDocument from "../dashboard/IconDocument";
import { getContentSize } from "../../lib";
import moment from "moment";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function DocumentListItem({
  document,
  inNotifications,
  showCategory = true,
  showDate = true,
  actionComponent,
}: Props) {
  return (
    <section className="documents-facility-object-item">
      <Link
        to={`./${document.documentId}/${
          document.originalFileName.startsWith("#")
            ? document.originalFileName.substring(1)
            : document.originalFileName
        }`}
        className={classNames("documents-facility-object-document", {
          unread: inNotifications,
        })}
        key={document.documentId}
        id={document.documentId}
        data-testid="document-list-item"
      >
        <div className={"documents-facility-object-document-icon"}>
          <IconDocument type={document.contentType} width={24} height={24} />
        </div>
        <div className="documents-facility-object-document-content">
          <div className="documents-facility-object-document-name">
            {document.originalFileName}
            {document.description && <span>{document.description}</span>}
          </div>
          <div
            className={
              "documents-facility-object-document-personal-category-wrapper"
            }
          >
            {document.isSharedForCurrentUser && (
              <div className="documents-facility-object-document-personal">
                Persönlich
              </div>
            )}
            {document.category && showCategory && (
              <div className="documents-facility-object-document-category">
                {document.category}
              </div>
            )}
          </div>
          {document.contentSize ? (
            <div className="documents-facility-object-document-info">
              {getContentSize(document.contentSize).count.toFixed(1)}{" "}
              {getContentSize(document.contentSize).size}
            </div>
          ) : (
            <div className="documents-facility-object-document-info"></div>
          )}
          {showDate && (
            <div className="documents-facility-object-document-cat">
              <div className="documents-facility-object-document-info">
                {moment.utc(document.createDateUTC).local().format("L")}
              </div>
            </div>
          )}
        </div>
      </Link>
      {actionComponent && (
        <div className="documents-facility-object-document-actions">
          {actionComponent}
        </div>
      )}
    </section>
  );
}

type Props = {
  document: Document;
  inNotifications?: boolean;
  showCategory?: boolean;
  showDate?: boolean;
  actionComponent?: ReactNode;
};
