import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetVotingResultsForPropositionsQuery } from "../../../api/propositionApi";
import PdfDocument from "./PdfDocument";
import useGetAgendaItemWithDetailsForPdfDocument from "../../../hooks/meetings/useGetAgendaItemWithDetailsForPdfDocument";
import useGetMeetingInfoForPdfDocument from "../../../hooks/meetings/useGetMeetingInfoForPdfDocument";
import useGetLogoForPdfDocument from "../../../hooks/meetings/useGetLogoForPdfDocument";

export default function PdfCreateComponent() {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const { allPropositionsWithDetails, allItemsPropositionsWithoutDetails } =
    useGetAgendaItemWithDetailsForPdfDocument();
  const { meeting, meetingTitle } = useGetMeetingInfoForPdfDocument();
  const { src } = useGetLogoForPdfDocument();

  const { data: propositionsWithVotingResult, isFetching } =
    useGetVotingResultsForPropositionsQuery({
      customerToken,
      meetingId,
      facilityObjectId,
      propositions: allPropositionsWithDetails,
    });

  const propositions = useMemo(() => {
    return propositionsWithVotingResult
      ?.concat(allItemsPropositionsWithoutDetails)
      .sort((a, b) => {
        const x = a.agendaItemNumber;
        const y = b.agendaItemNumber;

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
  }, [propositionsWithVotingResult, allItemsPropositionsWithoutDetails]);

  return (
    <PdfDocument
      propositionsWithVotingResult={propositions}
      meetingTitle={meetingTitle}
      meetingLocation={meeting?.location || ""}
      startDate={meeting?.startDate}
      endDate={meeting?.endDate}
      meetingLeader={meeting?.meetingLeader || ""}
      logoStamp={src}
      isFetching={isFetching}
    />
  );
}
