import moment from "moment";
import { Fragment } from "react";
import { ActivityDocument } from "../../types";
import OtherFile from "../common/files/OtherFile";
import LoadingSpinner from "../icons/LoadingSpinner";
import { PREVIEW_COMPONENTS } from "../../lib/messages";
import useOpenActivityDocument from "../../hooks/messages/useOpenActivityDocument";

import "../../styles/components/messages/MessageDetailsDocumentItem.scss";
import PdfPreviewComponent from "./PdfPreviewComponent";
import classNames from "classnames";

const FILE_SIZE_DIFFER = 32;
const FILE_SIZE_DIFFER_DATE = 20;

export default function MessageDetailsDocumentItem({
  document,
  size,
  showUploadDate,
  isMessageDetailsDocuments,
}: Props): JSX.Element {
  const { open, type, file } = useOpenActivityDocument(document);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const PreviewComponent = PREVIEW_COMPONENTS[type] || OtherFile;

  const style = {
    maxWidth:
      type === "application/pdf" && !isMessageDetailsDocuments
        ? 450
        : `${size}px`,
  };
  const calculatedSize =
    size - (showUploadDate ? FILE_SIZE_DIFFER_DATE : FILE_SIZE_DIFFER);

  const classNameItem = classNames("message-details-document-item", {
    "pdf-preview": type === "application/pdf" && !isMessageDetailsDocuments,
  });

  const classNameItemName = classNames("message-details-document-item-name", {
    "pdf-preview": type === "application/pdf" && !isMessageDetailsDocuments,
  });

  return (
    <div
      className={classNameItem}
      style={style}
      data-testid="message-details-document-item"
      onClick={open}
    >
      {type === "application/pdf" && file && !isMessageDetailsDocuments && (
        <PdfPreviewComponent file={file} downloadable onClick={open} />
      )}
      {file !== null && (
        <Fragment>
          {(type !== "application/pdf" || isMessageDetailsDocuments) && (
            <PreviewComponent
              file={file}
              width={calculatedSize}
              height={calculatedSize}
              downloadable
              onClick={open}
            />
          )}
          {showUploadDate && (
            <span
              className="message-details-document-item-date"
              title={`${moment
                .utc(document.createDateUTC)
                .local()
                .format("LLL")} Uhr`}
            >
              {moment.utc(document.createDateUTC).local().format("lll")} Uhr
            </span>
          )}
          <span className={classNameItemName} title={file.name}>
            {type === "application/pdf" && !isMessageDetailsDocuments && (
              <PreviewComponent file={file} width={20} height={30} />
            )}
            <span className="truncated-text">
              {type === "application/pdf" && !isMessageDetailsDocuments
                ? file.name.slice(0, 50) + (file.name.length > 50 ? "..." : "")
                : file.name.slice(0, 8) + (file.name.length > 8 ? "..." : "")}
            </span>
          </span>
        </Fragment>
      )}
      {file === null && <LoadingSpinner />}
    </div>
  );
}

type Props = {
  document: ActivityDocument;
  size: number;
  showUploadDate?: boolean;
  isMessageDetailsDocuments?: boolean;
};
