import React, { useMemo, useState } from "react";
import "../../styles/components/common/TermsOfUseUsersForm.scss";
import { Field, Form } from "react-final-form";
import { validateRequired } from "../../lib/formValidate";
import Button from "../common/Button";
import TextInput from "../common/form/TextInput";
import SearchIcon from "../icons/SearchIcon";
import { useNavigate, useParams } from "react-router-dom";
import { TermsOfUseUsersPage } from "../../types";
import Select from "../common/form/Select";

export default function TermsOfUseUsersForm({
  filterByName,
  filterByVersion,
  termsOfUseUsersList,
}: Props): JSX.Element {
  const [searchValue, setSearchValue] = useState({ name: "", version: "" });
  const navigate = useNavigate();
  const { customerToken } = useParams();
  const handleSubmitSearchValue = (values: Record<string, string>) => {
    setSearchValue({ name: values.name, version: String(values.version) });
    navigate(`/management/${customerToken}/nutzungsbedingungen/users/1`);
    filterByName(values.name);
    filterByVersion(values.version);
  };

  const versions: string[] = [];

  termsOfUseUsersList?.result.filter((item) => {
    if (item.lastAcceptedVersion !== null) {
      versions.push(item.lastAcceptedVersion);
    }
  });
  const uniqueVersions = versions.filter((element, index) => {
    return versions.indexOf(element) === index;
  });

  const versionOptions = useMemo(
    () => [
      { label: "Version auswählen", value: "" },
      {
        label: "Alle",
        value: "_",
      },
      ...uniqueVersions.map((item) => ({
        label: item,
        value: item,
      })),
    ],
    [uniqueVersions],
  );

  return (
    <Form
      onSubmit={(values) => handleSubmitSearchValue(values)}
      validate={(values) => ({
        text: values.documents && validateRequired()(values.text),
      })}
      render={({ handleSubmit }) => {
        return (
          <form
            className="terms-of-use-users-form"
            onSubmit={handleSubmit}
            role="form"
            noValidate
          >
            <div className="terms-of-use-users-form-fields">
              <Field
                component={TextInput}
                name="name"
                rows={2}
                placeholder="Name eingeben"
                initialValue={searchValue.name}
              />
              <Field
                component={Select}
                name="version"
                options={versionOptions}
                initialValue={searchValue.version}
              />
            </div>
            <Button
              label="Suchen"
              lightblue
              leadingIcon={SearchIcon}
              type="submit"
            />
          </form>
        );
      }}
    />
  );
}

type Props = {
  filterByName: (item: string | null) => void;
  filterByVersion: (item: string | null) => void;
  termsOfUseUsersList?: TermsOfUseUsersPage;
};
