import { FacilityObject } from "../../types";
import {
  useSetFacilityObjectOfflineMutation,
  useSetFacilityObjectOnlineMutation,
} from "../../api/facilityManagementApi";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export default function useToggleOnline(facilityObject?: FacilityObject) {
  const { customerToken = "" } = useParams();
  const [setOnline, { isLoading: onlineIsLoading }] =
    useSetFacilityObjectOnlineMutation();
  const [setOffline, { isLoading: offlineIsLoading }] =
    useSetFacilityObjectOfflineMutation();

  const toggleOnline = useCallback(() => {
    if (!facilityObject) return;
    if (facilityObject.isOnline) {
      return setOffline({
        customerToken,
        facilityObjectId: String(facilityObject.id),
      });
    }
    return setOnline({
      customerToken,
      facilityObjectId: String(facilityObject.id),
    });
  }, [facilityObject]);

  return { toggleOnline, isLoading: offlineIsLoading || onlineIsLoading };
}
