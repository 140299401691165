import React, { ReactElement } from "react";
import classNames from "classnames";
import ParticipantsVotingEligibilityList from "../participants/ParticipantsVotingEligibilityList";
import useFetchPropositionParticipants from "../../../hooks/meetings/useFetchPropositionParticipants";

import ParticipantsVotingEligibilityListSkeleton from "../../skeleton/ParticipantsVotingEligibilityListSkeleton";

import "../../../styles/components/meetings/proposition/PropositionVoteForParticipants.scss";
import FetchApiError from "../../common/FetchApiError";

export default function PropositionVoteForParticipants({
  propositionId,
  forOwner,
  votingDisabled,
}: Props): ReactElement {
  const { participants, isError, error, isLoading } =
    useFetchPropositionParticipants(propositionId);

  return (
    <div
      className={classNames(
        "etv-agenda-item-proposition-vote-for-participants",
        { forOwner },
      )}
    >
      {isError && <FetchApiError error={error} />}
      {participants.length > 0 && (
        <ParticipantsVotingEligibilityList
          participants={participants}
          propositionId={propositionId}
          votingDisabled={votingDisabled}
        />
      )}
      {isLoading && participants.length === 0 && (
        <ParticipantsVotingEligibilityListSkeleton forOwner />
      )}
    </div>
  );
}

type Props = {
  propositionId: string;
  forOwner?: boolean;
  votingDisabled?: boolean;
};
