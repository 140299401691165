import { Link } from "react-router-dom";
import { ContactUserEventModel, NotificationProps } from "../../types";
import { getShortenText } from "../../lib/notifications";
import NotificationItemDate from "./NotificationItemDate";

export default function ContactUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): JSX.Element {
  const { facilityObjectId, customerToken, raisedAtUtc, activityId, text } =
    userEventData as ContactUserEventModel;

  return (
    <Link
      to={`/${customerToken}/objekte/${facilityObjectId}/nachrichten/${activityId}`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong>
        <span>hat auf eine Nachricht geantwortet.</span>
        <div className="notification-item-content-text">
          {getShortenText(text)}
        </div>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </Link>
  );
}
