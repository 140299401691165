import React from "react";
import { Link, useParams } from "react-router-dom";
import HomeCaseLogo from "./icons/HomeCaseLogo";

export default function DrawerHeaderElementForPubliclyOutlet(): JSX.Element {
  const { customerToken = "" } = useParams();

  return (
    <Link to={`/${customerToken}`}>
      <HomeCaseLogo />
    </Link>
  );
}
