import { useSelector } from "react-redux";
import { store } from "../../store";
import { MyVoteInstructionsInfo, Proposition } from "../../types";
import { useMemo } from "react";

export default function useGetInstructionsState(
  propositionsDetailed?: Proposition[] | undefined,
  id?: string,
): Props {
  const propositionsState = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.propositionInstructionsVoting.items,
  );

  const propositionsInstructions = useMemo(() => {
    return propositionsState?.filter((item) => {
      return propositionsDetailed?.some(
        (proposition) => item.propositionId === proposition.id,
      );
    });
  }, [propositionsState, propositionsDetailed]);

  const proposition = useMemo(() => {
    return propositionsState?.find((a) => a.propositionId === id);
  }, [propositionsState, id]);

  return { propositionsInstructions, proposition };
}

type Props = {
  propositionsInstructions: MyVoteInstructionsInfo[];
  proposition: MyVoteInstructionsInfo | undefined;
};
