import "../../../styles/components/userSettings/ManagementDialogDescription.scss";
import React, { useEffect, useState } from "react";
import { Field, useFormState } from "react-final-form";
import RadioButton from "../../common/form/RadioButton";

import "../../../styles/components/meetings/InvitationDialogDescription.scss";
import { AttendanceType, VoteCastingType } from "../../../enums";
import InfoText from "../../userSettings/InfoText";

export default function InvitationAcceptDialogDescription(): JSX.Element {
  const { hasSubmitErrors, submitErrors, values } = useFormState();
  const [isSubmitErrorsText, setSubmitErrorsText] = useState(true);
  const invitationDialogActions =
    values.attendanceType === 0 || values.voteCastingType === 0;

  useEffect(() => {
    setSubmitErrorsText(false);
  }, [values]);

  useEffect(() => {
    setSubmitErrorsText(true);
  }, [hasSubmitErrors]);

  return (
    <section className="invitation-dialog-wrapper">
      <div className="invitation-dialog-question-wrapper">
        <span className="invitation-dialog-question">
          Wie planen Sie teilzunehmen? (Sie können Ihre Antworten später bei
          Bedarf ändern)
        </span>
        <Field
          name="attendanceType"
          component={RadioButton}
          label="Vor Ort"
          type="radio"
          required
          value={String(AttendanceType.Locally)}
          defaultChecked={values.attendanceType === AttendanceType.Locally}
        />
        <Field
          name="attendanceType"
          component={RadioButton}
          type="radio"
          label="Online per Videokonferenz"
          required
          value={String(AttendanceType.Online)}
          defaultChecked={values.attendanceType === AttendanceType.Online}
        />

        <div className="invitation-dialog-question-wrapper">
          <span className="invitation-dialog-question">
            Wie planen Sie, Ihre Stimmen abzugeben?
          </span>
          <Field
            name="voteCastingType"
            component={RadioButton}
            required
            type="radio"
            label="Selbst über HOMECASE"
            value={String(VoteCastingType.ByHomecase)}
            defaultChecked={
              values.voteCastingType === VoteCastingType.ByHomecase
            }
          />
          <Field
            name="voteCastingType"
            component={RadioButton}
            required
            type="radio"
            label="Die Hausverwaltung trägt meine Stimmen ein"
            value={String(VoteCastingType.ByEmployee)}
            defaultChecked={
              values.voteCastingType === VoteCastingType.ByEmployee
            }
          />
        </div>
      </div>
      {submitErrors?.notZero && invitationDialogActions && (
        <InfoText
          isError={true}
          textError="Bitte beantworten Sie beide Fragen."
        />
      )}
      {hasSubmitErrors &&
        values.attendanceType !== 0 &&
        values.voteCastingType !== 0 &&
        isSubmitErrorsText && (
          <InfoText
            isError={true}
            textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
          />
        )}
    </section>
  );
}
