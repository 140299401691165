import { useState } from "react";
import { useGetActivityTemplateCustomFieldsQuery } from "../../api/activitiesApi";
import { useParams } from "react-router-dom";
import { mapCustomFieldsToForm } from "../../lib/messages";
import {
  ActivityCustomFieldDefinition,
  FormFieldConfiguration,
} from "../../types";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export default function useCreateCustomFields(): UseCreateCustomFields {
  const [activityTemplateId, setActivityTemplateId] = useState("");
  const { customerToken = "" } = useParams();
  const {
    data: customFieldDefinition = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetActivityTemplateCustomFieldsQuery(
    { customerToken, activityTemplateId },
    { skip: !activityTemplateId },
  );

  return {
    customFields: mapCustomFieldsToForm(customFieldDefinition),
    customFieldDefinition,
    setActivityTemplateId,
    activityTemplateId,
    isLoading,
    isFetching,
    error,
    isError,
  };
}

type UseCreateCustomFields = {
  customFields: FormFieldConfiguration[];
  customFieldDefinition: ActivityCustomFieldDefinition[];
  setActivityTemplateId: (templateId: string) => void;
  isLoading: boolean;
  isFetching: boolean;
  activityTemplateId: string;
  error: SerializedError | FetchBaseQueryError | undefined;
  isError: boolean;
};
