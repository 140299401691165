import React, { Fragment, ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import CustomerLogo from "../components/common/CustomerLogo";
import { FacilityObject } from "../types";
import FacilityObjectPicture from "./common/FacilityObjectPicture";
import { PictureFillStrategy } from "../enums";

export default function DrawerHeaderElementForPrivateOutlet({
  selectedAssignedObject,
}: Props): ReactElement {
  const { customerToken = "" } = useParams();
  const width = Math.round(470 * devicePixelRatio);
  const height = Math.round(192 * devicePixelRatio);

  return (
    <Fragment>
      <Link to={`/${customerToken}`}>
        <CustomerLogo />
      </Link>
      {selectedAssignedObject && (
        <Link
          to={`/${customerToken}/objekte/${selectedAssignedObject.id}/schwarzesbrett`}
          className="drawer-header-picture"
        >
          <FacilityObjectPicture
            facilityObjectId={String(selectedAssignedObject.id)}
            width={width}
            height={height}
            fillStrategy={PictureFillStrategy.FitAndConvert}
            customerToken={customerToken}
          />
          <address className="drawer-header-address">
            <span className="drawer-header-address-content">
              <span className="drawer-header-address-street">
                {selectedAssignedObject.street}
              </span>
              <span className="drawer-header-address-city">
                {selectedAssignedObject.zipCode} {selectedAssignedObject.city}
              </span>
            </span>
          </address>
        </Link>
      )}
    </Fragment>
  );
}

type Props = {
  selectedAssignedObject?: FacilityObject;
};
