import { useEffect, useRef } from "react";
import useFetchNotifications from "../../hooks/notifications/useFetchNotifications";
import LoadingSpinner from "../icons/LoadingSpinner";
import FetchApiError from "../common/FetchApiError";
import useMarkUserEventsListAsRead from "../../hooks/notifications/useMarkUserEventsListAsRead";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import NotificationItem from "./NotificationItem";

import "../../styles/components/common/Notifications.scss";

export default function NotificationsDialog({
  onDialogClose,
}: Props): JSX.Element {
  const { userEvents, userBriefs, isLoading, isError, error, refetch } =
    useFetchNotifications();

  const unreadEventIds = userEvents
    .filter((event) => !event.payload.isRead)
    .map((event) => event.payload.eventId);

  const { markUserEventsAsRead } = useMarkUserEventsListAsRead(
    unreadEventIds,
    onDialogClose,
  );

  const ref = useRef(null);
  useOnClickOutside(ref, onDialogClose);

  useEffect(() => {
    refetch();
  }, []);
  return (
    <div
      className="notifications-dialog-background-layer"
      data-testid="notifications-dialog-background-layer"
      ref={ref}
    >
      <div className="notifications-dialog" data-testid="notifications-dialog">
        <div
          className="notifications-dialog-header"
          data-testid="notifications-dialog-header"
        >
          <h3
            className="notifications-dialog-header-title"
            data-testid="notifications-dialog-header-title"
          >
            Benachrichtigungen
          </h3>
          <div
            className="notifications-dialog-header-actions"
            data-testid="notifications-dialog-header-actions"
            onClick={markUserEventsAsRead}
            role="button"
          >
            Alle als gelesen markieren
          </div>
        </div>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div
            className="notifications-dialog-content"
            data-testid="notifications-dialog-content"
          >
            {userEvents.map(({ payload, eventType }) => (
              <NotificationItem
                payload={payload}
                eventType={eventType}
                userBriefs={userBriefs}
                onDialogClose={onDialogClose}
                key={payload.eventId}
              />
            ))}
          </div>
        )}
        {isError && <FetchApiError error={error} />}
      </div>
    </div>
  );
}

type Props = {
  onDialogClose: () => void;
};
