import {
  AgendaItem,
  AttendMeeting,
  Meeting,
  MeetingDataIntegrityFailureReason,
  MyInvitation,
  Participant,
  ParticipantAttendanceState,
  ParticipantDelegate,
  ParticipantRepresentative,
  Proposition,
  PropositionVote,
} from "../types";
import {
  MeetingState,
  MeetingType,
  ParticipantAttendanceType,
  ParticipantDelegationType,
  PropositionVoteType,
  PropositionVotingState,
  PropositionVotingType,
  UserRoles,
  ValidateMeetingDataIntegrityFailureReason,
  VoteCastingType,
} from "../enums";
import moment from "moment";
import { isEmployeeByRoles, isOwnerByRoles } from "./index";

export const FAILURE_REASONS = {
  [ValidateMeetingDataIntegrityFailureReason.None]: "",
  [ValidateMeetingDataIntegrityFailureReason.MeetingNotFound]:
    "Versammlung in Conventus nicht gefunden oder nicht im Durchführungsmodus",
  [ValidateMeetingDataIntegrityFailureReason.AgendaItemsListIsEmpty]:
    "Tagesordnung fehlt",
  [ValidateMeetingDataIntegrityFailureReason.AgendaItemsWithoutTitle]:
    "Tagesordnungspunkt-Titel fehlt",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutTitle]:
    "Antragstitel-Titel fehlt in TOP $1",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutPropositionContentAndDecision]:
    "Beschlusstext oder Antragstext in einem Antrag in TOP $1.",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutQualificationVotingRule]:
    "Stimmprinzip in einem Antrag in TOP $1.",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutQualificationDistributionKeyId]:
    "Schlüssel in einem Antrag in TOP $1",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutDoubleQualificationVotingRule]:
    "Stimmprinzip 2 in einem Antrag in TOP $1",
  [ValidateMeetingDataIntegrityFailureReason.PropositionsWithoutDoubleQualificationDistributionKeyId]:
    "Schlüssel 2 in einem Antrag in TOP $1",
};

export const EMPLOYEE_PARTICIPANT: Participant = {
  attendance: ParticipantAttendanceType.Online,
  delegationType: ParticipantDelegationType.None,
  id: "",
  meetingId: "",
  participantVoting: {
    vote: PropositionVoteType.None,
    basedOnInstruction: false,
  },
  user: {},
  firstName: "Hausverwaltung",
  lastName: "",
};
export function getMeetingsInThePast(meetings: Meeting[]): Meeting[] {
  return meetings.filter(({ state }: Meeting) => state === MeetingState.Closed);
}

export function getMeetingsInTheFuture(meetings: Meeting[]): Meeting[] {
  return meetings.filter(({ state }: Meeting) => state !== MeetingState.Closed);
}

export function getMeetingTitle(meeting: Meeting): string {
  const { startDate, type, description } = meeting;
  const startDateFormatted = moment(startDate).format("LL");

  if (description) {
    return description;
  }

  let headlinePrefix;

  switch (type) {
    case MeetingType.Extraordinary: {
      headlinePrefix = "Außerordentliche Eigentümerversammlung";
      break;
    }
    case MeetingType.CircularDecision: {
      headlinePrefix = "Umlaufbeschluss";
      break;
    }
    default: {
      headlinePrefix = "Eigentümerversammlung";
      break;
    }
  }

  return `${headlinePrefix} am ${startDateFormatted}`;
}

export function isMeetingReadyToStart(
  meetingState: MeetingState | undefined,
  customerRoles: UserRoles[],
): boolean {
  return (
    meetingState === MeetingState.OwnerAndEmployeePreview &&
    customerRoles.includes(UserRoles.Employee)
  );
}

export function isMeetingReadToShare(
  meetingState: MeetingState | undefined,
  customerRoles: UserRoles[],
): boolean {
  return (
    meetingState === MeetingState.EmployeePreview &&
    customerRoles.includes(UserRoles.Employee)
  );
}

export function isMeetingReadToClose(
  meetingState: MeetingState | undefined,
  customerRoles: UserRoles[],
): boolean {
  return (
    meetingState === MeetingState.Running &&
    customerRoles.includes(UserRoles.Employee)
  );
}

export function shouldRenderVoting(
  roles: UserRoles[],
  proposition: Proposition,
  meetingState: MeetingState | undefined,
): boolean {
  const { withoutVoting, votingState } = proposition;
  return (
    isOwnerByRoles(roles) &&
    proposition.votingType !== PropositionVotingType.Simple &&
    !withoutVoting &&
    votingState !== PropositionVotingState.Closed &&
    (meetingState === MeetingState.OwnerAndEmployeePreview ||
      meetingState === MeetingState.Running)
  );
}
export function shouldRenderVotingControl(
  roles: UserRoles[],
  proposition: Proposition,
  meetingState: MeetingState | undefined,
): boolean {
  const { withoutVoting } = proposition;
  return (
    isEmployeeByRoles(roles) &&
    !withoutVoting &&
    (meetingState === MeetingState.OwnerAndEmployeePreview ||
      meetingState === MeetingState.Running)
  );
}

export function shouldRenderSimpleVotingInformation(
  proposition: Proposition,
  meetingState: MeetingState | undefined,
): boolean {
  const { withoutVoting, votingType } = proposition;

  return (
    !withoutVoting &&
    votingType === PropositionVotingType.Simple &&
    meetingState !== MeetingState.Closed
  );
}

export function shouldRenderVotingResult(
  proposition: Proposition,
  meetingState: MeetingState | undefined,
): boolean {
  const { withoutVoting, votingState } = proposition;
  return (
    (!withoutVoting && meetingState === MeetingState.Closed) ||
    votingState === PropositionVotingState.Closed
  );
}

export function getDelegationTitle(
  delegationType: ParticipantDelegationType,
  delegate?: ParticipantDelegate,
): string {
  switch (delegationType) {
    case ParticipantDelegationType.VotingEligibilityDelegatedToParticipant:
      return delegate ? `${delegate.firstName} ${delegate.lastName}` : "";
    case ParticipantDelegationType.VotingEligibilityDelegatedToEmployee:
      return "Vollmacht";
    default:
      return "";
  }
}

export function getDelegationParticipant(
  participants: Participant[],
  delegationType: ParticipantDelegationType | undefined,
  delegate: ParticipantDelegate | undefined,
): Participant | undefined {
  if (
    delegationType ===
    ParticipantDelegationType.VotingEligibilityDelegatedToEmployee
  ) {
    return EMPLOYEE_PARTICIPANT;
  }
  if (delegate) {
    return participants.find((p) => p.id === delegate.id);
  }
}

export function getPropositionVotingInformationText(
  attendData: AttendMeeting | undefined,
  voteData: PropositionVote | undefined,
  votingState: PropositionVotingState,
): string {
  if (votingState === PropositionVotingState.None) {
    return "Abstimmung wurde noch nicht gestartet. Sie können aktuell noch nicht abstimmen.";
  }
  if (
    votingState === PropositionVotingState.Running &&
    attendData?.attend &&
    (voteData?.voteType === PropositionVoteType.None || !voteData)
  ) {
    return "Abstimmung wurde gestartet, Sie können jetzt abstimmen.";
  }

  if (votingState === PropositionVotingState.Running && !attendData?.attend) {
    return "Abstimmung wurde gestartet, Sie müssen an der Versammlung teilnehmen um abzustimmen.";
  }
  return "Sie können Ihre Stimme noch ändern.";
}

export function getPropositionVoteText(
  votingData: PropositionVote | undefined,
): string {
  if (votingData?.voteType === PropositionVoteType.Yes) {
    return "Sie haben mit Ja abgestimmt.";
  }
  if (votingData?.voteType === PropositionVoteType.No) {
    return "Sie haben mit Nein abgestimmt.";
  }
  if (votingData?.voteType === PropositionVoteType.Abstention) {
    return "Sie enthalten sich Ihrer Stimme.";
  }
  return "";
}

export function getFailureReasonText(
  reason: MeetingDataIntegrityFailureReason,
  agendaItems: AgendaItem[],
): string {
  const { failureReason, invalidEntityIds } = reason;

  const text = FAILURE_REASONS[failureReason];
  const invalidAgendaItems = agendaItems
    .filter(({ id }) =>
      invalidEntityIds.find(({ agendaItemId }) => agendaItemId === id),
    )
    .map(({ title }) => title);

  return text.replace(
    "$1",
    invalidAgendaItems.length > 0 ? invalidAgendaItems.join(", ") : "Unbekannt",
  );
}

export function getReverseParticipantDisplayName(
  participant: Participant,
  nameDelimiter: string | undefined = undefined,
): string {
  if (nameDelimiter === undefined) {
    nameDelimiter = "";
  }

  nameDelimiter = `${nameDelimiter} `;

  const names = (
    participant.attendance === ParticipantAttendanceType.Online &&
    participant.user &&
    (participant.user.firstName || participant.user.lastName)
      ? [participant.user.lastName, participant.user.firstName]
      : [participant.lastName, participant.firstName]
  ).filter((n) => n != undefined && n !== "");

  return names.length > 1 ? names.join(nameDelimiter) : names.join("");
}

export function getParticipantDisplayName(
  participant: Participant,
  nameDelimiter: string | undefined = undefined,
): string {
  if (nameDelimiter === undefined) {
    nameDelimiter = "";
  }

  nameDelimiter = `${nameDelimiter} `;

  const names = (
    participant.attendance === ParticipantAttendanceType.Online &&
    participant.user &&
    (participant.user.firstName || participant.user.lastName)
      ? [participant.user.firstName, participant.user.lastName]
      : [participant.firstName, participant.lastName]
  ).filter((n) => n != undefined && n !== "");

  return names.length > 1 ? names.join(nameDelimiter) : names.join("");
}

export function getReverseParticipantRepresentativeDisplayName(
  representative: ParticipantRepresentative,
): string {
  const names = [representative.lastName, representative.firstName].filter(
    (n) => n != undefined && n !== "",
  );

  return names.length > 1 ? names.join(", ") : names.join("");
}

export function shouldRenderParticipantFurtherRepresentatives(
  participant: Participant,
): boolean {
  const { attendance, furtherRepresentatives } = participant;
  return (
    attendance !== ParticipantAttendanceType.Online &&
    furtherRepresentatives != undefined &&
    furtherRepresentatives.length > 0
  );
}

export function getParticipantRunningJoinState(
  attendance: ParticipantAttendanceType,
): ParticipantAttendanceState {
  const attendanceState: ParticipantAttendanceState = {
    text: "",
    className: "",
    symbol: "",
  };
  switch (true) {
    case attendance === ParticipantAttendanceType.Online:
      attendanceState.text = "Anwesend in Homecase";
      attendanceState.className = "online";
      attendanceState.symbol = "✓";
      break;
    case attendance === ParticipantAttendanceType.Locally:
      attendanceState.text = "Anwesend";
      attendanceState.className = "locally";
      attendanceState.symbol = "✓";
      break;
    default:
      attendanceState.text = "Unbekannt";
      attendanceState.className = "unknown";
      attendanceState.symbol = "?";
      break;
  }
  return attendanceState;
}
export function getParticipantPreviewJoinState(
  invitation?: MyInvitation,
  delegate?: ParticipantDelegate,
): ParticipantAttendanceState {
  const attendanceState: ParticipantAttendanceState = {
    text: "",
    className: "",
    symbol: "",
  };

  switch (true) {
    case invitation &&
      invitation.invitationAccepted &&
      invitation.attendanceType === ParticipantAttendanceType.Online:
      attendanceState.text =
        invitation.voteCastingType === VoteCastingType.ByEmployee
          ? "Teilnahme Online, Stimmeintragung durch Hausverwalter"
          : "Teilnahme Online über Homecase";
      attendanceState.className = "invitation-accepted";
      attendanceState.symbol = "✓";
      break;
    case invitation &&
      invitation.invitationAccepted &&
      invitation.attendanceType === ParticipantAttendanceType.Locally:
      attendanceState.text =
        invitation.voteCastingType === VoteCastingType.ByEmployee
          ? "Teilnahme vor Ort, Stimmeintragung durch Hausverwalter"
          : "Teilnahme vor Ort über Homecase";
      attendanceState.className = "invitation-accepted";
      attendanceState.symbol = "✓";
      break;
    case invitation && !invitation.invitationAccepted:
      attendanceState.text = !delegate ? "Abgesagt" : "Abgesagt mit Vollmacht";
      attendanceState.className = "invitation-decline";
      attendanceState.symbol = "X";
      break;
    default:
      attendanceState.text = "Eingeladen, Antwort austehend";
      attendanceState.className = "invitation-waiting";
      attendanceState.symbol = "?";
      break;
  }

  return attendanceState;
}

export function getParticipantAttendanceState(
  meetingState: MeetingState,
  { delegate, invitation, attendance }: Participant,
): ParticipantAttendanceState {
  if (meetingState === MeetingState.OwnerAndEmployeePreview) {
    return getParticipantPreviewJoinState(invitation, delegate);
  }

  if (
    meetingState === MeetingState.Running &&
    attendance === ParticipantAttendanceType.Abscent
  ) {
    return getParticipantPreviewJoinState(invitation, delegate);
  }

  return getParticipantRunningJoinState(attendance);
}
