import TabBar from "../../common/TabBar";
import VirtualTab from "../../common/VirtualTab";
import React, { useCallback, useState } from "react";
import useParticipantsForVoteControlCenter from "../../../hooks/meetings/useParticipantsForVoteControlCenter";
import SearchField from "../../common/SearchField";

import PropositionVoteControlCenterTable from "./PropositionVoteControlCenterTable";
import FetchApiError from "../../common/FetchApiError";
import PropositionVoteControlCenterTableSkeleton from "../../skeleton/PropositionVoteControlCenterTableSkeleton";

import "../../../styles/components/meetings/proposition/PropositionVoteControlCenter.scss";
import { ParticipantsPurposeType, PropositionVoteType } from "../../../enums";
import PropositionVoteCenterTableActionsMenu from "./PropositionVoteCenterTableActionsMenu";
import { useParams } from "react-router-dom";
import { useSetVoteForParticipantsMutation } from "../../../api/propositionApi";

export default function PropositionVoteControlCenter({
  propositionId,
}: Props): JSX.Element {
  const [participantsPurpose, setParticipantsPurpose] = useState<
    number | string
  >(ParticipantsPurposeType.ForVote);
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
    agendaItemId = "",
  } = useParams();

  const [
    setVotes,
    {
      isError: setVotesIsError,
      isLoading,
      error: setVotesError,
      originalArgs,
      isSuccess,
    },
  ] = useSetVoteForParticipantsMutation();

  const {
    participantsVoted,
    participantsNotVoted,
    participantsForVote,
    participantsAbsent,
    participants,
    isError,
    error,
    isFetching,
    handleFilterByQuery,
  } = useParticipantsForVoteControlCenter(propositionId);

  const retry = useCallback(() => {
    if (originalArgs) {
      setVotes(originalArgs);
    }
  }, [originalArgs]);

  const handleSelect = useCallback(
    async (voteType: PropositionVoteType) => {
      const participantIds = participantsForVote.map(({ id }) => id);
      await setVotes({
        voteType,
        participantIds,
        propositionId,
        customerToken,
        agendaItemId,
        meetingId,
        facilityObjectId,
      });
    },
    [participants],
  );

  return (
    <div className="agenda-item-proposition-vote-control-center">
      <div
        className={"agenda-item-proposition-vote-control-center-header-wrapper"}
      >
        <SearchField
          onChange={handleFilterByQuery}
          placeholder="Person suchen"
        />

        <PropositionVoteCenterTableActionsMenu
          onSelect={handleSelect}
          isDisabled={
            !(isFetching && participants.length === 0) &&
            participantsPurpose === ParticipantsPurposeType.ForVote
          }
        />
      </div>

      <TabBar>
        <VirtualTab
          tabId={ParticipantsPurposeType.ForVote}
          label={`Abstimmen für Teilnehmer (${participantsForVote.length})`}
          onClick={setParticipantsPurpose}
          active={participantsPurpose === ParticipantsPurposeType.ForVote}
        />
        <VirtualTab
          tabId={ParticipantsPurposeType.Voted}
          label={`Abgestimmt (${participantsVoted.length})`}
          onClick={setParticipantsPurpose}
          active={participantsPurpose === ParticipantsPurposeType.Voted}
        />
        <VirtualTab
          tabId={ParticipantsPurposeType.NotVoted}
          label={`Nicht abgestimmt (${participantsNotVoted.length})`}
          onClick={setParticipantsPurpose}
          active={participantsPurpose === ParticipantsPurposeType.NotVoted}
        />
        <VirtualTab
          tabId={ParticipantsPurposeType.Absent}
          label={`Abwesend (${participantsAbsent.length})`}
          onClick={setParticipantsPurpose}
          active={participantsPurpose === ParticipantsPurposeType.Absent}
        />
      </TabBar>
      {isFetching && participants.length === 0 && (
        <PropositionVoteControlCenterTableSkeleton />
      )}
      {!(isFetching && participants.length === 0) &&
        participantsPurpose === ParticipantsPurposeType.ForVote && (
          <PropositionVoteControlCenterTable
            participants={participantsForVote}
            propositionId={propositionId}
            rawParticipants={participants}
            participantsPurpose={participantsPurpose}
            retry={retry}
            isError={setVotesIsError}
            error={setVotesError}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        )}
      {!(isFetching && participants.length === 0) &&
        participantsPurpose === ParticipantsPurposeType.Voted && (
          <PropositionVoteControlCenterTable
            participants={participantsVoted}
            propositionId={propositionId}
            rawParticipants={participants}
            participantsPurpose={participantsPurpose}
          />
        )}
      {!(isFetching && participants.length === 0) &&
        participantsPurpose === ParticipantsPurposeType.NotVoted && (
          <PropositionVoteControlCenterTable
            participants={participantsNotVoted}
            propositionId={propositionId}
            rawParticipants={participants}
            participantsPurpose={participantsPurpose}
          />
        )}
      {!(isFetching && participants.length === 0) &&
        participantsPurpose === ParticipantsPurposeType.Absent && (
          <PropositionVoteControlCenterTable
            participants={participantsAbsent}
            propositionId={propositionId}
            rawParticipants={participants}
            participantsPurpose={participantsPurpose}
          />
        )}
      {isError && <FetchApiError error={error} />}
    </div>
  );
}

type Props = {
  propositionId: string;
};
