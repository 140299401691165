import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FACILITY_OBJECTS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import {
  FacilityObject,
  FacilityObjectRequest,
  FacilityUnitWithTenanciesOrOwnershipsAndPersons,
  ServiceProvider,
  NavigationItem,
  NavigationRawItem,
  Neighbor,
  FacilityObjectContact,
  Contact,
  CustomerRequest,
  ContactPictureRequest,
  FacilityObjectPictureRequest,
  UsersRegistrationStatus,
  FacilityObjectReport,
  FacilityObjectReportRequest,
  StakeholdersInfo,
  CommunicationDataRequest,
  CommunicationDataInfo,
  RegistrationCodeRequest,
  TenantsRegistrationsDataRequest,
  TenantsRegistrationsData,
  OnlineIdentitiesRequest,
  OnlineIdentity,
  RevokeRegistrationCodeForOnlineIdentityRequest,
  NewRegistrationCodeForOnlineIdentityRequest,
  OnlineIdentityForTenantRequest,
  OnlineIdentityForTenant,
  FacilityObjectPictureEditRequest,
} from "../types";
import { mapRawNavigationItems } from "../lib/navigation";
import { CommunicationDataType } from "../enums";

export const facilityManagementApi = createApi({
  reducerPath: FACILITY_OBJECTS_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: [
    "FacilityObject",
    "FacilityObjects",
    "FacilityObjectPicture",
    "UsersRegistrationStatus",
    "AssignedObjects",
    "ReportsPeriodToLastYearRestricted",
    "FacilityObjectReports",
    "OnlineIdentities",
  ],
  endpoints: (build) => ({
    getFacilityObject: build.query<FacilityObject, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}`,
      providesTags: ["FacilityObject"],
    }),
    getAssignedObjects: build.query<FacilityObject[], CustomerRequest>({
      query: ({ customerToken }) => ({
        url: `/v2/customers/${customerToken}/assignedObjects`,
      }),
      providesTags: [{ type: "AssignedObjects", id: "OBJECTS" }],
    }),
    getFacilityObjects: build.query<FacilityObject[], CustomerRequest>({
      query: ({ customerToken, ...params }) => ({
        url: `/v2/customers/${customerToken}/facilityObjects`,
        params,
      }),
      providesTags: ["FacilityObjects"],
    }),
    setFacilityObjectOffline: build.mutation<void, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/gooffline`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjects", "FacilityObject"],
    }),
    setFacilityObjectOnline: build.mutation<void, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/goonline`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjects", "FacilityObject"],
    }),
    getFacilityObjectNavigationItems: build.query<
      NavigationItem[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/navigationItems`,
      transformResponse: (response: NavigationRawItem[]) =>
        mapRawNavigationItems(response),
    }),

    getFacilityObjectPicture: build.query<Blob, FacilityObjectPictureRequest>({
      query: ({ customerToken, facilityObjectId, ...params }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/picture`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
      providesTags: ["FacilityObjectPicture"],
    }),

    setFacilityObjectPicture: build.mutation<
      void,
      FacilityObjectPictureEditRequest
    >({
      query: ({ customerToken, facilityObjectId, file }) => {
        const formData = new FormData();
        formData.append("content", file);
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/picture`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["FacilityObjectPicture"],
    }),
    getFacilityUnitsWithTenanciesOrOwnerships: build.query<
      FacilityUnitWithTenanciesOrOwnershipsAndPersons[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/unitsWithTenanciesOrOwnerships`,
    }),
    getFacilityUnitsWithTenanciesAndOwnershipsIncludingFuture: build.query<
      FacilityUnitWithTenanciesOrOwnershipsAndPersons,
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/withTenanciesAndOwnershipsIncludingFuture`,
    }),
    getServiceProviders: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/persons/serviceProviders`,
      transformResponse: (response: ServiceProvider[]) =>
        response.map(
          ({
            responsibilities,
            contractedResources,
            resourceGroups,
            personId,
            firstName,
            lastName,
            communicationData,
            contactPerson,
            ...rest
          }) => {
            const hasContactPerson =
              contactPerson && Object.keys(contactPerson).length > 0;
            const personCommunicationData = hasContactPerson
              ? contactPerson.communicationData
              : communicationData;
            return {
              id: personId,
              firstName: hasContactPerson ? contactPerson.firstName : firstName,
              lastName: hasContactPerson ? contactPerson.lastName : lastName,
              resourceGroups: resourceGroups.map(
                ({ resourceGroupName }) => resourceGroupName,
              ),
              ...rest,
              responsibilities: responsibilities.map(
                (item): string => item.responsibilityName,
              ),
              contractedResources: contractedResources.map(
                (item): string => item.resourceName,
              ),
              communication: {
                emails: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Email)
                  .map(({ value }) => value),
                phones: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Phone)
                  .map(({ value }) => value),
                fax: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Fax)
                  .map(({ value }) => value),
                website: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.WebSite)
                  .map(({ value }) => value),
              },
            };
          },
        ),
    }),
    getNeighbors: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/neighbors`,
      transformResponse: (response: Neighbor[]) =>
        response.map(
          ({
            dayOfBirth,
            monthOfBirth,
            yearOfBirth,
            sid,
            aboutMe,
            gender,
            firstName,
            lastName,
          }) => ({
            id: sid,
            aboutMe,
            gender,
            firstName,
            lastName,
            birthDate: {
              dayOfBirth,
              monthOfBirth,
              yearOfBirth,
            },
          }),
        ),
    }),

    getContactPicture: build.query<string, ContactPictureRequest>({
      query: ({ customerToken, employeeId, visitingCardId, ...params }) => ({
        url: `customers/${customerToken}/employees/${employeeId}/visitingCards/${visitingCardId}/picture`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const url = await response.url;
            return Promise.resolve(url);
          }
          return Promise.resolve(null);
        },
      }),
    }),

    getContacts: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/contacts`,
      transformResponse: (response: FacilityObjectContact[]) =>
        response.map(
          ({
            emails,
            phones,
            mobiles,
            firstName,
            lastName,
            firma,
            roleName,
            employeeId,
            visitingCardId,
          }) => ({
            employeeId,
            visitingCardId,
            firstName,
            lastName,
            firma,
            roleName,
            communication: {
              emails,
              phones,
              mobiles,
            },
          }),
        ),
    }),

    getUsersRegistrationStatus: build.query<
      UsersRegistrationStatus,
      CustomerRequest
    >({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/usersRegistrationStatus`,
      providesTags: ["UsersRegistrationStatus"],
    }),

    sendRegistrationCodes: build.mutation<void, CustomerRequest>({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/sendRegistrationCodes`,
        method: "POST",
      }),
      invalidatesTags: ["UsersRegistrationStatus"],
    }),

    getFacilityObjectReports: build.query<
      FacilityObjectReport[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/reports`,
      providesTags: ["FacilityObjectReports"],
    }),

    isReportsPeriodToLastYearRestricted: build.query<
      boolean,
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/isReportsPeriodToLastYearRestricted`,
      providesTags: ["ReportsPeriodToLastYearRestricted"],
    }),

    removeReportsPeriodRestriction: build.mutation<void, FacilityObjectRequest>(
      {
        query: ({ customerToken, facilityObjectId }) => ({
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/removeReportsPeriodRestriction`,
          method: "POST",
        }),
        invalidatesTags: ["ReportsPeriodToLastYearRestricted"],
      },
    ),

    restrictReportsPeriodToLastYear: build.mutation<
      void,
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/restrictReportsPeriodToLastYear`,
        method: "POST",
      }),
      invalidatesTags: ["ReportsPeriodToLastYearRestricted"],
    }),

    enableReport: build.mutation<void, FacilityObjectReportRequest>({
      query: ({ customerToken, facilityObjectId, reportId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/reports/${reportId}/enableReport`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjectReports"],
    }),

    disableReport: build.mutation<void, FacilityObjectReportRequest>({
      query: ({ customerToken, facilityObjectId, reportId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/reports/${reportId}/disableReport`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjectReports"],
    }),

    getFacilityObjectServiceProviders: build.query<
      StakeholdersInfo[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/serviceProviders`,
    }),

    getFacilityObjectOwners: build.query<
      StakeholdersInfo[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/facilityObjectOwners`,
    }),

    getFacilityObjectTenants: build.query<
      StakeholdersInfo[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/tenants`,
    }),

    getCommunicationData: build.query<
      CommunicationDataInfo[],
      CommunicationDataRequest
    >({
      query: ({ customerToken, personId }) =>
        `/customers/${customerToken}/persons/${personId}/communicationData`,
    }),

    sendRegistrationCodeByEmail: build.mutation<
      boolean,
      RegistrationCodeRequest
    >({
      query: ({ customerToken, personId, email, issuedFor }) => ({
        url: `/customers/${customerToken}/persons/${personId}/sendRegistrationCodeByEmail`,
        method: "POST",
        body: {
          email,
          issuedFor,
        },
        responseHandler: async (response) => {
          if (response.status === 404) {
            return false;
          }
          return response.ok;
        },
      }),
    }),

    loadTenantsRegistrationData: build.mutation<
      TenantsRegistrationsData[],
      TenantsRegistrationsDataRequest
    >({
      query: ({ customerToken, ids }) => ({
        url: `/customers/${customerToken}/tenantsRegistrationData`,
        method: "POST",
        body: { ids },
      }),
    }),

    getOnlineIdentities: build.query<OnlineIdentity[], OnlineIdentitiesRequest>(
      {
        query: ({ customerToken, tenantId }) =>
          `/customers/${customerToken}/tenants/${tenantId}/onlineIdentities`,
        providesTags: ["OnlineIdentities"],
      },
    ),

    revokeRegistrationCodeForOnlineIdentity: build.mutation<
      void,
      RevokeRegistrationCodeForOnlineIdentityRequest
    >({
      query: ({ customerToken, regCode }) => ({
        url: `/customers/${customerToken}/onlineIdentities/registrationCodes/${regCode}/revoke`,
        method: "POST",
      }),
      invalidatesTags: ["OnlineIdentities"],
    }),

    issueNewRegistrationCodeForOnlineIdentity: build.mutation<
      void,
      NewRegistrationCodeForOnlineIdentityRequest
    >({
      query: ({ customerToken, oid }) => ({
        url: `/customers/${customerToken}/onlineIdentities/${oid}/registrationCodes`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["OnlineIdentities"],
    }),

    createOnlineIdentityForTenant: build.mutation<
      OnlineIdentityForTenant,
      OnlineIdentityForTenantRequest
    >({
      query: ({ customerToken, tenantId, issuedFor }) => ({
        url: `/customers/${customerToken}/tenants/${tenantId}/onlineIdentities`,
        method: "POST",
        body: { issuedFor },
      }),
      invalidatesTags: ["OnlineIdentities"],
    }),
  }),
});

export const {
  useLazyGetAssignedObjectsQuery,
  useGetFacilityObjectQuery,
  useGetFacilityObjectsQuery,
  useGetFacilityUnitsWithTenanciesOrOwnershipsQuery,
  useGetServiceProvidersQuery,
  useGetFacilityObjectNavigationItemsQuery,
  useGetNeighborsQuery,
  useGetContactsQuery,
  useGetContactPictureQuery,
  useGetFacilityObjectPictureQuery,
  useSetFacilityObjectPictureMutation,
  useSetFacilityObjectOfflineMutation,
  useSetFacilityObjectOnlineMutation,
  useGetFacilityUnitsWithTenanciesAndOwnershipsIncludingFutureQuery,
  useGetUsersRegistrationStatusQuery,
  useSendRegistrationCodesMutation,
  useGetFacilityObjectReportsQuery,
  useIsReportsPeriodToLastYearRestrictedQuery,
  useRemoveReportsPeriodRestrictionMutation,
  useRestrictReportsPeriodToLastYearMutation,
  useEnableReportMutation,
  useDisableReportMutation,
  useGetFacilityObjectServiceProvidersQuery,
  useGetFacilityObjectOwnersQuery,
  useGetFacilityObjectTenantsQuery,
  useGetCommunicationDataQuery,
  useSendRegistrationCodeByEmailMutation,
  useLoadTenantsRegistrationDataMutation,
  useGetOnlineIdentitiesQuery,
  useRevokeRegistrationCodeForOnlineIdentityMutation,
  useIssueNewRegistrationCodeForOnlineIdentityMutation,
  useCreateOnlineIdentityForTenantMutation,
} = facilityManagementApi;
