import React from "react";
import { useParams } from "react-router-dom";

import MeetingList from "../../meetings/MeetingList";
import { meetingApi } from "../../../api/meetingApi";
import {
  getMeetingsInTheFuture,
  getMeetingsInThePast,
} from "../../../lib/meeting";
import NoMeetingAvailable from "../../meetings/NoMeetingAvailable";
import FetchApiError from "../../common/FetchApiError";
import MeetingListSkeleton from "../../skeleton/MeetingListSkeleton";

import "../../../styles/components/pages/meetings/MeetingsPage.scss";

export default function MeetingsPage(): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const {
    data = [],
    error,
    isLoading,
    isError,
    isSuccess,
  } = meetingApi.endpoints.getMeetings.useQueryState({
    customerToken,
    facilityObjectId,
  });

  const meetingsInThePast = getMeetingsInThePast(data);
  const meetingsInTheFuture = getMeetingsInTheFuture(data);

  return (
    <div className="etv-meetings">
      {isLoading && <MeetingListSkeleton count={6} />}
      {isSuccess && meetingsInTheFuture.length > 0 && (
        <MeetingList meetings={meetingsInTheFuture} />
      )}
      {isSuccess && meetingsInThePast.length > 0 && (
        <MeetingList
          meetings={meetingsInThePast}
          headline="Vergangene Eigentümerversammlungen"
        />
      )}
      {isSuccess && data.length === 0 && <NoMeetingAvailable />}
      {isError && <FetchApiError error={error} />}
    </div>
  );
}
