import React, { useCallback, useState } from "react";
import classNames from "classnames";
import CaretDown from "../icons/CaretDown";

import "../../styles/components/common/Details.scss";

export default function Details({
  summary,
  children,
  open,
  controlId,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(Boolean(open));
  const className = classNames("details", { "details-open": isOpen });
  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <div className={className} data-testid="accordion">
      <div
        className="details-headline"
        onClick={toggle}
        aria-expanded={isOpen}
        aria-controls={controlId}
        role="button"
      >
        <h3 className="details-summary">{summary}</h3>
        <div className="details-action">
          <CaretDown className="details-action-open" width={16} height={16} />
        </div>
      </div>
      <div
        className="details-content"
        id={controlId}
        role="region"
        aria-label={typeof summary === "string" ? summary : "Zusammenfassung"}
      >
        <div className="details-content-inner">{children}</div>
      </div>
    </div>
  );
}

type Props = {
  summary: string | JSX.Element;
  children: JSX.Element | string | number;
  open?: boolean;
  controlId: string;
};
