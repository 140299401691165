import React, { ReactElement, useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment/moment";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";
import ProfileForm from "./ProfileForm";
import Button from "../common/Button";
import classNames from "classnames";

import "../../styles/components/profileSettings/Profile.scss";
import ProfilePictureSettings from "../common/ProfilePictureSettings";
import PictureProfilePreview from "../common/PictureProfilePreview";

export default function Profile(): ReactElement {
  const { pathname } = useLocation();
  const { user } = useGetUsersMyProfile();
  const {
    dayOfBirth,
    gender,
    firstName,
    lastName,
    monthOfBirth,
    yearOfBirth,
    aboutMe,
  } = user;
  const [isAvatar, setAvatar] = useState<File | undefined>(undefined);

  const handleAvatarUpload = useCallback(
    async (avatar: File) => {
      setAvatar(avatar);
    },
    [isAvatar, user],
  );

  const classNameDiv = classNames({ center: !dayOfBirth || !gender });

  return (
    <div>
      <div className="settings-menu-profile">
        {!isAvatar && (
          <ProfilePictureSettings handleAvatarUpload={handleAvatarUpload} />
        )}

        {isAvatar && (
          <PictureProfilePreview
            isAvatar={isAvatar}
            handleAvatarUpload={handleAvatarUpload}
          />
        )}

        {pathname.includes("profil") && !pathname.includes("bearbeiten") && (
          <div className="profile-info">
            <span className="profile-info-name">
              {firstName} {lastName}
            </span>

            <div className="profile-gender-birth">
              {gender && gender !== "Nicht angegeben" && (
                <div className="contact-person-card-info">
                  <div className={classNameDiv}>
                    <span>{gender}</span>
                  </div>
                </div>
              )}
              {dayOfBirth && (
                <div className="contact-person-card-info">
                  <div className={classNameDiv}>
                    <span>
                      {dayOfBirth && monthOfBirth && yearOfBirth
                        ? moment({
                            day: dayOfBirth,
                            month: monthOfBirth - 1,
                            year: yearOfBirth,
                          }).format("L")
                        : undefined}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <span className="about-me">{aboutMe}</span>
          </div>
        )}
        {pathname.includes("profil") && !pathname.endsWith("bearbeiten") && (
          <Link className="actions-button" to="/profil/bearbeiten">
            <Button lightblue label="Profil bearbeiten" />
          </Link>
        )}
      </div>
      {pathname.includes("/profil/bearbeiten") && (
        <ProfileForm isAvatar={isAvatar} />
      )}
    </div>
  );
}
