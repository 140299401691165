import React, { Fragment, FunctionComponent, ReactNode } from "react";
import classNames from "classnames";
import { IconProps } from "../../../types";
import Phone from "../../icons/Phone";
import Mail from "../../icons/Mail";

import "../../../styles/components/common/contact/ContactPersonCard.scss";

import ContactPersonCardResponsibility from "./ContactPersonCardResponsibility";
import ContactPersonCardIcon from "./ContactPersonCardIcon";

export default function ContactPersonCard(props: Props): JSX.Element {
  const {
    firstName,
    lastName,
    responsibilities = [],
    icon: ItemIcon,
    phone,
    email,
    profilePicture,
    onClick,
    contractedResources,
  } = props;

  const className = classNames({
    "contact-person-card": true,
    "contact-person-card-clickable": Boolean(onClick),
  });

  return (
    <Fragment>
      <div
        className={className}
        onClick={onClick}
        data-testid="contact-person-card"
      >
        <ContactPersonCardResponsibility
          responsibilities={responsibilities}
          contractedResources={contractedResources}
        />

        <ContactPersonCardIcon
          firstName={firstName}
          lastName={lastName}
          icon={ItemIcon}
          profilePicture={profilePicture}
        />

        <div className="contact-person-card-contacts">
          {phone && phone?.length > 0 && (
            <a href={`tel:${phone[0]}`}>
              <Phone className="contact-person-card-icon" />
            </a>
          )}
          {email && email?.length > 0 && (
            <a href={`mailto:${email[0]}`}>
              <Mail className="contact-person-card-icon" />
            </a>
          )}
        </div>
      </div>
    </Fragment>
  );
}

type Props = {
  firstName?: string;
  lastName: string;
  onClick?: () => void;
  responsibilities?: string[];
  icon?: FunctionComponent<IconProps>;
  profilePicture?: ReactNode;
  phone?: string[];
  email?: string[];
  contractedResources?: string[];
  employeeId?: string;
  visitingCardId?: string;
};
