import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";

import "../../../styles/components/pages/management/PermissionsPage.scss";
import PermissionsGroupItem from "../../management/PermissionsGroupItem";
import {
  useGetGroupsQuery,
  useGetMyPermissionsQuery,
  useGetPermissionsListQuery,
} from "../../../api/customerApi";
import { useParams } from "react-router-dom";
import InfoText from "../../userSettings/InfoText";
import { Permission } from "../../../types";
export default function PermissionsPage(): JSX.Element {
  const { customerToken = "" } = useParams();
  const { data: groups } = useGetGroupsQuery({ customerToken });
  const { data: permissionsList } = useGetPermissionsListQuery({
    customerToken,
  });
  const { data: myPermissions, isFetching } = useGetMyPermissionsQuery({
    customerToken,
  });
  const [permissionsListState, setPermissionsListState] = useState<
    Permission[] | undefined
  >();
  const myPermissionsFiltered = myPermissions?.map((item) => item.internalCode);
  const canManageEmployeePermissions = myPermissionsFiltered?.includes(
    "CanManageEmployeePermissions",
  );

  useEffect(() => {
    if (permissionsList) {
      setPermissionsListState(permissionsList);
    }
  }, [permissionsList]);

  return (
    <div className="permissions-container-page">
      <Helmet>
        <title> Berechtigungen - Homecase</title>
      </Helmet>
      <PageHeader title="Berechtigungen" />
      {canManageEmployeePermissions && (
        <section className="permissions-container-info-wrapper">
          {groups?.map((item) => (
            <PermissionsGroupItem
              key={item.id}
              groupId={item.id}
              name={item.name}
              permissionsList={permissionsListState}
            />
          ))}
        </section>
      )}
      {!canManageEmployeePermissions && !isFetching && (
        <InfoText
          isError={true}
          textError="Sie sind nicht berechtigt, Rechte zu bearbeiten."
        />
      )}
    </div>
  );
}
