import React, { ReactElement } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { de } from "date-fns/locale/de";

registerLocale("de", de);
import Calendar from "../../icons/Calendar";

import "../../../styles/components/profileSettings/ProfileForm.scss";
import { FieldRenderProps } from "react-final-form";

export default function DatePickerComponent({
  input,
  meta,
  label,
  required,
  ...rest
}: FieldRenderProps<string | undefined, HTMLInputElement>): ReactElement {
  return (
    <section className="date-form-container">
      <label>
        {label && (
          <span className="date-title" data-testid="title">
            {label} {required && "*"}
          </span>
        )}
        <div>
          <Calendar className={"calendar"} />
          <DatePicker
            locale={"de"}
            {...input}
            {...rest}
            selected={input.value ? new Date(input.value) : undefined}
            dateFormat="dd.MM.yyyy"
            yearDropdownItemNumber={90}
            showYearDropdown={true}
            dropdownMode={"scroll"}
            scrollableYearDropdown={true}
            className={"profile-settings-container-form input"}
            maxDate={new Date()}
            placeholderText=""
          />
        </div>
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div
          className="date-form-field-error"
          role="alert"
          data-testid="date-form-field-error"
        >
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
