import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";
import { ReactElement } from "react";

import "../../../styles/components/common/form/TextInput.scss";

export default function TextInput({
  input,
  meta,
  className,
  label,
  required,
  infoText,
  ...rest
}: FieldRenderProps<string | undefined, HTMLInputElement>): ReactElement {
  const textInputClassName = classNames(className, {
    "text-input-field": true,
    "text-input-error": (meta.error || meta.submitError) && meta.touched,
  });

  return (
    <section className={textInputClassName}>
      <label>
        {label && (
          <span className="text-input-field-label" role="label">
            {label} {required && "*"}
            {infoText && <p className="info-text">{infoText}</p>}
          </span>
        )}
        <input {...input} {...rest} required={required} />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="text-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
