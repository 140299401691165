import { useParams } from "react-router-dom";
import { useGetLatestOnlineDocumentsQuery } from "../api/documentsApi";

export default function useFetchDocuments() {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { data = [], isFetching } = useGetLatestOnlineDocumentsQuery({
    customerToken,
    facilityObjectId,
  });

  return { data, isFetching };
}
