import DefaultFileIcon from "../../icons/DefaultFileIcon";
import DocFileIcon from "../../icons/DocFileIcon";
import XlsFileIcon from "../../icons/XlsFileIcon";
import PptFileIcon from "../../icons/PptFileIcon";
import ZipFileIcon from "../../icons/ZipFileIcon";
import DownloadIcon from "../../icons/DownloadIcon";

import "../../../styles/components/common/files/OtherFile.scss";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import useDownload from "../../../hooks/useDownload";

const fileTypeIcons = {
  "application/zip": ZipFileIcon,
  "application/msword": DocFileIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    DocFileIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    XlsFileIcon,
  "text/csv": XlsFileIcon,
  "application/msexcel": XlsFileIcon,
  "application/vnd.ms-powerpoint": PptFileIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    PptFileIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    PptFileIcon,
};

export default function OtherFile({
  file,
  width = 100,
  height = 100,
  downloadable,
  onClick,
}: Props): JSX.Element {
  const type = file.type;
  const url = useMemo(() => URL.createObjectURL(file), [file]);
  const handleDownload = useDownload(url, file.name, downloadable);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = fileTypeIcons[type] || DefaultFileIcon;
  const className = classNames({
    "other-file": true,
    "other-file-downloadable": downloadable,
  });

  const onClickHandler = useCallback(() => {
    onClick && onClick(file);
  }, [onClick, file]);

  return (
    <div
      className={className}
      data-testid="other-file"
      onClick={onClickHandler}
    >
      <Icon width={width} height={height} />
      <span className="other-file-download-icon" onClick={handleDownload}>
        <DownloadIcon />
      </span>
    </div>
  );
}

type Props = {
  file: File;
  width?: number;
  height?: number;
  downloadable?: boolean;
  onClick?: (file: File) => void;
};
