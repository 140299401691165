import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

export default function AgendaItemPropositionVotingResultSkeleton(): JSX.Element {
  return (
    <Fragment>
      <Skeleton height={32} width={300} />
      <div
        className="agenda-item-proposition-voting-result-legend"
        data-testid="agenda-item-proposition-voting-result-legend-skeleton"
      >
        <span className="agenda-item-proposition-voting-result-legend-red">
          Ja <Skeleton width={16} />
        </span>
        <span className="agenda-item-proposition-voting-result-legend-green">
          Nein <Skeleton />
        </span>
        <span className="agenda-item-proposition-voting-result-legend-grey">
          Enthaltung <Skeleton width={16} />
        </span>
        <span className="agenda-item-proposition-voting-result-legend-lightgrey">
          Nicht abgestimmt <Skeleton width={16} />
        </span>
      </div>
      <table className="agenda-item-proposition-voting-result-details">
        <tbody>
          <tr>
            <td className="agenda-item-proposition-voting-result-details-title">
              Stimmrecht
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="agenda-item-proposition-voting-result-details-title">
              Teilgenommen
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}
