import { useMemo } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import Select from "../common/form/Select";
import {
  ActivityTemplate,
  FacilityObject,
  FacilityUnitWithActiveTenancyOrOwnership,
} from "../../types";
import TextArea from "../common/form/TextArea";
import Button from "../common/Button";
import Send from "../icons/Send";
import useCreateCustomFields from "../../hooks/messages/useCreateCustomFields";
import LoadingSpinner from "../icons/LoadingSpinner";
import validate, {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
  validateRequired,
} from "../../lib/formValidate";
import FileInput from "../common/form/FileInput";
import FilePreview from "../common/form/FilePreview";

export default function MessageCreateForm({
  handleSubmit,
  pristine,
  submitting,
  facilityObject,
  facilityUnits,
  templates,
}: Props): JSX.Element {
  const tenancyOrOwnershipOptions = useMemo(
    () => [
      { label: "Einheit auswählen", value: "" },
      {
        label: "Kein Bezug zu einer Einheit",
        value: "_",
      },
      ...facilityUnits.map(({ description, activeTenancyOrOwnership }) => ({
        label: description,
        value: activeTenancyOrOwnership.tenancyOrOwnershipId,
      })),
    ],
    [templates],
  );

  const activityTemplateOptions = useMemo(
    () => [
      { label: "Anliegen auswählen", value: "" },
      ...templates.map(({ id, description }) => ({
        label: description,
        value: id,
      })),
    ],
    [templates],
  );

  const {
    setActivityTemplateId,
    customFields,
    isFetching,
    activityTemplateId,
  } = useCreateCustomFields();

  const isDisabled = isFetching || !activityTemplateId;

  return (
    <form
      className="message-create-form"
      onSubmit={handleSubmit}
      role="form"
      noValidate
    >
      <div>Objekt: {facilityObject.street}</div>
      <Field
        component={Select}
        name="tenancyOrOwnershipId"
        options={tenancyOrOwnershipOptions}
        label="Auf welche Einheit bezieht sich Ihr Anliegen?"
        required
        validate={validate(validateRequired("Bitte wählen Sie eine Einheit!"))}
        disabled={isFetching}
      />
      <Field
        component={Select}
        name="activityTemplateId"
        options={activityTemplateOptions}
        label="Bitte nennen Sie uns Ihr Anliegen"
        required
        validate={validate(validateRequired("Bitte wählen Sie ein Anliegen!"))}
        disabled={isFetching}
      />
      <section
        className={`message-create-form-fields-${
          isDisabled ? "hidden" : "visible"
        }`}
      >
        {customFields.map((props, key) => (
          <Field key={key} {...props} disabled={isDisabled} />
        ))}
        {customFields.length === 0 && (
          <Field
            component={TextArea}
            name="text"
            label="Ihre Anfrage an uns"
            required
            disabled={isDisabled}
            rows={5}
            validate={validate(validateRequired())}
          />
        )}
        <fieldset className="message-create-form-upload">
          <legend className="message-create-form-upload-label">
            Bilder und Anhänge
          </legend>
          <Field
            component={FilePreview}
            name="documents"
            subscription={{ error: true, value: true, touched: true }}
          />
          <Field
            component={FileInput}
            name="documents"
            disabled={isDisabled}
            validate={validateFiles(
              validateFileType(),
              validateFileFirstBytes(),
            )}
          />
        </fieldset>
        <div className="message-create-form-actions">
          <Button
            label="Nachricht absenden"
            lightblue
            leadingIcon={Send}
            type="submit"
            disabled={isFetching || pristine || submitting}
          />
        </div>
        <OnChange name="activityTemplateId">
          {(value) => {
            setActivityTemplateId(value);
          }}
        </OnChange>
        {isFetching && (
          <div className="message-create-loading">
            <LoadingSpinner />
          </div>
        )}
      </section>
    </form>
  );
}

type Props = {
  facilityObject: FacilityObject;
  facilityUnits: FacilityUnitWithActiveTenancyOrOwnership[];
  templates: ActivityTemplate[];
} & FormRenderProps;
