import type { PayloadAction } from "@reduxjs/toolkit";
import { createDraftSafeSelector, createSlice, Draft } from "@reduxjs/toolkit";
import { FILE_UPLOAD_REDUCER_PATH } from "../reducerPaths";
import { ApiEndpointMutation } from "@reduxjs/toolkit/dist/query/core/module";
import { UploadState } from "../enums";
import { RootState } from "../store";

export type FileUploadState = {
  files: FileUploadStateItem[];
};

export type FileUploadStateItem = {
  referenceId: string | number;
  customerToken: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endpoint: ApiEndpointMutation<any, any>;
  file: File;
  uploadResult?: { id: string }[];
  error?: {
    status: number;
    data: string;
  };
  onlineDateUTC?: string;
  uploadState: UploadState;
};

export type FileUploadAppendPayload = {
  referenceId: string | number;
  customerToken: string;
  onlineDateUTC?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endpoint: ApiEndpointMutation<any, any>;
  files: File[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type FileUploadUploadStatePayload = {
  referenceId: string | number;
  uploadResult?: { id: string }[];
  uploadState: UploadState;
  error?: {
    status: number;
    data: string;
  };
  file: File;
};

export type FileUploadRemovePayload = {
  name: string;
};

export type AfterFileUploadPayload = {
  afterUpload: () => void;
};

export const initialState: FileUploadState = {
  files: [],
};

export const fileUploadSlice = createSlice({
  name: FILE_UPLOAD_REDUCER_PATH,
  initialState,
  reducers: {
    append: (
      state: Draft<FileUploadState>,
      action: PayloadAction<FileUploadAppendPayload>,
    ) => {
      const { referenceId, endpoint, files, customerToken, ...rest } =
        action.payload;

      state.files = files.map((file) => ({
        file,
        endpoint,
        referenceId,
        customerToken,
        ...rest,
        uploadState: UploadState.Ready,
      }));
    },
    updateUploadState: (
      state: Draft<FileUploadState>,
      action: PayloadAction<FileUploadUploadStatePayload>,
    ) => {
      const { referenceId, file, uploadState, error, uploadResult } =
        action.payload;

      state.files = state.files.map((item) => {
        if (item.referenceId === referenceId && item.file === file) {
          return { ...item, uploadState, error, uploadResult };
        }
        return item;
      });
    },
    remove: (
      state: Draft<FileUploadState>,
      action: PayloadAction<FileUploadRemovePayload>,
    ) => {
      const { name } = action.payload;
      state.files = state.files.filter((item) => item.file.name !== name);
    },
  },
});

export const { append, updateUploadState, remove } = fileUploadSlice.actions;

const selectFileUpload = (state: RootState) => state.fileUpload.files;

export const selectFileByReferenceId = (referenceId: string | number) =>
  createDraftSafeSelector(selectFileUpload, (items: FileUploadStateItem[]) =>
    items
      .filter((item) => item.referenceId === referenceId)
      .map(({ file, uploadState, error, uploadResult }) => ({
        file,
        uploadState,
        error,
        uploadResult,
      })),
  );

export const isUploadCompletedOrFailure = (referenceId: string) =>
  createDraftSafeSelector(selectFileUpload, (items: FileUploadStateItem[]) => {
    const referenceItem = items.filter(
      (item) => item.referenceId === referenceId,
    );
    const completedOrFailureItems = referenceItem.filter((item) =>
      [UploadState.Completed, UploadState.Failure].includes(item.uploadState),
    );

    return (
      referenceItem.length !== 0 &&
      referenceItem.length === completedOrFailureItems.length
    );
  });

export default fileUploadSlice.reducer;
