import { useParams } from "react-router-dom";
import { useGetTermsOfUseIsAcceptedQuery } from "../../api/termsOfUseApi";

export default function useTermsOfUseAccepted() {
  const { customerToken = "" } = useParams();
  const {
    data = null,
    isError,
    isLoading,
  } = useGetTermsOfUseIsAcceptedQuery(
    {
      customerToken,
    },
    { skip: !customerToken },
  );

  return { accepted: data, isError, isLoading };
}
