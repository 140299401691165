import { Customer, NavigationItem } from "../../types";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCustomerLogo } from "../../lib";
import NavigationSubItemsList from "./NavigationSubItemsList";
import useGetNaviSubItems from "../../hooks/useGetNaviSubItems";
import classNames from "classnames";
import { useGetFacilityObjectsQuery } from "../../api/facilityManagementApi";
import { FacilityObjectQueryFilterType } from "../../enums";

export default function NavigationListItem({
  item,
  managementItem,
  facilityObjectId,
}: Props): ReactElement {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const stateSelectSubItem = item?.subItems ? item.subItems[0] : undefined;
  const [selectSubItem, setSelectedSubItem] = useState<
    NavigationItem | undefined
  >(stateSelectSubItem);
  const customerToken = managementItem ? managementItem.token : "";
  const filter = [
    FacilityObjectQueryFilterType.Online,
    FacilityObjectQueryFilterType.CurrentlyManaged,
  ].join(",");
  const { data: facilityObjects } = useGetFacilityObjectsQuery(
    {
      customerToken,
      filter,
    },
    { skip: customerToken === "" || undefined },
  );
  const facilityObjectsLength = facilityObjects?.length
    ? facilityObjects.length
    : 0;
  const Icon = item?.icon;
  const iconCustomerLogo = getCustomerLogo(managementItem);
  const collapsed = window.location.pathname.endsWith("kontakte/");
  const [isCollapsed, setIsCollapsed] = useState(!collapsed);
  const { getSubItems } = useGetNaviSubItems();

  const pathnameEndsWith = item?.subItems?.filter((item) => {
    if (pathname.endsWith(item.to)) {
      return item;
    }
  });

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    if (item?.title === "Kontakte" && selectSubItem === undefined) {
      setSelectedSubItem(stateSelectSubItem);
    }
  }, [isCollapsed]);

  const subItemsList = useMemo(() => {
    if (item?.title === "Kontakte" && item?.subItems?.length !== 0) {
      return item?.subItems?.filter((item) => getSubItems.includes(item.title));
    } else {
      return item?.subItems;
    }
  }, [getSubItems, item]);

  useEffect(() => {
    if (!isCollapsed) {
      setSelectedSubItem(subItemsList?.find(({ to }) => pathname.endsWith(to)));
    }
  }, [pathname, isCollapsed]);

  useEffect(() => {
    if (!isCollapsed && selectSubItem) {
      navigate(selectSubItem.to);
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (item && pathnameEndsWith?.length === 0 && !pathname.endsWith(item.to)) {
      setIsCollapsed(true);
    }
  }, [pathname]);

  return (
    <Fragment>
      {!facilityObjectId && (
        <li className="navigation-list-item" onClick={toggleCollapsed}>
          <NavLink
            className={classNames({
              "navigation-list-item-link": true,
              active:
                isCollapsed &&
                pathnameEndsWith?.length !== 0 &&
                item?.title === "Kontakte",
            })}
            role="link"
            to={item?.to || ""}
            reloadDocument={!item?.navigate}
          >
            {Icon && (
              <Icon
                className="navigation-list-item-icon"
                width={30}
                height={30}
              />
            )}
            {!Icon && <span className="navigation-list-item-circle" />}
            <span className="navigation-list-item-title">{item?.title}</span>
          </NavLink>
        </li>
      )}
      {item?.subItems && subItemsList && (
        <NavigationSubItemsList
          items={subItemsList}
          isCollapsed={isCollapsed}
          parent={item}
          setIsCollapsed={setIsCollapsed}
        />
      )}

      {facilityObjectId && facilityObjectsLength > 0 && (
        <li className="navigation-list-item">
          <NavLink
            className="navigation-list-item-link"
            role="link"
            to={managementItem?.token ? `../${managementItem?.token}` : ""}
          >
            {iconCustomerLogo && (
              <div className={"navigation-list-item-icon-container"}>
                <img
                  className="navigation-list-item-icon"
                  width={65}
                  height={30}
                  {...iconCustomerLogo}
                  alt="Logo"
                />
              </div>
            )}
            {!iconCustomerLogo && (
              <span className="navigation-list-item-circle" />
            )}
            <div className="navigation-list-item-container">
              <span className="navigation-list-item-title">
                {managementItem?.name}
              </span>
              {facilityObjectsLength > 0 ? (
                <span>
                  {facilityObjectsLength} Objekt
                  {facilityObjectsLength > 1 && "e"}
                </span>
              ) : (
                <div />
              )}
            </div>
          </NavLink>
        </li>
      )}
    </Fragment>
  );
}

type Props = {
  item?: NavigationItem;
  managementItem?: Customer;
  facilityObjectId?: boolean;
};
