import { ReactElement, SyntheticEvent, useCallback, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { Document } from "../../../types";
import TextInput from "../../common/form/TextInput";

import "../../../styles/components/management/objectSettings/ObjectDocumentEditForm.scss";
import {
  useGetOnlineDocumentCategoriesQuery,
  useUpdateOnlineDocumentMutation,
} from "../../../api/documentsApi";
import { useParams } from "react-router-dom";
import Select from "../../common/form/Select";
import CheckboxField from "../../common/form/CheckboxField";
import { OnlineDocumentPermissionType } from "../../../enums";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import Button from "../../common/Button";
import Checkmark from "../../icons/Checkmark";
import { FORM_ERROR } from "final-form";

export default function ObjectDocumentEditFormDialog({
  document,
  onCancel,
}: Props): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data = [] } = useGetOnlineDocumentCategoriesQuery({ customerToken });
  const [updateDocument] = useUpdateOnlineDocumentMutation();

  const handleOnSubmit = useCallback(
    async ({ category, description, ...rest }: Values) => {
      const newCategory =
        data.find(({ id }) => id === Number(category))?.name ||
        document.category;
      const updatedDocument = {
        ...document,
        description,
        category: newCategory,
        tenantCanSee: rest[OnlineDocumentPermissionType.TenantCanSee],
        objectOwnerCanSee: rest[OnlineDocumentPermissionType.ObjectOwnerCanSee],
        serviceProviderCanSee:
          rest[OnlineDocumentPermissionType.ServiceProviderCanSee],
      };
      const response = await updateDocument({
        document: updatedDocument,
        customerToken,
        facilityObjectId,
      });

      if ("error" in response) {
        return { [FORM_ERROR]: response.error };
      }
      onCancel(undefined);
    },
    [data],
  );

  const categories = useMemo(
    () =>
      data.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [data],
  );

  const initialValues = {
    category: String(
      categories?.find(({ label }) => label === document.category)?.value,
    ),
    description: document.description,
    [OnlineDocumentPermissionType.TenantCanSee]: document.tenantCanSee,
    [OnlineDocumentPermissionType.ObjectOwnerCanSee]:
      document.objectOwnerCanSee,
    [OnlineDocumentPermissionType.ServiceProviderCanSee]:
      document.serviceProviderCanSee,
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ConfirmDialog
              title="Dokument bearbeiten"
              description={
                <div className="object-document-edit-form">
                  <h4>{document.originalFileName}</h4>
                  <Field
                    name="category"
                    component={Select}
                    label="Kategorie"
                    options={categories}
                  />

                  <Field
                    name="description"
                    component={TextInput}
                    label="Beschreibung"
                    placeholder="Füge eine optionale Beschreibung hinzu"
                  />
                  <span className="object-document-edit-form-permissions-label">
                    Berechtigungen
                  </span>
                  <div className="object-document-edit-form-permissions">
                    <Field
                      type="checkbox"
                      name={OnlineDocumentPermissionType.TenantCanSee}
                      component={CheckboxField}
                      label="Mieter"
                    />
                    <Field
                      type="checkbox"
                      name={OnlineDocumentPermissionType.ObjectOwnerCanSee}
                      component={CheckboxField}
                      label="Hauseigentümer"
                    />
                    <Field
                      type="checkbox"
                      name={OnlineDocumentPermissionType.ServiceProviderCanSee}
                      component={CheckboxField}
                      label="Dienstleister"
                    />
                  </div>
                </div>
              }
              showCloseButton
              confirmComponent={
                <Button
                  label="Übernehmen"
                  leadingIcon={Checkmark}
                  lightblue
                  data-testid="submit-button"
                />
              }
              onCancel={onCancel}
              onConfirm={handleSubmit}
            />
          </form>
        );
      }}
    />
  );
}

type Props = {
  document: Document;
  onCancel: (e: SyntheticEvent | undefined) => void;
};

type Values = {
  category: string;
  description: string;
  [OnlineDocumentPermissionType.TenantCanSee]: boolean;
  [OnlineDocumentPermissionType.ObjectOwnerCanSee]: boolean;
  [OnlineDocumentPermissionType.ServiceProviderCanSee]: boolean;
};
