import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Participant } from "../../../types";
import ProfilePicture from "../../common/ProfilePicture";
import Button from "../../common/Button";
import {
  ParticipantAttendanceType,
  ParticipantDelegationType,
  PropositionVoteType,
  SynchronisationMethod,
} from "../../../enums";
import useVoteForParticipant from "../../../hooks/meetings/useVoteForParticipant";
import ErrorDialog from "../../common/dialog/ErrorDialog";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import ParticipantsVotingEligibilityName from "../participants/ParticipantsVotingEligibilityName";
import LoadingSpinner from "../../icons/LoadingSpinner";
import InformationBox from "../../common/InformationBox";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";

import "../../../styles/components/meetings/participants/ParticipantsVotingEligibilityList.scss";
import IconButton from "../../common/IconButton";
import DeleteIcon from "../../icons/DeleteIcon";
import classNames from "classnames";
import { getParticipantDisplayName } from "../../../lib/meeting";

export default function PropositionVoteControlCenterTableRow({
  participant,
  propositionId,
  delegatedParticipant,
  votingDisabled,
}: Props): JSX.Element {
  const { voteForParticipant, error, isLoading, retry, isSuccess, isError } =
    useVoteForParticipant(participant.id, propositionId);

  const { sync } = useSynchronisation();

  const [
    showVoteForParticipantConfirmDialog,
    setShowVoteForParticipantConfirmDialog,
  ] = useState(false);

  const [participantVote, setParticipantVote] = useState(
    PropositionVoteType.None.toString(),
  );

  const voteForParticipantWithConfirmation = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setParticipantVote(e.currentTarget.value);
      setShowVoteForParticipantConfirmDialog(true);
    },
    [],
  );

  const handleProcessVoteForParticipant = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.currentTarget.value = participantVote;
      voteForParticipant(e);
      setShowVoteForParticipantConfirmDialog(false);
    },
    [participantVote],
  );

  const handleCancelVoteForParticipant = useCallback(() => {
    setShowVoteForParticipantConfirmDialog(false);
  }, []);

  const participantAttend =
    participant.attendance !== ParticipantAttendanceType.Abscent;

  const isShareOfVotesNull =
    !participant.participantVotingWeight?.qualificationValue?.value ||
    (participant.participantVotingWeight?.doubleQualificationValue &&
      !participant.participantVotingWeight?.doubleQualificationValue?.value);

  const basedOnInstruction = participant.participantVoting?.basedOnInstruction;
  const buttonClassName = classNames({
    deactivated: basedOnInstruction,
  });

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.PropositionVotingChanged, propositionId);
    }
  }, [isSuccess]);

  return (
    <div
      role="row"
      key={participant.id}
      className="agenda-item-proposition-vote-control-center-table-row"
    >
      <div
        className="agenda-item-proposition-vote-control-center-table-row-name"
        data-testid="participant-name"
        role="cell"
      >
        <ProfilePicture
          alt={participant.lastName}
          userSid={participant.userSid}
          onlineState={participant.attendance}
          width={32}
          height={32}
          rounded
        />
        <ParticipantsVotingEligibilityName participant={participant} />

        {basedOnInstruction && (
          <span className="agenda-item-proposition-vote-control-center-table-row-name-warning-info">
            Durch Weisung gesetzt
          </span>
        )}
      </div>
      <div
        role="cell"
        className="agenda-item-proposition-vote-control-center-table-row-delegate"
      >
        {delegatedParticipant && !participantAttend && (
          <div
            className="agenda-item-proposition-vote-control-center-table-row-delegate-participant"
            data-testid="delegate-participant"
          >
            <ProfilePicture
              alt={delegatedParticipant.lastName}
              userSid={delegatedParticipant.userSid}
              onlineState={delegatedParticipant.attendance}
              width={32}
              height={32}
              rounded
            />
            <ParticipantsVotingEligibilityName
              participant={delegatedParticipant}
            />
          </div>
        )}
      </div>
      {!isShareOfVotesNull ? (
        <div
          className="agenda-item-proposition-vote-control-center-table-row-actions"
          data-testid="participant-actions"
          role="cell"
        >
          <IconButton
            icon={DeleteIcon}
            onClick={
              participant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToEmployee
                ? voteForParticipant
                : voteForParticipantWithConfirmation
            }
            disabled={
              votingDisabled ||
              participant.participantVoting?.basedOnInstruction
            }
          />
          <Button
            label="Ja"
            green={[PropositionVoteType.Yes, PropositionVoteType.None].includes(
              participant.participantVoting?.vote,
            )}
            value={PropositionVoteType.Yes}
            disabled={votingDisabled}
            onClick={
              participant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToEmployee
                ? voteForParticipant
                : voteForParticipantWithConfirmation
            }
            className={buttonClassName}
            data-testid="yes-vote-button"
          />
          <Button
            label="Nein"
            red={[PropositionVoteType.No, PropositionVoteType.None].includes(
              participant.participantVoting?.vote,
            )}
            disabled={votingDisabled}
            value={PropositionVoteType.No}
            onClick={
              participant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToEmployee
                ? voteForParticipant
                : voteForParticipantWithConfirmation
            }
            className={buttonClassName}
            data-testid="no-vote-button"
          />
          <Button
            label="Enthaltung"
            grey={[
              PropositionVoteType.Abstention,
              PropositionVoteType.None,
            ].includes(participant.participantVoting?.vote)}
            disabled={votingDisabled}
            value={PropositionVoteType.Abstention}
            onClick={
              participant.delegationType ===
              ParticipantDelegationType.VotingEligibilityDelegatedToEmployee
                ? voteForParticipant
                : voteForParticipantWithConfirmation
            }
            className={buttonClassName}
            data-testid="abstention-vote-button"
          />
          {isLoading && (
            <div className="agenda-item-proposition-vote-control-center-table-row-actions-updating">
              <LoadingSpinner width={32} height={32} />
              <h3>Stimme wird gespeichert</h3>
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            "agenda-item-proposition-vote-control-center-table-row-actions"
          }
        >
          <InformationBox
            data-testid="participant-information"
            text="Kein Stimmrecht für laufende Abstimmung."
          />
        </div>
      )}

      {showVoteForParticipantConfirmDialog && (
        <ConfirmDialog
          description={`Sie haben keine Vollmacht für den Teilnehmer '${getParticipantDisplayName(participant)}' erhalten. Möchten Sie trotzdem fortfahren und die Stimme eintragen?`}
          title="Es wurde keine Vollmacht erteilt"
          confirmLabel="  Ja  "
          onConfirm={handleProcessVoteForParticipant}
          cancelLabel="Nein"
          onCancel={handleCancelVoteForParticipant}
        />
      )}

      {!isLoading && isError && (
        <ErrorDialog
          description={`Stimme für ${participant.firstName} ${participant.lastName} konnte nicht gespeichert werden.`}
          title="Stimme konnte nicht gespeichert werden."
          onRetry={retry}
          error={error}
        />
      )}
    </div>
  );
}

type Props = {
  participant: Participant;
  propositionId: string;
  delegatedParticipant?: Participant;
  votingDisabled?: boolean;
  forOwner?: boolean;
};
