import React from "react";
import { IconProps } from "../../types";

export default function Report({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M14.5 6c.414 0 .75.332.75.74V8h9c.38 0 .693.279.743.64l.007.1c0 .41-.336.741-.75.741H24v10.075a1.45 1.45 0 0 1-1.32 1.437l-.142.007h-5.931l1.532 2.808c.455.109.803.49.854.96l.007.12c0 .615-.504 1.112-1.125 1.112a1.118 1.118 0 0 1-1.125-1.111c0-.132.023-.26.066-.377L14.902 21h-.805l-1.913 3.512c.03.081.05.167.06.256l.006.12c0 .615-.504 1.112-1.125 1.112A1.118 1.118 0 0 1 10 24.889c0-.524.367-.963.861-1.08L12.392 21h-5.93c-.76 0-1.385-.573-1.455-1.305L5 19.555V9.482h-.25a.747.747 0 0 1-.743-.64L4 8.741C4 8.33 4.336 8 4.75 8h9V6.74c0-.374.282-.684.648-.733zm6.363 5.1a.736.736 0 0 0-1.034 0l-3.867 3.823-1.676-1.656a.736.736 0 0 0-1.033 0l-2.407 2.378-.945-.933a.736.736 0 0 0-1.033 0 .715.715 0 0 0 0 1.02l1.461 1.445a.733.733 0 0 0 1.034 0l2.406-2.378 1.676 1.656a.736.736 0 0 0 1.033 0l4.385-4.333a.715.715 0 0 0 0-1.021z"
        fillRule="evenodd"
      />
    </svg>
  );
}
