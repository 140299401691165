import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import PdfStyles from "./PdfStyles";
export default function PdfDocumentMeetingHeader({
  meetingTitle,
  meetingLocation,
  formattedStartDate,
  formattedEndDate,
  logoStamp,
  meetingLeader,
  startDate,
}: PdfDocumentMeetingHeaderProps) {
  const formattedDate = moment(startDate).format("DD.MM.YYYY");
  return (
    <View>
      <Image style={PdfStyles.img} src={logoStamp} />
      <Text style={PdfStyles.header}>
        Zusammenfassung der Eigentümerversammlung
      </Text>
      <Text style={PdfStyles.meetingTitle}>
        {meetingTitle} vom {formattedDate}
      </Text>
      <View style={PdfStyles.rowMeetingInfo}>
        <View style={PdfStyles.column1}>
          <Text style={PdfStyles.heading}>Ort</Text>
          <Text>{meetingLocation}</Text>
        </View>
        <View style={PdfStyles.column2}>
          <Text style={PdfStyles.heading}>Startuhrzeit</Text>
          <Text>{formattedStartDate} Uhr</Text>
        </View>

        <View style={PdfStyles.column2}>
          <Text style={PdfStyles.heading}>Enduhrzeit</Text>
          {formattedEndDate !== "Invalid date" ? (
            <Text>{formattedEndDate} Uhr</Text>
          ) : (
            ""
          )}
        </View>

        <View style={PdfStyles.column3}>
          <Text style={PdfStyles.heading}>Versammlungsleiter</Text>
          <Text>{meetingLeader}</Text>
        </View>
      </View>
      <View style={PdfStyles.separator}></View>
    </View>
  );
}

type PdfDocumentMeetingHeaderProps = {
  meetingTitle: string;
  meetingLocation: string;
  formattedStartDate: string;
  formattedEndDate: string;
  logoStamp?: string;
  meetingLeader: string;
  startDate?: Date;
};
