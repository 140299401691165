import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { Field, Form } from "react-final-form";
import TextInput from "../common/form/TextInput";
import {
  useGetUserProfileQuery,
  usePostEmailChangeMutation,
} from "../../api/userApi";
import InfoText from "./InfoText";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";

export default function AccountEmailForm(): JSX.Element {
  const { user } = useGetUsersMyProfile();
  const [newEmail, setNewEmail] = useState("");

  const [postEmailChange, { isSuccess, isError }] =
    usePostEmailChangeMutation();

  const { data: userProfile } = useGetUserProfileQuery({
    userSid: user.sid,
  });

  const handleSubmitChangeEmail = async (values: Record<string, string>) => {
    await postEmailChange({
      userSid: user.sid,
      email: {
        newEmail: values.email,
      },
    });
    setNewEmail(values.email);
  };

  return (
    <Form
      onSubmit={(values) => handleSubmitChangeEmail(values)}
      render={({ handleSubmit, form, submitSucceeded }) => {
        useEffect(() => {
          form.reset();
        }, [submitSucceeded]);

        return (
          <form
            onSubmit={handleSubmit}
            className="user-settings-account-container-form email"
          >
            <div>
              {isSuccess || isError ? (
                <div className="user-settings-account-container-form-fields">
                  <InfoText
                    textSuccess={`Wir haben eine E-Mail an ${newEmail} geschickt. Bitte klicken Sie
                  auf den darin enthaltenen Link, um Ihre neue E-Mail-Adresse zu bestätigen.`}
                    textError={
                      "Bitte geben Sie eine gültige E-Mail-Adresse ein."
                    }
                    isSuccess={isSuccess}
                    isError={isError}
                  />
                </div>
              ) : (
                <div>
                  <div className="user-settings-account-container-form-email">
                    <span>Derzeitige E-Mail-Adresse:</span>
                    <span>{userProfile?.email}</span>
                  </div>
                  <div>
                    <Field
                      className="user-settings-account-container-form-email-input"
                      name="email"
                      type="email"
                      component={TextInput}
                      rows={1}
                      label="Neue E-Mail-Adresse:"
                      placeholder="Neue Email-Adresse eingeben"
                    />
                  </div>
                  <Button
                    lightblue
                    label="E-Mail-Adresse ändern"
                    className="user-settings-account-container-button"
                  />
                </div>
              )}
            </div>
          </form>
        );
      }}
    />
  );
}
