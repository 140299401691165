import React from "react";
import { IconProps } from "../../../types";

export default function ServicesLawLawyer({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M73.794 77.891a.827.827 0 0 0-.5-.167H23.336a3.333 3.333 0 0 0 0 6.667H43.72c.236 0 .46.1.617.276 2 2.208 2 5.573 0 7.78a.827.827 0 0 1-.617.277H23.336a3.333 3.333 0 1 0 0 6.666h49.998c.18 0 .355-.057.5-.163a13.303 13.303 0 0 0-.036-21.333l-.004-.003z" />
        <path d="M88.334 77.724h-8.16a.833.833 0 0 0-.666 1.314 16.58 16.58 0 0 1 0 19.042.833.833 0 0 0 .666 1.31h8.16c5.983 0 10.833-4.85 10.833-10.833 0-5.983-4.85-10.833-10.833-10.833zM33.336 60.998A13.043 13.043 0 0 0 46.67 47.666a3.527 3.527 0 0 0-.273-1.437l-7.493-17.4a.833.833 0 0 1 .766-1.163h16.166c.46 0 .833.373.833.834v28.998a.83.83 0 0 1-.586.797 13.31 13.31 0 0 0-9.413 12.703c0 .92.746 1.667 1.666 1.667h23.333c.92 0 1.666-.747 1.666-1.667a13.31 13.31 0 0 0-9.413-12.693.833.833 0 0 1-.586-.797V28.51c0-.46.373-.834.833-.834h16.193a.833.833 0 0 1 .766 1.16l-7.526 17.63s-.267 1.063-.267 1.2c0 7.363 5.97 13.332 13.333 13.332 7.364 0 13.333-5.969 13.333-13.332 0-.147-.26-1.184-.267-1.2l-10-23.43A3.517 3.517 0 0 0 86.668 21h-53.33a3.523 3.523 0 0 0-3.06 2c-.517 1.24-10.274 23.336-10.274 24.666a13.043 13.043 0 0 0 13.333 13.332zM81.544 44.84l4.357-10.213a.833.833 0 0 1 1.533 0L91.79 44.84a.833.833 0 0 1-.766 1.16H82.31a.833.833 0 0 1-.767-1.16zm-53.411 0 4.436-10.31a.837.837 0 0 1 1.534 0l4.436 10.307a.837.837 0 0 1-.766 1.163H28.9a.837.837 0 0 1-.767-1.163v.003z" />
      </g>
    </svg>
  );
}
