import React from "react";
import { IconProps } from "../../types";

export default function Stop({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M19.583 5.75a4.667 4.667 0 0 1 4.667 4.667v9.166a4.667 4.667 0 0 1-4.667 4.667h-9.166a4.667 4.667 0 0 1-4.667-4.667v-9.166a4.667 4.667 0 0 1 4.667-4.667zm0 2h-9.166a2.667 2.667 0 0 0-2.667 2.667v9.166a2.667 2.667 0 0 0 2.667 2.667h9.166a2.667 2.667 0 0 0 2.667-2.667v-9.166a2.667 2.667 0 0 0-2.667-2.667zm-7.793 4.502c0-1.934 1.894-3.3 3.729-2.688l2.569.856a2.833 2.833 0 0 1 1.938 2.688v3.756a4.163 4.163 0 0 1-4.162 4.163h-1.491a4.164 4.164 0 0 1-3.332-1.667L9.24 16.954a2.295 2.295 0 0 1 2.442-3.59l.107.033zm3.096-.79a.833.833 0 0 0-1.096.79v3.206c0 .891-1.077 1.337-1.707.708l-.797-.796a.295.295 0 0 0-.445.386l1.8 2.404c.41.546 1.05.867 1.732.867h1.49a2.163 2.163 0 0 0 2.163-2.163v-3.756a.834.834 0 0 0-.57-.79z"
        fillRule="evenodd"
      />
    </svg>
  );
}
