import { ActivityContact } from "../../types";
import MessageDetailsContactItem from "./MessageDetailsContactItem";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { useEffect, useRef } from "react";

export default function MessageDetailsContacts({ contacts }: Props) {
  const [firstContact] = contacts;
  const ref = useRef(null);
  const listRef = useRef<ViewportListRef>(null);
  const itemsCount = contacts.length;

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToIndex({ index: itemsCount });
    }
  }, [itemsCount, listRef]);

  return (
    <div className="message-details-contacts" ref={ref}>
      <ViewportList viewportRef={ref} ref={listRef} items={contacts}>
        {(contact) => (
          <MessageDetailsContactItem
            contact={contact}
            key={contact.id}
            odd={firstContact.createdByUser.sid !== contact.createdByUser.sid}
          />
        )}
      </ViewportList>
    </div>
  );
}

type Props = {
  contacts: ActivityContact[];
};
