import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";
import React, { ReactElement } from "react";
import { useGetFacilityObjectsQuery } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import FacilityObjectsTable from "../../management/FacilityObjectsTable";

import "../../../styles/components/pages/management/ObjectsPage.scss";
import SearchField from "../../common/SearchField";
import useSearchByObjects from "../../../hooks/home/useSearchByObjects";
import FetchApiError from "../../common/FetchApiError";
import ObjectsPageActionsButton from "../../management/ObjectsPageActionsButton";
import { FacilityObjectQueryFilterType } from "../../../enums";

export default function ObjectsPage(): ReactElement {
  const { customerToken = "" } = useParams();
  const {
    data = [],
    isFetching,
    error,
    isError = [],
  } = useGetFacilityObjectsQuery({
    customerToken,
    filter: [
      FacilityObjectQueryFilterType.CurrentlyManaged,
      FacilityObjectQueryFilterType.FutureManaged,
    ].join(","),
  });
  const { handleChangeSearchString, objectsList = [] } =
    useSearchByObjects(data);

  return (
    <div className="objects-container">
      <Helmet>
        <title> Verwaltungsbereich Objekte - Homecase</title>
      </Helmet>
      <PageHeader
        title="Objekte"
        searchComponent={SearchField}
        onSearch={handleChangeSearchString}
        actionComponent={ObjectsPageActionsButton}
      />
      <FacilityObjectsTable
        facilityObjects={objectsList}
        isFetching={isFetching}
      />
      {isError && <FetchApiError error={error} />}
    </div>
  );
}
