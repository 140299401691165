import React from "react";
import { IconProps } from "../../../types";

export default function ServicesSmokeDetector({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 21)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M20.867 12.25a32.076 32.076 0 0 0-9.38 7.569 3.39 3.39 0 1 0 5.159 4.4 25.466 25.466 0 0 1 7.409-6.001 3.385 3.385 0 1 0-3.188-5.967zM21.946 6.561A3.387 3.387 0 1 0 19.577.215 42.308 42.308 0 0 0 .758 14.135 3.385 3.385 0 1 0 6.11 18.28 35.539 35.539 0 0 1 21.946 6.56zM44.56 18.218a25.436 25.436 0 0 1 7.412 5.984 3.385 3.385 0 0 0 5.158-4.383 32.046 32.046 0 0 0-9.382-7.568 3.385 3.385 0 1 0-3.189 5.967zM46.671 6.561A35.563 35.563 0 0 1 62.508 18.28a3.39 3.39 0 0 0 5.368-4.143A42.308 42.308 0 0 0 49.024.215a3.387 3.387 0 1 0-2.37 6.346h.017z" />
        <circle cx="73.231" cy="37.236" r="6.769" />
        <path d="M75.56 46.233a8.8 8.8 0 0 1-4.641 0 .85.85 0 0 0-1.066.819v12.093c0 6.543-5.304 11.846-11.847 11.846h-4.349a.843.843 0 0 1-.626-1.418c8.076-8.86 8.844-22.166 1.84-31.896s-19.866-13.226-30.832-8.38C13.074 34.144 7.001 46.008 9.481 57.737c2.48 11.73 12.837 20.12 24.826 20.112L58 77.757c10.276-.01 18.604-8.339 18.615-18.615V47.048a.847.847 0 0 0-1.062-.819l.006.004zm-41.253 13a6.77 6.77 0 1 1 0-13.539 6.77 6.77 0 0 1 0 13.54z" />
      </g>
    </svg>
  );
}
