import React from "react";
import { IconProps } from "../../types";

export default function KeyIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M15.803 14.743a5.397 5.397 0 0 1-8.288 6.85 5.396 5.396 0 0 1 6.86-8.25l7.05-7.05 1.415 1.414-.892.892 2.398 2.398-4.212 4.211-2.398-2.398-1.933 1.933zm-2.047.659a3.396 3.396 0 0 0-4.761.04 3.394 3.394 0 0 0-.05 4.754 3.396 3.396 0 1 0 4.81-4.794zm6.778-5.39l-1.383 1.384.983.984 1.384-1.383-.984-.984z"
        fillRule="evenodd"
      />
    </svg>
  );
}
