import useBulletinBoard from "../../hooks/bulletinBoard/useBulletinBoard";
import CreatePost from "./CreatePost";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import { UserRoles } from "../../enums";

export default function PostListHeader(): JSX.Element {
  const { data } = useBulletinBoard();
  const roles = useCustomerRoles();
  const canPost = roles.includes(UserRoles.Tenant)
    ? data?.tenantsCanPost
    : data?.canPost;
  const canMultiPost = roles.includes(UserRoles.Employee);

  return (
    <div className="bulletin-board-post-list-header">
      <h3>BEITRÄGE</h3>
      {canPost && (
        <div className="bulletin-board-post-list-create">
          <CreatePost multiPost={canMultiPost} />
        </div>
      )}
    </div>
  );
}
