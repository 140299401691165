import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import { PropositionLegalValidityType } from "../../../enums";
import { PropositionsResultForPDF } from "../../../types";
import PdfStyles from "./PdfStyles";

export default function PdfDocumentResolutionInfo({ item }: Props) {
  return (
    <Fragment>
      <View>
        <Text style={PdfStyles.title}>Beschluss</Text>
      </View>
      <View>
        <Text style={PdfStyles.text}>Beschluss gefasst</Text>
      </View>
      {item.decisionLegalValidity === PropositionLegalValidityType.Valid && (
        <View>
          <Text style={PdfStyles.text}>Beschluss rechtskräftig</Text>
        </View>
      )}
      {item.statusTitle !== "(kein Status)" && (
        <View>
          <Text style={PdfStyles.text}>
            Status der Abstimmung: {item.statusTitle}
          </Text>
        </View>
      )}
      {item.categoryTitle !== "(keine Kategorie)" && (
        <View>
          <Text style={PdfStyles.text}>
            Kategorie der Abstimmung: {item.categoryTitle}
          </Text>
        </View>
      )}
    </Fragment>
  );
}

type Props = {
  item: PropositionsResultForPDF;
};
