import React from "react";
import Skeleton from "react-loading-skeleton";
import "../../styles/components/skeleton/AgandaListSkeleton.scss";

export default function ObjectsListCardSkeleton(): JSX.Element {
  return (
    <div className="object-list-card" data-testid="object-list-card-skeleton">
      <div style={{ width: "100%", height: "100%", padding: "16px" }}>
        <Skeleton width={250} height={150} />
      </div>

      <div className="object-list-card-info-wrapper">
        <div className="object-list-card-info">
          <Skeleton width={220} height={20} />
          <span className="object-list-card-city">
            <Skeleton width={100} height={20} />
          </span>
        </div>
      </div>
    </div>
  );
}
