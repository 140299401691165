import React from "react";
import Pencil from "../icons/Pencil";

import "../../styles/components/common/PencilUploader.scss";

export default function PencilUploader(): JSX.Element {
  return (
    <span className="pencil" data-testid="pencil">
      <Pencil />
    </span>
  );
}
