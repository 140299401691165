import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";

export default function useScrollToSection(path: string, threshold = [0.5]) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const ref = useRef<HTMLDivElement | HTMLHeadingElement | null>(null);
  const rootRef = useRef<HTMLDivElement | HTMLHeadingElement | null>(null);

  const [
    intersectionObserverHookRefCallback,
    { entry = { isIntersecting: false } },
  ] = useIntersectionObserver({
    threshold,
  });

  useEffect(() => {
    if (ref) {
      intersectionObserverHookRefCallback(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (
      entry.isIntersecting &&
      !pathname.endsWith(path) &&
      !pathname.endsWith("kontakte")
    ) {
      navigate(`../${path}`, { state: { intersecting: true } });
    }
  }, [entry.isIntersecting]);

  useEffect(() => {
    if (state && state.intersecting) {
      return;
    }
    if (pathname.endsWith(path) && rootRef && rootRef.current) {
      const top = rootRef.current?.offsetTop;
      document.getElementsByTagName("main")[0].scrollTo({
        top: top - 60,
      });
    }
  }, [pathname, state, ref, path]);

  return { ref, rootRef };
}
