import { Contact } from "../types";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";

export default function useSearchByContactCardInfo(
  elementsList: Contact[],
): SearchByContactCardInfo {
  const [searchString, setSearchString] = useState("");

  const handleChangeSearchString = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setSearchString(value);
    },
    [elementsList, setSearchString],
  );

  const contactsList = useMemo(
    () =>
      elementsList.filter((element) => {
        const searchParts = [
          element.firstName,
          element.lastName,
          element.street,
          element.gender,
          element.firma,
          element.aboutMe,
          element.roleName,
          element.responsibilities || [],
          element.contractedResources || [],
          element.birthDate ? Object.values(element.birthDate) : [],
          element.communication ? Object.values(element.communication) : [],
          element.resourceGroups ? Object.values(element.resourceGroups) : [],
        ]
          .flat()
          .filter((v) => Boolean(v));
        const search = searchParts.join(" ");

        const reg = new RegExp(
          `(?=.*${searchString.split(" ").join(")(?=.*")})`,
          "i",
        );

        return search.match(reg);
      }),
    [elementsList, searchString],
  );

  return {
    handleChangeSearchString,
    contactsList,
  };
}

type SearchByContactCardInfo = {
  handleChangeSearchString: (e: SyntheticEvent<HTMLInputElement>) => void;
  contactsList: Contact[];
};
