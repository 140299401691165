import { IconProps } from "../../types";

export default function ZipFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="zip-file-icon"
    >
      <defs>
        <rect id="b" width="26" height="32" x="5" y="2" rx="1" />
        <filter
          id="a"
          width="111.5%"
          height="112.5%"
          x="-5.8%"
          y="-4.7%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="27"
          height="33"
          x="4.5"
          y="1.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <path
          fill="#E88224"
          d="M19.491 18c.225 0 .432.233.432.508v5.978c0 .275-.207.514-.432.514h-3.053c-.225 0-.438-.239-.438-.514v-5.978c0-.275.213-.508.438-.508h3.053zM19 23.587v-.416c0-.224-.127-.371-.239-.371h-1.518c-.112 0-.243.147-.243.37v.417c0 .224.131.413.243.413h1.518c.112 0 .239-.19.239-.413z"
        />
        <path
          fill="#9599AF"
          fillOpacity=".5"
          d="M16.5 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm-1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm-1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm-1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm1 2h3a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1z"
        />
      </g>
    </svg>
  );
}
