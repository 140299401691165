import React from "react";
import { Editor, EditorState } from "react-draft-wysiwyg";
import { FieldRenderProps } from "react-final-form";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useGetPermissions from "../../hooks/useGetPermissions";
import { useLocation } from "react-router-dom";

export default function TextEditor({
  input,
}: FieldRenderProps<EditorState, HTMLInputElement>): JSX.Element {
  const permission = useGetPermissions();

  const { pathname } = useLocation();

  const toolbarEditor = pathname.includes("invitation")
    ? {
        options: ["inline", "history", "link"],
        inline: {
          inDropdown: false,
          className: "editor-buttons",
          options: ["bold"],
        },
        link: {
          inDropdown: false,
          options: ["link"],
          className: "editor-buttons",
        },
        history: {
          inDropdown: false,
          options: ["undo"],
          className: "editor-buttons",
        },
      }
    : {
        options: ["inline", "history", "link", "fontSize", "blockType"],
        blockType: {
          className: "editor-buttons",
          options: ["H1", "H2", "H3", "H4", "H5", "H6", "Normal"],
        },
        inline: {
          inDropdown: false,
          className: "editor-buttons",
          options: ["bold"],
        },
        link: {
          inDropdown: false,
          options: ["link"],
          className: "editor-buttons",
        },
        fontSize: {
          inDropdown: true,
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
          className: "editor-buttons",
        },
        history: {
          inDropdown: false,
          options: ["undo"],
          className: "editor-buttons",
        },
      };

  return (
    <Editor
      localization={{ locale: "de" }}
      editorState={input.value}
      onEditorStateChange={input.onChange}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbarHidden={!permission}
      readOnly={!permission}
      toolbar={toolbarEditor}
      editorStyle={{ fontSize: "1rem" }}
    />
  );
}
