import React from "react";
import AgendaListItem from "./AgendaListItem";

import "../../styles/components/meetings/AgendaList.scss";
import { AgendaItem } from "../../types";
import useGetSortedAgendaItems from "../../hooks/meetings/useGetSortedAgendaItems";

export default function AgendaList({ agendaItems }: Props): JSX.Element {
  const { sortedAgendaItems } = useGetSortedAgendaItems(agendaItems);

  return (
    <section role="list" className="agenda-list">
      {sortedAgendaItems.map((item) => (
        <AgendaListItem {...item} key={item.id} />
      ))}
    </section>
  );
}

type Props = {
  agendaItems: AgendaItem[];
};
