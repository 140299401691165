import { Activity } from "../../types";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import "../../styles/components/messages/MessageListItem.scss";
import MessageState from "./MessageState";
import { ActivityType } from "../../enums";

export default function MessageListItem({ message }: Props): JSX.Element {
  const { messageId = "" } = useParams();
  return (
    <li className="message-list-item" data-testid="message-list-item">
      <NavLink
        to={`${!!messageId ? "../" : "./"}${message.id}`}
        className="message-list-item-content"
        data-testid="message-list-item-link"
      >
        <span className="message-list-item-header">
          <strong className="message-list-item-header-title">
            {message.description}
          </strong>
          {message.type === ActivityType.General && (
            <span className="message-list-item-type">Allgemein</span>
          )}
          <MessageState stateType={message.stateType} />
        </span>
        <span>
          <span
            className="message-list-item-date"
            data-testid="message-list-item-date"
          >
            Geändert{" "}
            {moment.utc(message.changedDateUTC).local().format("L HH:mm")} Uhr
          </span>
          <span
            className="message-list-item-number"
            data-testid="message-list-item-number"
          >
            #{message.number}
          </span>
        </span>
      </NavLink>
    </li>
  );
}

type Props = {
  message: Activity;
};
