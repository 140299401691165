import {
  Fragment,
  // ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import FileUploadPreview from "../../common/FileUploadPreview";
// import { MeetingAttachment } from "../../../types";
import MeetingAttachmentItem from "./MeetingAttachmentItem";
import useLightbox from "../../../hooks/useLightbox";
import useUploadFiles from "../../../hooks/useUploadFiles";
import { useDispatch } from "react-redux";
import { UploadState } from "../../../enums";
import { remove } from "../../../slices/fileUploadSlice";

export default function MeetingAttachmentList({
  agendaItemId,
  propositionId,
  attachments,
}: Props): ReactNode {
  const { addFile, toggleOpen, initialize } = useLightbox();
  const uploadFiles = useUploadFiles(propositionId ?? agendaItemId);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const handleFilePreviewClick = useCallback((file: File) => {
    addFile(file);
    toggleOpen(file);
  }, []);

  useEffect(() => {
    if (uploadFiles.length) {
      uploadFiles.forEach(({ uploadResult = [{}], uploadState, file }) => {
        const [{ id }] = uploadResult;
        if (id && uploadState === UploadState.Completed) {
          dispatch(remove({ name: file.name }));
        }
      });
    }
  }, [uploadFiles]);

  useEffect(() => {
    initialize(attachments);
  }, [attachments]);

  return (
    <Fragment>
      <div className="meeting-item-attachments-file-upload-preview">
        <FileUploadPreview
          referenceId={propositionId ?? agendaItemId}
          showName
          previewWidth={81}
          previewHeight={81}
          onClick={handleFilePreviewClick}
        />
        {attachments.length > 0 && (
          <div
            className="meeting-item-attachments"
            ref={ref}
            data-testid="meeting-item-attachments"
          >
            {attachments.map((item, key) => (
              <MeetingAttachmentItem
                attachment={item}
                propositionId={propositionId}
                key={key}
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

type Props = {
  agendaItemId: string;
  propositionId?: string;
  attachments: File[];
};
