import { useGetFacilityUnitsWithTenanciesAndOwnershipsIncludingFutureQuery } from "../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import useCustomerRoles from "../useCustomerRoles";
import { UserRoles } from "../../enums";

export default function useTenanciesAndOwnershipsOptions(skip: boolean) {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data } =
    useGetFacilityUnitsWithTenanciesAndOwnershipsIncludingFutureQuery(
      { customerToken, facilityObjectId },
      { skip: skip },
    );
  const roles = useCustomerRoles();
  const isEmployee = roles.includes(UserRoles.Employee);

  return useMemo(() => {
    if (data) {
      const { tenancies, ownerships } = data;
      const options = [];

      if (!isEmployee) {
        options.push({
          value: "",
          disabled: true,
          label:
            ownerships !== null
              ? "Eigentumsverhältnis auswählen"
              : "Mietverhältnis auswählen",
        });
      }

      if (isEmployee) {
        options.push({
          label: "Alle Nachrichten",
          value: "",
        });
      }

      options.push(
        ...(tenancies ? tenancies : []).map((item) => ({
          label: `${item.person.lastName}, ${item.person.firstName}; ${item.facilityUnit.description}`,
          value: item.tenancyId,
        })),
        ...(ownerships ? ownerships : []).map((item) => ({
          label: `${item.person.lastName}, ${item.person.firstName}; ${item.facilityUnit.description}`,
          value: item.ownershipId,
        })),
      );

      return options
        .filter((option) => Object.keys(option).length > 0)
        .sort((a, b) =>
          a.value === "" || b.value === "" || a.label > b.label
            ? 1
            : a.label < b.label
              ? -1
              : 0,
        );
    }

    return [];
  }, [data, isEmployee]);
}
