import { useParams } from "react-router-dom";
import { useGetEmployeesListQuery } from "../../api/customerApi";
import { useMemo } from "react";

export default function useFetchEmployeesList() {
  const { customerToken = "" } = useParams();

  const { data: employeesList } = useGetEmployeesListQuery({
    customerToken,
  });

  const employeesListWithOid = useMemo(() => {
    return employeesList?.filter(
      (item) => item.oid !== null && item.email !== null,
    );
  }, [employeesList]);

  const employeesListWithoutOid = useMemo(() => {
    return employeesList?.filter(
      (item) => item.oid === null || item.email === null,
    );
  }, [employeesList]);

  return { employeesList, employeesListWithOid, employeesListWithoutOid };
}
