import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function Pin({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m15.294 5.053 3.253 3.253a2.23 2.23 0 0 1-.34 3.432l-2.378 1.585a.63.63 0 0 0-.262.371l-.99 3.958a1.515 1.515 0 0 1-2.54.704l-2.831-2.832-3.04 3.042a.8.8 0 0 1-1.132-1.132l3.04-3.04-2.83-2.83a1.515 1.515 0 0 1 .704-2.542l3.958-.99a.63.63 0 0 0 .371-.26l1.585-2.379a2.23 2.23 0 0 1 3.432-.34zm-1.526.95h-.112a.63.63 0 0 0-.462.278l-1.586 2.378a2.23 2.23 0 0 1-1.314.926l-3.813.953 6.58 6.58.954-3.812c.121-.484.4-.912.792-1.217l.134-.097 2.379-1.586a.63.63 0 0 0 .095-.969l-3.252-3.252a.63.63 0 0 0-.395-.183z"
        fill="#FF8544"
      />
    </svg>
  );
}
