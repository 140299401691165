import React, { Fragment, useState } from "react";

import "../../../styles/components/management/objectSettings/ObjectTenant.scss";
import TableSortHeader from "../../common/TableSortHeader";
import StakeholdersInfoListItem from "./StakeholdersInfoListItem";
import { useParams } from "react-router-dom";
import useTableSort from "../../../hooks/common/useTableSort";
import { useGetFacilityObjectTenantsQuery } from "../../../api/facilityManagementApi";
import useSortStakeholders from "../../../hooks/useSortStakeholders";
import { StakeholdersInfo } from "../../../types";
import StakeholdersInfoListItemUsersAndCode from "./StakeholdersInfoListItemUsersAndCode";
import CaretDown from "../../icons/CaretDown";
import useFetchFacilityObjectWithFacilityUnits from "../../../hooks/messages/useFetchFacilityObjectWithFacilityUnits";
import { FacilityObjectType } from "../../../enums";
import StakeholdersInfoListItemSkeleton from "../../skeleton/StakeholdersInfoListItem";

export default function ObjectTenantOrFlatOwner(): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "name",
      sortDirection: "ascending",
    },
    "facility-objects-tenants-list",
  );

  const sortOptions = [{ sortBy: "name", label: "Name" }];

  const {
    data: facilityObjectsTenants,
    isSuccess,
    isFetching,
  } = useGetFacilityObjectTenantsQuery({
    customerToken,
    facilityObjectId,
  });
  const { facilityObject } = useFetchFacilityObjectWithFacilityUnits();

  const sortedFacilityObjectsTenants = useSortStakeholders(
    facilityObjectsTenants ?? [],
    sort,
  );

  const isActiveStakeholderInitialState: StakeholdersInfo = {
    addressId: 0,
    firstName: "",
    lastName: "",
    activeAccountsCount: 0,
  };
  const [isActiveStakeholder, setActiveStakeholder] =
    useState<StakeholdersInfo>(isActiveStakeholderInitialState);

  return (
    <section className="object-tenant-wrapper">
      {isActiveStakeholder.addressId !== 0 && (
        <span
          onClick={() => setActiveStakeholder(isActiveStakeholderInitialState)}
          role="button"
          className="object-tenant-back"
        >
          <CaretDown width={16} height={16} /> Alle{" "}
          {facilityObject.facilityObjectType ===
          FacilityObjectType.ResidentialCommunityOfOwners
            ? "Eigentümer"
            : "Mieter"}
        </span>
      )}
      {isActiveStakeholder.addressId === 0 && (
        <div className="object-tenant-container">
          <div className="object-tenant-info">
            <h3>
              {facilityObject.facilityObjectType ===
              FacilityObjectType.ResidentialCommunityOfOwners
                ? "Eigentümer"
                : "Mieter"}
            </h3>

            <div className="sort-header">
              <TableSortHeader
                handleSort={handleSort}
                sortOptions={sortOptions}
                sort={sort}
              />
            </div>

            <div className="object-tenant-info-list-body" role="row">
              {isSuccess &&
                !isFetching &&
                sortedFacilityObjectsTenants?.map((tenant, index) => (
                  <StakeholdersInfoListItem
                    key={index}
                    stakeholdersInfo={tenant}
                    isActive={
                      tenant.addressId === isActiveStakeholder.addressId
                    }
                    setActiveStakeholder={setActiveStakeholder}
                  />
                ))}

              {!isSuccess && isFetching && (
                <Fragment>
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}

      {isActiveStakeholder.addressId !== 0 && (
        <StakeholdersInfoListItemUsersAndCode
          isActiveStakeholder={isActiveStakeholder}
        />
      )}
    </section>
  );
}
