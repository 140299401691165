import classNames from "classnames";
import moment from "moment";
import { ActivityContact } from "../../types";
import ProfilePicture from "../common/ProfilePicture";
import MessageDetailsDocuments from "./MessageDetailsDocuments";

import "../../styles/components/messages/MessageDetailsContactItem.scss";

export default function MessageDetailsContactItem({
  contact,
  odd,
}: Props): JSX.Element {
  const { personInChargeUser, createdByUser } = contact;
  const person = personInChargeUser || createdByUser;
  return (
    <div
      className={classNames("message-details-contact-item", { odd })}
      data-testid="message-details-contact-item"
    >
      <div className="message-details-contact-item-content">
        <div className="message-details-contact-item-content-line">
          <div className="message-details-contact-item-content-text">
            <strong className="message-details-contact-item-content-person">
              {person.firstName} {person.lastName}
            </strong>
            {contact.text !== null && <span>{contact.text}</span>}
            {contact.documents && contact.documents.length > 0 && (
              <MessageDetailsDocuments documents={contact.documents} />
            )}
          </div>
          <ProfilePicture
            alt=""
            userSid={person.sid}
            rounded
            width={36}
            height={36}
          />
        </div>
        <div className="message-details-contact-item-date">
          {moment.utc(contact.createdDateUTC).local().format("LLL")}
        </div>
      </div>
    </div>
  );
}

type Props = {
  contact: ActivityContact;
  odd: boolean;
};
