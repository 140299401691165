import React, { ReactElement, useCallback } from "react";
import classNames from "classnames";
import "../../styles/components/common/Tab.scss";

export default function VirtualTab({
  tabId,
  label,
  active,
  onClick,
}: Props): ReactElement {
  const className = classNames({
    tab: true,
    "tab-active": active,
  });
  const tabIndex = active ? 0 : -1;
  const clickHandler = useCallback(() => {
    onClick(tabId);
  }, [tabId]);
  return (
    <a
      className={className}
      role="tab"
      tabIndex={tabIndex}
      onClick={clickHandler}
    >
      <span className="tab-content">{label}</span>
      <span className="tab-indicator" />
    </a>
  );
}

type Props = {
  tabId: string | number;
  label: string;
  active?: boolean;
  onClick: (tabId: string | number) => void;
};
