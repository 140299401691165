import prettyBytes from "pretty-bytes";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo } from "react";

import useLightbox from "../../../hooks/useLightbox";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import PdfFileIcon from "../../icons/PdfFileIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import CloseIcon from "../../icons/CloseIcon";

import OtherFile from "../files/OtherFile";
import useDownload from "../../../hooks/useDownload";
import Button from "../Button";

import "../../../styles/components/common/Lightbox.scss";
import AudioFileIcon from "../../icons/AudioFileIcon";

export default function Lightbox({
  lazy = false,
  onClose,
}: Props): JSX.Element {
  const { files, selectedIndex, next, prev, toggleOpen, isOpen, options } =
    useLightbox();
  const selectedFile = files[selectedIndex];

  const src = useMemo(
    () => selectedFile && URL.createObjectURL(selectedFile),
    [selectedFile],
  );
  const handleDownload = useDownload(
    src,
    selectedFile?.name,
    selectedFile !== undefined,
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        prev(event);
      }
      if (event.key === "ArrowRight") {
        next(event);
      }
      if (event.key === "Escape") {
        toggleOpen();
        onClose && onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", listener);
    } else {
      document.removeEventListener("keydown", listener);
    }

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isOpen, next, prev, onClose]);

  const closeLightbox = useCallback(() => {
    toggleOpen();
    onClose && onClose();
  }, [toggleOpen, onClose]);

  const supportedPreviewFile =
    selectedFile &&
    selectedFile.type.match(
      /image\/*|application\/pdf|text\/plain|audio\/*|video\/*/,
    );

  return (
    <Fragment>
      {isOpen && (selectedFile || lazy) && (
        <div className="lightbox" data-testid="lightbox">
          <div
            className="lightbox-background-layer"
            onClick={closeLightbox}
          ></div>
          <div className="lightbox-container">
            {options.closeButton && (
              <div className="lightbox-header">
                <span
                  onClick={closeLightbox}
                  data-testid="lightbox-header-close"
                >
                  <CloseIcon
                    width={32}
                    height={32}
                    className="lightbox-action-icon"
                  />
                </span>
              </div>
            )}
            <div className="lightbox-content">
              {selectedFile && (
                <div className="lightbox-file-preview">
                  {selectedFile.type.match(/image\/*/) && (
                    <img
                      src={src}
                      alt={files[selectedIndex].name}
                      className="lightbox-image"
                      data-testid="lightbox-image"
                    />
                  )}
                  {selectedFile.type.match(/audio\/*/) && (
                    <Fragment>
                      <AudioFileIcon width={150} height={150} />
                      <audio
                        controls
                        src={src}
                        className="lightbox-image"
                        data-testid="lightbox-image"
                      />
                    </Fragment>
                  )}
                  {selectedFile.type.match(/video\/*/) && (
                    <video
                      controls
                      src={src}
                      className="lightbox-image"
                      data-testid="lightbox-image"
                    />
                  )}
                  {(selectedFile.type.match(/application\/pdf/) ||
                    selectedFile.type.match(/text\/plain/)) && (
                    <object
                      data-testid="lightbox-pdf-file"
                      className="lightbox-pdf-file"
                      data={src}
                    >
                      <PdfFileIcon width={150} height={150} />
                    </object>
                  )}
                  {!supportedPreviewFile && (
                    <Fragment>
                      <OtherFile file={selectedFile} width={150} height={150} />
                      <Button
                        label="Download"
                        onClick={handleDownload}
                        leadingIcon={DownloadIcon}
                        lightblue
                      />
                    </Fragment>
                  )}
                </div>
              )}
              {options.showMeta && selectedFile && (
                <div className="lightbox-meta" data-testid="lightbox-meta">
                  <dl className="lightbox-meta-list">
                    <dt>Dateiname</dt>
                    <dd>{selectedFile.name}</dd>
                    <dt>Größe</dt>
                    <dd>{prettyBytes(selectedFile.size, { locale: "de" })}</dd>
                    <dt>Letzte Änderung</dt>
                    <dd>{moment(selectedFile.lastModified).format("llll")}</dd>
                    {selectedFile.meta &&
                      selectedFile.meta.map(({ value, title }) => (
                        <Fragment key={title}>
                          <dt>{title}</dt>
                          <dd>{value}</dd>
                        </Fragment>
                      ))}
                  </dl>
                  {supportedPreviewFile && (
                    <Button
                      label="Download"
                      onClick={handleDownload}
                      leadingIcon={DownloadIcon}
                      lightblue
                    />
                  )}
                </div>
              )}
            </div>
            {files.length > 1 && (
              <div className="lightbox-actions" data-testid="lightbox-actions">
                <div
                  className="lightbox-prev"
                  onClick={prev}
                  data-testid="lightbox-prev"
                >
                  <ArrowLeft
                    width={32}
                    height={32}
                    className="lightbox-action-icon"
                  />
                </div>
                <div
                  className="lightbox-next"
                  onClick={next}
                  data-testid="lightbox-next"
                >
                  <ArrowRight
                    width={32}
                    height={32}
                    className="lightbox-action-icon"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  lazy?: boolean;
  onClose?: () => void;
};
