import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { SynchronisationMethod } from "../enums";

const joinMeeting = async (
  connection: HubConnection | null,
  customerToken: string,
  facilityObjectId: string,
  meetingId: string,
) => {
  if (connection) {
    await connection.invoke(
      SynchronisationMethod.JoinMeeting,
      customerToken,
      parseInt(facilityObjectId, 10),
      meetingId,
    );
  }
};

const leaveMeeting = async (
  connection: HubConnection | null,
  meetingId: string,
) => {
  if (connection) {
    await connection.invoke(SynchronisationMethod.LeaveMeeting, meetingId);
  }
};

const joinPublicChannelOfFacilityObject = async (
  connection: HubConnection | null,
  customerToken: string,
  facilityObjectId: string,
) => {
  if (connection) {
    await connection.invoke(
      SynchronisationMethod.JoinPublicChannelOfFacilityObject,
      customerToken,
      parseInt(facilityObjectId, 10),
    );
  }
};

const leavePublicChannelOfFacilityObject = async (
  connection: HubConnection | null,
  facilityObjectId: string,
) => {
  if (connection) {
    await connection.invoke(
      SynchronisationMethod.LeavePublicChannelOfFacilityObject,
      parseInt(facilityObjectId, 10),
    );
  }
};

const SynchronisationContext =
  createContext<SynchronisationProviderContext | null>(null);

export function useSynchronisationContext(
  endpoint: string,
): SynchronisationProviderContext {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();

  if (!connection) {
    const hubConnection: HubConnection = new HubConnectionBuilder()
      .withUrl(endpoint)
      .withAutomaticReconnect()
      .build();

    setConnection(hubConnection);
  }

  const join = async () =>
    !!meetingId
      ? joinMeeting(connection, customerToken, facilityObjectId, meetingId)
      : joinPublicChannelOfFacilityObject(
          connection,
          customerToken,
          facilityObjectId,
        );

  const leave = async () =>
    !!meetingId
      ? leaveMeeting(connection, meetingId)
      : leavePublicChannelOfFacilityObject(connection, facilityObjectId);

  const switchChannel = useCallback(async () => {
    try {
      if (connection?.state === HubConnectionState.Connected) {
        if (!meetingId) {
          await leaveMeeting(connection, facilityObjectId);
          await joinPublicChannelOfFacilityObject(
            connection,
            customerToken,
            facilityObjectId,
          );
        } else {
          await leavePublicChannelOfFacilityObject(
            connection,
            facilityObjectId,
          );
          await joinMeeting(
            connection,
            customerToken,
            facilityObjectId,
            meetingId,
          );
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, [connection, facilityObjectId, customerToken, meetingId]);

  const connect = useCallback(async () => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      try {
        await connection.start();
        await join();
        connection.onreconnected(join);
        document.addEventListener("visibilitychange", reconnectListener);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
    return true;
  }, [connection, setConnection, meetingId]);

  const disconnect = useCallback(async () => {
    if (connection && connection.state === HubConnectionState.Connected) {
      try {
        await leave();
        await connection.stop();
        document.removeEventListener("visibilitychange", reconnectListener);
      } catch (e) {
        console.log(e);
        return false;
      }
    }
    return true;
  }, [connection, setConnection, meetingId]);

  const syncMeeting = useCallback(
    async (methodName: string, meeting: string, ...args: [string | number]) => {
      if (connection && connection.state === HubConnectionState.Connected) {
        try {
          await connection.invoke(
            methodName,
            customerToken,
            parseInt(facilityObjectId, 10),
            meeting,
            ...args,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [connection, customerToken, facilityObjectId],
  );

  const sync = useCallback(
    async (methodName: string, ...args: [string | number]) => {
      if (
        !!meetingId &&
        connection &&
        connection.state === HubConnectionState.Connected
      ) {
        try {
          await connection.invoke(
            methodName,
            customerToken,
            parseInt(facilityObjectId, 10),
            meetingId,
            ...args,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [connection, customerToken, facilityObjectId, meetingId],
  );

  const reconnectListener = useMemo(() => {
    return async () => {
      if (
        connection &&
        document.visibilityState === "visible" &&
        connection.state !== HubConnectionState.Connected
      ) {
        await connect();
      }
    };
  }, [connection, connect]);

  return {
    connect,
    disconnect,
    sync,
    syncMeeting,
    connection,
    switchChannel,
  };
}

export function useSynchronisation(): SynchronisationProviderContext {
  return useContext(SynchronisationContext) as SynchronisationProviderContext;
}

export function SynchronisationProvider({
  children,
  endpoint,
}: Props): JSX.Element {
  return (
    <SynchronisationContext.Provider
      value={useSynchronisationContext(endpoint)}
    >
      {children}
    </SynchronisationContext.Provider>
  );
}

type SynchronisationProviderContext = {
  connect: () => Promise<boolean>;
  disconnect: () => Promise<boolean>;
  switchChannel: () => Promise<boolean>;
  sync: (methodName: string, ...rest: string[]) => void;
  syncMeeting: (
    methodName: string,
    meetingId: string,
    ...rest: string[]
  ) => void;
  connection: HubConnection | null;
};

type Props = {
  children: ReactNode | ReactNode[];
  endpoint: string;
};
