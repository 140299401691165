import React from "react";
import { IconProps } from "../../../types";

export default function ServicesLift({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M36.333 30.003H38c.92 0 1.666.746 1.666 1.666v1.667a3.333 3.333 0 0 0 6.667 0v-1.667c0-.92.746-1.666 1.666-1.666h1.667a1.667 1.667 0 0 0 1.18-2.847l-6.667-6.666a1.667 1.667 0 0 0-2.36 0l-6.666 6.666a1.667 1.667 0 0 0 1.18 2.847zM75.152 36.182a1.667 1.667 0 0 0 2.36 0l6.666-6.666a1.667 1.667 0 0 0-1.18-2.847h-1.667c-.92 0-1.666-.746-1.666-1.666v-1.667a3.333 3.333 0 0 0-6.667 0v1.667c0 .92-.746 1.666-1.666 1.666h-1.667a1.667 1.667 0 0 0-1.18 2.847l6.667 6.666zM86.331 40.002H33a5 5 0 0 0-5 5V95a5 5 0 0 0 5 5H86.33a5 5 0 0 0 5-5V45.002a5 5 0 0 0-5-5zm-11.666 5a7.5 7.5 0 1 1-7.5 7.5 7.51 7.51 0 0 1 7.5-7.5zm-29.999 0a7.5 7.5 0 1 1-7.5 7.5 7.51 7.51 0 0 1 7.5-7.5zm10 33.332c0 .92-.746 1.667-1.667 1.667h-1.223a1.667 1.667 0 0 0-1.667 1.46l-1.303 10.416a1.667 1.667 0 0 1-1.667 1.457h-4.973a1.667 1.667 0 0 1-1.666-1.457l-1.304-10.416A1.667 1.667 0 0 0 37.53 80h-1.197c-.92 0-1.667-.746-1.667-1.667v-5c0-5.522 4.477-10 10-10s10 4.478 10 10v5zm29.999 0c0 .92-.747 1.667-1.667 1.667h-1.223a1.667 1.667 0 0 0-1.667 1.46l-1.303 10.416a1.667 1.667 0 0 1-1.667 1.457h-5a1.667 1.667 0 0 1-1.666-1.457l-1.304-10.416A1.667 1.667 0 0 0 67.502 80h-1.17c-.92 0-1.667-.746-1.667-1.667v-5c0-5.522 4.477-10 10-10s10 4.478 10 10v5z" />
      </g>
    </svg>
  );
}
