import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { ViewportList } from "react-viewport-list";
import { useParams } from "react-router-dom";
import { Document, DocumentsRequest } from "../../types";
import React, { useCallback, useEffect, useState } from "react";
import { DocumentsQueryType, GroupingType } from "../../enums";
import DocumentListItemSkeleton from "../skeleton/DocumentListItemSkeleton";
import DocumentListItem from "./DocumentListItem";
import NoDocuments from "../icons/NoDocuments";

import SelectDocuments from "../icons/SelectDocuments";
import useDocumentsNotificationDocumentIds from "../../hooks/documents/useDocumentsNotificationDocumentIds";
import CaretDown from "../icons/CaretDown";
import classNames from "classnames";
import useGroupDocuments from "../../hooks/documents/useGroupDocuments";

import "../../styles/components/documents/DocumentList.scss";

export default function DocumentList({
  documents,
  getOnlineDocuments,
  queryType,
  tenancyOrOwnershipId,
  isFetching,
  term,
  isContentVisible,
  isGroupedBy,
  setSortingNotHidden,
}: Props): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { groupedDocuments, categories } = useGroupDocuments(
    documents,
    isGroupedBy,
  );

  const documentIds = useDocumentsNotificationDocumentIds();

  const createCollapsedMap = useCallback(
    (
      categories: string[] | null,
    ): {
      [category: string]: boolean;
    } => {
      const collapsedMap: { [category: string]: boolean } = {};

      categories?.forEach((category) => {
        collapsedMap[category] = isContentVisible;
      });

      return collapsedMap;
    },
    [categories],
  );

  const initialCollapsedMap: { [p: string]: boolean } =
    createCollapsedMap(categories);

  const [collapsedMap, setCollapsedMap] =
    useState<Record<string, boolean>>(initialCollapsedMap);

  useEffect(() => {
    if (
      Object.keys(collapsedMap).length === 0 &&
      Object.keys(initialCollapsedMap).length > 0
    ) {
      setCollapsedMap(initialCollapsedMap);
    }
  }, [initialCollapsedMap, collapsedMap]);

  const toggleCollapsed = useCallback(
    (category: string) => {
      setCollapsedMap((prevMap) => ({
        ...prevMap,
        [category]: !prevMap[category],
      }));
    },

    [collapsedMap],
  );

  useEffect(() => {
    if (tenancyOrOwnershipId !== null) {
      getOnlineDocuments({
        customerToken,
        facilityObjectId,
        queryType,
        tenancyOrOwnershipId,
      });
    }
  }, [customerToken, facilityObjectId, tenancyOrOwnershipId, queryType]);

  useEffect(() => {
    if (Object.values(collapsedMap).includes(true)) {
      setSortingNotHidden(true);
    } else {
      setSortingNotHidden(false);
    }
  }, [Object.values(collapsedMap).includes(true)]);

  useEffect(() => {
    setCollapsedMap(() => {
      const updatedMap: {
        [category: string]: boolean;
      } = {};
      Object.keys(initialCollapsedMap).forEach((category) => {
        updatedMap[category] = isContentVisible;
      });
      return updatedMap;
    });
  }, [isContentVisible, documents.length, isGroupedBy]);

  return (
    <div className="documents-facility-object">
      <div className="documents-facility-object-list">
        {isFetching &&
          Array(20)
            .fill(null)
            .map((v, key) => <DocumentListItemSkeleton key={key} />)}
        {!isFetching &&
          tenancyOrOwnershipId !== null &&
          documents.length === 0 && (
            <div className="documents-facility-object-list-noresult">
              <NoDocuments width={300} height={200} />
              <h2>Keine Dokumente</h2>
              {term && (
                <p data-testid="no-search-result">
                  Keine Dokumente zu Ihrer Suche <strong>{term}</strong>{" "}
                  gefunden.
                </p>
              )}
              {!term && <p>Es wurden noch keine Dokumente bereit gestellt.</p>}
            </div>
          )}
        {!isFetching && tenancyOrOwnershipId !== null && (
          <div>
            {categories.map((category) => (
              <div className="documents-category-wrapper" key={category}>
                {category && isGroupedBy !== GroupingType.None && (
                  <h3
                    className="category-documents-headline"
                    onClick={() => toggleCollapsed(category)}
                    role="title"
                  >
                    {category} ({groupedDocuments.get(category)?.length})
                    <CaretDown
                      width={16}
                      height={16}
                      className={classNames("category-documents-icon", {
                        "category-documents-icon-collapsed":
                          !collapsedMap[category],
                      })}
                    />
                  </h3>
                )}
                {category && (
                  <div
                    className={classNames("documents-category-list", {
                      "documents-category-list-open":
                        collapsedMap[category] ||
                        isGroupedBy === GroupingType.None,
                    })}
                  >
                    <ViewportList
                      items={
                        isGroupedBy === GroupingType.None
                          ? groupedDocuments.get(GroupingType.None)
                          : groupedDocuments.get(category)
                      }
                      initialAlignToTop
                    >
                      {(document) => (
                        <DocumentListItem
                          document={document}
                          key={document.id}
                          inNotifications={documentIds.includes(document.id)}
                          showCategory={isGroupedBy === GroupingType.None}
                        />
                      )}
                    </ViewportList>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {!isFetching && tenancyOrOwnershipId === null && (
          <div className="documents-facility-object-list-select">
            <SelectDocuments width={300} height={200} />
            <h2>
              Bitte wählen Sie zunächst ein Eigentumsverhältnis aus, um
              Dokumente anzuzeigen.
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

type Props = {
  term?: string;
  documents: Document[];
  isFetching: boolean;
  tenancyOrOwnershipId?: number | null;
  queryType: DocumentsQueryType;
  getOnlineDocuments: LazyQueryTrigger<
    QueryDefinition<
      DocumentsRequest,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, never>,
        FetchBaseQueryMeta
      >,
      never,
      Document[],
      "documents"
    >
  >;
  isContentVisible: boolean;
  isGroupedBy: GroupingType;
  setSortingNotHidden: (item: boolean) => void;
};
