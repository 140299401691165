import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DOCUMENTS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import {
  CustomerRequest,
  Document,
  DocumentCreateRequest,
  DocumentRequest,
  DocumentsRequest,
  DocumentUpdateRequest,
  OnlineDocumentCategory,
} from "../types";
import { DocumentsQueryType } from "../enums";

export const documentApi = createApi({
  reducerPath: DOCUMENTS_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["Documents"],
  endpoints: (build) => ({
    getOnlineDocuments: build.query<Document[], DocumentsRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        queryType,
        tenancyOrOwnershipId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
        params: { queryType, tenancyOrOwnershipId },
      }),
      transformResponse: (data: Document[]) => {
        const documents: Document[] = data.map((element) => {
          if (element.category === "") {
            return { ...element, category: "Sonstiges" };
          }
          return element;
        });
        return documents.sort((a, b) => {
          const firstDate = Date.parse(a.createDateUTC);
          const secondDate = Date.parse(b.createDateUTC);
          if (firstDate < secondDate) {
            return 1;
          }
          if (firstDate > secondDate) {
            return -1;
          }
          return 0;
        });
      },
      providesTags: ["Documents"],
    }),

    createOnlineDocument: build.mutation<Document, DocumentCreateRequest>({
      query: ({ customerToken, facilityObjectId, ...rest }) => {
        const formData = new FormData();
        formData.append("contents", rest.file);
        if (rest.description) {
          formData.append("description", rest.description);
        }
        formData.append("category", rest.category);
        formData.append("tenantCanSee", rest.tenantCanSee.toString());
        formData.append("objectOwnerCanSee", rest.objectOwnerCanSee.toString());
        formData.append(
          "serviceProviderCanSee",
          rest.serviceProviderCanSee.toString(),
        );
        return {
          url: `/v2/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Documents"],
    }),

    updateOnlineDocument: build.mutation<void, DocumentUpdateRequest>({
      query: ({ customerToken, facilityObjectId, document }) => {
        const {
          description,
          category,
          tenantCanSee,
          objectOwnerCanSee,
          serviceProviderCanSee,
        } = document;
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${document.documentId}`,
          method: "PATCH",
          body: {
            description,
            category,
            tenantCanSee,
            objectOwnerCanSee,
            serviceProviderCanSee,
          },
        };
      },
      invalidatesTags: ["Documents"],
    }),

    deleteOnlineDocument: build.mutation<void, DocumentRequest>({
      query: ({ customerToken, facilityObjectId, documentId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Documents"],
    }),

    getOnlineDocumentCategories: build.query<
      OnlineDocumentCategory[],
      CustomerRequest
    >({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/facilityObjects/onlinedocuments/categories`,
      }),
    }),
    getOnlineDocument: build.query<Blob, DocumentRequest>({
      query: ({ customerToken, facilityObjectId, documentId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${documentId}`,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),
    getOnlineDocumentActivityNumber: build.query<number, DocumentRequest>({
      query: ({ customerToken, facilityObjectId, documentId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${documentId}/activityNumber`,
      }),
    }),

    getLatestOnlineDocuments: build.query<Document[], DocumentsRequest>({
      queryFn: async (
        { customerToken, facilityObjectId },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const response = await Promise.all([
          await baseQuery({
            url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
            params: { queryType: DocumentsQueryType.FacilityObjectDocuments },
          }),
          await baseQuery({
            url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
            params: { queryType: DocumentsQueryType.CurrentUserDocuments },
          }),
        ]);
        const data = response
          .map(({ data }) => data)
          .flat()
          .filter((d) => d !== undefined) as Document[];

        data.sort((a, b) => {
          const firstDate = Date.parse(a.createDateUTC);
          const secondDate = Date.parse(b.createDateUTC);
          if (firstDate < secondDate) {
            return 1;
          }
          if (firstDate > secondDate) {
            return -1;
          }
          return 0;
        });

        return {
          data: data.slice(0, 3),
        };
      },
    }),
  }),
});

export const {
  useGetLatestOnlineDocumentsQuery,
  useLazyGetOnlineDocumentsQuery,
  useGetOnlineDocumentsQuery,
  useLazyGetOnlineDocumentQuery,
  useLazyGetOnlineDocumentActivityNumberQuery,
  useDeleteOnlineDocumentMutation,
  useCreateOnlineDocumentMutation,
  useGetOnlineDocumentCategoriesQuery,
  useUpdateOnlineDocumentMutation,
} = documentApi;
