import PageHeader from "../../common/PageHeader";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TermsOfUseUserListItem from "../../termsOfUse/TermsOfUseUserListItem";
import "../../../styles/components/common/TermsOfUsePagination.scss";
import useSortedUsersTermsOfUse from "../../../hooks/termsOfUse/useSortedUsersTermsOfUse";
import TermsOfUseUsersForm from "../../termsOfUse/TermsOfUseUsersForm";
import { useNavigate, useParams } from "react-router-dom";
import useFetchTermsOfUseUserList from "../../../hooks/termsOfUse/useFetchTermsOfUseUserList";
import TermsOfUseUsersListPagination from "../../termsOfUse/TermsOfUseUserListPagination";

import "../../../styles/components/pages/management/TermsOfUseUserListPage.scss";
import { TermsOfServicesUsersSortBy } from "../../../enums";

export default function TermsOfUseUsersListPage(): JSX.Element {
  const navigate = useNavigate();
  const { customerToken } = useParams();
  const pageNumber = Number(useParams().pageNumber);
  const [filterByName, setFilterByName] = useState<string | null>(null);
  const [filterByVersion, setFilterByVersion] = useState<string | null>(null);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    size: 50,
    sortBy: TermsOfServicesUsersSortBy.UserName,
    sortOrder: 1,
  });

  const { sort, handleSort } = useSortedUsersTermsOfUse();

  const { data } = useFetchTermsOfUseUserList(
    pageInfo.page,
    sort.sortBy,
    sort.sortDirection === "descending" ? 2 : 1,
    filterByName ? filterByName : "",
    filterByVersion ? filterByVersion : "",
    pageInfo.size,
  );

  useEffect(() => {
    setPageInfo({
      page: pageNumber ? pageNumber : 1,
      size: data ? data.pageSize : 50,
      sortBy: sort.sortBy,
      sortOrder: sort.sortDirection === "descending" ? 2 : 1,
    });
  }, [sort, pageNumber, filterByName, filterByVersion]);

  useEffect(() => {
    navigate(`/management/${customerToken}/nutzungsbedingungen/users/1`);
  }, [sort]);

  const pathnameEndsWith =
    window.location.pathname.endsWith("nutzungsbedingungen") ||
    window.location.pathname.endsWith("nutzungsbedingungen/");

  const backTo = !pathnameEndsWith
    ? { to: "../nutzungsbedingungen", label: "Nutzungsbedingungen" }
    : undefined;

  return (
    <div className="terms-of-use-user-list-container">
      <Helmet>
        <title>Management - Nutzungsbedingungen - Homecase</title>
      </Helmet>
      <PageHeader title="Zustimmung nach Benutzern" backTo={backTo} />
      <section role="table" className="terms-of-use-user-list">
        <div className="terms-of-use-user-list-container-header">
          <TermsOfUseUsersForm
            filterByName={setFilterByName}
            filterByVersion={setFilterByVersion}
            termsOfUseUsersList={data}
          />
          <TermsOfUseUsersListPagination
            pageNumberActual={data?.pageNumber}
            hasMorePages={data?.hasMorePages}
          />
        </div>

        <div className="terms-of-use-user-list-header" role="row">
          <div
            className="terms-of-use-user-list-header-name"
            aria-sort={
              sort.sortBy === TermsOfServicesUsersSortBy.UserName
                ? sort.sortDirection
                : "none"
            }
            role="columnheader"
            aria-level={TermsOfServicesUsersSortBy.UserName}
            onClick={handleSort}
          >
            Benutzer
          </div>
          <div
            className="terms-of-use-user-list-header-version"
            aria-sort={
              sort.sortBy === TermsOfServicesUsersSortBy.LastAcceptedVersion
                ? sort.sortDirection
                : "none"
            }
            role="columnheader"
            aria-level={TermsOfServicesUsersSortBy.LastAcceptedVersion}
            onClick={handleSort}
          >
            Letzte zugestimmte Version
          </div>
          <div
            className="terms-of-use-user-list-header-datum"
            aria-sort={
              sort.sortBy === TermsOfServicesUsersSortBy.AcceptedAtUtc
                ? sort.sortDirection
                : "none"
            }
            role="columnheader"
            aria-level={TermsOfServicesUsersSortBy.AcceptedAtUtc}
            onClick={handleSort}
          >
            Datum
          </div>
        </div>
        <div className="terms-of-use-user-list-items-wrapper">
          {data?.result.map((user) => (
            <TermsOfUseUserListItem user={user} key={user.userSid} />
          ))}
        </div>
      </section>

      <TermsOfUseUsersListPagination
        pageNumberActual={data?.pageNumber}
        hasMorePages={data?.hasMorePages}
      />
    </div>
  );
}
