import React, { Fragment, ReactElement, useCallback, useState } from "react";
import AlertDialog from "../common/dialog/AlertDialog";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import { UserRoles } from "../../enums";
import usePostAcceptTermsOfUse from "../../hooks/termsOfUse/usePostAcceptTermsOfUse";
import { useParams } from "react-router-dom";
import useTermsOfUseActual from "../../hooks/termsOfUse/useTermsOfUseActual";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";

export default function TermsOfUseDialogContent({
  termsOfUseAccepted,
  management,
}: Props): ReactElement {
  const { customerToken = "", facilityObjectId } = useParams();
  const customerRoles = useCustomerRoles();
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const { postAcceptTermsOfUse } = usePostAcceptTermsOfUse();
  const { termsOfUseActual, isError, isLoading } = useTermsOfUseActual(
    termsOfUseAccepted === null || termsOfUseAccepted === undefined,
  );
  const { customers } = useFetchPostLoginBaseData();
  const [termsOfUseContentDialogOpen, setTermsOfUseContentDialogOpen] =
    useState(false);
  const [termsOfUseDialogOpen, setTermsOfUseDialogOpen] = useState(true);

  const contentTermsOfUseActual = termsOfUseActual?.content
    ? termsOfUseActual.content
    : "";

  const getText = () => {
    return {
      __html: contentTermsOfUseActual,
    };
  };

  const [customer] = customers.filter((item) => item.token === customerToken);

  const acceptTermsHandler = useCallback(() => {
    postAcceptTermsOfUse();
    setTermsOfUseContentDialogOpen(false);
    setTermsOfUseDialogOpen(false);
  }, []);

  const showDialog =
    termsOfUseAccepted === false &&
    termsOfUseDialogOpen &&
    !management &&
    facilityObjectId &&
    !isEmployee;

  return (
    <div>
      {showDialog && !isLoading && !isError && (
        <AlertDialog
          confirmLabel="Bestätigen"
          description={
            <div>
              {!termsOfUseContentDialogOpen && (
                <Fragment>
                  <p>
                    Mit Klick auf den Button 'Bestätigen' akzeptiere ich die
                    Nutzungsbedingungen der Verwaltung
                  </p>
                  <b>{customer?.name}.</b>
                  <div
                    className="dialog-description-content"
                    onClick={() => {
                      setTermsOfUseContentDialogOpen(true);
                    }}
                  >
                    Nutzungsbedingungen lesen
                  </div>
                </Fragment>
              )}
              {termsOfUseContentDialogOpen && (
                <div
                  className="terms-of-use-content"
                  dangerouslySetInnerHTML={getText()}
                ></div>
              )}
            </div>
          }
          title={`Nutzungsbedingungen der ${customer?.name}`}
          onConfirm={acceptTermsHandler}
        />
      )}
    </div>
  );
}

type Props = {
  management?: boolean;
  termsOfUseAccepted?: boolean | null;
  isFetching?: boolean;
};
