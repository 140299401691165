import { useParams } from "react-router-dom";
import { useGetMyPermissionsQuery } from "../api/customerApi";
import { Permission } from "../types";

export default function useGetPermissions(): Permission[] {
  const { customerToken = "" } = useParams();

  const { data: permissions = [] } = useGetMyPermissionsQuery(
    { customerToken },
    { skip: customerToken === "" },
  );

  return permissions;
}
