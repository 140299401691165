import React, { ReactElement } from "react";
import "../../styles/components/common/ScrollToTopButton.scss";
import IconButton from "./IconButton";
import ArrowLeft from "../icons/ArrowLeft";

export default function ScrollToTopButton({
  scrollToTop,
}: Props): ReactElement {
  return (
    <div className="scroll-to-top-container">
      <IconButton
        className="scroll-to-top-button"
        icon={ArrowLeft}
        onClick={scrollToTop}
        grey
      />
    </div>
  );
}

type Props = {
  scrollToTop: () => void;
};
