import React from "react";
import { IconProps } from "../../../types";

export default function ServicesGarage({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fill="#8E9A9E"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="m98.848 53.18-34.41-31.417a6.623 6.623 0 0 0-9 0L21.041 53.181a3.333 3.333 0 0 0 4.493 4.923l33.287-30.395c.637-.58 1.61-.58 2.247 0l33.287 30.395a3.333 3.333 0 0 0 4.493-4.923z" />
        <path d="M89.462 66.626a3.333 3.333 0 0 0-4.573-1.143l-1.697 1.02a1.667 1.667 0 0 1-2.513-1.233l-.403-3.543a6.666 6.666 0 0 0-6.62-5.883h-27.41a6.666 6.666 0 0 0-6.633 5.883l-.403 3.543a1.667 1.667 0 0 1-2.514 1.233L35 65.483a3.333 3.333 0 1 0-3.43 5.716l2.743 1.647a1.667 1.667 0 0 1 .5 2.393 8.183 8.183 0 0 0-1.533 4.763v3.333a5 5 0 0 0 5 5h43.33a5 5 0 0 0 4.999-5v-3.333c0-1.71-.535-3.377-1.53-4.767a1.667 1.667 0 0 1 .5-2.393l2.743-1.646a3.333 3.333 0 0 0 1.14-4.57zM72.163 62.51a1.667 1.667 0 0 1 1.667 1.47l.687 5.833a1.667 1.667 0 0 1-1.667 1.86H47.042a1.667 1.667 0 0 1-1.666-1.86l.686-5.833c.1-.843.818-1.476 1.667-1.47h24.434zM44.946 91.667H38.28c-.92 0-1.667.747-1.667 1.667V95a5 5 0 0 0 10 0v-1.666c0-.92-.747-1.667-1.667-1.667zM81.61 91.667h-6.667c-.92 0-1.666.747-1.666 1.667V95a5 5 0 0 0 9.999 0v-1.666c0-.92-.746-1.667-1.667-1.667z" />
      </g>
    </svg>
  );
}
