import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";

import LoadingSpinner from "../../icons/LoadingSpinner";
import { useGetFacilityObjectQuery } from "../../../api/facilityManagementApi";
import ObjectIcon from "../../icons/ObjectIcon";
import ObjectNumberIcon from "../../icons/ObjectNumberIcon";
import LocationIcon from "../../icons/LocationIcon";
import { FacilityObjectType } from "../../../enums";
import SwitchInput from "../../common/SwitchInput";
import { useGetBulletinBoardQuery } from "../../../api/bulletinBoardApi";
import ObjectPicture from "./ObjectPicture";
import useToggleTenantCanPost from "../../../hooks/bulletinBoard/useToggleTenantCanPost";

import "../../../styles/components/management/objectSettings/ObjectComponent.scss";
import useToggleTenantCanComment from "../../../hooks/bulletinBoard/useToggleTenantCanComment";
import useToggleOnline from "../../../hooks/facilityObject/useToggleOnline";

const objectTypeNames = {
  [FacilityObjectType.Tenantment]: "Mietobjekt",
  [FacilityObjectType.CentralTenant]: "Zentralmieter",
  [FacilityObjectType.CommercialProperty]: "Gewerbeobjekt",
  [FacilityObjectType.Other]: "Sonstige",
  [FacilityObjectType.PartOwnership]: "SE",
  [FacilityObjectType.ResidentialCommunityOfOwners]: "WEG",
  [FacilityObjectType.SocialHousing]: "Sozialer Wohnungsbau",
  [FacilityObjectType.ObjectSurfaceModel]: "Objektflächenmodell",
};

export default function ObjectComponent(): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const { data: facilityObject, isLoading } = useGetFacilityObjectQuery({
    facilityObjectId,
    customerToken,
  });

  const { data: bulletinBoard } = useGetBulletinBoardQuery({
    customerToken,
    facilityObjectId: facilityObjectId,
  });

  const { toggleTenantsCanPost, isLoading: postIsLoading } =
    useToggleTenantCanPost(facilityObjectId, bulletinBoard);

  const { toggleTenantsCanComment, isLoading: commentIsLoading } =
    useToggleTenantCanComment(facilityObjectId, bulletinBoard);

  const { toggleOnline, isLoading: onlineIsLoading } =
    useToggleOnline(facilityObject);
  return (
    <section className="object-component-wrapper">
      <div className="object-component">
        {isLoading && <LoadingSpinner height={72} width={72} />}
        {!isLoading && facilityObject && (
          <div className="object-info">
            <h2>{facilityObject.description}</h2>
            <div className="object-info-list">
              <div className="object-info-list-item">
                <span className="object-info-list-item-label">
                  <ObjectNumberIcon className="object-info-list-item-label-icon" />
                  Objekt-Nr:
                </span>
                <span className="object-info-list-item-content">
                  {facilityObject.number}
                </span>
              </div>
              <div className="object-info-list-item">
                <span className="object-info-list-item-label">
                  <ObjectIcon className="object-info-list-item-label-icon" />
                  Objekttyp:
                </span>
                <span className="object-info-list-item-content">
                  {objectTypeNames[facilityObject.facilityObjectType]}
                </span>
              </div>
              <div className="object-info-list-item">
                <span className="object-info-list-item-label">
                  <LocationIcon className="object-info-list-item-label-icon-fill" />
                  Straße, PLZ, Ort
                </span>
                <span className="object-info-list-item-content">
                  {facilityObject.street}
                  <br />
                  {`${facilityObject.zipCode} ${facilityObject.city}`}
                </span>
              </div>
            </div>
            <hr className="object-info-divider" />
            <SwitchInput
              onChange={toggleOnline}
              checked={facilityObject.isOnline || false}
              label={
                facilityObject.isOnline ? "Objekt Online" : "Objekt Offline"
              }
              disabled={onlineIsLoading}
            />
            <h3 className="object-info-inline">Schwarzes Brett</h3>
            <SwitchInput
              onChange={toggleTenantsCanPost}
              checked={bulletinBoard?.tenantsCanPost || false}
              disabled={
                bulletinBoard === undefined ||
                !facilityObject.isOnline ||
                postIsLoading
              }
              label="Nutzerbeiträge erlauben"
            />
            <SwitchInput
              onChange={toggleTenantsCanComment}
              checked={bulletinBoard?.tenantsCanComment || false}
              disabled={
                bulletinBoard === undefined ||
                !facilityObject.isOnline ||
                commentIsLoading
              }
              label="Nutzerkommentare erlauben"
            />
          </div>
        )}
        <ObjectPicture />
      </div>
    </section>
  );
}
