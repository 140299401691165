import React from "react";
import { IconProps } from "../../../types";

export default function ServicesFloorTiles({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M81.714 76.703 95 89.989v3.845c0 .643-.522 1.166-1.167 1.166H90.11L76.772 81.662l4.942-4.959zm-6.59 6.61 4.95 4.95L73.337 95h-9.902l11.688-11.688zm-36.298-6.6L52.85 90.737l4.263 4.261h-9.9l-1.992-1.992-5.57-5.569-5.775-5.775 4.95-4.949zm-6.6 6.6 4.95 4.95-6.736 6.736h-4.273A1.166 1.166 0 0 1 25 93.832v-3.295l7.226-7.224zm49.497 6.599 5.087 5.086H76.636l5.087-5.086zm-6.6-19.8 4.94 4.94-5.765 5.785-14.023 14.024-4.95-4.95 19.799-19.798zm-36.297 19.8 2.753 2.754h-5.507l2.754-2.754zm0-26.398L52.85 77.538l5.776 5.774-4.95 4.95L39.651 74.24l-5.774-5.775 4.948-4.95zm-6.6 6.6 4.95 4.95-5.775 5.773-6.4 6.402v-9.9l7.225-7.226zm49.497-6.6L94.999 76.79v9.899L82.538 74.226l-5.765-5.763 4.95-4.95zm-6.6-6.6 4.951 4.95-19.8 19.798-4.948-4.95 19.798-19.798zm-36.296-6.6 19.799 19.8-4.95 4.95-14.025-14.025-5.775-5.774 4.95-4.95zm-6.6 6.6 4.949 4.95L25 74.039v-9.9l7.225-7.226zm49.496-6.599 13.276 13.277v9.9L76.773 55.266l4.95-4.95zm-6.598-6.6 4.939 4.94-5.765 5.786-14.024 14.022-4.95-4.95L61.1 57.74l14.025-14.025zm-36.298-6.598 19.798 19.798-4.949 4.95L39.651 47.84l-5.774-5.775 4.95-4.948zm-6.6 6.598 4.949 4.95L31.4 54.44 25 60.842V50.94l7.226-7.225zm49.495-6.599L95 50.394v9.897L82.548 47.839l-.012-.01-5.763-5.763 4.949-4.95zm-6.6-6.599 4.95 4.95-5.77 5.77-14.03 14.028-4.948-4.95 5.775-5.774 14.023-14.024zM39.91 25l12.492 12.493c.078.078.175.128.27.18.052.094.1.19.178.267l5.775 5.775-4.95 4.95L30.012 25h9.898zm-7.684 5.516 4.95 4.95L31.4 41.24l-6.4 6.4v-9.899l7.225-7.225zM82.803 25 95 37.195v9.9L82.553 34.648l-5.78-5.781L80.639 25h2.164zm-5.463.001-3.018 3.017c-.009.008-.02.01-.03.02-.008.008-.01.019-.017.027l-14 14-4.951-4.95L67.44 25.002h9.9zm-13.199 0L53.675 35.465 43.21 25H64.14zm-37.43 0 3.865 3.864-5.575 5.577v-8.275c0-.645.522-1.167 1.167-1.167h.543zm67.122 0c.644 0 1.166.52 1.166 1.166v7.729L86.103 25h7.73z"
        fillRule="nonzero"
      />
    </svg>
  );
}
