import { useMemo } from "react";
import { OnlineIdentity, SortState } from "../types";
export function sortOnlineIdentity(
  onlineIdentity: OnlineIdentity[],
  key: SortKey,
  sortDirection: "descending" | "ascending" | "none",
): OnlineIdentity[] {
  return sortDirection === "none"
    ? onlineIdentity
    : onlineIdentity.slice().sort((a, b) => {
        let x;
        let y;
        switch (key) {
          case "e-mail":
            x = `${a.email}`;
            y = `${b.email}`;
            break;
          case "name":
            x = `${a.userSid}`;
            y = `${a.userSid}`;
            break;
          case "code-created":
            x = new Date(a.createDateUTC).getTime();
            y = new Date(b.createDateUTC).getTime();
            break;
          case "account":
            x = `${a.active} ? "Active" : "Inactive"`;
            y = `${b.active} ? "Active" : "Inactive"`;
            break;
          case "reg-codes":
            x = `${a.registrationCodeUsed}`;
            y = `${b.registrationCodeUsed}`;
            break;
          case "reg-codes-without-user-sid":
            x = `${a.regCode}`;
            y = `${b.regCode}`;
            break;

          default:
            x = a[key];
            y = b[key];
        }

        if (x < y) {
          return sortDirection === "ascending" ? -1 : 1;
        }

        if (x > y) {
          return sortDirection === "ascending" ? 1 : -1;
        }
        return 0;
      });
}

export default function useSortOnlineIdentity(
  onlineIdentity: OnlineIdentity[],
  sort: SortState,
): OnlineIdentity[] {
  return useMemo(
    () =>
      sortOnlineIdentity(
        onlineIdentity,
        sort.sortBy as SortKey,
        sort.sortDirection,
      ),
    [onlineIdentity, sort],
  );
}

type SortKey =
  | "reg-codes"
  | "e-mail"
  | "name"
  | "reg-codes-without-user-sid"
  | "account"
  | "code-created";
