import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import useTermsOfUseActual from "../../hooks/termsOfUse/useTermsOfUseActual";

import "../../styles/components/pages/TermsOfUseContent.scss";

export default function TermsOfUseContent(): JSX.Element {
  const { termsOfUseActual } = useTermsOfUseActual();
  const content = termsOfUseActual?.content ? termsOfUseActual.content : "";
  const getText = () => {
    return {
      __html: content,
    };
  };

  return (
    <div className="terms-of-use-content" data-testid="nutzungsbedingungen">
      <Helmet title="Nutzungsbedingungen der Verwaltung - Homecase" />
      <PageHeader title="Nutzungsbedingungen der Verwaltung" />
      <div
        className="terms-of-use-content-container"
        dangerouslySetInnerHTML={getText()}
      ></div>
    </div>
  );
}
