import React, { ReactElement, useCallback, useState } from "react";

import { useGetOnlineDocumentsQuery } from "../../../api/documentsApi";
import { Outlet, useParams } from "react-router-dom";
import { DcsEmployeePermissionType, DocumentsQueryType } from "../../../enums";
import Button from "../../common/Button";

import UploadIcon from "../../icons/UploadIcon";
import TableSortHeader from "../../common/TableSortHeader";
import useTableSort from "../../../hooks/common/useTableSort";
import DocumentListItem from "../../documents/DocumentListItem";
import LightboxProvider from "../../common/lightbox/LightboxProvider";
import ObjectDocumentActions from "./ObjectDocumentActions";
import ObjectDocumentUploadFormDialog from "./ObjectDocumentUploadFormDialog";

import useHasPermission from "../../../hooks/useHasPermission";
import DocumentListItemSkeleton from "../../skeleton/DocumentListItemSkeleton";

import "../../../styles/components/management/objectSettings/ObjectDocuments.scss";
import useSortDocuments from "../../../hooks/documents/useSortDocuments";

const sortOptions = [
  {
    sortBy: "originalFileName",
    label: "Dokument",
  },
  {
    sortBy: "category",
    label: "Kategorie",
  },

  {
    sortBy: "contentSize",
    label: "Größe",
  },
];

export default function xObjectDocuments(): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "originalFileName",
      sortDirection: "descending",
    },
    "documents-list",
  );
  const { data = [], isFetching } = useGetOnlineDocumentsQuery({
    customerToken,
    facilityObjectId,
    queryType: DocumentsQueryType.FacilityObjectDocuments,
  });

  const documents = useSortDocuments(data || [], sort);
  const canModifyDocuments = useHasPermission(
    DcsEmployeePermissionType.CanModifyFacilityObjectMasterData,
  );

  const handleToggleUploadDialog = useCallback(() => {
    setShowUploadDialog(!showUploadDialog);
  }, [showUploadDialog]);

  return (
    <section className="object-documents-wrapper">
      <div className="object-documents-container">
        <div className="object-documents-header">
          <h2>{data ? `${data.length} ` : ""}Dokumente</h2>
          <Button
            label="Dokument hochladen"
            lightblue
            leadingIcon={UploadIcon}
            onClick={handleToggleUploadDialog}
          />
        </div>
      </div>
      <div className="object-documents-content">
        <TableSortHeader
          handleSort={handleSort}
          sortOptions={sortOptions}
          sort={sort}
        />
        <div className="object-documents-list">
          {documents?.map((document) => (
            <DocumentListItem
              document={document}
              key={document.documentId}
              showDate={false}
              actionComponent={
                canModifyDocuments && (
                  <ObjectDocumentActions document={document} />
                )
              }
            />
          ))}
          {isFetching &&
            !data.length &&
            Array(10)
              .fill(0)
              .map((_, index) => <DocumentListItemSkeleton key={index} />)}
        </div>
        {showUploadDialog && (
          <ObjectDocumentUploadFormDialog
            handleToggleUploadDialog={handleToggleUploadDialog}
          />
        )}
        <LightboxProvider options={{ showMeta: true, closeButton: true }}>
          <Outlet context={data} />
        </LightboxProvider>
      </div>
    </section>
  );
}
