import React from "react";
import { IconProps } from "../../../types";

export default function ServicesBoilerWater({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M38.02 59.792a2.5 2.5 0 0 0-2.186-1.29h-1.453a1.667 1.667 0 0 1-1.667-1.883A27.497 27.497 0 0 1 81.563 43.11a3.333 3.333 0 0 0 5.226-4.136 34.163 34.163 0 0 0-60.81 17.998 1.667 1.667 0 0 1-1.667 1.513h-1.813a2.5 2.5 0 0 0-2.12 3.823l6.666 10.666a2.503 2.503 0 0 0 4.243 0l6.666-10.666a2.5 2.5 0 0 0 .067-2.516zM99.611 58.012l-6.666-10.665a2.603 2.603 0 0 0-4.243 0l-6.666 10.665a2.5 2.5 0 0 0 2.12 3.823h1.453a1.667 1.667 0 0 1 1.667 1.883 27.497 27.497 0 0 1-48.849 13.509 3.333 3.333 0 0 0-5.226 4.136 34.163 34.163 0 0 0 60.81-18.015c.08-.86.803-1.517 1.667-1.513h1.823a2.5 2.5 0 0 0 2.12-3.823h-.01z" />
        <path d="M59.995 41.504a3.313 3.313 0 0 0-2.786 1.506c-3.94 5.993-10.546 16.855-10.546 22.158 0 7.363 5.969 13.332 13.332 13.332s13.332-5.969 13.332-13.332c0-5.306-6.606-16.165-10.546-22.161a3.313 3.313 0 0 0-2.786-1.503zm3.333 30.58a1.667 1.667 0 0 1-1.666 1.667c-5.523 0-10-4.477-10-10a1.667 1.667 0 0 1 3.333 0 6.666 6.666 0 0 0 6.667 6.667c.919 0 1.664.744 1.666 1.663v.003z" />
      </g>
    </svg>
  );
}
