import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function ObjectMenu({
  width = 58,
  height = 58,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={{
        margin: "auto",
        background: "rgba(0, 0, 0, 0.0)",
        display: "block",
        shapeRendering: "auto",
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect
        fill="#ffffff"
        x="15"
        y="55"
        width="30"
        height="30"
        rx="3"
        ry="3"
        className={className ? `${className}-path` : undefined}
      />
      <rect
        fill="#ffffff"
        x="55"
        y="55"
        width="30"
        height="30"
        rx="3"
        ry="3"
        className={className ? `${className}-path` : undefined}
      />
      <rect
        fill="#ffffff"
        x="55"
        y="15"
        width="30"
        height="30"
        rx="3"
        ry="3"
        className={className ? `${className}-path` : undefined}
      />
      <rect
        fill="#ffffff"
        x="15"
        y="15"
        width="30"
        height="30"
        rx="3"
        ry="3"
        className={className ? `${className}-path` : undefined}
      />
    </svg>
  );
}
