import moment from "moment";
import React from "react";

export default function NotificationItemDate({ date }: Props): JSX.Element {
  return (
    <div className="notification-item-content-date">
      {moment(date).format("L HH:mm")} Uhr
    </div>
  );
}

type Props = {
  date: Date;
};
