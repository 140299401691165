import { useParams } from "react-router-dom";
import {
  useEditTermsOfUseMutation,
  useSaveTermsOfUseMutation,
} from "../../api/termsOfUseApi";
import { useCallback } from "react";
import { TermsOfServicesState } from "../../enums";

export default function useSaveTermsOfUse(
  latestId: number,
  state: TermsOfServicesState | undefined,
) {
  const { customerToken = "", termsOfUseId } = useParams();
  const [postTermsOfUse, {}] = useSaveTermsOfUseMutation();
  const [editTermsOfUse, { error, isError, isSuccess }] =
    useEditTermsOfUseMutation();

  const save = useCallback(
    async (content: string) => {
      if (state !== TermsOfServicesState.Draft) {
        return await postTermsOfUse({
          customerToken,
          content,
        });
      }
      if (!!termsOfUseId) {
        return await editTermsOfUse({
          customerToken,
          content,
          id: Number(termsOfUseId),
        });
      }
    },
    [termsOfUseId, latestId],
  );

  return { save, error, isError, isSuccess };
}
