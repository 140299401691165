import ProfilePicture from "../common/ProfilePicture";
import Skeleton from "react-loading-skeleton";
import Comment from "../icons/Comment";

export default function PostListItemSkeleton(): JSX.Element {
  return (
    <div
      className="bulletin-board-post-list-item"
      data-testid="list-item-skeleton"
    >
      <div className="bulletin-board-post-list-item-container">
        <ProfilePicture height={32} width={32} rounded alt="skeleton" />
        <div className="bulletin-board-post-list-item-content">
          <div className="bulletin-board-post-list-item-username">
            <Skeleton width={100} />
          </div>
          <div className="bulletin-board-post-list-item-date">
            <Skeleton width={80} height={12} />
          </div>
          <p className="bulletin-board-post-list-item-text">
            <Skeleton width={300} />
            <Skeleton width={250} />
            <Skeleton width={290} />
          </p>
          <div className="bulletin-board-post-list-item-comments">
            <Comment className="icon" />
            <span>
              <Skeleton width={50} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
