import React from "react";
import AppRouter from "./AppRouter";
import "./styles/App.scss";
import { BrowserRouter as Router } from "react-router-dom";
export default function App(): JSX.Element {
  return (
    <div className="homecase" data-testid="app">
      <Router>
        <AppRouter />
      </Router>
    </div>
  );
}
