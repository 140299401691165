import { SynchronisationMethod } from "../enums";
import { useEffect, useMemo, useState } from "react";
import { useSynchronisation } from "./useSynchronisation";

export default function useStateChanged({
  initialState,
  synchronisationMethod,
}: Props): [State, (state: State) => void] {
  const [stateChanged, setStateChanged] = useState<State>(initialState);
  const { connection } = useSynchronisation();

  const handleStateChanged = useMemo(() => {
    return function (args: string[]) {
      const [meetingId, agendaItemId, propositionId] = args;
      setStateChanged({
        meetingId,
        agendaItemId,
        propositionId,
        time: new Date().getTime(),
      });
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection.on(synchronisationMethod, handleStateChanged);
    }
    return () => {
      if (connection) {
        connection.off(synchronisationMethod);
      }
    };
  }, [connection]);

  return [stateChanged, setStateChanged];
}

type State = {
  meetingId?: string;
  agendaItemId?: string;
  propositionId?: string;
  time: number;
};

type Props = {
  synchronisationMethod:
    | SynchronisationMethod.PropositionVotingStateChanged
    | SynchronisationMethod.MeetingStateChanged;
  initialState: State;
};
