import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/Imprint.scss";

export default function Imprint(): JSX.Element {
  const backTo = {
    to: "/",
    label: "Zurück zu HOMECASE",
  };

  return (
    <div className="legal-notice-page-container">
      <Helmet>
        <title> Impressum - Homecase</title>
      </Helmet>
      <PageHeader title="Impressum" backTo={backTo} />
      <div className="legal-notice-page-container-info">
        <span className="legal-notice-page-container-info-title">
          Sitz der Gesellschaft
        </span>
        <div className="company-name">
          <p>DOMUS Software AG </p>
          <p>Otto-Hahn-Straße 4 </p>
          <p>85521 Riemerling (Hohenbrunn)</p>
        </div>
        <div className="company-contact">
          <p>Tel.: +49 [89] 660 86-0 </p>
          <p> Fax: +49 [89] 660 86-190 </p>
          <p>
            E-Mail:{" "}
            <a href={`mailto:info@domus-software.de`}>info@domus-software.de</a>
          </p>
        </div>
        <span className="legal-notice-page-container-info-title">
          Geschäftsdaten
        </span>
        <div className="legal-notice-page-container-info-business-data">
          <p>Vorstand:</p>
          <p>Dipl.Ing. Angèle Baretzky-Fichtner </p>
          <p>Stephanie Kreuzpaintner</p>
        </div>

        <div className="legal-notice-page-container-info-business-data">
          <p>Prokura:</p>
          <p>Tanja Baretzky</p>
        </div>

        <div className="legal-notice-page-container-info-business-data">
          <p>Registergericht: Amtsgericht München </p>
          <p>Registernummer: HRB 245226 </p>
          <p>Ust-Id.Nr.: DE217770896</p>
        </div>

        <span className="legal-notice-page-container-info-title">Links</span>
        <p className="legal-notice-page-container-info-link">
          Mit Urteil vom 12. Mai 1998 (312 O 85/98) hat das Landgericht Hamburg
          entschieden, dass durch die Anbringung eines Links, die Inhalte der
          gelinkten Seite ggfs. mit zu verantworten sind. Dies kann – so das
          Landgericht – nur durch eine ausdrückliche Distanzierung von diesen
          Inhalten verhindert werden. Die Autoren, die Urheber bzw. die DOMUS
          Software AG haben keinerlei Einfluss auf den Inhalt und die Gestaltung
          der durch externe Links verknüpften Seiten. Es wird sich ausdrücklich
          von allen Inhalten der gelinkten Seiten distanziert, auch wird sich
          ihr Inhalt nicht zu eigen gemacht. Diese Erklärung gilt für alle auf
          dieser Internetpräsenz angeführten externen Links.
        </p>
        <span className="legal-notice-page-container-info-title">
          Verwendete Ressourcen
        </span>
        <ul className="legal-notice-page-container-info-resources">
          <li>Emoji kostenlos zur Verfügung gestellt von EmojiOne</li>
          <li>
            Icons von
            <a href="https://www.icons8.de" target="_blank">
              www.icons8.de
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
