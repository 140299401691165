import { store } from "../store";
import { useSelector } from "react-redux";

export default function useGetNaviSubItems(): UseGetNaviSubItems {
  const getSubItems = useSelector(
    (state: ReturnType<typeof store.getState>) => state.subItems.items,
  );

  return { getSubItems };
}

type UseGetNaviSubItems = {
  getSubItems: string[];
};
