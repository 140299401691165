import React from "react";
import { IconProps } from "../../types";

export default function DeleteIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M11 8v-.4A2.6 2.6 0 0 1 13.6 5h3.2a2.6 2.6 0 0 1 2.6 2.6V8h3a1 1 0 0 1 0 2H22v10.4c0 1.442-1.358 2.6-2.8 2.6h-7.6A2.6 2.6 0 0 1 9 20.4V10h-.5a1 1 0 1 1 0-2H11zm2 0h4.4v-.4a.6.6 0 0 0-.6-.6h-3.2a.6.6 0 0 0-.6.6V8zm7 2h-9v10.4a.6.6 0 0 0 .6.6h7.6c.402 0 .8-.34.8-.6V10z"
        fillRule="evenodd"
      />
    </svg>
  );
}
