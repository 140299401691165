import React from "react";
import { IconProps } from "../../../types";

export default function House({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 60 60"
      data-testid="house-icon"
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M24.813.063a1 1 0 0 0-.532.312L.375 25.25a1.006 1.006 0 1 0 1.469 1.375L5 23.344V50h40V23.344l3.156 3.281a1.006 1.006 0 1 0 1.469-1.375L25.719.375a1 1 0 0 0-.907-.313zM25 2.5l18 18.75V48H32V27H18v21H7V21.25L25 2.5zM35 5v3.031l2 2.063V7h2v5.188l2 2.093V5h-6zM20 29h10v19H20V29 Z"
      />
    </svg>
  );
}
