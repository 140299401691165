import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function UploadIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="3 3 24 24"
      className={className}
    >
      <path
        d="M23 18.75a1 1 0 0 1 1 1v2.55c0 1.501-1.253 2.7-2.778 2.7H8.778C7.253 25 6 23.801 6 22.3v-2.55a1 1 0 0 1 2 0v2.55c0 .376.338.7.778.7h12.444c.44 0 .778-.324.778-.7v-2.55a1 1 0 0 1 1-1zM15 6h.02c.023 0 .046.002.07.004L15 6a1.008 1.008 0 0 1 .595.196c.04.03.077.061.112.097l-.09-.08.008.007.082.073 3.4 3.4a1 1 0 1 1-1.414 1.414L16 9.414V18.9a1 1 0 0 1-2 0V9.414l-1.693 1.693a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l3.4-3.4.082-.073A1.005 1.005 0 0 1 15 6z"
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
      />
    </svg>
  );
}
