import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";
import { ReactElement } from "react";

import "../../../styles/components/common/form/DateYearInput.scss";

export default function DateYearInput({
  input,
  meta,
  className,
  label,
  required,
  ...rest
}: FieldRenderProps<string | undefined, HTMLInputElement>): ReactElement {
  const dateYearInputClassName = classNames(className, {
    "date-year-input-field": true,
    "date-year-input-error": (meta.error || meta.submitError) && meta.touched,
  });
  const date = new Date();
  return (
    <section className={dateYearInputClassName}>
      <label>
        {label && (
          <span className="date-year-input-field-label" role="label">
            {label} {required && "*"}
          </span>
        )}
        <input
          {...input}
          {...rest}
          type="number"
          min={date.getFullYear() - 100}
          max={date.getFullYear() + 10}
          step="1"
          required={required}
        />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="date-year-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
