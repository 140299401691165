import { useSelector } from "react-redux";
import { selectFileByReferenceId } from "../slices/fileUploadSlice";
import { UploadState } from "../enums";

export function shallowFilesEquals(
  left: { uploadState: UploadState; file: File; error?: object }[],
  right: { uploadState: UploadState; file: File; error?: object }[],
) {
  if (left.length !== right.length) {
    return false;
  }

  for (let i = 0; i < left.length; i++) {
    const {
      uploadState: uploadStateLeft,
      file: fileLeft,
      error: errorLeft,
    } = left[i];
    const {
      uploadState: uploadStateRight,
      file: fileRight,
      error: errorRight,
    } = right[i];

    if (
      uploadStateLeft !== uploadStateRight ||
      fileLeft.name !== fileRight.name ||
      errorRight !== errorLeft
    ) {
      return false;
    }
  }
  return true;
}
export default function useUploadFiles(referenceId: string | number) {
  return useSelector(selectFileByReferenceId(referenceId), shallowFilesEquals);
}
