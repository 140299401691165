import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import TextArea from "../common/form/TextArea";
import Send from "../icons/Send";
import LoadingSpinner from "../icons/LoadingSpinner";
import { validateFileFirstBytes } from "../../lib/formValidate";
import FileInput from "../common/form/FileInput";
import FilePreview from "../common/form/FilePreview";
import useCreateMessageAnswer from "../../hooks/messages/useCreateMessageAnswer";
import IconButton from "../common/IconButton";

import "../../styles/components/messages/MessageAnswerCreateForm.scss";

export default function MessageAnswerCreateForm(): JSX.Element {
  const { save, isLoading, messageId } = useCreateMessageAnswer();

  return (
    <Form
      onSubmit={save}
      render={({
        pristine,
        handleSubmit,
        submitting,
        submitSucceeded,
        hasSubmitErrors,
        form,
      }) => {
        useEffect(() => {
          if (submitSucceeded && !hasSubmitErrors) {
            form.restart();
          }
        }, [submitSucceeded, hasSubmitErrors]);

        useEffect(() => {
          form.restart();
        }, [messageId]);

        return (
          <form
            className="message-answer-contacts-create-form"
            onSubmit={handleSubmit}
            role="form"
            noValidate
          >
            <div className="message-answer-contacts-create-form-preview">
              <Field
                component={FilePreview}
                name="documents"
                subscription={{ error: true, value: true, touched: true }}
              />
            </div>
            <div className="message-answer-contacts-create-form-fields">
              <Field
                component={FileInput}
                name="documents"
                validate={validateFileFirstBytes()}
                width={36}
                height={36}
              />
              <Field
                component={TextArea}
                name="text"
                required
                disabled={isLoading}
                rows={2}
              />
              {!isLoading && (
                <IconButton
                  lightblue
                  icon={Send}
                  type="submit"
                  disabled={pristine || submitting}
                  width={32}
                  height={32}
                />
              )}
              {isLoading && <LoadingSpinner width={32} height={32} />}
            </div>
          </form>
        );
      }}
    />
  );
}
