import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function DashboardDocumentItemSkeleton(): JSX.Element {
  return (
    <Link to={`/`} className="dashboard-right-container-item-documents">
      <div className="icon">
        <Skeleton width={50} height={60} />
      </div>
      <div className="dashboard-right-container-item-documents-info">
        <span>
          <Skeleton width={250} />
        </span>
        <div className="size">
          <span>
            <Skeleton width={110} />
          </span>

          <span>
            <Skeleton width={110} />
          </span>
        </div>
      </div>
    </Link>
  );
}
