import React from "react";
import { IconProps } from "../../../types";

export default function ServicesReading({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M60 20c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c-.024-22.081-17.919-39.976-40-40zm24.423 62.667a52.793 52.793 0 0 0-48.85 0c-12.31-13.255-11.8-33.91 1.147-46.542 12.948-12.633 33.609-12.633 46.556 0 12.948 12.632 13.457 33.287 1.147 46.542z" />
        <path d="M38.333 57.5h-5a2.5 2.5 0 0 0 0 5h5a2.5 2.5 0 1 0 0-5zM43.233 39.7a2.5 2.5 0 1 0-3.533 3.533l3.533 3.534a2.5 2.5 0 1 0 3.534-3.534L43.233 39.7zM60 40.833a2.5 2.5 0 0 0 2.5-2.5v-5a2.5 2.5 0 0 0-5 0v5a2.5 2.5 0 0 0 2.5 2.5zM86.667 57.5h-5a2.5 2.5 0 1 0 0 5h5a2.5 2.5 0 0 0 0-5zM81.18 42.153a1.667 1.667 0 0 0-2.037-.25c-2.296 1.38-22.51 13.554-25.036 16.08a7.963 7.963 0 0 0 0 11.244 8.077 8.077 0 0 0 11.243 0c2.527-2.527 14.7-22.74 16.08-25.034a1.667 1.667 0 0 0-.25-2.04z" />
      </g>
    </svg>
  );
}
