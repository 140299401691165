import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocumentMeetingResultsList from "./PdfDocumentMeetingResultsList";
import { PropositionsResultForPDF } from "../../../types";
import Button from "../../common/Button";

export default function PdfDocument({
  propositionsWithVotingResult,
  meetingTitle,
  meetingLocation,
  startDate,
  endDate,
  logoStamp,
  meetingLeader,
  isFetching,
}: Props) {
  return (
    <div>
      <PDFDownloadLink
        document={
          <PdfDocumentMeetingResultsList
            propositionsWithVotingResult={propositionsWithVotingResult}
            meetingTitle={meetingTitle}
            meetingLocation={meetingLocation}
            startDate={startDate}
            endDate={endDate}
            logoStamp={logoStamp}
            meetingLeader={meetingLeader}
          />
        }
        fileName="PDF_Zusammenfassung.pdf"
        className="etv-pdf-link"
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {({ loading, error }: { loading: boolean; error: Error }) => {
          console.log("pdf", loading, error);
          return (
            <Button
              label={
                loading || isFetching
                  ? "Bitte warten, PDF-Zusammenfassung wird erstellt..."
                  : "PDF Zusammenfassung herunterladen"
              }
              className="etv-pdf-button"
              disabled={loading || isFetching || Boolean(error)}
            />
          );
        }}
      </PDFDownloadLink>
    </div>
  );
}

type Props = {
  propositionsWithVotingResult?: PropositionsResultForPDF[];
  meetingTitle: string;
  meetingLocation: string;
  startDate?: Date;
  endDate?: Date;
  logoStamp?: string;
  meetingLeader: string;
  isFetching: boolean;
};
