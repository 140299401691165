import { IconProps } from "../../types";

export default function LoginIcon({
  width,
  height,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M15.252 14l-2.737-2.737a1 1 0 0 1 1.414-1.415l4.445 4.445a1 1 0 0 1 0 1.414l-4.445 4.445a1 1 0 0 1-1.414-1.415L15.252 16H7a1 1 0 1 1 0-2h8.252zm2.415-6a1 1 0 1 1 0-2h3.555A2.778 2.778 0 0 1 24 8.778v12.444A2.778 2.778 0 0 1 21.222 24h-3.555a1 1 0 1 1 0-2h3.555c.43 0 .778-.348.778-.778V8.778A.778.778 0 0 0 21.222 8h-3.555z"
      />
    </svg>
  );
}
