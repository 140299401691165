import React from "react";
import { IconProps } from "../../types";

export default function ObjectIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="3 3 24 24"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M3 3h24v24H3z" />
        <path
          className={className ? `${className}-path` : undefined}
          d="M14.75 16h.5A2.75 2.75 0 0 1 18 18.75V24h-6v-5.25A2.75 2.75 0 0 1 14.75 16z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={className ? `${className}-path` : undefined}
          d="M6 21v-7.04a4.5 4.5 0 0 1 1.689-3.514l4.188-3.35a5 5 0 0 1 6.246 0l4.188 3.35A4.5 4.5 0 0 1 24 13.96V21a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
