import { createSlice } from "@reduxjs/toolkit";
import { AGENDA_ITEM_EDITING_REDUCER_PATH } from "../reducerPaths";

type InitialStateType = {
  agendaItemEditing: boolean;
  propositionEditing: boolean;
};
export const initialState: InitialStateType = {
  agendaItemEditing: false,
  propositionEditing: false,
};

const agendaItemEditingProcessSlice = createSlice({
  name: AGENDA_ITEM_EDITING_REDUCER_PATH,
  initialState,
  reducers: {
    isAgendaItemEditing: (state, action) => {
      state.agendaItemEditing = action.payload;
    },
    isPropositionEditing: (state, action) => {
      state.propositionEditing = action.payload;
    },
  },
});
export const { isAgendaItemEditing, isPropositionEditing } =
  agendaItemEditingProcessSlice.actions;
export default agendaItemEditingProcessSlice.reducer;
