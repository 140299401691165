import React from "react";
import { FieldRenderProps } from "react-final-form";

import "../../../styles/components/common/form/RadioButton.scss";
import classNames from "classnames";

export default function RadioButton({
  input,
  meta,
  className,
  label,
  required,
  checked,
  ...rest
}: FieldRenderProps<string, HTMLInputElement>): JSX.Element {
  return (
    <section className={classNames("radio-button-wrapper", className)}>
      <label className="radio-label">
        <input
          data-testid="radio-button"
          {...input}
          {...rest}
          type="radio"
          required={required}
          className="custom-radio"
          checked={checked}
        />
        <span>{label}</span>
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="currency-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
