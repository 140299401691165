import { useParams } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Customer, FacilityObject } from "../types";
import { useGetTermsOfUseIsAcceptedQuery } from "../api/termsOfUseApi";
import useCustomerRoles from "./useCustomerRoles";
import { useLazyGetAssignedObjectsQuery } from "../api/facilityManagementApi";
import { useGetCustomersQuery } from "../api/customerApi";
import { useEffect, useState } from "react";

export default function useFetchPostLoginBaseData(): {
  isSuccess: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  assignedObjects: FacilityObject[];
  selectedAssignedObject: FacilityObject | undefined;
  termsOfUseAccepted: boolean | undefined | null;
  customers: Customer[];
  isLoading: boolean;
} {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  useCustomerRoles();
  const {
    data: customers = [],
    isSuccess,
    isError,
    error,
    isLoading,
  } = useGetCustomersQuery();
  const [getAssignedObjects] = useLazyGetAssignedObjectsQuery();
  const [assignedObjects, setAssignedObjects] = useState<FacilityObject[]>([]);
  const [isSuccessAssignedObject, setIsSuccessAssignedObject] = useState(false);
  const { data: termsOfUseAccepted } = useGetTermsOfUseIsAcceptedQuery(
    {
      customerToken,
    },
    { skip: !customerToken },
  );

  useEffect(() => {
    const fn = async () => {
      if (customers.length) {
        const result = await Promise.all(
          customers.map(async (customer) => {
            const response = await getAssignedObjects({
              customerToken: customer.token,
            });
            if (response.data) {
              return response.data.map((item) => ({
                ...item,
                token: customer.token,
              }));
            }
            return [];
          }),
        );
        setAssignedObjects(result.flat() as FacilityObject[]);
        setIsSuccessAssignedObject(true);
      }
    };
    fn();
  }, [customers.length]);

  const selectedAssignedObject = assignedObjects.find(
    ({ token, id }) =>
      token.toLowerCase() === customerToken.toLowerCase() &&
      String(id) === facilityObjectId,
  );

  return {
    isSuccess: isSuccess && isSuccessAssignedObject,
    isError,
    error,
    customers,
    assignedObjects,
    selectedAssignedObject,
    termsOfUseAccepted,
    isLoading,
  };
}
