import Messages from "../icons/Messages";
import LoadingSpinner from "../icons/LoadingSpinner";

export default function MessageDetailsLoading(): JSX.Element {
  return (
    <div className="message-details-loading">
      <div>
        <Messages
          height={200}
          width={200}
          className="message-details-loading-icon"
        />
        <LoadingSpinner />
      </div>
    </div>
  );
}
