import { BulletinBoard } from "../../types";
import {
  useDisableBulletinBoardTenantPostingMutation,
  useEnableBulletinBoardTenantPostingMutation,
} from "../../api/bulletinBoardApi";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export default function useToggleTenantCanPost(
  facilityObjectId: string,
  data?: BulletinBoard,
) {
  const { customerToken = "" } = useParams();
  const [disableTenantPost, { isLoading: disableIsLoading }] =
    useDisableBulletinBoardTenantPostingMutation();
  const [enableTenantPost, { isLoading: enableIsLoading }] =
    useEnableBulletinBoardTenantPostingMutation();
  const toggleTenantsCanPost = useCallback(() => {
    if (data) {
      if (data.tenantsCanPost) {
        return disableTenantPost({
          customerToken,
          facilityObjectId,
        });
      }
      return enableTenantPost({
        customerToken,
        facilityObjectId,
      });
    }
  }, [data, facilityObjectId]);

  return {
    toggleTenantsCanPost,
    isLoading: enableIsLoading || disableIsLoading,
  };
}
