import { useGetIdentityQuery } from "../api/customerApi";
import { useParams } from "react-router-dom";
import { Identity } from "../types";
import { useGetLoginInfoQuery } from "../api/homecaseMvcApi";

export default function useIdentity(): Identity | undefined {
  const { customerToken = "" } = useParams();
  const { data: isLoggedIn } = useGetLoginInfoQuery();
  const { data } = useGetIdentityQuery(
    { customerToken },
    { skip: customerToken === "" || !isLoggedIn },
  );

  return data;
}
