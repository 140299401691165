import ServicesBuild from "../components/icons/serviceProviders/ServicesBuild";
import ServicesManagementOfContract from "../components/icons/serviceProviders/ServicesManagementOfContract";
import ServicesLawLawyer from "../components/icons/serviceProviders/ServicesLawLawyer";
import ServicesTechnicalAdministration from "../components/icons/serviceProviders/ServicesTechnicalAdministration";
import ServicesExterminator from "../components/icons/serviceProviders/ServicesExterminator";
import ServicesInsurance from "../components/icons/serviceProviders/ServicesInsurance";
import { IconProps } from "../types";
import ServicesUtilizer from "../components/icons/serviceProviders/ServicesUtilizer";
import ServicesLift from "../components/icons/serviceProviders/ServicesLift";
import ServicesTVAntenna from "../components/icons/serviceProviders/ServicesTVAntenna";
import ServicesAccountancy from "../components/icons/serviceProviders/ServicesAccountancy";
import ServicesBuildingInsurance from "../components/icons/serviceProviders/ServicesBuildingInsurance";
import ServicesBanking from "../components/icons/serviceProviders/ServicesBanking";
import ServicesAir from "../components/icons/serviceProviders/ServicesAir";
import ServicesBoilerWater from "../components/icons/serviceProviders/ServicesBoilerWater";
import ServicesChemistryMold from "../components/icons/serviceProviders/ServicesChemistryMold";
import ServicesChimney from "../components/icons/serviceProviders/ServicesChimney";
import ServicesCleaning from "../components/icons/serviceProviders/ServicesCleaning";
import ServicesDemageInsurance from "../components/icons/serviceProviders/ServicesDamageInsurance";
import ServicesElektro from "../components/icons/serviceProviders/ServicesElektro";
import ServicesEmergency from "../components/icons/serviceProviders/ServicesEmergency";
import ServicesFirePrevention from "../components/icons/serviceProviders/ServicesFirePrevention";
import ServicesFloorTiles from "../components/icons/serviceProviders/ServicesFloorTiles";
import ServicesGarage from "../components/icons/serviceProviders/ServicesGarage";
import ServicesGarden from "../components/icons/serviceProviders/ServicesGarden";
import ServicesGasOil from "../components/icons/serviceProviders/ServicesGasOil";
import ServicesGlass from "../components/icons/serviceProviders/ServicesGlass";
import ServicesHeating from "../components/icons/serviceProviders/ServicesHeating";
import ServicesInternet from "../components/icons/serviceProviders/ServicesInternet";
import ServicesKey from "../components/icons/serviceProviders/ServicesKey";
import ServicesMove from "../components/icons/serviceProviders/ServicesMove";
import ServicesPainter from "../components/icons/serviceProviders/ServicesPainter";
import ServicesPlayground from "../components/icons/serviceProviders/ServicesPlayground";
import ServicesReading from "../components/icons/serviceProviders/ServicesReading";
import ServicesServiceProviderDefault from "../components/icons/serviceProviders/ServicesServiceProviderDefault";
import ServicesSmokeDetector from "../components/icons/serviceProviders/ServicesSmokeDetector";
import ServicesWater from "../components/icons/serviceProviders/ServicesWater";
import ServicesWinterService from "../components/icons/serviceProviders/ServicesWinterService";

export const SERVICE_PROVIDER_ICONS: IconsType = [
  {
    icon: ServicesWater,
    title: new RegExp("Wasser|Wasserschaden|Wasserqualitätsprüfung", "i"),
  },
  {
    icon: ServicesReading,
    title: new RegExp("Ablezung|Zähler|Messdienst", "i"),
  },
  {
    icon: ServicesPainter,
    title: new RegExp("Maler", "i"),
  },
  {
    icon: ServicesKey,
    title: new RegExp("Schlüssel|Tor", "i"),
  },
  {
    icon: ServicesGlass,
    title: new RegExp("Glas", "i"),
  },
  {
    icon: ServicesBuild,
    title: new RegExp("Bau", "i"),
  },
  {
    icon: ServicesManagementOfContract,
    title: new RegExp("Bewirtschaftung|Auftrag", "i"),
  },
  {
    icon: ServicesLawLawyer,
    title: new RegExp("Recht|Anwalt", "i"),
  },
  {
    icon: ServicesTechnicalAdministration,
    title: new RegExp("Technische Objektverwaltung|Architekt", "i"),
  },
  {
    icon: ServicesChemistryMold,
    title: new RegExp(
      "Schimmel|Käfer|Schädling|Sanierung|Entfern|Chemie|Graffiti",
      "i",
    ),
  },
  {
    icon: ServicesExterminator,
    title: new RegExp("Vogel|Tier", "i"),
  },
  {
    icon: ServicesChimney,
    title: new RegExp("Schornsteinfeger|Kaminkehrer", "i"),
  },
  {
    icon: ServicesInsurance,
    title: new RegExp("Versicherung", "i"),
  },
  {
    icon: ServicesLift,
    title: new RegExp("aufzug|lift", "i"),
  },
  {
    icon: ServicesUtilizer,
    title: new RegExp("Ver-/Entsorger|Müll|Abfall", "i"),
  },
  {
    icon: ServicesTVAntenna,
    title: new RegExp("Fernseh|Rundfunk|Kabel|Antenne|SAT", "i"),
  },
  {
    icon: ServicesAccountancy,
    title: new RegExp("Prüfer|Beirat|Buchhalter|Gutachter", "i"),
  },
  {
    icon: ServicesBuildingInsurance,
    title: new RegExp("Gebäude|Gebäudeversicherung|Hausmeister", "i"),
  },
  {
    icon: ServicesBanking,
    title: new RegExp("Bank|Geld|Abrechnung", "i"),
  },
  {
    icon: ServicesAir,
    title: new RegExp("Lüftung|Luft|Klima", "i"),
  },
  {
    icon: ServicesBoilerWater,
    title: new RegExp("Boiler", "i"),
  },
  {
    icon: ServicesCleaning,
    title: new RegExp("Reinigung", "i"),
  },
  {
    icon: ServicesDemageInsurance,
    title: new RegExp("Schaden|Sachbearbeiter|Versicherungsvertreter", "i"),
  },
  {
    icon: ServicesElektro,
    title: new RegExp("Elektro|Strom|Blitzschutz", "i"),
  },
  {
    icon: ServicesEmergency,
    title: new RegExp("Winterdienst", "i"),
  },
  {
    icon: ServicesFirePrevention,
    title: new RegExp("Feuer|Feuerlöscher|Brandschutz", "i"),
  },
  {
    icon: ServicesFloorTiles,
    title: new RegExp("Fliesenleger|Fliesen|Parkett|Boden", "i"),
  },
  {
    icon: ServicesGarage,
    title: new RegExp("Garage|Stellplatz|Parken", "i"),
  },
  {
    icon: ServicesGarden,
    title: new RegExp("Garten", "i"),
  },
  {
    icon: ServicesGasOil,
    title: new RegExp("Gas|Öl|Fernwärme", "i"),
  },
  {
    icon: ServicesHeating,
    title: new RegExp("Heizung|Sanitär", "i"),
  },
  {
    icon: ServicesInternet,
    title: new RegExp("Internet", "i"),
  },
  {
    icon: ServicesMove,
    title: new RegExp("Umzug|Transport", "i"),
  },
  {
    icon: ServicesPlayground,
    title: new RegExp("Wartung|Spielplatz", "i"),
  },
  {
    icon: ServicesServiceProviderDefault,
    title: new RegExp("Dienstleister|Handwerker|Service", "i"),
  },
  {
    icon: ServicesSmokeDetector,
    title: new RegExp("Rauchwarnmelder|Klingel|Sprechanlage|IoT|Gerät", "i"),
  },
  {
    icon: ServicesWinterService,
    title: new RegExp("Rauchwarnmelder|Klingel|Sprechanlage|IoT|Gerät", "i"),
  },
];

type IconType = {
  icon: React.FunctionComponent<IconProps>;
  title: RegExp;
};
type IconsType = IconType[];
