import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Field } from "react-final-form";
import "../../../styles/components/management/objectSettings/SendInvitationDialogDescription.scss";
import { useGetCommunicationDataQuery } from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import TextInput from "../../common/form/TextInput";
import Menu from "../../common/Menu";
import CaretDown from "../../icons/CaretDown";
import MenuListItem from "../../common/menu/MenuListItem";
import MenuListDiver from "../../common/menu/MenuListDiver";
import IconButton from "../../common/IconButton";

export default function SendInvitationDialogDescription({
  personId,
}: Props): JSX.Element {
  const { customerToken = "" } = useParams();
  const { data } = useGetCommunicationDataQuery({
    customerToken,
    personId: personId,
  });
  const [inputValue, setInputValue] = useState("");

  const communicationInfo = useMemo(
    () => data?.filter((item) => item.type === 3),
    [data],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const [isOpen, setOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [isOpen]);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [isOpen]);

  const validateEmail = (value: string) => {
    let error;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      error = "E-Mail-Adresse ist erforderlich.";
    } else if (!emailRegex.test(value)) {
      error = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
    }
    return error;
  };

  return (
    <section className="send-invitation-dialog-info-wrapper">
      <div className="invitation-wrapper">
        <span className="invitation-description">
          Wählen Sie eine E-Mail Adresse aus, an die eine Einladung versendet
          werden soll.
        </span>
      </div>

      <div className="input-field-wrapper">
        <Field
          name="eMail"
          type="email"
          data-testid="eMail"
          component={TextInput}
          placeholder={
            communicationInfo && communicationInfo.length > 0
              ? "E-Mail Adresse eingeben oder auswählen"
              : "E-Mail Adresse eingeben"
          }
          value={inputValue}
          defaultValue={inputValue}
          initialValue={inputValue}
          required
          onChange={handleInputChange}
          validate={validateEmail}
        />
        {communicationInfo && communicationInfo.length > 0 && (
          <Menu
            open={isOpen}
            button={
              <IconButton
                onClick={() => {
                  openMenu();
                }}
                icon={CaretDown}
              />
            }
          >
            {isOpen ? (
              <div>
                {communicationInfo?.map((item, index) => (
                  <Fragment>
                    <MenuListItem
                      key={item.value + index}
                      onClick={() => {
                        closeMenu();
                        setInputValue(item.value);
                      }}
                    >
                      {item.value}
                    </MenuListItem>
                    <MenuListDiver key={item.description + index} />
                  </Fragment>
                ))}
              </div>
            ) : (
              <div />
            )}
          </Menu>
        )}
      </div>
    </section>
  );
}

type Props = {
  personId: number;
  isSubmitErrorsText: boolean;
};
