import React from "react";
import { IconProps } from "../../types";

export default function SettingsIcon({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="5 5 25 20"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M15 18.455a3.455 3.455 0 1 1 0-6.91 3.455 3.455 0 0 1 0 6.91zm0-2a1.455 1.455 0 1 0 0-2.91 1.455 1.455 0 0 0 0 2.91zm7.29-.819h.074a.636.636 0 0 0 0-1.272h-.143a2.35 2.35 0 0 1-2.151-1.425l-.08-.188v-.09a2.355 2.355 0 0 1 .562-2.377l.05-.05a.636.636 0 1 0-.902-.9l-.057.056a2.346 2.346 0 0 1-2.582.475 2.351 2.351 0 0 1-1.425-2.155v-.074a.636.636 0 1 0-1.272 0v.143a2.35 2.35 0 0 1-1.425 2.151l-.188.08h-.09c-.825.27-1.74.06-2.377-.562l-.05-.05a.636.636 0 1 0-.9.902l.056.057c.672.687.858 1.713.494 2.534a2.357 2.357 0 0 1-2.174 1.538h-.074a.636.636 0 1 0 0 1.273h.143a2.346 2.346 0 0 1 2.147 1.414 2.354 2.354 0 0 1-.478 2.6l-.05.05a.636.636 0 1 0 .902.9l.057-.056c.687-.672 1.713-.858 2.534-.494a2.357 2.357 0 0 1 1.538 2.174v.074a.636.636 0 0 0 1.273 0v-.143a2.346 2.346 0 0 1 1.414-2.147 2.354 2.354 0 0 1 2.6.478l.05.05a.636.636 0 1 0 .9-.902l-.056-.057a2.35 2.35 0 0 1-.472-2.587 2.351 2.351 0 0 1 2.152-1.42zm-.065-3.272h.139a2.636 2.636 0 0 1 0 5.272h-.07a.35.35 0 0 0-.32.212l-.005.01a.347.347 0 0 0 .063.379l.048.048a2.636 2.636 0 1 1-3.729 3.73l-.041-.041a.354.354 0 0 0-.396-.066.349.349 0 0 0-.212.317v.139a2.636 2.636 0 0 1-5.273 0v-.05c-.003-.144-.094-.271-.287-.345a.347.347 0 0 0-.379.063l-.048.048a2.636 2.636 0 1 1-3.73-3.729l.041-.041a.354.354 0 0 0 .066-.396.349.349 0 0 0-.317-.212h-.139a2.636 2.636 0 0 1 0-5.273h.05c.144-.003.271-.094.345-.287a.347.347 0 0 0-.063-.379l-.048-.048a2.636 2.636 0 1 1 3.729-3.73l.041.041a.35.35 0 0 0 .386.07l.19-.084a.345.345 0 0 0 .098-.237v-.139a2.636 2.636 0 1 1 5.272 0v.07c0 .14.084.265.222.325.131.057.284.03.379-.063l.048-.048a2.636 2.636 0 1 1 3.73 3.729l-.041.041a.35.35 0 0 0-.07.386l.084.19c.063.061.148.097.237.098z"
        fillRule="evenodd"
      />
    </svg>
  );
}
