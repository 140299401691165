import { useParams } from "react-router-dom";
import { useGetFacilityObjectsQuery } from "../api/facilityManagementApi";
import { useMemo, useState } from "react";
import { FacilityObjectType } from "../enums";

export default function useFacilityObjectsWithFilter(filterQuery?: string) {
  const [search, setSearch] = useState("");
  const { facilityObjectId = "" } = useParams();
  const [filter, setFilter] = useState<FacilityObjectType | undefined>(
    undefined,
  );
  const [offline, setOffline] = useState<boolean>(true);
  const { customerToken = "" } = useParams();
  const {
    data = [],
    isFetching,
    isError,
    error,
  } = useGetFacilityObjectsQuery(
    { customerToken, filter: filterQuery },
    { skip: customerToken === "" },
  );

  const facilityObjects = useMemo(() => {
    if (search === "" && !filter && offline) {
      return [...data].sort((a, b) => (a.number > b.number ? 1 : -1));
    }
    return data
      .filter((item) => {
        const searchString =
          `${item.number} ${item.description} ${item.street} ${item.city} ${item.zipCode}`.toLowerCase();
        return (
          searchString.includes(search.toLowerCase()) &&
          (filter ? item.facilityObjectType === filter : true) &&
          (!offline ? item.isOnline === true : true)
        );
      })
      .sort((a, b) => (a.number > b.number ? 1 : -1));
  }, [data, search, filter, offline]);

  return {
    isError,
    isFetching,
    facilityObjects,
    error,
    setFilter,
    filter,
    offline,
    setSearch,
    setOffline,
    data,
    facilityObjectId,
  };
}
