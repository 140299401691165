import React, { ReactElement, useCallback } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/HomePage.scss";
import ObjectsList from "../home/ObjectList";
import SearchField from "../common/SearchField";
import useSearchByObjects from "../../hooks/home/useSearchByObjects";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";
import InfoText from "../userSettings/InfoText";
import useLocalStorage from "../../hooks/useLocalStorage";
import { FacilityObjectListSortingType } from "../../enums";
import ObjectListHeaderActionsSort from "../home/ObjectListHeaderActionsSort";
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollToTopButton from "../common/ScrollToTopButton";

export default function HomePage(): ReactElement {
  const { assignedObjects, customers, isLoading } = useFetchPostLoginBaseData();
  const { handleChangeSearchString, objectsList } =
    useSearchByObjects(assignedObjects);
  const [isSortedBy, setSortedBy] = useLocalStorage(
    "objects-sorted-by",
    FacilityObjectListSortingType.None,
  );

  const handleSelect = useCallback(
    (sortedBy: FacilityObjectListSortingType) => {
      setSortedBy(sortedBy);
    },
    [],
  );

  const { rootRef, scrollToTop, showScrollToTop } = useScrollToTop();
  return (
    <div className="home-page-container">
      <Helmet>
        <title> Alle Adressen - Homecase</title>
      </Helmet>
      <PageHeader
        title="Alle Adressen"
        searchComponent={SearchField}
        onSearch={(evt) => {
          handleChangeSearchString(evt);
        }}
      />
      {customers?.length !== 0 ? (
        <div className="home-page-container-info" ref={rootRef}>
          {customers?.map((item) => (
            <div className={"management-objects-container"} key={item.name}>
              <div className="tittle-sort-wrapper">
                <h2 className="management-name">{item.name}</h2>
                <ObjectListHeaderActionsSort
                  groupingType={isSortedBy}
                  onSelect={handleSelect}
                />
              </div>
              <ObjectsList
                token={item.token}
                facilityObjects={objectsList.filter(
                  ({ token }) => token == item.token,
                )}
                isLoading={isLoading}
                isSortedBy={isSortedBy}
              />
            </div>
          ))}
        </div>
      ) : (
        <InfoText isInfo={true} textInfo="Keine online Objekte gefunden." />
      )}
      {showScrollToTop && <ScrollToTopButton scrollToTop={scrollToTop} />}
    </div>
  );
}
