import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

import { PropositionVotingState, PropositionVotingType } from "../../enums";
import {
  propositionApi,
  useStartVotingMutation,
  useStopSimpleVotingMutation,
  useStopVotingMutation,
} from "../../api/propositionApi";

export default function usePropositionVotingControl(
  id: string,
  votingState: PropositionVotingState,
  votingType?: PropositionVotingType,
): UsePropositionVotingControl {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const { data = [] } = propositionApi.endpoints.getPropositions.useQueryState({
    customerToken,
    meetingId,
    facilityObjectId,
    agendaItemId,
  });

  const isVotingRunning = Boolean(
    data.find(
      ({ votingState }) => votingState === PropositionVotingState.Running,
    ),
  );

  const [
    startVoting,
    {
      isLoading: startVotingIsLoading,
      isError: startVotingIsError,
      error: startVotingError,
      isSuccess: startVotingSuccess,
    },
  ] = useStartVotingMutation();
  const [
    stopVoting,
    {
      isLoading: stopVotingIsLoading,
      isError: stopVotingIsError,
      error: stopVotingError,
      isSuccess: stopVotingSuccess,
    },
  ] = useStopVotingMutation();

  const [
    stopSimpleVoting,
    {
      isLoading: stopSimpleVotingIsLoading,
      isError: stopSimpleVotingIsError,
      error: stopSimpleVotingError,
      isSuccess: stopSimpleVotingSuccess,
    },
  ] = useStopSimpleVotingMutation();

  const handleVotingControl = useCallback(
    (values?: Record<string, number>) => {
      const totalVotes: number = [
        Number(values?.totalYesVotes),
        Number(values?.totalNoVotes),
        Number(values?.totalAbstentionVotes),
      ].reduce((a, b) => a + b, 0);

      if (
        [PropositionVotingState.Closed, PropositionVotingState.None].includes(
          votingState,
        ) &&
        !isVotingRunning
      ) {
        startVoting({
          customerToken,
          facilityObjectId,
          meetingId,
          propositionId: id,
          agendaItemId,
          keepStoredVotes: Boolean(values?.keepStoredVotes),
        });
      }
      if (
        votingState === PropositionVotingState.Running &&
        votingType !== PropositionVotingType.Simple
      ) {
        stopVoting({
          customerToken,
          facilityObjectId,
          meetingId,
          propositionId: id,
          agendaItemId,
        });
      }
      if (
        votingState === PropositionVotingState.Running &&
        votingType === PropositionVotingType.Simple &&
        Number(values?.totalAttendedParticipants) >= totalVotes
      ) {
        stopSimpleVoting({
          customerToken,
          facilityObjectId,
          meetingId,
          propositionId: id,
          agendaItemId,
          votingResult: {
            totalYesVotes: Number(values?.totalYesVotes),
            totalNoVotes: Number(values?.totalNoVotes),
            totalAbstentionVotes: Number(values?.totalAbstentionVotes),
            totalAttendedParticipants: Number(
              values?.totalAttendedParticipants,
            ),
          },
        });
      }
    },
    [
      votingState,
      id,
      customerToken,
      facilityObjectId,
      meetingId,
      isVotingRunning,
    ],
  );

  return {
    handleVotingControl,
    isLoading:
      startVotingIsLoading || stopVotingIsLoading || stopSimpleVotingIsLoading,
    isError: startVotingIsError || stopVotingIsError || stopSimpleVotingIsError,
    error: stopVotingError || startVotingError || stopSimpleVotingError,
    startVotingSuccess,
    stopVotingSuccess,
    isVotingRunning,
    stopSimpleVotingSuccess,
  };
}

type UsePropositionVotingControl = {
  handleVotingControl: (values?: Record<string, number>) => void;
  isLoading: boolean;
  isError: boolean;
  stopVotingSuccess: boolean;
  startVotingSuccess: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  isVotingRunning: boolean;
  stopSimpleVotingSuccess: boolean;
};
