import {
  FacilityDocumentRolePermissionsGrantedUserEventModel,
  NotificationProps,
} from "../../types";
import { getShortenText } from "../../lib/notifications";
import NotificationItemDate from "./NotificationItemDate";

export default function FacilityDocumentRolePermissionsGrantedUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): JSX.Element {
  const { facilityObjectId, customerToken, raisedAtUtc, text } =
    userEventData as FacilityDocumentRolePermissionsGrantedUserEventModel;

  return (
    <a
      href={`/${customerToken}/objekte/${facilityObjectId}/dokumente`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong> <span>{getShortenText(text)}</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </a>
  );
}
