import { useParams } from "react-router-dom";
import { useSetVoteForParticipantMutation } from "../../api/propositionApi";
import { SyntheticEvent, useCallback } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { PropositionVoteType } from "../../enums";

export default function useVoteForParticipant(
  participantId: string,
  propositionId: string,
): VoteForParticipant {
  const {
    meetingId = "",
    customerToken = "",
    agendaItemId = "",
    facilityObjectId = "",
  } = useParams();

  const [
    setVoteForParticipantMutation,
    { isLoading, isError, error, originalArgs, isSuccess },
  ] = useSetVoteForParticipantMutation();

  const voteForParticipant = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const voteType = !e.currentTarget.value
        ? PropositionVoteType.None
        : parseInt(e.currentTarget.value, 10);

      setVoteForParticipantMutation({
        propositionId,
        voteType,
        meetingId,
        customerToken,
        agendaItemId,
        participantId,
        facilityObjectId,
      });
    },
    [propositionId, participantId],
  );

  const retry = useCallback(() => {
    if (originalArgs) {
      setVoteForParticipantMutation(originalArgs);
    }
  }, [originalArgs]);

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    retry,
    voteForParticipant,
  };
}

type VoteForParticipant = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error?: FetchBaseQueryError | SerializedError;
  retry: () => void;
  voteForParticipant: (e: SyntheticEvent<HTMLButtonElement>) => void;
};
