import React, { ReactNode, SyntheticEvent } from "react";
import classNames from "classnames";

import "../../styles/components/common/TableSortHeader.scss";

export default function TableSortHeader({
  sortOptions,
  handleSort,
  sort,
  className,
}: Props) {
  return (
    <div className="table-sort-header" role="row">
      {sortOptions.map(({ label, sortBy }, index) => (
        <div
          key={index}
          className={classNames(`table-sort-header-column ${className}`, {
            [`table-sort-header-column-${sortBy?.toLowerCase()}`]:
              sortBy !== null,
            [`table-sort-header-column-sortable`]: sortBy !== null,
          })}
          aria-sort={
            sortBy !== null && sort.sortBy === sortBy
              ? sort.sortDirection
              : "none"
          }
          role="columnheader"
          aria-label={sortBy !== null ? sortBy : undefined}
          onClick={handleSort}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

type Props = {
  handleSort: (e: SyntheticEvent) => void;
  sortOptions: { label: string | ReactNode; sortBy: string | null }[];
  className?: string;
  sort: {
    sortBy: string;
    sortDirection: "descending" | "ascending" | "none";
  };
};
