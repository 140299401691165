import React, { ReactElement, useCallback, useState } from "react";
import classNames from "classnames";
import Menu from "../common/Menu";
import Button from "../common/Button";
import GroupedIcon from "../icons/GroupedIcon";
import CaretDown from "../icons/CaretDown";
import MenuListItem from "../common/menu/MenuListItem";
import MenuListDiver from "../common/menu/MenuListDiver";
import { GroupingType, FacilityObjectListSortingType } from "../../enums";
import { ActionComponentProps } from "../common/PageHeader";
import useFetchPostLoginBaseData from "../../hooks/useFetchPostLoginBaseData";
import { useParams } from "react-router-dom";

export default function ObjectListHeaderActionsSort({
  groupingType,
  onSelect,
}: Props): ReactElement {
  const { customers } = useFetchPostLoginBaseData();
  const defaultCustomerForEmployeeUser = customers[0];

  const { customerToken = defaultCustomerForEmployeeUser?.token ?? "" } =
    useParams();

  const isEmployee = customers.some(
    ({ token, currentUserIsEmployee }) =>
      token === customerToken && currentUserIsEmployee,
  );

  const [isOpen, setOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [isOpen]);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [isOpen]);

  return (
    groupingType &&
    isEmployee && (
      <Menu
        open={isOpen}
        button={
          <Button
            leadingIcon={GroupedIcon}
            className="home-page-header-actions-group-button"
            role={"button-sorting"}
            label="Sortierung"
            trailingIcon={CaretDown}
            onClick={openMenu}
          />
        }
      >
        {isOpen ? (
          <div>
            <p className="menu-title">Sortierung</p>
            <MenuListItem
              onClick={() => {
                onSelect(FacilityObjectListSortingType.None);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === FacilityObjectListSortingType.None,
              })}
            >
              Standard
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(FacilityObjectListSortingType.Alphabetical);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === FacilityObjectListSortingType.Alphabetical,
              })}
            >
              Alphabetisch
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(FacilityObjectListSortingType.Date);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === FacilityObjectListSortingType.Date,
              })}
            >
              Erstellungsdatum
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(FacilityObjectListSortingType.Postcode);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === FacilityObjectListSortingType.Postcode,
              })}
            >
              PLZ
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(FacilityObjectListSortingType.ObjectType);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === FacilityObjectListSortingType.ObjectType,
              })}
            >
              Objekttyp
            </MenuListItem>
          </div>
        ) : (
          <div></div>
        )}
      </Menu>
    )
  );
}

type Props = {
  groupingType: GroupingType;
  onSelect: (item: GroupingType) => void;
} & ActionComponentProps;
