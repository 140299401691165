import React, { Fragment } from "react";
import { PropositionsResultForPDF } from "../../../types";
import moment from "moment";
import PdfDocumentMeetingHeader from "./PdfDocumentMeetingHeader";
import PdfDocumentMeetingResultsListItem from "./PdfDocumentMeetingResultsListItem";
import PdfDocumentMeetingResultsListFooter from "./PdfDocumentMeetingResultsListFooter";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import PdfStyles from "./PdfStyles";

const MAX_ITEMS_PER_PAGE = 20;
export default function PdfDocumentMeetingResultsList({
  propositionsWithVotingResult,
  meetingTitle,
  meetingLocation,
  startDate,
  endDate,
  logoStamp,
  meetingLeader,
}: Props) {
  const formattedStartDate = moment(startDate).format("HH:mm");
  const formattedEndDate = moment(endDate).format("HH:mm");

  const totalPages = propositionsWithVotingResult
    ? Math.ceil(propositionsWithVotingResult.length / MAX_ITEMS_PER_PAGE)
    : 0;
  return (
    <Document>
      {[...Array(totalPages)].map((_, pageIndex) => (
        <Page size="A4" style={PdfStyles.page} key={pageIndex}>
          {pageIndex === 0 && (
            <Fragment>
              <PdfDocumentMeetingHeader
                meetingTitle={meetingTitle}
                meetingLocation={meetingLocation}
                formattedStartDate={formattedStartDate}
                formattedEndDate={formattedEndDate}
                logoStamp={logoStamp}
                meetingLeader={meetingLeader}
                startDate={startDate}
              />
              <View>
                <Text style={PdfStyles.titleVotingInfo}>
                  Aufführung aller Abstimmungen wie folgt:
                </Text>
              </View>
            </Fragment>
          )}

          {propositionsWithVotingResult
            ?.slice(
              pageIndex * MAX_ITEMS_PER_PAGE,
              (pageIndex + 1) * MAX_ITEMS_PER_PAGE,
            )
            .map((item, index) => (
              <PdfDocumentMeetingResultsListItem
                item={item}
                key={`${item.propositionId}-${index}`}
              />
            ))}

          <PdfDocumentMeetingResultsListFooter />
        </Page>
      ))}
    </Document>
  );
}

type Props = {
  propositionsWithVotingResult?: PropositionsResultForPDF[];
  meetingTitle: string;
  meetingLocation: string;
  startDate?: Date;
  endDate?: Date;
  logoStamp?: string;
  meetingLeader: string;
};
