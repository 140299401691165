import React, { ReactElement, ReactNode, useCallback } from "react";
import classNames from "classnames";
import { FacilityObject } from "../../types";

import IconButton from "./IconButton";
import CaretDown from "../icons/CaretDown";
import useLocalStorage from "../../hooks/useLocalStorage";

import "../../styles/components/common/Drawer.scss";

export default function Drawer({
  children,
  drawerHeaderElement,
  collapsable = true,
}: Props): ReactElement {
  const [small, setSmall] = useLocalStorage("drawer-collapse", false);
  const className = classNames({
    drawer: true,
    "drawer-small": small && collapsable,
  });
  const toggleDrawer = useCallback(() => {
    setSmall(!small);
  }, [small]);

  return (
    <aside className={className} data-testid="drawer">
      {collapsable && (
        <IconButton
          icon={CaretDown}
          className="drawer-action-button"
          onClick={toggleDrawer}
          width={16}
          height={16}
        />
      )}
      <div className="drawer-header" role="banner">
        {drawerHeaderElement}
      </div>
      {children}
    </aside>
  );
}

type Props = {
  children: ReactNode | boolean;
  drawerHeaderElement?: ReactElement;
  assignedObjects?: FacilityObject[];
  collapsable?: boolean;
};
