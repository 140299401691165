import { useParams } from "react-router-dom";
import { useGetTermsOfUseUsersListQuery } from "../../api/termsOfUseApi";

export default function useFetchTermsOfUseUserList(
  page?: number,
  sortBy?: number,
  sortOrder?: number,
  filterByUserName?: string,
  filterByVersion?: string,
  size?: number,
) {
  const { customerToken = "" } = useParams();

  const { data } = useGetTermsOfUseUsersListQuery({
    customerToken,
    size,
    page,
    sortBy,
    sortOrder,
    filterByUserName,
    filterByVersion,
  });

  return { data };
}
