import React, { Fragment, ReactNode, SyntheticEvent } from "react";

import "../../styles/components/common/SwitchInput.scss";

export default function SwitchInput({
  id,
  label,
  onClick,
  onChange,
  checked,
  defaultChecked,
  infoText,
  disabled,
  required,
  infoNotification,
}: Props): JSX.Element {
  return (
    <Fragment>
      <section className="switch-wrapper">
        <label className="tgl-label">
          <input
            data-testid="switch"
            className="tgl tgl-input"
            type="checkbox"
            checked={checked}
            defaultChecked={defaultChecked}
            onClick={onClick}
            onChange={onChange}
            disabled={disabled}
            required={required}
            id={id}
          />
          <span className="tgl-circle"></span>
          {label}
        </label>
        {(infoText || infoNotification) && (
          <span className="info-text">
            {infoText}
            {infoNotification && (
              <span className="info-notification">{infoNotification}</span>
            )}
          </span>
        )}
      </section>
    </Fragment>
  );
}

type Props = {
  label?: string;
  disabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  onChange?: (event: SyntheticEvent) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  infoText?: string;
  infoNotification?: string | ReactNode;
  id?: string;
  required?: boolean;
};
