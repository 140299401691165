import React, { ReactElement, useCallback, useState } from "react";
import classNames from "classnames";
import Menu from "../common/Menu";
import Button from "../common/Button";
import GroupedIcon from "../icons/GroupedIcon";
import CaretDown from "../icons/CaretDown";
import MenuListItem from "../common/menu/MenuListItem";
import MenuListDiver from "../common/menu/MenuListDiver";
import { GroupingType } from "../../enums";
import { ActionComponentProps } from "../common/PageHeader";

export default function DocumentsPageHeaderActionsGroup({
  groupingType,
  onSelect,
}: Props): ReactElement {
  const [isOpen, setOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [isOpen]);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [isOpen]);

  return (
    groupingType && (
      <Menu
        open={isOpen}
        button={
          <Button
            leadingIcon={GroupedIcon}
            className="documents-page-header-actions-group-button"
            role={"button"}
            label={""}
            trailingIcon={CaretDown}
            onClick={openMenu}
          />
        }
      >
        {isOpen ? (
          <div>
            <p className="menu-title">Gruppierung nach:</p>
            <MenuListItem
              onClick={() => {
                onSelect(GroupingType.DocumentCategory);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active":
                  groupingType === GroupingType.DocumentCategory,
              })}
            >
              Dokumentenkategorien
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(GroupingType.Date);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active": groupingType === GroupingType.Date,
              })}
            >
              Datum
            </MenuListItem>
            <MenuListDiver />
            <MenuListItem
              onClick={() => {
                onSelect(GroupingType.None);
                closeMenu();
              }}
              className={classNames("", {
                "menu-list-item-active": groupingType === GroupingType.None,
              })}
            >
              Keine Gruppierung
            </MenuListItem>
          </div>
        ) : (
          <div></div>
        )}
      </Menu>
    )
  );
}

type Props = {
  groupingType: GroupingType;
  onSelect: (item: GroupingType) => void;
} & ActionComponentProps;
