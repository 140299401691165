import { Field, Form } from "react-final-form";
import { TextAreaForwardRef } from "../common/form/TextArea";
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { BulletinBoardPostCommentMaxTextLength } from "./PredefinedTextLengthValidationValues";
import "../../styles/components/bulletinBoard/PostEditComment.scss";
import IconButton from "../common/IconButton";
import Send from "../icons/Send";
import { usePutBulletinBoardPostCommentMutation } from "../../api/bulletinBoardApi";
import classNames from "classnames";

export default function PostEditComment({
  postId,
  facilityObjectId,
  customerToken,
  commentId,
  text,
}: Props): ReactElement {
  const [postComment, { isLoading }] = usePutBulletinBoardPostCommentMutation();
  const [postCommentTextLength, setPostCommentTextLength] = useState(
    text?.length ?? 0,
  );
  const [hasFocus, setHasFocus] = useState(false);

  const onSubmit = useCallback(async (values: Record<string, string>) => {
    const response = await postComment({
      bulletinBoardPostId: postId,
      facilityObjectId,
      customerToken,
      text: values.comment,
      commentId,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return response?.error ? response.error : undefined;
  }, []);

  const handleFocus = useCallback(() => setHasFocus(true), []);

  const handleInputChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget?.value;
    setPostCommentTextLength(value.length);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ comment: text }}
      render={({ handleSubmit, dirty, form }) => {
        const value = form.getFieldState("comment")?.value;
        const ref = useRef<HTMLTextAreaElement | null>(null);

        useEffect(() => {
          ref.current !== null && ref.current.focus();
        }, [ref]);

        return (
          <form
            onSubmit={handleSubmit}
            className="bulletin-board-post-list-item-edit-comment"
            data-testid="edit-comment"
            onFocus={handleFocus}
          >
            <div
              className={classNames(
                "bulletin-board-post-list-item-edit-comment-field",
                {
                  hasFocus,
                },
              )}
              data-testid="edit-comment-fields"
            >
              <Field
                name="comment"
                component={TextAreaForwardRef}
                ref={ref}
                placeholder="kommentieren..."
                rows={hasFocus ? 3 : 1}
                maxLength={BulletinBoardPostCommentMaxTextLength}
                onChange={handleInputChange}
              />
              {hasFocus && (
                <div
                  className={classNames(
                    "bulletin-board-post-list-item-edit-comment-text-length",
                    {
                      oversizing:
                        postCommentTextLength >=
                        BulletinBoardPostCommentMaxTextLength,
                    },
                  )}
                  data-testid="comment-text-length"
                >
                  {postCommentTextLength}/
                  {BulletinBoardPostCommentMaxTextLength}
                </div>
              )}
            </div>
            <IconButton
              icon={Send}
              lightblue
              disabled={!dirty || isLoading || !value}
            />
          </form>
        );
      }}
    />
  );
}

type Props = {
  postId: number;
  commentId: number;
  facilityObjectId: string;
  customerToken: string;
  text: string;
};
