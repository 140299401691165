import React from "react";
import { IconProps } from "../../types";

export default function Play({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M9.253 10.025v9.948c0 2.033 2.212 3.296 3.962 2.26l8.41-4.975c1.72-1.015 1.72-3.503 0-4.519l-8.41-4.974c-1.75-1.036-3.962.227-3.962 2.26zm2.943-.539 8.411 4.975c.41.242.41.834 0 1.075l-8.41 4.975a.625.625 0 0 1-.944-.538v-9.948c0-.484.527-.785.943-.539z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
