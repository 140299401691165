import React from "react";
import { IconProps } from "../../../types";

export default function ServicesBuildingInsurance({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(20 20)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <rect y="46.862" width="13.334" height="30.001" rx="1" />
        <path d="M79.082 63.56a1.667 1.667 0 0 0 .43-2.667 10.787 10.787 0 0 0-11.607-3.767c-.193.057-6.556 2.093-6.746 2.153a1.667 1.667 0 0 0-1.16 1.617 7.5 7.5 0 0 1-7.497 7.634h-17.5a1.667 1.667 0 0 1 0-3.334h17.5a4.167 4.167 0 0 0 0-8.333l-11.857.023a1.647 1.647 0 0 1-.907-.267c-.407-.26-.953-.666-1.427-1a22.787 22.787 0 0 0-13.31-5.423h-6.667c-.92 0-1.667.746-1.667 1.666V71.53c0 .714.454 1.35 1.13 1.58 2.354.797 4.47 1.533 6.39 2.2 8.747 3.027 13.628 4.693 18.061 4.693 5.413 0 10.164-2.473 20.464-7.953 4.27-2.27 9.587-5.097 16.37-8.49zM60.562 9.731a1.667 1.667 0 0 0 2.773-1.247v-5.15A3.333 3.333 0 0 0 60.002.001h-6a1.667 1.667 0 0 0-1.107 2.913l7.667 6.817zM18.334 25.002H25c.92 0 1.666.746 1.666 1.666v16.668a3.333 3.333 0 0 0 3.334 3.333h5a3.333 3.333 0 0 0 3.334-3.333v-8.334c0-.92.746-1.667 1.666-1.667h6.667c.92 0 1.667.746 1.667 1.667v8.334a3.333 3.333 0 0 0 3.333 3.333h5a3.333 3.333 0 0 0 3.334-3.333V26.668c0-.92.746-1.666 1.667-1.666h6.666a1.667 1.667 0 0 0 1.107-2.914L45.548.841a3.333 3.333 0 0 0-4.427 0L17.227 22.088a1.667 1.667 0 0 0 1.107 2.914z" />
      </g>
    </svg>
  );
}
