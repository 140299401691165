import React from "react";
import { IconProps } from "../../../types";

export default function ServicesTVAntenna({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M42.415 77.636A42.86 42.86 0 0 1 37.668 72L20.667 94.668a3.334 3.334 0 0 0 2.667 5.334H61.67a3.334 3.334 0 0 0 2.747-5.22l-4.64-6.751a42.297 42.297 0 0 1-17.362-10.395zM50.41 20.958a3.334 3.334 0 0 0-4.714.037 37.472 37.472 0 0 0 .24 53.091c14.742 14.57 38.494 14.462 53.105-.24a3.334 3.334 0 0 0-.034-4.714L77.135 47.464 84.11 40.7a9.97 9.97 0 0 0 14.231-9.085c-.025-5.505-4.495-9.954-10-9.954h-.047a9.968 9.968 0 0 0-8.918 14.335l-6.964 6.764L50.41 20.958z" />
      </g>
    </svg>
  );
}
