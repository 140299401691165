import { useGetUsersMyProfileQuery } from "../api/userApi";
import { UsersMyProfile } from "../types";
import { useGetLoginInfoQuery } from "../api/homecaseMvcApi";

export default function useGetUsersMyProfile(): Props {
  const defaultData: UsersMyProfile = {
    aboutMe: "",
    dayOfBirth: null,
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    monthOfBirth: null,
    profileVersionStamp: "00000000-0000-0000-0000-000000000000",
    sid: "00000000-0000-0000-0000-000000000000",
    yearOfBirth: null,
  };
  const { data: isLoggedIn } = useGetLoginInfoQuery();

  const { data: user = defaultData, isSuccess: isSuccessUsersMyProfile } =
    useGetUsersMyProfileQuery(undefined, { skip: !isLoggedIn });

  return { user, isSuccessUsersMyProfile };
}

type Props = {
  user: UsersMyProfile;
  isSuccessUsersMyProfile: boolean;
};
