import React from "react";
import { IconProps } from "../../types";

const ThreeDots = ({
  width = 8,
  height = 10,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 36" // Angepasster Viewport für drei Punkte
      className={className}
    >
      <circle
        cx="6"
        cy="6"
        r="2"
        className={className ? `${className}-circle` : undefined}
      />
      <circle
        cx="6"
        cy="18"
        r="2"
        className={className ? `${className}-circle` : undefined}
      />
      <circle
        cx="6"
        cy="30"
        r="2"
        className={className ? `${className}-circle` : undefined}
      />
    </svg>
  );
};

export default ThreeDots;
