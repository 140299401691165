import Button from "../../common/Button";
import CaretDown from "../../icons/CaretDown";
import Menu from "../../common/Menu";
import MenuListItem from "../../common/menu/MenuListItem";
import MenuListDiver from "../../common/menu/MenuListDiver";
import { PropositionVoteType } from "../../../enums";
import { useCallback, useState } from "react";
import People from "../../icons/management/People";
import classNames from "classnames";

export default function PropositionVoteCenterTableActionsMenu({
  onSelect,
  isDisabled,
}: Props): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [isOpen]);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [isOpen]);

  return (
    <Menu
      open={isOpen}
      button={
        <Button
          className={classNames(
            "agenda-item-proposition-vote-control-center-table-header-actions-button",
            {
              "button-active": isDisabled,
            },
          )}
          label="Teilnehmeraktionen"
          role={"button"}
          trailingIcon={CaretDown}
          onClick={() => {
            openMenu();
          }}
          leadingIcon={People}
          disabled={!isDisabled}
        />
      }
    >
      {isOpen ? (
        <div>
          <MenuListItem
            onClick={() => {
              onSelect(PropositionVoteType.Yes);
              closeMenu();
            }}
          >
            Ausgewählte auf Ja setzen
          </MenuListItem>
          <MenuListDiver />
          <MenuListItem
            onClick={() => {
              onSelect(PropositionVoteType.No);
              closeMenu();
            }}
          >
            Ausgewählte auf Nein setzen
          </MenuListItem>
          <MenuListDiver />
          <MenuListItem
            onClick={() => {
              onSelect(PropositionVoteType.None);
              closeMenu();
            }}
          >
            Alle Stimmen zurücksetzen
          </MenuListItem>
        </div>
      ) : (
        <div />
      )}
    </Menu>
  );
}

type Props = {
  onSelect: (voteType: PropositionVoteType) => void;
  isDisabled?: boolean;
};
