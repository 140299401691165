import { Field, Form } from "react-final-form";
import React, {
  Fragment,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import Button from "../../common/Button";
import IconButton from "../../common/IconButton";
import { TextAreaForwardRef } from "../../common/form/TextArea";
import Pencil from "../../icons/Pencil";
import { SynchronisationMethod, UserRoles } from "../../../enums";
import "../../../styles/components/meetings/agendaItem/AgendaItemUpdateForm.scss";
import { AgendaItem } from "../../../types";
import useUpdateAgendaItem from "../../../hooks/meetings/useUpdateAgendaItem";
import { useParams } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import InfoText from "../../userSettings/InfoText";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import { isAgendaItemEditing } from "../../../slices/agendaItemEditingProcessSlice";
import { useDispatch } from "react-redux";
import useInitialMeetingItemAttachments from "../../../hooks/meetings/useInitialMeetingItemAttachments";
import MeetingAttachmentList from "../attachments/MeetingAttachmentList";
import FilePreview from "../../common/form/FilePreview";
import FileInput from "../../common/form/FileInput";
import {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
} from "../../../lib/formValidate";
import LoadingSpinner from "../../icons/LoadingSpinner";

export default function AgendaItemUpdateForm({
  initialValues,
}: Props): ReactNode {
  const [readOnly, setReadOnly] = useState(true);
  const updateElement: RefObject<HTMLTextAreaElement> = useRef(null);
  const { agendaItemId = "" } = useParams();

  const { initialAttachmentValues, initialItems } =
    useInitialMeetingItemAttachments(false);

  const { sync } = useSynchronisation();
  const dispatch = useDispatch();
  const customerRoles = useCustomerRoles();
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const componentRef = useRef<HTMLDivElement>(null);
  const [currentElementHeight, setCurrentElementHeight] = useState(0);
  const { update, isLoading } = useUpdateAgendaItem();

  const onSubmit = useCallback(
    async (values: { description: string; attachments?: File[] }) => {
      const result = await update(
        {
          updateValues: values,
          initialValues,
        },
        initialItems,
      );
      if (result?.error) {
        return { [FORM_ERROR]: "Beschluss konnte nicht gespeichert werden!" };
      } else {
        setReadOnly(true);
        sync(SynchronisationMethod.AgendaItemChanged);
      }
    },
    [readOnly, initialAttachmentValues, initialItems],
  );

  const handleEditButton = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setReadOnly(false);
      if (updateElement.current) {
        updateElement.current.focus();
      }
    },
    [readOnly, updateElement],
  );

  const formClassName = classNames({
    "agenda-item-update-form": true,
    "agenda-item-update-form-readonly": readOnly,
  });

  const handleCancelButton = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setReadOnly(true);
    },
    [readOnly],
  );

  const [fieldName] = Object.keys(initialValues);

  useEffect(() => {
    if (componentRef.current) {
      setCurrentElementHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef, readOnly]);

  useEffect(() => {
    if (!readOnly) {
      setReadOnly(true);
    }
  }, [agendaItemId]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, attachments: initialAttachmentValues }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        submitError,
        error,
        values,
        submitSucceeded,
        dirty,
        form,
      }) => {
        useEffect(() => {
          if (dirty) {
            dispatch(isAgendaItemEditing(true));
          } else {
            dispatch(isAgendaItemEditing(false));
          }
          if (dirty && readOnly) {
            form.restart();
          }
        }, [dirty, readOnly]);

        return (
          <Fragment>
            <form
              onSubmit={handleSubmit}
              className={formClassName}
              data-testid="agenda-item-update-form"
            >
              {readOnly && (
                <div className={"agenda-item-form-read-only-wrapper"}>
                  <div
                    className="agenda-item-update-form-field"
                    ref={componentRef}
                  >
                    {values.description}
                  </div>
                  {submitSucceeded && !error && (
                    <InfoText
                      isSuccess={true}
                      textSuccess={"Erfolgreich gespeichert"}
                    />
                  )}
                </div>
              )}
              {!readOnly && (
                <Fragment>
                  <Field
                    component={TextAreaForwardRef}
                    name={fieldName}
                    className="agenda-item-update-form-field"
                    style={{ height: currentElementHeight }}
                    ref={updateElement}
                  />
                  <div className={"agenda-item-update-form-edit-attachments"}>
                    <Field
                      component={FilePreview}
                      name="attachments"
                      subscription={{
                        error: true,
                        value: true,
                        touched: true,
                      }}
                    />
                    <Field
                      component={FileInput}
                      name="attachments"
                      width={32}
                      height={32}
                      validate={validateFiles(
                        validateFileType(),
                        validateFileFirstBytes(),
                      )}
                    />
                  </div>
                </Fragment>
              )}
              {readOnly && isEmployee && (
                <IconButton onClick={handleEditButton} icon={Pencil} />
              )}
              {error && (
                <div className="agenda-item-update-form-error">{error}</div>
              )}
              {!readOnly && (
                <div
                  className="agenda-item-update-form-actions"
                  data-testid="agenda-item-update-form-actions"
                >
                  <Button
                    leadingIcon={isLoading ? LoadingSpinner : Pencil}
                    label="Fertig"
                    lightblue
                    type="submit"
                    disabled={pristine || submitting}
                  />
                  <Button label="Abbrechen" onClick={handleCancelButton} />
                </div>
              )}
              {submitError && !readOnly && (
                <InfoText
                  isError={true}
                  textError="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
                />
              )}
            </form>
            {readOnly && (
              <MeetingAttachmentList
                agendaItemId={agendaItemId}
                attachments={initialAttachmentValues}
              />
            )}
          </Fragment>
        );
      }}
    />
  );
}

type Props = {
  roles?: UserRoles[];
  item?: AgendaItem;
  initialValues: {
    description: string;
  };
};
