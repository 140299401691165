import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import useFetchMessages from "../../../hooks/messages/useFetchMessages";
import useFilterMessages from "../../../hooks/messages/useFilterMessages";
import LightboxProvider from "../../common/lightbox/LightboxProvider";
import MessageList from "../../messages/MessageList";
import MessageListHeader from "../../messages/MessageListHeader";
import MessageDetails from "../../messages/MessageDetails";
import MessageCreate from "../../messages/MessageCreate";
import MessageListSkeleton from "../../skeleton/MessageListSkeleton";
import FetchApiError from "../../common/FetchApiError";

import Lightbox from "../../common/lightbox/Lightbox";

import "../../../styles/components/pages/messages/ListMessagePage.scss";
import { useEffect, useState } from "react";

export default function ListMessagePage(): JSX.Element {
  const { messageId = "" } = useParams();
  const navigate = useNavigate();
  const create = messageId === "neu";
  const [query] = useOutletContext<[string]>();
  const [
    selectedTenanciesAndOwnershipsOptions,
    setSelectedTenanciesAndOwnershipsOptions,
  ] = useState<number | null>(null);

  const { myAddressMessages, objectMessages, isLoading, isError, error } =
    useFetchMessages(selectedTenanciesAndOwnershipsOptions);
  const { messages, setFilter } = useFilterMessages(
    myAddressMessages,
    objectMessages,
  );

  const selectedMessage = [...myAddressMessages, ...objectMessages].find(
    (m) => m.id === messageId,
  );

  const listClassName = classNames("messages-list-page", {
    "messages-list-page-selected": selectedMessage || create,
  });

  useEffect(() => {
    setFilter([query]);
  }, [query]);

  useEffect(() => {
    if (selectedTenanciesAndOwnershipsOptions && selectedMessage) {
      navigate("../");
    }
  }, [selectedTenanciesAndOwnershipsOptions]);

  return (
    <div className={listClassName}>
      {selectedMessage && !create && (
        <Helmet title={`${selectedMessage.description} - Homecase`} />
      )}
      {create && <Helmet title="Neue Nachricht - Homecase" />}
      {!isError && (
        <LightboxProvider options={{ showMeta: true }}>
          <div className="messages-list-page-left">
            <MessageListHeader
              selectedTenanciesAndOwnershipsOptions={
                selectedTenanciesAndOwnershipsOptions
              }
              setSelectedTenanciesAndOwnershipsOption={
                setSelectedTenanciesAndOwnershipsOptions
              }
              isLoading={isLoading}
            />
            {!isLoading && <MessageList messages={messages} />}
            {isLoading && (
              <MessageListSkeleton
                isMessageSelected={Boolean(selectedMessage || create)}
              />
            )}
          </div>
          <div className="messages-list-page-right">
            {!create && (
              <MessageDetails
                messageId={messageId}
                selectedMessage={selectedMessage}
              />
            )}
            {create && <MessageCreate />}
          </div>
          <Lightbox />
        </LightboxProvider>
      )}
      {isError && <FetchApiError error={error} />}
    </div>
  );
}
