import { useGetUserProfileQuery } from "../api/userApi";
import useIdentity from "./useIdentity";
import { UserProfile } from "../types";

export default function useFetchUserProfile(): UserProfile {
  const identity = useIdentity();
  const userSid = identity?.userSid ?? "";

  const defaultData: UserProfile = {
    aboutMe: "",
    dayOfBirth: 0,
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    monthOfBirth: 0,
    onlineIdentityIsBlocked: false,
    isEmployee: false,
    profileVersionStamp: "00000000-0000-0000-0000-000000000000",
    sid: "00000000-0000-0000-0000-000000000000",
    yearOfBirth: 0,
  };
  const { data = defaultData } = useGetUserProfileQuery(
    { userSid },
    { skip: !userSid },
  );
  return data;
}
