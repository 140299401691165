import { User } from "../types";
import { UserEventType } from "../enums";
import BulletinBoardPostCreatedUserEventNotification from "../components/notifications/BulletinBoardPostCreatedUserEventNotification";
import BulletinBoardPostCommentCreatedUserEventNotification from "../components/notifications/BulletinBoardPostCommentCreatedUserEventNotification";
import ActivityUserEventNotification from "../components/notifications/ActivityUserEventNotification";
import ActivityStateChangedUserEventNotification from "../components/notifications/ActivityStateChangedUserEventNotification";
import ContactUserEventNotification from "../components/notifications/ContactUserEventNotification";
import FacilityDocumentRolePermissionsGrantedUserEventNotification from "../components/notifications/FacilityDocumentRolePermissionsGrantedUserEventNotification";
import FacilityDocumentPermissionsGrantedUserEventNotification from "../components/notifications/FacilityDocumentPermissionsGrantedUserEventNotification";
import AuditIsReadyToReviewUserEventNotification from "../components/notifications/AuditIsReadyToReviewUserEventNotification";
import AuditReviewRetryUserEventNotification from "../components/notifications/AuditReviewRetryUserEventNotification";
import MeetingGenericEventNotification from "../components/notifications/MeetingGenericEventNotification";
import ActivityDocumentsSharingByMailEventNotification from "../components/notifications/ActivityDocumentsSharingByMailEventNotification";

export const USER_EVENT_COMPONENTS = {
  [UserEventType.BulletinBoardPostCreatedUserEvent]:
    BulletinBoardPostCreatedUserEventNotification,
  [UserEventType.BulletinBoardPostCommentCreatedUserEvent]:
    BulletinBoardPostCommentCreatedUserEventNotification,
  [UserEventType.ActivityCreatedUserEvent]: ActivityUserEventNotification,
  [UserEventType.ActivitySetOnlineUserEvent]: ActivityUserEventNotification,
  [UserEventType.ActivityStateChangedUserEvent]:
    ActivityStateChangedUserEventNotification,
  [UserEventType.ContactCreatedUserEvent]: ContactUserEventNotification,
  [UserEventType.ContactSetOnlineUserEvent]: ContactUserEventNotification,
  [UserEventType.FacilityDocumentRolePermissionsGrantedUserEvent]:
    FacilityDocumentRolePermissionsGrantedUserEventNotification,
  [UserEventType.FacilityDocumentPersonPermissionsGrantedUserEvent]:
    FacilityDocumentPermissionsGrantedUserEventNotification,
  [UserEventType.FacilityDocumentContractualRelationPermissionsGrantedUserEvent]:
    FacilityDocumentPermissionsGrantedUserEventNotification,
  [UserEventType.AuditIsReadyToReviewUserEvent]:
    AuditIsReadyToReviewUserEventNotification,
  [UserEventType.AuditReviewRetryUserEvent]:
    AuditReviewRetryUserEventNotification,
  [UserEventType.MeetingSetOnlineEvent]: MeetingGenericEventNotification,
  [UserEventType.MeetingStartedEvent]: MeetingGenericEventNotification,
  [UserEventType.MeetingDelegatedVotingSetToParticipantEvent]:
    MeetingGenericEventNotification,
  [UserEventType.ActivityDocumentsSharingByMailDispatchingCompletedUserEvent]:
    ActivityDocumentsSharingByMailEventNotification,
};

export function mapUsersToSids(users: User[]) {
  return Object.fromEntries(users.map((user) => [user.sid, user]));
}

export function getShortenText(text: string, min = 50, max = 65) {
  if (text.length <= max) {
    return text;
  }

  const maxTextLength = Math.min(text.length, max);
  const shortenedText = text.substring(0, maxTextLength);

  const punctuationIndex = getIndexOfLastPunctuationMark(shortenedText);

  const textUpToPunctuationMark =
    punctuationIndex < min
      ? shortenedText
      : shortenedText.substring(0, punctuationIndex + 1);

  return textUpToPunctuationMark + " …";
}

function getIndexOfLastPunctuationMark(text: string) {
  const highestIndex = text.length - 1;

  for (let i = highestIndex; i >= 0; i--) {
    switch (text[i]) {
      case ".":
      case "!":
      case "?":
        if (i == highestIndex || isCharWhiteSpace(text[i + 1])) {
          return i;
        }
        break;
    }
  }

  return -1;
}

function isCharWhiteSpace(char: string) {
  return !/\S/.test(char);
}

export function getUserFullName(user?: User): string {
  if (!user) return "Administrator";

  return [user.firstName, user.lastName]
    .filter((n) => n !== undefined)
    .join(" ");
}

export function getUserLastName(user?: User): string {
  if (!user) return "";
  return [user.lastName].filter((n) => n !== undefined).join(" ");
}

export function getPrettifyGuid(guid: string) {
  return guid.replaceAll("-", "").toLowerCase();
}
