import React, { useCallback, useState } from "react";
import ContactPersonCard from "../common/contactPerson/ContactPersonCard";
import { Contact } from "../../types";
import ContactPersonCardDialog from "../common/contactPerson/ContactPersonCardDialog";
import "../../styles/components/pages/FacilityObjectContactsPage.scss";
import ServicesUser from "../icons/serviceProviders/ServicesUser";
import useFetchContactCardInfo from "../../hooks/useFetchContactCardInfo";
import useScrollToSection from "../../hooks/common/useScrollToSection";
import ProfilePictureManagement from "../common/ProfilePictureManagement";

type Props = {
  contactsListFacilityObjectContacts: Contact[];
};

export default function FacilityObjectContactsPage({
  contactsListFacilityObjectContacts,
}: Props): JSX.Element {
  const [modelContent, setModelContent] = useState<null | Contact>(null);

  const { facilityObjectContactsListIsFetching } = useFetchContactCardInfo();

  const handleCloseContactPersonCardDialog = useCallback(() => {
    setModelContent(null);
  }, []);

  const { ref, rootRef } = useScrollToSection("verwalter");

  return (
    <div className="contacts" data-testid="contacts" ref={rootRef}>
      <h2 ref={ref}>Hausverwaltung</h2>
      {!facilityObjectContactsListIsFetching &&
        contactsListFacilityObjectContacts.length > 0 && (
          <div className="contacts-container">
            {contactsListFacilityObjectContacts.map((item, key) => (
              <ContactPersonCard
                key={key}
                firstName={item.firstName}
                lastName={item.lastName}
                responsibilities={[item.roleName] as string[]}
                email={item.communication?.emails}
                phone={item.communication?.phones}
                onClick={() => {
                  setModelContent(item);
                }}
                employeeId={item.employeeId}
                visitingCardId={item.visitingCardId}
                profilePicture={
                  <ProfilePictureManagement
                    alt={item.lastName}
                    rounded
                    width={120}
                    height={120}
                    employeeId={item.employeeId}
                    visitingCardId={item.visitingCardId}
                  />
                }
              />
            ))}
          </div>
        )}
      {modelContent && (
        <ContactPersonCardDialog
          firstName={modelContent.firstName}
          lastName={modelContent.lastName}
          responsibilities={[modelContent.roleName]}
          email={modelContent.communication?.emails}
          phone={modelContent.communication?.phones}
          firma={modelContent.firma}
          icon={ServicesUser}
          onClose={handleCloseContactPersonCardDialog}
        />
      )}
    </div>
  );
}
