import React, { ReactNode, SyntheticEvent } from "react";
import "../../styles/components/common/Dialog.scss";
import { AnyObject } from "final-form";
import classNames from "classnames";
import IconButton from "../common/IconButton";
import CloseIcon from "../icons/CloseIcon";
import Button from "../common/Button";

export default function ConfirmInstructionsDialog({
  title,
  description,
  onConfirm,
  onCancel,
  cancelLabel = "Abbrechen",
  confirmLabel = "Bestätigen",
  confirmComponent,
  className,
  confirmDisabled,
  showCloseButton = false,
  showCancelButton = true,
  pagination = false,
  pagesCount,
  isPageNumber,
}: Props): JSX.Element {
  return (
    <div className="dialog-background-layer">
      <div
        className={classNames("dialog", className)}
        role="alertdialog"
        aria-modal
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
        data-testid="confirm-dialog"
      >
        {showCloseButton && (
          <div className="close-button-wrapper">
            <IconButton
              onClick={onCancel}
              className="dialog-button-close"
              icon={CloseIcon}
            />
          </div>
        )}
        <h2 className="dialog-title" id="dialog1Title">
          {title}
        </h2>
        <div
          className="dialog-description"
          id="dialog1Desc"
          data-testid="description"
        >
          {description}
        </div>
        <div
          className={classNames("dialog-actions", {
            "cancel-button-hidden": !showCancelButton,
          })}
        >
          {showCancelButton && (
            <Button
              label={cancelLabel}
              onClick={onCancel}
              className="dialog-actions-cancel"
            />
          )}
          {pagination && (
            <span className="pages">{`Seite ${isPageNumber} von ${pagesCount}`}</span>
          )}
          {Array.isArray(confirmLabel) &&
            confirmLabel.map((label, key) => (
              <Button
                lightblue
                label={label}
                onClick={onConfirm}
                className="dialog-actions-confirm"
                aria-controls="form"
                id={`dialog-actions-confirm-${key}`}
                key={key}
                disabled={confirmDisabled}
              />
            ))}
          {!Array.isArray(confirmLabel) &&
            confirmLabel &&
            !confirmComponent && (
              <Button
                lightblue
                label={confirmLabel}
                onClick={onConfirm}
                className="dialog-actions-confirm"
                aria-controls="form"
                disabled={confirmDisabled}
              />
            )}
          {confirmComponent}
        </div>
      </div>
    </div>
  );
}

type Props = {
  description: ReactNode;
  title: ReactNode;
  onConfirm?: (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => void | Promise<void | AnyObject | undefined>;
  onCancel?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  confirmLabel?: string | string[];
  confirmComponent?: ReactNode | ReactNode[];
  cancelLabel?: string;
  className?: string;
  confirmDisabled?: boolean;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  pagination?: boolean;
  pagesCount?: number;
  isPageNumber?: number;
};
