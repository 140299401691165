import React from "react";
import { IconProps } from "../../types";

export default function Attachment({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        fillRule="evenodd"
        d="M22.903 13.447a.8.8 0 0 1 1.131 1.131l-7.775 7.776a5.581 5.581 0 1 1-7.893-7.893l6.377-6.376a3.987 3.987 0 1 1 5.648 5.629l-6.396 6.399a2.394 2.394 0 0 1-3.375-3.394l6.386-6.389a.8.8 0 1 1 1.132 1.131L11.76 17.84a.794.794 0 0 0 .01 1.113.791.791 0 0 0 1.103.019l6.395-6.4a2.388 2.388 0 1 0-3.385-3.365l-6.387 6.386a3.981 3.981 0 0 0 5.63 5.63l7.776-7.776z"
      />
    </svg>
  );
}
