import { useGetUserPicturesForFirstLoginQuery } from "../api/userApi";
import { PicturesForFirstLoginRequest } from "../types";

export default function useGetPicturesFirstLogin() {
  const defaultData: PicturesForFirstLoginRequest = {
    fileNames: [],
  };

  const { data = defaultData } = useGetUserPicturesForFirstLoginQuery();

  return data;
}
