import React, { useCallback, useEffect, useMemo } from "react";
import "../../styles/components/meetings/DialogDescriptionDelegateBeforeLeaving.scss";
import VotingEligibilityDropdown from "./participants/VotingEligibilityDropdown";
import { Participant } from "../../types";
import useGetMyRelatedParticipants from "../../hooks/meetings/useGetMyRelatedParticipants";
import useFetchParticipants from "../../hooks/meetings/useFetchParticipants";
import {
  EMPLOYEE_PARTICIPANT,
  getDelegationParticipant,
} from "../../lib/meeting";
import { ParticipantAttendanceType } from "../../enums";
import LoadingSpinner from "../icons/LoadingSpinner";

export default function DialogDescriptionDelegateBeforeLeaving({
  meetingIdToAttend,
  setDelegateParticipant,
  delegateParticipant,
}: Props): React.ReactElement {
  const onSelect = useCallback(
    (p: Participant | undefined) => {
      setDelegateParticipant(p);
    },
    [setDelegateParticipant],
  );

  const { myRelatedParticipants, isFetching } =
    useGetMyRelatedParticipants(meetingIdToAttend);
  const { participants, isFetching: isFetchingParticipants } =
    useFetchParticipants(meetingIdToAttend);
  const selectableVotingEligibilityParticipants = useMemo(
    () => [
      EMPLOYEE_PARTICIPANT,
      ...participants.filter(
        (p) =>
          p.attendance === ParticipantAttendanceType.Online ||
          p.attendance === ParticipantAttendanceType.Locally,
      ),
    ],
    [participants],
  );

  const initialDelegateParticipant = useMemo(() => {
    const myRelatedParticipant =
      myRelatedParticipants[myRelatedParticipants.length - 1];
    if (!myRelatedParticipant) return undefined;
    const { delegationType, delegate } = myRelatedParticipant;
    return getDelegationParticipant(
      selectableVotingEligibilityParticipants,
      delegationType,
      delegate,
    );
  }, [myRelatedParticipants, selectableVotingEligibilityParticipants]);

  useEffect(() => {
    setDelegateParticipant(initialDelegateParticipant);
  }, [initialDelegateParticipant, setDelegateParticipant]);

  return (
    <div className="dialog-description-delegate-before-leaving">
      <div className="description-text">
        Möchten Sie eine Vollmacht für die laufenden Eigentümerversammlung
        erteilen? Um die Versammlung zu verlassen ohne eine Vollmacht zu
        erteilen, lassen Sie das untenstehende Feld leer.
      </div>
      {(isFetching || isFetchingParticipants) && <LoadingSpinner />}
      {!isFetching && !isFetchingParticipants && (
        <VotingEligibilityDropdown
          selectableVotingEligibilityParticipants={
            selectableVotingEligibilityParticipants
          }
          delegateParticipant={delegateParticipant}
          participant={myRelatedParticipants[myRelatedParticipants.length - 1]}
          closestSelector=".dialog"
          onSelect={onSelect}
          readOnly={false}
        />
      )}
    </div>
  );
}

type Props = {
  meetingIdToAttend: string;
  setDelegateParticipant: (p: Participant | undefined) => void;
  delegateParticipant: Participant | undefined;
};
