import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import Comment from "../icons/Comment";
import PostCreateComment from "./PostCreateComment";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { useGetBulletinBoardPostCommentsQuery } from "../../api/bulletinBoardApi";
import PostCommentsItem from "./PostCommentsItem";

import "../../styles/components/bulletinBoard/PostComments.scss";

export default function PostComments({
  postId,
  commentsCount,
  canComment,
  activeUserSid,
}: Props): JSX.Element {
  const [showList, setShowList] = useState(false);
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [inView, setInView] = useState(false);
  const [ref, { entry }] = useIntersectionObserver({ threshold: [0.5] });

  useEffect(() => {
    entry && entry.isIntersecting && setInView(true);
  }, [entry]);

  const { data } = useGetBulletinBoardPostCommentsQuery(
    {
      bulletinBoardPostId: postId,
      facilityObjectId,
      customerToken,
    },
    { skip: !inView },
  );

  const toggleList = useCallback(() => {
    setShowList(!showList);
  }, [showList]);

  useEffect(() => {
    if (data && data.length) {
      setShowList(true);
    }
  }, [data, activeUserSid]);

  const count = data ? data.length : commentsCount;
  return (
    <div className="bulletin-board-post-list-item-comments" ref={ref}>
      {canComment && (
        <div
          role="button"
          data-testid="button-toggle"
          className={classNames(
            "bulletin-board-post-list-item-comments-count",
            {
              interactive: true,
            },
          )}
          onClick={toggleList}
        >
          <Comment className="icon" />
          <span>
            {count > 0
              ? `${count} ${count === 1 ? "Kommentar" : "Kommentare"}`
              : "kommentieren…"}
          </span>
        </div>
      )}
      {data && (
        <div
          role="list"
          className={classNames("bulletin-board-post-list-item-comments-list", {
            open: showList,
          })}
        >
          {data.map((comment) => (
            <PostCommentsItem
              comment={comment}
              key={comment.id}
              facilityObjectId={facilityObjectId}
              customerToken={customerToken}
            />
          ))}
        </div>
      )}
      {canComment && showList && (
        <PostCreateComment
          postId={postId}
          facilityObjectId={facilityObjectId}
          customerToken={customerToken}
        />
      )}
    </div>
  );
}

type Props = {
  postId: number;
  commentsCount: number;
  canComment: boolean;
  activeUserSid: string | number;
};
