import React from "react";
import { IconProps } from "../../../types";

export default function ServicesInternet({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M47.3 44.71C32.174 44.877 20 57.186 20 72.312c0 15.127 12.174 27.436 27.3 27.603 15.125-.167 27.3-12.476 27.3-27.603 0-15.126-12.175-27.435-27.3-27.602zm.625 47.67a.837.837 0 0 1-1.24 0 24.373 24.373 0 0 1-4.433-7.153.84.84 0 0 1 .777-1.164h8.548a.84.84 0 0 1 .777 1.164 24.346 24.346 0 0 1-4.429 7.152zm-7.001-14.201a.837.837 0 0 1-.83-.71 32.073 32.073 0 0 1 0-10.31.837.837 0 0 1 .83-.71h12.758c.414 0 .766.301.83.71.27 1.705.41 3.429.418 5.155a33.805 33.805 0 0 1-.417 5.155.837.837 0 0 1-.831.71H40.924zm-15.34-5.865a21.46 21.46 0 0 1 .672-5.229.84.84 0 0 1 .817-.635h6.251a.844.844 0 0 1 .834.955 36.956 36.956 0 0 0-.366 4.91c.013 1.643.135 3.284.366 4.912a.837.837 0 0 1-.834.952H27.06a.84.84 0 0 1-.818-.636 21.44 21.44 0 0 1-.659-5.229zm21.1-20.068a.844.844 0 0 1 1.24 0 24.312 24.312 0 0 1 4.43 7.159.84.84 0 0 1-.777 1.16h-8.548a.84.84 0 0 1-.777-1.16 24.363 24.363 0 0 1 4.432-7.16zm13.764 15.159a.837.837 0 0 1 .834-.955h6.264a.84.84 0 0 1 .818.635 21.185 21.185 0 0 1 0 10.458.837.837 0 0 1-.818.636h-6.264a.837.837 0 0 1-.834-.952c.23-1.628.353-3.269.366-4.913a36.956 36.956 0 0 0-.366-4.91zm4.146-8.189a.84.84 0 0 1-.673 1.345h-4.348a.844.844 0 0 1-.8-.581 36.909 36.909 0 0 0-2.384-5.67.84.84 0 0 1 1.14-1.13 21.814 21.814 0 0 1 7.065 6.036zm-27.52-6.032a.84.84 0 0 1 1.143 1.13 36.495 36.495 0 0 0-2.387 5.672.84.84 0 0 1-.797.579H30.68a.84.84 0 0 1-.669-1.349 21.8 21.8 0 0 1 7.062-6.032zm-7.062 32.231a.84.84 0 0 1 .673-1.345h4.348c.364 0 .687.235.8.582a36.404 36.404 0 0 0 2.384 5.666.84.84 0 0 1-1.143 1.13 21.891 21.891 0 0 1-7.062-6.033zm27.517 6.03a.847.847 0 0 1-.989-.145.837.837 0 0 1-.151-.989 36.734 36.734 0 0 0 2.384-5.666.84.84 0 0 1 .8-.582h4.352a.84.84 0 0 1 .672 1.345 21.834 21.834 0 0 1-7.068 6.036zM65.24 34.447a3.299 3.299 0 0 0-3.407 3.319 3.363 3.363 0 0 0 3.316 3.406c7.485.154 13.516 6.185 13.67 13.67a3.363 3.363 0 0 0 3.362 3.315h.044a3.363 3.363 0 0 0 3.319-3.406c-.229-11.118-9.187-20.076-20.304-20.304z" />
        <path d="M64.96 20.004a3.245 3.245 0 0 0-3.406 3.319 3.363 3.363 0 0 0 3.333 3.406c15.544.322 28.068 12.847 28.387 28.392a3.363 3.363 0 0 0 3.363 3.315h.047A3.363 3.363 0 0 0 100 55.03c-.398-19.182-15.857-34.635-35.04-35.026z" />
      </g>
    </svg>
  );
}
