import React, { SyntheticEvent, useCallback } from "react";
import "../../../styles/components/management/objectSettings/ObjectReports.scss";
import { useParams } from "react-router-dom";
import Checkbox from "../../common/form/Checkbox";
import { FacilityObjectReport } from "../../../types";
import {
  useDisableReportMutation,
  useEnableReportMutation,
} from "../../../api/facilityManagementApi";

export default function ObjectReportItem({
  report,
  isCanModifyFacilityObjectMasterData,
}: Props): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const [enableReportMutation] = useEnableReportMutation();
  const [disableReportMutation] = useDisableReportMutation();

  const handleOnChange = useCallback(
    async (e: SyntheticEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      if (checked) {
        await enableReportMutation({
          customerToken,
          facilityObjectId,
          reportId: report.id,
        });
      } else {
        await disableReportMutation({
          customerToken,
          facilityObjectId,
          reportId: report.id,
        });
      }
    },
    [report],
  );
  return (
    <li className="reports-descriptions-list-item" key={report.id}>
      <Checkbox
        label={report.description}
        onChange={handleOnChange}
        value={report.description}
        defaultChecked={report.isActive}
        disabled={!isCanModifyFacilityObjectMasterData}
      />
    </li>
  );
}

type Props = {
  report: FacilityObjectReport;
  isCanModifyFacilityObjectMasterData: boolean;
};
