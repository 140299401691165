import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import NoMeetings from "../icons/NoMeeting";

import "../../styles/components/pages/NoFoundPage.scss";
import ArrowRight from "../icons/ArrowRight";
import Button from "../common/Button";

export default function NotFoundPage(): JSX.Element {
  const location = useLocation();

  return (
    <div className="not-found" data-testid="not-found">
      <Helmet>
        <title>Seite nicht gefunden - Homecase</title>
      </Helmet>
      <PageHeader title="Seite nicht gefunden" />
      <div className="not-found-container">
        <NoMeetings />
        <div className="not-found-content">
          <h2>Seite nicht gefunden</h2>
          <p className="not-found-content-block">
            {`Die gewünschte Seite ${location.pathname} konnte nicht gefunden werden.`}
          </p>
          <p className="not-found-content-block">
            Wenn Sie die URL manuell eingeben, können schnell Fehler passieren.
            Auch wenn Sie die Seite über einen geteilten Link aufrufen, kann
            sich ein Tippfehler in der URL befinden. Deshalb sollten Sie den
            Pfad der Webseite genau überprüfen und ändern, wenn Ihnen etwas
            auffällt.
          </p>
          <Button
            leadingIcon={ArrowRight}
            label="Zur Startseite"
            lightblue
            onClick={() => (window.location.href = "/")}
          />
        </div>
      </div>
    </div>
  );
}
