import { FacilityObject } from "../../types";
import { SyntheticEvent, useCallback, useState } from "react";

export default function useSearchByObjects(
  objects: FacilityObject[],
): SearchByContactCardInfo {
  const [searchString, setSearchString] = useState("");

  const handleChangeSearchString = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setSearchString(value);
    },
    [objects, setSearchString],
  );

  const objectsList = objects.filter((element) => {
    const searchParts = [
      element.street,
      element.city,
      element.zipCode,
      element.description,
      element.number,
    ]
      .flat()
      .filter((v) => Boolean(v));
    const search = searchParts.join(" ");

    const reg = new RegExp(
      `(?=.*${searchString.split(" ").join(")(?=.*")})`,
      "i",
    );

    return search.match(reg);
  });

  return {
    handleChangeSearchString,
    objectsList,
  };
}

type SearchByContactCardInfo = {
  handleChangeSearchString: (e: SyntheticEvent<HTMLInputElement>) => void;
  objectsList: FacilityObject[];
};
