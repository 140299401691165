import React from "react";

import "../../styles/components/meetings/ParticipantsList.scss";
import Skeleton from "react-loading-skeleton";

export default function ParticipantsListSkeleton(): JSX.Element {
  const skeletonItems = Array(5).fill(null);
  return (
    <section role="list" className="etv-participants-list">
      <div className="table-sort-header">
        <div
          className="table-sort-header-column table-sort-header-column-name table-sort-header-column-sortable"
          aria-sort="none"
        >
          Eigentümer*in
        </div>
        <div
          className="table-sort-header-column table-sort-header-column-unit table-sort-header-column-sortable"
          aria-sort="none"
        >
          Einheit
        </div>
        <div
          className="table-sort-header-column table-sort-header-column-attendance table-sort-header-column-sortable"
          aria-sort="none"
        >
          Status
        </div>
        <div
          className="table-sort-header-column table-sort-header-column-actions"
          aria-sort="none"
        >
          Anwesenheit
        </div>
        <div
          className="table-sort-header-column table-sort-header-column-delegate table-sort-header-column-sortable"
          aria-sort="none"
        >
          Hat Vollmacht gegeben an
        </div>
      </div>
      {skeletonItems.map((val, key) => (
        <div role="listitem" className="etv-participants-list-item" key={key}>
          <div className="etv-participants-list-item-name">
            <Skeleton height={32} />
          </div>
          <div className="etv-participants-list-item-attendance">
            <Skeleton />
          </div>
          <div className="etv-participants-list-item-actions">
            <Skeleton />
          </div>
        </div>
      ))}
    </section>
  );
}
