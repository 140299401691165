import React from "react";
import { IconProps } from "../../../types";

export default function ServicesDemageInsurance({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M51.657 62.84a.833.833 0 0 0-.833-.833h-6.818a.833.833 0 0 1-.817-.667l-1.264-6.314a.833.833 0 0 0-1.333-.484L20.588 70.74a1.667 1.667 0 0 0 1.063 2.937h4.168c.46 0 .833.373.833.833v15.837c0 .92.747 1.667 1.667 1.667h8.335c.92 0 1.667-.747 1.667-1.667v-7.502a5.001 5.001 0 0 1 10.002 0v7.502c0 .92.746 1.667 1.667 1.667h8.335c.92 0 1.667-.747 1.667-1.667V74.239a.833.833 0 0 0-.523-.773l-7.288-2.914a.833.833 0 0 1-.524-.774V62.84zM58.325 33.668h38.341a3.334 3.334 0 1 0 0-6.668h-38.34a3.334 3.334 0 0 0 0 6.668zM49.99 45.337h36.674a3.334 3.334 0 1 0 0-6.668H49.99a3.334 3.334 0 0 0 0 6.668zM96.666 53.672a3.334 3.334 0 0 0-3.334-3.334H63.326a3.334 3.334 0 0 0 0 6.668h30.006a3.334 3.334 0 0 0 3.334-3.334zM96.666 62.007h-21.67a3.334 3.334 0 1 0 0 6.668h21.67a3.334 3.334 0 0 0 0-6.668zM89.998 77.01a3.334 3.334 0 0 0-3.334-3.334H76.662a3.334 3.334 0 0 0 0 6.668h10.002a3.334 3.334 0 0 0 3.334-3.334zM89.998 85.345H83.33a3.334 3.334 0 1 0 0 6.668h6.668a3.334 3.334 0 1 0 0-6.668z" />
      </g>
    </svg>
  );
}
