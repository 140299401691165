import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import {
  FacilityObject,
  FacilityUnitWithActiveTenancyOrOwnership,
  TenancyOrOwnership,
} from "../../types";
import {
  useGetFacilityObjectQuery,
  useGetFacilityUnitsWithTenanciesOrOwnershipsQuery,
} from "../../api/facilityManagementApi";

export default function useFetchFacilityObjectWithFacilityUnits(): UseFetchFacilityObjectWithFacilityUnits {
  const { customerToken = "", facilityObjectId = "" } = useParams();

  const {
    data: facilityObject = {} as unknown as FacilityObject,
    isFetching: isFacilityObjectFetching,
    isLoading: isFacilityObjectLoading,
    isError: isFacilityObjectError,
    error: facilityObjectError,
  } = useGetFacilityObjectQuery(
    {
      customerToken,
      facilityObjectId,
    },
    { skip: !facilityObjectId },
  );

  const {
    data: facilityUnitsWithTenanciesOrOwnershipsData = [],
    isFetching: isFacilityUnitsFetching,
    isLoading: isFacilityUnitsLoading,
    isError: isFacilityUnitsError,
    error: facilityUnitsError,
  } = useGetFacilityUnitsWithTenanciesOrOwnershipsQuery(
    {
      customerToken,
      facilityObjectId,
    },
    {
      skip: !facilityObjectId,
    },
  );

  const facilityUnits = facilityUnitsWithTenanciesOrOwnershipsData
    .map(({ facilityUnitId, description, tenancies, ownerships }) => {
      const tenancyOrOwnership =
        tenancies && tenancies.length > 0
          ? {
              tenancyOrOwnershipId: tenancies[0].tenancyId,
              number: tenancies[0].number,
              facilityObjectId: tenancies[0].facilityObjectId,
              validFrom: tenancies[0].validFrom,
              validTo: tenancies[0].validTo,
              facilityUnit: tenancies[0].facilityUnit,
              person: tenancies[0].person,
            }
          : ownerships && ownerships.length > 0
            ? {
                tenancyOrOwnershipId: ownerships[0].ownershipId,
                number: ownerships[0].number,
                facilityObjectId: ownerships[0].facilityObjectId,
                validFrom: ownerships[0].validFrom,
                validTo: ownerships[0].validTo,
                facilityUnit: ownerships[0].facilityUnit,
                person: ownerships[0].person,
              }
            : null;

      return {
        facilityUnitId,
        description,
        activeTenancyOrOwnership: tenancyOrOwnership as TenancyOrOwnership,
      };
    })
    .sort((a, b) => {
      const compA = (a.description ?? "").toLowerCase();
      const compB = (b.description ?? "").toLowerCase();

      return compA.localeCompare(compB);
    });

  return {
    facilityObject,
    facilityUnits,
    isFacilityManagementDataFetching:
      isFacilityObjectFetching || isFacilityUnitsFetching,
    isFacilityManagementDataLoading:
      isFacilityObjectLoading || isFacilityUnitsLoading,
    isFacilityManagementDataError:
      isFacilityObjectError || isFacilityUnitsError,
    facilityManagementDataError: facilityObjectError || facilityUnitsError,
  };
}

type UseFetchFacilityObjectWithFacilityUnits = {
  facilityObject: FacilityObject;
  facilityUnits: FacilityUnitWithActiveTenancyOrOwnership[];
  isFacilityManagementDataFetching: boolean;
  isFacilityManagementDataLoading: boolean;
  isFacilityManagementDataError: boolean;
  facilityManagementDataError:
    | SerializedError
    | FetchBaseQueryError
    | undefined;
};
