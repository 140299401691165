import { Field, Form } from "react-final-form";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Draft, { convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import useFetchTermsOfUseContent from "../../../hooks/termsOfUse/useFetchTermsOfUseContent";
import useFetchTermsOfUse from "../../../hooks/termsOfUse/useFetchTermsOfUse";
import useSaveTermsOfUse from "../../../hooks/termsOfUse/useSaveTermsOfUse";
import Button from "../../common/Button";
import AlertDialog from "../../common/dialog/AlertDialog";
import useTermsOfUsePatch from "../../../hooks/termsOfUse/useTermsOfUsePatch";
import {
  DcsEmployeePermissionType,
  TermsOfServicesState,
} from "../../../enums";
import useHasPermission from "../../../hooks/useHasPermission";
import TextEditor from "../../common/TextEditor";

const ContentState = Draft.ContentState;
export default function TermsOfUseEditPage(): JSX.Element {
  const [alertDialogHidden, setAlertDialogHidden] = useState(true);
  const navigate = useNavigate();
  const [errorAlertDialogHidden, setErrorAlertDialogHidden] = useState(true);
  const [alertDialogOnlineHidden, setAlertDialogOnlineHidden] = useState(true);
  const [errorAlertDialogOnlineHidden, setErrorAlertDialogOnlineHidden] =
    useState(true);
  const { latestId, sortedTermsOfUse } = useFetchTermsOfUse();
  const { termsOfUseId = "" } = useParams();
  const { termsOfUseContent } = useFetchTermsOfUseContent(latestId);
  const { state } =
    sortedTermsOfUse.find(({ id }) => id === Number(termsOfUseId)) || {};

  const { save, error, isError, isSuccess } = useSaveTermsOfUse(
    latestId,
    state,
  );
  const hasPermission = useHasPermission(
    DcsEmployeePermissionType.CanManageTermsOfServices,
  );
  const {
    termsOfUsePatch,
    isError: isErrorPatch,
    isSuccess: isSuccessPatch,
  } = useTermsOfUsePatch(latestId);

  const editorContent =
    termsOfUseContent?.id === Number(termsOfUseId)
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(termsOfUseContent?.content || "").contentBlocks,
          ),
        )
      : EditorState.createEmpty();

  const onSubmit = useCallback(
    async ({ editor }: { editor: EditorState }) => {
      const htmlContent = draftToHtml(convertToRaw(editor.getCurrentContent()));
      const result = await save(htmlContent);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return result?.error;
    },
    [termsOfUseId, latestId],
  );

  useEffect(() => {
    if (isSuccess) {
      setAlertDialogHidden(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error || isError) {
      setErrorAlertDialogHidden(false);
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccessPatch) {
      setAlertDialogOnlineHidden(false);
    }
  }, [isSuccessPatch]);

  useEffect(() => {
    if (isErrorPatch) {
      setErrorAlertDialogOnlineHidden(false);
    }
  }, [isErrorPatch]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        values,
        pristine,
        submitting,
        submitSucceeded,
        form,
      }) => {
        const editorFieldContent = form
          .getFieldState("editor")
          ?.value?.getCurrentContent();
        const isModified =
          editorFieldContent !== undefined &&
          !editorFieldContent.equals(editorContent.getCurrentContent());
        const handleClickButtonOnline = useCallback(async () => {
          const htmlContent = draftToHtml(
            convertToRaw(values.editor.getCurrentContent()),
          );

          return await termsOfUsePatch(htmlContent);
        }, [termsOfUseId, latestId, values]);

        useEffect(() => {
          form.restart();
          if (
            submitSucceeded &&
            state &&
            state !== TermsOfServicesState.Draft
          ) {
            navigate(`../${latestId + 1}`);
          }
        }, [termsOfUseId, submitSucceeded]);

        return (
          <form onSubmit={handleSubmit}>
            <div className="terms-of-use-edit-control">
              <h2>
                {termsOfUseContent &&
                termsOfUseContent.id === Number(termsOfUseId)
                  ? `Version ${termsOfUseContent.version}`
                  : "Neue Version"}
              </h2>
              {hasPermission && (
                <div className="terms-of-use-edit-control-buttons">
                  <Button
                    label={
                      state === TermsOfServicesState.Draft
                        ? "Speichern"
                        : "Neue Version speichern"
                    }
                    type="submit"
                    green
                    disabled={
                      !isModified || pristine || submitting || !hasPermission
                    }
                  />
                  {state === TermsOfServicesState.Draft && (
                    <Button
                      lightblue
                      label="Online stellen"
                      green
                      disabled={!hasPermission}
                      type="button"
                      onClick={handleClickButtonOnline}
                    />
                  )}
                </div>
              )}
            </div>
            <Field
              name="editor"
              component={TextEditor}
              initialValue={editorContent}
            />
            {isSuccess && !alertDialogHidden && (
              <AlertDialog
                title={"Erfolgreich gespeichert"}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setAlertDialogHidden(true)}
              />
            )}
            {isError && !errorAlertDialogHidden && (
              <AlertDialog
                title={"Version konnte nicht gespeichert werden"}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setErrorAlertDialogHidden(true)}
              />
            )}
            {isSuccessPatch && !alertDialogOnlineHidden && (
              <AlertDialog
                title={"Version ist online"}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setAlertDialogOnlineHidden(true)}
              />
            )}
            {isErrorPatch && !errorAlertDialogOnlineHidden && (
              <AlertDialog
                title={"Version konnte nicht online gestellt werden"}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setErrorAlertDialogOnlineHidden(true)}
              />
            )}
          </form>
        );
      }}
    />
  );
}
