import { useParams } from "react-router-dom";
import { useGetBulletinBoardQuery } from "../../api/bulletinBoardApi";

export default function useBulletinBoard() {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const { data, isFetching, isError, error } = useGetBulletinBoardQuery(
    {
      customerToken,
      facilityObjectId,
    },
    { skip: !facilityObjectId },
  );

  return { data, isFetching, isError, error };
}
