import React, { useEffect } from "react";
import { Participant } from "../../../types";
import { getDelegationParticipant } from "../../../lib/meeting";
import VotingEligibilityDropdown from "./VotingEligibilityDropdown";

import ErrorDialog from "../../common/dialog/ErrorDialog";
import useSetVotingEligibility from "../../../hooks/meetings/useSetVotingEligibility";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import { SynchronisationMethod } from "../../../enums";

import "../../../styles/components/meetings/participants/ParticipantDelegation.scss";
import LoadingSpinner from "../../icons/LoadingSpinner";

export default function ParticipantDelegation({
  selectableVotingEligibilityParticipants,
  participant,
  readOnly,
}: Props): JSX.Element {
  const delegateParticipant = getDelegationParticipant(
    selectableVotingEligibilityParticipants,
    participant.delegationType,
    participant.delegate,
  );
  const {
    handleDelegationSelect,
    retry,
    error,
    retainIsSuccess,
    setIsSuccess,
    isLoading,
  } = useSetVotingEligibility(participant);

  const { sync } = useSynchronisation();

  useEffect(() => {
    if (retainIsSuccess || setIsSuccess) {
      sync(SynchronisationMethod.ParticipantsChanged);
    }
  }, [retainIsSuccess, setIsSuccess]);

  return (
    <div className="participant-delegation">
      {isLoading && (
        <LoadingSpinner
          height={32}
          width={32}
          className="participant-delegation-change"
        />
      )}
      <VotingEligibilityDropdown
        selectableVotingEligibilityParticipants={
          selectableVotingEligibilityParticipants
        }
        participant={participant}
        delegateParticipant={delegateParticipant}
        closestSelector=".etv-participants-list"
        onSelect={handleDelegationSelect}
        readOnly={readOnly}
      />
      <ErrorDialog
        serverErrorDescription="Es ist ein Fehler beim Speichern aufgetreten. Versuchen Sie es bitte erneut."
        description="Die Vollmacht konnte dem ausgewählten Teilnehmer nicht erteilt werden."
        title="Ein Fehler ist aufgetreten!"
        onRetry={retry}
        error={error}
      />
    </div>
  );
}

type Props = {
  participant: Participant;
  selectableVotingEligibilityParticipants: Participant[];
  readOnly: boolean;
};
