import { Employee } from "../../types";
import { useMemo } from "react";

export default function useGetSortEmployees(
  employees: Employee[] | undefined,
): Employee[] | undefined {
  return useMemo(() => {
    if (!employees) {
      return undefined;
    }

    const clonedEmployees = [...employees];

    clonedEmployees.sort((a, b) => {
      const x: string = a.name;
      const y: string = b.name;

      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    });

    return clonedEmployees;
  }, [employees]);
}
