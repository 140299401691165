import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  ReactElement,
} from "react";
import { useParams } from "react-router-dom";
import PostListItem from "./PostListItem";
import FetchApiError from "../common/FetchApiError";
import PostListItemSkeleton from "../skeleton/PostListItemSkeleton";
import useFetchUserProfile from "../../hooks/useFetchUserProfile";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import useBulletinBoardPosts from "../../hooks/bulletinBoard/useBulletinBoardPosts";
import PostListHeader from "./PostListHeader";

import "../../styles/components/bulletinBoard/PostList.scss";
import useBulletinBoardNotificationPostIds from "../../hooks/bulletinBoard/useBulletinBoardNotificationPostIds";
import useTracking from "../../hooks/googleAnalytics/useTracking";

export default function PostList(): ReactElement {
  const user = useFetchUserProfile();
  const { bulletinBoardPostId = "" } = useParams();
  const postIds = useBulletinBoardNotificationPostIds();
  const [page, setPage] = useState(1);
  const { trackNonInteractionEvent } = useTracking();

  const {
    data = { hasMorePages: true, pageNumber: 1, result: [] },
    isFetching,
    isError,
    isSuccess,
    error,
  } = useBulletinBoardPosts(page);

  useEffect(() => {
    if (isSuccess) {
      trackNonInteractionEvent({
        action: "bulletinBoardPosts",
        category: "bulletinBoard",
        value: data.result.length,
      });
    }
  }, [data, isSuccess]);
  const ref = useRef<HTMLDivElement | null>(null);
  const [intersectionCallback, { entry }] = useIntersectionObserver({
    threshold: [1],
  });

  useEffect(() => {
    if (
      entry &&
      entry.isIntersecting &&
      data.hasMorePages &&
      !isFetching &&
      !isError
    ) {
      setPage(page + 1);
    }
  }, [data.hasMorePages, isFetching, isError, entry]);

  useEffect(() => {
    if (ref) {
      intersectionCallback(ref.current);
    }
  }, [ref]);

  return (
    <div
      className="bulletin-board-post-list"
      data-testid="bulletin-board-post-list"
    >
      <PostListHeader />
      <div className="bulletin-board-post-list-items">
        {isFetching && page === 0 ? (
          <Fragment>
            <PostListItemSkeleton />
            <PostListItemSkeleton />
            <PostListItemSkeleton />
            <PostListItemSkeleton />
          </Fragment>
        ) : (
          data.result.map((post) => (
            <PostListItem
              post={post}
              key={post.id}
              activeUserSid={user.sid}
              page={data.pageNumber}
              inNotifications={
                postIds.includes(post.id) ||
                bulletinBoardPostId === String(post.id)
              }
            />
          ))
        )}
        {isFetching && page > 0 && (
          <Fragment>
            <PostListItemSkeleton />
            <PostListItemSkeleton />
            <PostListItemSkeleton />
            <PostListItemSkeleton />
          </Fragment>
        )}
        <div ref={ref} />
        {isError && <FetchApiError error={error} />}
      </div>
    </div>
  );
}
