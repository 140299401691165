import UpdateDialog from "../common/dialog/UpdateDialog";
import { Document } from "../../types";
import React from "react";

export default function DocumentOpenDialog({ document }: Props) {
  return (
    <UpdateDialog
      description={
        <span>
          "Das Dokument" <strong>{document.originalFileName}</strong> wird
          geöffnet bitte warten.
        </span>
      }
      title="Dokument wird geöffnet"
    />
  );
}

type Props = {
  document: Document;
};
