import { useCallback, useState, useMemo } from "react";

export default function usePagination<T>(items: T[], { pageSize = 10 }) {
  const [page, setPage] = useState(1);

  const pageItems = useMemo(() => {
    const start = page === 1 ? 0 : (page - 1) * pageSize;
    const end = start + pageSize;
    return items.slice(start, end);
  }, [page, pageSize, items]);

  const next = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const reset = useCallback(() => {
    setPage(1);
  }, [page]);

  const prev = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  const hasNextPage = page * pageSize < items.length;
  const hasPrevPage = page !== 1;

  return { pageItems, next, prev, hasNextPage, hasPrevPage, reset };
}
