import classNames from "classnames";
import { ActivityState } from "../../enums";

import "../../styles/components/messages/MessageState.scss";

const STATE_TITLES = {
  [ActivityState.InProgress]: "In Bearbeitung",
  [ActivityState.Closed]: "Geschlossen",
  [ActivityState.Open]: "Offen",
  [ActivityState.NotDefined]: "Unbekannt",
};

const STATE_CLASSES = {
  [ActivityState.InProgress]: "message-state-in-progress",
  [ActivityState.Closed]: "message-state-closed",
  [ActivityState.Open]: "message-state-open",
  [ActivityState.NotDefined]: "message-state-not-defined",
};
export default function MessageState({ stateType }: Props): JSX.Element {
  return (
    <span className={classNames("message-state", STATE_CLASSES[stateType])}>
      {STATE_TITLES[stateType]}
    </span>
  );
}

type Props = {
  stateType: ActivityState;
};
