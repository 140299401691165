import { configureStore } from "@reduxjs/toolkit";
import { activitiesApi } from "./api/activitiesApi";
import { meetingApi } from "./api/meetingApi";
import { propositionApi } from "./api/propositionApi";
import { participantsApi } from "./api/participantsApi";
import { agendaItemApi } from "./api/agendaItemApi";
import { customerApi } from "./api/customerApi";
import { userApi } from "./api/userApi";
import { termsOfUseApi } from "./api/termsOfUseApi";
import { facilityManagementApi } from "./api/facilityManagementApi";
import { homecaseMvcApi } from "./api/homecaseMvcApi";
import { documentApi } from "./api/documentsApi";
import fileUploadReducer from "./slices/fileUploadSlice";
import subItemsReducer from "./slices/navigationItemsSlice";
import propositionInstructionsVotingReducer from "./slices/propositionInstructionsVotingSlice";
import {
  AGENDA_ITEM_EDITING_REDUCER_PATH,
  FILE_UPLOAD_REDUCER_PATH,
  PROPOSITION_INSTRUCTIONS_VOTING_PATH,
  SUB_ITEMS_REDUCER_PATH,
} from "./reducerPaths";
import fileUploadListener from "./middleware/fileUploadListener";
import { bulletinBoardApi } from "./api/bulletinBoardApi";
import agendaItemEditingProcessSlice from "./slices/agendaItemEditingProcessSlice";

export const store = configureStore({
  reducer: {
    [activitiesApi.reducerPath]: activitiesApi.reducer,
    [bulletinBoardApi.reducerPath]: bulletinBoardApi.reducer,
    [meetingApi.reducerPath]: meetingApi.reducer,
    [propositionApi.reducerPath]: propositionApi.reducer,
    [agendaItemApi.reducerPath]: agendaItemApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [facilityManagementApi.reducerPath]: facilityManagementApi.reducer,
    [termsOfUseApi.reducerPath]: termsOfUseApi.reducer,
    [homecaseMvcApi.reducerPath]: homecaseMvcApi.reducer,
    [participantsApi.reducerPath]: participantsApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [FILE_UPLOAD_REDUCER_PATH]: fileUploadReducer,
    [SUB_ITEMS_REDUCER_PATH]: subItemsReducer,
    [PROPOSITION_INSTRUCTIONS_VOTING_PATH]:
      propositionInstructionsVotingReducer,
    [AGENDA_ITEM_EDITING_REDUCER_PATH]: agendaItemEditingProcessSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      activitiesApi.middleware,
      bulletinBoardApi.middleware,
      meetingApi.middleware,
      propositionApi.middleware,
      agendaItemApi.middleware,
      customerApi.middleware,
      userApi.middleware,
      facilityManagementApi.middleware,
      termsOfUseApi.middleware,
      homecaseMvcApi.middleware,
      participantsApi.middleware,
      documentApi.middleware,
      fileUploadListener.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
