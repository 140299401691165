import { MeetingDataIntegrityFailureReason } from "../../types";
import "../../styles/components/meetings/MeetingValidationError.scss";
import { useParams } from "react-router-dom";
import { getFailureReasonText } from "../../lib/meeting";
import { useGetAgendaItemsQuery } from "../../api/agendaItemApi";
import { useEffect } from "react";

export default function MeetingValidationError({
  failureReasons,
  id,
}: Props): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const { data = [], refetch } = useGetAgendaItemsQuery(
    {
      customerToken,
      meetingId: id ? id : meetingId,
      facilityObjectId,
    },
    {
      skip: !customerToken || !facilityObjectId || !(id || meetingId),
    },
  );

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  return (
    <div className="meeting-validation-error">
      <span>
        Die Versammlung kann für Eigentümer nicht angezeigt bzw. gestartet
        werden, da es folgende Daten fehlen:
      </span>
      <ul className="meeting-validation-error-list">
        {failureReasons.map((reason, key) => (
          <li className="meeting-validation-error-item" key={key}>
            {getFailureReasonText(reason, data)}
          </li>
        ))}
      </ul>
    </div>
  );
}

type Props = {
  failureReasons: MeetingDataIntegrityFailureReason[];
  id?: string;
};
