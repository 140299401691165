import React, { Fragment } from "react";
import { Proposition } from "../../../types";
import {
  AgendaItemType,
  MeetingState,
  PropositionVotingState,
  PropositionVotingType,
  UserRoles,
} from "../../../enums";
import PropositionVoting from "../proposition/PropositionVoting";
import PropositionVotingControl from "../proposition/PropositionVotingControl";
import PropositionVotingResult from "../proposition/PropositionVotingResult";
import {
  shouldRenderSimpleVotingInformation,
  shouldRenderVoting,
  shouldRenderVotingControl,
  shouldRenderVotingResult,
} from "../../../lib/meeting";

import PropositionSimpleVotingInformation from "../proposition/PropositionSimpleVotingInformation";
import PropositionInformation from "../proposition/PropositionInformation";
import PropositionVotingResultsForParticipants from "../proposition/PropositionVotingResultForParticipants";
import PropositionUpdateForm from "../proposition/PropositionUpdateForm";

import "../../../styles/components/meetings/agendaItem/AgendaItemProposition.scss";
import PropositionSimpleVotingInformationForParticipants from "../proposition/PropositionSimpleVotingInformationForParticipants";
import PropositionVoteControlCenter from "../proposition/PropositionVoteControlCenter";
import RecordingAttendanceInformation from "../RecordingAttendanceInformation";
import { useParams } from "react-router-dom";
import PropositionInstructionsVoting from "../proposition/PropositionInstructionsVoting";

export default function AgendaItemProposition({
  proposition,
  roles,
  meetingState,
  meetingId,
  agendaItemId,
  typeInfo,
  showAttachments = false,
}: Props): JSX.Element {
  const {
    propositionContent,
    decision,
    preliminaryRemark,
    qualificationVotingRule,
    votingState,
    id,
    votingType,
  } = proposition;
  const renderVotingResult = shouldRenderVotingResult(
    proposition,
    meetingState,
  );

  const isAgendaItemPage = useParams().agendaItemId === agendaItemId;

  return (
    <div className="agenda-item-proposition">
      {typeInfo !== AgendaItemType.ParticipantsRegistration && (
        <Fragment>
          <PropositionUpdateForm
            initialValues={{ decision: decision || propositionContent }}
            meetingState={meetingState}
            roles={roles}
            votingState={votingState}
            id={id}
            showAttachments={showAttachments}
          />
          <PropositionInformation
            qualificationVotingRule={
              meetingState !== MeetingState.Closed &&
              votingState !== PropositionVotingState.Closed
                ? qualificationVotingRule
                : undefined
            }
            preliminaryRemark={preliminaryRemark}
            forOwner={!roles.includes(UserRoles.Employee)}
          />

          {shouldRenderVoting(roles, proposition, meetingState) &&
            isAgendaItemPage && (
              <PropositionVoting
                id={id}
                meetingId={meetingId}
                meetingState={meetingState}
                roles={roles}
                votingState={votingState}
              />
            )}
          {!isAgendaItemPage && (
            <PropositionInstructionsVoting
              id={id}
              meetingId={meetingId}
              agendaItemId={agendaItemId}
              votingType={votingType}
              isAgendaItemPage={isAgendaItemPage}
            />
          )}

          {shouldRenderVotingControl(roles, proposition, meetingState) && (
            <Fragment>
              <PropositionVotingControl
                id={id}
                meetingState={meetingState}
                votingState={votingState}
                agendaItemId={agendaItemId}
                votingType={votingType}
              />
              {votingState === PropositionVotingState.Running &&
                votingType !== PropositionVotingType.Simple && (
                  <PropositionVoteControlCenter propositionId={id} />
                )}
            </Fragment>
          )}

          {renderVotingResult && (
            <Fragment>
              <PropositionVotingResult
                propositionId={id}
                preliminaryRemark={preliminaryRemark}
                votingType={votingType}
              />
              {votingType !== PropositionVotingType.Simple && (
                <PropositionVotingResultsForParticipants
                  propositionId={id}
                  forOwner={!roles.includes(UserRoles.Employee)}
                />
              )}
            </Fragment>
          )}

          {shouldRenderSimpleVotingInformation(proposition, meetingState) && (
            <Fragment>
              {roles.includes(UserRoles.Employee) && (
                <PropositionSimpleVotingInformation votingState={votingState} />
              )}
              {!roles.includes(UserRoles.Employee) && (
                <PropositionSimpleVotingInformationForParticipants
                  votingState={votingState}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {typeInfo === AgendaItemType.ParticipantsRegistration && (
        <RecordingAttendanceInformation />
      )}
    </div>
  );
}

type Props = {
  proposition: Proposition;
  roles: UserRoles[];
  meetingState: MeetingState;
  meetingId: string;
  agendaItemId: string;
  typeInfo?: AgendaItemType;
  showAttachments?: boolean;
};
