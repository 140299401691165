import React from "react";

import "../../../styles/components/meetings/participants/ParticipantsVotingEligibilityName.scss";
import { Participant } from "../../../types";
import { getReverseParticipantDisplayName } from "../../../lib/meeting";

export default function ParticipantsVotingEligibilityName({
  participant,
  forOwner,
}: Props): JSX.Element {
  const qualificationValue =
    participant.participantVotingWeight?.qualificationValue;
  const doubleQualificationValue =
    participant.participantVotingWeight?.doubleQualificationValue;
  return (
    <div className="etv-participants-voting-eligibility-name-container">
      <div className="etv-participants-voting-eligibility-name-wrapper">
        <span className="etv-participants-voting-eligibility-name">
          {getReverseParticipantDisplayName(participant, ",")}
        </span>
        {forOwner && (
          <div className="etv-participants-voting-eligibility-name-info-wrapper">
            <span>
              (Vollmacht
              <span className="etv-participants-voting-eligibility-name-info">
                i
                <span className="etv-participants-voting-eligibility-name-hint">
                  Diese Person hat Sie bevollmächtigt, für ihn/sie abzustimmen.
                </span>
              </span>
              )
            </span>
          </div>
        )}
      </div>
      {forOwner && (
        <div className="voting-share-wrapper">
          <span>
            {qualificationValue &&
              `Stimmanteil${doubleQualificationValue ? " 1" : ""}: (${qualificationValue.value}/${qualificationValue.totalValues})`}
          </span>

          <span>
            {" "}
            {doubleQualificationValue &&
              `Stimmanteil${qualificationValue ? " 2" : ""}: (${doubleQualificationValue.value}/${doubleQualificationValue.totalValues})`}
          </span>
        </div>
      )}
    </div>
  );
}

type Props = {
  participant: Participant;
  forOwner?: boolean;
};
