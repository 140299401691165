import React from "react";
import "../../styles/components/management/objectSettings/ObjectReports.scss";
import Skeleton from "react-loading-skeleton";

export default function ObjectReportItemSkeleton(): JSX.Element {
  return (
    <li className="reports-descriptions-list-item">
      <Skeleton style={{ width: "150px", marginTop: "15px" }} />
    </li>
  );
}
