import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CustomerSettings,
  CustomerRequest,
  CustomerRolesRequest,
  Identity,
  Customer,
  Permission,
  PermissionRequest,
  AutoSetCorrespondenceToHomecaseForNewRegisteredUserRequest,
  Employee,
  OnlineIdentityRequest,
  EmployeeIdentityRequest,
  InternetUserAccountRequest,
  ProhibitDeactivationUserSettingReceiveEmailNotification,
  Group,
} from "../types";
import { CUSTOMER_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import { UserRoles } from "../enums";

export const customerApi = createApi({
  reducerPath: CUSTOMER_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: [
    "AssignedObjects",
    "FacilityObjects",
    "ApiKeys",
    "Settings",
    "Employees",
    "Groups",
    "GroupPermissionsList",
  ],
  endpoints: (build) => ({
    getRoles: build.query<Array<UserRoles>, CustomerRolesRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/myRoles`,
        params: { facilityObjectId },
      }),
      transformResponse: (response: { roles: Array<UserRoles> }) => {
        return response.roles;
      },
    }),

    getIdentity: build.query<Identity, CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/myOnlineIdentity`,
    }),

    getApiKey: build.query<
      {
        id: string;
        generatedByUserSid: string;
        generatedAtUtc: string;
      },
      CustomerRequest
    >({
      query: ({ customerToken }) => `/customers/${customerToken}/apiKey`,
      providesTags: [{ type: "ApiKeys", id: "KEY" }],
    }),

    postApiKey: build.mutation<
      {
        id: string;
        apiKey: string;
        generatedByUserSid: string;
        generatedAtUtc: string;
      },
      CustomerRequest
    >({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/apiKey`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "ApiKeys", id: "KEY" }],
    }),

    getSettings: build.query<CustomerSettings, CustomerRequest>({
      query: ({ customerToken }) => `/customers/${customerToken}/settings`,
      providesTags: ["Settings"],
    }),

    postAutoSetCorrespondenceToHomecaseForNewRegisteredUser: build.mutation<
      void,
      AutoSetCorrespondenceToHomecaseForNewRegisteredUserRequest
    >({
      query: ({ customerToken, value }) => ({
        url: `/customers/${customerToken}/settings/AutoSetCorrespondenceDispatchingMethodToHomecaseForNewRegisteredUser`,
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["Settings"],
    }),

    postProhibitDeactivationUserSettingReceiveEmailNotification: build.mutation<
      void,
      ProhibitDeactivationUserSettingReceiveEmailNotification
    >({
      query: ({ customerToken, value }) => ({
        url: `/customers/${customerToken}/settings/ProhibitDeactivationUserSettingReceiveEmailNotification`,
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["Settings"],
    }),

    getCustomers: build.query<Customer[], void>({
      query: () => `/customers`,
      providesTags: [{ type: "AssignedObjects", id: "OBJECTS" }],
    }),

    getProhibitDeactivationUserSettingReceiveEmailNotification: build.query<
      {
        prohibitDeactivationUserSettingReceiveEmailNotification: boolean;
      },
      void
    >({
      query: () => `/customers/settings/aggregated`,
    }),

    getMyPermissions: build.query<Permission[], CustomerRequest>({
      query: ({ customerToken }) => `/customers/${customerToken}/myPermissions`,
    }),

    getPermission: build.query<boolean, PermissionRequest>({
      query: ({ customerToken, employeeId, permissionId }) => ({
        url: `/customers/${customerToken}/employees/${employeeId}/permissions/${permissionId}`,
        method: "HEAD",
        responseHandler: (response) => Promise.resolve(response.status === 200),
        validateStatus: () => true,
      }),
    }),

    getPermissions: build.query<Permission[], PermissionRequest>({
      query: ({ customerToken, employeeId }) => ({
        url: `/customers/${customerToken}/employees/${employeeId}/permissions`,
        method: "GET",
      }),
    }),

    getEmployeesList: build.query<Employee[], CustomerRequest>({
      query: ({ customerToken }) => `/customers/${customerToken}/employees`,
      providesTags: ["Employees"],
    }),

    postAssignUserToCustomer: build.mutation<
      {
        succeeded: boolean;
        assignedCustomer: string;
      },
      {
        userSid: string;
        registrationCode: string;
      }
    >({
      query: ({ userSid, registrationCode }) => ({
        url: `/registration/assignUserToCustomer`,
        method: "POST",
        body: { userSid, registrationCode },
      }),

      invalidatesTags: [
        { type: "AssignedObjects", id: "OBJECTS" },
        "FacilityObjects",
      ],
    }),

    postEmployeeOnlineIdentity: build.mutation<
      EmployeeIdentityRequest,
      OnlineIdentityRequest
    >({
      query: ({ customerToken, issuedFor, employeeId }) => ({
        url: `/customers/${customerToken}/employees/onlineIdentities`,
        method: "POST",
        body: { issuedFor, employeeId },
      }),
    }),

    postEnrollInternetUserAccount: build.mutation<
      void,
      InternetUserAccountRequest
    >({
      query: ({ firstName, lastName, email, oid, customerToken }) => ({
        url: `/customers/${customerToken}/employees/onlineIdentities/${oid}/enrollInternetUserAccount`,
        method: "POST",
        body: { firstName, lastName, email },
      }),

      invalidatesTags: ["Employees"],
    }),

    getGroups: build.query<Group[], CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/employees/groups`,
      providesTags: ["Groups"],
    }),

    getPermissionsList: build.query<Permission[], PermissionRequest>({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/employees/permissions`,
        method: "GET",
      }),
    }),

    getGroupPermissionsList: build.query<Permission[], PermissionRequest>({
      query: ({ customerToken, groupId }) => ({
        url: `/customers/${customerToken}/employees/groups/${groupId}/permissions`,
        method: "GET",
      }),
      providesTags: (result, data, permissionRequest) =>
        result
          ? [
              {
                type: "GroupPermissionsList" as const,
                id: permissionRequest.groupId,
              },
            ]
          : ["GroupPermissionsList"],
    }),

    revokePermission: build.mutation<Permission[], PermissionRequest>({
      query: ({ customerToken, groupId, pid }) => ({
        url: `/customers/${customerToken}/employees/groups/${groupId}/permissions`,
        method: "DELETE",
        body: [pid],
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "GroupPermissionsList" as const, id: arg.groupId },
      ],
    }),

    postPermission: build.mutation<Permission[], PermissionRequest>({
      query: ({ customerToken, groupId, pid }) => ({
        url: `/customers/${customerToken}/employees/groups/${groupId}/permissions`,
        method: "POST",
        body: [pid],
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "GroupPermissionsList" as const, id: arg.groupId },
      ],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetIdentityQuery,
  useGetSettingsQuery,
  useGetCustomersQuery,
  usePostAssignUserToCustomerMutation,
  useGetMyPermissionsQuery,
  useGetPermissionQuery,
  useGetApiKeyQuery,
  usePostApiKeyMutation,
  usePostAutoSetCorrespondenceToHomecaseForNewRegisteredUserMutation,
  useGetEmployeesListQuery,
  useGetPermissionsQuery,
  usePostEmployeeOnlineIdentityMutation,
  usePostEnrollInternetUserAccountMutation,
  usePostProhibitDeactivationUserSettingReceiveEmailNotificationMutation,
  useGetProhibitDeactivationUserSettingReceiveEmailNotificationQuery,
  useGetGroupsQuery,
  useGetPermissionsListQuery,
  useGetGroupPermissionsListQuery,
  useRevokePermissionMutation,
  usePostPermissionMutation,
} = customerApi;
