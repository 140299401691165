import React from "react";
import { IconProps } from "../../../types";

export default function ServicesElektro({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M78.338 48.67H64.67l15.082-24.126A1.667 1.667 0 0 0 78.338 22h-26.67c-.765 0-1.432.521-1.617 1.264L40.05 63.269a1.667 1.667 0 0 0 1.616 2.07h11.152l-9.425 34.562a1.667 1.667 0 0 0 .917 1.957 1.697 1.697 0 0 0 2.077-.593l33.338-50.007a1.667 1.667 0 0 0-1.387-2.588z"
        fillRule="nonzero"
      />
    </svg>
  );
}
