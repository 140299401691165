import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useMeetingState from "../../hooks/meetings/useMeetingState";
import useUpdateMeetingState from "../../hooks/meetings/useUpdateMeetingState";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import Button from "../common/Button";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import {
  isMeetingReadToClose,
  isMeetingReadToShare,
  isMeetingReadyToStart,
} from "../../lib/meeting";
import UpdateDialog from "../common/dialog/UpdateDialog";
import ErrorDialog from "../common/dialog/ErrorDialog";
import { useSynchronisation } from "../../hooks/useSynchronisation";
import { MeetingState, SynchronisationMethod } from "../../enums";
import useValidateMeeting from "../../hooks/meetings/useValidateMeeting";
import AlertDialog from "../common/dialog/AlertDialog";
import MeetingValidationError from "./MeetingValidationError";
import MeetingConfirmStartDialog from "./MeetingConfirmStartDialog";
import { useParams } from "react-router-dom";
import { useGetAgendaItemsQuery } from "../../api/agendaItemApi";
import ActionsWarningIcon from "../icons/ActionsWarningIcon";
import IconButton from "../common/IconButton";

export default function MeetingControl(): ReactElement {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();
  const [isCloseToConfirm, setCloseToConfirm] = useState(false);
  const [isActionsWarningDialogOpen, setActionsWarningDialogOpen] =
    useState(false);
  const [isShareToConfirm, setShareToConfirm] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isCloseMeeting, setIsCloseMeeting] = useState(false);
  const [endMeetingDuringVote, setEndMeetingDuringVote] = useState(false);
  const {
    data = [],
    refetch,
    isFetching,
  } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    includePropositions: true,
  });

  const allPropositions = useMemo(
    () =>
      data
        .flatMap((item) =>
          (item.propositions || []).map((proposition) => ({
            ...proposition,
            topNumber: item.number,
            topTitle: item.title,
          })),
        )
        .filter((proposition) => proposition.votingState === 1),
    [data],
  );
  const meetingState = useMeetingState();
  const { validate, isValidating, isValid, failureReasons } =
    useValidateMeeting();
  const { closeMeeting, shareMeeting, isLoading, retry, error, isSuccess } =
    useUpdateMeetingState();
  const customerRoles = useCustomerRoles();
  const { sync } = useSynchronisation();

  const handleConfirmCloseMeeting = useCallback(() => {
    if (allPropositions.length > 0) {
      setEndMeetingDuringVote(true);
    }
    if (allPropositions.length === 0) {
      setIsCloseMeeting(true);
      setCloseToConfirm(!isCloseToConfirm);
    }
    refetch();
  }, [
    setCloseToConfirm,
    isCloseToConfirm,
    endMeetingDuringVote,
    allPropositions,
  ]);

  const handleConfirmShareMeeting = useCallback(() => {
    setShareToConfirm(!isShareToConfirm);
  }, [setShareToConfirm, isShareToConfirm]);

  const handleAlertConfirm = useCallback(() => {
    setIsValidationError(false);
  }, [setIsValidationError]);

  const handleAlertConfirmWarningDialog = useCallback(() => {
    setActionsWarningDialogOpen(false);
  }, [setActionsWarningDialogOpen]);

  const handleShareToConfirm = useCallback(async () => {
    await validate();
    if (isValid && meetingState === MeetingState.EmployeePreview) {
      shareMeeting();
    }
    if (!isValid && failureReasons) {
      setIsValidationError(true);
    }
  }, [isValid, failureReasons, validate, setIsValidationError]);

  useEffect(() => {
    if (allPropositions.length > 0) {
      setEndMeetingDuringVote(true);
    }
  }, [data, setEndMeetingDuringVote, allPropositions]);

  useEffect(() => {
    if (isLoading) {
      setCloseToConfirm(false);
    }
  }, [isLoading, setCloseToConfirm]);

  useEffect(() => {
    if (meetingState === 3) {
      setIsCloseMeeting(false);
    }
  }, [meetingState, setIsCloseMeeting]);

  useEffect(() => {
    if (isValidating) {
      setShareToConfirm(false);
    }
  }, [isValidating, setShareToConfirm]);

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.MeetingStateChanged);
    }
  }, [isSuccess]);

  useEffect(() => {
    validate();
  }, [meetingId]);

  return (
    <div className="etv-meeting-control" data-testid="etv-meeting-control">
      {!isValid &&
        failureReasons &&
        meetingId !== "" &&
        meetingState !== MeetingState.Closed && (
          <IconButton
            onClick={() => {
              setActionsWarningDialogOpen(true);
            }}
            className="actions-warning-info"
            icon={ActionsWarningIcon}
          />
        )}
      {isMeetingReadToClose(meetingState, customerRoles) && (
        <Button
          label="Versammlung beenden"
          red
          onClick={handleConfirmCloseMeeting}
          disabled={isLoading}
        />
      )}
      {isMeetingReadyToStart(meetingState, customerRoles) && (
        <MeetingConfirmStartDialog meetingId={meetingId} disabled={isLoading} />
      )}
      {isMeetingReadToShare(meetingState, customerRoles) && (
        <Button
          label="Für Eigentümer anzeigen"
          lightblue
          onClick={handleConfirmShareMeeting}
          disabled={isLoading}
        />
      )}
      {isCloseToConfirm && allPropositions.length === 0 && !isFetching && (
        <ConfirmDialog
          title="Versammlung beenden"
          description="Möchten Sie die Versammlung wirklich beenden?"
          onConfirm={closeMeeting}
          onCancel={handleConfirmCloseMeeting}
        />
      )}
      {isShareToConfirm && (
        <ConfirmDialog
          title="Für Eigentümer anzeigen"
          description="Wollen Sie wirklich diese Versammlung für Eigentümer anzeigen?"
          onConfirm={handleShareToConfirm}
          onCancel={handleConfirmShareMeeting}
          confirmLabel="Anzeigen"
        />
      )}
      {isLoading && meetingState === 0 && (
        <UpdateDialog
          description="Die Versammlung wird für die Eigentümer veröffentlicht."
          title="Für Eigentümer anzeigen"
        />
      )}
      {isLoading && isCloseMeeting && (
        <UpdateDialog
          description="Die Versammlung wird für alle Teilnehmer beendet."
          title="Versammlung beenden"
        />
      )}
      {isValidating && meetingState === 0 && (
        <UpdateDialog
          description="Die Eigentümerversammlung wird für die Freigabe geprüft"
          title="Eigentümerversammlung wird geprüft."
        />
      )}
      {isValidationError && failureReasons && (
        <AlertDialog
          title="Die Versammlung kann für Eigentümer nicht angezeigt bzw. gestartet werden."
          onConfirm={handleAlertConfirm}
          description={
            <MeetingValidationError failureReasons={failureReasons} />
          }
        />
      )}
      {failureReasons && isActionsWarningDialogOpen && (
        <AlertDialog
          title="Um die Versammlung starten zu können (bzw. auf Sichtbar stellen zu können), ergänzen Sie bitte folgende Informationen Ihrer Eigentümerversammlung."
          onConfirm={handleAlertConfirmWarningDialog}
          description={
            <MeetingValidationError failureReasons={failureReasons} />
          }
          showCloseButton
        />
      )}
      <ErrorDialog
        description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
        title="Es ist ein Fehler aufgetreten!"
        onRetry={retry}
        error={error}
      />
      {allPropositions.length > 0 && endMeetingDuringVote && !isFetching && (
        <AlertDialog
          title="Die Versammlung kann bei laufenden Abstimmungen nicht beendet werden."
          onConfirm={() => setEndMeetingDuringVote(false)}
          description={
            <div className="meeting-beenden-dialog-info">
              <span>
                Unter folgenden TOPs wurde die Abstimmung noch nicht beendet:
              </span>
              {allPropositions.map((item, index) => (
                <div key={item.id}>
                  {index + 1}. TOP {item.topNumber}: {item.topTitle}
                </div>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
}
