import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DecisionAcceptedRequest,
  MeetingsRequest,
  Participant,
  Proposition,
  PropositionCategory,
  PropositionStatus,
  PropositionItemPatchRequest,
  PropositionItemRequest,
  PropositionItemSimpleRequest,
  PropositionRequest,
  PropositionVote,
  PropositionVoteForParticipantRequest,
  PropositionVoteForParticipantsRequest,
  PropositionVoteRequest,
  PropositionVotingResult,
  PropositionVotingWeight,
  StartVotingResultRequest,
  MeetingAttachment,
  MeetingAttachmentsRequest,
  MeetingAttachmentContentRequest,
  MeetingDeleteAttachmentRequest,
  MeetingPostCreateAttachmentRequest,
  PropositionsResultForPDF,
  PropositionsResultForPDFRequest,
} from "../types";
import { PROPOSITION_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import { PropositionVoteType } from "../enums";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export const propositionApi = createApi({
  reducerPath: PROPOSITION_REDUCER_PATH,
  tagTypes: [
    "PropositionCategories",
    "PropositionStatuses",
    "Propositions",
    "Vote",
    "PropositionsParticipant",
    "Proposition",
    "VotingResult",
    "PropositionVoteInstructions",
    "MeetingAttachments",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),

  endpoints: (build) => ({
    getPropositionCategories: build.query<
      PropositionCategory[],
      MeetingsRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/propositionCategories`,
      providesTags: [{ type: "PropositionCategories", id: "LIST" }],
    }),
    getPropositionStatuses: build.query<PropositionStatus[], MeetingsRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/propositionStatuses`,
      providesTags: [{ type: "PropositionStatuses", id: "LIST" }],
    }),
    getPropositions: build.query<Proposition[], PropositionRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, agendaItemId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions`,
      providesTags: [{ type: "Propositions", id: "LIST" }],
    }),
    getProposition: build.query<Proposition, PropositionItemRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}`,
      providesTags: [{ type: "Proposition" }],
    }),
    patchProposition: build.mutation<void, PropositionItemPatchRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        decision,
        title,
        decisionHasLegalForce,
        categoryId,
        statusId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}`,
        method: "PATCH",
        body: { decision, title, decisionHasLegalForce, categoryId, statusId },
      }),
      invalidatesTags: [{ type: "Propositions", id: "LIST" }],
    }),
    getPropositionParticipants: build.query<
      Participant[],
      PropositionItemRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/participants`,
      providesTags: [{ type: "PropositionsParticipant", id: "LIST" }],
    }),
    startVoting: build.mutation<Proposition[], StartVotingResultRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        keepStoredVotes,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/startVoting`,
        method: "POST",
        body: { keepStoredVotes },
      }),
      invalidatesTags: [
        { type: "Propositions", id: "LIST" },
        { type: "PropositionsParticipant", id: "LIST" },
      ],
    }),
    stopVoting: build.mutation<Proposition[], PropositionItemRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/endVoting`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: [
        { type: "Propositions", id: "LIST" },
        { type: "PropositionsParticipant", id: "LIST" },
        { type: "VotingResult" },
      ],
    }),
    stopSimpleVoting: build.mutation<
      Proposition[],
      PropositionItemSimpleRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        votingResult,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/endVoting`,
        method: "POST",
        body: votingResult,
      }),

      invalidatesTags: [
        { type: "Propositions", id: "LIST" },
        { type: "PropositionsParticipant", id: "LIST" },
        { type: "VotingResult" },
      ],
    }),
    getVote: build.query<PropositionVote, PropositionItemRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/myVoting`,
      transformResponse: (result: { vote: PropositionVoteType }) => ({
        voteType: result.vote,
      }),
      providesTags: [{ type: "Vote" }],
    }),
    getVotingWeight: build.query<
      PropositionVotingWeight,
      PropositionItemRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/myVotingWeight`,
      providesTags: [{ type: "Vote" }],
    }),
    getVotingResult: build.query<
      PropositionVotingResult,
      PropositionItemRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/votingResult`,
      providesTags: [{ type: "VotingResult" }],
    }),
    setVote: build.mutation<void, PropositionVoteRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        voteType,
      }) => {
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/vote`,
          method: "POST",
          body: { voteType },
        };
      },
      invalidatesTags: [{ type: "Vote" }],
    }),
    patchDecisionAccepted: build.mutation<void, DecisionAcceptedRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        decision,
      }) => {
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/votingResult`,
          method: "PATCH",
          body: decision,
        };
      },
      invalidatesTags: [{ type: "VotingResult" }],
    }),
    setVoteForParticipant: build.mutation<
      void,
      PropositionVoteForParticipantRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        voteType,
        participantId,
      }) => {
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/voteForParticipant`,
          method: "POST",
          body: { voteType, participantId },
        };
      },
      invalidatesTags: [{ type: "PropositionsParticipant" }],
    }),
    setVoteForParticipants: build.mutation<
      void,
      PropositionVoteForParticipantsRequest
    >({
      queryFn: async (
        {
          customerToken,
          meetingId,
          facilityObjectId,
          agendaItemId,
          propositionId,
          voteType,
          participantIds,
        },
        api,
        extraOptions,
        baseQuery,
      ) => {
        await Promise.all(
          participantIds.map((participantId) => {
            return baseQuery({
              url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/voteForParticipant`,
              method: "POST",
              body: { voteType, participantId },
            });
          }),
        );

        return {
          data: undefined,
        };
      },
      invalidatesTags: [{ type: "PropositionsParticipant" }],
    }),
    checkVoteInstructionsAvailability: build.query<
      boolean,
      PropositionItemRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/hasVoteInstructions`,
        method: "HEAD",
        responseHandler: (response) => Promise.resolve(response.status === 204),
        validateStatus: () => true,
      }),
      providesTags: [{ type: "PropositionVoteInstructions" }],
    }),
    invalidateVoteInstructions: build.mutation<void, PropositionItemRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/invalidateVoteInstructions`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "PropositionVoteInstructions" }],
    }),
    getMeetingItemAttachments: build.query<
      MeetingAttachment[],
      MeetingAttachmentsRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
      }) => {
        if (propositionId) {
          return `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/attachments`;
        } else {
          return `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/attachments`;
        }
      },
      providesTags: [{ type: "MeetingAttachments", id: "LIST" }],
    }),
    getMeetingItemAttachmentContent: build.query<
      Blob,
      MeetingAttachmentContentRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        attachmentId,
      }) => ({
        url: propositionId
          ? `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/attachments/${attachmentId}/content`
          : `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/attachments/${attachmentId}/content`,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),
    postMeetingItemAttachment: build.mutation<
      MeetingAttachment[],
      MeetingPostCreateAttachmentRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        file,
      }) => {
        const formData = new FormData();
        formData.append("content", file);
        return {
          url: propositionId
            ? `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/attachments`
            : `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/attachments`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "MeetingAttachments", id: "LIST" }],
    }),
    deleteMeetingItemAttachment: build.mutation<
      void,
      MeetingDeleteAttachmentRequest
    >({
      queryFn: async (
        {
          customerToken,
          meetingId,
          facilityObjectId,
          agendaItemId,
          propositionId,
          deleteFiles,
        },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const deletePromises = deleteFiles.map(({ id }) => {
          return baseQuery({
            url: propositionId
              ? `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/attachments/${id}`
              : `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/attachments/${id}`,
            method: "DELETE",
          });
        });

        await Promise.all(deletePromises);

        return {
          data: undefined,
        };
      },
      invalidatesTags: [{ type: "MeetingAttachments", id: "LIST" }],
    }),

    getVotingResultsForPropositions: build.query<
      PropositionsResultForPDF[],
      {
        customerToken: string;
        meetingId: string;
        facilityObjectId: string;
        propositions: PropositionsResultForPDFRequest[];
      }
    >({
      queryFn: async (
        { customerToken, meetingId, facilityObjectId, propositions },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const results = await Promise.all(
          propositions.map(
            async ({
              agendaItemId,
              propositionId,
              agendaItemNumber,
              agendaItemTitle,
              propositionTitle,
              decisionLegalValidity,
              statusTitle,
              categoryTitle,
              description,
              votingType,
            }) => {
              const result = await baseQuery({
                url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/agendaItems/${agendaItemId}/propositions/${propositionId}/votingResult`,
                method: "GET",
              });
              return {
                ...result,
                agendaItemId,
                propositionId,
                agendaItemNumber,
                agendaItemTitle,
                propositionTitle,
                decisionLegalValidity,
                statusTitle,
                categoryTitle,
                description,
                votingType,
              };
            },
          ),
        );

        const hasError = results.some((result) => !result.error);

        if (!hasError) {
          return {
            error: {
              status: "FETCH_ERROR",
              error: "Fehler beim Abrufen der Abstimmungsergebnisse",
            } as FetchBaseQueryError,
          };
        }

        const votingResults = results
          .map((result) => {
            return {
              votingResult: result.data ? result.data : null,
              agendaItemId: result.agendaItemId,
              propositionId: result.propositionId,
              agendaItemNumber: result.agendaItemNumber,
              agendaItemTitle: result.agendaItemTitle,
              propositionTitle: result.propositionTitle,
              decisionLegalValidity: result.decisionLegalValidity,
              statusTitle: result.statusTitle,
              categoryTitle: result.categoryTitle,
              description: result.description,
              votingType: result.votingType,
            };
          })
          .filter((item): item is PropositionsResultForPDF => item !== null);

        return {
          data: votingResults,
        };
      },
      providesTags: [{ type: "VotingResult" }],
    }),
  }),
});

export const {
  useGetPropositionCategoriesQuery,
  useGetPropositionStatusesQuery,
  useGetPropositionsQuery,
  useGetPropositionQuery,
  useGetPropositionParticipantsQuery,
  useStartVotingMutation,
  useStopVotingMutation,
  useSetVoteMutation,
  useSetVoteForParticipantMutation,
  useSetVoteForParticipantsMutation,
  usePatchPropositionMutation,
  useGetVoteQuery,
  useGetVotingWeightQuery,
  useGetVotingResultQuery,
  useStopSimpleVotingMutation,
  usePatchDecisionAcceptedMutation,
  useCheckVoteInstructionsAvailabilityQuery,
  useInvalidateVoteInstructionsMutation,
  useGetMeetingItemAttachmentsQuery,
  useLazyGetMeetingItemAttachmentContentQuery,
  usePostMeetingItemAttachmentMutation,
  useDeleteMeetingItemAttachmentMutation,
  useGetVotingResultsForPropositionsQuery,
} = propositionApi;
