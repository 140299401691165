import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import { useGetUserProfileQuery } from "../../api/userApi";

import "../../styles/components/pages/GoodbyePage.scss";
import Button from "../common/Button";
import useGetUsersMyProfile from "../../hooks/useGetUsersMyProfile";

export default function GoodbyePage({
  showPageHeaderActions,
}: Props): JSX.Element {
  const { user } = useGetUsersMyProfile();

  const {
    data: userProfile,
    isLoading,
    isSuccess,
  } = useGetUserProfileQuery({
    userSid: user.sid,
  });

  return (
    <div className="goodbye-page-container">
      <Helmet>
        <title>Auf Wiedersehen - Homecase</title>
      </Helmet>
      {!isLoading && isSuccess && (
        <Fragment>
          <PageHeader
            showPageHeaderActions={showPageHeaderActions}
            title={`Auf Wiedersehen, ${userProfile?.lastName} ${userProfile?.firstName}!`}
          />
          <div className="goodbye-container">
            <h3>Wir werden Sie vermissen!</h3>
            <span>
              Wir haben Ihre Anfrage erhalten und werden Ihren Account in Kürze
              löschen. Sollte es sich hierbei um ein Versehen handeln, schreiben
              Sie uns bitte so schnell wie möglich eine E-Mail{" "}
              <a href={`mailto: homecase@domus-software.de`}>
                homecase@domus-software.de
              </a>
              , damit wir die Löschung zurücknehmen können.
            </span>

            <Button
              lightblue
              label="OK"
              role={"button"}
              onClick={() => {
                window.location.assign("/anmelden");
              }}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

type Props = {
  showPageHeaderActions?: boolean;
};
