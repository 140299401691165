import React from "react";
import { IconProps } from "../../../types";

export default function ServicesWinterService({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M80.167 90H75c6.444 0 11.667-5.223 11.667-11.667V49.167a11.63 11.63 0 0 0-9.48-11.404 1.667 1.667 0 0 1-1.353-1.636v-7.794c0-.92.746-1.666 1.666-1.666h4.167a3.333 3.333 0 1 0 0-6.667H38.334a3.333 3.333 0 1 0 0 6.667H42.5c.92 0 1.667.746 1.667 1.666v7.794c0 .8-.57 1.487-1.357 1.636a11.63 11.63 0 0 0-9.476 11.404v29.166C33.334 84.777 38.557 90 45 90h-5.166a10 10 0 0 0-9.8 8 1.667 1.667 0 0 0 1.633 2h56.667a1.667 1.667 0 0 0 1.636-2 10 10 0 0 0-9.803-8zM50.834 28.333c0-.92.746-1.666 1.666-1.666h15c.92 0 1.667.746 1.667 1.666v7.5c0 .92-.746 1.667-1.667 1.667h-15c-.92 0-1.666-.746-1.666-1.667v-7.5zm9.166 50c-6.443 0-11.666-5.223-11.666-11.666a10.473 10.473 0 0 1 6.266-9.754.887.887 0 0 1 1.237.52 6.517 6.517 0 0 0 1.897 2.704.833.833 0 0 0 1.333-.274A12.243 12.243 0 0 0 58 48.717a.893.893 0 0 1 1.1-1.314 21.463 21.463 0 0 1 12.567 19.264A11.667 11.667 0 0 1 60 78.333z"
        fillRule="nonzero"
      />
    </svg>
  );
}
