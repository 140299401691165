import { StyleSheet } from "@react-pdf/renderer";

const PdfStyles = StyleSheet.create({
  title: {
    paddingLeft: 10,
    marginTop: 15,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  description: {
    display: "flex",
  },
  text: {
    paddingLeft: 10,
  },
  topTitle: {
    flexWrap: "wrap",
  },
  header: {
    fontSize: 16,
    textAlign: "left",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  meetingTitle: {
    fontSize: 16,
    marginBottom: 5,
    textAlign: "left",
  },
  rowMeetingInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  column1: {
    width: "40%",
  },
  column2: {
    width: "30%",
  },
  column3: {
    width: "30%",
  },
  heading: {
    fontSize: 12,
    marginBottom: 2,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  img: {
    width: "170%",
    height: 40,
    objectFit: "contain",
  },
  separator: {
    marginTop: 5,
    marginBottom: 10,
    height: 2,
    backgroundColor: "#f5f5f5",
  },
  voteBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  voteBarContainerLabel: {
    width: "15%",
  },
  voteBarContainerCount: {
    width: "10%",
  },
  voteBarContainerBar: {
    width: "75%",
  },
  voteBar: {
    height: 10,
    borderRadius: 5,
    marginLeft: 10,
  },
  voteResults: {
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
  section: {
    marginBottom: 10,
    marginTop: 10,
  },
  topicHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    backgroundColor: "#f5f5f5",
    padding: 10,
    flexWrap: "wrap",
  },
  topicHeaderNumber: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  numberOfParticipants: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 10,
    paddingTop: 10,
  },
  count: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 10,
    color: "grey",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    padding: "30px 30px 50px 30px",
    fontSize: 12,
    fontFamily: "Helvetica",
  },

  titleVotingInfo: {
    paddingBottom: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
});

export default PdfStyles;
