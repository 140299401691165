import React from "react";
import classNames from "classnames";
import useSearchByQueryParticipants from "../../../hooks/meetings/useSearchByQueryParticipants";
import FetchApiError from "../../common/FetchApiError";
import ParticipantsVotingResultListItem from "../participants/ParticipantsVotingResultListItem";
import PropositionVotingResultsForParticipantsSkeleton from "../../skeleton/PropositionVotingResultsForParticipantsSkeleton";
import useFetchPropositionParticipants from "../../../hooks/meetings/useFetchPropositionParticipants";
import { getDelegationParticipant } from "../../../lib/meeting";

import { sortParticipants } from "../../../hooks/meetings/useSortParticipants";
import SearchField from "../../common/SearchField";

import "../../../styles/components/meetings/proposition/PropositionVotingResultForParticipants.scss";

export default function PropositionVotingResultsForParticipants({
  propositionId,
  forOwner,
}: Props): JSX.Element {
  const { participants, isError, error, isFetching } =
    useFetchPropositionParticipants(propositionId);

  const { queryParticipants, handleFilterByQuery } =
    useSearchByQueryParticipants(participants);

  const sortedParticipants = sortParticipants(
    queryParticipants,
    "name",
    "ascending",
  );

  return (
    <div
      className={classNames(
        "etv-agenda-item-proposition-voting-result-for-participants",
      )}
    >
      {!forOwner && (
        <SearchField
          onChange={handleFilterByQuery}
          placeholder="Person suchen"
        />
      )}
      {isError && <FetchApiError error={error} notFoundElement={<div />} />}
      {!isError && (
        <div
          role="table"
          className="etv-agenda-item-proposition-voting-result-for-participants-list"
          data-testid="voting-eligibility-list"
        >
          <div
            className="etv-agenda-item-proposition-voting-result-for-participants-list-header"
            role="row"
          >
            <div
              className="etv-agenda-item-proposition-voting-result-for-participants-list-header-name"
              role="columnheader"
              aria-details="name"
            >
              {forOwner ? "Abgestimmt für Eigentümer*in" : "Eigentümer*in"}
            </div>
            <div className="etv-agenda-item-proposition-voting-result-for-participants-list-header-actions">
              Ja
            </div>
            <div className="etv-agenda-item-proposition-voting-result-for-participants-list-header-actions">
              Nein
            </div>
            <div className="etv-agenda-item-proposition-voting-result-for-participants-list-header-actions">
              Enthalten
            </div>
          </div>
          <div
            className="etv-agenda-item-proposition-voting-result-for-participants-list-body"
            role="row"
          >
            {sortedParticipants.map((p, key) => (
              <ParticipantsVotingResultListItem
                key={key}
                participant={p}
                delegatedParticipant={getDelegationParticipant(
                  participants,
                  p.delegationType,
                  p.delegate,
                )}
              />
            ))}
            {isFetching && participants.length === 0 && (
              <PropositionVotingResultsForParticipantsSkeleton
                forOwner={forOwner}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

type Props = {
  propositionId: string;
  forOwner?: boolean;
};
