import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Document } from "../../types";
import moment from "moment";
import { getContentSize } from "../../lib";
import useTracking from "../googleAnalytics/useTracking";

export default function useSearchDocument(documents: Document[]) {
  const [term, setTerm] = useState<string | undefined>();
  const { trackEvent } = useTracking();
  const handleSearch = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    trackEvent({ action: "search", category: "documents", label: value });
    setTerm(value);
  }, []);

  const searchedDocuments = useMemo(() => {
    if (!term) {
      return documents;
    }

    const searchReg = new RegExp(
      term
        .toLowerCase()
        .replace(" ", ",")
        .replace(",", "|")
        .replace(".", "\\.")
        .replace("-", "\\-"),
      "ig",
    );
    const wordLength = term.toLowerCase().replace(" ", ",").split(",").length;
    return documents.filter(
      ({
        description,
        createDateUTC,
        originalFileName,
        category,
        contentType,
        contentSize,
        isSharedForCurrentUser,
      }) => {
        const { size, count } = getContentSize(contentSize);
        const queryString = [
          description,
          moment.utc(createDateUTC).local().format("L"),
          originalFileName,
          category,
          contentType,
          `${count.toFixed(1)} ${size}`,
          isSharedForCurrentUser ? "persönlich" : "",
        ]
          .filter((s) => s !== null)
          .join(" ");
        const result = new Set(
          Array.from(queryString.matchAll(searchReg)).map(([match]) =>
            match.toLowerCase(),
          ),
        );

        return result.size === wordLength;
      },
    );
  }, [documents, term]);
  return {
    searchedDocuments,
    handleSearch,
    term,
  };
}
