import React from "react";
import { IconProps } from "../../types";

export default function Checkmark({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M12.75 17.836l7.543-7.543a1 1 0 0 1 1.414 1.414l-8.25 8.25a1 1 0 0 1-1.414 0l-3.75-3.75a1 1 0 1 1 1.414-1.414l3.043 3.043z"
        fillRule="evenodd"
      />
    </svg>
  );
}
