import React from "react";
import { IconProps } from "../../../types";

export default function ServicesUtilizer({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        transform="translate(28 20)"
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M52.174 0H5.217A5.217 5.217 0 0 0 0 5.217v6.957c0 .96.779 1.739 1.74 1.739h60.869c.96 0 1.739-.779 1.739-1.74C64.348 5.45 58.898 0 52.174 0zM57.391 19.13H5.217c-.96 0-1.739.78-1.739 1.74v39.819a.856.856 0 0 0 1.325.727 13.033 13.033 0 0 1 18.675 17.238.877.877 0 0 0 .727 1.346h16.877a1.74 1.74 0 0 0 1.67-1.263l16.302-57.39a1.74 1.74 0 0 0-1.663-2.217z" />
        <circle cx="12.174" cy="72.174" r="7.826" />
      </g>
    </svg>
  );
}
