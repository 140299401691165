import { FieldRenderProps } from "react-final-form";
import { ReactElement } from "react";
import classNames from "classnames";

import "../../../styles/components/common/form/CurrencyInput.scss";

export default function CurrencyInput({
  input,
  meta,
  className,
  label,
  required,
  symbol = "left",
  ...rest
}: Props): ReactElement {
  const currencyInputClassName = classNames(className, {
    "currency-input-field": true,
    "currency-input-field-lead-symbol": symbol === "left",
    "currency-input-field-trail-symbol": symbol === "right",
    "currency-input-error": (meta.error || meta.submitError) && meta.touched,
  });
  return (
    <section className={currencyInputClassName}>
      <label>
        {label && (
          <span className="currency-input-field-label" role="label">
            {label} {required && "*"}
          </span>
        )}
        <input
          {...input}
          {...rest}
          type="number"
          step="any"
          required={required}
          aria-label={label}
        />
        <span
          className="currency-input-field-symbol"
          data-testid="currency-symbol"
        >
          €
        </span>
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="currency-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}

interface Props extends FieldRenderProps<string | undefined, HTMLInputElement> {
  label?: string;
  symbol?: "left" | "right";
}
