import React from "react";
import "../../styles/components/common/TermsOfUsePagination.scss";
import "../../styles/components/pages/management/TermsOfUseUserListPage.scss";
import { useNavigate, useParams } from "react-router-dom";

export default function TermsOfUseUsersListPagination({
  pageNumberActual,
  hasMorePages,
}: Params): JSX.Element {
  const navigate = useNavigate();
  const { customerToken, pageNumber } = useParams();
  const pageActual = pageNumberActual ? pageNumberActual : 1;

  return (
    <div className="terms-of-use-user-list-container-header-pagination">
      <div className="pagination-container">
        <button
          onClick={() => {
            navigate(
              `/management/${customerToken}/nutzungsbedingungen/users/${
                pageActual - 1
              }`,
            );
          }}
          disabled={pageActual === 1}
        >
          <span className="button-label">{"<"}</span>
        </button>
        <p>Seite</p>
        <b>{pageNumber}</b>
        <button
          onClick={() => {
            navigate(
              `/management/${customerToken}/nutzungsbedingungen/users/${
                pageActual + 1
              }`,
            );
          }}
          disabled={!hasMorePages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

type Params = {
  pageNumberActual?: number;
  hasMorePages?: boolean;
};
