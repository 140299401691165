import React from "react";
import { IconProps } from "../../../types";

export default function ServicesManagementOfContract({
  width = 120,
  height = 120,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={className}
    >
      <g
        fillRule="nonzero"
        className={className ? `${className}-path` : undefined}
      >
        <path d="M66.167 82.5h3.334a3.333 3.333 0 0 0 3.333-3.334v-8.333c0-.92.746-1.667 1.667-1.667h3.333a1.667 1.667 0 0 0 1.12-2.9L61.744 50.62a3.313 3.313 0 0 0-4.487 0l-17.21 15.647a1.667 1.667 0 0 0 1.12 2.9H44.5c.92 0 1.667.746 1.667 1.667v8.333A3.333 3.333 0 0 0 49.5 82.5h3.334a3.333 3.333 0 0 0 3.333-3.334V72.5a3.333 3.333 0 1 1 6.667 0v6.666a3.333 3.333 0 0 0 3.333 3.334z" />
        <path d="M85.334 25.832h-3.333a3.333 3.333 0 0 0 0 6.667h1.667c.92 0 1.666.746 1.666 1.667v57.5c0 .921-.746 1.667-1.666 1.667H35.333c-.92 0-1.666-.746-1.666-1.666V34.166c0-.92.746-1.667 1.666-1.667H37a3.333 3.333 0 1 0 0-6.667h-3.333A6.667 6.667 0 0 0 27 32.5v60.834A6.667 6.667 0 0 0 33.667 100h51.667a6.667 6.667 0 0 0 6.667-6.667V32.5a6.667 6.667 0 0 0-6.667-6.667z" />
        <path d="M46.167 39.999h26.667a3.333 3.333 0 0 0 3.333-3.333v-6.667a3.333 3.333 0 0 0-3.333-3.334h-3.907a1.667 1.667 0 0 1-1.57-1.11 8.333 8.333 0 0 0-15.713 0 1.667 1.667 0 0 1-1.57 1.11h-3.907A3.333 3.333 0 0 0 42.834 30v6.667a3.333 3.333 0 0 0 3.333 3.333z" />
      </g>
    </svg>
  );
}
