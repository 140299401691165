import {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import IconButton from "../../common/IconButton";
import DownloadIcon from "../../icons/DownloadIcon";
import { Document } from "../../../types";
import Pencil from "../../icons/Pencil";
import DeleteIcon from "../../icons/DeleteIcon";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";

import {
  useLazyGetOnlineDocumentQuery,
  useDeleteOnlineDocumentMutation,
} from "../../../api/documentsApi";
import LoadingSpinner from "../../icons/LoadingSpinner";
import ObjectDocumentEditFormDialog from "./ObjectDocumentEditFormDialog";
import MenuDotsVertical from "../../icons/MenuDotsVertical";

import "../../../styles/components/management/objectSettings/ObjectDocumentActions.scss";
import classNames from "classnames";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

export default function ObjectDocumentActions({
  document,
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showEditDocument, setShowEditDocument] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [getOnlineDocument, { isFetching: isDownloading }] =
    useLazyGetOnlineDocumentQuery();
  const [deleteDocument] = useDeleteOnlineDocumentMutation();

  const handleDownload = useCallback(async () => {
    const response = await getOnlineDocument({
      customerToken: document.customerToken,
      facilityObjectId: String(document.facilityObjectId),
      documentId: String(document.id),
    });
    if (response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", document.originalFileName);
      window.document.body.appendChild(link);
      link.click();
    }
  }, []);

  const handleDelete = useCallback(async () => {
    setShowConfirmDelete(false);
    await deleteDocument({
      customerToken: document.customerToken,
      facilityObjectId: String(document.facilityObjectId),
      documentId: document.documentId,
    });
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setShowConfirmDelete(!showConfirmDelete);
    setShowMenu(false);
  }, [showConfirmDelete]);

  const handelEditDocument = useCallback(
    (event: SyntheticEvent | undefined) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setShowEditDocument(!showEditDocument);
      setShowMenu(false);
    },
    [showEditDocument],
  );

  const toggleMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <div
      className="object-document-actions-menu"
      ref={ref}
      data-testid="object-document-actions-menu"
    >
      <IconButton
        icon={MenuDotsVertical}
        className="object-documents-actions-menu-toggle"
        onClick={toggleMenu}
      />
      <div className={classNames("object-document-actions", { showMenu })}>
        <IconButton icon={Pencil} onClick={handelEditDocument} />
        <IconButton
          icon={isDownloading ? LoadingSpinner : DownloadIcon}
          disabled={isDownloading}
          onClick={handleDownload}
        />
        {!document.isSharedActivityDocument && (
          <Fragment>
            <div className="object-document-actions-vr" />
            <IconButton icon={DeleteIcon} grey onClick={handleConfirmDelete} />
          </Fragment>
        )}
      </div>
      {showConfirmDelete && (
        <ConfirmDialog
          description={`Möchten Sie wirklich das Dokument ${document.originalFileName} löschen?`}
          title={`${document.originalFileName} löschen`}
          onConfirm={handleDelete}
          onCancel={handleConfirmDelete}
          confirmLabel="Löschen"
        />
      )}
      {showEditDocument && (
        <ObjectDocumentEditFormDialog
          document={document}
          onCancel={handelEditDocument}
        />
      )}
    </div>
  );
}

type Props = {
  document: Document;
};
