import { DependencyList, useEffect } from "react";

const vendorEvents = [
  {
    hidden: "hidden",
    event: "visibilitychange",
    state: "visibilityState",
  },
  {
    hidden: "webkitHidden",
    event: "webkitvisibilitychange",
    state: "webkitVisibilityState",
  },
  {
    hidden: "mozHidden",
    event: "mozvisibilitychange",
    state: "mozVisibilityState",
  },
  {
    hidden: "msHidden",
    event: "msvisibilitychange",
    state: "msVisibilityState",
  },
  {
    hidden: "oHidden",
    event: "ovisibilitychange",
    state: "oVisibilityState",
  },
];

const visibility = (() => {
  for (const event of vendorEvents) {
    if (event.hidden in document) {
      return event;
    }
  }
  // otherwise it's not supported
  return null;
})();

export default function usePageVisibilityEffect(
  callback: (visibilityState: DocumentVisibilityState) => void,
  deps: DependencyList,
) {
  useEffect(() => {
    const handler = () => {
      const state =
        document.visibilityState === visibility?.hidden ? "hidden" : "visible";
      callback(state);
    };
    if (visibility) {
      document.addEventListener(visibility.event, handler);
    }
    return () => {
      if (visibility) {
        document.removeEventListener(visibility.event, handler);
      }
    };
  }, deps);
}
