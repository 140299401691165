import {
  useGetFacilityObjectsActivitiesQuery,
  useGetMyAddressActivitiesQuery,
} from "../../api/activitiesApi";
import { useParams } from "react-router-dom";
import { Activity } from "../../types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import useCustomerRoles from "../useCustomerRoles";
import { UserRoles } from "../../enums";

export default function useFetchMessages(
  selectedTenanciesAndOwnershipsOptions: number | null,
): UseFetchMessages {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const roles = useCustomerRoles();
  const isEmployeeOrObjectOwner =
    roles.includes(UserRoles.Employee) || roles.includes(UserRoles.ObjectOwner);

  const {
    data: objectMessages = [],
    isFetching: isObjectMessagesFetching,
    isLoading: isObjectMessagesLoading,
    isError: isObjectMessageError,
    error: objectError,
  } = useGetFacilityObjectsActivitiesQuery({
    customerToken,
    facilityObjectId,
    filter: selectedTenanciesAndOwnershipsOptions,
    filterType: isEmployeeOrObjectOwner ? "ByTenancyOrOwnership" : "Default",
  });

  const {
    data: myAddressMessages = [],
    isFetching: isMyAddressMessagesFetching,
    isLoading: isMyAddressMessagesLoading,
    isError: isMyAddressMessageError,
    error: myAddressError,
  } = useGetMyAddressActivitiesQuery({ customerToken });

  return {
    myAddressMessages,
    objectMessages,
    isFetching: isMyAddressMessagesFetching || isObjectMessagesFetching,
    isLoading: isMyAddressMessagesLoading || isObjectMessagesLoading,
    isError: isObjectMessageError || isMyAddressMessageError,
    error: objectError || myAddressError,
  };
}

type UseFetchMessages = {
  myAddressMessages: Activity[];
  objectMessages: Activity[];
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
};
