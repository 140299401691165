import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import { Form } from "react-final-form";
import React, { useCallback } from "react";
import { usePostRefuseInvitationMutation } from "../../../api/meetingApi";
import { useParams } from "react-router-dom";
import InvitationDeclineDialogDescription from "./InvitationDeclineDialogDescription";

export default function InvitationDecline({
  onNext,
  onCancel,
  isEmployee,
}: Props) {
  const [refuseInvitation] = usePostRefuseInvitationMutation();
  const {
    meetingId = "",
    customerToken = "",
    facilityObjectId = "",
  } = useParams();
  const onSubmit = useCallback(async () => {
    const result = await refuseInvitation({
      customerToken,
      meetingId,
      facilityObjectId,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!result.error) {
      onNext();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result.error;
  }, [refuseInvitation]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <ConfirmDialog
            description={<InvitationDeclineDialogDescription />}
            cancelLabel="Abbrechen"
            confirmLabel="Einladung absagen"
            title="Einladung absagen"
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmDisabled={isEmployee}
          />
        );
      }}
    />
  );
}

type Props = {
  onCancel: () => void;
  onNext: () => void;
  isEmployee: boolean;
};
