import { useIntersectionObserver } from "react-intersection-observer-hook";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetBulletinBoardPostAttachmentsQuery } from "../../api/bulletinBoardApi";
import { useParams } from "react-router-dom";
import PostAttachmentItem from "./PostAttachmentItem";
import FileUploadPreview from "../common/FileUploadPreview";
import useLightbox from "../../hooks/useLightbox";
import { UploadState } from "../../enums";
import useUploadFiles from "../../hooks/useUploadFiles";
import { useDispatch } from "react-redux";
import { remove } from "../../slices/fileUploadSlice";

import "../../styles/components/bulletinBoard/PostAttachments.scss";

export default function PostAttachments({
  postId,
  attachmentCount,
}: Props): JSX.Element {
  const { addFile, toggleOpen } = useLightbox();
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [inView, setInView] = useState(false);
  const uploadFiles = useUploadFiles(postId);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const [intersectionCallback, { entry }] = useIntersectionObserver({
    threshold: [0.5],
  });

  useEffect(() => {
    entry && entry.isIntersecting && setInView(true);
  }, [entry]);

  const { data, refetch } = useGetBulletinBoardPostAttachmentsQuery(
    {
      customerToken,
      facilityObjectId,
      bulletinBoardPostId: postId,
    },
    { skip: !inView },
  );
  const handleFilePreviewClick = useCallback((file: File) => {
    addFile(file);
    toggleOpen(file);
  }, []);

  const attachmentPlaceholders = useMemo(() => {
    const count = data
      ? data.length
      : uploadFiles.length
        ? uploadFiles.length
        : attachmentCount;
    return Array(count).fill(null);
  }, [data, attachmentCount, uploadFiles]);

  useEffect(() => {
    if (ref.current) {
      intersectionCallback(ref.current);
    }
  }, [ref.current]);

  useEffect(() => {
    if (uploadFiles.length) {
      uploadFiles.forEach(({ uploadResult = [{}], uploadState, file }) => {
        const [{ id }] = uploadResult;
        if (id && uploadState === UploadState.Completed) {
          dispatch(remove({ name: file.name }));
        }
      });
      if (!inView) {
        setInView(true);
      } else {
        refetch();
      }
    }
  }, [uploadFiles, inView, refetch]);

  return (
    <Fragment>
      <FileUploadPreview
        referenceId={postId}
        showName
        onClick={handleFilePreviewClick}
        previewWidth={81}
        previewHeight={81}
      />
      {attachmentPlaceholders.length > 0 && (
        <div
          className="bulletin-board-post-attachments"
          ref={ref}
          data-testid="post-attachments"
        >
          {attachmentPlaceholders.map((v, key) => (
            <PostAttachmentItem
              attachment={data ? data[key] : undefined}
              key={key}
              postId={postId}
              facilityObjectId={facilityObjectId}
              customerToken={customerToken}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  postId: number;
  attachmentCount: number;
};
