import React, { ReactElement } from "react";
import "../../../styles/components/meetings/participants/InstructionItemForParticipant.scss";
import {
  AgendaItem,
  MyVotingInstructionsPropositionsInfo,
} from "../../../types";
import classNames from "classnames";
import PropositionItemForInstructions from "./PropositionItemForInstructions";

export default function InstructionItemForParticipant({
  agendaItem,
  instructions,
}: Props): ReactElement {
  const instructionWrapperClass = classNames("instruction-wrapper", {
    propositions: agendaItem?.propositions && agendaItem?.propositions[1],
  });

  return (
    <div className={instructionWrapperClass}>
      <span className="agenda-item-number">TOP {agendaItem?.number}:</span>
      {agendaItem?.propositions?.map((proposition, index) => (
        <PropositionItemForInstructions
          isPropositionsList={
            !!agendaItem.propositions && !!agendaItem.propositions[1]
          }
          key={proposition.id}
          proposition={proposition}
          instructions={instructions}
          propositionIndex={index}
        />
      ))}
    </div>
  );
}

type Props = {
  instructions?: MyVotingInstructionsPropositionsInfo[];
  agendaItem: AgendaItem;
};
