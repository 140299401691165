import { Field } from "react-final-form";
import NumberInput from "../../common/form/NumberInput";
import React from "react";

export default function PropositionVotingSimple({
  values,
}: Props): JSX.Element {
  return (
    <div className="simple-voting-container">
      <Field
        className="simple-voting-container-item"
        name="totalYesVotes"
        type="number"
        component={NumberInput}
        label="Ja:"
        min={0}
        required
      />
      <Field
        className="simple-voting-container-item"
        name="totalNoVotes"
        type="number"
        component={NumberInput}
        label="Nein:"
        min={0}
        required
      />
      <Field
        className="simple-voting-container-item"
        name="totalAbstentionVotes"
        type="number"
        component={NumberInput}
        label="Enthalten:"
        min={0}
        required
      />
      <Field
        className="simple-voting-container-item total"
        name="total"
        type="number"
        disabled
        component={NumberInput}
        label="Abgestimmt:"
        placeholder={values}
      />

      <Field
        className="simple-voting-container-item"
        name="totalAttendedParticipants"
        type="number"
        component={NumberInput}
        label="Anwesende:"
        min={1}
        required
      />
    </div>
  );
}

type Props = {
  values: number;
};
