import useGetPermissions from "./useGetPermissions";
import { DcsEmployeePermissionType } from "../enums";

export default function useHasPermission(
  permissionType: DcsEmployeePermissionType,
): boolean {
  const permissions = useGetPermissions();

  return Boolean(permissions.find(({ pid }) => pid === permissionType));
}
