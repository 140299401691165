import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import React, { Fragment, ReactElement, useCallback } from "react";
import TextArea from "../common/form/TextArea";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import { usePostBulletinBoardPostReportComplainMutation } from "../../api/bulletinBoardApi";

import "../../styles/components/bulletinBoard/PostReport.scss";
import AlertDialog from "../common/dialog/AlertDialog";

export default function PostReport({ postId, onChancel }: Props): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [createReport] = usePostBulletinBoardPostReportComplainMutation();
  const onSubmit = useCallback(async (values: Record<string, string>) => {
    const result = await createReport({
      customerToken,
      facilityObjectId,
      bulletinBoardPostId: postId,
      reason: values.reason,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result.error;
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitSucceeded }) => {
        const handleConfirm = useCallback(() => {
          handleSubmit();
        }, []);

        return (
          <Fragment>
            {submitSucceeded && (
              <AlertDialog
                description="Beitrag wurde erfolgreich gemeldet."
                title="Beitrag melden"
                onConfirm={onChancel}
              />
            )}
            {!submitSucceeded && (
              <ConfirmDialog
                title="Beitrag melden"
                onConfirm={handleConfirm}
                onCancel={onChancel}
                confirmLabel="Beitrag melden"
                description={
                  <form
                    onSubmit={handleSubmit}
                    className="bulletin-board-post-list-item-report"
                    data-testid="create-reason"
                  >
                    <Field
                      name="reason"
                      component={TextArea}
                      label="Weshalb möchten Sie diesen Beitrag melden?"
                      className="bulletin-board-post-list-item-report-field"
                    />
                    <div className="bulletin-board-post-list-item-report-hint">
                      Hier können Sie unangemessene Inhalte melden, eine
                      Kommunikation mit der Hausverwaltung ist nicht möglich.
                    </div>
                  </form>
                }
              />
            )}
          </Fragment>
        );
      }}
    />
  );
}

type Props = {
  postId: number;
  onChancel: () => void;
};
