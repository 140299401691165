import { Field, Form } from "react-final-form";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Draft, { convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Button from "../../common/Button";
import AlertDialog from "../../common/dialog/AlertDialog";
import { usePatchMeetingMutation } from "../../../api/meetingApi";

import "../../../styles/components/meetings/inivitation/InvitationLinkEditor.scss";
import TextEditor from "../../common/TextEditor";
import InfoText from "../../userSettings/InfoText";

const ContentState = Draft.ContentState;
export default function InvitationLinkEditor({
  setEditorOpen,
  meetingId,
  onlineMeetingInformation,
}: Props): JSX.Element {
  const [alertDialogHidden, setAlertDialogHidden] = useState(true);
  const [errorAlertDialogHidden, setErrorAlertDialogHidden] = useState(true);
  const { facilityObjectId = "", customerToken = "" } = useParams();

  const [patchMeeting, { isSuccess, isError, error }] =
    usePatchMeetingMutation();

  const editorDefaultContent = onlineMeetingInformation
    ? onlineMeetingInformation
    : "HOMECASE und Online Meeting (Link folgt von Ihrem Hausverwalter)";

  const editorContent = EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(editorDefaultContent).contentBlocks,
    ),
  );

  const onSubmit = useCallback(
    async ({ editor }: { editor: EditorState }) => {
      const contentState = editor.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      rawContentState.blocks.forEach((block) => {
        block.text += "\n";
      });
      const htmlContent = draftToHtml(rawContentState);
      const result = await patchMeeting({
        customerToken,
        facilityObjectId,
        meetingId: meetingId,
        onlineMeetingInformation: htmlContent,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return result?.error;
    },
    [onlineMeetingInformation],
  );

  useEffect(() => {
    if (isSuccess) {
      setAlertDialogHidden(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error || isError) {
      setErrorAlertDialogHidden(false);
    }
  }, [error, isError]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values, pristine, submitting, form }) => {
        let currentLength = 0;
        const maxCurrentLength = 2000;

        const editorFieldContent = form
          .getFieldState("editor")
          ?.value?.getCurrentContent();
        const isModified =
          editorFieldContent !== undefined &&
          !editorFieldContent.equals(editorContent.getCurrentContent());

        const contentState = values.editor && values.editor.getCurrentContent();

        if (contentState) {
          const rawContentState = convertToRaw(contentState);
          rawContentState.blocks.forEach((block) => {
            block.text += "\n";
          });

          currentLength = draftToHtml(rawContentState).length;
        }

        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="editor"
              component={TextEditor}
              initialValue={editorContent}
            />
            <div className="invitation-link-editor-buttons-wrapper">
              {currentLength > maxCurrentLength && (
                <div className="info-text-wrapper">
                  <InfoText
                    isError={true}
                    textError="Die maximale Textlänge wurde erreicht. Bitte kürzen Sie Ihren Text, um fortzufahren."
                  />
                </div>
              )}
              <Button
                label="Abbrechen"
                type="button"
                onClick={() => setEditorOpen(false)}
              />
              <Button
                lightblue
                label="Speichern"
                type="submit"
                disabled={
                  !isModified ||
                  pristine ||
                  submitting ||
                  currentLength > maxCurrentLength
                }
              />
            </div>
            {isSuccess && !alertDialogHidden && (
              <AlertDialog
                title={"Erfolgreich gespeichert."}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => {
                  setAlertDialogHidden(true);
                  setEditorOpen(false);
                }}
              />
            )}
            {isError && !errorAlertDialogHidden && (
              <AlertDialog
                title={
                  "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut."
                }
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setErrorAlertDialogHidden(true)}
              />
            )}
          </form>
        );
      }}
    />
  );
}

type Props = {
  setEditorOpen: (item: boolean) => void;
  onlineMeetingInformation: string | null;
  meetingId: string;
};
