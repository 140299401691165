import React, { ReactElement } from "react";

import "../../../styles/components/common/CheckboxField.scss";
import { FieldRenderProps } from "react-final-form";
import classNames from "classnames";

export default function CheckboxField({
  label,
  input,
  meta,
  className,
  required,
  disabled,
  ...rest
}: FieldRenderProps<string, HTMLInputElement>): ReactElement {
  return (
    <section className={classNames("checkbox-field", className)}>
      <label className="checkbox-field-label">
        <input
          data-testid="checkbox-field"
          {...input}
          {...rest}
          type="checkbox"
          required={required}
          disabled={disabled}
        />
        <span className="checkbox-field-label">{label}</span>
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="currency-input-field-error" role="alert">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}
