import React from "react";
import classNames from "classnames";

import "../../styles/components/common/ProfilePictureForUser.scss";

export default function ProfilePictureForUser(props: Props): JSX.Element {
  const {
    width = 74,
    height = 74,
    alt,
    rounded,
    url,
    name,
    setAvatar,
    setProfilePicture,
    picturesList,
  } = props;

  const className = classNames({
    "profile-picture": true,
    "profile-picture-rounded": rounded,
    "pictures-list": picturesList,
  });

  return (
    <div className={className} data-testid="profile-picture-user">
      {url && (
        <img
          src={url}
          alt={alt}
          title={name}
          style={{ width, height }}
          className="profile-picture-image"
          onClick={(e) => {
            setAvatar({
              pictureName: e.currentTarget.alt,
              pictureSrc: e.currentTarget.src,
            });
            setProfilePicture(undefined);
          }}
        />
      )}
    </div>
  );
}

type Props = {
  width?: number;
  height?: number;
  alt: string;
  rounded?: boolean;
  url: string;
  name?: string;
  picturesList?: boolean;
  setAvatar: (
    item: { pictureName: string; pictureSrc: string } | undefined,
  ) => void;
  setProfilePicture: (item: File | undefined) => void;
};
