import { IconProps } from "../../types";

export default function PptFileIcon({ width, height, className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      className={className}
      data-testid="ppt-file-icon"
    >
      <defs>
        <rect id="b" width="32" height="26" x="2" y="5" rx="1" />
        <filter
          id="a"
          width="109.4%"
          height="115.4%"
          x="-4.7%"
          y="-5.8%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.584313725 0 0 0 0 0.6 0 0 0 0 0.68627451 0 0 0 0.12 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <rect
          width="33"
          height="27"
          x="1.5"
          y="4.5"
          fill="#FFF"
          stroke="#9599AF"
          strokeOpacity=".25"
          rx="1"
        />
        <path
          fill="#9599AF"
          fillOpacity=".5"
          fillRule="nonzero"
          d="M29.987 23.368c-.179 2.465-2.215 4.461-4.713 4.622a5.092 5.092 0 0 1-3.791-1.35.14.14 0 0 1 0-.205l3.42-3.375a.132.132 0 0 0 .044-.103v-4.783c0-.088.074-.147.148-.147 2.84.088 5.1 2.48 4.892 5.341zm-6.036-.601l-3.36-3.317a.145.145 0 0 0-.208 0A4.907 4.907 0 0 0 19 22.87c0 1.32.52 2.523 1.368 3.418.06.059.148.059.208 0l3.36-3.316c.074-.059.074-.147.015-.205zm0-1.027c.089.088.252.029.252-.103v-4.49a.148.148 0 0 0-.148-.147 5.137 5.137 0 0 0-3.316 1.35.14.14 0 0 0 0 .205l3.212 3.185z"
        />
        <rect width="8" height="1" x="14" y="10" fill="#EA573B" rx=".5" />
        <rect
          width="6"
          height="1"
          x="14"
          y="13"
          fill="#9599AF"
          fillOpacity=".6"
          rx=".5"
        />
        <path
          fill="#EA573B"
          d="M7.215 12.228c.471.038.931.128 1.217-.374.216-.378.198-1.07-.053-1.361-.324-.376-.743-.323-1.164-.254v1.99zm-.033 3.562H6V9.008c.912 0 1.82-.027 2.723.018.238.011.514.193.69.379.67.712.67 1.6.47 2.485-.178.789-.673 1.343-1.453 1.538-.389.097-.804.08-1.248.117v2.245z"
        />
      </g>
    </svg>
  );
}
