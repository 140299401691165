import React from "react";

import "../../styles/components/common/InformationBox.scss";

export default function InformationBox({ text }: Props): JSX.Element {
  return (
    <div className="information-box">
      <span className="information-icon">i</span>
      <span className="information-text">{text}</span>
    </div>
  );
}

type Props = {
  text: string;
};
