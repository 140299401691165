import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import {
  useGetActivityContactsQuery,
  useGetActivityDocumentsQuery,
  useGetActivityParticipantsQuery,
  useGetActivityQuery,
} from "../../api/activitiesApi";
import { Activity, ActivityContact, ActivityDocument, User } from "../../types";
import { useSelector } from "react-redux";
import { isUploadCompletedOrFailure } from "../../slices/fileUploadSlice";
import { useEffect, useMemo } from "react";
import { getUserLastName } from "../../lib/notifications";
import { ActivityState } from "../../enums";

export default function useFetchMessageDetails(): useFetchMessageDetails {
  const { customerToken = "", messageId = "" } = useParams();
  const shouldReFetch = useSelector(isUploadCompletedOrFailure(messageId));

  const {
    data: activity = { customFields: [] } as unknown as Activity,
    isFetching: isActivityFetching,
    isLoading: isActivityLoading,
    isError: isActivityError,
    error: activityError,
  } = useGetActivityQuery(
    {
      customerToken,
      activityId: messageId,
    },
    { skip: !messageId },
  );

  const {
    data: participantsData = [],
    isFetching: isParticipantsFetching,
    isLoading: isParticipantsLoading,
    isError: isParticipantsError,
    error: participantsError,
  } = useGetActivityParticipantsQuery(
    {
      customerToken,
      activityId: messageId,
    },
    {
      skip: !messageId,
    },
  );

  const {
    data: contactsData = [],
    isFetching: isContactsFetching,
    isLoading: isContactsLoading,
    isError: isContactsError,
    error: contactsError,
  } = useGetActivityContactsQuery(
    {
      customerToken,
      activityId: messageId,
    },
    {
      skip: !messageId,
      pollingInterval: 30000,
    },
  );

  const {
    data: documents = [],
    isFetching: isDocumentsFetching,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
    error: documentsError,
    refetch,
  } = useGetActivityDocumentsQuery(
    { customerToken, activityId: messageId },
    { skip: !messageId, pollingInterval: 30000 },
  );

  const participantsDataForSort = [...participantsData];
  const participants = participantsDataForSort.sort((a, b) => {
    const nameA = getUserLastName(a);
    const nameB = getUserLastName(b);

    return nameA.localeCompare(nameB);
  });

  useEffect(() => {
    if (shouldReFetch) {
      refetch();
    }
  }, [shouldReFetch]);

  const contacts = useMemo(() => {
    const items = contactsData
      .filter((c) => c.dcsDocumentId === null)
      .map((contact) => ({
        ...contact,
        documents: documents.filter(
          (d) => d.onlineDateUTC === contact.onlineDateUTC,
        ),
      }));

    documents.forEach((document) => {
      if (
        !items.find(
          (contact) => contact.onlineDateUTC === document.onlineDateUTC,
        )
      ) {
        items.push({
          activityId: "",
          changedDateUTC: document.createDateUTC,
          createdBy: "",
          createdByUser: document.createdByUser,
          createdDateUTC: document.createDateUTC,
          dcsDocumentId: 0,
          documents: documents.filter(
            (d) => d.onlineDateUTC === document.onlineDateUTC,
          ),
          id: String(document.id),
          isInfo: false,
          personInCharge: "",
          personInChargeUser: undefined,
          sortNr: 0,
          stateType: ActivityState.Open,
          onlineDateUTC: document.onlineDateUTC,
          text: null,
        });
      }
    });

    return items.slice().sort((a, b) => {
      const x = a.createdDateUTC;
      const y = b.createdDateUTC;

      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    });
  }, [contactsData.length, documents.length]);

  return {
    activity,
    participants,
    documents,
    contacts,
    isFetching:
      isActivityFetching ||
      isParticipantsFetching ||
      isDocumentsFetching ||
      isContactsFetching,
    isLoading:
      isActivityLoading ||
      isParticipantsLoading ||
      isDocumentsLoading ||
      isContactsLoading,
    isActivityError,
    isParticipantsError,
    isContactsError,
    isDocumentsError,
    activityError,
    participantsError,
    contactsError,
    documentsError,
  };
}

type useFetchMessageDetails = {
  activity: Activity;
  participants: User[];
  documents: ActivityDocument[];
  contacts: ActivityContact[];
  isFetching: boolean;
  isLoading: boolean;
  isActivityError: boolean;
  isParticipantsError: boolean;
  isContactsError: boolean;
  isDocumentsError: boolean;
  activityError: SerializedError | FetchBaseQueryError | undefined;
  participantsError: SerializedError | FetchBaseQueryError | undefined;
  contactsError: SerializedError | FetchBaseQueryError | undefined;
  documentsError: SerializedError | FetchBaseQueryError | undefined;
};
