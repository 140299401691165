import { ParticipantWithAttendanceState } from "../../types";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { ParticipantAttendanceType } from "../../enums";
import {
  getDelegationParticipant,
  getParticipantDisplayName,
} from "../../lib/meeting";

export default function useSearchByQueryParticipants(
  participants: ParticipantWithAttendanceState[],
): SearchByQueryParticipants {
  const [queryString, setQueryString] = useState("");
  const handleFilterByQuery = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setQueryString(value);
    },
    [participants, setQueryString],
  );

  const queryParticipants = useMemo(
    () =>
      !!queryString
        ? participants.filter((participant) => {
            const delegatedParticipant =
              participant.attendance === ParticipantAttendanceType.Abscent
                ? getDelegationParticipant(
                    participants,
                    participant.delegationType,
                    participant.delegate,
                  )
                : undefined;

            const searchString = `${getParticipantDisplayName(participant)} ${
              delegatedParticipant
                ? `${getParticipantDisplayName(delegatedParticipant)}`
                : ""
            }${
              delegatedParticipant?.facilityAssignments
                ? delegatedParticipant?.facilityAssignments
                    .map(
                      ({ facilityUnitDescription }) => facilityUnitDescription,
                    )
                    .join(" ")
                : ""
            }${participant?.attendanceState?.text || ""}`;

            return searchString
              .toLowerCase()
              .includes(queryString.toLowerCase());
          })
        : participants,
    [queryString, participants],
  );

  return {
    handleFilterByQuery,
    queryParticipants,
  };
}

type SearchByQueryParticipants = {
  handleFilterByQuery: (e: SyntheticEvent<HTMLInputElement>) => void;
  queryParticipants: ParticipantWithAttendanceState[];
};
