import {
  SyntheticEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  LightboxContext,
  LightboxProviderContext,
} from "../components/common/lightbox/LightboxProvider";
import { FileWithMeta } from "../types";

const defaultOptions = {
  showMeta: false,
  closeButton: true,
};

export default function useLightbox(): LightboxProviderContext {
  return useContext(LightboxContext) as LightboxProviderContext;
}

export function useLightboxContext(
  options = {},
  initialFiles: FileWithMeta[],
): LightboxProviderContext {
  const files = useRef<FileWithMeta[]>(initialFiles).current;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const addFile = useCallback(
    (file: FileWithMeta) => {
      if (
        !files.find(
          ({ name, size, lastModified }) =>
            file.lastModified === lastModified &&
            file.name === name &&
            file.size === size,
        )
      ) {
        files.push(file);
      }
    },
    [files],
  );
  const toggleOpen = useCallback(
    (file?: FileWithMeta) => {
      if (file) {
        const index = files.findIndex(
          (f) =>
            file.name === f.name &&
            file.size === f.size &&
            file.lastModified === f.lastModified,
        );
        setSelectedIndex(index);
      }
      setIsOpen(!isOpen);
    },
    [isOpen, files],
  );

  const initialize = useCallback((initFiles: FileWithMeta[] = []) => {
    files.splice(0, files.length);
    files.push(...initFiles);
    setIsOpen(false);
    setSelectedIndex(0);
  }, []);

  const next = useCallback(
    (e: SyntheticEvent | KeyboardEvent) => {
      if (!(e instanceof KeyboardEvent)) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (selectedIndex < files.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      } else {
        setSelectedIndex(0);
      }
    },
    [files, selectedIndex],
  );

  const prev = useCallback(
    (e: SyntheticEvent | KeyboardEvent) => {
      if (!(e instanceof KeyboardEvent)) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      } else {
        setSelectedIndex(files.length - 1);
      }
    },
    [files, selectedIndex],
  );

  return {
    addFile,
    toggleOpen,
    initialize,
    files,
    selectedIndex,
    isOpen,
    next,
    prev,
    options: { ...defaultOptions, ...options },
  };
}
