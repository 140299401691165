import React, { FunctionComponent, ReactNode } from "react";
import { IconProps } from "../../../types";

import "../../../styles/components/common/contact/ContactPersonCard.scss";

export default function ContactPersonCardIcon(
  props: PropsProvider,
): JSX.Element {
  const { firstName, lastName, icon: ItemIcon, profilePicture } = props;

  return (
    <div className="contact-person-card-icon-container">
      {ItemIcon && (
        <div
          className="contact-person-card-icon-container-oval"
          data-testid="contact-person-card-icon"
        >
          <ItemIcon className="contact-person-card-icon" />
        </div>
      )}
      {profilePicture && (
        <div className="contact-person-card-profile-picture">
          {profilePicture}
        </div>
      )}
      <span data-testid="contact-person-card-name">
        {firstName} {lastName}
      </span>
    </div>
  );
}

type PropsProvider = {
  firstName?: string;
  lastName?: string;
  icon?: FunctionComponent<IconProps>;
  profilePicture?: ReactNode;
};
