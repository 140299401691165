import { Customer, FacilityObject, NavigationItem } from "../../types";
import { NavLink, useLocation } from "react-router-dom";
import React, { Fragment, useEffect } from "react";

export default function NavigationListSubItem({
  item,
  facilityObjectId,
  setIsCollapsed,
}: Props): JSX.Element {
  const { pathname } = useLocation();
  const Icon = item?.icon;

  useEffect(() => {
    if (item && pathname.endsWith(item?.to)) {
      setIsCollapsed(false);
    }
  }, [pathname]);

  return (
    <Fragment>
      {!facilityObjectId && (
        <li className="navigation-list-item">
          <NavLink
            className="navigation-list-item-link"
            role="link"
            to={item?.to ? item.to : ""}
            reloadDocument={!item?.navigate}
          >
            {Icon && (
              <Icon
                className="navigation-list-item-icon"
                width={30}
                height={30}
              />
            )}
            {!Icon && <span className="navigation-list-item-circle" />}
            <span className="navigation-list-item-title">{item?.title}</span>
          </NavLink>
        </li>
      )}
    </Fragment>
  );
}

type Props = {
  item?: NavigationItem;
  managementItem?: Customer;
  facilityObjectId?: boolean;
  assignedObjects?: FacilityObject[];
  setIsCollapsed: (item: boolean) => void;
};
