import React from "react";
import { IconProps } from "../../types";

export default function Invoices({
  width = 24,
  height = 24,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M23.743 16.563c.364 0 .711-.05 1.025-.164L25 17.935c-.463.114-.96.18-1.472.18-1.91 0-3.311-1.05-3.373-3.039h-.943v-.931h.943v-.686h-.943v-.915h.96c.156-1.985 1.545-3.022 3.505-3.022.447 0 .893.065 1.323.163l-.232 1.552a3.129 3.129 0 0 0-.975-.163c-.984 0-1.592.412-1.704 1.47h1.786v.915h-1.802v.686h1.802v.931h-1.798c.045 1.18.707 1.487 1.666 1.487zM6 5h11.811v13.333H12.75v3.334c0 1.84-1.511 3.333-3.375 3.333C7.511 25 6 23.508 6 21.667V5zm1.687 1.667v1.666h5.062V6.667H7.687zm3.375 11.666v-1.666H7.687v1.666h3.375zM14.436 15v-1.667H7.687V15h6.75zm1.688-3.333V10H7.687v1.667h8.437zM14.436 20h9.28v1.667c0 1.84-1.51 3.333-3.374 3.333h-7.206a4.937 4.937 0 0 0 1.3-3.333V20z"
        fillRule="evenodd"
      />
    </svg>
  );
}
