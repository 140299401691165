import "../../styles/components/bulletinBoard/DashboardRightContainer.scss";
import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function DashboardUpcomingDatesSkeleton(): JSX.Element {
  return (
    <Link className="dashboard-right-container-item-dates" to={`/`}>
      <div className="date-container">
        <span>
          <Skeleton width={50} />
        </span>
        <span className="date-container-date">
          <Skeleton />
        </span>
      </div>
      <div className="date-container">
        <Skeleton width={250} />
        <Skeleton width={250} />
      </div>
    </Link>
  );
}
