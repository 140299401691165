import React, { Fragment, useState } from "react";

import "../../styles/components/pages/EmployeesPage.scss";
import Button from "../common/Button";
import CrossIcon from "../icons/CrossIcon";
import EmployeesAddDialog from "./EmployeesAddDialog";
import useFetchEmployeesList from "../../hooks/employee/useFetchEmployeesList";

export default function AddEmployeeButton(): JSX.Element {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { employeesListWithoutOid } = useFetchEmployeesList();

  return (
    <Fragment>
      <Button
        leadingIcon={CrossIcon}
        label="Mitarbeiter hinzufügen"
        lightblue
        onClick={() => setDialogOpen(true)}
      />
      {isDialogOpen && (
        <EmployeesAddDialog
          onCancel={() => setDialogOpen(false)}
          employeesList={employeesListWithoutOid}
        />
      )}
    </Fragment>
  );
}
