import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import TabBar from "../common/TabBar";
import Tab from "../common/Tab";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import "../../styles/components/pages/UserSettingsPage.scss";
import { useLastLocation } from "react-router-dom-last-location";

export default function UserSettingsPage(): JSX.Element {
  const { pathname } = useLocation();
  const [pathnameLast, setPathnameLast] = useState("/");
  const lastLocation = useLastLocation();

  const lastLocationName =
    pathnameLast === undefined || pathnameLast.endsWith("/");

  const to = lastLocationName ? "../" : pathnameLast + "/";

  const backTo = {
    to: to ? to : "/",
    label: lastLocationName ? "Zurück zu HOMECASE" : "Zurück",
  };

  useEffect(() => {
    if (
      !lastLocation.lastLocation?.pathname.includes("einstellungen") &&
      lastLocation.lastLocation
    ) {
      setPathnameLast(lastLocation.lastLocation?.pathname);
    }
  }, [lastLocation.lastLocation?.pathname]);

  return (
    <div className="user-settings-container">
      <Helmet title="Einstellungen - Homecase" />
      <PageHeader title="Einstellungen" backTo={backTo} />
      <TabBar>
        <Tab
          to={"benachrichtigungen"}
          active={
            pathname.endsWith("benachrichtigungen") ||
            pathname.endsWith("einstellungen")
          }
          label="Benachrichtigungen"
        />
        <Tab
          to={"account"}
          active={pathname.endsWith("account")}
          label="Account"
        />
        <Tab
          to={"hausverwaltungen"}
          active={pathname.endsWith("hausverwaltungen")}
          label="Hausverwaltungen"
        />
      </TabBar>
      <div className="user-settings-container-agenda">
        <Outlet />
      </div>
    </div>
  );
}
