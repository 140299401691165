import { GenericUserEventModel, NotificationProps } from "../../types";
import NotificationItemDate from "./NotificationItemDate";

export default function OtherUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
}: NotificationProps): JSX.Element {
  const { raisedAtUtc } = userEventData as GenericUserEventModel;

  return (
    <div
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item-other"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <span>Nicht unterstützte Benachrichtigung.</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </div>
  );
}
