import {
  ReactElement,
  ReactFragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { MenuItem } from "./menu/MenuListItem";
import { MenuDiver } from "./menu/MenuListDiver";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import "../../styles/components/common/Menu.scss";

export default function Menu({
  button,
  children,
  className,
  header,
  footer,
  open = false,
}: Props): JSX.Element {
  const [isOpen, setOpen] = useState(open);
  const ref = useRef(null);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [isOpen]);
  const toggleMenu = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  useOnClickOutside(ref, closeMenu);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const menuSurfaceClassName = classNames({
    "menu-surface": true,
    "menu-surface-open": isOpen,
  });

  return (
    <div className={classNames("menu", className)} ref={ref}>
      <div className="menu-button" role="button" onClick={toggleMenu}>
        {button}
      </div>
      <div
        className={menuSurfaceClassName}
        role="menu"
        aria-hidden={isOpen}
        aria-orientation="vertical"
        tabIndex={-1}
      >
        {header && <div className="menu-header">{header}</div>}
        <ul className="menu-list">{children}</ul>
        {footer && <div className="menu-footer">{footer}</div>}
      </div>
    </div>
  );
}

type Props = {
  button: ReactNode | string;
  className?: string;
  header?: ReactNode | string;
  footer?: ReactNode | string;
  children:
    | (ReactElement<MenuItem | MenuDiver | ReactFragment> | boolean)[]
    | ReactElement<MenuItem | MenuDiver | ReactFragment>;
  open?: boolean;
};
