import { Permission } from "../../types";
import { useMemo } from "react";

export default function useGetSortPermissionsList(
  permissions: Permission[] | undefined,
): Permission[] | undefined {
  return useMemo(() => {
    if (!permissions) {
      return undefined;
    }

    const clonedPermissions = [...permissions];

    clonedPermissions.sort((a, b) => {
      const x: string = a.permissionName;
      const y: string = b.permissionName;

      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    });

    return clonedPermissions;
  }, [permissions]);
}
