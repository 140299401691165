import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  bulletinBoardApi,
  usePostBulletinBoardPostMutation,
} from "../../api/bulletinBoardApi";
import { append } from "../../slices/fileUploadSlice";
export default function useCreatePost() {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const dispatch = useDispatch();
  const [postItem, { isLoading, isError, error }] =
    usePostBulletinBoardPostMutation();

  const save = useCallback(
    async (values: {
      post: string;
      attachments?: File[];
      facilityObjectIds?: number[];
    }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data, error } = await postItem({
        facilityObjectId,
        customerToken,
        text: values.post,
        multiplePostingFacilityObjectIds: values.facilityObjectIds,
      });

      if (error) {
        return error;
      }

      if (data && values.attachments) {
        const { id } = data;
        dispatch(
          append({
            referenceId: id,
            files: values.attachments,
            customerToken,
            facilityObjectId,
            bulletinBoardPostId: id,
            endpoint:
              bulletinBoardApi.endpoints.postBulletinBoardPostAttachment,
          }),
        );
      }
    },
    [],
  );

  return { save, isLoading, isError, error };
}
