import React from "react";
import "../../styles/components/management/ObjectsPageActionsButtonDialogDescription.scss";
import { useGetUsersRegistrationStatusQuery } from "../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
export default function ObjectsPageActionsButtonDialogDescription(): JSX.Element {
  const { customerToken = "" } = useParams();
  const { data } = useGetUsersRegistrationStatusQuery({
    customerToken,
  });
  return (
    <div className="objects-page-actions-button-dialog-description-wrapper">
      <span className="description-text">
        aus den online Objekten, aus allen Miet-/ Eigentumsverhältnissen bzw.
        Einheiten, wo noch keine registrierte Benutzer gibt.
      </span>

      <div className="invitations-info">
        <span>Einladungen (Anzahl Personen):</span>
        <div className="invitations-info-list">
          <span>Wird eingeladen: {data?.willBeInvited}</span>
          <span> Bereits registriert: {data?.alreadyRegistered}</span>
          <span> Keine E-Mail-Adresse: {data?.withoutEmail}</span>
        </div>
      </div>
    </div>
  );
}
