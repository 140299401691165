import { useParams } from "react-router-dom";
import { useGetContactPictureQuery } from "../api/facilityManagementApi";

export default function useGetProfilePicture(
  employeeId?: string,
  visitingCardId?: string,
): Props {
  const { customerToken = "" } = useParams();
  const {
    data: profilePictureManagement,
    isSuccess: profilePictureManagementIsSuccess,
  } = useGetContactPictureQuery(
    {
      customerToken,
      employeeId,
      visitingCardId,
    },
    { skip: employeeId === undefined || visitingCardId === undefined },
  );

  return { profilePictureManagement, profilePictureManagementIsSuccess };
}

type Props = {
  profilePictureManagement: string | undefined;
  profilePictureManagementIsSuccess: boolean;
};
