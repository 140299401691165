import { ReactElement, useCallback } from "react";
import { FieldRenderProps } from "react-final-form";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import Attachment from "../../icons/Attachment";

import { ACCEPT_FILES } from "../../../lib/formValidate";

import "../../../styles/components/common/form/FileInput.scss";
import { isSameFile } from "../../../lib";

export default function FileInput({
  input,
  label,
  required,
  disabled,
  dropZoneProps,
  width = 56,
  height = 56,
  meta,
  description,
  ...rest
}: FieldRenderProps<File[] | undefined, HTMLInputElement>): ReactElement {
  const onDrop = useCallback(
    (files: File[]) => {
      const lastValue = input.value || [];
      const filteredValues = lastValue.filter(
        (a) => !files.find((b) => isSameFile(a, b)),
      );
      input.onChange([...filteredValues, ...files]);
    },
    [input],
  );
  const { getRootProps, getInputProps, isFocused, isDragReject, isDragAccept } =
    useDropzone({
      onDrop,
      noDrag: false,
      ...dropZoneProps,
      maxSize: 524288000,
      disabled,
      excludeAcceptAllOption: true,
      accept: ACCEPT_FILES,
    });
  const fileInputClassName = classNames({
    "file-input-field": true,
    "file-input-error": (meta.touched && meta.error) || isDragReject,
    "file-input-accepted": isDragAccept,
    "file-input-focused": isFocused,
  });

  return (
    <section className={fileInputClassName}>
      {label && (
        <span className="file-input-field-label" role="label">
          {label} {required && "*"}
        </span>
      )}
      <div
        className="file-input-field-container"
        {...getRootProps()}
        title="Ziehen Sie Dateien per Drag-and-Drop hierher oder klicken Sie, um Dateien auszuwählen"
      >
        <Attachment
          width={width}
          height={height}
          className="file-input-field-icon"
        />
        {description && (
          <div className="file-input-field-description">{description}</div>
        )}
        <input
          {...getInputProps()}
          {...rest}
          disabled={disabled}
          data-testid="file-input"
        />
      </div>
    </section>
  );
}
