import { createSlice } from "@reduxjs/toolkit";
import { SUB_ITEMS_REDUCER_PATH } from "../reducerPaths";

type InitialStateType = {
  items: string[];
};
export const initialState: InitialStateType = {
  items: [],
};

const subItemsSlice = createSlice({
  name: SUB_ITEMS_REDUCER_PATH,
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
  },
});
export const { addItem } = subItemsSlice.actions;
export default subItemsSlice.reducer;
