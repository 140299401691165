import React from "react";
import { IconProps } from "../../types";

export default function Calendar({
  width = 30,
  height = 30,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="M18 6a1 1 0 0 1 1 1h1a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H10a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h1a1 1 0 1 1 2 0h4a1 1 0 0 1 1-1zm3 7.999H9V20a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6.001zM11 9h-1a1 1 0 0 0-1 1v1.999h12V10a1 1 0 0 0-1-1h-1v1a1 1 0 0 1-2 0V9h-4v1a1 1 0 1 1-2 0V9z"
        fill="#212529"
      />
    </svg>
  );
}
