import { FacilityObjectListSortingType } from "../../enums";
import { useMemo } from "react";
import { FacilityObject } from "../../types";

export default function useSortedObjects(
  sorted: string,
  objects?: FacilityObject[],
) {
  const sortedFacilityObjects = useMemo(() => {
    let sortedObjects: FacilityObject[] | undefined = [];
    if (sorted === FacilityObjectListSortingType.None) {
      sortedObjects = objects;
    }
    if (sorted === FacilityObjectListSortingType.Alphabetical) {
      sortedObjects = objects?.sort((a, b) => {
        const x: string = a.street ? a.street : "";
        const y: string = b.street ? b.street : "";

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
    }

    if (sorted === FacilityObjectListSortingType.ObjectType) {
      sortedObjects = objects?.sort((a, b) => {
        const x = a.facilityObjectType;
        const y = b.facilityObjectType;

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
    }

    if (sorted === FacilityObjectListSortingType.Postcode) {
      sortedObjects = objects?.sort((a, b) => {
        const x = Number(a.zipCode);
        const y = Number(b.zipCode);

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
    }

    if (sorted === FacilityObjectListSortingType.Date) {
      sortedObjects = objects?.sort((a, b) => {
        const x = a.id;
        const y = b.id;

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
    }

    return sortedObjects;
  }, [objects, sorted]);

  return {
    sortedFacilityObjects,
  };
}
