import React from "react";
import "../../styles/components/common/TermsOfUseUserListItem.scss";
import { TermsOfUseUser } from "../../types";
import moment from "moment";

type Props = {
  user: TermsOfUseUser;
};

export default function TermsOfUseUserListItem({ user }: Props): JSX.Element {
  const createDateTime = moment
    .utc(user.acceptedAtUtc)
    .local()
    .format("DD.MM.YYYY");

  return (
    <div role="row" className="terms-of-use-user-list-item">
      <div
        className="terms-of-use-user-list-item-name"
        data-testid="participant-name"
        role="cell"
      >
        {user.userLastName}, {user.userFirstName}
      </div>
      <div
        className="terms-of-use-user-list-item-version"
        data-testid="participant-actions"
        role="cell"
      >
        {user.lastAcceptedVersion}
      </div>
      <div
        className="terms-of-use-user-list-item-datum"
        data-testid="participant-delegate"
        role="cell"
      >
        {user.lastAcceptedVersion ? createDateTime : ""}
      </div>
    </div>
  );
}
