import React, { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "../../styles/components/common/Tab.scss";

export default function Tab({
  to,
  label,
  active,
  onClick,
}: Props): JSX.Element {
  const className = classNames({
    tab: true,
    "tab-active": active,
    "tab-active-account": label === "Account",
  });
  const tabIndex = active ? 0 : -1;

  return (
    <Link
      to={to}
      className={className}
      role="tab"
      tabIndex={tabIndex}
      onClick={onClick}
    >
      <span className="tab-content">{label}</span>
      <span className="tab-indicator" />
    </Link>
  );
}

type Props = {
  to: string;
  label: string;
  active?: boolean;
  onClick?: (e: SyntheticEvent) => void;
};
