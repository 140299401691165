import React, { ReactElement, useCallback, useEffect } from "react";
import FacilityObjectPicture from "../../common/FacilityObjectPicture";
import { PictureFillStrategy } from "../../../enums";
import { useParams } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";
import {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
} from "../../../lib/formValidate";

import FileButton from "../../common/form/FileButton";
import CropImage from "../../common/form/CropImage";
import { useSetFacilityObjectPictureMutation } from "../../../api/facilityManagementApi";
import LoadingSpinner from "../../icons/LoadingSpinner";

import "../../../styles/components/management/objectSettings/ObjectPicture.scss";

export default function ObjectPicture(): ReactElement {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [setFacilityObjectPicture] = useSetFacilityObjectPictureMutation();

  const onSubmit = useCallback(async ({ picture }: { picture: File[] }) => {
    const [file] = picture;
    const response = await setFacilityObjectPicture({
      facilityObjectId,
      customerToken,
      file,
    });
    if ("error" in response) {
      if ("data" in response.error) {
        return {
          [FORM_ERROR]: response.error.data,
        };
      }
      return {
        [FORM_ERROR]: "Es ist ein Fehler aufgetreten.",
      };
    }
  }, []);

  return (
    <div className="object-picture">
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          hasSubmitErrors,
          form,
        }) => {
          useEffect(() => {
            if (submitSucceeded) {
              form.reset();
            }
          }, [submitSucceeded]);
          return (
            <form onSubmit={handleSubmit} className="object-picture-form">
              <div className="object-picture-form-preview">
                <FacilityObjectPicture
                  facilityObjectId={facilityObjectId}
                  customerToken={customerToken}
                  width={320}
                  height={320}
                  fillStrategy={PictureFillStrategy.None}
                />
                {submitting && (
                  <LoadingSpinner className="object-picture-form-spinner" />
                )}
              </div>
              {hasSubmitErrors && (
                <div className="object-picture-error">{submitError}</div>
              )}
              <Field
                component={CropImage}
                subscription={{ value: true, error: true, touched: true }}
                name="picture"
                onCrop={handleSubmit}
              />
              <Field
                component={FileButton}
                disabled={submitting}
                name="picture"
                label="Objektbild ändern"
                accept="image/*"
                validate={validateFiles(
                  validateFileType(),
                  validateFileFirstBytes(),
                )}
              />
            </form>
          );
        }}
      />
    </div>
  );
}
