import { IconProps } from "../../types";
import React from "react";

export default function GroupedIcon({
  width = 34,
  height = 34,
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
      data-testid="download-icon"
    >
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M23 14.778h-7.111M23 9.444H11.444M23 20.111h-7.111M7 9.444V13c0 .978.8 1.778 1.778 1.778h2.666" />
        <path d="M7 13v5.333c0 .978.8 1.778 1.778 1.778h2.666" />
      </g>
    </svg>
  );
}
