import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CustomerRequest,
  TermsOfServices,
  TermsOfServicesContentEditRequest,
  TermsOfServicesContentRequest,
  TermsOfServicesRequest,
  TermsOfServicesIsAccepted,
  TermsOfUseListParams,
  TermsOfUseUsersPage,
} from "../types";
import { TERMS_OF_SERVICES_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";

export const termsOfUseApi = createApi({
  reducerPath: TERMS_OF_SERVICES_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["TermsOfUse", "TermOfUseContent", "boolean", "TermsOfUseUserList"],
  endpoints: (build) => ({
    getTermsOfUse: build.query<TermsOfServices[], CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/termsOfServices`,
      providesTags: [{ type: "TermsOfUse", id: "LIST" }],
    }),

    getTermsOfUseUsersList: build.query<
      TermsOfUseUsersPage,
      TermsOfUseListParams
    >({
      query: ({ customerToken, ...params }) => ({
        url: `/customers/${customerToken}/termsOfServices/users`,
        params,
        providesTags: [{ type: "TermsOfUseUserList", id: "LIST" }],
      }),
    }),
    getTermsOfUseWithContent: build.query<
      TermsOfServices,
      TermsOfServicesRequest
    >({
      query: ({ customerToken, id }) =>
        `/customers/${customerToken}/termsOfServices/${id}`,
      providesTags: [{ type: "TermOfUseContent", id: "ITEM" }],
    }),
    getTermsOfUseActual: build.query<TermsOfServices, CustomerRequest>({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/termsOfServices/actual`,
    }),
    saveTermsOfUse: build.mutation<
      TermsOfServices,
      TermsOfServicesContentRequest
    >({
      query: ({ customerToken, content }) => ({
        url: `/customers/${customerToken}/termsOfServices`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: [
        { type: "TermsOfUse", id: "LIST" },
        { type: "TermOfUseContent", id: "ITEM" },
      ],
    }),
    editTermsOfUse: build.mutation<
      TermsOfServices,
      TermsOfServicesContentEditRequest
    >({
      query: ({ customerToken, content, id }) => ({
        url: `/customers/${customerToken}/termsOfServices/${id}`,
        method: "PUT",
        body: { content },
      }),
      invalidatesTags: [
        { type: "TermOfUseContent", id: "ITEM" },
        { type: "TermsOfUse", id: "LIST" },
      ],
    }),
    getOnlineTermsOfUse: build.mutation<
      TermsOfServices,
      TermsOfServicesContentEditRequest
    >({
      query: ({ customerToken, content, id }) => ({
        url: `/customers/${customerToken}/termsOfServices/${id}`,
        method: "PATCH",
        body: { content },
      }),
      invalidatesTags: [
        { type: "TermOfUseContent", id: "ITEM" },
        { type: "TermsOfUse", id: "LIST" },
      ],
    }),
    getTermsOfUseIsAccepted: build.query<boolean, CustomerRequest>({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/termsOfServices/actual/isAccepted`,
      }),
      providesTags: [{ type: "boolean", id: "TermOfUseAccepted" }],
    }),
    postTermsOfUseAccepted: build.mutation<
      TermsOfServicesIsAccepted,
      CustomerRequest
    >({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/termsOfServices/actual/accept`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "boolean", id: "TermOfUseAccepted" }],
    }),
  }),
});

export const {
  useGetTermsOfUseQuery,
  useGetTermsOfUseWithContentQuery,
  useSaveTermsOfUseMutation,
  useEditTermsOfUseMutation,
  useGetOnlineTermsOfUseMutation,
  useGetTermsOfUseIsAcceptedQuery,
  usePostTermsOfUseAcceptedMutation,
  useGetTermsOfUseActualQuery,
  useGetTermsOfUseUsersListQuery,
} = termsOfUseApi;
